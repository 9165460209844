/* React modules */

/* Our modules */
import { WebOddsServiceClient } from 'generated-proto/proto/odds/web_odds_pb.client';
import { OddsCacheServiceClient } from 'generated-proto/proto/cache_service/cache_pb.client';
import {
  CompetitionId,
  // InitialDataRequest,
  GetStreamUrlRequest,
} from 'generated-proto/proto/odds/web_odds_pb';
import {
  WebStreamEvent,
  InitialDataRequest as InitialDataRequestGO,
  CounterRequest,
} from 'generated-proto/proto/cache_service/cache_pb';
import { AppConfigResponse } from 'modules/sports/sports.types';
// import { MarketGroup, Sport } from 'modules/sports/sports.types';
import { getRpcTransport, getApiUrl } from 'libs/urlBuilder';
import { http } from 'app/axios-config';

/* 3rd Party modules */
import { Empty } from 'generated-proto/google/protobuf/empty_pb';

/*
type SportsAndMarketsResponse = {
  marketGroupsList: MarketGroup[];
  sportsList: Sport[];
};

export type EventsResponse = {
  liveEvents: {
    eventsList: Event[];
  };
  upcomingEvents: {
    error: any;
    eventsList: Event[];
  };
  playerEvents: {
    error: any;
    eventsList: Event[];
  };
};
*/

class SportsAPI {
  private client: WebOddsServiceClient;
  private oddsCacheClient: OddsCacheServiceClient;

  constructor() {
    this.client = new WebOddsServiceClient(getRpcTransport(getApiUrl()));

    this.oddsCacheClient = new OddsCacheServiceClient(
      getRpcTransport(getApiUrl('', { service: 'go_cache' }))
    );
  }

  // getSportsAndMarkets(options = {}): Promise<SportsAndMarketsResponse> {
  /*
  getSportsAndMarkets() {
    const request: InitialDataRequestGO = {
      onlyMarkets: false,
    };

    return this.oddsCacheClient.getInitialData(request);
  }
  */

  getSports() {
    const request: InitialDataRequestGO = {
      onlyMarkets: false,
    };

    return this.oddsCacheClient.getInitialDataSportsMap(request);
  }

  getMarkets() {
    const request: InitialDataRequestGO = {
      onlyMarkets: false,
    };

    return this.oddsCacheClient.getInitialDataMarkets(request);
  }

  getInitialDelta() {
    const request: InitialDataRequestGO = {
      onlyMarkets: false,
    };

    return this.oddsCacheClient.getInitialDataDeltaMap(request);
  }

  // getPlayerOutrightMarkets(): Promise<SportsAndMarketsResponse> {
  getPlayerOutrightMarkets() {
    const request: Empty = {};

    return this.client.getPlayerOutrightMarkets(request);
  }

  getOfferCounters(timeZoneOffset: string) {
    const request: CounterRequest = {
      offset: timeZoneOffset,
    };

    return this.oddsCacheClient.getOfferCounter(request);
  }

  getEventOdds(id: string) {
    const request: WebStreamEvent = {
      id,
      name: '',
      competitionId: 0,
      locationId: 0,
      sportId: 0,
      odds: [],
      isDisabled: false,
      landbaseCode: '',
      type: '',
      outrightType: '',
      mon: 0,
      numberOfOdds: 0,
      exefeedId: 0,
      profile: '',
      betradarId: '',
      betradarStreamId: '',
    };

    return this.oddsCacheClient.getSingleEvent(request);
  }

  getLiveDisableStatus() {
    const request: Empty = {};

    return this.client.getLiveDisabled(request);
  }

  getMissingCompetition(id: number, options = {}) {
    const request: CompetitionId = {
      id,
    };

    return this.client.getMissingCompetition(request);
  }

  getPublicIP() {
    return http.get<string>('', {
      baseURL: process.env.REACT_APP_PUBLIC_IP_SERVICE,
    });
  }

  getLiveStreamURL(
    streamId: string,
    userId: number,
    publicIP: string,
    accessToken: string
  ) {
    const request: GetStreamUrlRequest = {
      streamId,
      streamType: 'hls',
      csid: String(userId),
      deviceCategory: 'Desktop',
      realIp: publicIP,
    };

    return this.client.getStreamUrl(request, { meta: { accessToken } });
  }

  getTimestamp() {
    return http.get<AppConfigResponse>('/app-config');
  }

}

export { SportsAPI };
