/* React modules */
import { useState, useEffect, useMemo } from 'react';

/* Our modules */
import { Button, FileInput, Select, DatePicker, Input } from 'components';
import Validators from 'libs/validations';
import { hasProperty } from 'libs/common-helpers';
import './UploadDocumentsForm.scss';

/* 3rd Party modules */
import { useTranslation } from 'react-i18next';

interface Form {
  onSubmit: (files: any, type: string, expiryDate: string) => void;
}

const UploadDocumentsForm = ({ onSubmit }: Form) => {
  const { t } = useTranslation();

  const DOC_OPTIONS = useMemo(
    () => [
      { value: 'ID card', label: t('userData.personal-id') },
      /* { value: 'driver-license', label: t('userData.driver-license') }, */
      { value: 'Passport', label: t('userData.passport') },
    ],
    [t]
  );

  const [documentType, setDocumentType] = useState(DOC_OPTIONS[0]);
  const formDataValue = {
    document_expiry_date: '',
    document_front_page: null,
    document_last_page: null,
  };

  const [formData, setFormData] = useState<any>(formDataValue);
  const [errors, setErrors] = useState<any>({});
  const [isValid, setIsValid] = useState<any>(false);

  const fieldValidations = useMemo(
    () => ({
      document_expiry_date: [Validators.required],
      document_front_page: [Validators.required],
      document_last_page: [Validators.required],
    }),
    []
  );

  const validateField = (key: string) => {
    const validations = fieldValidations[key as keyof typeof fieldValidations];
    if (validations) {
      const error = validations.find((v: any) => !v.validate(formData[key]));
      if (error) {
        setErrors({ ...errors, [key]: t(error.message) });
      } else {
        setErrors({ ...errors, [key]: null });
      }
    }
  };

  const formatFieldValue = (value: any) => {
    if (value.target) {
      return value.target.value;
    } else {
      return value;
    }
  };

  const updateField = (key: string) => (value: any) => {
    const formattedFieldValue = formatFieldValue(value);

    if (formattedFieldValue.length) {
      setFormData({ ...formData, [key]: formattedFieldValue });
      validateField(key);
    }
  };

  const onDocumentTypeChange = (val: any) => {
    setDocumentType(val);
  };

  const submit = () => {
    onSubmit(formData, documentType.value, formData.document_expiry_date);
  };

  // @ts-ignore
  const CustomDatePickerInput = ({ ref }) => {
    return (
      <Input
        ref={ref}
        bg="white"
        height="large"
        name="document_expiry_date"
        value={formData.document_expiry_date}
        onChange={() => {}}
        onBlur={() => validateField('document_expiry_date')}
        readOnly
        error={errors.document_expiry_date}
      />
    );
  };

  useEffect(() => {
    if (documentType.value === DOC_OPTIONS[1].value) {
      delete formData.document_last_page;
      delete errors.document_last_page;
    }
  }, [errors, documentType.value, formData, DOC_OPTIONS]);

  useEffect(() => {
    let formValid = false;

    Object.keys(formDataValue).forEach((key) => {
      if (hasProperty(errors, key) || errors[key]) {
        formValid = true;
      }
    });
    setIsValid(formValid);
  }, [errors, formDataValue]);

  return (
    <div className="identity-form">
      <div className="heading-l text-white  mb-6">
        {t('verification.upload-documents.title')}
      </div>

      <div className="mb-4">
        <div className="identity-form__label">
          {t('verification.upload-documents.document-type')}
        </div>
        <Select
          onChange={onDocumentTypeChange}
          value={documentType}
          placeholder={t('verification.upload-documents.choose-document')}
          options={DOC_OPTIONS}
          shape="squared"
          bg="light"
          className="mb-4"
        />
      </div>

      <div className="mb-4">
        <div className="identity-form__label">
          {t('verification.upload-documents.expiration-date')}
        </div>

        <DatePicker
          customInput={CustomDatePickerInput}
          minimumDate={{
            day: new Date().getDate(),
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
          }}
          value={formData.document_expiry_date}
          onChange={updateField('document_expiry_date')}
        />
      </div>

      <div className="mb-4">
        <div className="identity-form__label">
          {t('verification.upload-documents.first-page')}
        </div>
        <FileInput
          className="my-2"
          btnLabel={t('verification.upload-documents.upload-first-page')}
          value={formData.document_front_page}
          name="document_front_page"
          error={errors.document_front_page}
          onChange={updateField('document_front_page')}
          onBlur={() => validateField('document_front_page')}
          accept=".HEIC, .heic, image/*"
        />
      </div>
      {documentType.value === DOC_OPTIONS[0].value && (
        <div className="mb-4">
          <div className="identity-form__label">
            {t('verification.upload-documents.second-page')}
          </div>

          <FileInput
            className="my-2"
            btnLabel={t('verification.upload-documents.upload-second-page')}
            value={formData.document_last_page}
            name="upload_last_two_pages"
            error={errors.document_last_page}
            onChange={updateField('document_last_page')}
            onBlur={() => validateField('document_last_page')}
            accept=".HEIC, .heic, image/*"
          />
        </div>
      )}
      <Button
        disabled={!isValid}
        size="large"
        className="my-6"
        bg="success"
        onClick={submit}
      >
        {t('verification.upload-documents.submit')}
      </Button>
    </div>
  );
};

export default UploadDocumentsForm;
