/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import { TicketStatus } from 'modules/ticket/ticket.types';
import useStores from 'common/hooks/useStores';
import TicketStatusFilter from 'modules/user/ui/TicketOverview/TicketStatusFilter';
import SectionHeader from 'modules/user/ui/SectionHeader';
import TicketsMobile from 'modules/user/ui/TicketOverview/TicketMobile';
import TicketStatusComponent from 'modules/ticket/ui/TicketStatus';
import TicketMobileFilter from 'modules/user/ui/TicketOverview/TicketMobile/TicketMobileFilter';
import { Icon, SimpleTable } from 'components';
import Responsive from 'components/Responsive';
import Footer from 'components/Footer';
import { formatDate, toDate, getOneMonthAgo } from 'libs/datetime';
import './TicketOverview.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export interface TicketOverviewProps {
  quickCodes?: boolean;
}

const TicketOverViewBody = observer(
  ({ status, dateFilter, searchId, quickCodes }: any) => {
    const { ticketsStore, overlayStore } = useStores();
    const {
      getTickets,
      resetTickets,
      tickets,
      ticketsPagination,
      setTicketsPage,
      quickSlipCodes,
    } = ticketsStore;
    const { t } = useTranslation();

    useEffect(() => {
      resetTickets();
      loadMore();
    }, [status, getTickets, resetTickets, dateFilter, searchId]);

    const loadMore = () => {
      if (ticketsPagination.hasNext) {
        setTicketsPage(ticketsPagination.page + 1);
        getTickets(status, dateFilter, searchId);
      }
    };

    const STATUS_DISPLAY: any = {
      [TicketStatus.NOT_RESOLVED]: {
        label: t('slips.active'),
        color: 'orange-800',
      },
      [TicketStatus.PASSED]: {
        label: t('slips.passed'),
        color: 'green-600',
      },
      [TicketStatus.FAILED]: {
        label: t('slips.failed'),
        color: 'red',
      },
      [TicketStatus.DENIED]: {
        label: t('slips.denied'),
        color: 'red',
      },
      [TicketStatus.APPROVING]: {
        label: t('slips.approving'),
        color: 'yellow',
      },
      [TicketStatus.PAYED_OUT]: {
        label: t('slips.paid'),
        color: 'green-600',
      },
      [TicketStatus.MANUAL_CHANGED]: {
        label: t('slips.changed'),
        color: 'blue',
      },
      default: {
        label: '',
        bg: 'green',
      },
    };

    const renderTicketDate = (field: any) => {
      return formatDate(toDate(field.value.seconds));
    };

    const renderStatus = (field: any) => {
      const cashoutPaidout = field.row.original.cashoutPaidOut;
      const ticketStatus =
        STATUS_DISPLAY[field.value] || STATUS_DISPLAY.default;

      return (
        <>
          {!cashoutPaidout ? (
            <div className={`uppercase text-${ticketStatus.color}`}>
              {ticketStatus.label}
            </div>
          ) : (
            <div className="text-green-600">CASHOUT</div>
          )}
        </>
      );
    };

    const openTicket = (id: string) => {
      ticketsStore.getTicket(id, { disableTimer: true }).then(() => {
        overlayStore.openModal(
          <TicketStatusComponent allTicketsButton={false} />,
          {
            width: 'large',
            className: 'visibe-tablet-down',
            wrapClassName: 'ticket-preview-modal-wrap',
            name: 'ticket-preview',
          }
        );
      });
    };

    const openQuickTicket = (code: string) => {
      ticketsStore.getQuickTicket(code).then(() => {
        overlayStore.openModal(
          <TicketStatusComponent hideTime={true} allTicketsButton={false} />,
          {
            width: 'large',
            className: 'visibe-tablet-down',
            wrapClassName: 'ticket-preview-modal-wrap',
            name: 'ticket-preview',
          }
        );
      });
    };

    const columns = [
      { accessor: 'shortUuid', Header: t('globals.id-caps') },
      {
        accessor: 'bettingTime',
        Header: t('globals.date-caps'),
        Cell: renderTicketDate,
      },
      { accessor: 'slipType', Header: t('globals.type-caps') },
      { accessor: 'bettingAmount', Header: t('globals.amount-caps') },
      {
        accessor: 'maxWinningTotal',
        Header: t('slips.pot-winning-amount-short'),
      },
      {
        accessor: 'status',
        Header: t('globals.status-caps'),
        Cell: renderStatus,
      },
      {
        id: 'search',
        Header: '',
        Cell: ({ row }: any) => <Icon name="search" />,
      },
    ];

    const quickSlipColumns = [
      { accessor: 'code', Header: t('globals.code-caps') },
      {
        id: 'search',
        Header: '',
        Cell: ({ row }: any) => <Icon name="search" />,
      },
    ];

    return (
      <Responsive
        display="block"
        fromDesktop={
          <SimpleTable
            columns={quickCodes ? quickSlipColumns : columns}
            data={quickCodes ? quickSlipCodes.slice(0) : tickets.slice(0)}
            loadMore={loadMore}
            tableFooter={ticketsPagination.hasNext ? null : Footer}
            onRowClick={(row: any) =>
              quickCodes ? openQuickTicket(row.code) : openTicket(row.shortUuid)
            }
          />
        }
        toLaptop={
          <TicketsMobile
            quickCodes={quickCodes}
            loadMore={loadMore}
            openTicket={quickCodes ? openQuickTicket : openTicket}
          />
        }
      />
    );
  }
);

const TicketOverview = observer(({ quickCodes }: TicketOverviewProps) => {
  const { ticketsStore } = useStores();
  const { getUserQuickCodes, tickets, quickSlipCodes } = ticketsStore;
  const [statusFilter, setStatusFilter] = useState('all');
  const { startDate, endDate } = getOneMonthAgo();
  const [dateFilter, setDateFilter] = useState<any>([startDate, endDate]);
  const [id, setId] = useState<string>('');
  const { t } = useTranslation();

  const handleStatusChange = (filter: any) => {
    setStatusFilter(filter);
  };

  const handleIdChange = (id: string) => {
    setId(id);
  };

  useEffect(() => {
    getUserQuickCodes();
  }, [getUserQuickCodes]);

  return (
    <div className="user-ticket-overview">
      <div className="max-height">
        <SectionHeader title={t('slips.ticket-overview')} />
        <TicketStatusFilter
          active={statusFilter as TicketStatus}
          onIdFilterChange={handleIdChange}
          onStatusChange={handleStatusChange}
          onDateChange={setDateFilter}
          resetDateFilter={() => setDateFilter([null, null])}
          isQuickTicketList={!!quickCodes}
        />
        {!quickCodes && (
          <TicketMobileFilter
            onStatusChange={handleStatusChange}
            onDateChange={setDateFilter}
            resetDateFilter={() => setDateFilter([null, null])}
          />
        )}
        <div className="visible-mobile-down py-4">
          <p className="text-right w-95 sb-text-small text-white uppercase">
            {t('slips.number-of-tickets')}{' '}
            {!quickCodes ? tickets.length : quickSlipCodes.length}
          </p>
        </div>
        <TicketOverViewBody
          quickCodes={quickCodes}
          searchId={id}
          status={statusFilter}
          dateFilter={dateFilter}
        />
      </div>
    </div>
  );
});

export default TicketOverview;
