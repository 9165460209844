/* React modules */

/* Our modules */
import i18n from 'app/localization/i18n';
import {
  QuickGamesOptions,
  QuickGame,
} from 'modules/quick-games/quick-games.types';
import QuickGamesApi from 'modules/quick-games/quick-games.api';
import authStore from 'modules/auth/auth.store';
import gamblingStore from 'modules/gambling/gambling.store';
import { isNumber, logger } from 'libs/common-helpers';

/* 3rd Party modules */
import { toast } from 'react-toastify';

const SPRIBE_GAMES = [
  QuickGamesOptions.AVIATOR,
  QuickGamesOptions.MINES,
  QuickGamesOptions.GOAL,
  QuickGamesOptions.DICE,
  QuickGamesOptions.MINI_ROULETTE,
  QuickGamesOptions.KENO,
  QuickGamesOptions.PLINKO,
  QuickGamesOptions.HILO,
];

const ELBET_GAMES = [
  QuickGamesOptions.ROCKETMAN,
  QuickGamesOptions.FASTBALLS,
  QuickGamesOptions.GOLDMINES,
  QuickGamesOptions.RACCOONRUSH,
];

export const BASE_URL = process.env.REACT_APP_SEVEN_GAMES_URL;
export const LIGHTNING_BASE_URL = 'https://dev.games-lightning.7platform.net';

export interface GameOptions {
  lang: string;
  isDemo: boolean;
}

class QuickGamesService {
  private api: QuickGamesApi;

  constructor() {
    this.api = new QuickGamesApi();
  }

  isSpribe(game: QuickGame): boolean {
    return SPRIBE_GAMES.includes(game);
  }

  isElbet(game: QuickGame): boolean {
    return ELBET_GAMES.includes(game);
  }

  isLightning = (game: QuickGame) => {
    return game === QuickGamesOptions.LIGHTNING;
  };

  setSpribeLanguage = (url: string, lang: string) => {
    const locale = lang === 'me' ? 'sr' : lang;
    return url.replace(/lang=EN/i, `lang=${locale}`);
  };

  getSpribeGame = async (game: QuickGame, options: GameOptions) => {
    const { lang, isDemo } = options;

    try {
      const { data } = await this.api.getSpribeGameData(game, isDemo);
      return this.setSpribeLanguage(data, lang);
    } catch (exception: any) {
      logger('QuickGamesService -> getSpribeGame -> exception', exception);

      return Promise.reject(exception);
    }
  };

  getSevenGame = async (game: QuickGame, options: GameOptions) => {
    if (options.isDemo) {
      return this.getSevenDemoUrl(game, options);
    }

    try {
      const { data } = await this.api.getSevenGameData();
      return this.getSevenGameUrl(game, data, options);
    } catch (exception: any) {
      logger('QuickGamesService -> getSevenGame -> exception', exception);

      return Promise.reject(exception);
    }
  };

  getGameUrl = async (game: QuickGame, options: GameOptions) => {
    try {
      if (isNumber(game)) {
        if (authStore && authStore.token) {
          return await gamblingStore.getQuickGameUrl(
            game as unknown as number,
            authStore.token
          );
        } else {
          toast.error(i18n.t('verification.login-activation'));

          return '';
        }
      } else {
        if (this.isSpribe(game)) {
          return await this.getSpribeGame(game, options);
        } else if (this.isElbet(game)) {
          return await this.getElbetLaunchURL(game);
        } else {
          return await this.getSevenGame(game, options);
        }
      }
    } catch (exception: any) {
      logger('QuickGamesService -> getGameUrl -> exception', exception);

      return Promise.reject(exception);
    }
  };

  getPokerLaunchURL = async () => {
    try {
      const { data } = await this.api.getPokerLaunchURL();
      return data;
    } catch (exception: any) {
      logger('QuickGamesService -> getPokerLaunchURL -> exception', exception);

      return Promise.reject(exception);
    }
  };

  getSevenDemoUrl = (game: QuickGame, options: GameOptions) => {
    const { lang } = options;

    return `${BASE_URL}/${game}?auth=b2b&logout=true&lang=${lang}`;
  };

  getSevenGameUrl = (game: QuickGame, data: any, options: GameOptions) => {
    const { lang } = options;
    const { id, authStrategy, sessionId, productId, tenantId } = data;

    if (this.isLightning(game)) {
      const locale = lang === 'me' ? 'sr-Latn' : lang;
      return `${LIGHTNING_BASE_URL}/?platform=seven&clientType=${authStrategy}&tenantId=${tenantId}&productId=${productId}&productName=${game}&locale=${locale}&token=${sessionId}&playerId=${id}`;
    }
    return `${BASE_URL}/${game}?auth=b2b&lang=${lang}&token=${sessionId}&id=${id}&authStrategy=${authStrategy}`;
  };

  getElbetLaunchURL = async (game: string) => {
    try {
      const { data } = await this.api.getElbetLaunchURL(game);

      return data;
    } catch (exception) {
      logger('QuickGamesService -> getElbetGameData -> exception', exception);

      return Promise.reject(exception);
    }
  };
}

export default QuickGamesService;
