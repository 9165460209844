/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import { TicketStatus, TicketStatuses } from 'modules/ticket/ticket.types';
import { ValidationRule } from 'modules/ticket/ticket.errors';
import useStores from 'common/hooks/useStores';
import { Button, Checkbox, Icon } from 'components';
import Timer from 'components/Timer';
import TicketStatusComponent from 'modules/ticket/ui/TicketStatus';

/* 3rd Party modules */
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface TicketStatusActionsProps {
  status: TicketStatuses;
  cashout_amount: number | null;
  placeBet: () => void;
  allTicketsButton?: boolean;
  hideFooter?: boolean;
  isSharedTicket?: boolean;
}

const TicketStatusActions = observer(
  ({
    status,
    placeBet,
    allTicketsButton,
    hideFooter,
    isSharedTicket,
  }: TicketStatusActionsProps) => {
    const { t } = useTranslation();
    const [timerCount, setTimerCount] = useState(0);
    const [cashoutTimerCount, setCashoutTimerCount] = useState(0);
    const [timerPercentage, setTimerPercentage] = useState(0);
    const [cashoutTimerPercentage, setCashoutTimerPercentage] = useState(0);
    const [isWaitingCashout, setIsWaitingCashout] = useState(false);

    const {
      overlayStore,
      ticketValidator,
      ticketsStore,
      ticketBuilder,
      loaderStore,
    } = useStores();
    const { shouldSaveOdds, setShouldSaveOdds } = ticketBuilder;
    const {
      shouldAcceptChanges,
      approvalTimer,
      cashoutTimer,
      setShouldAcceptChanges,
    } = ticketsStore;
    const unconfirmed = status === TicketStatus.UNCONFIRMED;

    const closeModal = () => {
      ticketsStore.clearApprovalTimer();
      overlayStore.closeModal();
    };

    const viewAllTickets = () => {
      closeModal();
    };

    const onTicketChangesDenied = (timeExceeded: boolean = false) => {
      ticketsStore.denyTicketChanges(timeExceeded).then(() => {
        ticketsStore
          .getTicket(ticketsStore.ticket?.shortUuid || '')
          .then(() => {
            overlayStore.openModal(<TicketStatusComponent />, {
              width: 'large',
              className: 'visibe-tablet-down',
              wrapClassName: 'ticket-preview-modal-wrap',
              name: 'ticket-preview',
            });
          });
      });
    };

    const resetCashoutTimer = () => {
      setCashoutTimerCount(0);
      setCashoutTimerPercentage(0);
    };

    const sendSlipForCashout = () => {
      setIsWaitingCashout(true);
      ticketsStore.sendSlipForCashout();
    };

    const acceptCashout = async () => {
      await ticketsStore.acceptCashout();

      setIsWaitingCashout(false);
    };

    const denyCashout = async (deniedManually: boolean = true) => {
      await ticketsStore.denyCashout(deniedManually);

      resetCashoutTimer();
      setIsWaitingCashout(false);
    };

    const cancelCashout = async () => {
      await ticketsStore.cancelCashout();

      setIsWaitingCashout(false);
    };

    const toggleShoudSaveOdds = (event: any) => {
      setShouldSaveOdds(event.target.checked);
    };

    const toggleShouldAcceptChanges = (event: any) => {
      setShouldAcceptChanges(event.target.checked);
    };

    const shareTicket = async () => {
      const { slipId } = ticketsStore.ticket || {};

      if (slipId) {
        try {
          await window.navigator.clipboard.writeText(
            `${window.location.origin}/sport?ticket-share=${slipId}`
          );

          toast.success(t('slips.share-ticket-success'));
        } catch (exception) {
          toast.error(t('errors.error-unknown'));
        }
      }
    };

    if (hideFooter) return null;

    useEffect(() => {
      if (ticketsStore.timerActive) {
        const approvalInterval = setInterval(() => {
          if (timerCount <= approvalTimer) {
            setTimerCount(timerCount + 0.1);
            setTimerPercentage((timerCount * 100) / approvalTimer);
          } else {
            if (ticketsStore.timerActive) {
              onTicketChangesDenied(true);
            }

            window.clearInterval(approvalInterval);
          }
        }, 100);

        return () => window.clearInterval(approvalInterval);
      }
    });

    useEffect(() => {
      if (isWaitingCashout) {
        window.setTimeout(() => {
          ticketsStore.cancelCashout();

          setIsWaitingCashout(false);
        }, 60000);
      }
    }, [isWaitingCashout]);

    useEffect(() => {
      if (ticketsStore.cashoutTimerActive) {
        const cashoutInterval = window.setInterval(() => {
          if (
            cashoutTimerCount <= cashoutTimer &&
            !ticketsStore.cashoutDenied
          ) {
            setCashoutTimerCount(cashoutTimerCount + 0.1);
            setCashoutTimerPercentage((cashoutTimerCount * 100) / cashoutTimer);
          } else {
            ticketsStore.clearCashout();

            window.clearInterval(cashoutInterval);

            resetCashoutTimer();
          }

          if (cashoutTimerCount > cashoutTimer) {
            denyCashout(false);
          }
        }, 100);

        return () => window.clearInterval(cashoutInterval);
      }
    });

    return (
      <div>
        <div className="ticket-status__actions">
          <div className="ticket-status__save-ticket">
            <div>
              {allTicketsButton && unconfirmed && !isSharedTicket && (
                <Checkbox
                  value={shouldSaveOdds}
                  label={t('slips.save-games')}
                  onChange={toggleShoudSaveOdds}
                />
              )}
            </div>

            <div>
              {ticketBuilder.hasLiveEvents &&
                unconfirmed &&
                !isSharedTicket && (
                  <Checkbox
                    value={shouldAcceptChanges}
                    label={t('slips.accept-changes-after-place-bet')}
                    onChange={toggleShouldAcceptChanges}
                  />
                )}
            </div>
          </div>

          <div className="ticket-status__errors">
            {!isSharedTicket &&
              ticketValidator.serverErrors.map((error: string) =>
                error.includes(ValidationRule.SERVICE_ERROR) ? (
                  <p className="text-small text-yellow">
                    {t('errors.system-error')}
                  </p>
                ) : error.includes(ValidationRule.ANTEPOST_ERROR) ? (
                  <p className="text-small text-yellow">
                    {t(`errors.${error.split(':')[0]}`)}: {error.split(':')[2]}
                  </p>
                ) : (
                  <p className="text-small text-yellow">{error}</p>
                )
              )}
          </div>

          <div
            style={{ position: 'relative' }}
            className="ticket-status__buttons mt-2"
          >
            {(ticketsStore.ticket || {}).slipId && !isSharedTicket && (
              <Icon
                name="share"
                size="medium"
                className="pointer"
                onClick={shareTicket}
              />
            )}

            {ticketsStore.timerActive && !isSharedTicket && (
              <div className="ticket-status__timer">
                <Timer percent={timerPercentage} />
              </div>
            )}

            {ticketsStore.cashoutTimerActive && !isSharedTicket && (
              <div className="ticket-status__timer">
                <Timer percent={cashoutTimerPercentage} />
              </div>
            )}

            {status !== TicketStatus.MANUAL_CHANGED && !isSharedTicket && (
              <>
                <div>
                  {ticketsStore.cashoutAmount &&
                    status !== TicketStatus.PAYED_OUT && (
                      <Button size="small" bg="warning" onClick={denyCashout}>
                        {t('slips.deny-cashout')}
                      </Button>
                    )}

                  {ticketsStore.ticket?.hasCashout && !isWaitingCashout && (
                    <Button
                      size="small"
                      className="ml-6"
                      bg="success"
                      onClick={sendSlipForCashout}
                    >
                      {t('slips.cashout')}
                    </Button>
                  )}

                  {ticketsStore.cashoutAmount && (
                    <Button
                      size="small"
                      className="ml-6"
                      bg="success"
                      onClick={acceptCashout}
                    >
                      {t('slips.accept-cashout')} &euro;
                      {ticketsStore.cashoutAmount}
                    </Button>
                  )}

                  {isWaitingCashout && !ticketsStore.cashoutAmount && (
                    <Button
                      size="small"
                      className="ml-6 uppercase"
                      bg="warning"
                      onClick={cancelCashout}
                    >
                      {t('slips.cancel-cashout')}
                    </Button>
                  )}
                </div>

                {unconfirmed && (
                  <Button
                    size="small"
                    bg={unconfirmed ? 'grey' : 'success'}
                    onClick={closeModal}
                  >
                    {t('globals.cancel')}
                  </Button>
                )}

                {allTicketsButton &&
                  unconfirmed &&
                  ticketBuilder.changesAccepted && (
                    <Button
                      className="ml-6"
                      size="small"
                      disabled={loaderStore.isActive}
                      bg="success"
                      onClick={placeBet}
                    >
                      {t('slips.place-bet')}
                    </Button>
                  )}

                {allTicketsButton &&
                  unconfirmed &&
                  !ticketBuilder.changesAccepted && (
                    <Button
                      className="ml-6"
                      size="small"
                      bg="warning"
                      disabled={loaderStore.isActive}
                      onClick={() => ticketBuilder.acceptAllChanges(true)}
                    >
                      {t('slips.accept-changes')}
                    </Button>
                  )}

                {allTicketsButton &&
                  !unconfirmed &&
                  !ticketsStore.cashoutTimerActive && (
                    <NavLink to="/profil/pregled-tiketa">
                      <Button
                        className="ml-6"
                        size="small"
                        bg="success"
                        disabled={loaderStore.isActive}
                        onClick={viewAllTickets}
                      >
                        {t('slips.all-tickets')}
                      </Button>
                    </NavLink>
                  )}
              </>
            )}

            {(status === TicketStatus.FAILED ||
              status === TicketStatus.NOT_RESOLVED ||
              isSharedTicket) && (
              <Button
                size="small"
                bg="success"
                className="ml-3 uppercase"
                disabled={!ticketsStore.canCopyTicket}
                onClick={() => ticketsStore.copyTicket()}
              >
                {ticketsStore.canCopyTicket
                  ? t('globals.copy-ticket')
                  : t('globals.ticket-copied')}
              </Button>
            )}

            {status === TicketStatus.MANUAL_CHANGED && !isSharedTicket && (
              <>
                <Button
                  className="mr-6"
                  size="small"
                  bg="grey"
                  onClick={() => onTicketChangesDenied()}
                >
                  {t('globals.cancel')}
                </Button>

                <Button
                  size="small"
                  bg="success"
                  onClick={() => ticketsStore.acceptTicketChanges()}
                >
                  {t('slips.accept-changes')}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export { TicketStatusActions };
