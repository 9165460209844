import { makeAutoObservable } from 'mobx';

import sportsStore from 'modules/sports/store/sports.store';
import { MarketGroup } from 'modules/sports/sports.types';
import Event from 'modules/sports/store/event.store';
import { SportMarket } from 'modules/sports/market-model';
import { SportMarketGroup } from 'modules/sports/market-group-model';

class OfferEvent {
  event: Event | null = null;
  marketGroupsFilter: MarketGroup | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get marketGroups(): SportMarketGroup[] {
    if (!this.event) return [];
    return sportsStore.marketGroups.get(this.event.sportId)?.groups || [];
  }

  markets() {
    if (!this.event || !this.marketGroupsFilter) return [];

    return (this.marketGroupsFilter.marketsList || []).map((market) => {
      const sportMarket = new SportMarket(market);

      return Object.assign(
        Object.create(Object.getPrototypeOf(sportMarket)),
        sportMarket,
        {
          odds: this.getOddsForMarket(market.id),
        }
      );
    });
  }

  getOddsForMarket = (marketId: number) => {
    if (!this.event) return [];

    return this.event.allOdds.filter(
      (odd: any) => odd?.market?.id === marketId
    );
  };

  setEvent = (event: any) => {
    this.event = event;

    if (this.notSelectedMarketGroupFilter()) {
      this.setGroupsFilter(this.marketGroups[0]);
    }
  };

  notSelectedMarketGroupFilter(): boolean {
    if (!this.marketGroupsFilter) return true;
    const group = this.marketGroups.find(
      (market) => market.id === this.marketGroupsFilter?.id
    );
    return !group;
  }

  setGroupsFilter = (group: any) => {
    this.marketGroupsFilter = group;
  };

  hasOdds = (marketGroup: any) => {
    const markets =
      this.event && marketGroup
        ? ((marketGroup || {}).marketsList || []).map((market: any) => {
            const sportMarket = new SportMarket(market);

            return Object.assign(
              Object.create(Object.getPrototypeOf(sportMarket)),
              sportMarket,
              {
                odds: this.getOddsForMarket(market.id),
              }
            );
          })
        : [];

    return markets.some((m: any) => !!m.odds.length);
  };
}

export default new OfferEvent();
