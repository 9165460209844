/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import { EventsSort } from 'modules/offer/store/offer.sort';
import {
  getCompetitionEventsCount,
  getDateEventsCount,
} from 'modules/offer/services/offer-helpers.service';
import { Icon } from 'components';
import RenderEvents from 'modules/offer/ui/OfferMobile/RenderEvents';
import { IconName } from 'components/Icon/Icon';
import CountryIcon, { COUNTRY_ICONS } from 'components/CountryIcon';
import Loader from 'components/Loader';
import DefaultLocationIcon from '../../../../images/defaultLocationIcon.svg';

/* 3rd Party modules */
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';

export interface RenderGroupedEventsProps {
  groupedEvents: any;
  offer: any;
}

const CustomAccordionHeader = ({
  label,
  count,
  onClick,
  isActive,
  location,
  activeSort,
}: any) => {
  const group = label.indexOf('=') !== -1 ? label.split('=')[1] : label;

  return (
    <div
      onClick={() => {
        onClick(label);
      }}
      className={classnames('offer-mobile__group-header', { active: isActive })}
    >
      <div className="w-90 d-flex align-items text-ellipsis">
        <div className="d-flex align-items justify-start mr-2">
          {COUNTRY_ICONS[location] !== undefined &&
          activeSort === EventsSort.COMPETITION ? (
            <CountryIcon
              original
              size="regular"
              country={COUNTRY_ICONS[location]}
            />
          ) : (
            <img
              alt="Location"
              style={{ height: '20px', width: '20px' }}
              src={DefaultLocationIcon}
            />
          )}
        </div>
        {group}
      </div>
      <div className="w-10 d-flex align-items text-small justify-end">
        <div className="w-70 text-center">{count}</div>
        <div className="w-30 text-center ml-2">
          {
            <Icon
              size="extra-small"
              name={isActive ? 'caretDown' : ('caretRight' as IconName)}
            />
          }
        </div>
      </div>
    </div>
  );
};

const RenderGroupedEvents = observer(
  ({ groupedEvents }: RenderGroupedEventsProps) => {
    const { offerStore, offerSort, sportsStore, offerFilters } = useStores();
    const { allEventsLoaded, offerCounters } = sportsStore;

    let streamEventsCount = 0;
    let localEventsCount = 0;

    const toggleGroup = (group: string) => {
      if (offerStore.activeGroup) {
        offerStore.setActiveGroup(null);
      } else {
        offerStore.setActiveGroup(group);

        if (group.includes('=')) {
          //@ts-ignore
          streamEventsCount = getCompetitionEventsCount(
            groupedEvents[group][0].sportId,
            groupedEvents[group][0].locationId,
            groupedEvents[group][0].competitionId,
            offerCounters,
            offerFilters.timeFilter
          );
          //@ts-ignore
          localEventsCount = groupedEvents[group].length;
        } else {
          //@ts-ignore
          streamEventsCount = getDateEventsCount(
            groupedEvents[group][0].sportId,
            group,
            offerCounters,
            offerFilters.timeFilter
          );
          //@ts-ignore
          localEventsCount = groupedEvents[group].length;
        }
      }
    };

    return (
      <>
        {offerStore.activeGroup && (
          <>
            <CustomAccordionHeader
              label={offerStore.activeGroup}
              count={
                allEventsLoaded
                  ? groupedEvents[offerStore.activeGroup].length
                  : offerSort.activeSort === 1
                  ? getCompetitionEventsCount(
                      groupedEvents[offerStore.activeGroup][0].sportId,
                      groupedEvents[offerStore.activeGroup][0].locationId,
                      groupedEvents[offerStore.activeGroup][0].competitionId,
                      offerCounters,
                      offerFilters.timeFilter
                    )
                  : getDateEventsCount(
                      groupedEvents[offerStore.activeGroup][0].sportId,
                      offerStore.activeGroup,
                      offerCounters,
                      offerFilters.timeFilter
                    )
              }
              onClick={toggleGroup}
              isActive
            />
            <RenderEvents events={groupedEvents[offerStore.activeGroup]} />
            {!allEventsLoaded && localEventsCount !== streamEventsCount && (
              <div
                style={{
                  height: '200px',
                  position: 'relative',
                  background: '#303030',
                }}
              >
                <Loader height={'0px'} />
              </div>
            )}
          </>
        )}
        {!offerStore.activeGroup &&
          Object.keys(groupedEvents).map((group, index) => (
            <CustomAccordionHeader
              key={`RenderGroupedEvents-CustomAccordionHeader-${index}`}
              label={group}
              location={groupedEvents[group][0].locationName}
              activeSort={offerSort.activeSort}
              count={
                allEventsLoaded
                  ? groupedEvents[group].length
                  : offerSort.activeSort === 1
                  ? getCompetitionEventsCount(
                      groupedEvents[group][0].sportId,
                      groupedEvents[group][0].locationId,
                      groupedEvents[group][0].competitionId,
                      offerCounters,
                      offerFilters.timeFilter
                    )
                  : getDateEventsCount(
                      groupedEvents[group][0].sportId,
                      group,
                      offerCounters,
                      offerFilters.timeFilter
                    )
              }
              onClick={toggleGroup}
            />
          ))}
      </>
    );
  }
);

export default RenderGroupedEvents;
