/* React modules */

/* Our modules */
// import { GetStoreItemsResponse } from 'modules/user/market.types';
import { LoyaltyWebServiceClient } from 'generated-proto/proto/loyalty/loyalty_pb.client';
import {
  GetOrdersRequest,
  Order,
  OrderItem,
  StoreItem,
} from 'generated-proto/proto/loyalty/loyalty_pb';
import { getRpcTransport, getApiUrl } from 'libs/urlBuilder';

/* 3rd Party modules */
import { Empty } from 'generated-proto/google/protobuf/empty_pb';

class MarketAPI {
  private client: LoyaltyWebServiceClient;

  constructor() {
    this.client = new LoyaltyWebServiceClient(getRpcTransport(getApiUrl()));
  }

  getStoreItems(accessToken: string) {
    const request: Empty = {};

    return this.client.getStoreItems(request, { meta: { accessToken } });
  }

  placeOrder(payload: any, accessToken: string) {
    const items: OrderItem[] = [];

    payload.items.forEach((item: any) => {
      const {
        quantity,
        item: { id },
      } = item;

      let itemReq: OrderItem = { quantity };

      const storeItem: StoreItem = {
        id,
        name: '',
        description: '',
        price: 0,
        imageUrl: '',
        quantity: 0,
        reserved: 0,
        digital: false,
        digitalType: '',
        digitalAmount: 0,
        isActive: false,
        order: 0,
      };

      itemReq = { ...itemReq, item: storeItem };

      items.push(itemReq);
    });

    const request: Order = {
      items,
      bettingPlaceId: payload.betting_place_id || 0,
      points: 0,
      code: '',
      id: 0,
      userId: 0,
      createdAt: 0,
      status: '',
    };

    return this.client.placeOrder(request, { meta: { accessToken } });
  }

  getOrders(offset: number, accessToken: string) {
    const request: GetOrdersRequest = {
      offset,
      start: 0,
      end: 0,
      code: '',
      status: '',
    };

    return this.client.getOrders(request, { meta: { accessToken } });
  }

  getLoyalty(accessToken: string) {
    const request: Empty = {};

    return this.client.getLoyalty(request, { meta: { accessToken } });
  }

  getLoyaltyConfig(accessToken: string) {
    const request: Empty = {};

    return this.client.getWebLoyaltyConfig(request, { meta: { accessToken } });
  }

  getSingleOrder(orderId: number, accessToken: string) {
    const request: Order = {
      id: orderId,
      points: 0,
      code: '',
      userId: 0,
      items: [],
      createdAt: 0,
      status: '',
      bettingPlaceId: 0,
    };

    return this.client.getSingleOrder(request, { meta: { accessToken } });
  }

  cancelOrder(orderId: number, accessToken: string) {
    const request: Order = {
      id: orderId,
      points: 0,
      code: '',
      userId: 0,
      items: [],
      createdAt: 0,
      status: '',
      bettingPlaceId: 0,
    };

    return this.client.cancelOrder(request, { meta: { accessToken } });
  }
}

export { MarketAPI };
