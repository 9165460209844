/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import Button from 'components/Button';
import './MobileItem.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface MobileItemProps {
  item: any;
  expandedItems: any;
  onClick: (id: number) => void;
  hasButtons?: boolean;
}

const MobileItem = observer(
  ({ item, expandedItems, onClick, hasButtons = true }: MobileItemProps) => {
    const { t } = useTranslation();
    const { marketStore, userStore } = useStores();
    const { casinoBonus } = userStore;
    const { getCart, addToCart, removeFromCart, isDigital, cartCount } =
      marketStore;

    const addToCartHandler = (item: any) => {
      if (isDigital && item.digital && cartCount > 0) {
        toast.error(t('errors.cannot-add-more-than-one-digital-item'));
      } else if (isDigital && !item.digital && cartCount > 0) {
        toast.error(t('errors.cannot-order-mixed-items'));
      } else if (!isDigital && item.digital && cartCount > 0) {
        toast.error(t('errors.cannot-order-mixed-items'));
      } else if (item.digitalType === 'casino' && casinoBonus) {
        toast.error(t('errors.cannot-buy-casino-bonus-if-you-has-one'));
      } else {
        addToCart(item);
      }
    };

    return (
      <div
        className={
          expandedItems.includes(item?.id || item?.item?.id)
            ? 'item-mobile-expanded'
            : 'item-mobile'
        }
      >
        <img
          className="item-mobile-image"
          src={item?.imageUrl || item?.item?.imageUrl}
          alt={item?.name || item?.item?.name}
        />
        <div className="item-mobile-content">
          <div className="item-mobile-content-title">
            <p className="text-yellow text-bold">
              {item?.name || item?.item?.name}
            </p>
            <p className="text-white">
              Poena:{' '}
              <span className="text-green-800 text-extra-strong">
                {item?.price || item?.item?.price}
              </span>
            </p>
          </div>
          <div className="item-mobile-content-description mt-1">
            <div
              onClick={() => onClick(item?.id || item?.item?.id)}
              className="item-mobile-content-description-main sb-text-small pr-2 text-white"
            >
              <p className="">{item?.description || item?.item?.description}</p>
            </div>
            <div className="item-mobile-content-description-actions sb-text-small">
              {hasButtons &&
                getCart.filter((i) => i?.id === (item?.id || item?.item?.id))
                  .length === 0 && (
                  <Button
                    bg="green"
                    size="small"
                    className="text-sb-extra-small uppercase"
                    onClick={() => addToCartHandler(item)}
                  >
                    {t('globals.add-to-cart')}
                  </Button>
                )}

              {hasButtons &&
                getCart.filter((i) => i.id === (item?.id || item?.item?.id))
                  .length > 0 && (
                  <Button
                    bg="grey"
                    size="small"
                    className="text-sb-extra-small uppercase"
                    onClick={() => removeFromCart(item?.id || item?.item?.id)}
                  >
                    {t('globals.remove-from-cart')}
                  </Button>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default MobileItem;
