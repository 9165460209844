/* React modules */
import { ReactNode, useState, useEffect, Fragment } from 'react';

/* Our modules */
import { EVENT_TYPE, OddStatuses } from 'modules/sports/sports.types';
import useStores from 'common/hooks/useStores';
import { useLimitOptions } from 'libs/useLimitOptions';
import OfferOdd from 'modules/sports/store/odd.store';
import { DUPLICATE_MARKETS } from 'modules/offer/store/market-switcher';
import {
  filterAntepostOutcomes,
  formatMarkets,
  formatOddsByLimit,
} from 'modules/offer/services/offer-helpers.service';
import EventCell from 'modules/offer/ui/Offer/EventCell';
import OddCell from 'modules/offer/ui/Offer/OddCell';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';

/* 3rd Party modules */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

const Market = ({
  index,
  children,
}: {
  index: number;
  children: ReactNode;
}) => (
  <div
    className={classNames('column', {
      highlighted: index % 2 !== 0,
    })}
  >
    {children}
  </div>
);

const Odd = observer(({ odd, event }: any) => {
  if (!odd || !odd.visible)
    return <div className="cell cell__empty odd-cell">-</div>;
  return <OddCell event={event} odd={odd} />;
});

const RenderMarket = observer(
  ({
    data,
    market,
    i,
  }: // allMarkets,
  {
    data: any;
    market: any;
    i: number;
    // allMarkets: any[];
  }) => {
    const { odds } = data;
    const { name, outcomes } = market;

    const [limitKey, setLimitKey] = useState('');
    const [isLimitManuallySelected, setIsLimitManuallySelected] =
      useState(false);

    const { limitOptions, oddsByLimit } = useLimitOptions(
      odds.filter((o: any) => o.market?.id === market.id && !!o.limit)
    );

    const onLimitOptionSwitch = (
      value: string,
      isManuallySelected?: boolean
    ) => {
      if (isManuallySelected) {
        setIsLimitManuallySelected(true);
      }

      setLimitKey(value || '');
    };

    useEffect(() => {
      if (limitOptions.length) {
        onLimitOptionSwitch(limitOptions[0]);
      }
    }, []);

    useEffect(() => {
      if (
        limitOptions.length &&
        (!limitKey ||
          !limitOptions.includes(limitKey) ||
          (!isLimitManuallySelected && limitKey !== limitOptions[0]))
      ) {
        onLimitOptionSwitch(limitOptions[0]);
      }
    }, [limitOptions]);

    if (
      DUPLICATE_MARKETS.includes(market.id) &&
      data.type === EVENT_TYPE.LIVE
    ) {
      /*
      const filtered = odds.filter(
        (o: any) => o.market?.id === market.id && !!o.limit
      );

      const oddsByLimit = formatOddsByLimit(filtered);

      Object.keys(oddsByLimit).forEach((key: any) => {
        oddsByLimit[key].sort((a: any, b: any) =>
          a.outcome.id > b.outcome.id ? 1 : -1
        );
      });
      */

      Object.keys(oddsByLimit).forEach((limit: any) => {
        const odds = oddsByLimit[limit];

        if (odds.length === 1) {
          if (odds[0].displayName === '>') {
            oddsByLimit[limit] = [null, odds[0]];
          } else {
            oddsByLimit[limit] = [odds[0], null];
          }
        }

        if (oddsByLimit[limit].every((o: any) => !o || !o.visible)) {
          delete oddsByLimit[limit];
        }

        if (oddsByLimit[limit] && oddsByLimit[limit][0]?.outcomeName === '>') {
          const [odd1, odd2] = oddsByLimit[limit];
          oddsByLimit[limit] = [odd2, odd1];
        }
      });

      /*
      const limits = Object.keys(oddsByLimit);
      const isSecond = allMarkets.findIndex((m: any) => m.id === market.id) < i;
      const limitKey = isSecond ? limits[1] : limits[0];
      */

      // TODO: Refactor
      let marketOdds: any = null;
      marketOdds = (outcomes || []).map((outcome: any) => {
        return (odds || []).find(
          (odd: OfferOdd) =>
            odd.outcome?.id === outcome?.id &&
            odd.status === OddStatuses.NOT_RESOLVED &&
            !odd.isDisabled
        );
      });

      const hasFrame = (marketOdds || []).some(
        (o: any) => o?.frameNumber && o?.frameNumber > 0
      );
      const frameNumber = marketOdds[0]?.frameNumber;

      /*
      const sortedOddsByLimits = sortObj(oddsByLimit);

      const { lowestDifferenceKey, targetKey } =
        findLowestDifference(sortedOddsByLimits);

      const sortedByLowestDifference = sortByLowestDifference(
        sortedOddsByLimits,
        lowestDifferenceKey,
        targetKey
      );

      const limitOptions = sortedByLowestDifference
        ? Object.keys(sortedByLowestDifference)
        : [];
      */

      return (
        <Market key={`RenderMarket-Market-1-${name}-${i}`} index={i}>
          {hasFrame && <div className="cell__frame">{frameNumber}</div>}

          {limitOptions.length > 1 ? (
            <Dropdown
              handleSelect={onLimitOptionSwitch}
              options={limitOptions.map((item) => ({
                label: item,
                value: item,
              }))}
              className="market-cell__switcher"
            >
              <div className="market-cell__switcher-trigger">
                <div className="text-ellipsis pr-1">
                  {limitKey ? limitKey : '-'}
                </div>

                <Icon name="caretDown" size="extra-small" />
              </div>
            </Dropdown>
          ) : (
            <div className="cell__limit">{limitKey ? limitKey : '-'}</div>
          )}

          {(oddsByLimit[limitKey] || []).map((odd: any, i: number) => (
            <Odd
              key={`RenderMarket-Odd-1-${data.id}-${i}-${odd?.id}`}
              event={data}
              odd={odd}
            />
          ))}

          {!limitKey &&
            Array.from(Array(2), (e, i) => (
              <div className="cell cell__empty odd-cell">-</div>
            ))}
        </Market>
      );
    }

    let marketOdds: any = null;
    marketOdds = (outcomes || []).map((outcome: any) => {
      return (odds || []).find(
        (odd: OfferOdd) =>
          odd.outcome?.id === outcome?.id &&
          odd.status === OddStatuses.NOT_RESOLVED &&
          !odd.isDisabled
      );
    });

    /*
    const limit = (marketOdds || []).length
      ? (marketOdds[0] || marketOdds[1] || {}).limit
      : '';
    */

    const hasLimit = (outcomes || []).some((o: any) => o?.hasLimit);

    const hasFrame = (marketOdds || []).some(
      (o: any) => o?.frameNumber && o?.frameNumber > 0
    );

    const frameNumber = marketOdds[0]?.frameNumber;

    return (
      <Market key={`RenderMarket-Market-2-${name}-${i}`} index={i}>
        {hasFrame && <div className="cell__frame">{frameNumber}</div>}

        {limitOptions.length > 1 ? (
          <Dropdown
            handleSelect={(value) => onLimitOptionSwitch(value, true)}
            options={limitOptions.map((item) => ({
              label: item,
              value: item,
            }))}
            className="market-cell__switcher"
          >
            <div className="market-cell__switcher-trigger">
              <div className="text-ellipsis pr-1">
                {limitKey ? limitKey : '-'}
              </div>

              <Icon name="caretDown" size="extra-small" />
            </div>
          </Dropdown>
        ) : hasLimit ? (
          <div className="cell__limit">{limitKey ? limitKey : '-'}</div>
        ) : (
          <></>
        )}

        {/* {hasLimit && <div className="cell__limit">{limit ? limit : '-'}</div>} */}

        {/* {(marketOdds || []).map((odd: any, i: number) => ( */}
        {((limitKey ? oddsByLimit[limitKey] : marketOdds) || []).map(
          (odd: any, i: number) => (
            <Odd
              key={`RenderMarket-Odd-2-${data.id}-${i}-${odd?.id}`}
              event={data}
              odd={odd}
            />
          )
        )}
      </Market>
    );
  }
);

const OfferRow = observer(({ data, table, basketballPlayer = false }: any) => {
  const {
    marketSwitcher,
    offerStore: { liveDisabled, disabledSports },
  } = useStores();

  const isPlayer = table.type === EVENT_TYPE.PLAYER;
  const isSpecial = table.type === EVENT_TYPE.SPECIAL;
  const isAntepost = table.type === EVENT_TYPE.ANTEPOST;

  useEffect(() => {
    data.initOdds();
  }, [data]);

  let markets = marketSwitcher.getFromDesktopSelection(
    table.sportId, //maybe this messes up with antepost markets
    table.type
  );

  if (isAntepost) {
    markets = filterAntepostOutcomes(markets || [], table.events);
  } else if (isSpecial || (isPlayer && !basketballPlayer)) {
    markets = formatMarkets(data.odds);
    // first sort affects the outcomes of football players
    markets.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
    // second sort affects limits for basketball ans specials, so "less than" is printed first and "greather than"
    markets.forEach((market: any) => {
      market.odds.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
    });
  }

  const disableClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const isSuspended = () => {
    const { sportId, disabled, type, suspended } = data;

    if (type === EVENT_TYPE.LIVE) {
      return (
        disabled ||
        liveDisabled ||
        (disabledSports || []).includes(sportId) ||
        suspended
      );
    }

    return disabled;
  };

  const renderFootballMarket = ({ odds }: any) => {
    const oddsByLimit = formatOddsByLimit(odds);
    const limits = Object.keys(oddsByLimit);

    return (limits || []).map((limit: any, index: number) => (
      <Fragment key={`renderFootballMarket-Fragment-${index}`}>
        <div
          className={classNames('d-flex', {
            'w-50': marketSwitcher.desktopMarketsNumber === 2,
          })}
          style={{ minWidth: '33.3%' }}
        >
          <Market
            key={`renderFootballMarket-Market-1-${limit}-${index}`}
            index={2}
          >
            {limit > 0 && (
              <div className="cell__limit">{limit ? limit : '-'}</div>
            )}
            {(
              oddsByLimit[limit]?.slice(0, oddsByLimit[limit]?.length) || []
            ).map(
              (odd: any, i: number) =>
                odd.value > 0 && (
                  <Odd
                    key={`renderFootballMarket-Odd-${data.id}-${i}-${odd?.id}`}
                    event={data}
                    odd={odd}
                  />
                )
            )}
          </Market>
        </div>

        {!oddsByLimit[limit][0].isDisabled &&
          oddsByLimit[limit][0].event.sportName !== 'fudbal' &&
          Array.from(
            Array(marketSwitcher.desktopMarketsNumber - limits.length),
            (e, i) => (
              <div
                className={classNames('d-flex', {
                  'w-50': marketSwitcher.desktopMarketsNumber === 2,
                })}
                style={{ minWidth: '33.3%' }}
              >
                <Market
                  key={`renderFootballMarket-Market-2-${e}-${i}`}
                  index={4}
                >
                  {Array.from(Array(3), (q, w) => (
                    <div className="cell cell__empty odd-cell">-</div>
                  ))}
                </Market>
              </div>
            )
          )}
      </Fragment>
    ));
  };

  const renderSpecialMarket = ({ odds }: any) => {
    const oddsByLimit = formatOddsByLimit(odds);

    Object.keys(oddsByLimit).forEach((key) => {
      oddsByLimit[key].sort((a: any, b: any) => a.outcome?.id - b.outcome?.id);
    });

    const limits = Object.keys(oddsByLimit).slice(0, 1);

    return (limits || []).map((limit: any, index: number) => (
      <div
        key={`renderSpecialMarket-div-${index}`}
        className={classNames('d-flex w-100')}
      >
        <Market key={`renderSpecialMarket-Market-${limit}-${index}`} index={2}>
          {limit > 0 && (
            <div className="cell__limit">{limit ? limit : '-'}</div>
          )}

          {(oddsByLimit[limit]?.slice(0, oddsByLimit[limit]?.length) || []).map(
            (odd: any, i: number) =>
              odd.value > 0 && (
                <Odd
                  key={`renderSpecialMarket-Odd-${data.id}-${i}-${odd?.id}`}
                  event={data}
                  odd={odd}
                />
              )
          )}
        </Market>
      </div>
    ));
  };

  const isRegularMarkets =
    (basketballPlayer && isPlayer && !isPlayer) ||
    (markets && isPlayer && basketballPlayer) ||
    (!isPlayer && !isSpecial);
  const isFootballPlayer = isPlayer && !basketballPlayer && !isSpecial;

  return (
    <div
      key={`OfferRow-div-${data.id}`}
      className={classNames('row', { row__disabled: data.disabled })}
    >
      {isSuspended() && (
        <div onClick={disableClick} className="suspended-mask"></div>
      )}
      <div className="event" style={{ minWidth: '300px', height: '50px' }}>
        <EventCell isSuspended={isSuspended()} value={{ event: data || [] }} />
      </div>

      {isRegularMarkets &&
        markets &&
        (markets || []).map((market: any, i: number) => (
          <RenderMarket
            data={data}
            market={market}
            i={i}
            /* 
            limitKey={limitKey}
            setLimitKey={setLimitKey}
            allMarkets={markets}
            */
          />
        ))}

      {isSpecial && (
        <div
          className="d-flex w-100 ml-4 overflow-hidden"
          style={{ marginLeft: '4px' }}
        >
          {(markets.slice(0, marketSwitcher.desktopMarketsNumber) || []).map(
            renderSpecialMarket
          )}
        </div>
      )}

      {isFootballPlayer && (
        <div
          className="d-flex w-100 ml-4 overflow-hidden"
          style={{ marginLeft: '4px' }}
        >
          {markets &&
            (markets.slice(0, marketSwitcher.desktopMarketsNumber) || []).map(
              renderFootballMarket
            )}
        </div>
      )}
    </div>
  );
});

export default OfferRow;
