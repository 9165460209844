const it = {
  translation: {
    casino: {
      'all-games': 'TUTTI I GIOCHI',
      award: 'Vincita',
      back: 'INDIETRO',
      'biggest-wins': "VINCITE PIU' GRANDI",
      'casino-brands': 'I BREND DEL CASINÒ',
      categories: 'CATEGORIE',
      category: 'CATEGORIA',
      close: 'CHIUDI',
      demo: 'PROVA',
      'favourite-games': 'GIOCHI PREFERITI',
      'game-of-week': 'GIOCO DELLA SETTIMANA',
      'last-played': 'ULTIMA GIOCATA',
      'most-played': 'IL PIÙ GIOCATO',
      play: 'GIOCA',
      points: 'Punti',
      provider: 'BREND',
      sections: 'SEZIONI',
      'see-all': 'VEDI TUTTO',
      'use-free-casino': 'Usa i giri gratuiti',
      user: 'Utente',
    },
    countries: {
      albania: 'Albania',
      'bosnia-and-herzegovina': 'Bosnia  ed Erzegovina',
      croatia: 'Croazia',
      kosovo: 'Kosovo',
      montenegro: 'Montenegro',
      'republic-of-north-macedonia': 'Macedonia del Nord',
      russia: 'Russia',
      serbia: 'Serbia',
      turkey: 'Turchia',
      ukraine: 'Ucraina',
    },
    errorPage: {
      'reload-page': 'Aggiorna la pagina.',
      'something-went-wrong': "E' successo un errore",
    },
    errors: {
      'activation-link-exp':
        'Per completare la registrazione è necessario attivare il proprio account. Il link di attivazione è stato inviato al tuo indirizzo email.',
      'another-order-exists': 'Esiste già un altro ordine',
      'bad-password': 'password errata',
      'bet-not-possible':
        'Al momento non è possibile scommettere su questo evento',
      'betting-blocked': 'Le scommesse sono bloccate.',
      'can-not-find-user': "Non e' possibile trovare l'utente",
      'cancel-funds': 'Il pagamento pre-autorizzato non è stato effettuato.',
      'cannot-add-more-than-one-digital-item':
        'Non puoi aggiungere più di un prodotto digitale al carrello',
      'cannot-buy-casino-bonus-if-you-has-one':
        'Non puoi acquistare un bonus del casinò se ne possiedi già uno esistente',
      'cannot-cancel-order': 'Non è possibile annullare un ordine',
      'cannot-order-mixed-items':
        'Non è possibile ordinare tipologie diverse di prodotti',
      'cannot-order-more-than-one-digital-item':
        'Non puoi ordinare più di un prodotto digitale',
      'choose-betting-place': 'Seleziona un luogo di ritiro',
      'connection-offline': 'Connessione Internet interrotta!',
      'credentials-error': 'Nome utente o password non inseriti correttamente.',
      'data-error-reading':
        'Errore durante il salvataggio dei dati. Per favore riprova.',
      'email-exists': 'Esiste già un utente con questo indirizzo email.',
      'email-invalid': "L'e-mail non è valida",
      'err-cash-drop-already-collected':
        'Qualcuno è stato più veloce di te. CashDrop è stato utilizzato e non è più disponibile.',
      'err-cash-drop-already-collected-by-user':
        'CashDrop è già stato ritirato',
      'err-cash-drop-group-already-collected-by-user':
        'Non puoi ritirare CashDrop da questo gruppo',
      'err-cash-drop-not-active': 'CashDrop è scaduto e non è più disponibile.',
      'err-cash-drop-not-found': 'CashDrop non trovato',
      'err-cash-drop-user-min-balance':
        'Devi avere almeno {{a}} EUR per ritirare il cash drop',
      'err-cash-drop-user-min-bet':
        'Devi spendere almeno {{a}} EUR nelle ultime {{b}} ore per poter richiedere CashDrop',
      ERR_BETTING_MACHINE_BLOCKED: 'Questa azione è stata bloccata',
      ERR_CANT_PAYOUT_BETTING_PLACE:
        'Il voucher non può essere riscattato presso questo punto di pagamento',
      ERR_CANT_PAYOUT_MUNICIPALITY:
        'Il voucher non può essere riscattato in questo comune',
      ERR_CASINO_BLOCKED: 'I giochi da casinò sono bloccati',
      ERR_DEPOSIT_BLOCKED: "Il pagamento dell'utente è stato bloccato",
      ERR_DEPOSIT_BLOCKED_FOR_BETTING_MACHINE:
        'Il pagamento al bancomat è bloccato',
      ERR_INVALID_GAME: 'Il gioco non è valido',
      ERR_TIME_LIMIT_EXCEEDED_FOR_PAYOUT:
        "La scheda non è più valida per il pagamento. Si prega di rivolgersi all'operatore. ",
      ERR_USER_BLOCKED_ALREADY: 'Il tuo account è stato bloccato.',
      ERR_USER_NOT_VALIDATED: "L'utente non è verificato",
      ERR_VIRTUAL_GAMES_BLOCKED: 'I giochi virtuali sono bloccati',
      ERR_VOUCHER_CONFIG_NOT_FOUND: 'La configurazione del voucher non esiste',
      ERR_VOUCHER_EXPIRED: 'Il voucher è scaduto',
      ERR_VOUCHER_NOT_FOUND: 'Il voucher non esiste',
      ERR_VOUCHER_STATUS_NOT_VALID_FOR_PAYOUT:
        'Il voucher non è valido per il pagamento',
      ERR_STREAM_NOT_AVAILABLE:
        'Lo streaming live non è attualmente disponibile',
      ERR_STREAM_TYPE_NOT_AVAILABLE:
        'Questo tipo di live streaming non è supportato',
      ERR_LOCATION_NOT_SUPPORTED:
        'Lo streaming live non è supportato nella tua posizione',
      ERR_IP_NOT_ALLOWED:
        'Lo streaming live non è consentito per il tuo indirizzo IP',
      ERR_STREAM_NOT_ALLOWED: 'Lo streaming in diretta non è consentito.',
      ERR_STREAM_NOT_ALLOWED_FOR_UNVALIDATED_USER:
        'Lo streaming live non è consentito agli utenti non verificati',
      ERR_MINIMUM_STREAMING_BALANCE:
        'Lo streaming live è disponibile se hai un minimo di {{errorArgument}} EUR sul tuo account',
      'error-starting-game': 'Non è possibile avviare il gioco',
      'error-unknown': 'Si è verificato un errore. Per favore, riprova.',
      'field-invalid': "L'immissione non è valida",
      'field-required': 'Questo campo è obbligatorio',
      'fields-obligated': 'I campi contrassegnati con * sono obbligatori.',
      'invalid-system-slip': 'Il biglietto di sistema non è valido',
      'invalid-user': "L'utente non è valido",
      'item-not-available': 'Il prodotto non è disponibile',
      'item-not-found': 'Prodotto non trovato',
      'jmbg-max': 'JMBG non può contenere più di 13 caratteri.',
      'jmbg-min': 'JMBG deve contenere almeno 13 caratteri.',
      'min-password': 'La password deve contenere un minimo di 6 caratteri.',
      'no-betting-place-id': 'Seleziona una filiale',
      'no-events-for-copy': 'Non ci sono eventi che possono essere copiati',
      'no-items-in-order': "Non ci sono prodotti nell'ordine",
      'not-enough-points': 'Non hai abbastanza punti',
      'order-does-not-exist': "L'ordine non esiste",
      'passwords-dont-match': 'La password non corrisponde.',
      'payout-error':
        'Si è verificato un errore durante il tentativo di effettuare un pagamento.',
      'place-bet-error':
        'Errore nel pagamento del biglietto. Per favore riprova.',
      'poker-not-responding':
        'Il server del poker non è attualmente disponibile',
      'poker-success-false':
        "L'azione del server del poker non ha avuto successo",
      'print-error':
        "Si è verificato un errore durante il tentativo di stampa. Se il biglietto non viene stampato, contattare l'operatore.",
      'print-not-operational': 'LA STAMPANTE NON FUNZIONA',
      'promo-code-invalid': 'Il codice promozionale non è valido',
      'reservation-locked': 'I fondi riservati sono bloccati',
      'session-expired':
        "La tua sessione è scaduta, effettua nuovamente l'accesso.",
      'system-error':
        'Si è verificato un errore di sistema. Per favore riprova.',
      TEST_USER_CANNOT_DEPOSIT_MONEY_WITH_CARD:
        "L'utente di prova non può depositare denaro con la carta.",
      TEST_USERS_CANNOT_WITHDRAW_MONEY:
        'Gli utenti di prova non possono prelevare denaro.',
      'ticket-changed': 'Il tuo biglietto è stato modificato.',
      'ticket-denied': 'Il tuo biglietto è stato rifiutato.',
      'ticket-error-reading': 'Errore nella lettura del biglietto.',
      TICKET_NOT_FOUND: 'Biglietto non trovato',
      TICKET_NOT_VALID_FOR_PAYOUT:
        "La scheda non è più valida per il pagamento. Si prega di rivolgersi all'operatore.",
      'token-expired': 'Il token è scaduto',
      'user-blocked-error':
        'Il tuo account è stato bloccato. Contatta il supporto.',
      'user-exists': "L'utente esiste già.",
      'user-not-active':
        "L'utente non è attivato. Per favore attiva il tuo account.",
      'user-update-error':
        'Errore durante il salvataggio dei dati. Per favore riprova.',
      'username-exists': 'Esiste già un utente con questo nome utente.',
      VALIDATION_CANNOT_MIX_LANDBASE_CODES:
        'Gli eventi antepost non sono cumulabili sullo stesso biglietto',
      'withdrawal-canceled-or-paid':
        'Pagamento annullato o pagato. Aggiorna la pagina.',
      'withdrawal-max-amount-exceeded':
        'Il pagamento massimo consentito è di {{a}}€',
      'withdrawal-min-amount-exceeded':
        'Il pagamento minimo consentito è di {{a}}€',
      'zero-item-quantity': 'Non ci sono prodotti in magazzino',
    },
    footer: {
      'about-us': 'Chi siamo',
      'account-id': 'ACCOUNT ID',
      'all-rights-reserved': 'Tutti i diritti riservati',
      'available-for-payment': 'Disponibile per il pagamento',
      aviator: 'Aviatore',
      betting: 'Scommesse',
      bonuses: 'Bonus',
      casino: 'Casinò',
      'casino-live': 'Casinò dal vivo',
      contact: 'Contatto',
      cookies: 'Biscotti (cookies)',
      description:
        "SBbet organizza giochi d'azzardo dal 1997 e offre ai propri clienti servizi di altissimo livello. Con una rete di oltre 100 punti di pagamento in Montenegro, siamo posizionati come leader di mercato, il che ci motiva ulteriormente a indirizzare la nostra attività verso la creazione di intrattenimento di livello mondiale nel campo dei giochi d'azzardo. Il sito SBbet è progettato per permetterti di scommettere con la migliore esperienza utente. Una vasta gamma di sport e competizioni, quote interessanti, bonus, scommesse gratuite e la possibilità di vincere grandi somme di denaro attraverso i nostri jackpot sono solo alcuni dei motivi per fidarsi del più antico bookmaker del Montenegro, con oltre 20 anni di esperienza. Tenta la fortuna in uno dei tanti giochi da casinò, nel nostro bingo, in eventi sportivi virtuali o ad un vero tavolo da casinò. SBbet.me fa parte di Sporting Group doo, Mediteranska 21, Budva, Montenegro ed è regolato dalle leggi del Montenegro ed è titolare della licenza rilasciata dall'Amministrazione per i giochi d'azzardo: 02/01-702/4. Il team di SBbet ti augura buona fortuna - Certo...",
      'download-list': "Scarica l'elenco",
      'follow-us': 'Seguici',
      'frequently-questions': 'Domande frequenti',
      'game-rules': 'Le regole del gioco',
      informations: 'Informazione',
      'lucky-6': 'Lucky 6',
      'pay-all': 'PAGHI TUTTO',
      'payment-to-web': 'Pagamento sul WEB',
      'play-responsibly': 'Gioca in modo responsabile',
      poker: 'Poker',
      privacy: 'Privacy',
      'promo-code': 'Codice promozionale',
      promotions: 'Promozioni',
      'site-map': 'Mappa del sito',
      sport: 'Gli sport',
      'ticket-check': 'Controllo dei biglietti',
      transactions: 'Transazioni',
      'using-conditions': 'Termini di utilizzo',
    },
    globals: {
      accept: 'ACCETTA',
      'accept-cookies':
        "Questo sito utilizza i cookie per migliorare i servizi, per mostrarti contenuti pertinenti. Se continui a navigare nel sito consideriamo che accetti l'uso dei cookie.",
      account: 'Account',
      'account-type': 'Tipo di account',
      activation: 'ATTIVAZIONE',
      'add-to-cart': 'Aggiungi al carrello',
      all: 'Tutto',
      'amount-caps': 'IMPORTO',
      'app-idle-msg':
        "Non sei attivo da un po' di tempo. Fare clic su Aggiorna per un'offerta aggiornata.",
      approved: 'Approvato',
      'betting-history': 'Storia delle scommesse',
      'booking-an-order': "Prenotazione dell'ordine",
      braon: 'Marrone',
      cancel: 'RINUNCIA',
      canceled: 'Respinto',
      cart: 'Cestino',
      'cart-is-empty': 'Il cestino è vuoto',
      Cet: 'Giovedi',
      'choose-a-betting-place': 'Seleziona una filiale',
      'choose-a-city': 'Seleziona una città',
      'choose-order-place':
        'Scegli il luogo di consegna nella filiale SBbet selezionata',
      'click-for-more-info': 'Fare clic per ulteriori informazioni',
      close: 'CHIUDI',
      'code-caps': 'CODICE',
      collect: 'Raccolta',
      copy: 'Coppia',
      'copy-ticket': 'Copia il biglietto',
      'customer-support': 'Servizio Clienti',
      date: 'Data',
      'date-caps': 'DATA',
      delivered: 'Consegnato',
      'filter-by-date': 'Filtra per data',
      free: 'Gratuito',
      'id-caps': 'ID',
      immediately: 'Subito',
      'installation-guide': "Istruzioni per l'installazione",
      loading: 'Caricamento...',
      'mark-all-as-read': 'Segna tutto come letto',
      'my-account': 'Il mio account',
      'necessary-reload': "È necessario sincronizzare l'offerta",
      Ned: 'Domenica',
      nepar: 'Dispari',
      no: 'NON',
      'no-info-about-event': "Non ci sono informazioni sull'evento",
      notifications: 'Notifiche',
      numbers: 'NUMERI',
      'order-history': 'Cronologia ordini',
      par: 'Pari',
      pending: 'In attesa',
      Pet: 'Venerdi',
      'pick-date': 'Scegli una data',
      'place-order': 'Ordina prodotti',
      Pon: 'Linedi',
      proceed: 'ricaricare',
      profile: 'Profilo',
      'promo-code': 'Codice promozionale',
      register: 'REGISTRAZIONE',
      'remove-from-cart': 'Togli dal cestino',
      save: 'Salvare',
      'sbbet-promo-code': 'CODICE PROMOZIONALE SBBet',
      search: 'Ricerca',
      'search-1': 'Ricerca',
      'search-events': 'Cerca eventi',
      send: 'INOLTRARE',
      'send-1': 'INVIA',
      Sre: 'Mercoledi',
      statistics: 'STATISTICA',
      'status-caps': 'STATO',
      step: 'Un passo',
      Sub: 'Sabato',
      submit: 'CONFERMARE',
      success: 'Scaricato',
      'successfully-collected-cash-drop':
        'Hai ritirato con successo una consegna di cash drop',
      'ticket-copied': 'Biglietto copiato',
      time: 'Tempo',
      'transaction-history': 'Cronologia delle transazioni',
      type: 'Tipo',
      'type-caps': 'TIPO',
      'user-id-caps': 'ID UTENTE',
      Uto: 'Martedi',
      wallets: 'Conti',
      welcome: 'Benvenuto!',
      yes: 'SÌ',
      zelena: 'Verde',
      žuta: 'Giallo',
    },
    header: {
      betting: 'Scommesse',
      casino: "Casino'",
      'casino-live': "Casino' in vivo",
      live: 'In vivo',
      'quick-games': 'Giochi veloci',
    },
    home: {
      '100-spins': '100 GIRI',
      'be-in-the-game-with-app': "PARTECIPA AL GIOCO CON L'APP SBBET ",
      betting: 'SCOMMESSE',
      'betting-description':
        "Ti aspettano le quote più alte e l'offerta più diversificata di giochi e offerte speciali.",
      'call-center': 'CENTRO CHIAMATE',
      'casino-description':
        'I giochi da casinò più popolari portano grandi jackpot',
      'follow-us': 'SEGUICI',
      'live-betting': 'SCOMMESSE LIVE',
      'live-betting-description':
        'Le scommesse live ora sono due volte più vantaggiose, perché su SBbet ti aspettano la più ampia selezione di sport dal vivo e una vasta gamma di giochi!',
      'place-bet': 'FAI SCOMMESSE',
      'promotions-description': 'Scopri tutto sulle nuove promozioni e novità.',
      'quick-games-description':
        'La più vasta selezione di virtuali: numeri, calcio, corse e molto altro!',
      register: 'Iscriviti',
      'up-to-250': 'FINO AL 250',
      'we-double-your-first-deposit': 'RADDOPPIIAMO IL TUO PRIMO DEPOSITO',
    },
    language: {
      english: 'Inglese',
      language: 'Lingua',
      montenegrin: 'Montenegrino',
      'select-language': 'Scegli una lingua',
    },
    login: {
      'forgot-password': 'Hai dimenticato la password?',
      'forgot-password-caps': 'HAI DIMENTICATO LA PASSWORD?',
      'log-in': 'LOG IN',
      'log-out': 'Disconnettersi',
      login: 'REGISTRAZIONE',
      logout: 'DISCONNETTERSI',
      'logout-1': 'DISCONNESSIONE',
      'logout-question': "Sei sicuro che vuoi annullare l'iscrizione?",
      'sign-in': 'ISCRIZIONE',
    },
    navigation: {
      aviator: 'AVIATORE',
      betting: 'SCOMMESSE',
      casino: 'CASINÒ',
      liveCasino: 'CASINÒ DAL VIVO',
      lucky6: 'LUCKY 6',
      numbers: 'NUMERI',
      offer: "UN'OFFERTA",
      poker: 'POKER',
      promotions: 'PROMOZIONI',
      quickGames: 'GIOCHI VELOCI',
      sport: 'GLI SPORT',
    },
    payments: {
      'account-id-confirm': 'Verificate che il vostro ACCOUNT ID',
      amount: 'Quantità',
      'available-for-payment': 'Disponibile per il pagamento',
      balance: 'Bilancio',
      'balance-after': 'Importo dopo',
      'balance-before': 'Importo prima',
      'bonus-balance': 'Saldo bonus',
      'cancel-withdrawal': 'Annulla il pagamento',
      'cancel-withdrawal-question':
        'Sei sicuro di voler annullare il pagamento?',
      'cancel-withdrawal-success': 'Hai annullato con successo il pagamento.',
      'card-expired': 'La transazione è stata rifiutata. La carta è scaduta.',
      'card-not-found': 'Carta non trovata. Per favore riprova.',
      'card-suspended':
        'La transazione è stata rifiutata. La carta è stata sospesa.',
      commission: 'Commissione',
      credit: 'Pagamento',
      'credit-card': 'Carta di credito',
      debit: 'Pagamento',
      'e-wallet': 'Portafoglio elettronico',
      'funds-available-for-payment': 'Fondi disponibili per il pagamento',
      'funds-that-can-be-raised': 'FONDI CHE POSSONO ESSERE PRELEVATI',
      'in-the-bank': 'NELLA FILIALE',
      'in-the-bank-note':
        'Se il tuo Nome e Cognome non sono corretti, contatta il Supporto',
      'in-the-office': "NEL LIBRO D'AFFARI",
      'invalid-amount':
        "La transazione è stata rifiutata. L'importo non è valido.",
      'invalid-card-number':
        'La transazione è stata rifiutata. Il numero della carta non è valido.',
      'maximum-credit': 'Pagamento massimo',
      'maximum-debit': 'Versamento massimo',
      'minimum-credit': 'Versamento minimo',
      'minimum-debit': 'Pagamento minimo',
      pay: 'Esegui il pagamento',
      'payment-default-error':
        'La transazione è stata rifiutata. Per favore riprova.',
      'payment-destination': 'Destinazione del pagamento',
      'payment-success': 'Il pagamento è stato effettuato con successo.',
      'physical-payment': 'Pagamento fisico',
      'poker-withdrawal-success':
        'Il pagamento è stato effettuato con successo.',
      'potential-payout': 'Pagamento potenziale:',
      'proceed-with-payment': 'Continua il pagamento cliccando sul pulsante',
      skrill: 'Skrill',
      tabaccopress: {
        'deposit-step-1':
          'Chiedi al venditore in uno dei punti vendita Tobacco S Press di effettuare un deposito sul tuo conto Sbbet tramite terminali di pagamento',
        'deposit-step-2':
          "È necessario comunicare al venditore il numero ID del tuo conto SBBet e l'importo che desideri depositare.",
        'deposit-step-3':
          "L'importo pagato sarà disponibile sul tuo conto SBBet, solitamente entro pochi minuti dal momento del pagamento.",
        'deposit-steps':
          'Come pagare un deposito tramite terminali di pagamento?',
        'instruction-1':
          'Per il pagamento tramite terminali di pagamento è richiesto solo il numero utente del tuo conto SBbet (numero ID).',
        'instruction-2':
          'Puoi effettuare il pagamento tramite terminali di pagamento presso uno degli oltre 300 punti vendita di Tobacco S Press',
        'instruction-3':
          "L'importo minimo del pagamento tramite terminali di pagamento è di EUR 1,00 e l'importo massimo del pagamento per transazione è di EUR 500,00.",
        'instruction-4':
          'In caso di errore, il tempo limite per stornare la transazione presso il punto vendita è di 10 minuti (se il denaro del conto non è stato speso).',
        'instruction-5':
          ' Le strutture TABACCO SPRESS dove potrai versare il deposito sono disponibili al seguente link.',
      },
      terminals: {
        note: 'NOTA: se per qualche motivo i fondi non vengono versati sul tuo conto SBBet, contatta il servizio J&A allo 067/788-588 dalle 9:00 alle 17:00, dalle 17:00 alle 21:00 e nei fine settimana al numero 068/488-588.',
        'step-1': 'Sul terminale J&A, seleziona il logo SBbet',
        'step-2':
          'Inserisci il tuo ID account utente e fai clic sul pulsante "PAGA".',
        'step-3':
          'Inserisci la somma di denaro desiderata nel terminale e il dispositivo leggerà e visualizzerà quanti soldi hai inserito.',
        'step-4':
          'Facendo clic sul pulsante "CONFERMA", effettuerai correttamente il pagamento e riceverai una ricevuta stampata per la transazione.',
      },
      'transaction-date': 'Data della transazione',
      'transaction-deposit': 'Pagamento',
      'transaction-id': 'ID della transazione',
      'transaction-type': 'Tipo di transazione',
      'transaction-web-bet': 'Scommessa sul web',
      'transaction-web-bet-rollback': 'Scommessa Web rollback',
      'transaction-web-casino-credit': 'Credito del casinò',
      'transaction-web-casino-debit': 'Casinò adebito',
      'transaction-web-casino-rollback': 'Casinò rollback',
      'transaction-web-game-bet': 'Scommessa sul gioco web',
      'transaction-web-game-rollback': 'Gioco Web rollback',
      'transaction-web-game-win': 'Vittoria del gioco web',
      'transaction-web-win': 'Vittoria sul web',
      'transaction-withdraw': 'Pagamento',
      transactions: 'Transazioni',
      withdraw: 'PAGA',
      'withdraw-on-the-bank': 'PAGAMENTO TRAMITE BANCA',
      'withdraw-on-the-betting-place': 'PAGAMENTO IN FILIALE',
      'withdrawal-pending': 'In approvazione',
      'withdrawal-ready': 'Pronto per il prelevamento',
      'withdrawal-reserved': 'Pagamento programmato',
      'withdrawal-success': 'Hai pianificato con successo il pagamento.',
      'your-transaction-has-been-confirmed':
        'La tua transazione è stata confermata',
    },
    promoBanner: {
      aviator: 'Aviatore',
      betting: 'Scommesse',
      blackjack: 'Blackjack',
      cashout: 'Incassare',
      'casino-tournament': 'Torneo del casinò',
      'defeat-goalkeeper-for-bonuses':
        'Sconfiggi il portiere per ottenere BONUS',
      'double-first-payin': 'Raddoppiamo ogni primo deposito',
      'download-app': "Scarica l'applicazione",
      'fly-to-win': 'Vola verso la vittoria',
      'happy-monday-bonus': 'Happy Monday Bonus',
      'lucky-6': 'Lucky 6',
      'netent-casino-games': 'Giochi da casinò NetEnt',
      new: 'Nuovo',
      'pay-the-ticket-at-any-time': 'Incassa il biglietto in qualsiasi momento',
      popular: 'Popolare',
      promotion: 'Promozione',
      roulette: 'Roulette',
      'win-5000-jackpot': 'Vinci un jackpot di 5.000 euro',
      'win-apartment': 'Vinci un appartamento a Podgorica',
      'win-iphone-13': 'Vinci un iPhone 13',
      'win-jackpot-up-to-5000': 'Vinci un jackpot fino a 5000 euro',
    },
    slips: {
      'accept-all-changes': 'Acceto tutte le modifiche',
      'accept-cashout': "ACCETTA L'INCASSO",
      'accept-changes': 'Accetta le modifiche',
      'accept-changes-after-place-bet':
        'Accetta le modifiche dopo il pagamento',
      active: 'Attivo',
      'actual-winning-amount': 'Incasso',
      'all-tickets': 'TUTTI I BIGLIETTI',
      approving: 'In attesa di approvazione',
      bet: 'Scommessa',
      'bet-type': 'Tipo della scomessa',
      bonus: 'Bonus:',
      'cancel-cashout': 'Annulla il prelievo',
      cashout: 'INCASSARE',
      'cashout-accepted': 'Incasso accettato',
      'cashout-denied': 'Hai rifiutato con successo il prelievo',
      'cashout-denied-timer':
        "Il prelievo è stato rifiutato a causa del timeout per l'accettazione",
      changed: 'Cambiato',
      'confirm-ticket': 'CONFERMA BIGLIETTO',
      'delete-all': 'Elimina tutto',
      'delete-ticket': 'Elimina il biglietto',
      'delete-ticket-info': 'Vuoi cancellare questo biglietto?',
      denied: 'Respinto',
      'deny-cashout': "RIFIUTARE L'INCASSO",
      'erase-multiple-odds':
        'Questa azione eliminerà più caratteri, lasciando solo il primo carattere in ogni partita.',
      failed: 'Perduto',
      fix: 'Fix',
      'group-number': 'Gruppo n.',
      id: 'Codice',
      'max-pot-payout': 'Massimo pagamento potenziale',
      'max-pot-win': 'Massima vincita',
      'max-potential-bonus': 'Massimo bonus potenziale',
      'min-max-bonus': 'Bonus minimo/massimo',
      'min-max-odd': 'Quota minima/massima',
      'min-max-pot-win': 'Vincita potenziale minima/massima',
      'min-pot-win': 'Vincita minima ',
      'min-potential-bonus': 'Bonus minimo potenziale',
      'min-winning-total': 'Pagamento minimo potenziale',
      multiplier: 'Quota',
      'my-tickets': 'I MIEI BIGLIETTI',
      'no-slip-events': 'Non ci sono eventi nel slip',
      'no-tickets': "Non c'è nessun biglietto.",
      'number-of-combinations': 'NO. combinazione:',
      'number-of-matches': 'Numero di partite:',
      'number-of-pairs': 'NO. partite:',
      'number-of-tickets': 'Numero del biglietto:',
      odd: 'Quota:',
      paid: 'Pagato',
      passed: 'Vincente',
      payin: 'Pagamento:',
      'payin-time': 'Orario del deposito:',
      'payment-time': 'Orario del pagamento',
      payout: 'Pagamento:',
      'place-bet': 'Paga il biglietto',
      'possible-winning': 'Guadagno possibile',
      'pot-winning-amount': 'Pagamento potenziale',
      'pot-winning-amount-short': 'Pagamento pot.',
      'potential-bonus': 'Un potenziale bonus',
      'potential-gain': 'Vincita potenziale',
      'quick-ticket-code': 'CODICE BIGLIETTO VELOCE',
      'quick-ticket-live':
        'Non è possibile giocare un ticket rapido con le partite in diretta',
      'quick-ticket-pay':
        'Puoi pagare il biglietto rapido in qualsiasi punto pagamento SBBet con il codice:',
      'quick-ticket-terms':
        'Le condizioni e le eventuali restrizioni vengono verificate presso i nostri punti pagamento al momento del pagamento del biglietto.',
      'quick-tickets': 'Biglietti veloci',
      regular: 'ORDINARIO',
      'regular-ticket': 'BIGLIETTO ORDINARIO',
      'remove-match': 'Rimuovi la partita',
      'remove-odd': 'Rimuovi quota',
      round: 'Giro',
      'save-games': 'Salva le partite',
      'share-ticket-success': 'Link per la scheda copiato correttamente',
      'sign-in-status': 'Accedi per vedere lo stato del biglietto.',
      'slip-not-eligible-for-cashout':
        'Il biglietto non è soggetto ad incasso.',
      'slip-payout': 'Pagamento del biglietto',
      stake: 'Scomessa',
      'stake-per-combination': 'Pagamento per combinazione:',
      status: 'Status',
      'status-capitalize': 'Status:',
      'switch-ticket': 'Vuoi convertire il biglietto in normale?',
      system: 'SISTEMA',
      'system-bonus-calculation':
        'Il calcolo del bonus sul ticket di sistema verrà effettuato dopo la realizzazione di tutti gli eventi.',
      ticket: 'BIGLIETTO',
      'ticket-date': 'Data del biglietto',
      'ticket-details': 'DETTAGLI DEL BIGLIETTO',
      'ticket-id': 'Identificativo del biglietto',
      'ticket-id-search': 'ID biglietto:',
      'ticket-overview': 'Visione dei biglietti',
      'ticket-status': 'Stato del biglietto',
      'ticket-type': 'Tipo del biglietto',
      tickets: 'BIGLIETTI',
      'top-tickets': 'I MIGLIORI BIGLIETTI',
      'total-odd': 'Quota totale:',
      unconfirmed: 'Non confermato',
      win: 'Vincita:',
      'winning-amount-caps': 'VINCITA',
      'ticket-is-grouped': 'Il biglietto è raggruppato',
    },
    sports: {
      'americki fudbal': 'Football americano',
      atletika: 'Atletica',
      badminton: 'Badminton',
      bejzbol: 'Baseball',
      biciklizam: 'Ciclismo',
      boks: 'Pugilato',
      efudbal: 'eFootball',
      esport: 'eSport',
      evrovizija: 'Eurovisione',
      fudbal: 'Calcio',
      futsal: 'Calcio a 5',
      golf: 'Golf',
      hokej: 'Hockey',
      košarka: 'Pallacanestro',
      mma: 'MMA',
      odbojka: 'Pallavolo',
      olimpijada: 'Olimpiade',
      oskar: 'Oscar',
      pikado: 'Freccette',
      ragbi: 'Rugby',
      'ragbi liga': 'Lega di rugby',
      rukomet: 'Pallamano',
      snuker: 'Biliardo',
      'stoni tenis': 'Ping-pong',
      tenis: 'Tennis',
      trke: 'Corsa',
      'trke konja': 'Corsa di cavalli',
      vaterpolo: 'Pallanuoto',
      'virtuelni fudbal': 'Calcio virtuale',
      'zimski sportovi': 'Sport invernali',
    },
    sportsPage: {
      '1d': '1 giorno',
      '1h': '1 ora',
      '3d': '3d',
      '3h': '3 ore',
      all: 'TUTTO',
      antepost: 'ANTEPOSTO',
      competition: 'Lega',
      'configure-offer': "Configura l'offerta",
      event: "L'evento",
      limit: 'GR',
      live: 'IN VIVO',
      'live-match-tracker': 'Segui la partita in diretta',
      'no-events': 'Non ci sono eventi',
      'no-odds': 'Al momento non ci sono offerte',
      odds: 'QUOTE',
      player: 'GIOCATORE',
      'sort-by-competition': 'Per campionati',
      'sort-by-time': 'Su orario',
      special: 'SPECIALI',
      upcoming: 'PROSSIMO',
    },
    userData: {
      zip_code: 'Cap',
      address: 'Indirizzo',
      adress: 'Indirizzo',
      'bank-account-number': 'Numero di conto corrente',
      'bank-name': 'Nome della banca',
      'betting-win-loss': 'Betting win/loss',
      'casino-and-virtuals-win-loss': 'Casinò e virtuali win/loss',
      'change-password': 'Cambiare la password',
      'changes-active-in-24-hrs':
        'Le tue modifiche saranno attive entro 24 ore',
      city: 'Città',
      code: 'Il codice',
      'confirm-password': 'Conferma password',
      country: 'Paese',
      'current-password': 'Password attuale',
      'current-spending': 'Consumo attuale',
      'daily-limits': 'Limiti giornalieri',
      'date-of-birth': 'Data di nascita',
      deposit: 'Deposito',
      'document-id': 'Passaporto ID',
      documents: 'Documenti',
      'driver-license': 'Patente di guida',
      'email-address': 'Indirizzo e-mail',
      'email-adress': 'Indirizzo e-mail',
      female: 'Femminile',
      'first-name': 'Nome',
      gender: 'Genere',
      'i-have-jmbg': 'Ho JMBG (solo per i cittadini del Montenegro)',
      jmbg: 'JMBG',
      'last-name': 'Cognome',
      male: 'Maschio',
      'monthly-limits': 'Limiti mensili',
      'new-limit-value-is': 'Il nuovo valore del limite è',
      'new-limits-take-effect-in': 'I nuovi limiti entrano in vigore ',
      'new-password': 'Nuova password',
      passport: 'Passaporto',
      password: 'Codice',
      'pause-gambling-confirmation':
        'Ciò disabiliterà le scommesse sportive, il casinò e quelle virtuali fino alla data selezionata. Sei sicuro di voler continuare?',
      'pause-gambling-until': 'Sospendere le scommesse fino al',
      'personal-data': 'Dati personali',
      'personal-id': "Carta d'identità",
      phone: 'Telefono',
      place: 'Luogo',
      'responsible-gambling': 'Scommesse responsabili',
      save: 'Salvarla',
      'street-and-number': 'Via e numero',
      telephone: 'Telefono',
      'user-details': 'Dati personali',
      username: 'Nome utente',
      'weekly-limits': 'Limiti settimanali',
    },
    userMarket: {
      cancel: 'Cancella',
      code: 'Il codice',
      doctor: 'Medico',
      expert: "L'esperto",
      items: 'Elementi',
      order: 'Ordine',
      'order-success': 'Ordine andato a buon fine',
      place: 'Posto',
      professor: 'Professore',
      'sbbet-points': 'Punti SBBet',
      'successfuly-canceled-order': 'Hai annullato con successo il tuo ordine',
    },
    validations: {
      'amount-greater-than-available':
        "L'importo inserito è superiore a quello disponibile",
      'cannot-mix-group-events':
        'Non è possibile giocare ad eventi di gruppo ed eventi di gruppo sullo stesso biglietto',
      'event-disabled': "Scommettere sull'evento: {{a}} non è possibile.",
      'event-starts-in-less-than':
        "L'evento {{a}} inizia tra meno di {{b}} secondi",
      'free-bet-error':
        'Il numero minimo di eventi su un biglietto per scommessa gratuita è {{minEvents}}. La quota totale minima è {{minTotalOdd}}.',
      'free-bet-unvalidated':
        'Verifica il tuo account per usufruire dei fondi promozionali.',
      'live-odds-change': 'Si prega di accettare tutte le modifiche.',
      'max-bet-amount': 'Il pagamento massimo è {{a}}',
      'max-credit': 'La vincita massima è di {{max}} €',
      'max-debit': 'Il pagamento massimo è {{max}}',
      'max-number-of-combinations': 'Il numero massimo di combinazioni è {{a}}',
      'max-number-of-events': 'Il numero massimo di eventi è {{a}}',
      'max-number-of-same-events': 'Il numero massimo di caratteri è {{a}}',
      'max-same-slips': 'Hai giocato a {{a}} dello stesso biglietto.',
      'min-amount-per-combination':
        'Il pagamento minimo per combinazione è {{a}}',
      'min-credit': 'Il pagamento minimo è di {{min}} €',
      'min-debit': 'Il pagamento minimo è {{min}}',
      'min-payment-for-number-of-matches':
        'Il pagamento minimo per un numero di eventi inferiore a {{b}} è {{a}}',
      'no-space': 'Non sono ammessi spazi',
      'odd-disabled': 'Non è possibile scommettere sulla quota {{a}}.',
      'odd-higher-than-one': 'Qualsiasi quota deve essere maggiore di 1.',
      'only-letters-and-spaces-allowed': 'Sono consentiti solo lettere e spazi',
      'only-numbers-allowed': 'Sono ammessi solo numeri',
      'repeat-password': 'Ripeti la password',
      'validation-antepost-system':
        'Non è possibile riprodurre eventi antepost su un ticket di sistema.',
      'validation-balance': 'Non hai fondi sufficienti nel tuo account.',
      'validation-max-payment':
        'Il pagamento è superiore al pagamento massimo definito.',
      'validation-max-systems': 'Il numero massimo di sistemi è {{a}}',
      'validation-max-win': 'La vincita potenziale massimo è {{a}}',
      'validation-min-payment': 'Il pagamento minimo è di {{minAmount}} EUR',
      'validation-mix-long-short-term':
        "Non è possibile giocare all'evento antepost {{a}} e all'evento a breve termine {{b}} sullo stesso biglietto.",
      'validation-mixed-event-types':
        'Non è possibile giocare eventi antepost in combinazione con altri tipi di eventi',
      'validation-mixed-markets':
        'Non è possibile giocare al mercato {{a}} e al mercato {{b}} sullo stesso biglietto.',
      'validation-not-validated':
        'Un utente non verificato non può effettuare un pagamento.',
      'validation-only-singles':
        "L'evento {{a}} può essere giocato solo in singolo.",
      'validation-single-choice':
        "L'evento {{a}} non può contenere più caratteri.",
    },
    verification: {
      'accept-terms': 'Accetto i termini di utilizzo',
      'account-activated': "L'account è stato attivato con successo",
      'account-success': 'Hai attivato con successo il tuo account!',
      'agree-email-notif': 'Desidero ricevere notifiche via email',
      'agree-sms-notif': 'Desidero ricevere notifiche via SMS',
      confirm: 'CONFERMARE',
      'connection-online': 'Connessione Internet stabilita!',
      'data-saved': 'I dati sono stati salvati con successo',
      'deny-changes': 'Hai rifiutato con successo le modifiche al ticket.',
      'deny-changes-timer':
        "Le modifiche sono state rifiutate a causa del timeout per l'accettazione delle modifiche.",
      'email-verified-success':
        'Hai verificato con successo il tuo account email',
      'enter-recovery-email':
        "Inserisci l'indirizzo email con cui hai creato l'account.",
      instruction:
        'Invia un messaggio SMS al numero 1818 nel formato: SbbIDNumberDeposit Amount',
      'login-activation': "Accedi per utilizzare l'app",
      'promo-code-activated':
        'Hai attivato con successo il codice promozionale. Hai ricevuto {{amount}} {{currency}} nel tuo account promozionale.',
      'promo-code-activated-multiple-wallets':
        'Hai attivato con successo il codice promozionale. Hai ricevuto {{amount}} {{currency}} sul tuo conto delle scommesse gratuite e {{amount_fc}} {{currency}} sul tuo conto dei giri gratuiti.',
      'promo-code-already-activated':
        'Il codice promozionale è già stato attivato',
      'promo-code-not-for-existing-users':
        'Il codice promozionale non è destinato ai vecchi utenti',
      'promo-code-not-for-new-users':
        'Il codice promozionale non è destinato ai nuovi utenti',
      'promo-code-used-up': 'Il codice promozionale è già stato utilizzato',
      'recovery-email-sent':
        "Ti abbiamo inviato un'e-mail al tuo indirizzo e-mail",
      'successfully-changed-password':
        'Hai cambiato con successo la tua password',
      'ticket-approved':
        'Il tuo biglietto è stato approvato. Clicca per visualizzare il biglietto.',
      'ticket-paid': 'Il biglietto è stato pagato con successo.',
      'ticket-waiting-approval': 'Il tuo biglietto è in fase di approvazione.',
      'upload-documents': {
        'expiration-date': 'Data di scadenza',
        'choose-document': 'Seleziona un documento',
        'document-type': 'Tipo di documento di convalida:',
        'first-page': 'Fronte/prima pagina del documento:',
        'second-page': 'Retro/seconda pagina del documento:',
        submit: 'Inviare',
        title: 'Caricamento del documento',
        'upload-first-page': 'Caricare la prima/prima pagina del documento',
        'upload-second-page': 'Caricare il retro/il secondo lato del documento',
        'user-update-success': 'I dati sono stati salvati con successo',
      },
      'validation-18-year': 'Non hai 18 anni.',
      'verification-complete':
        "La convalida dell'ordine è stata completata con successo",
      'verification-in-progress': 'Convalida in corso',
      'verification-modal': {
        activate: 'Validazione account',
        desc: 'Puoi convalidare il tuo account in qualsiasi momento nella pagina del tuo account nella sezione Il mio account > ACCOUNT > Documenti',
        'doc-uploaded': 'Convalida in corso',
        Name: 'Denominazione',
        proceed: 'Continua senza convalida',
        validate: "Convalida dell'utente",
      },
    },
    wallet: {
      'activate-bonus-info':
        'Attiva un bonus promozionale per un importo di {{bonus}} EUR',
      'casino-bonus-info':
        'Devi spendere altri {{amountToUnlock}} EUR per sbloccare il bonus del casinò',
      'casino-to-unlock': 'Bonus del casinò allo sblocco',
      'free-bet': 'Scommessa gratuita',
      'free-bet-bonus': 'Bonus di scommessa gratuita',
      'free-bet-bonus-info':
        'Devi spendere altri {{amountToUnlock}} EUR per sbloccare la scommessa gratuita',
      'free-bet-bonus-to-unlock': 'Bonus di scommessa gratuita allo sblocco',
      'free-bet-bonus-unlock-message':
        'Devi spendere altri {{amountToUnlock}} EUR per sbloccare il bonus di scommessa gratuita',
      'free-spin': 'Giri gratuiti',
      'free-spin-to-unlock': 'Giro gratuito allo sblocco',
      'free-spin-unlock-message':
        'Devi spendere altri {{amountToUnlock}} EUR per sbloccare il giro gratuito',
      'games-ticket-payout-info': 'Vuoi riscuotere il prossimo biglietto?',
      'payout-success': 'Il pagamento è stato effettuato con successo.',
      'poker-balance': 'Saldo del poker',
      'promo-bill': 'Conto promozionale',
      'promo-bonus': 'Bonus promozionale',
      'promo-to-unlock': 'Sblocca promozione',
      'reserved-funds': 'Fondi riservati',
      'standard-account': 'Conto standard',
      'standard-bill': 'Bilancio',
      'submit-for-print-success': 'Inviato per la stampa con successo',
      'ticket-successfully-paid': 'Il biglietto è stato ricosso con successo',
      'valid-to': 'Valido fino al giorno {{validTo}}',
      'voucher-payout-info': 'Vuoi riscotere il prossimo VOUCHER',
      'voucher-print': 'STAMPA VOUCHER',
      'voucher-print-confirm': 'Vuoi continuare?',
      'voucher-print-info':
        'Tutti i fondi presenti sul Saldo verranno rimossi e verrà stampato un VOUCHER dello stesso valore.',
      wallet: 'Conto',
      'wallet-locked': "L'account è bloccato",
      'wallets-refresh': 'Aggiorna le informazioni del tuo portafoglio',
    },
  },
};

export default it;
