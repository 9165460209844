/* React modules */

/* Our modules */
import { ILocation } from 'modules/sports/sports.types';
import {
  SportNodeService,
  NODE_TYPE,
} from 'modules/sports/services/sport-node.service';

/* 3rd Party modules */
import { makeObservable, observable } from 'mobx';

class LocationStore extends SportNodeService {
  constructor(node: ILocation) {
    super(node, NODE_TYPE.LOCATION);

    makeObservable(this, {
      children: observable,
    });
  }
}

export { LocationStore };
