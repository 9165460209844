// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/loyalty/cashdrop.proto" (package "cashdrop", syntax proto3)
// tslint:disable
import { Empty } from "../../google/protobuf/empty_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Error } from "../common/common_pb";
/**
 * @generated from protobuf message cashdrop.CashDropDisabled
 */
export interface CashDropDisabled {
    /**
     * @generated from protobuf field: string status = 1;
     */
    status: string;
}
/**
 * @generated from protobuf message cashdrop.Drop
 */
export interface Drop {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int32 start = 2;
     */
    start: number;
    /**
     * @generated from protobuf field: bool collectable = 3;
     */
    collectable: boolean;
    /**
     * @generated from protobuf field: repeated int32 collected_by = 4;
     */
    collectedBy: number[];
    /**
     * @generated from protobuf field: bool is_disabled = 5;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: common.Error error = 9;
     */
    error?: Error;
}
/**
 * @generated from protobuf message cashdrop.WebDrop
 */
export interface WebDrop {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: bool active = 2;
     */
    active: boolean;
    /**
     * @generated from protobuf field: string award_type = 3;
     */
    awardType: string;
    /**
     * @generated from protobuf field: double award_amount = 4;
     */
    awardAmount: number;
    /**
     * @generated from protobuf field: int32 collected = 5;
     */
    collected: number;
    /**
     * @generated from protobuf field: bool collected_by_user = 6;
     */
    collectedByUser: boolean;
    /**
     * @generated from protobuf field: int32 total = 7;
     */
    total: number;
    /**
     * @generated from protobuf field: int32 start = 8;
     */
    start: number;
}
/**
 * @generated from protobuf message cashdrop.Drops
 */
export interface Drops {
    /**
     * @generated from protobuf field: repeated cashdrop.Drop drops = 1;
     */
    drops: Drop[];
}
/**
 * @generated from protobuf message cashdrop.WebDrops
 */
export interface WebDrops {
    /**
     * @generated from protobuf field: repeated cashdrop.WebDrop drops = 1;
     */
    drops: WebDrop[];
}
/**
 * @generated from protobuf message cashdrop.DropGroup
 */
export interface DropGroup {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int32 start = 2;
     */
    start: number;
    /**
     * @generated from protobuf field: int32 end = 3;
     */
    end: number;
    /**
     * @generated from protobuf field: string award_type = 4;
     */
    awardType: string;
    /**
     * @generated from protobuf field: double award_amount = 5;
     */
    awardAmount: number;
    /**
     * @generated from protobuf field: int32 drop_daily_count = 6;
     */
    dropDailyCount: number;
    /**
     * @generated from protobuf field: int32 drop_duration = 7;
     */
    dropDuration: number;
    /**
     * @generated from protobuf field: int32 drop_award_count = 8;
     */
    dropAwardCount: number;
    /**
     * @generated from protobuf field: int32 drop_user_max_collect = 9;
     */
    dropUserMaxCollect: number;
    /**
     * @generated from protobuf field: double min_user_balance = 10;
     */
    minUserBalance: number;
    /**
     * @generated from protobuf field: double min_user_bet = 11;
     */
    minUserBet: number;
    /**
     * @generated from protobuf field: int32 min_user_bet_period = 12;
     */
    minUserBetPeriod: number;
    /**
     * @generated from protobuf field: repeated cashdrop.Drop drops = 13;
     */
    drops: Drop[];
    /**
     * @generated from protobuf field: bool is_disabled = 14;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: common.Error error = 19;
     */
    error?: Error;
}
/**
 * @generated from protobuf message cashdrop.DropGroups
 */
export interface DropGroups {
    /**
     * @generated from protobuf field: repeated cashdrop.DropGroup groups = 1;
     */
    groups: DropGroup[];
}
/**
 * @generated from protobuf message cashdrop.GetDropGroups
 */
export interface GetDropGroups {
    /**
     * @generated from protobuf field: int32 start = 1;
     */
    start: number;
    /**
     * @generated from protobuf field: int32 end = 2;
     */
    end: number;
    /**
     * @generated from protobuf field: bool is_disabled = 3;
     */
    isDisabled: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class CashDropDisabled$Type extends MessageType<CashDropDisabled> {
    constructor() {
        super("cashdrop.CashDropDisabled", [
            { no: 1, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CashDropDisabled>): CashDropDisabled {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = "";
        if (value !== undefined)
            reflectionMergePartial<CashDropDisabled>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CashDropDisabled): CashDropDisabled {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string status */ 1:
                    message.status = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CashDropDisabled, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string status = 1; */
        if (message.status !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message cashdrop.CashDropDisabled
 */
export const CashDropDisabled = new CashDropDisabled$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Drop$Type extends MessageType<Drop> {
    constructor() {
        super("cashdrop.Drop", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "start", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "collectable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "collected_by", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<Drop>): Drop {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.start = 0;
        message.collectable = false;
        message.collectedBy = [];
        message.isDisabled = false;
        if (value !== undefined)
            reflectionMergePartial<Drop>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Drop): Drop {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 start */ 2:
                    message.start = reader.int32();
                    break;
                case /* bool collectable */ 3:
                    message.collectable = reader.bool();
                    break;
                case /* repeated int32 collected_by */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.collectedBy.push(reader.int32());
                    else
                        message.collectedBy.push(reader.int32());
                    break;
                case /* bool is_disabled */ 5:
                    message.isDisabled = reader.bool();
                    break;
                case /* common.Error error */ 9:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Drop, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 start = 2; */
        if (message.start !== 0)
            writer.tag(2, WireType.Varint).int32(message.start);
        /* bool collectable = 3; */
        if (message.collectable !== false)
            writer.tag(3, WireType.Varint).bool(message.collectable);
        /* repeated int32 collected_by = 4; */
        if (message.collectedBy.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.collectedBy.length; i++)
                writer.int32(message.collectedBy[i]);
            writer.join();
        }
        /* bool is_disabled = 5; */
        if (message.isDisabled !== false)
            writer.tag(5, WireType.Varint).bool(message.isDisabled);
        /* common.Error error = 9; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message cashdrop.Drop
 */
export const Drop = new Drop$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebDrop$Type extends MessageType<WebDrop> {
    constructor() {
        super("cashdrop.WebDrop", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "award_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "award_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "collected", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "collected_by_user", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "start", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<WebDrop>): WebDrop {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.active = false;
        message.awardType = "";
        message.awardAmount = 0;
        message.collected = 0;
        message.collectedByUser = false;
        message.total = 0;
        message.start = 0;
        if (value !== undefined)
            reflectionMergePartial<WebDrop>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebDrop): WebDrop {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* bool active */ 2:
                    message.active = reader.bool();
                    break;
                case /* string award_type */ 3:
                    message.awardType = reader.string();
                    break;
                case /* double award_amount */ 4:
                    message.awardAmount = reader.double();
                    break;
                case /* int32 collected */ 5:
                    message.collected = reader.int32();
                    break;
                case /* bool collected_by_user */ 6:
                    message.collectedByUser = reader.bool();
                    break;
                case /* int32 total */ 7:
                    message.total = reader.int32();
                    break;
                case /* int32 start */ 8:
                    message.start = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebDrop, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* bool active = 2; */
        if (message.active !== false)
            writer.tag(2, WireType.Varint).bool(message.active);
        /* string award_type = 3; */
        if (message.awardType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.awardType);
        /* double award_amount = 4; */
        if (message.awardAmount !== 0)
            writer.tag(4, WireType.Bit64).double(message.awardAmount);
        /* int32 collected = 5; */
        if (message.collected !== 0)
            writer.tag(5, WireType.Varint).int32(message.collected);
        /* bool collected_by_user = 6; */
        if (message.collectedByUser !== false)
            writer.tag(6, WireType.Varint).bool(message.collectedByUser);
        /* int32 total = 7; */
        if (message.total !== 0)
            writer.tag(7, WireType.Varint).int32(message.total);
        /* int32 start = 8; */
        if (message.start !== 0)
            writer.tag(8, WireType.Varint).int32(message.start);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message cashdrop.WebDrop
 */
export const WebDrop = new WebDrop$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Drops$Type extends MessageType<Drops> {
    constructor() {
        super("cashdrop.Drops", [
            { no: 1, name: "drops", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Drop }
        ]);
    }
    create(value?: PartialMessage<Drops>): Drops {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.drops = [];
        if (value !== undefined)
            reflectionMergePartial<Drops>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Drops): Drops {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated cashdrop.Drop drops */ 1:
                    message.drops.push(Drop.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Drops, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated cashdrop.Drop drops = 1; */
        for (let i = 0; i < message.drops.length; i++)
            Drop.internalBinaryWrite(message.drops[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message cashdrop.Drops
 */
export const Drops = new Drops$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebDrops$Type extends MessageType<WebDrops> {
    constructor() {
        super("cashdrop.WebDrops", [
            { no: 1, name: "drops", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WebDrop }
        ]);
    }
    create(value?: PartialMessage<WebDrops>): WebDrops {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.drops = [];
        if (value !== undefined)
            reflectionMergePartial<WebDrops>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebDrops): WebDrops {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated cashdrop.WebDrop drops */ 1:
                    message.drops.push(WebDrop.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebDrops, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated cashdrop.WebDrop drops = 1; */
        for (let i = 0; i < message.drops.length; i++)
            WebDrop.internalBinaryWrite(message.drops[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message cashdrop.WebDrops
 */
export const WebDrops = new WebDrops$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DropGroup$Type extends MessageType<DropGroup> {
    constructor() {
        super("cashdrop.DropGroup", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "start", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "end", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "award_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "award_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "drop_daily_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "drop_duration", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "drop_award_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "drop_user_max_collect", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "min_user_balance", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 11, name: "min_user_bet", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 12, name: "min_user_bet_period", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "drops", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Drop },
            { no: 14, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<DropGroup>): DropGroup {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.start = 0;
        message.end = 0;
        message.awardType = "";
        message.awardAmount = 0;
        message.dropDailyCount = 0;
        message.dropDuration = 0;
        message.dropAwardCount = 0;
        message.dropUserMaxCollect = 0;
        message.minUserBalance = 0;
        message.minUserBet = 0;
        message.minUserBetPeriod = 0;
        message.drops = [];
        message.isDisabled = false;
        if (value !== undefined)
            reflectionMergePartial<DropGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DropGroup): DropGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 start */ 2:
                    message.start = reader.int32();
                    break;
                case /* int32 end */ 3:
                    message.end = reader.int32();
                    break;
                case /* string award_type */ 4:
                    message.awardType = reader.string();
                    break;
                case /* double award_amount */ 5:
                    message.awardAmount = reader.double();
                    break;
                case /* int32 drop_daily_count */ 6:
                    message.dropDailyCount = reader.int32();
                    break;
                case /* int32 drop_duration */ 7:
                    message.dropDuration = reader.int32();
                    break;
                case /* int32 drop_award_count */ 8:
                    message.dropAwardCount = reader.int32();
                    break;
                case /* int32 drop_user_max_collect */ 9:
                    message.dropUserMaxCollect = reader.int32();
                    break;
                case /* double min_user_balance */ 10:
                    message.minUserBalance = reader.double();
                    break;
                case /* double min_user_bet */ 11:
                    message.minUserBet = reader.double();
                    break;
                case /* int32 min_user_bet_period */ 12:
                    message.minUserBetPeriod = reader.int32();
                    break;
                case /* repeated cashdrop.Drop drops */ 13:
                    message.drops.push(Drop.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool is_disabled */ 14:
                    message.isDisabled = reader.bool();
                    break;
                case /* common.Error error */ 19:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DropGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 start = 2; */
        if (message.start !== 0)
            writer.tag(2, WireType.Varint).int32(message.start);
        /* int32 end = 3; */
        if (message.end !== 0)
            writer.tag(3, WireType.Varint).int32(message.end);
        /* string award_type = 4; */
        if (message.awardType !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.awardType);
        /* double award_amount = 5; */
        if (message.awardAmount !== 0)
            writer.tag(5, WireType.Bit64).double(message.awardAmount);
        /* int32 drop_daily_count = 6; */
        if (message.dropDailyCount !== 0)
            writer.tag(6, WireType.Varint).int32(message.dropDailyCount);
        /* int32 drop_duration = 7; */
        if (message.dropDuration !== 0)
            writer.tag(7, WireType.Varint).int32(message.dropDuration);
        /* int32 drop_award_count = 8; */
        if (message.dropAwardCount !== 0)
            writer.tag(8, WireType.Varint).int32(message.dropAwardCount);
        /* int32 drop_user_max_collect = 9; */
        if (message.dropUserMaxCollect !== 0)
            writer.tag(9, WireType.Varint).int32(message.dropUserMaxCollect);
        /* double min_user_balance = 10; */
        if (message.minUserBalance !== 0)
            writer.tag(10, WireType.Bit64).double(message.minUserBalance);
        /* double min_user_bet = 11; */
        if (message.minUserBet !== 0)
            writer.tag(11, WireType.Bit64).double(message.minUserBet);
        /* int32 min_user_bet_period = 12; */
        if (message.minUserBetPeriod !== 0)
            writer.tag(12, WireType.Varint).int32(message.minUserBetPeriod);
        /* repeated cashdrop.Drop drops = 13; */
        for (let i = 0; i < message.drops.length; i++)
            Drop.internalBinaryWrite(message.drops[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* bool is_disabled = 14; */
        if (message.isDisabled !== false)
            writer.tag(14, WireType.Varint).bool(message.isDisabled);
        /* common.Error error = 19; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message cashdrop.DropGroup
 */
export const DropGroup = new DropGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DropGroups$Type extends MessageType<DropGroups> {
    constructor() {
        super("cashdrop.DropGroups", [
            { no: 1, name: "groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DropGroup }
        ]);
    }
    create(value?: PartialMessage<DropGroups>): DropGroups {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.groups = [];
        if (value !== undefined)
            reflectionMergePartial<DropGroups>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DropGroups): DropGroups {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated cashdrop.DropGroup groups */ 1:
                    message.groups.push(DropGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DropGroups, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated cashdrop.DropGroup groups = 1; */
        for (let i = 0; i < message.groups.length; i++)
            DropGroup.internalBinaryWrite(message.groups[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message cashdrop.DropGroups
 */
export const DropGroups = new DropGroups$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDropGroups$Type extends MessageType<GetDropGroups> {
    constructor() {
        super("cashdrop.GetDropGroups", [
            { no: 1, name: "start", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "end", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetDropGroups>): GetDropGroups {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.start = 0;
        message.end = 0;
        message.isDisabled = false;
        if (value !== undefined)
            reflectionMergePartial<GetDropGroups>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDropGroups): GetDropGroups {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 start */ 1:
                    message.start = reader.int32();
                    break;
                case /* int32 end */ 2:
                    message.end = reader.int32();
                    break;
                case /* bool is_disabled */ 3:
                    message.isDisabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDropGroups, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 start = 1; */
        if (message.start !== 0)
            writer.tag(1, WireType.Varint).int32(message.start);
        /* int32 end = 2; */
        if (message.end !== 0)
            writer.tag(2, WireType.Varint).int32(message.end);
        /* bool is_disabled = 3; */
        if (message.isDisabled !== false)
            writer.tag(3, WireType.Varint).bool(message.isDisabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message cashdrop.GetDropGroups
 */
export const GetDropGroups = new GetDropGroups$Type();
/**
 * @generated ServiceType for protobuf service cashdrop.CashDropBOService
 */
export const CashDropBOService = new ServiceType("cashdrop.CashDropBOService", [
    { name: "GetCashDropGroups", options: {}, I: GetDropGroups, O: DropGroups },
    { name: "CreateCashDropGroup", options: {}, I: DropGroup, O: DropGroup },
    { name: "EditCashDropStart", options: {}, I: Drops, O: Drops },
    { name: "DisableCashDrop", options: {}, I: Drop, O: Drop },
    { name: "DisableCashDropGroup", options: {}, I: DropGroup, O: DropGroup },
    { name: "GetCashDropDisabledBO", options: {}, I: Empty, O: CashDropDisabled },
    { name: "SetCashDropDisabled", options: {}, I: CashDropDisabled, O: CashDropDisabled }
]);
/**
 * @generated ServiceType for protobuf service cashdrop.CashDropWebService
 */
export const CashDropWebService = new ServiceType("cashdrop.CashDropWebService", [
    { name: "GetDailyDrops", options: {}, I: Empty, O: WebDrops },
    { name: "Collect", options: {}, I: Drop, O: Drop },
    { name: "GetCashDropGlobalDisabled", options: {}, I: Empty, O: CashDropDisabled }
]);
