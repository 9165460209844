/* React modules */

/* Our modules */
import { BettingPlacesAPI } from 'modules/betting-places/betting-places.api';
import { BettingPlace } from 'generated-proto/proto/user/user_pb';
import { logger } from 'libs/common-helpers';

/* 3rd Party modules */
import { makeAutoObservable } from 'mobx';

class BettingPlacesStore {
  api: BettingPlacesAPI;

  public bettingPlaces: BettingPlace[] | null = null;

  constructor() {
    this.api = new BettingPlacesAPI();

    /*
    makeObservable(this, {
      bettingPlaces: observable,
      getBettingPlaces: action,
    });
    */
    makeAutoObservable(this);
  }

  get bettingPlacesList() {
    return this.bettingPlaces;
  }

  getBettingPlaces = async () => {
    try {
      const {
        response: { bettingPlaces },
      } = await this.api.getBettingPlaces();

      this.bettingPlaces = bettingPlaces;
    } catch (exception) {
      this.bettingPlaces = [];

      logger('BettingPlacesStore -> getBettingPlaces -> exception', exception);
    }
  };

  getBettingPlacesByMunicipalities() {
    if (!this.bettingPlaces) return {};
    return this.bettingPlaces.reduce((places: any, place: BettingPlace) => {
      if (!places[place.municipalityName]) {
        places[place.municipalityName] = [place];
      } else {
        places[place.municipalityName].push(place);
      }

      return places;
    }, {});
  }

  getBettingPlace(id: number): BettingPlace | null {
    if (!this.bettingPlaces) return null;
    return (
      this.bettingPlaces.find((bettingPlace) => bettingPlace.id === id) || null
    );
  }
}

export const bettingPlacesStore = new BettingPlacesStore();
