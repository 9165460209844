/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import { Button, Icon } from 'components';
import defaultGameImg from 'images/default-game.png';
import './GameInfoModal.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface GameInfoModalProps {
  id: number;
  name: string;
  lines: number;
  provider: string;
  play_for_fun: boolean | undefined;
  image_filled: string;
  max_bet: number | undefined | null;
  min_bet: number | undefined | null;
  total: string | undefined | null;
}

interface PlayGameProps {
  tryGame: () => void;
  playGame: () => void;
  setFavouriteGame: (id: number) => void;
  removeFavouriteGame: (id: number) => void;
}

const GameInfoModal = observer(
  ({
    tryGame,
    playGame,
    setFavouriteGame,
    removeFavouriteGame,
  }: PlayGameProps) => {
    const { t } = useTranslation();
    const { overlayStore, authStore, gamblingStore } = useStores();
    const {
      id,
      name,
      image_filled,
      provider,
      play_for_fun,
      max_bet,
      min_bet,
      total,
    }: GameInfoModalProps = overlayStore.modalProps;

    const checkIsFavouriteGame = (id: number) => {
      if (gamblingStore.favouriteGames.some((game: any) => game.id === id)) {
        return true;
      } else {
        return false;
      }
    };

    const isFavouriteGame = checkIsFavouriteGame(id);

    return (
      <div className="game-info">
        <div className="game-info__top-bar">
          <div className="ml-2 mt-3">
            {authStore.isLoggedIn && (
              <>
                {!isFavouriteGame && (
                  <div onClick={() => setFavouriteGame(id)}>
                    <Icon name="starUnselected" />
                  </div>
                )}
                {isFavouriteGame && (
                  <div onClick={() => removeFavouriteGame(id)}>
                    <Icon name="starSelected" />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="mr-2 sb-heading-xl" onClick={overlayStore.closeModal}>
            &times;
          </div>
        </div>
        <div className="game-info__title">
          <span>{provider}</span>
          <h3>{name}</h3>
        </div>
        <div className="game-info__content">
          <div className="game-info__thumbnail">
            <div
              className="game-info__image"
              style={{
                backgroundImage: `url(${image_filled}), url(${defaultGameImg})`,
              }}
            ></div>
            {total && <span className="game-info__jackpot">{total} EUR</span>}
            {max_bet && min_bet && (
              <span className="mt-3">
                {max_bet} EUR - {min_bet} EUR
              </span>
            )}
          </div>
          <div className="game-info__buttons">
            <Button bg="green" onClick={playGame}>
              <span>{t('casino.play')}</span>
            </Button>
            {play_for_fun && (
              <Button className="game-info__buttons--try" onClick={tryGame}>
                <span>{t('casino.demo')}</span>
              </Button>
            )}
          </div>
        </div>
        {/* Use free spin */}
        {/*<div className="w-100 mt-4">*/}
        {/*  <Checkbox*/}
        {/*    key="use-free-casino"*/}
        {/*    className="ml-2"*/}
        {/*    label={t('casino.use-free-casino')}*/}
        {/*    value={isActiveFreeCasino}*/}
        {/*    onChange={() => setFreeCasino(!isActiveFreeCasino)}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    );
  }
);

export default GameInfoModal;
