// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/slip/web_slip.proto" (package "web_slip", syntax proto3)
// tslint:disable
import { Empty } from "../../google/protobuf/empty_pb";
import { EmptyError } from "../common/common_pb";
import { SlipRequest } from "../common/common_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { FastSlipRequest } from "../common/common_pb";
import { Slip } from "../common/common_pb";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
import { Error } from "../common/common_pb";
/**
 * @generated from protobuf message web_slip.BonusConfig
 */
export interface BonusConfig {
    /**
     * @generated from protobuf field: string bonus_config = 1;
     */
    bonusConfig: string;
}
/**
 * @generated from protobuf message web_slip.Rules
 */
export interface Rules {
    /**
     * @generated from protobuf field: string rules = 1;
     */
    rules: string;
    /**
     * @generated from protobuf field: common.Error error = 4;
     */
    error?: Error;
}
/**
 * @generated from protobuf message web_slip.GetUserSlipsRequest
 */
export interface GetUserSlipsRequest {
    /**
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 2;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp end = 3;
     */
    end?: Timestamp;
    /**
     * @generated from protobuf field: repeated string statuses = 4;
     */
    statuses: string[];
    /**
     * @generated from protobuf field: string short_uuid = 5;
     */
    shortUuid: string;
    /**
     * @generated from protobuf field: int32 per_page = 6;
     */
    perPage: number;
}
/**
 * @generated from protobuf message web_slip.PaginatedSlips
 */
export interface PaginatedSlips {
    /**
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 total_pages = 2;
     */
    totalPages: number;
    /**
     * @generated from protobuf field: repeated common.Slip slips = 3;
     */
    slips: Slip[];
}
/**
 * @generated from protobuf message web_slip.SlipId
 */
export interface SlipId {
    /**
     * @generated from protobuf field: string slip_id = 1;
     */
    slipId: string;
    /**
     * @generated from protobuf field: string short_uuid = 2;
     */
    shortUuid: string;
    /**
     * @generated from protobuf field: bool check_cashout = 3;
     */
    checkCashout: boolean;
}
/**
 * @generated from protobuf message web_slip.CalculatedValues
 */
export interface CalculatedValues {
    /**
     * @generated from protobuf field: int32 number_of_combinations = 1;
     */
    numberOfCombinations: number;
    /**
     * @generated from protobuf field: double max_odds_value = 2;
     */
    maxOddsValue: number;
    /**
     * @generated from protobuf field: double min_odds_value = 3;
     */
    minOddsValue: number;
    /**
     * @generated from protobuf field: double max_winning_amount = 4;
     */
    maxWinningAmount: number;
    /**
     * @generated from protobuf field: double min_winning_amount = 5;
     */
    minWinningAmount: number;
    /**
     * @generated from protobuf field: double max_bonus_amount = 6;
     */
    maxBonusAmount: number;
    /**
     * @generated from protobuf field: double min_bonus_amount = 7;
     */
    minBonusAmount: number;
    /**
     * @generated from protobuf field: double bonus_percent = 8;
     */
    bonusPercent: number;
    /**
     * @generated from protobuf field: common.Error error = 9;
     */
    error?: Error;
}
/**
 * @generated from protobuf message web_slip.FastSlip
 */
export interface FastSlip {
    /**
     * @generated from protobuf field: int32 user_id = 1;
     */
    userId: number;
    /**
     * @generated from protobuf field: string code = 2;
     */
    code: string;
    /**
     * @generated from protobuf field: common.FastSlipRequest request = 3;
     */
    request?: FastSlipRequest;
    /**
     * @generated from protobuf field: common.Error error = 4;
     */
    error?: Error;
}
/**
 * @generated from protobuf message web_slip.FastSlips
 */
export interface FastSlips {
    /**
     * @generated from protobuf field: repeated web_slip.FastSlip fast_slips = 1;
     */
    fastSlips: FastSlip[];
}
/**
 * @generated from protobuf message web_slip.GetTaxThresholdConfigurationResponseWeb
 */
export interface GetTaxThresholdConfigurationResponseWeb {
    /**
     * @generated from protobuf field: double tax_threshold = 1;
     */
    taxThreshold: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class BonusConfig$Type extends MessageType<BonusConfig> {
    constructor() {
        super("web_slip.BonusConfig", [
            { no: 1, name: "bonus_config", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BonusConfig>): BonusConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bonusConfig = "";
        if (value !== undefined)
            reflectionMergePartial<BonusConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BonusConfig): BonusConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string bonus_config */ 1:
                    message.bonusConfig = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BonusConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string bonus_config = 1; */
        if (message.bonusConfig !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.bonusConfig);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_slip.BonusConfig
 */
export const BonusConfig = new BonusConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Rules$Type extends MessageType<Rules> {
    constructor() {
        super("web_slip.Rules", [
            { no: 1, name: "rules", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<Rules>): Rules {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rules = "";
        if (value !== undefined)
            reflectionMergePartial<Rules>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Rules): Rules {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string rules */ 1:
                    message.rules = reader.string();
                    break;
                case /* common.Error error */ 4:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Rules, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string rules = 1; */
        if (message.rules !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.rules);
        /* common.Error error = 4; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_slip.Rules
 */
export const Rules = new Rules$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserSlipsRequest$Type extends MessageType<GetUserSlipsRequest> {
    constructor() {
        super("web_slip.GetUserSlipsRequest", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "start", kind: "message", T: () => Timestamp },
            { no: 3, name: "end", kind: "message", T: () => Timestamp },
            { no: 4, name: "statuses", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "short_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "per_page", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserSlipsRequest>): GetUserSlipsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.statuses = [];
        message.shortUuid = "";
        message.perPage = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserSlipsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserSlipsRequest): GetUserSlipsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* google.protobuf.Timestamp start */ 2:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 3:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                case /* repeated string statuses */ 4:
                    message.statuses.push(reader.string());
                    break;
                case /* string short_uuid */ 5:
                    message.shortUuid = reader.string();
                    break;
                case /* int32 per_page */ 6:
                    message.perPage = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserSlipsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* google.protobuf.Timestamp start = 2; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 3; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated string statuses = 4; */
        for (let i = 0; i < message.statuses.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.statuses[i]);
        /* string short_uuid = 5; */
        if (message.shortUuid !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.shortUuid);
        /* int32 per_page = 6; */
        if (message.perPage !== 0)
            writer.tag(6, WireType.Varint).int32(message.perPage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_slip.GetUserSlipsRequest
 */
export const GetUserSlipsRequest = new GetUserSlipsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaginatedSlips$Type extends MessageType<PaginatedSlips> {
    constructor() {
        super("web_slip.PaginatedSlips", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "total_pages", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "slips", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Slip }
        ]);
    }
    create(value?: PartialMessage<PaginatedSlips>): PaginatedSlips {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.totalPages = 0;
        message.slips = [];
        if (value !== undefined)
            reflectionMergePartial<PaginatedSlips>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaginatedSlips): PaginatedSlips {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 total_pages */ 2:
                    message.totalPages = reader.int32();
                    break;
                case /* repeated common.Slip slips */ 3:
                    message.slips.push(Slip.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaginatedSlips, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 total_pages = 2; */
        if (message.totalPages !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalPages);
        /* repeated common.Slip slips = 3; */
        for (let i = 0; i < message.slips.length; i++)
            Slip.internalBinaryWrite(message.slips[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_slip.PaginatedSlips
 */
export const PaginatedSlips = new PaginatedSlips$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SlipId$Type extends MessageType<SlipId> {
    constructor() {
        super("web_slip.SlipId", [
            { no: 1, name: "slip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "short_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "check_cashout", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SlipId>): SlipId {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.slipId = "";
        message.shortUuid = "";
        message.checkCashout = false;
        if (value !== undefined)
            reflectionMergePartial<SlipId>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SlipId): SlipId {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string slip_id */ 1:
                    message.slipId = reader.string();
                    break;
                case /* string short_uuid */ 2:
                    message.shortUuid = reader.string();
                    break;
                case /* bool check_cashout */ 3:
                    message.checkCashout = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SlipId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string slip_id = 1; */
        if (message.slipId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.slipId);
        /* string short_uuid = 2; */
        if (message.shortUuid !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.shortUuid);
        /* bool check_cashout = 3; */
        if (message.checkCashout !== false)
            writer.tag(3, WireType.Varint).bool(message.checkCashout);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_slip.SlipId
 */
export const SlipId = new SlipId$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalculatedValues$Type extends MessageType<CalculatedValues> {
    constructor() {
        super("web_slip.CalculatedValues", [
            { no: 1, name: "number_of_combinations", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "max_odds_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "min_odds_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "max_winning_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "min_winning_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "max_bonus_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "min_bonus_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "bonus_percent", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<CalculatedValues>): CalculatedValues {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.numberOfCombinations = 0;
        message.maxOddsValue = 0;
        message.minOddsValue = 0;
        message.maxWinningAmount = 0;
        message.minWinningAmount = 0;
        message.maxBonusAmount = 0;
        message.minBonusAmount = 0;
        message.bonusPercent = 0;
        if (value !== undefined)
            reflectionMergePartial<CalculatedValues>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalculatedValues): CalculatedValues {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 number_of_combinations */ 1:
                    message.numberOfCombinations = reader.int32();
                    break;
                case /* double max_odds_value */ 2:
                    message.maxOddsValue = reader.double();
                    break;
                case /* double min_odds_value */ 3:
                    message.minOddsValue = reader.double();
                    break;
                case /* double max_winning_amount */ 4:
                    message.maxWinningAmount = reader.double();
                    break;
                case /* double min_winning_amount */ 5:
                    message.minWinningAmount = reader.double();
                    break;
                case /* double max_bonus_amount */ 6:
                    message.maxBonusAmount = reader.double();
                    break;
                case /* double min_bonus_amount */ 7:
                    message.minBonusAmount = reader.double();
                    break;
                case /* double bonus_percent */ 8:
                    message.bonusPercent = reader.double();
                    break;
                case /* common.Error error */ 9:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CalculatedValues, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 number_of_combinations = 1; */
        if (message.numberOfCombinations !== 0)
            writer.tag(1, WireType.Varint).int32(message.numberOfCombinations);
        /* double max_odds_value = 2; */
        if (message.maxOddsValue !== 0)
            writer.tag(2, WireType.Bit64).double(message.maxOddsValue);
        /* double min_odds_value = 3; */
        if (message.minOddsValue !== 0)
            writer.tag(3, WireType.Bit64).double(message.minOddsValue);
        /* double max_winning_amount = 4; */
        if (message.maxWinningAmount !== 0)
            writer.tag(4, WireType.Bit64).double(message.maxWinningAmount);
        /* double min_winning_amount = 5; */
        if (message.minWinningAmount !== 0)
            writer.tag(5, WireType.Bit64).double(message.minWinningAmount);
        /* double max_bonus_amount = 6; */
        if (message.maxBonusAmount !== 0)
            writer.tag(6, WireType.Bit64).double(message.maxBonusAmount);
        /* double min_bonus_amount = 7; */
        if (message.minBonusAmount !== 0)
            writer.tag(7, WireType.Bit64).double(message.minBonusAmount);
        /* double bonus_percent = 8; */
        if (message.bonusPercent !== 0)
            writer.tag(8, WireType.Bit64).double(message.bonusPercent);
        /* common.Error error = 9; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_slip.CalculatedValues
 */
export const CalculatedValues = new CalculatedValues$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FastSlip$Type extends MessageType<FastSlip> {
    constructor() {
        super("web_slip.FastSlip", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "request", kind: "message", T: () => FastSlipRequest },
            { no: 4, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<FastSlip>): FastSlip {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = 0;
        message.code = "";
        if (value !== undefined)
            reflectionMergePartial<FastSlip>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FastSlip): FastSlip {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string code */ 2:
                    message.code = reader.string();
                    break;
                case /* common.FastSlipRequest request */ 3:
                    message.request = FastSlipRequest.internalBinaryRead(reader, reader.uint32(), options, message.request);
                    break;
                case /* common.Error error */ 4:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FastSlip, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string code = 2; */
        if (message.code !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.code);
        /* common.FastSlipRequest request = 3; */
        if (message.request)
            FastSlipRequest.internalBinaryWrite(message.request, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 4; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_slip.FastSlip
 */
export const FastSlip = new FastSlip$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FastSlips$Type extends MessageType<FastSlips> {
    constructor() {
        super("web_slip.FastSlips", [
            { no: 1, name: "fast_slips", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FastSlip }
        ]);
    }
    create(value?: PartialMessage<FastSlips>): FastSlips {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.fastSlips = [];
        if (value !== undefined)
            reflectionMergePartial<FastSlips>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FastSlips): FastSlips {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated web_slip.FastSlip fast_slips */ 1:
                    message.fastSlips.push(FastSlip.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FastSlips, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated web_slip.FastSlip fast_slips = 1; */
        for (let i = 0; i < message.fastSlips.length; i++)
            FastSlip.internalBinaryWrite(message.fastSlips[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_slip.FastSlips
 */
export const FastSlips = new FastSlips$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTaxThresholdConfigurationResponseWeb$Type extends MessageType<GetTaxThresholdConfigurationResponseWeb> {
    constructor() {
        super("web_slip.GetTaxThresholdConfigurationResponseWeb", [
            { no: 1, name: "tax_threshold", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<GetTaxThresholdConfigurationResponseWeb>): GetTaxThresholdConfigurationResponseWeb {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.taxThreshold = 0;
        if (value !== undefined)
            reflectionMergePartial<GetTaxThresholdConfigurationResponseWeb>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTaxThresholdConfigurationResponseWeb): GetTaxThresholdConfigurationResponseWeb {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double tax_threshold */ 1:
                    message.taxThreshold = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTaxThresholdConfigurationResponseWeb, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double tax_threshold = 1; */
        if (message.taxThreshold !== 0)
            writer.tag(1, WireType.Bit64).double(message.taxThreshold);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_slip.GetTaxThresholdConfigurationResponseWeb
 */
export const GetTaxThresholdConfigurationResponseWeb = new GetTaxThresholdConfigurationResponseWeb$Type();
/**
 * @generated ServiceType for protobuf service web_slip.WebSlipService
 */
export const WebSlipService = new ServiceType("web_slip.WebSlipService", [
    { name: "PlaceBetWeb", options: {}, I: SlipRequest, O: Slip },
    { name: "PlaceBetLiveWeb", options: {}, I: SlipRequest, O: Slip },
    { name: "ManualChangeUserAcceptWeb", options: {}, I: SlipId, O: EmptyError },
    { name: "ManualChangeUserDenyWeb", options: {}, I: SlipId, O: EmptyError },
    { name: "SlipSendForCashOutWeb", options: {}, I: SlipId, O: EmptyError },
    { name: "SlipCashOutUserAcceptWeb", options: {}, I: SlipId, O: EmptyError },
    { name: "SlipCashOutUserDenyWeb", options: {}, I: SlipId, O: EmptyError },
    { name: "SlipCashOutUserCancel", options: {}, I: SlipId, O: EmptyError },
    { name: "GetValidationRulesWeb", options: {}, I: Empty, O: Rules },
    { name: "GetUserSlipsWeb", options: {}, I: GetUserSlipsRequest, O: PaginatedSlips },
    { name: "GetSingleSlipWeb", options: {}, I: SlipId, O: Slip },
    { name: "GetSharedSlip", options: {}, I: SlipId, O: Slip },
    { name: "GetTopWinningSlips", options: {}, I: Empty, O: PaginatedSlips },
    { name: "CalculateSlipValuesWeb", options: {}, I: SlipRequest, O: CalculatedValues },
    { name: "ScanSlip", options: {}, I: SlipId, O: Slip },
    { name: "GetBonusConfig", options: {}, I: Empty, O: BonusConfig },
    { name: "CreateFastSlip", options: {}, I: FastSlipRequest, O: FastSlip },
    { name: "CreateFastSlipUser", options: {}, I: FastSlipRequest, O: FastSlip },
    { name: "GetFastSlipsForUser", options: {}, I: Empty, O: FastSlips },
    { name: "GetFastSlipWeb", options: {}, I: FastSlip, O: Slip },
    { name: "GetTaxThresholdConfigurationWeb", options: {}, I: Empty, O: GetTaxThresholdConfigurationResponseWeb }
]);
