/* React modules */
import { useEffect } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import LeaderboardModal from 'modules/gambling/ui/GamblingHomepage/Leaderboard/LeaderboardModal/LeaderboardModal';
import './Leaderboard.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';

interface LeaderboardProps {
  tag: Tag;
}

interface Tag {
  web_tag: number;
  mobile_tag: number;
  top: any[];
  user_position?: any;
}

const Leaderboard = observer(({ tag }: LeaderboardProps) => {
  const { gamblingStore, overlayStore } = useStores();

  let user: any = null;
  tag?.top.find((player: any) => {
    // @ts-ignore
    if (player.username === tag.user_position?.username) {
      user = tag.user_position;
    }
  });

  useEffect(() => {
    const intervalTime = 5 * 60000; // one minute === 60000;
    const leaderBoardFetchInterval = setInterval(() => {
      gamblingStore.getLeaderBoard();
    }, intervalTime);

    return () => {
      window.clearInterval(leaderBoardFetchInterval);
    };
  }, []);

  const openLeaderboard = async () => {
    await gamblingStore.getLeaderBoard().then(() => {
      openLeaderboardModal();
    });
  };

  const openLeaderboardModal = () => {
    const modalContent = (
      <LeaderboardModal
        key={`Leaderboard-LeaderboardModal-${tag.web_tag}`}
        leaderboard={tag}
      />
    );

    overlayStore.openModal(modalContent, {
      width: 'medium',
    });
  };

  return (
    <div className="leaderboard">
      <div className="leaderboard__content">
        <div className="leaderboard__content__list">
          {tag?.top.map((player: any, index: number) => (
            <p key={`Leaderboard-p-${index}`}>
              <span
                className={
                  user?.username === player.username
                    ? 'text-yellow-500 sb-heading text-extra-strong'
                    : 'text-white text-extra-strong'
                }
              >
                {player.pos}. &nbsp;
              </span>
              <span
                className={
                  user?.username === player.username
                    ? 'text-yellow-500 sb-heading'
                    : 'text-green-200'
                }
              >
                {user?.username === player.username ? user?.id : player.id}
              </span>
            </p>
          ))}
        </div>

        {tag.user_position && tag.user_position?.pos > tag.top.length && (
          <div className="leaderboard__content__user">
            <div className="d-flex align-items">
              <p>&bull;</p>
              <p>&bull;</p>
              <p>&bull;</p>
            </div>
          </div>
        )}

        {tag.user_position && tag.user_position?.pos > tag.top.length && (
          <div className="leaderboard__content__user">
            {/* @ts-ignore */}
            <p className="ml-3 text-yellow-500">
              <span className="text-extra-strong">
                {tag.user_position?.pos}. &nbsp;{' '}
              </span>
              {tag.user_position?.id}
            </p>
          </div>
        )}
      </div>

      <div className="leaderboard__actions">
        <p
          onClick={openLeaderboard}
          className="uppercase text-green-200 pointer text-bold sb-text-small"
        >
          LEADERBOARD
        </p>
      </div>
    </div>
  );
});

export default Leaderboard;
