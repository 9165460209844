// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/notifications/notifications.proto" (package "notifications", syntax proto3)
// tslint:disable
import { ApproveNotificationsService } from "./notifications_pb";
import type { LimitsRequest } from "./notifications_pb";
import type { ManualCashOutRequest } from "./notifications_pb";
import type { ManualApprovalRequest } from "./notifications_pb";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { NotificationsService } from "./notifications_pb";
import type { EmptyError } from "../common/common_pb";
import type { ConfirmBmMessageRequest } from "./notifications_pb";
import type { OutcomeUpdateRequest } from "./notifications_pb";
import type { WebNotification } from "./notifications_pb";
import type { BroadcastBlankSlipRequest } from "./notifications_pb";
import type { BroadcastDoublePrintRequest } from "./notifications_pb";
import type { GetPushNotificationsResponse } from "./notifications_pb";
import type { Empty } from "../../google/protobuf/empty_pb";
import type { SystemNotificationRequest } from "./notifications_pb";
import type { BettingPlacesNotification } from "./notifications_pb";
import type { UserConfirmation } from "./notifications_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { NotificationResponse } from "./notifications_pb";
import type { UserNotification } from "./notifications_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service notifications.NotificationsService
 */
export interface INotificationsServiceClient {
    /**
     * @generated from protobuf rpc: NotifyUser(notifications.UserNotification) returns (notifications.NotificationResponse);
     */
    notifyUser(input: UserNotification, options?: RpcOptions): UnaryCall<UserNotification, NotificationResponse>;
    /**
     * @generated from protobuf rpc: ConfirmNotification(notifications.UserConfirmation) returns (notifications.NotificationResponse);
     */
    confirmNotification(input: UserConfirmation, options?: RpcOptions): UnaryCall<UserConfirmation, NotificationResponse>;
    /**
     * @generated from protobuf rpc: NotifyBettingPlaces(notifications.BettingPlacesNotification) returns (notifications.NotificationResponse);
     */
    notifyBettingPlaces(input: BettingPlacesNotification, options?: RpcOptions): UnaryCall<BettingPlacesNotification, NotificationResponse>;
    /**
     * @generated from protobuf rpc: SystemNotification(notifications.SystemNotificationRequest) returns (notifications.NotificationResponse);
     */
    systemNotification(input: SystemNotificationRequest, options?: RpcOptions): UnaryCall<SystemNotificationRequest, NotificationResponse>;
    /**
     * @generated from protobuf rpc: GetPushNotifications(google.protobuf.Empty) returns (notifications.GetPushNotificationsResponse);
     */
    getPushNotifications(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetPushNotificationsResponse>;
    /**
     * @generated from protobuf rpc: BroadcastDoublePrint(notifications.BroadcastDoublePrintRequest) returns (notifications.NotificationResponse);
     */
    broadcastDoublePrint(input: BroadcastDoublePrintRequest, options?: RpcOptions): UnaryCall<BroadcastDoublePrintRequest, NotificationResponse>;
    /**
     * @generated from protobuf rpc: BroadcastBlankSlip(notifications.BroadcastBlankSlipRequest) returns (notifications.NotificationResponse);
     */
    broadcastBlankSlip(input: BroadcastBlankSlipRequest, options?: RpcOptions): UnaryCall<BroadcastBlankSlipRequest, NotificationResponse>;
    /**
     * @generated from protobuf rpc: ValidationRulesUpdate(google.protobuf.Empty) returns (notifications.NotificationResponse);
     */
    validationRulesUpdate(input: Empty, options?: RpcOptions): UnaryCall<Empty, NotificationResponse>;
    /**
     * @generated from protobuf rpc: WebUsersNotification(notifications.WebNotification) returns (notifications.NotificationResponse);
     */
    webUsersNotification(input: WebNotification, options?: RpcOptions): UnaryCall<WebNotification, NotificationResponse>;
    /**
     * @generated from protobuf rpc: OutcomeUpdateNotification(notifications.OutcomeUpdateRequest) returns (notifications.NotificationResponse);
     */
    outcomeUpdateNotification(input: OutcomeUpdateRequest, options?: RpcOptions): UnaryCall<OutcomeUpdateRequest, NotificationResponse>;
    /**
     * @generated from protobuf rpc: ConfirmBmMessage(notifications.ConfirmBmMessageRequest) returns (common.EmptyError);
     */
    confirmBmMessage(input: ConfirmBmMessageRequest, options?: RpcOptions): UnaryCall<ConfirmBmMessageRequest, EmptyError>;
}
/**
 * @generated from protobuf service notifications.NotificationsService
 */
export class NotificationsServiceClient implements INotificationsServiceClient, ServiceInfo {
    typeName = NotificationsService.typeName;
    methods = NotificationsService.methods;
    options = NotificationsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: NotifyUser(notifications.UserNotification) returns (notifications.NotificationResponse);
     */
    notifyUser(input: UserNotification, options?: RpcOptions): UnaryCall<UserNotification, NotificationResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserNotification, NotificationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ConfirmNotification(notifications.UserConfirmation) returns (notifications.NotificationResponse);
     */
    confirmNotification(input: UserConfirmation, options?: RpcOptions): UnaryCall<UserConfirmation, NotificationResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserConfirmation, NotificationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: NotifyBettingPlaces(notifications.BettingPlacesNotification) returns (notifications.NotificationResponse);
     */
    notifyBettingPlaces(input: BettingPlacesNotification, options?: RpcOptions): UnaryCall<BettingPlacesNotification, NotificationResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingPlacesNotification, NotificationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SystemNotification(notifications.SystemNotificationRequest) returns (notifications.NotificationResponse);
     */
    systemNotification(input: SystemNotificationRequest, options?: RpcOptions): UnaryCall<SystemNotificationRequest, NotificationResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<SystemNotificationRequest, NotificationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPushNotifications(google.protobuf.Empty) returns (notifications.GetPushNotificationsResponse);
     */
    getPushNotifications(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetPushNotificationsResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, GetPushNotificationsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BroadcastDoublePrint(notifications.BroadcastDoublePrintRequest) returns (notifications.NotificationResponse);
     */
    broadcastDoublePrint(input: BroadcastDoublePrintRequest, options?: RpcOptions): UnaryCall<BroadcastDoublePrintRequest, NotificationResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<BroadcastDoublePrintRequest, NotificationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BroadcastBlankSlip(notifications.BroadcastBlankSlipRequest) returns (notifications.NotificationResponse);
     */
    broadcastBlankSlip(input: BroadcastBlankSlipRequest, options?: RpcOptions): UnaryCall<BroadcastBlankSlipRequest, NotificationResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<BroadcastBlankSlipRequest, NotificationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ValidationRulesUpdate(google.protobuf.Empty) returns (notifications.NotificationResponse);
     */
    validationRulesUpdate(input: Empty, options?: RpcOptions): UnaryCall<Empty, NotificationResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, NotificationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: WebUsersNotification(notifications.WebNotification) returns (notifications.NotificationResponse);
     */
    webUsersNotification(input: WebNotification, options?: RpcOptions): UnaryCall<WebNotification, NotificationResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<WebNotification, NotificationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: OutcomeUpdateNotification(notifications.OutcomeUpdateRequest) returns (notifications.NotificationResponse);
     */
    outcomeUpdateNotification(input: OutcomeUpdateRequest, options?: RpcOptions): UnaryCall<OutcomeUpdateRequest, NotificationResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<OutcomeUpdateRequest, NotificationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ConfirmBmMessage(notifications.ConfirmBmMessageRequest) returns (common.EmptyError);
     */
    confirmBmMessage(input: ConfirmBmMessageRequest, options?: RpcOptions): UnaryCall<ConfirmBmMessageRequest, EmptyError> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<ConfirmBmMessageRequest, EmptyError>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service notifications.ApproveNotificationsService
 */
export interface IApproveNotificationsServiceClient {
    /**
     * @generated from protobuf rpc: SendForManualApproval(notifications.ManualApprovalRequest) returns (notifications.NotificationResponse);
     */
    sendForManualApproval(input: ManualApprovalRequest, options?: RpcOptions): UnaryCall<ManualApprovalRequest, NotificationResponse>;
    /**
     * @generated from protobuf rpc: RemoveFromManualApproval(notifications.ManualApprovalRequest) returns (notifications.NotificationResponse);
     */
    removeFromManualApproval(input: ManualApprovalRequest, options?: RpcOptions): UnaryCall<ManualApprovalRequest, NotificationResponse>;
    /**
     * @generated from protobuf rpc: SendForManualCashOut(notifications.ManualCashOutRequest) returns (notifications.NotificationResponse);
     */
    sendForManualCashOut(input: ManualCashOutRequest, options?: RpcOptions): UnaryCall<ManualCashOutRequest, NotificationResponse>;
    /**
     * @generated from protobuf rpc: RemoveFromManualCashOut(notifications.ManualCashOutRequest) returns (notifications.NotificationResponse);
     */
    removeFromManualCashOut(input: ManualCashOutRequest, options?: RpcOptions): UnaryCall<ManualCashOutRequest, NotificationResponse>;
    /**
     * @generated from protobuf rpc: SendMinimumLimits(notifications.LimitsRequest) returns (notifications.NotificationResponse);
     */
    sendMinimumLimits(input: LimitsRequest, options?: RpcOptions): UnaryCall<LimitsRequest, NotificationResponse>;
    /**
     * @generated from protobuf rpc: RequestNewLimits(notifications.LimitsRequest) returns (notifications.NotificationResponse);
     */
    requestNewLimits(input: LimitsRequest, options?: RpcOptions): UnaryCall<LimitsRequest, NotificationResponse>;
}
/**
 * @generated from protobuf service notifications.ApproveNotificationsService
 */
export class ApproveNotificationsServiceClient implements IApproveNotificationsServiceClient, ServiceInfo {
    typeName = ApproveNotificationsService.typeName;
    methods = ApproveNotificationsService.methods;
    options = ApproveNotificationsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: SendForManualApproval(notifications.ManualApprovalRequest) returns (notifications.NotificationResponse);
     */
    sendForManualApproval(input: ManualApprovalRequest, options?: RpcOptions): UnaryCall<ManualApprovalRequest, NotificationResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ManualApprovalRequest, NotificationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveFromManualApproval(notifications.ManualApprovalRequest) returns (notifications.NotificationResponse);
     */
    removeFromManualApproval(input: ManualApprovalRequest, options?: RpcOptions): UnaryCall<ManualApprovalRequest, NotificationResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ManualApprovalRequest, NotificationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SendForManualCashOut(notifications.ManualCashOutRequest) returns (notifications.NotificationResponse);
     */
    sendForManualCashOut(input: ManualCashOutRequest, options?: RpcOptions): UnaryCall<ManualCashOutRequest, NotificationResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ManualCashOutRequest, NotificationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveFromManualCashOut(notifications.ManualCashOutRequest) returns (notifications.NotificationResponse);
     */
    removeFromManualCashOut(input: ManualCashOutRequest, options?: RpcOptions): UnaryCall<ManualCashOutRequest, NotificationResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<ManualCashOutRequest, NotificationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SendMinimumLimits(notifications.LimitsRequest) returns (notifications.NotificationResponse);
     */
    sendMinimumLimits(input: LimitsRequest, options?: RpcOptions): UnaryCall<LimitsRequest, NotificationResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<LimitsRequest, NotificationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RequestNewLimits(notifications.LimitsRequest) returns (notifications.NotificationResponse);
     */
    requestNewLimits(input: LimitsRequest, options?: RpcOptions): UnaryCall<LimitsRequest, NotificationResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<LimitsRequest, NotificationResponse>("unary", this._transport, method, opt, input);
    }
}
