/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import BankCredit from 'modules/user/ui/UserCreditDebit/CreditDebitBody/Credit/BankCredit';
import useQuery from 'libs/useQuery';
import './BankCreditPlaceholder.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const BankCreditPlaceholder = observer(() => {
  const { t, i18n } = useTranslation();
  const { authStore } = useStores();
  const [error, setError] = useState('');
  let queryParams = useQuery();

  const getParam = (param: string) => {
    return queryParams.get(param);
  };

  const handleBadParams = () => {
    setError(t('errors.system-error'));
  };

  const init = async (token: string | null) => {
    if (token) {
      authStore.setToken(token);
    }
  };

  useEffect(() => {
    const token = getParam('token') || '';
    const lang = getParam('lang') || 'me';

    i18n.changeLanguage(lang as string);
    if (token) {
      init(token);
    } else {
      handleBadParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bank-credit-placeholder">
      {!error ? (
        <BankCredit />
      ) : (
        <div className="bank-credit-placeholder__error">{error}</div>
      )}
    </div>
  );
});

export default BankCreditPlaceholder;
