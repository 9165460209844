/* React modules */

/* Our modules */
import i18n from 'app/localization/i18n';

/* 3rd Party modules */
import { toast } from 'react-toastify';

const grpcErrorListHandler = (errorList: string[]) => {
  if ((errorList || []).length) {
    errorList.forEach((errorItem) => {
      if (errorItem) {
        const errorOptions = errorItem.includes(':')
          ? { errorArgument: errorItem.split(':')[1] }
          : null;

        const item = errorItem.includes(':')
          ? errorItem.split(':')[0]
          : errorItem;

        if (errorOptions && i18n.t(`errors.${item}`, errorOptions)) {
          toast.error(i18n.t(`errors.${item}`, errorOptions));
        } else if (i18n.t(`errors.${item}`)) {
          toast.error(i18n.t(`errors.${item}`));
        }
      }
    });
  }
};

export { grpcErrorListHandler };
