/* React modules */

/* Our modules */
import feedStore from 'modules/feed/feed.store';
import sportsStore from 'modules/sports/store/sports.store';
import authStore from 'modules/auth/auth.store';
import userStore from 'modules/user/user.store';
import { ticketsStore } from 'modules/ticket/store/tickets-store';
import offerFilters from 'modules/offer/store/offer.filters';
import ticketBuilder from 'modules/ticket/store/ticket-builder';
import { NotificationsService } from 'modules/notifications/notifications.service';
import { SystemService } from 'modules/system/system.service';
import oddFeedService from 'modules/sports/services/odd-feed.service';

/* 3rd Party modules */
import { autorun } from 'mobx';

class AppService {
  systemService: SystemService | null = null;

  notificationsService: NotificationsService | null = null;

  setup(): void {
    sportsStore.resetState();
    sportsStore.loadData();

    oddFeedService.feedOdds = new Map();

    feedStore.start();

    this.connectSystemSocket();

    autorun(() => {
      if (sportsStore.sportsData) {
        offerFilters.onSportsLoad();
      }
    });
  }

  connectSystemSocket(): void {
    const handlers = {
      validation_rules_update: () => ticketBuilder.onRulesChanged(),
    };

    this.systemService = new SystemService(handlers);
  }

  disconnectSystemSocket = () => {
    if (this.systemService) {
      this.systemService.disconnect();
    }
  };

  onLogin() {
    userStore.initUser();
    userStore.getPokerBalance();

    if (authStore.token) {
      const handlers = {
        ...ticketsStore.getApprovalHandlers(),
      };

      this.notificationsService = new NotificationsService(
        handlers,
        authStore.token
      );
    } else {
      console.error(
        'Could not connect to notifications socket because token is not valid.'
      );
    }
  }
}

export const appService = new AppService();
