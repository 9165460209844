// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/loyalty/loyalty.proto" (package "loyalty", syntax proto3)
// tslint:disable
import { LoyaltyWebService } from "./loyalty_pb";
import type { WebLoyaltyConfig } from "./loyalty_pb";
import type { GetOrdersRequest } from "./loyalty_pb";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { LoyaltyBOService } from "./loyalty_pb";
import type { Transactions } from "./loyalty_pb";
import type { UserTransactionsRequest } from "./loyalty_pb";
import type { LevelCount } from "./loyalty_pb";
import type { Users } from "./loyalty_pb";
import type { GetUsersRequest } from "./loyalty_pb";
import type { Order } from "./loyalty_pb";
import type { Orders } from "./loyalty_pb";
import type { GetOrdersBORequest } from "./loyalty_pb";
import type { StoreItems } from "./loyalty_pb";
import type { EmptyError } from "../common/common_pb";
import type { StoreItemOrdering } from "./loyalty_pb";
import type { StoreItem } from "./loyalty_pb";
import type { UserLoyalty } from "./loyalty_pb";
import type { DisableUserLoyaltyMessage } from "./loyalty_pb";
import type { DisableLoyaltyMessage } from "./loyalty_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { LoyaltyConfig } from "./loyalty_pb";
import type { Empty } from "../../google/protobuf/empty_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service loyalty.LoyaltyBOService
 */
export interface ILoyaltyBOServiceClient {
    /**
     * @generated from protobuf rpc: GetLoyaltyConfig(google.protobuf.Empty) returns (loyalty.LoyaltyConfig);
     */
    getLoyaltyConfig(input: Empty, options?: RpcOptions): UnaryCall<Empty, LoyaltyConfig>;
    /**
     * @generated from protobuf rpc: SetLoyaltyConfig(loyalty.LoyaltyConfig) returns (loyalty.LoyaltyConfig);
     */
    setLoyaltyConfig(input: LoyaltyConfig, options?: RpcOptions): UnaryCall<LoyaltyConfig, LoyaltyConfig>;
    /**
     * @generated from protobuf rpc: DisableLoyalty(loyalty.DisableLoyaltyMessage) returns (loyalty.DisableLoyaltyMessage);
     */
    disableLoyalty(input: DisableLoyaltyMessage, options?: RpcOptions): UnaryCall<DisableLoyaltyMessage, DisableLoyaltyMessage>;
    /**
     * @generated from protobuf rpc: DisableUserLoyalty(loyalty.DisableUserLoyaltyMessage) returns (loyalty.DisableUserLoyaltyMessage);
     */
    disableUserLoyalty(input: DisableUserLoyaltyMessage, options?: RpcOptions): UnaryCall<DisableUserLoyaltyMessage, DisableUserLoyaltyMessage>;
    /**
     * @generated from protobuf rpc: GetUserLoyaltyBO(loyalty.UserLoyalty) returns (loyalty.UserLoyalty);
     */
    getUserLoyaltyBO(input: UserLoyalty, options?: RpcOptions): UnaryCall<UserLoyalty, UserLoyalty>;
    /**
     * @generated from protobuf rpc: CreateStoreItem(loyalty.StoreItem) returns (loyalty.StoreItem);
     */
    createStoreItem(input: StoreItem, options?: RpcOptions): UnaryCall<StoreItem, StoreItem>;
    /**
     * @generated from protobuf rpc: UpdateStoreItem(loyalty.StoreItem) returns (loyalty.StoreItem);
     */
    updateStoreItem(input: StoreItem, options?: RpcOptions): UnaryCall<StoreItem, StoreItem>;
    /**
     * @generated from protobuf rpc: DeleteStoreItem(loyalty.StoreItem) returns (loyalty.StoreItem);
     */
    deleteStoreItem(input: StoreItem, options?: RpcOptions): UnaryCall<StoreItem, StoreItem>;
    /**
     * @generated from protobuf rpc: UpdateStoreItemOrdering(loyalty.StoreItemOrdering) returns (common.EmptyError);
     */
    updateStoreItemOrdering(input: StoreItemOrdering, options?: RpcOptions): UnaryCall<StoreItemOrdering, EmptyError>;
    /**
     * @generated from protobuf rpc: GetAllStoreItems(google.protobuf.Empty) returns (loyalty.StoreItems);
     */
    getAllStoreItems(input: Empty, options?: RpcOptions): UnaryCall<Empty, StoreItems>;
    /**
     * @generated from protobuf rpc: GetOrdersBO(loyalty.GetOrdersBORequest) returns (loyalty.Orders);
     */
    getOrdersBO(input: GetOrdersBORequest, options?: RpcOptions): UnaryCall<GetOrdersBORequest, Orders>;
    /**
     * @generated from protobuf rpc: GetSingleOrderBO(loyalty.Order) returns (loyalty.Order);
     */
    getSingleOrderBO(input: Order, options?: RpcOptions): UnaryCall<Order, Order>;
    /**
     * @generated from protobuf rpc: ApproveOrder(loyalty.Order) returns (loyalty.Order);
     */
    approveOrder(input: Order, options?: RpcOptions): UnaryCall<Order, Order>;
    /**
     * @generated from protobuf rpc: DeliverOrder(loyalty.Order) returns (loyalty.Order);
     */
    deliverOrder(input: Order, options?: RpcOptions): UnaryCall<Order, Order>;
    /**
     * @generated from protobuf rpc: ConfirmOrder(loyalty.Order) returns (loyalty.Order);
     */
    confirmOrder(input: Order, options?: RpcOptions): UnaryCall<Order, Order>;
    /**
     * @generated from protobuf rpc: CancelOrderBO(loyalty.Order) returns (loyalty.Order);
     */
    cancelOrderBO(input: Order, options?: RpcOptions): UnaryCall<Order, Order>;
    /**
     * @generated from protobuf rpc: GetUsersBO(loyalty.GetUsersRequest) returns (loyalty.Users);
     */
    getUsersBO(input: GetUsersRequest, options?: RpcOptions): UnaryCall<GetUsersRequest, Users>;
    /**
     * @generated from protobuf rpc: GetLevelCount(google.protobuf.Empty) returns (loyalty.LevelCount);
     */
    getLevelCount(input: Empty, options?: RpcOptions): UnaryCall<Empty, LevelCount>;
    /**
     * @generated from protobuf rpc: GetUserTransactions(loyalty.UserTransactionsRequest) returns (loyalty.Transactions);
     */
    getUserTransactions(input: UserTransactionsRequest, options?: RpcOptions): UnaryCall<UserTransactionsRequest, Transactions>;
}
/**
 * @generated from protobuf service loyalty.LoyaltyBOService
 */
export class LoyaltyBOServiceClient implements ILoyaltyBOServiceClient, ServiceInfo {
    typeName = LoyaltyBOService.typeName;
    methods = LoyaltyBOService.methods;
    options = LoyaltyBOService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetLoyaltyConfig(google.protobuf.Empty) returns (loyalty.LoyaltyConfig);
     */
    getLoyaltyConfig(input: Empty, options?: RpcOptions): UnaryCall<Empty, LoyaltyConfig> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, LoyaltyConfig>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetLoyaltyConfig(loyalty.LoyaltyConfig) returns (loyalty.LoyaltyConfig);
     */
    setLoyaltyConfig(input: LoyaltyConfig, options?: RpcOptions): UnaryCall<LoyaltyConfig, LoyaltyConfig> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<LoyaltyConfig, LoyaltyConfig>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DisableLoyalty(loyalty.DisableLoyaltyMessage) returns (loyalty.DisableLoyaltyMessage);
     */
    disableLoyalty(input: DisableLoyaltyMessage, options?: RpcOptions): UnaryCall<DisableLoyaltyMessage, DisableLoyaltyMessage> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<DisableLoyaltyMessage, DisableLoyaltyMessage>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DisableUserLoyalty(loyalty.DisableUserLoyaltyMessage) returns (loyalty.DisableUserLoyaltyMessage);
     */
    disableUserLoyalty(input: DisableUserLoyaltyMessage, options?: RpcOptions): UnaryCall<DisableUserLoyaltyMessage, DisableUserLoyaltyMessage> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<DisableUserLoyaltyMessage, DisableUserLoyaltyMessage>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserLoyaltyBO(loyalty.UserLoyalty) returns (loyalty.UserLoyalty);
     */
    getUserLoyaltyBO(input: UserLoyalty, options?: RpcOptions): UnaryCall<UserLoyalty, UserLoyalty> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserLoyalty, UserLoyalty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateStoreItem(loyalty.StoreItem) returns (loyalty.StoreItem);
     */
    createStoreItem(input: StoreItem, options?: RpcOptions): UnaryCall<StoreItem, StoreItem> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<StoreItem, StoreItem>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateStoreItem(loyalty.StoreItem) returns (loyalty.StoreItem);
     */
    updateStoreItem(input: StoreItem, options?: RpcOptions): UnaryCall<StoreItem, StoreItem> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<StoreItem, StoreItem>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteStoreItem(loyalty.StoreItem) returns (loyalty.StoreItem);
     */
    deleteStoreItem(input: StoreItem, options?: RpcOptions): UnaryCall<StoreItem, StoreItem> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<StoreItem, StoreItem>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateStoreItemOrdering(loyalty.StoreItemOrdering) returns (common.EmptyError);
     */
    updateStoreItemOrdering(input: StoreItemOrdering, options?: RpcOptions): UnaryCall<StoreItemOrdering, EmptyError> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<StoreItemOrdering, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAllStoreItems(google.protobuf.Empty) returns (loyalty.StoreItems);
     */
    getAllStoreItems(input: Empty, options?: RpcOptions): UnaryCall<Empty, StoreItems> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, StoreItems>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOrdersBO(loyalty.GetOrdersBORequest) returns (loyalty.Orders);
     */
    getOrdersBO(input: GetOrdersBORequest, options?: RpcOptions): UnaryCall<GetOrdersBORequest, Orders> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetOrdersBORequest, Orders>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSingleOrderBO(loyalty.Order) returns (loyalty.Order);
     */
    getSingleOrderBO(input: Order, options?: RpcOptions): UnaryCall<Order, Order> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<Order, Order>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ApproveOrder(loyalty.Order) returns (loyalty.Order);
     */
    approveOrder(input: Order, options?: RpcOptions): UnaryCall<Order, Order> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<Order, Order>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeliverOrder(loyalty.Order) returns (loyalty.Order);
     */
    deliverOrder(input: Order, options?: RpcOptions): UnaryCall<Order, Order> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<Order, Order>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ConfirmOrder(loyalty.Order) returns (loyalty.Order);
     */
    confirmOrder(input: Order, options?: RpcOptions): UnaryCall<Order, Order> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<Order, Order>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CancelOrderBO(loyalty.Order) returns (loyalty.Order);
     */
    cancelOrderBO(input: Order, options?: RpcOptions): UnaryCall<Order, Order> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<Order, Order>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUsersBO(loyalty.GetUsersRequest) returns (loyalty.Users);
     */
    getUsersBO(input: GetUsersRequest, options?: RpcOptions): UnaryCall<GetUsersRequest, Users> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUsersRequest, Users>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLevelCount(google.protobuf.Empty) returns (loyalty.LevelCount);
     */
    getLevelCount(input: Empty, options?: RpcOptions): UnaryCall<Empty, LevelCount> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, LevelCount>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserTransactions(loyalty.UserTransactionsRequest) returns (loyalty.Transactions);
     */
    getUserTransactions(input: UserTransactionsRequest, options?: RpcOptions): UnaryCall<UserTransactionsRequest, Transactions> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserTransactionsRequest, Transactions>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service loyalty.LoyaltyWebService
 */
export interface ILoyaltyWebServiceClient {
    /**
     * @generated from protobuf rpc: GetLoyalty(google.protobuf.Empty) returns (loyalty.UserLoyalty);
     */
    getLoyalty(input: Empty, options?: RpcOptions): UnaryCall<Empty, UserLoyalty>;
    /**
     * @generated from protobuf rpc: GetStoreItems(google.protobuf.Empty) returns (loyalty.StoreItems);
     */
    getStoreItems(input: Empty, options?: RpcOptions): UnaryCall<Empty, StoreItems>;
    /**
     * @generated from protobuf rpc: PlaceOrder(loyalty.Order) returns (loyalty.Order);
     */
    placeOrder(input: Order, options?: RpcOptions): UnaryCall<Order, Order>;
    /**
     * @generated from protobuf rpc: CancelOrder(loyalty.Order) returns (loyalty.Order);
     */
    cancelOrder(input: Order, options?: RpcOptions): UnaryCall<Order, Order>;
    /**
     * @generated from protobuf rpc: GetOrders(loyalty.GetOrdersRequest) returns (loyalty.Orders);
     */
    getOrders(input: GetOrdersRequest, options?: RpcOptions): UnaryCall<GetOrdersRequest, Orders>;
    /**
     * @generated from protobuf rpc: GetSingleOrder(loyalty.Order) returns (loyalty.Order);
     */
    getSingleOrder(input: Order, options?: RpcOptions): UnaryCall<Order, Order>;
    /**
     * @generated from protobuf rpc: GetWebLoyaltyConfig(google.protobuf.Empty) returns (loyalty.WebLoyaltyConfig);
     */
    getWebLoyaltyConfig(input: Empty, options?: RpcOptions): UnaryCall<Empty, WebLoyaltyConfig>;
}
/**
 * @generated from protobuf service loyalty.LoyaltyWebService
 */
export class LoyaltyWebServiceClient implements ILoyaltyWebServiceClient, ServiceInfo {
    typeName = LoyaltyWebService.typeName;
    methods = LoyaltyWebService.methods;
    options = LoyaltyWebService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetLoyalty(google.protobuf.Empty) returns (loyalty.UserLoyalty);
     */
    getLoyalty(input: Empty, options?: RpcOptions): UnaryCall<Empty, UserLoyalty> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, UserLoyalty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetStoreItems(google.protobuf.Empty) returns (loyalty.StoreItems);
     */
    getStoreItems(input: Empty, options?: RpcOptions): UnaryCall<Empty, StoreItems> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, StoreItems>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PlaceOrder(loyalty.Order) returns (loyalty.Order);
     */
    placeOrder(input: Order, options?: RpcOptions): UnaryCall<Order, Order> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<Order, Order>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CancelOrder(loyalty.Order) returns (loyalty.Order);
     */
    cancelOrder(input: Order, options?: RpcOptions): UnaryCall<Order, Order> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<Order, Order>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOrders(loyalty.GetOrdersRequest) returns (loyalty.Orders);
     */
    getOrders(input: GetOrdersRequest, options?: RpcOptions): UnaryCall<GetOrdersRequest, Orders> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetOrdersRequest, Orders>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSingleOrder(loyalty.Order) returns (loyalty.Order);
     */
    getSingleOrder(input: Order, options?: RpcOptions): UnaryCall<Order, Order> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<Order, Order>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetWebLoyaltyConfig(google.protobuf.Empty) returns (loyalty.WebLoyaltyConfig);
     */
    getWebLoyaltyConfig(input: Empty, options?: RpcOptions): UnaryCall<Empty, WebLoyaltyConfig> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, WebLoyaltyConfig>("unary", this._transport, method, opt, input);
    }
}
