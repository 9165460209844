import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import './TicketStatusFilter.scss';
import { Icon, DatePicker, Select, Input } from 'components';
import { useTranslation } from 'react-i18next';
import { TICKET_STATUS_OPTIONS } from 'modules/ticket/ticket.constants';
import { TicketStatuses } from 'modules/ticket/ticket.types';

import { formatDatePickerDate } from 'libs/datetime';

type FilterDate = Date | null;

export interface TicketStatusFilterProps {
  active: TicketStatuses | 'all';
  onStatusChange: (filter: TicketStatuses) => void;
  onDateChange: (filter: [Date, Date]) => void;
  resetDateFilter: () => void;
  onIdFilterChange: (id: string) => void;
  isQuickTicketList: boolean;
}

export interface SelectOption {
  label: string;
  value: number;
}

let searchDebounce: any;

const TicketSearch = observer(({ onSearch }: any) => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');

  const searchById = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (typeof searchDebounce === 'number') {
      window.clearTimeout(searchDebounce);
    }
    searchDebounce = window.setTimeout(() => onSearch(searchTerm), 300);
  }, [searchTerm, onSearch]);

  return (
    <>
      <Input
        bg="transparent"
        name="search-id"
        value={searchTerm}
        onChange={searchById}
        placeholder={t('slips.ticket-id')}
        className="user-ticket-overview__search-ticket"
        height="small"
      />
    </>
  );
});

const TicketStatusFilter = observer(
  ({
    active,
    onStatusChange,
    onDateChange,
    resetDateFilter,
    onIdFilterChange,
    isQuickTicketList,
  }: TicketStatusFilterProps) => {
    const { t } = useTranslation();
    const statusOptions = TICKET_STATUS_OPTIONS(t);

    const [dateRange, setDateRange] = useState<[FilterDate, FilterDate]>([
      null,
      null,
    ]);
    const [startDate, endDate] = dateRange;

    const onDateFilterChange = (range: [FilterDate, FilterDate]) => {
      setDateRange(range);

      if (range[0] && range[1]) {
        onDateChange(range as [Date, Date]);
      } else if (!range[0] && !range[1]) {
        resetDateFilter();
      }
    };

    return (
      <div className="ticket-status-filter visible-mobile-up">
        {!isQuickTicketList && (
          <div className="d-flex align-items">
            <label htmlFor="filter-datepicker" className="d-flex">
              <Icon name="calendar" variant="lightgrey" className="mx-3" />
              <DatePicker
                placeholderText={t('globals.filter-by-date')}
                className="ticket-status-filter__datepicker ml-3"
                value={{
                  from: formatDatePickerDate(startDate),
                  to: formatDatePickerDate(endDate),
                }}
                onChange={onDateFilterChange}
                isRange
                id="filter-datepicker-transactions"
              />
            </label>
          </div>
        )}

        <div>
          <TicketSearch onSearch={onIdFilterChange} />
        </div>

        {!isQuickTicketList && (
          <Select
            className="mb-1 select"
            options={statusOptions}
            bg="sb-dark"
            size="sb-market"
            placeholder="Status tiketa"
            value={statusOptions.find((status: any) => status.value === active)}
            shape="squared"
            onChange={(status: any) => onStatusChange(status.value)}
          />
        )}
      </div>
    );
  }
);

export default TicketStatusFilter;
