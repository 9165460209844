/* React modules */

/* Our modules */
import { EventType, EVENT_TYPE } from 'modules/sports/sports.types';
import Event from 'modules/sports/store/event.store';
import offerFilters, { EventsFilters } from 'modules/offer/store/offer.filters';
import { SportNodeService } from 'modules/sports/services/sport-node.service';

/* 3rd Party modules */

class OfferEvents {
  getEvents(sport: SportNodeService, type: EventType, isOfferDisabled = false) {
    if (!sport || isOfferDisabled) return [];

    if (offerFilters.displayDefaultOffer) {
      return this.getFullOffer(sport, type);
    } else {
      return this.getOfferByCompetitions(sport, type);
    }
  }

  getFullOffer(sport: SportNodeService, type: EventType) {
    return sport.getEvents((e) => this.isEventVisible(e, type)) || [];
  }

  getOfferByCompetitions(sport: SportNodeService, type: EventType) {
    const { competitions } = offerFilters;
    const active = competitions.get(sport.id);
    if (!active) return [];

    return active.reduce((events: Event[], competition: SportNodeService) => {
      return [
        ...events,
        ...competition.getEvents((e) => this.isEventVisible(e, type)),
      ];
    }, []);
  }

  isEventVisible(event: Event, eventType: EventType) {
    const { type, start } = event;
    return type === eventType && offerFilters.timeFilterFunction(start.seconds);
  }

  getActiveCompetitions(sportId: number) {
    return offerFilters.competitions.get(sportId) || [];
  }

  isEventTypeHidden(
    type: EVENT_TYPE,
    offerState: { liveDisabled: boolean; upcomingDisabled: boolean }
  ) {
    if (type === EVENT_TYPE.LIVE) {
      return offerState.liveDisabled;
    } else if (type === EVENT_TYPE.UPCOMING) {
      return offerState.upcomingDisabled;
    }

    return false;
  }

  countEventsForType = (sports: SportNodeService[], type: EventType) => {
    return sports.reduce((count: number, sport: SportNodeService) => {
      return count + this.getEvents(sport, type).length;
    }, 0);
  };

  getEventsCount(sports: SportNodeService[], offerState: any) {
    const { upcomingDisabled, liveDisabled } = offerState;
    const liveEventsCount = liveDisabled
      ? 0
      : this.countEventsForType(sports, EVENT_TYPE.LIVE);
    const upcomingEventsCount = upcomingDisabled
      ? 0
      : this.countEventsForType(sports, EVENT_TYPE.UPCOMING);
    const antepostEventsCount = this.countEventsForType(
      sports,
      EVENT_TYPE.ANTEPOST
    );
    const playerEventsCount = this.countEventsForType(
      sports,
      EVENT_TYPE.PLAYER
    );
    const specialEventsCount = this.countEventsForType(
      sports,
      EVENT_TYPE.SPECIAL
    );

    const allEventsCount =
      liveEventsCount +
      upcomingEventsCount +
      antepostEventsCount +
      playerEventsCount +
      specialEventsCount;

    return {
      [EventsFilters.ALL]: allEventsCount,
      [EventsFilters.LIVE]: liveEventsCount,
      [EventsFilters.UPCOMING]: upcomingEventsCount,
      [EventsFilters.ANTEPOST]: antepostEventsCount,
      [EventsFilters.PLAYER]: playerEventsCount,
      [EventsFilters.SPECIAL]: specialEventsCount,
    };
  }

  groupByDate(events: Event[]) {
    return events.reduce((acc: any, event: any) => {
      const key = `${event.day}, ${event.date}`;

      (acc[key] = acc[key] || []).push(event);

      return acc;
    }, {});
  }

  groupByCompetition(events: Event[]) {
    return events.reduce((acc: any, event: any) => {
      const { competitionName, competitionId } = event;
      const key = competitionId + '=' + competitionName?.toUpperCase();
      // const key = competitionName?.toUpperCase();
      (acc[key] = acc[key] || []).push(event);

      return acc;
    }, {});
  }
}

export default new OfferEvents();
