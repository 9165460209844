/* React modules */

/* Our modules */
import { Empty } from 'generated-proto/google/protobuf/empty_pb';
import {
  InitialDataSportsMap,
  InitialDataMarketsMap,
  CounterMap,
  CounterSportMap,
} from 'generated-proto/proto/cache_service/cache_pb';
import { PlayerOutrightMarkets } from 'generated-proto/proto/odds/web_odds_pb';
import {
  EventStreamInt,
  WebStreamEventInt,
} from 'generated-proto/proto/odds_stream/odds_stream_pb';
import { OddsStreamServiceClient } from 'generated-proto/proto/odds_stream/odds_stream_pb.client';
import { SportsAPI } from 'modules/sports/services/sports.api';
import {
  EVENT_TYPE,
  Sport,
  AppConfigResponse,
} from 'modules/sports/sports.types';
import loaderStore from 'components/Loader/loader.store';
import { MarketService } from 'modules/sports/services/market-service';
import SportsData from 'modules/sports/services/sports-data.service';
import {
  sortSportsList,
  parseSportsMapToArray,
  parseMarketsMapToArray,
  sortCompetitions,
} from 'modules/sports/sports.service';
import { NODE_TYPE } from 'modules/sports/services/sport-node.service';
import { grpcErrorListHandler } from 'common/services/grpc-error-list-service';
import { getRpcTransport, getApiUrl } from 'libs/urlBuilder';
import { logger, parseJSONString } from 'libs/common-helpers';
import { IndexedDBService } from 'libs/indexeddb-service';

/* 3rd Party modules */
import { autorun, runInAction, makeAutoObservable } from 'mobx';

class SportsStore {
  indexedDBService: IndexedDBService;

  api: SportsAPI;
  events: WebStreamEventInt[] = [];
  sportsList: Sport[] = [];
  public offerCounters: Map<string, CounterSportMap> = new Map();

  specialsSport: Sport | null = null;
  firstBatchLoaded = false;
  allEventsLoaded = false;
  sportsLoaded = false;
  sportsData: SportsData | null = null;
  marketGroups: Map<number, MarketService> = new Map();
  playerOutrightMarketsConfig: PlayerOutrightMarkets | null = null;
  allEvents: WebStreamEventInt[] = [];
  deltaTimestamp: string | number = '';
  isPokerEnabled = false;
  liveStreamURL = '';
  isLiveStreamPlaying = false;

  constructor() {
    this.api = new SportsAPI();

    this.indexedDBService = new IndexedDBService('initialData', [
      'initialData',
    ]);

    makeAutoObservable(this);

    autorun(() => {
      if (this.firstBatchLoaded && this.sportsLoaded) {
        runInAction(() => {
          this.sportsData = new SportsData(this.sportsList, this.events);
          loaderStore.removeLoader('sports');
        });
      }

      if (this.allEventsLoaded && this.sportsLoaded) {
        runInAction(() => {
          this.sportsData = new SportsData(this.sportsList, this.events);
          loaderStore.removeLoader('sports');
        });

        this.events = [];
      }
    });
  }

  setLiveStreamURL = (url: string) => {
    this.liveStreamURL = url || '';
  };

  setIsLiveStreamPlaying = (isPlaying: boolean) => {
    this.isLiveStreamPlaying = isPlaying;
  };

  refetchEvent = async (sportId: number, competitionId: number, id: string) => {
    const sport = this.sportsData?.findSportById(sportId);
    const competition = sport?.findNode(NODE_TYPE.COMPETITION, competitionId);

    if (competition) {
      competition.removeEvent(id);

      try {
        const { response } = await this.api.getEventOdds(id);
        competition.addEvent(response);
      } catch (exception: any) {
        logger('SportsStore -> refetchEvent -> exception', exception);
      }
    }
  };

  resetState = () => {
    this.sportsList = [];
    this.marketGroups = new Map();
    this.sportsData = null;
    this.playerOutrightMarketsConfig = null;
    this.firstBatchLoaded = false;
    this.allEventsLoaded = false;
    this.sportsLoaded = false;
  };

  loadData = async () => {
    try {
      loaderStore.addLoader('sports');

      const { response } = await this.api.getPlayerOutrightMarkets();
      this.onPlayerOutrightMarketsLoad(response);

      await this.checkInitialData();

      const client = new OddsStreamServiceClient(
        getRpcTransport(getApiUrl('', { service: 'go' }))
      );

      const request: Empty = {};
      const streamingCall = client.webEventsStreamOrdered(request);

      for await (const response of streamingCall.responses) {
        this.onEventsLoad(response);
      }

      this.onEnd();
    } catch (exception: any) {
      logger('SportsStore -> loadData -> exception', exception);
    }
  };

  loadPokerConfiguration = () => {
    this.api.getTimestamp().then((response) => {
      this.isPokerEnabled = response.data.po;
    });
  };

  onOfferCounters = (data: CounterMap) => {
    this.offerCounters = new Map(Object.entries(data.sports));
  };

  checkInitialData = async () => {
    try {
      const hasStoredSports = await this.indexedDBService.keyExists(
        'initialData',
        'sports'
      );
      const hasStoredMarkets = await this.indexedDBService.keyExists(
        'initialData',
        'markets'
      );

      const response = await this.api.getTimestamp();
      const data = response.data;

      const isNewTimestamp = this.checkIsNewDeltaTimestamp(data);

      if (isNewTimestamp || !hasStoredSports || !hasStoredMarkets) {
        await this.fetchInitialData();
      } else {
        const { response } = await this.api.getInitialDelta();
        if (response) {
          this.onInitialDeltaLoad(response);
        }
      }

      const { response: offerCountersResponse } =
        await this.api.getOfferCounters('+02');

      this.onOfferCounters(offerCountersResponse);
    } catch (error) {
      logger('checkInitialData error', error);
    }
  };

  async fetchInitialData() {
    try {
      const { response: sportsResponse } = await this.api.getSports();
      await this.onSportsLoad(sportsResponse);

      const { response: marketsResponse } = await this.api.getMarkets();
      await this.onMarketsLoad(marketsResponse);
    } catch (error) {
      logger('Failed to fetch initial data', error);
    }
  }

  onEnd = () => {
    this.allEventsLoaded = true;
    this.firstBatchLoaded = true;

    if (this.sportsLoaded) {
      loaderStore.removeLoader('sports');
    }
  };

  onEventsLoad = (data: EventStreamInt) => {
    if (data) {
      const {
        upcomingEvents,
        liveEvents,
        playerEvents,
        groupEvents,
        outrightEvents,
      } = data;

      const processEvents = (
        events: WebStreamEventInt[] | undefined,
        eventType: EVENT_TYPE
      ) => {
        (events || []).forEach((event) => {
          (event.odds || []).forEach((odd) => {
            odd.value /= 100;
            odd.limit /= 100;
          });

          this.events.push({ ...event, type: eventType });
        });
      };

      processEvents(upcomingEvents?.events, EVENT_TYPE.UPCOMING);
      processEvents(liveEvents?.events, EVENT_TYPE.LIVE);
      processEvents(playerEvents?.events, EVENT_TYPE.PLAYER);
      processEvents(groupEvents?.events, EVENT_TYPE.SPECIAL);
      processEvents(outrightEvents?.events, EVENT_TYPE.ANTEPOST);
    }

    const firstBatchPiece = 80;

    if (this.events.length >= firstBatchPiece) {
      this.firstBatchLoaded = true;
    }

    this.allEvents = this.events;
  };

  async onSportsLoad(initialDataMap: InitialDataSportsMap) {
    try {
      const sportsList = parseSportsMapToArray(initialDataMap);

      await this.indexedDBService.put(
        'initialData',
        'sports',
        JSON.stringify(initialDataMap)
      );

      this.specialsSport =
        sportsList.find(
          (s) => s.id === this.playerOutrightMarketsConfig?.sportId
        ) || null;

      this.sportsList = sportsList.filter(
        (s) => s.id !== this.specialsSport?.id
      );

      this.sportsList = sortCompetitions(this.sportsList);

      this.sportsList = sortSportsList(this.sportsList);

      runInAction(() => {
        this.sportsLoaded = true;
      });
    } catch (error) {
      logger('onSportsLoad error', error);
    }
  }

  async onMarketsLoad(initialDataMap: InitialDataMarketsMap) {
    try {
      await this.indexedDBService.put(
        'initialData',
        'markets',
        JSON.stringify(initialDataMap)
      );

      const marketGroups = parseMarketsMapToArray(initialDataMap);

      marketGroups.sort((a, b) => (a.order > b.order ? 1 : -1));

      marketGroups.forEach((mg) => {
        (mg.marketsList || []).sort((a, b) => (a.order > b.order ? 1 : -1));
      });

      this.sportsList.forEach((sport: Sport) => {
        if (this.playerOutrightMarketsConfig) {
          this.marketGroups.set(
            sport.id,
            new MarketService(
              sport,
              marketGroups,
              this.playerOutrightMarketsConfig
            )
          );
        }
      });

      runInAction(() => {
        this.sportsLoaded = true;
      });

      if (this.firstBatchLoaded) {
        loaderStore.removeLoader('sports');
      }
    } catch (error) {
      logger('onMarketsLoad error', error);
    }
  }

  async onInitialDeltaLoad(deltaData: InitialDataSportsMap) {
    try {
      const storedSportsData = await this.indexedDBService.get<string>(
        'initialData',
        'sports'
      );

      if (storedSportsData) {
        const sportsMap = parseJSONString(
          storedSportsData
        ) as InitialDataSportsMap;
        const oldSportsList = parseSportsMapToArray(sportsMap);
        const deltaSportsList = parseSportsMapToArray(deltaData);

        deltaSportsList.forEach((sport) => {
          const localSport = oldSportsList.find((x) => x.id === sport.id);

          if (!localSport) {
            oldSportsList.push(sport);
            return;
          }

          localSport.name = sport.name;

          (sport.locationsList || []).forEach((location) => {
            const localLocation = (localSport.locationsList || []).find(
              (x) => x.id === location.id
            );

            if (!localLocation) {
              (localSport.locationsList || []).push(location);
              return;
            }

            localLocation.name = location.name;
            localLocation.flagCode = location.flagCode;

            (location.competitionsList || []).forEach((competition) => {
              const localCompetition = (
                localLocation.competitionsList || []
              ).find((x) => x.id === competition.id);

              if (!localCompetition) {
                (localLocation.competitionsList || []).push(competition);
                return;
              }

              Object.assign(localCompetition, competition);
            });
          });
        });

        this.specialsSport =
          oldSportsList.find(
            (s) => s.id === this.playerOutrightMarketsConfig?.sportId
          ) || null;

        this.sportsList = oldSportsList.filter(
          (s) => s.id !== this.specialsSport?.id
        );

        this.sportsList = sortCompetitions(this.sportsList);

        this.sportsList = sortSportsList(this.sportsList);

        const storedMarketsData = await this.indexedDBService.get<string>(
          'initialData',
          'markets'
        );

        if (storedMarketsData) {
          const markets = parseJSONString(
            storedMarketsData
          ) as InitialDataMarketsMap;

          await this.onMarketsLoad(markets);
        }
      }
    } catch (error) {
      logger('onInitialDeltaLoad error', error);
    }
  }

  onPlayerOutrightMarketsLoad = (markets: PlayerOutrightMarkets) => {
    this.playerOutrightMarketsConfig = markets;
  };

  checkIsNewDeltaTimestamp = (data: AppConfigResponse) => {
    const storedDeltaTimestamp = localStorage.getItem('deltaTimestamp');

    const oldTimestamp = storedDeltaTimestamp
      ? parseJSONString(storedDeltaTimestamp)
      : null;

    const newTimestamp = data.ts;

    if (newTimestamp === oldTimestamp) {
      return false;
    }

    localStorage.setItem('deltaTimestamp', JSON.stringify(newTimestamp));
    return true;
  };

  getMissingCompetition = async (id: number) => {
    try {
      const { response } = await this.api.getMissingCompetition(id);
      return response || null;
    } catch (exception: any) {
      logger('SportsStore -> getMissingCompetition -> exception', exception);
      return null;
    }
  };

  getPublicIP = async () => {
    try {
      const { data } = await this.api.getPublicIP();
      return data || '';
    } catch (exception: any) {
      logger('SportsStore -> getPublicIP -> exception', exception);
      return '';
    }
  };

  getLiveStreamURL = async (
    streamId: string,
    userId: number,
    accessToken: string
  ) => {
    try {
      const publicIP = await this.getPublicIP();

      if (publicIP) {
        const { response } = await this.api.getLiveStreamURL(
          streamId,
          userId,
          publicIP,
          accessToken
        );

        if (response.error) {
          grpcErrorListHandler(response.error.message);
        }

        this.setLiveStreamURL(response.url || '');
      }
    } catch (exception: any) {
      logger('SportsStore -> getLiveStreamURL -> exception', exception);
    }
  };

}

export default new SportsStore();
