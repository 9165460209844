/* React modules */

/* Our modules */
import SportMarketBase from 'modules/sports/market-base-model';
import { Market, MarketGroup } from 'modules/sports/sports.types';

/* 3rd Party modules */

class SportMarketGroup extends SportMarketBase {
  public marketsList: Market[];

  constructor(data: MarketGroup) {
    super(data);

    this.marketsList = data.marketsList || [];
  }
}

export { SportMarketGroup };
