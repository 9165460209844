/* React modules */

/* Our modules */
import { logger } from 'libs/common-helpers';

/* 3rd Party modules */

class IndexedDBService {
  private dbPromise: Promise<IDBDatabase>;

  constructor(dbName: string, storeNames: string[]) {
    this.dbPromise = this.openDB(dbName, storeNames);
  }

  private openDB(dbName: string, storeNames: string[]): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName);

      request.onerror = (event) => {
        logger(`Failed to open IndexedDB: ${dbName}`, event);
        reject(event);
      };

      request.onsuccess = () => {
        const db = request.result;
        resolve(db);
      };

      request.onupgradeneeded = (event) => {
        const db = request.result;
        storeNames.forEach((storeName) => {
          if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName);
          }
        });
      };
    });
  }

  async keyExists(storeName: string, key: string): Promise<boolean> {
    const db = await this.dbPromise;

    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], 'readonly');
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.getKey(key);

      request.onsuccess = () => {
        resolve(request.result !== undefined);
      };

      request.onerror = (event) => {
        logger(`Error checking key '${key}' in IndexedDB`, event);
        reject(event);
      };
    });
  }

  async get<T>(storeName: string, key: string): Promise<T | null> {
    const db = await this.dbPromise;

    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], 'readonly');
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.get(key);

      request.onsuccess = () => {
        resolve(request.result ? (request.result as T) : null);
      };

      request.onerror = (event) => {
        logger(`Error getting key '${key}' from IndexedDB`, event);
        reject(event);
      };
    });
  }

  async put(storeName: string, key: string, value: any): Promise<void> {
    const db = await this.dbPromise;

    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], 'readwrite');
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.put(value, key);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        logger(`Error putting key '${key}' in IndexedDB`, event);
        reject(event);
      };
    });
  }
}

export { IndexedDBService };
