const me = {
  translation: {
    casino: {
      'all-games': 'SVE IGRE',
      award: 'Nagrada',
      back: 'NAZAD',
      'biggest-wins': 'NAJVEĆI DOBICI',
      'casino-brands': 'KAZINO BRENDOVI',
      categories: 'KATEGORIJE',
      category: 'KATEGORIJA',
      close: 'ZATVORI',
      demo: 'PROBAJ',
      'favourite-games': 'OMILJENE IGRE',
      'game-of-week': 'IGRA NEDJELJE',
      'last-played': 'POSLJEDNJE ODIGRANO',
      'most-played': 'NAJIGRANIJE',
      play: 'IGRAJ',
      points: 'Poeni',
      provider: 'BREND',
      sections: 'SEKCIJE',
      'see-all': 'VIDI SVE',
      'use-free-casino': 'Koristi free spin',
      user: 'Korisnik',
    },
    countries: {
      albania: 'Albanija',
      'bosnia-and-herzegovina': 'Bosna i Hercegovina',
      croatia: 'Hrvatska',
      kosovo: 'Kosovo',
      montenegro: 'Crna Gora',
      'republic-of-north-macedonia': 'Severna Makedonija',
      russia: 'Rusija',
      serbia: 'Srbija',
      turkey: 'Turska',
      ukraine: 'Ukrajina',
    },
    errorPage: {
      'reload-page': 'Molimo vas da osvježite stranicu.',
      'something-went-wrong': 'Desila se greška.',
    },
    errors: {
      'activation-link-exp':
        'Da biste dovršili registraciju potrebno je da aktivirate Vaš nalog. Aktivacioni link Vam je poslat na email adresu.',
      'another-order-exists': 'Druga porudžbina već postoji',
      'bad-password': 'Pogrešna lozinka',
      'bet-not-possible': 'Trenutno nije moguće klađenje na ovaj događaj',
      'betting-blocked': 'Klađenje je blokirano.',
      'can-not-find-user': 'Nije moguće pronaći korisnika',
      'cancel-funds': 'Zakazana isplata nije pronađena',
      'cannot-add-more-than-one-digital-item':
        'Ne možete dodati vise od jednog proizvoda digitalnog tipa u korpu',
      'cannot-buy-casino-bonus-if-you-has-one':
        'Ne možete kupiti kazino bonus ako vec imate postojeći',
      'cannot-cancel-order': 'Ne možete otkazati porudžbinu',
      'cannot-order-mixed-items':
        'Ne možete poručiti različite tipove proizvoda',
      'cannot-order-more-than-one-digital-item':
        'Ne možete poručiti vise od jednog proizvoda digitalnog tipa',
      'choose-betting-place': 'Izaberite mesto preuzimanja',
      'connection-offline': 'Veza sa internetom je izgubljena!',
      'credentials-error': 'Korisničko ime ili lozinka nisu ispravno unešeni.',
      'data-error-reading':
        'Greška pri čuvanju podataka. Molimo Vas pokušajte ponovo.',
      'email-exists': 'Korisnik sa ovom email adresom već postoji.',
      'email-invalid': 'Email nije validan',
      'err-cash-drop-already-collected':
        'Neko je bio brži od Vas. CashDrop je iskorišćen i više nije dostupan.',
      'err-cash-drop-already-collected-by-user': 'CashDrop je već pokupljen',
      'err-cash-drop-group-already-collected-by-user':
        'Ne možete pokupiti CashDrop iz ove grupe',
      'err-cash-drop-not-active': 'CashDrop je istekao i više nije dostupan.',
      'err-cash-drop-not-found': 'CashDrop nije pronadjen',
      'err-cash-drop-user-min-balance':
        'Morate imati najmanje {{a}} EUR da bi ste pokupili cash drop',
      'err-cash-drop-user-min-bet':
        'Morate potrošiti najmanje {{a}} EUR u poslednjih {{b}} sati da bi ste mogli da pokupite CashDrop',
      ERR_BETTING_MACHINE_BLOCKED: 'Ova akcija je blokirana',
      ERR_CANT_PAYOUT_BETTING_PLACE:
        'Vaučer nije moguće isplatiti na ovom uplatnom mjestu',
      ERR_CANT_PAYOUT_MUNICIPALITY:
        'Vaučer nije moguće isplatiti u ovoj opštini',
      ERR_CASINO_BLOCKED: 'Kazino igre su blokirane',
      ERR_DEPOSIT_BLOCKED: 'Korisniku je blokirana uplata',
      ERR_DEPOSIT_BLOCKED_FOR_BETTING_MACHINE:
        'Uplata na kladomat je blokirana',
      ERR_INVALID_GAME: 'Igra nije validna',
      ERR_TIME_LIMIT_EXCEEDED_FOR_PAYOUT:
        'Tiket nije više validan za isplatu. Molimo vas obratite se operateru.',
      ERR_USER_BLOCKED_ALREADY: 'Vaš nalog je već blokiran.',
      ERR_USER_NOT_VALIDATED: 'Korisnik nije verifikovan',
      ERR_VIRTUAL_GAMES_BLOCKED: 'Virtualne igre su blokirane',
      ERR_VOUCHER_CONFIG_NOT_FOUND: 'Konfiguracija za vaučer ne postoji',
      ERR_VOUCHER_EXPIRED: 'Vaučer je istekao',
      ERR_VOUCHER_NOT_FOUND: 'Vaučer ne postoji',
      ERR_VOUCHER_STATUS_NOT_VALID_FOR_PAYOUT: 'Vaučer nije validan za isplatu',
      ERR_STREAM_NOT_AVAILABLE: 'Live stream trenutno nije dostupan',
      ERR_STREAM_TYPE_NOT_AVAILABLE: 'Ovaj tip Live stream-a nije podržan',
      ERR_LOCATION_NOT_SUPPORTED: 'Live stream nije podržan na vašoj lokaciji',
      ERR_IP_NOT_ALLOWED: 'Live stream nije dozvoljen za vašu IP adresu',
      ERR_STREAM_NOT_ALLOWED: 'Live stream nije dozvoljen',
      ERR_STREAM_NOT_ALLOWED_FOR_UNVALIDATED_USER:
        'Live stream nije dozvoljen za neverifikovane korisnike',
      ERR_MINIMUM_STREAMING_BALANCE:
        'Live stream je dostupan ako imate minimum {{errorArgument}} EUR na računu',
      'error-starting-game': 'Igrica ne može biti pokrenuta',
      'error-unknown': 'Došlo je do greške, molimo Vas pokušajte ponovo.',
      'field-invalid': 'Unos nije validan',
      'field-required': 'Polje je obavezno',
      'fields-obligated': 'Polja naznačena (*) su obavezna.',
      'invalid-system-slip': 'Sistemski tiket nije validan',
      'invalid-user': 'Korisnik nije validan',
      'item-not-available': 'Proizvod nije dostupan',
      'item-not-found': 'Proizvod nije pronađen',
      'jmbg-max': 'JMBG ne može sadržati više od 13 karaktera.',
      'jmbg-min': 'JMBG mora imati minimalno 13 karaktera.',
      'min-password': 'Lozinka mora da sadrži minimum 6 karaktera.',
      'no-betting-place-id': 'Izaberite poslovnicu',
      'no-events-for-copy': 'Nema događaja koji mogu biti iskopirani',
      'no-items-in-order': 'Nema proizvoda u porudžbini',
      'not-enough-points': 'Nemate dovoljno poena',
      'order-does-not-exist': 'Porudžbina ne postoji',
      'passwords-dont-match': 'Lozinka se ne podudara.',
      'payout-error': 'Došlo je do greške prlikom pokušaja isplate.',
      'place-bet-error':
        'Greška pri uplati tiketa. Molimo Vas da pokušate ponovo.',
      'poker-not-responding': 'Poker server je trenutno nedostupan',
      'poker-success-false': 'Poker server akcija nije uspešna',
      'print-error':
        'Došlo je do greške prlikom pokušaja štampanja. Ukoliko tiket nije odštampan obratite se operateru.',
      'print-not-operational': 'ŠTAMPAČ NIJE U FUNKCIJI',
      'promo-code-invalid': 'Promo kod nije validan',
      'reservation-locked': 'Rezervisana sredstva su zaključana',
      'session-expired': 'Sesija je istekla, molimo Vas prijavite se ponovo.',
      'system-error':
        'Došlo je do greške u sistemu. Molimo Vas da pokušate ponovo.',
      TEST_USER_CANNOT_DEPOSIT_MONEY_WITH_CARD:
        'Testni korisnici ne mogu uplaćivati novac karticom.',
      TEST_USERS_CANNOT_WITHDRAW_MONEY:
        'Testni korisnici ne mogu isplaćivati novac.',
      'ticket-changed': 'Vaš tiket je promijenjen.',
      'ticket-denied': 'Vaš tiket je odbijen.',
      'ticket-error-reading': 'Greška pri očitavanju tiketa.',
      TICKET_NOT_FOUND: 'Tiket nije pronađen',
      TICKET_NOT_VALID_FOR_PAYOUT:
        'Tiket nije više validan za isplatu. Molimo vas obratite se operateru.',
      'token-expired': 'Token je istekao',
      'user-blocked-error': 'Vaš nalog je blokiran. Kontaktirajte podršku.',
      'user-exists': 'Korisnik već postoji.',
      'user-not-active':
        'Korisnik nije aktiviran. Molimo vas da aktivirate vaš nalog.',
      'user-update-error':
        'Greška pri čuvanju podataka. Molimo Vas pokušajte ponovo.',
      'username-exists': 'Korisnik sa ovim korisničkim imenom već postoji.',
      VALIDATION_CANNOT_MIX_LANDBASE_CODES:
        'Antepost događaje nije moguće kombinovati na jednom tiketu',
      'withdrawal-canceled-or-paid':
        'Isplata je otkazana ili isplaćena. Molimo vas da osvježite stranicu.',
      'withdrawal-max-amount-exceeded':
        'Maksimalna dozvoljena isplata je {{a}}€',
      'withdrawal-min-amount-exceeded':
        'Minimalna dozvoljena isplata je {{a}}€',
      'zero-item-quantity': 'Nema proizvoda na stanju',
    },
    footer: {
      'about-us': 'O nama',
      'account-id': 'ID NALOGA',
      'all-rights-reserved': 'Sva prava zadržana',
      'available-for-payment': 'Raspoloživo za uplatu',
      aviator: 'Aviator',
      betting: 'Klađenje',
      bonuses: 'Bonusi',
      casino: 'Casino',
      'casino-live': 'Casino uživo',
      contact: 'Kontakt',
      cookies: 'Kolačići (Cookies)',
      description:
        'SBbet se još od 1997. godine bavi organizovanjem igara na sreću i pruža svojim klijentima usluge na najvišem nivou. Sa mrežom od preko 100 uplatnih mjesta u Crnoj Gori, pozicionirani smo kao lideri na tržištu, što nas dodatno motiviše da naše poslovanje usmjerimo ka kreiranju zabave svjetskog kvaliteta u oblasti igara na sreću. SBbet sajt je dizajniran da vam omogući klađenje uz najbolje korisničko iskustvo. Ogromna ponuda sportova i takmičenja, atraktivne kvote, bonusi, fribetovi i mogućnost osvajanja velikog iznosa novca putem naših Jackpotova su samo neki od razloga da ukažete povjerenje najstarijoj kladionici u Crnoj Gori, sa preko 20 godina iskustva. Oprobajte sreću i na nekoj od mnogobrojnih kazinskih igrica, našem bingu, virtuelnim sportskim događajima ili na pravom kazinskom stolu. SBbet.me je dio Sporting Group doo, Mediteranska 21, Budva, Crna Gora i regulisan je zakonima Crne Gore i nosilac je licence izdate od strane Uprave za igre na sreću: 01-424/24-742/5. Puno sreće Vam želi SBbet tim - Siguran Fiks!',
      'download-list': 'Preuzmi listu',
      'follow-us': 'Pratite nas',
      'frequently-questions': 'Česta pitanja',
      'game-rules': 'Pravila igre',
      informations: 'Informacije',
      'lucky-6': 'Lucky6',
      'pay-all': 'UPLATI SVE',
      'payment-to-web': 'Uplata na WEB',
      'play-responsibly': 'Igraj odgovorno',
      poker: 'Poker',
      privacy: 'Privatnost',
      'promo-code': 'Promo kod',
      promotions: 'Promocije',
      'site-map': 'Mapa sajta',
      sport: 'Sport',
      'ticket-check': 'Provjera tiketa',
      transactions: 'Transakcije',
      'using-conditions': 'Uslovi korišćenja',
    },
    globals: {
      accept: 'PRIHVATI',
      'accept-cookies':
        'Ovaj sajt koristi kolačiće radi unapređenja usluga, kako bi vam prikazali relevantan sadržaj. Ako nastavite pretraživanje na sajtu, smatramo da ste saglasni sa upotrebom kolačića.',
      account: 'Nalog',
      'account-type': 'Tip računa',
      activation: 'AKTIVACIJA',
      'add-to-cart': 'Dodaj u korpu',
      all: 'Sve',
      'amount-caps': 'IZNOS',
      'app-idle-msg':
        'Niste bili aktivni neko vrijeme. Kliknite osvježi za ažurnu ponudu.',
      approved: 'Odobreno',
      'betting-history': 'Istorija klađenja',
      'booking-an-order': 'Rezervacija porudžbine',
      braon: 'Braon',
      cancel: 'ODUSTANI',
      canceled: 'Odbijeno',
      cart: 'Korpa',
      'cart-is-empty': 'Korpa je prazna',
      Cet: 'Čet',
      'choose-a-betting-place': 'Izaberite poslovnicu',
      'choose-a-city': 'Izaberite grad',
      'choose-order-place':
        'Izaberite mjesto dostave u odabranoj SBbet poslovnici',
      'click-for-more-info': 'Klikni za više informacija',
      close: 'ZATVORI',
      'code-caps': 'KOD',
      collect: 'Pokupi',
      copy: 'Kopiraj',
      'copy-ticket': 'Kopiraj tiket',
      'customer-support': 'Korisnička podrška',
      date: 'Datum',
      'date-caps': 'DATUM',
      delivered: 'Dostavljeno',
      'filter-by-date': 'Filtriraj po datumu',
      free: 'Besplatno',
      'id-caps': 'ID',
      immediately: 'Odmah',
      'installation-guide': 'Uputstvo za instalaciju',
      loading: 'Učitavanje...',
      'mark-all-as-read': 'Označi sve kao pročitano',
      'my-account': 'Moj nalog',
      'necessary-reload': 'Neophodno je izvršiti sinhronizaciju ponude',
      Ned: 'Ned',
      nepar: 'Nepar',
      no: 'NE',
      'no-info-about-event': 'Nema informacija o događaju',
      notifications: 'Obavještenja',
      numbers: 'BROJEVI',
      'order-history': 'Istorija porudžbina',
      par: 'Par',
      pending: 'Na čekanju',
      Pet: 'Pet',
      'pick-date': 'Izaberi datum',
      'place-order': 'Poruči proizvode',
      Pon: 'Pon',
      proceed: 'Osvježi',
      profile: 'Profil',
      'promo-code': 'Promo kod',
      register: 'REGISTRACIJA',
      'remove-from-cart': 'Izbaci iz korpe',
      save: 'Sačuvaj',
      'sbbet-promo-code': 'SBbet PROMO KOD',
      search: 'Pretraga',
      'search-1': 'Pretraži',
      'search-events': 'Pretraži događaje',
      send: 'PROSLIJEDI',
      'send-1': 'POŠALJI',
      Sre: 'Sre',
      statistics: 'STATISTIKA',
      'status-caps': 'STATUS',
      step: 'Korak',
      Sub: 'Sub',
      submit: 'POTVRDI',
      success: 'Preuzeto',
      'successfully-collected-cash-drop': 'Uspješno ste pokupili cash drop',
      'ticket-copied': 'Tiket kopiran',
      time: 'Vrijeme',
      'transaction-history': 'Istorija transakcija',
      type: 'Tip',
      'type-caps': 'TIP',
      'user-id-caps': 'KORISNIČKI ID',
      Uto: 'Uto',
      wallets: 'Računi',
      welcome: 'Dobrodošli!',
      yes: 'DA',
      zelena: 'Zelena',
      žuta: 'Žuta',
    },
    header: {
      betting: 'Klađenje',
      casino: 'Kazino',
      'casino-live': 'Uživo kazino',
      live: 'Uživo',
      'quick-games': 'Brze igre',
    },
    home: {
      '100-spins': '100 SPINOVA',
      'be-in-the-game-with-app': 'BUDI U IGRI SA SBBET APLIKACIJOM',
      betting: 'KLAĐENJE',
      'betting-description':
        'Čekaju te najveće kvote i najraznovrsnija ponuda igara kao i specijala.',
      'call-center': 'CALL CENTAR',
      'casino-description':
        'Najpopularnije casino igre donose velike džekpotove!',
      'follow-us': 'PRATI NAS',
      'live-betting': 'UŽIVO KLAĐENJE',
      'live-betting-description':
        'Klađenje uživo sada je duplo bolje, jer te u SBbet-u čeka najveći izbor live sportova i široka ponuda igara!',
      'place-bet': 'KLADI SE',
      'promotions-description':
        'Saznaj sve o novim promocijama i aktuelnostima.',
      'quick-games-description':
        'Najveći izbor virtuala: brojevi, fudbal, trke i još mnogo toga!',
      register: 'Registruj se',
      'up-to-250': 'DO 250',
      'we-double-your-first-deposit': 'DUPLIRAMO TI PRVI DEPOZIT',
    },
    language: {
      english: 'Engleski',
      language: 'Jezik',
      montenegrin: 'Crnogorski',
      'select-language': 'Izaberi jezik',
    },
    login: {
      'forgot-password': 'Zaboravio sam lozinku',
      'forgot-password-caps': 'ZABORAVILI STE LOZINKU?',
      'log-in': 'ULOGUJ SE',
      'log-out': 'Odjavi se',
      login: 'PRIJAVA',
      logout: 'ODJAVI SE',
      'logout-1': 'ODJAVA',
      'logout-question': 'Da li ste sigurni da želite da se odjavite?',
      'sign-in': 'PRIJAVI SE',
    },
    navigation: {
      aviator: 'AVIATOR',
      betting: 'KLAĐENJE',
      casino: 'CASINO',
      liveCasino: 'CASINO UŽIVO',
      lucky6: 'LUCKY6',
      numbers: 'BROJEVI',
      offer: 'PONUDA',
      poker: 'POKER',
      promotions: 'PROMOCIJE',
      quickGames: 'BRZE IGRE',
      sport: 'KLAĐENJE i UŽIVO',
    },
    payments: {
      'account-id-confirm': 'Potvrdite da je vaš ID NALOGA:',
      amount: 'Iznos',
      'available-for-payment': 'Dostupno za isplatu',
      balance: 'Saldo',
      'balance-after': 'Iznos poslije',
      'balance-before': 'Iznos prije',
      'bonus-balance': 'Stanje bonusa',
      'cancel-withdrawal': 'Otkaži isplatu',
      'cancel-withdrawal-question':
        'Da li ste sigurni da želite otkazati isplatu?',
      'cancel-withdrawal-success': 'Uspješno ste otkazali isplatu.',
      'card-expired': 'Transakcija je odbijena. Kartica je istekla.',
      'card-not-found': 'Kartica nije pronađena. Molimo Vas pokušajte ponovo.',
      'card-suspended': 'Transakcija je odbijena. Kartica je suspendovana.',
      commission: 'Provizija',
      credit: 'Isplata',
      'credit-card': 'Kreditna kartica',
      debit: 'Uplata',
      'e-wallet': 'E-novčanik',
      'funds-available-for-payment': 'Sredstva dostupna za isplatu',
      'funds-that-can-be-raised': 'SREDSTVA KOJA SE MOGU PODIĆI',
      'in-the-bank': 'U BANCI',
      'in-the-bank-note':
        'Ukoliko Vaše Ime i Prezime nisu ispravni, molim Vas da kontaktirate Podršku',
      'in-the-office': 'U POSLOVNICI',
      'invalid-amount': 'Transakcija je odbijena. Iznos nije validan.',
      'invalid-card-number':
        'Transakcija je odbijena. Broj kartice nije validan.',
      'maximum-credit': 'Maksimalna isplata',
      'maximum-debit': 'Maksimalna uplata',
      'minimum-credit': 'Minimalna isplata',
      'minimum-debit': 'Minimalna uplata',
      pay: 'Uplati',
      'payment-default-error':
        'Transakcija je odbijena. Molimo Vas pokušajte ponovo.',
      'payment-destination': 'Destinacija plaćanja',
      'payment-success': 'Uplata je uspješno izvršena.',
      'physical-payment': 'Fizička uplata',
      'poker-withdrawal-success': 'Isplata je uspješno izvršena.',
      'potential-payout': 'Potencijalna isplata:',
      'proceed-with-payment': 'Nastavite sa plaćanjem klikom na dugme',
      skrill: 'Skrill',
      tabaccopress: {
        'deposit-step-1':
          'Od prodavca na nekom od Tobacco S Press prodajnih mjesta zahtijevajte uplatu depozita na svoj Sbbet nalog putem platnih terminala',
        'deposit-step-2':
          'Neophodno je da prodavcu saopštite ID broj svog SBBet naloga i iznos koji želite da uplatite.',
        'deposit-step-3':
          'Iznos koji ste uplatili će Vam biti raspoloživ na Vašem SBBet nalogu najčešće u roku od nekoliko minuta od trenutka uplate.',
        'deposit-steps': 'Kako uplatiti depozit putem platnih terminala?',
        'instruction-1':
          'Za uplatu putem platnih terminala potreban je samo korisnički broj Vašeg SBbet računa (ID broj).',
        'instruction-2':
          'Mogućnost uplate putem platnih terminala možete izrvšiti na nekoj od 300+ prodajnih mjesta Tobacco S Press-a',
        'instruction-3':
          'Minimalni iznos uplate putem platnih terminala je 1.00 EUR, a maksimalni iznos uplate po transakciji je 500.00 EUR.',
        'instruction-4':
          'U slučaju greške vremenski rok za storniranje transakcije na samom prodajnom mjestu je 10 minuta (ukoliko novac sa računa nije potrošen).',
        'instruction-5':
          'Objekte TOBACCO SPRESS-a na kojima možete uplatiti depozit pogledajte na sledećem linku.',
      },
      terminals: {
        note: 'NAPOMENA: Ukoliko iz nekog razloga sredstva ne budu uplaćena na vaš SBBet nalog molimo Vas da kontaktirate J&A službu na broj 067/788-588 u terminu od 9h-17h, a nakon 17h do 21h i vikendom na broj 068/488-588.',
        'step-1': 'Na J&A terminalu, izaberite logo SBbet',
        'step-2':
          'Ukucajte ID svog korisničkog naloga i kliknite dugme "PLATI"',
        'step-3':
          'Ubaciti u terminal željenu sumu novca, i aparat će očitati i prikazati koliko novca ste unijeli.',
        'step-4':
          'Klikom na dugme "POTVRDI" uspješno ćete izvršiti uplatu i dobiti odštampanu priznanicu za vašu transakciju.',
      },
      'transaction-date': 'Datum transakcije',
      'transaction-deposit': 'Uplata',
      'transaction-id': 'ID transakcije',
      'transaction-type': 'Tip transakcije',
      'transaction-web-bet': 'Web bet',
      'transaction-web-bet-rollback': 'Web bet rollback',
      'transaction-web-casino-credit': 'Casino credit',
      'transaction-web-casino-debit': 'Casino debit',
      'transaction-web-casino-rollback': 'Casino rollback',
      'transaction-web-game-bet': 'Web game bet',
      'transaction-web-game-rollback': 'Web game rollback',
      'transaction-web-game-win': 'Web game win',
      'transaction-web-win': 'Web win',
      'transaction-withdraw': 'Isplata',
      transactions: 'Transakcije',
      withdraw: 'ISPLATI',
      'withdraw-on-the-bank': 'ISPLATA PUTEM BANKE',
      'withdraw-on-the-betting-place': 'ISPLATA U POSLOVNICI',
      'withdrawal-pending': 'Na odobrenju',
      'withdrawal-ready': 'Spremno za podizanje',
      'withdrawal-reserved': 'Zakazana isplata',
      'withdrawal-success': 'Uspješno ste zakazali isplatu.',
      'your-transaction-has-been-confirmed': 'Vaša transakcija je potvrđena',
    },
    promoBanner: {
      aviator: 'Aviator',
      betting: 'Klađenje',
      blackjack: 'Blekdžek',
      cashout: 'Cashout',
      'casino-tournament': 'Kazino turnir',
      'defeat-goalkeeper-for-bonuses': 'Savladaj golmana za BONUSE',
      'double-first-payin': 'Dupliramo svaku prvu uplatu',
      'download-app': 'Preuzmi aplikaciju',
      'fly-to-win': 'Poleti do dobitka',
      'happy-monday-bonus': 'Happy Monday Bonus',
      'lucky-6': 'Lucky 6',
      'netent-casino-games': 'NetEnt Kazino Igre',
      new: 'Novo',
      'pay-the-ticket-at-any-time': 'Isplati tiket u bilo kom trenutku',
      popular: 'Popularno',
      promotion: 'Promocija',
      roulette: 'Rulet',
      'win-5000-jackpot': 'Osvoji džekpot 5.000 EUR',
      'win-apartment': 'Osvoji stan u Podgorici',
      'win-iphone-13': 'Osvoji Iphone 13',
      'win-jackpot-up-to-5000': 'Osvoji dzekpot do 5000 eura',
    },
    slips: {
      'accept-all-changes': 'Prihvatam sve promjene',
      'accept-cashout': 'PRIHVATI CASHOUT',
      'accept-changes': 'PRIHVATI IZMJENE',
      'accept-changes-after-place-bet': 'Prihvati izmjene nakon uplate',
      active: 'Aktivan',
      'actual-winning-amount': 'Isplata',
      'all-tickets': 'SVI TIKETI',
      approving: 'Čeka na odobravanje',
      bet: 'Opklada',
      'bet-type': 'Tip klađenja',
      bonus: 'Bonus:',
      'cancel-cashout': 'Otkaži cashout',
      cashout: 'CASHOUT',
      'cashout-accepted': 'Cashout prihvaćen',
      'cashout-denied': 'Uspješno ste odbili cashout',
      'cashout-denied-timer':
        'Cashout je odbijen usled isteka vremena za prihvatanje',
      changed: 'Promijenjen',
      'confirm-ticket': 'POTVRDITE TIKET',
      'delete-all': 'Obriši sve',
      'delete-ticket': 'Obriši tiket',
      'delete-ticket-info': 'Da li želite da obrišete ovaj tiket',
      denied: 'Odbijen',
      'deny-cashout': 'ODBIJ CASHOUT',
      'erase-multiple-odds':
        'Ovom akcijom biće obrisani višeznaci, tako da ostane samo prvi znak u svakom meču.',
      failed: 'Gubitan',
      fix: 'FIKS',
      'group-number': 'Grupa br.',
      id: 'Šifra',
      'max-pot-payout': 'Max. pot. isplata',
      'max-pot-win': 'Max. dobitak',
      'max-potential-bonus': 'Max. pot. bonus',
      'min-max-bonus': 'Min/Max Bonus',
      'min-max-odd': 'Min/Max kvota',
      'min-max-pot-win': 'Min/Max pot. dob.',
      'min-pot-win': 'Min. dobitak',
      'min-potential-bonus': 'Min. pot. bonus',
      'min-winning-total': 'Min. pot. isplata',
      multiplier: 'Kvota',
      'my-tickets': 'MOJI TIKETI',
      'no-slip-events': 'Nema događaja u slipu',
      'no-tickets': 'Nema tiketa.',
      'number-of-combinations': 'Broj kombinacija:',
      'number-of-matches': 'Broj Parova:',
      'number-of-pairs': 'Br. parova:',
      'number-of-tickets': 'Broj tiketa:',
      odd: 'Kvota:',
      paid: 'Isplaćen',
      passed: 'Dobitan',
      payin: 'Uplata:',
      'payin-time': 'Vrijeme uplate:',
      'payment-time': 'Vrijeme uplate',
      payout: 'Isplata:',
      'place-bet': 'UPLATI TIKET',
      'possible-winning': 'Mogući dobitak',
      'pot-winning-amount': 'Potencijalna isplata',
      'pot-winning-amount-short': 'Pot. isplata',
      'potential-bonus': 'Potencijalni bonus',
      'potential-gain': 'Potencijalni dobitak',
      'quick-ticket-code': 'KOD ZA BRZI TIKET',
      'quick-ticket-live': 'Nije moguće odigrati brzi tiket sa live utakmicama',
      'quick-ticket-pay':
        'Brzi tiket možete uplatiti na bilo kom SBBet uplatnom mjestu pod šifrom:',
      'quick-ticket-terms':
        'Provjera uslova i mogućih ograničenja se vrši na našim uplatnim mjestima prilikom uplate tiketa.',
      'quick-tickets': 'Brzi tiketi',
      regular: 'OBIČAN',
      'regular-ticket': 'OBIČAN TIKET',
      'remove-match': 'Ukloni meč',
      'remove-odd': 'Ukloni kvotu',
      round: 'Runda',
      'save-games': 'Sačuvaj utakmice',
      'share-ticket-success': 'Uspješno kopiran link tiketa',
      'sign-in-status': 'Prijavite se da biste vidjeli status tiketa.',
      'slip-not-eligible-for-cashout': 'Tiket ne podliježe cashout-u.',
      'slip-payout': 'Isplata tiketa',
      stake: 'Ulog',
      'stake-per-combination': 'Uplata po kombinaciji:',
      status: 'Status',
      'status-capitalize': 'Status:',
      'switch-ticket': 'Da li želite da prebacite tiket u običan?',
      system: 'SISTEMSKI',
      'system-bonus-calculation':
        'Obračun bonusa na sistemskom tiketu izvršiće se nakon realizacije svih događaja.',
      ticket: 'TIKET',
      'ticket-date': 'Datum tiketa',
      'ticket-details': 'DETALJI TIKETA',
      'ticket-id': 'ID tiketa',
      'ticket-id-search': 'ID tiketa:',
      'ticket-overview': 'Pregled tiketa',
      'ticket-status': 'Status tiketa',
      'ticket-type': 'Tip tiketa',
      tickets: 'TIKETI',
      'top-tickets': 'TOP TIKETI',
      'total-odd': 'Ukupna kvota:',
      unconfirmed: 'Nepotvrđen',
      win: 'Dobitak:',
      'winning-amount-caps': 'DOBITAK',
      'ticket-is-grouped': 'Tiket je grupni',
    },
    sports: {
      'americki fudbal': 'Američki fudbal',
      atletika: 'Atletika',
      badminton: 'Badminton',
      bejzbol: 'Bejzbol',
      biciklizam: 'Biciklizam',
      boks: 'Boks',
      efudbal: 'eFudbal',
      esport: 'eSport',
      evrovizija: 'Evrovizija',
      fudbal: 'Fudbal',
      futsal: 'Futsal',
      golf: 'Golf',
      hokej: 'Hokej',
      košarka: 'Košarka',
      mma: 'MMA',
      odbojka: 'Odbojka',
      olimpijada: 'Olimpijada',
      oskar: 'Oskar',
      pikado: 'Pikado',
      ragbi: 'Ragbi',
      'ragbi liga': 'Ragbi liga',
      rukomet: 'Rukomet',
      snuker: 'Snuker',
      'stoni tenis': 'Stoni tenis',
      tenis: 'Tenis',
      trke: 'Trke',
      'trke konja': 'Trke konja',
      vaterpolo: 'Vaterpolo',
      'virtuelni fudbal': 'Virtuelni fudbal',
      'zimski sportovi': 'Zimski sportovi',
    },
    sportsPage: {
      '1d': '1d',
      '1h': '1h',
      '3d': '3d',
      '3h': '3h',
      all: 'SVE',
      antepost: 'ANTEPOST',
      competition: 'Liga',
      'configure-offer': 'Konfiguriši ponudu',
      event: 'Događaj',
      limit: 'GR',
      live: 'UŽIVO',
      'live-match-tracker': 'Praćenje Meča Uživo',
      'no-events': 'Nema događaja',
      'no-odds': 'Trenutno nema ponude',
      odds: 'KVOTE',
      player: 'IGRAČ',
      'sort-by-competition': 'Sortiraj po ligama',
      'sort-by-time': 'Sortiraj po vremenu',
      special: 'SPECIJALI',
      upcoming: 'SLJEDEĆE',
    },
    userData: {
      zip_code: 'Poštanski broj',
      address: 'Adresa',
      adress: 'Adresa',
      'bank-account-number': 'Broj tekućeg računa',
      'bank-name': 'Ime banke',
      'betting-win-loss': 'Betting win/loss',
      'casino-and-virtuals-win-loss': 'Kazino i virtuali win/loss',
      'change-password': 'Promjena lozinke',
      'changes-active-in-24-hrs': 'Vaše izmjene će biti aktivne za 24 sata',
      city: 'Grad',
      code: 'Kod',
      'confirm-password': 'Potvrdi lozinku',
      country: 'Država',
      'current-password': 'Trenutna lozinka',
      'current-spending': 'Trenutna potrošnja',
      'daily-limits': 'Dnevni limiti',
      'date-of-birth': 'Datum rođenja',
      deposit: 'Depozit',
      'document-id': 'Broj pasoša',
      documents: 'Dokumenta',
      'driver-license': 'Vozačka dozvola',
      'email-address': 'Email adresa',
      'email-adress': 'Email adresa',
      female: 'Ženski',
      'first-name': 'Ime',
      gender: 'Pol',
      'i-have-jmbg': 'Imam JMBG (samo za državljane Crne Gore)',
      jmbg: 'JMBG',
      'last-name': 'Prezime',
      male: 'Muški',
      'monthly-limits': 'Mjesečni limiti',
      'new-limit-value-is': 'Nova vrednost limita je',
      'new-limits-take-effect-in': 'Nova ograničenja stupaju na snagu za',
      'new-password': 'Nova lozinka',
      passport: 'Pasoš',
      password: 'Šifra',
      'pause-gambling-confirmation':
        'Ovim će sportsko klađenje, kazino i virtuali biti onemogućeni do izabranog datuma. Da li ste sigurni da želite da nastavite?',
      'pause-gambling-until': 'Pauziranje klađenja do',
      'personal-data': 'Lični podaci',
      'personal-id': 'Lična karta',
      phone: 'Telefon',
      place: 'Mjesto',
      'responsible-gambling': 'Odgovorno klađenje',
      save: 'Sačuvaj',
      'street-and-number': 'Ulica i broj',
      telephone: 'Telefon',
      'user-details': 'Lični podaci',
      username: 'Korisničko ime',
      'weekly-limits': 'Nedjeljni limiti',
    },
    userMarket: {
      cancel: 'Otkaži',
      code: 'Kod',
      doctor: 'Doktor',
      expert: 'Ekspert',
      items: 'Stavke',
      order: 'Porudžbina',
      'order-success': 'Uspješna porudžbina',
      place: 'Mjesto',
      professor: 'Profesor',
      'sbbet-points': 'SBBet poeni',
      'successfuly-canceled-order': 'Uspješno ste otkazali porudžbinu',
    },
    validations: {
      'amount-greater-than-available': 'Unijeti iznos je veći od dostupnog',
      'cannot-mix-group-events':
        'Nije moguće igrati grupne događaje i događaje iz grupe na istom tiketu',
      'event-disabled': 'Klađenje na događaj: {{a}} nije moguće.',
      'event-starts-in-less-than':
        'Događaj {{a}} počinje za manje od {{b}} sekundi',
      'free-bet-error':
        'Minimalan broj događaja na free bet tiketu je {{minEvents}}. Minimalna ukupna kvota je {{minTotalOdd}}.',
      'free-bet-unvalidated':
        'Molimo vas da verifikujete vas nalog kako bi iskoristili promo sredstva.',
      'live-odds-change': 'Molimo Vas, prihvatite sve promjene.',
      'max-bet-amount': 'Maksimalna uplata je {{a}}',
      'max-credit': 'Maksimalna isplata je {{max}} €',
      'max-debit': 'Maksimalna uplata je {{max}}',
      'max-number-of-combinations': 'Maksimalan broj kombinacija je {{a}}',
      'max-number-of-events': 'Maksimalan broj događaja je {{a}}',
      'max-number-of-same-events': 'Maksimalan broj višeznaka je {{a}}',
      'max-same-slips': 'Odigrali ste {{a}} ista tiketa.',
      'min-amount-per-combination': 'Minimalna uplata po kombinaciji je {{a}}',
      'min-credit': 'Minimalna isplata je {{min}} €',
      'min-debit': 'Minimalna uplata je {{min}}',
      'min-payment-for-number-of-matches':
        'Minimalna uplata za broj događaja manji od {{b}} je {{a}}',
      'no-space': 'Nije dozvoljen unos razmaka',
      'odd-disabled': 'Klađenje na kvotu {{a}} nije moguće.',
      'odd-higher-than-one': 'Svaka kvota mora biti veća od 1.',
      'only-letters-and-spaces-allowed':
        'Dozvoljen je samo unos slova i razmaka',
      'only-numbers-allowed': 'Dozvoljen je unos samo brojeva',
      'repeat-password': 'Ponovite lozinku',
      'validation-antepost-system':
        'Nije moguće igrati antepost događaje na sistemskom tiketu.',
      'validation-balance': 'Nemate dovoljno sredstava na računu.',
      'validation-max-payment':
        'Uplata je veća od definisane maksimalne uplate.',
      'validation-max-systems': 'Maksimalan broj sistema je {{a}}',
      'validation-max-win': 'Maksimalan potencijalni dobitak je {{a}}',
      'validation-min-payment': 'Minimalna uplata je {{minAmount}} EUR',
      'validation-mix-long-short-term':
        'Nije moguće odigrati antepost događaj {{a}} i kratkoročan događaj {{b}} na istom tiketu.',
      'validation-mixed-event-types':
        'Nije moguće igrati antepost događaje u kombinaciji sa drugim tipovima događaja',
      'validation-mixed-markets':
        'Nije moguće odigrati market {{a}} i market {{b}} na istom tiketu.',
      'validation-not-validated':
        'Neverifikovan korisnik ne može izvršiti isplatu.',
      'validation-only-singles':
        'Događaj {{a}} se može odigrati samo u singlu.',
      'validation-single-choice':
        'Događaj {{a}} ne može da sadrži više znakova.',
    },
    verification: {
      'accept-terms': 'Prihvatam uslove korišćenja',
      'account-activated': 'Nalog je uspješno aktiviran',
      'account-success': 'Uspješno ste izvršili aktivaciju naloga!',
      'agree-email-notif': 'Želim primati obavještenja putem emaila',
      'agree-sms-notif': 'Želim primati obavještenja putem SMS-a',
      confirm: 'POTVRDI',
      'connection-online': 'Veza sa internetom je uspostavljena!',
      'data-saved': 'Podaci su uspješno sačuvani',
      'deny-changes': 'Uspješno ste odbili izmjene na tiketu.',
      'deny-changes-timer':
        'Izmjene su odbijene usled isteka vremena za prihvatanje izmjena.',
      'email-verified-success': 'Uspješno ste verifikovali vaš email nalog',
      'enter-recovery-email':
        'Unesite email adresu sa kojom ste napravili nalog.',
      instruction:
        'Na broj 1818 pošaljite SMS poruku u formatu: SbbIDbrojIznosDepozita',
      'login-activation': 'Prijavite se kako bi koristili aplikaciju',
      'promo-code-activated':
        'Uspješno ste aktivirali promo kod. Primili ste {{amount}} {{currency}} na vašem {{walletType}} računu.',
      'promo-code-activated-multiple-wallets':
        'Uspješno ste aktivirali promo kod. Primili ste {{amount}} {{currency}} na vašem Free bet računu i {{amount_fc}} {{currency}} na vašem Free spin računu.',
      'promo-code-already-activated': 'Promo kod je već aktiviran',
      'promo-code-not-for-existing-users':
        'Promo kod nije namijenjen starim korisnicima',
      'promo-code-not-for-new-users':
        'Promo kod nije namijenjen novim korinsicima',
      'promo-code-used-up': 'Promo kod je već iskorišćen',
      'recovery-email-sent': 'Poslali smo vam email na vašu email adresu',
      'successfully-changed-password': 'Uspješno ste promjenili lozinku',
      'ticket-approved':
        'Vaš tiket je odobren. Kliknite kako biste vidjeli tiket.',
      'ticket-paid': 'Tiket je uspješno uplaćen.',
      'ticket-waiting-approval': 'Vaš tiket je na odobravanju.',
      'upload-documents': {
        'expiration-date': 'Datum isteka',
        'choose-document': 'Izaberite dokument',
        'document-type': 'Tip validacionog dokumenta:',
        'first-page': 'Prednja/prva strana dokumenta:',
        'second-page': 'Zadnja/druga strana dokumenta:',
        submit: 'Pošalji',
        title: 'Učitavanje dokumenta',
        'upload-first-page': 'Učitaj prednju/prvu stranu dokumenta',
        'upload-second-page': 'Učitaj zadnju/drugu stranu dokumenta',
        'user-update-success': 'Podaci su uspješno sačuvani',
      },
      'validation-18-year': 'Nemate 18 godina.',
      'verification-complete': 'Validacija naloga je uspješno okončana',
      'verification-in-progress': 'Validacija u toku',
      'verification-modal': {
        activate: 'Validacija naloga',
        desc: 'Validaciju naloga možete izvršiti u svakom trenutku na stranici Vašeg naloga pod sekcijom Moj nalog > NALOG > Dokumenta',
        'doc-uploaded': 'Validacija u toku',
        proceed: 'Nastavi bez validacije',
        validate: 'Validacija korisnika',
      },
    },
    wallet: {
      'activate-bonus-info':
        'Aktivirajte promo bonus u iznosu od {{bonus}} EUR',
      'casino-bonus-info':
        'Potrebno je da potrošite još {{amountToUnlock}} EUR da bi otključali casino bonus',
      'casino-to-unlock': 'Casino Bonus na otključavanju',
      'free-bet': 'Free bet',
      'free-bet-bonus': 'Free bet bonus',
      'free-bet-bonus-info':
        'Potrebno je da potrošite još {{amountToUnlock}} EUR da bi otključali free bet',
      'free-bet-bonus-to-unlock': 'Free bet bonus na otključavanju',
      'free-bet-bonus-unlock-message':
        'Potrebno je da potrošite još {{amountToUnlock}} EUR da bi otključali free bet bonus',
      'free-spin': 'Free spin',
      'free-spin-to-unlock': 'Free spin na otključavanju',
      'free-spin-unlock-message':
        'Potrebno je da potrošite još {{amountToUnlock}} EUR da bi otključali free spin',
      'games-ticket-payout-info': 'Da li želite da isplatite sledeći tiket',
      'payout-success': 'Isplata je uspješno izvršena.',
      'poker-balance': 'Poker bilans',
      'promo-bill': 'Promo račun',
      'promo-bonus': 'Promo Bonus',
      'promo-to-unlock': 'Promo na otključavanju',
      'reserved-funds': 'Rezervisana sredstva',
      'standard-account': 'Standardni račun',
      'standard-bill': 'Saldo',
      'submit-for-print-success': 'Uspješno poslato na štampanje',
      'ticket-successfully-paid': 'Tiket je uspješno uplaćen',
      'valid-to': 'Validan do {{validTo}}',
      'voucher-payout-info': 'Da li želite da isplatite sledeći VAUČER',
      'voucher-print': 'ISPRINTAJ VAUČER',
      'voucher-print-confirm': 'Da li želite da nastavite?',
      'voucher-print-info':
        'Sva sredstva koja se nalaze na Balansu će biti skinuta i štampaće se VAUČER u istoj vrijednosti.',
      wallet: 'Račun',
      'wallet-locked': 'Račun je zaključan',
      'wallets-refresh': 'Ažuriraj podatke o novčaniku',
    },
  },
};

export default me;
