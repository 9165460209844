// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/odds/web_odds.proto" (package "web_odds", syntax proto3)
// tslint:disable
import { Empty } from "../../google/protobuf/empty_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { EventResult } from "../common/common_pb";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
import { Error } from "../common/common_pb";
/**
 * Initial data
 *
 * @generated from protobuf message web_odds.CompetitionId
 */
export interface CompetitionId {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message web_odds.Competition
 */
export interface Competition {
    /**
     * @generated from protobuf field: web_odds.InitialCompetition competition = 1001;
     */
    competition?: InitialCompetition;
    /**
     * @generated from protobuf field: web_odds.InitialSport sport = 1002;
     */
    sport?: InitialSport;
    /**
     * @generated from protobuf field: common.Error error = 2002;
     */
    error?: Error;
}
/**
 * @generated from protobuf message web_odds.InitialDataRequest
 */
export interface InitialDataRequest {
    /**
     * @generated from protobuf field: bool only_markets = 1;
     */
    onlyMarkets: boolean;
}
/**
 * @generated from protobuf message web_odds.InitialDataResponse
 */
export interface InitialDataResponse {
    /**
     * @generated from protobuf field: repeated web_odds.InitialMarketGroup market_groups = 1;
     */
    marketGroups: InitialMarketGroup[];
    /**
     * @generated from protobuf field: repeated web_odds.InitialSport sports = 2;
     */
    sports: InitialSport[];
}
/**
 * @generated from protobuf message web_odds.InitialSport
 */
export interface InitialSport {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated web_odds.InitialLocation locations = 3;
     */
    locations: InitialLocation[];
    /**
     * @generated from protobuf field: web_odds.InitialBasicOffer basic_offer_live = 4;
     */
    basicOfferLive?: InitialBasicOffer;
    /**
     * @generated from protobuf field: web_odds.InitialBasicOffer basic_offer_prematch = 5;
     */
    basicOfferPrematch?: InitialBasicOffer;
}
/**
 * @generated from protobuf message web_odds.InitialLocation
 */
export interface InitialLocation {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated web_odds.InitialCompetition competitions = 3;
     */
    competitions: InitialCompetition[];
    /**
     * @generated from protobuf field: string flag_code = 444;
     */
    flagCode: string;
}
/**
 * @generated from protobuf message web_odds.InitialCompetition
 */
export interface InitialCompetition {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string short_name = 3;
     */
    shortName: string;
    /**
     * @generated from protobuf field: int32 competition_order = 333;
     */
    competitionOrder: number;
    /**
     * @generated from protobuf field: bool is_favorite = 334;
     */
    isFavorite: boolean;
    /**
     * @generated from protobuf field: int32 location_order = 4;
     */
    locationOrder: number;
}
/**
 * @generated from protobuf message web_odds.InitialBasicOffer
 */
export interface InitialBasicOffer {
    /**
     * @generated from protobuf field: repeated web_odds.InitialBasicOfferMarket markets = 1;
     */
    markets: InitialBasicOfferMarket[];
}
/**
 * @generated from protobuf message web_odds.InitialBasicOfferMarket
 */
export interface InitialBasicOfferMarket {
    /**
     * @generated from protobuf field: int32 market_id = 1;
     */
    marketId: number;
    /**
     * @generated from protobuf field: repeated int32 outcome_ids = 2;
     */
    outcomeIds: number[];
}
/**
 * @generated from protobuf message web_odds.InitialMarketGroup
 */
export interface InitialMarketGroup {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string english_name = 555;
     */
    englishName: string;
    /**
     * @generated from protobuf field: string albanian_name = 556;
     */
    albanianName: string;
    /**
     * @generated from protobuf field: string turkish_name = 557;
     */
    turkishName: string;
    /**
     * @generated from protobuf field: string russian_name = 558;
     */
    russianName: string;
    /**
     * @generated from protobuf field: string ukrainian_name = 559;
     */
    ukrainianName: string;
    /**
     * @generated from protobuf field: string italian_name = 560;
     */
    italianName: string;
    /**
     * @generated from protobuf field: string german_name = 561;
     */
    germanName: string;
    /**
     * @generated from protobuf field: repeated web_odds.InitialMarket markets = 3;
     */
    markets: InitialMarket[];
}
/**
 * @generated from protobuf message web_odds.InitialMarket
 */
export interface InitialMarket {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int32 sport_id = 3;
     */
    sportId: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string english_name = 555;
     */
    englishName: string;
    /**
     * @generated from protobuf field: string albanian_name = 556;
     */
    albanianName: string;
    /**
     * @generated from protobuf field: string turkish_name = 557;
     */
    turkishName: string;
    /**
     * @generated from protobuf field: string russian_name = 558;
     */
    russianName: string;
    /**
     * @generated from protobuf field: string ukrainian_name = 559;
     */
    ukrainianName: string;
    /**
     * @generated from protobuf field: string italian_name = 560;
     */
    italianName: string;
    /**
     * @generated from protobuf field: string german_name = 561;
     */
    germanName: string;
    /**
     * @generated from protobuf field: repeated web_odds.InitialOutcome outcomes = 4;
     */
    outcomes: InitialOutcome[];
}
/**
 * @generated from protobuf message web_odds.InitialOutcome
 */
export interface InitialOutcome {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string outcome_group = 3;
     */
    outcomeGroup: string;
    /**
     * @generated from protobuf field: string outcome_tic = 4;
     */
    outcomeTic: string;
    /**
     * @generated from protobuf field: string exe_limit = 5;
     */
    exeLimit: string;
    /**
     * @generated from protobuf field: string short_description = 6;
     */
    shortDescription: string;
    /**
     * @generated from protobuf field: bool has_limit = 7;
     */
    hasLimit: boolean;
}
/**
 * @generated from protobuf message web_odds.EventStreamString
 */
export interface EventStreamString {
    /**
     * @generated from protobuf field: string live_events = 1;
     */
    liveEvents: string;
    /**
     * @generated from protobuf field: string upcoming_events = 2;
     */
    upcomingEvents: string;
    /**
     * @generated from protobuf field: string outright_events = 3;
     */
    outrightEvents: string;
    /**
     * @generated from protobuf field: string player_events = 4;
     */
    playerEvents: string;
    /**
     * @generated from protobuf field: string group_events = 5;
     */
    groupEvents: string;
}
// Events stream

/**
 * @generated from protobuf message web_odds.EventStream
 */
export interface EventStream {
    /**
     * @generated from protobuf field: web_odds.WebStreamEvents live_events = 1;
     */
    liveEvents?: WebStreamEvents;
    /**
     * @generated from protobuf field: web_odds.WebStreamEvents upcoming_events = 2;
     */
    upcomingEvents?: WebStreamEvents;
    /**
     * @generated from protobuf field: web_odds.WebStreamEvents outright_events = 3;
     */
    outrightEvents?: WebStreamEvents;
    /**
     * @generated from protobuf field: web_odds.WebStreamEvents player_events = 4;
     */
    playerEvents?: WebStreamEvents;
    /**
     * @generated from protobuf field: web_odds.WebStreamEvents group_events = 5;
     */
    groupEvents?: WebStreamEvents;
}
/**
 * @generated from protobuf message web_odds.WebStreamEvents
 */
export interface WebStreamEvents {
    /**
     * @generated from protobuf field: repeated web_odds.WebStreamEvent events = 17;
     */
    events: WebStreamEvent[];
}
/**
 * @generated from protobuf message web_odds.WebStreamEvent
 */
export interface WebStreamEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 competition_id = 3;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: int32 location_id = 4;
     */
    locationId: number;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated web_odds.WebStreamEventOdd odds = 6;
     */
    odds: WebStreamEventOdd[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 7;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: bool is_disabled = 8;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: common.EventResult result = 9;
     */
    result?: EventResult;
    /**
     * @generated from protobuf field: string landbase_code = 10;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: string type = 11;
     */
    type: string;
    /**
     * @generated from protobuf field: string outright_type = 12;
     */
    outrightType: string;
    /**
     * @generated from protobuf field: int32 mon = 13;
     */
    mon: number;
    /**
     * @generated from protobuf field: int32 number_of_odds = 14;
     */
    numberOfOdds: number;
    /**
     * @generated from protobuf field: int32 exefeed_id = 15;
     */
    exefeedId: number;
    /**
     * @generated from protobuf field: string profile = 16;
     */
    profile: string;
    /**
     * @generated from protobuf field: string betradar_id = 17;
     */
    betradarId: string;
    /**
     * @generated from protobuf field: string betradar_stream_id = 18;
     */
    betradarStreamId: string;
}
/**
 * @generated from protobuf message web_odds.WebStreamEventOdd
 */
export interface WebStreamEventOdd {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: double value = 2;
     */
    value: number;
    /**
     * @generated from protobuf field: int32 outcome_id = 3;
     */
    outcomeId: number;
    /**
     * @generated from protobuf field: double limit = 4;
     */
    limit: number;
    /**
     * @generated from protobuf field: int32 frame_number = 5;
     */
    frameNumber: number;
    /**
     * @generated from protobuf field: string current_result = 6;
     */
    currentResult: string;
    /**
     * @generated from protobuf field: bool is_disabled = 7;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: int32 status = 8;
     */
    status: number;
    /**
     * @generated from protobuf field: string live_player = 9;
     */
    livePlayer: string;
}
// for proto serialization

/**
 * @generated from protobuf message web_odds.WebStreamEventInt
 */
export interface WebStreamEventInt {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 competition_id = 3;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: int32 location_id = 4;
     */
    locationId: number;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated web_odds.WebStreamEventOddInt odds = 6;
     */
    odds: WebStreamEventOddInt[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 7;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: bool is_disabled = 8;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: common.EventResult result = 9;
     */
    result?: EventResult;
    /**
     * @generated from protobuf field: string landbase_code = 10;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: string type = 11;
     */
    type: string;
    /**
     * @generated from protobuf field: string outright_type = 12;
     */
    outrightType: string;
    /**
     * @generated from protobuf field: int32 mon = 13;
     */
    mon: number;
    /**
     * @generated from protobuf field: int32 number_of_odds = 14;
     */
    numberOfOdds: number;
    /**
     * @generated from protobuf field: int32 exefeed_id = 15;
     */
    exefeedId: number;
    /**
     * @generated from protobuf field: string profile = 16;
     */
    profile: string;
    /**
     * @generated from protobuf field: string betradar_id = 17;
     */
    betradarId: string;
    /**
     * @generated from protobuf field: string betradar_stream_id = 18;
     */
    betradarStreamId: string;
}
/**
 * @generated from protobuf message web_odds.WebStreamEventOddInt
 */
export interface WebStreamEventOddInt {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int32 value = 2;
     */
    value: number;
    /**
     * @generated from protobuf field: int32 outcome_id = 3;
     */
    outcomeId: number;
    /**
     * @generated from protobuf field: int32 limit = 4;
     */
    limit: number;
    /**
     * @generated from protobuf field: int32 frame_number = 5;
     */
    frameNumber: number;
    /**
     * @generated from protobuf field: string current_result = 6;
     */
    currentResult: string;
    /**
     * @generated from protobuf field: bool is_disabled = 7;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: int32 status = 8;
     */
    status: number;
    /**
     * @generated from protobuf field: string live_player = 9;
     */
    livePlayer: string;
}
// LiveDisabled

/**
 * @generated from protobuf message web_odds.LiveDisabled
 */
export interface LiveDisabled {
    /**
     * @generated from protobuf field: bool all = 1;
     */
    all: boolean;
    /**
     * @generated from protobuf field: repeated int32 sport_ids = 2;
     */
    sportIds: number[];
}
/**
 * PlayerOutright
 *
 * @generated from protobuf message web_odds.PlayerOutrightMarkets
 */
export interface PlayerOutrightMarkets {
    /**
     * @generated from protobuf field: int32 sport_id = 1;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated web_odds.InitialMarket outright_markets = 2;
     */
    outrightMarkets: InitialMarket[];
    /**
     * @generated from protobuf field: repeated web_odds.InitialMarket player_markets = 3;
     */
    playerMarkets: InitialMarket[];
}
/**
 * LandingPage
 *
 * @generated from protobuf message web_odds.LandingPage
 */
export interface LandingPage {
    /**
     * @generated from protobuf field: repeated web_odds.WebStreamEvent top = 1;
     */
    top: WebStreamEvent[];
    /**
     * @generated from protobuf field: repeated web_odds.WebStreamEvent live = 2;
     */
    live: WebStreamEvent[];
    /**
     * @generated from protobuf field: repeated web_odds.WebStreamEvent prematch = 3;
     */
    prematch: WebStreamEvent[];
}
/**
 * @generated from protobuf message web_odds.FeaturedEvent
 */
export interface FeaturedEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message web_odds.FeaturedEvents
 */
export interface FeaturedEvents {
    /**
     * @generated from protobuf field: repeated web_odds.FeaturedEvent events = 1;
     */
    events: FeaturedEvent[];
}
/**
 * @generated from protobuf message web_odds.GetStreamUrlRequest
 */
export interface GetStreamUrlRequest {
    /**
     * @generated from protobuf field: string stream_id = 1;
     */
    streamId: string;
    /**
     * @generated from protobuf field: string stream_type = 2;
     */
    streamType: string;
    /**
     * @generated from protobuf field: string csid = 3;
     */
    csid: string;
    /**
     * @generated from protobuf field: string device_category = 4;
     */
    deviceCategory: string;
    /**
     * @generated from protobuf field: string real_ip = 5;
     */
    realIp: string;
}
/**
 * @generated from protobuf message web_odds.StreamUrl
 */
export interface StreamUrl {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * @generated from protobuf field: common.Error error = 2;
     */
    error?: Error;
}
// @generated message type with reflection information, may provide speed optimized methods
class CompetitionId$Type extends MessageType<CompetitionId> {
    constructor() {
        super("web_odds.CompetitionId", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CompetitionId>): CompetitionId {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<CompetitionId>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompetitionId): CompetitionId {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompetitionId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.CompetitionId
 */
export const CompetitionId = new CompetitionId$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Competition$Type extends MessageType<Competition> {
    constructor() {
        super("web_odds.Competition", [
            { no: 1001, name: "competition", kind: "message", T: () => InitialCompetition },
            { no: 1002, name: "sport", kind: "message", T: () => InitialSport },
            { no: 2002, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<Competition>): Competition {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Competition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Competition): Competition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* web_odds.InitialCompetition competition */ 1001:
                    message.competition = InitialCompetition.internalBinaryRead(reader, reader.uint32(), options, message.competition);
                    break;
                case /* web_odds.InitialSport sport */ 1002:
                    message.sport = InitialSport.internalBinaryRead(reader, reader.uint32(), options, message.sport);
                    break;
                case /* common.Error error */ 2002:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Competition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* web_odds.InitialCompetition competition = 1001; */
        if (message.competition)
            InitialCompetition.internalBinaryWrite(message.competition, writer.tag(1001, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.InitialSport sport = 1002; */
        if (message.sport)
            InitialSport.internalBinaryWrite(message.sport, writer.tag(1002, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 2002; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(2002, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.Competition
 */
export const Competition = new Competition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialDataRequest$Type extends MessageType<InitialDataRequest> {
    constructor() {
        super("web_odds.InitialDataRequest", [
            { no: 1, name: "only_markets", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<InitialDataRequest>): InitialDataRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.onlyMarkets = false;
        if (value !== undefined)
            reflectionMergePartial<InitialDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialDataRequest): InitialDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool only_markets */ 1:
                    message.onlyMarkets = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool only_markets = 1; */
        if (message.onlyMarkets !== false)
            writer.tag(1, WireType.Varint).bool(message.onlyMarkets);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialDataRequest
 */
export const InitialDataRequest = new InitialDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialDataResponse$Type extends MessageType<InitialDataResponse> {
    constructor() {
        super("web_odds.InitialDataResponse", [
            { no: 1, name: "market_groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialMarketGroup },
            { no: 2, name: "sports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialSport }
        ]);
    }
    create(value?: PartialMessage<InitialDataResponse>): InitialDataResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.marketGroups = [];
        message.sports = [];
        if (value !== undefined)
            reflectionMergePartial<InitialDataResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialDataResponse): InitialDataResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated web_odds.InitialMarketGroup market_groups */ 1:
                    message.marketGroups.push(InitialMarketGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated web_odds.InitialSport sports */ 2:
                    message.sports.push(InitialSport.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialDataResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated web_odds.InitialMarketGroup market_groups = 1; */
        for (let i = 0; i < message.marketGroups.length; i++)
            InitialMarketGroup.internalBinaryWrite(message.marketGroups[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated web_odds.InitialSport sports = 2; */
        for (let i = 0; i < message.sports.length; i++)
            InitialSport.internalBinaryWrite(message.sports[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialDataResponse
 */
export const InitialDataResponse = new InitialDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialSport$Type extends MessageType<InitialSport> {
    constructor() {
        super("web_odds.InitialSport", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "locations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialLocation },
            { no: 4, name: "basic_offer_live", kind: "message", T: () => InitialBasicOffer },
            { no: 5, name: "basic_offer_prematch", kind: "message", T: () => InitialBasicOffer }
        ]);
    }
    create(value?: PartialMessage<InitialSport>): InitialSport {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.locations = [];
        if (value !== undefined)
            reflectionMergePartial<InitialSport>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialSport): InitialSport {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated web_odds.InitialLocation locations */ 3:
                    message.locations.push(InitialLocation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* web_odds.InitialBasicOffer basic_offer_live */ 4:
                    message.basicOfferLive = InitialBasicOffer.internalBinaryRead(reader, reader.uint32(), options, message.basicOfferLive);
                    break;
                case /* web_odds.InitialBasicOffer basic_offer_prematch */ 5:
                    message.basicOfferPrematch = InitialBasicOffer.internalBinaryRead(reader, reader.uint32(), options, message.basicOfferPrematch);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialSport, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated web_odds.InitialLocation locations = 3; */
        for (let i = 0; i < message.locations.length; i++)
            InitialLocation.internalBinaryWrite(message.locations[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.InitialBasicOffer basic_offer_live = 4; */
        if (message.basicOfferLive)
            InitialBasicOffer.internalBinaryWrite(message.basicOfferLive, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.InitialBasicOffer basic_offer_prematch = 5; */
        if (message.basicOfferPrematch)
            InitialBasicOffer.internalBinaryWrite(message.basicOfferPrematch, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialSport
 */
export const InitialSport = new InitialSport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialLocation$Type extends MessageType<InitialLocation> {
    constructor() {
        super("web_odds.InitialLocation", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "competitions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialCompetition },
            { no: 444, name: "flag_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InitialLocation>): InitialLocation {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.competitions = [];
        message.flagCode = "";
        if (value !== undefined)
            reflectionMergePartial<InitialLocation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialLocation): InitialLocation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated web_odds.InitialCompetition competitions */ 3:
                    message.competitions.push(InitialCompetition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string flag_code */ 444:
                    message.flagCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialLocation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated web_odds.InitialCompetition competitions = 3; */
        for (let i = 0; i < message.competitions.length; i++)
            InitialCompetition.internalBinaryWrite(message.competitions[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string flag_code = 444; */
        if (message.flagCode !== "")
            writer.tag(444, WireType.LengthDelimited).string(message.flagCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialLocation
 */
export const InitialLocation = new InitialLocation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialCompetition$Type extends MessageType<InitialCompetition> {
    constructor() {
        super("web_odds.InitialCompetition", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 333, name: "competition_order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 334, name: "is_favorite", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "location_order", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<InitialCompetition>): InitialCompetition {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.shortName = "";
        message.competitionOrder = 0;
        message.isFavorite = false;
        message.locationOrder = 0;
        if (value !== undefined)
            reflectionMergePartial<InitialCompetition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialCompetition): InitialCompetition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string short_name */ 3:
                    message.shortName = reader.string();
                    break;
                case /* int32 competition_order */ 333:
                    message.competitionOrder = reader.int32();
                    break;
                case /* bool is_favorite */ 334:
                    message.isFavorite = reader.bool();
                    break;
                case /* int32 location_order */ 4:
                    message.locationOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialCompetition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string short_name = 3; */
        if (message.shortName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.shortName);
        /* int32 competition_order = 333; */
        if (message.competitionOrder !== 0)
            writer.tag(333, WireType.Varint).int32(message.competitionOrder);
        /* bool is_favorite = 334; */
        if (message.isFavorite !== false)
            writer.tag(334, WireType.Varint).bool(message.isFavorite);
        /* int32 location_order = 4; */
        if (message.locationOrder !== 0)
            writer.tag(4, WireType.Varint).int32(message.locationOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialCompetition
 */
export const InitialCompetition = new InitialCompetition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialBasicOffer$Type extends MessageType<InitialBasicOffer> {
    constructor() {
        super("web_odds.InitialBasicOffer", [
            { no: 1, name: "markets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialBasicOfferMarket }
        ]);
    }
    create(value?: PartialMessage<InitialBasicOffer>): InitialBasicOffer {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.markets = [];
        if (value !== undefined)
            reflectionMergePartial<InitialBasicOffer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialBasicOffer): InitialBasicOffer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated web_odds.InitialBasicOfferMarket markets */ 1:
                    message.markets.push(InitialBasicOfferMarket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialBasicOffer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated web_odds.InitialBasicOfferMarket markets = 1; */
        for (let i = 0; i < message.markets.length; i++)
            InitialBasicOfferMarket.internalBinaryWrite(message.markets[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialBasicOffer
 */
export const InitialBasicOffer = new InitialBasicOffer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialBasicOfferMarket$Type extends MessageType<InitialBasicOfferMarket> {
    constructor() {
        super("web_odds.InitialBasicOfferMarket", [
            { no: 1, name: "market_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "outcome_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<InitialBasicOfferMarket>): InitialBasicOfferMarket {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.marketId = 0;
        message.outcomeIds = [];
        if (value !== undefined)
            reflectionMergePartial<InitialBasicOfferMarket>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialBasicOfferMarket): InitialBasicOfferMarket {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 market_id */ 1:
                    message.marketId = reader.int32();
                    break;
                case /* repeated int32 outcome_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.outcomeIds.push(reader.int32());
                    else
                        message.outcomeIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialBasicOfferMarket, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 market_id = 1; */
        if (message.marketId !== 0)
            writer.tag(1, WireType.Varint).int32(message.marketId);
        /* repeated int32 outcome_ids = 2; */
        if (message.outcomeIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.outcomeIds.length; i++)
                writer.int32(message.outcomeIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialBasicOfferMarket
 */
export const InitialBasicOfferMarket = new InitialBasicOfferMarket$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialMarketGroup$Type extends MessageType<InitialMarketGroup> {
    constructor() {
        super("web_odds.InitialMarketGroup", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 555, name: "english_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 556, name: "albanian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 557, name: "turkish_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 558, name: "russian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 559, name: "ukrainian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 560, name: "italian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 561, name: "german_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "markets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialMarket }
        ]);
    }
    create(value?: PartialMessage<InitialMarketGroup>): InitialMarketGroup {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.englishName = "";
        message.albanianName = "";
        message.turkishName = "";
        message.russianName = "";
        message.ukrainianName = "";
        message.italianName = "";
        message.germanName = "";
        message.markets = [];
        if (value !== undefined)
            reflectionMergePartial<InitialMarketGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialMarketGroup): InitialMarketGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string english_name */ 555:
                    message.englishName = reader.string();
                    break;
                case /* string albanian_name */ 556:
                    message.albanianName = reader.string();
                    break;
                case /* string turkish_name */ 557:
                    message.turkishName = reader.string();
                    break;
                case /* string russian_name */ 558:
                    message.russianName = reader.string();
                    break;
                case /* string ukrainian_name */ 559:
                    message.ukrainianName = reader.string();
                    break;
                case /* string italian_name */ 560:
                    message.italianName = reader.string();
                    break;
                case /* string german_name */ 561:
                    message.germanName = reader.string();
                    break;
                case /* repeated web_odds.InitialMarket markets */ 3:
                    message.markets.push(InitialMarket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialMarketGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string english_name = 555; */
        if (message.englishName !== "")
            writer.tag(555, WireType.LengthDelimited).string(message.englishName);
        /* string albanian_name = 556; */
        if (message.albanianName !== "")
            writer.tag(556, WireType.LengthDelimited).string(message.albanianName);
        /* string turkish_name = 557; */
        if (message.turkishName !== "")
            writer.tag(557, WireType.LengthDelimited).string(message.turkishName);
        /* string russian_name = 558; */
        if (message.russianName !== "")
            writer.tag(558, WireType.LengthDelimited).string(message.russianName);
        /* string ukrainian_name = 559; */
        if (message.ukrainianName !== "")
            writer.tag(559, WireType.LengthDelimited).string(message.ukrainianName);
        /* string italian_name = 560; */
        if (message.italianName !== "")
            writer.tag(560, WireType.LengthDelimited).string(message.italianName);
        /* string german_name = 561; */
        if (message.germanName !== "")
            writer.tag(561, WireType.LengthDelimited).string(message.germanName);
        /* repeated web_odds.InitialMarket markets = 3; */
        for (let i = 0; i < message.markets.length; i++)
            InitialMarket.internalBinaryWrite(message.markets[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialMarketGroup
 */
export const InitialMarketGroup = new InitialMarketGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialMarket$Type extends MessageType<InitialMarket> {
    constructor() {
        super("web_odds.InitialMarket", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 555, name: "english_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 556, name: "albanian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 557, name: "turkish_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 558, name: "russian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 559, name: "ukrainian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 560, name: "italian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 561, name: "german_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "outcomes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialOutcome }
        ]);
    }
    create(value?: PartialMessage<InitialMarket>): InitialMarket {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.sportId = 0;
        message.name = "";
        message.englishName = "";
        message.albanianName = "";
        message.turkishName = "";
        message.russianName = "";
        message.ukrainianName = "";
        message.italianName = "";
        message.germanName = "";
        message.outcomes = [];
        if (value !== undefined)
            reflectionMergePartial<InitialMarket>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialMarket): InitialMarket {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 sport_id */ 3:
                    message.sportId = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string english_name */ 555:
                    message.englishName = reader.string();
                    break;
                case /* string albanian_name */ 556:
                    message.albanianName = reader.string();
                    break;
                case /* string turkish_name */ 557:
                    message.turkishName = reader.string();
                    break;
                case /* string russian_name */ 558:
                    message.russianName = reader.string();
                    break;
                case /* string ukrainian_name */ 559:
                    message.ukrainianName = reader.string();
                    break;
                case /* string italian_name */ 560:
                    message.italianName = reader.string();
                    break;
                case /* string german_name */ 561:
                    message.germanName = reader.string();
                    break;
                case /* repeated web_odds.InitialOutcome outcomes */ 4:
                    message.outcomes.push(InitialOutcome.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialMarket, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 sport_id = 3; */
        if (message.sportId !== 0)
            writer.tag(3, WireType.Varint).int32(message.sportId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string english_name = 555; */
        if (message.englishName !== "")
            writer.tag(555, WireType.LengthDelimited).string(message.englishName);
        /* string albanian_name = 556; */
        if (message.albanianName !== "")
            writer.tag(556, WireType.LengthDelimited).string(message.albanianName);
        /* string turkish_name = 557; */
        if (message.turkishName !== "")
            writer.tag(557, WireType.LengthDelimited).string(message.turkishName);
        /* string russian_name = 558; */
        if (message.russianName !== "")
            writer.tag(558, WireType.LengthDelimited).string(message.russianName);
        /* string ukrainian_name = 559; */
        if (message.ukrainianName !== "")
            writer.tag(559, WireType.LengthDelimited).string(message.ukrainianName);
        /* string italian_name = 560; */
        if (message.italianName !== "")
            writer.tag(560, WireType.LengthDelimited).string(message.italianName);
        /* string german_name = 561; */
        if (message.germanName !== "")
            writer.tag(561, WireType.LengthDelimited).string(message.germanName);
        /* repeated web_odds.InitialOutcome outcomes = 4; */
        for (let i = 0; i < message.outcomes.length; i++)
            InitialOutcome.internalBinaryWrite(message.outcomes[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialMarket
 */
export const InitialMarket = new InitialMarket$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialOutcome$Type extends MessageType<InitialOutcome> {
    constructor() {
        super("web_odds.InitialOutcome", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "outcome_group", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "outcome_tic", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "exe_limit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "short_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "has_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<InitialOutcome>): InitialOutcome {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.outcomeGroup = "";
        message.outcomeTic = "";
        message.exeLimit = "";
        message.shortDescription = "";
        message.hasLimit = false;
        if (value !== undefined)
            reflectionMergePartial<InitialOutcome>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialOutcome): InitialOutcome {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string outcome_group */ 3:
                    message.outcomeGroup = reader.string();
                    break;
                case /* string outcome_tic */ 4:
                    message.outcomeTic = reader.string();
                    break;
                case /* string exe_limit */ 5:
                    message.exeLimit = reader.string();
                    break;
                case /* string short_description */ 6:
                    message.shortDescription = reader.string();
                    break;
                case /* bool has_limit */ 7:
                    message.hasLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialOutcome, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string outcome_group = 3; */
        if (message.outcomeGroup !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.outcomeGroup);
        /* string outcome_tic = 4; */
        if (message.outcomeTic !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.outcomeTic);
        /* string exe_limit = 5; */
        if (message.exeLimit !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.exeLimit);
        /* string short_description = 6; */
        if (message.shortDescription !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.shortDescription);
        /* bool has_limit = 7; */
        if (message.hasLimit !== false)
            writer.tag(7, WireType.Varint).bool(message.hasLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialOutcome
 */
export const InitialOutcome = new InitialOutcome$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventStreamString$Type extends MessageType<EventStreamString> {
    constructor() {
        super("web_odds.EventStreamString", [
            { no: 1, name: "live_events", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "upcoming_events", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "outright_events", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "player_events", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "group_events", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EventStreamString>): EventStreamString {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.liveEvents = "";
        message.upcomingEvents = "";
        message.outrightEvents = "";
        message.playerEvents = "";
        message.groupEvents = "";
        if (value !== undefined)
            reflectionMergePartial<EventStreamString>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventStreamString): EventStreamString {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string live_events */ 1:
                    message.liveEvents = reader.string();
                    break;
                case /* string upcoming_events */ 2:
                    message.upcomingEvents = reader.string();
                    break;
                case /* string outright_events */ 3:
                    message.outrightEvents = reader.string();
                    break;
                case /* string player_events */ 4:
                    message.playerEvents = reader.string();
                    break;
                case /* string group_events */ 5:
                    message.groupEvents = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventStreamString, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string live_events = 1; */
        if (message.liveEvents !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.liveEvents);
        /* string upcoming_events = 2; */
        if (message.upcomingEvents !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.upcomingEvents);
        /* string outright_events = 3; */
        if (message.outrightEvents !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.outrightEvents);
        /* string player_events = 4; */
        if (message.playerEvents !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.playerEvents);
        /* string group_events = 5; */
        if (message.groupEvents !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.groupEvents);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.EventStreamString
 */
export const EventStreamString = new EventStreamString$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventStream$Type extends MessageType<EventStream> {
    constructor() {
        super("web_odds.EventStream", [
            { no: 1, name: "live_events", kind: "message", T: () => WebStreamEvents },
            { no: 2, name: "upcoming_events", kind: "message", T: () => WebStreamEvents },
            { no: 3, name: "outright_events", kind: "message", T: () => WebStreamEvents },
            { no: 4, name: "player_events", kind: "message", T: () => WebStreamEvents },
            { no: 5, name: "group_events", kind: "message", T: () => WebStreamEvents }
        ]);
    }
    create(value?: PartialMessage<EventStream>): EventStream {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EventStream>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventStream): EventStream {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* web_odds.WebStreamEvents live_events */ 1:
                    message.liveEvents = WebStreamEvents.internalBinaryRead(reader, reader.uint32(), options, message.liveEvents);
                    break;
                case /* web_odds.WebStreamEvents upcoming_events */ 2:
                    message.upcomingEvents = WebStreamEvents.internalBinaryRead(reader, reader.uint32(), options, message.upcomingEvents);
                    break;
                case /* web_odds.WebStreamEvents outright_events */ 3:
                    message.outrightEvents = WebStreamEvents.internalBinaryRead(reader, reader.uint32(), options, message.outrightEvents);
                    break;
                case /* web_odds.WebStreamEvents player_events */ 4:
                    message.playerEvents = WebStreamEvents.internalBinaryRead(reader, reader.uint32(), options, message.playerEvents);
                    break;
                case /* web_odds.WebStreamEvents group_events */ 5:
                    message.groupEvents = WebStreamEvents.internalBinaryRead(reader, reader.uint32(), options, message.groupEvents);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventStream, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* web_odds.WebStreamEvents live_events = 1; */
        if (message.liveEvents)
            WebStreamEvents.internalBinaryWrite(message.liveEvents, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.WebStreamEvents upcoming_events = 2; */
        if (message.upcomingEvents)
            WebStreamEvents.internalBinaryWrite(message.upcomingEvents, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.WebStreamEvents outright_events = 3; */
        if (message.outrightEvents)
            WebStreamEvents.internalBinaryWrite(message.outrightEvents, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.WebStreamEvents player_events = 4; */
        if (message.playerEvents)
            WebStreamEvents.internalBinaryWrite(message.playerEvents, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.WebStreamEvents group_events = 5; */
        if (message.groupEvents)
            WebStreamEvents.internalBinaryWrite(message.groupEvents, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.EventStream
 */
export const EventStream = new EventStream$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebStreamEvents$Type extends MessageType<WebStreamEvents> {
    constructor() {
        super("web_odds.WebStreamEvents", [
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WebStreamEvent }
        ]);
    }
    create(value?: PartialMessage<WebStreamEvents>): WebStreamEvents {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<WebStreamEvents>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebStreamEvents): WebStreamEvents {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated web_odds.WebStreamEvent events */ 17:
                    message.events.push(WebStreamEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebStreamEvents, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated web_odds.WebStreamEvent events = 17; */
        for (let i = 0; i < message.events.length; i++)
            WebStreamEvent.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.WebStreamEvents
 */
export const WebStreamEvents = new WebStreamEvents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebStreamEvent$Type extends MessageType<WebStreamEvent> {
    constructor() {
        super("web_odds.WebStreamEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WebStreamEventOdd },
            { no: 7, name: "start", kind: "message", T: () => Timestamp },
            { no: 8, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "result", kind: "message", T: () => EventResult },
            { no: 10, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "outright_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "mon", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "number_of_odds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "exefeed_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "profile", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "betradar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "betradar_stream_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WebStreamEvent>): WebStreamEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.competitionId = 0;
        message.locationId = 0;
        message.sportId = 0;
        message.odds = [];
        message.isDisabled = false;
        message.landbaseCode = "";
        message.type = "";
        message.outrightType = "";
        message.mon = 0;
        message.numberOfOdds = 0;
        message.exefeedId = 0;
        message.profile = "";
        message.betradarId = "";
        message.betradarStreamId = "";
        if (value !== undefined)
            reflectionMergePartial<WebStreamEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebStreamEvent): WebStreamEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 competition_id */ 3:
                    message.competitionId = reader.int32();
                    break;
                case /* int32 location_id */ 4:
                    message.locationId = reader.int32();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* repeated web_odds.WebStreamEventOdd odds */ 6:
                    message.odds.push(WebStreamEventOdd.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp start */ 7:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* bool is_disabled */ 8:
                    message.isDisabled = reader.bool();
                    break;
                case /* common.EventResult result */ 9:
                    message.result = EventResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                case /* string landbase_code */ 10:
                    message.landbaseCode = reader.string();
                    break;
                case /* string type */ 11:
                    message.type = reader.string();
                    break;
                case /* string outright_type */ 12:
                    message.outrightType = reader.string();
                    break;
                case /* int32 mon */ 13:
                    message.mon = reader.int32();
                    break;
                case /* int32 number_of_odds */ 14:
                    message.numberOfOdds = reader.int32();
                    break;
                case /* int32 exefeed_id */ 15:
                    message.exefeedId = reader.int32();
                    break;
                case /* string profile */ 16:
                    message.profile = reader.string();
                    break;
                case /* string betradar_id */ 17:
                    message.betradarId = reader.string();
                    break;
                case /* string betradar_stream_id */ 18:
                    message.betradarStreamId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebStreamEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 competition_id = 3; */
        if (message.competitionId !== 0)
            writer.tag(3, WireType.Varint).int32(message.competitionId);
        /* int32 location_id = 4; */
        if (message.locationId !== 0)
            writer.tag(4, WireType.Varint).int32(message.locationId);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* repeated web_odds.WebStreamEventOdd odds = 6; */
        for (let i = 0; i < message.odds.length; i++)
            WebStreamEventOdd.internalBinaryWrite(message.odds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp start = 7; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bool is_disabled = 8; */
        if (message.isDisabled !== false)
            writer.tag(8, WireType.Varint).bool(message.isDisabled);
        /* common.EventResult result = 9; */
        if (message.result)
            EventResult.internalBinaryWrite(message.result, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string landbase_code = 10; */
        if (message.landbaseCode !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.landbaseCode);
        /* string type = 11; */
        if (message.type !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.type);
        /* string outright_type = 12; */
        if (message.outrightType !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.outrightType);
        /* int32 mon = 13; */
        if (message.mon !== 0)
            writer.tag(13, WireType.Varint).int32(message.mon);
        /* int32 number_of_odds = 14; */
        if (message.numberOfOdds !== 0)
            writer.tag(14, WireType.Varint).int32(message.numberOfOdds);
        /* int32 exefeed_id = 15; */
        if (message.exefeedId !== 0)
            writer.tag(15, WireType.Varint).int32(message.exefeedId);
        /* string profile = 16; */
        if (message.profile !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.profile);
        /* string betradar_id = 17; */
        if (message.betradarId !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.betradarId);
        /* string betradar_stream_id = 18; */
        if (message.betradarStreamId !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.betradarStreamId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.WebStreamEvent
 */
export const WebStreamEvent = new WebStreamEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebStreamEventOdd$Type extends MessageType<WebStreamEventOdd> {
    constructor() {
        super("web_odds.WebStreamEventOdd", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "frame_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "current_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "live_player", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WebStreamEventOdd>): WebStreamEventOdd {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.value = 0;
        message.outcomeId = 0;
        message.limit = 0;
        message.frameNumber = 0;
        message.currentResult = "";
        message.isDisabled = false;
        message.status = 0;
        message.livePlayer = "";
        if (value !== undefined)
            reflectionMergePartial<WebStreamEventOdd>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebStreamEventOdd): WebStreamEventOdd {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double value */ 2:
                    message.value = reader.double();
                    break;
                case /* int32 outcome_id */ 3:
                    message.outcomeId = reader.int32();
                    break;
                case /* double limit */ 4:
                    message.limit = reader.double();
                    break;
                case /* int32 frame_number */ 5:
                    message.frameNumber = reader.int32();
                    break;
                case /* string current_result */ 6:
                    message.currentResult = reader.string();
                    break;
                case /* bool is_disabled */ 7:
                    message.isDisabled = reader.bool();
                    break;
                case /* int32 status */ 8:
                    message.status = reader.int32();
                    break;
                case /* string live_player */ 9:
                    message.livePlayer = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebStreamEventOdd, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double value = 2; */
        if (message.value !== 0)
            writer.tag(2, WireType.Bit64).double(message.value);
        /* int32 outcome_id = 3; */
        if (message.outcomeId !== 0)
            writer.tag(3, WireType.Varint).int32(message.outcomeId);
        /* double limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Bit64).double(message.limit);
        /* int32 frame_number = 5; */
        if (message.frameNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.frameNumber);
        /* string current_result = 6; */
        if (message.currentResult !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.currentResult);
        /* bool is_disabled = 7; */
        if (message.isDisabled !== false)
            writer.tag(7, WireType.Varint).bool(message.isDisabled);
        /* int32 status = 8; */
        if (message.status !== 0)
            writer.tag(8, WireType.Varint).int32(message.status);
        /* string live_player = 9; */
        if (message.livePlayer !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.livePlayer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.WebStreamEventOdd
 */
export const WebStreamEventOdd = new WebStreamEventOdd$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebStreamEventInt$Type extends MessageType<WebStreamEventInt> {
    constructor() {
        super("web_odds.WebStreamEventInt", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WebStreamEventOddInt },
            { no: 7, name: "start", kind: "message", T: () => Timestamp },
            { no: 8, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "result", kind: "message", T: () => EventResult },
            { no: 10, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "outright_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "mon", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "number_of_odds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "exefeed_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "profile", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "betradar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "betradar_stream_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WebStreamEventInt>): WebStreamEventInt {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.competitionId = 0;
        message.locationId = 0;
        message.sportId = 0;
        message.odds = [];
        message.isDisabled = false;
        message.landbaseCode = "";
        message.type = "";
        message.outrightType = "";
        message.mon = 0;
        message.numberOfOdds = 0;
        message.exefeedId = 0;
        message.profile = "";
        message.betradarId = "";
        message.betradarStreamId = "";
        if (value !== undefined)
            reflectionMergePartial<WebStreamEventInt>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebStreamEventInt): WebStreamEventInt {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 competition_id */ 3:
                    message.competitionId = reader.int32();
                    break;
                case /* int32 location_id */ 4:
                    message.locationId = reader.int32();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* repeated web_odds.WebStreamEventOddInt odds */ 6:
                    message.odds.push(WebStreamEventOddInt.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp start */ 7:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* bool is_disabled */ 8:
                    message.isDisabled = reader.bool();
                    break;
                case /* common.EventResult result */ 9:
                    message.result = EventResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                case /* string landbase_code */ 10:
                    message.landbaseCode = reader.string();
                    break;
                case /* string type */ 11:
                    message.type = reader.string();
                    break;
                case /* string outright_type */ 12:
                    message.outrightType = reader.string();
                    break;
                case /* int32 mon */ 13:
                    message.mon = reader.int32();
                    break;
                case /* int32 number_of_odds */ 14:
                    message.numberOfOdds = reader.int32();
                    break;
                case /* int32 exefeed_id */ 15:
                    message.exefeedId = reader.int32();
                    break;
                case /* string profile */ 16:
                    message.profile = reader.string();
                    break;
                case /* string betradar_id */ 17:
                    message.betradarId = reader.string();
                    break;
                case /* string betradar_stream_id */ 18:
                    message.betradarStreamId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebStreamEventInt, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 competition_id = 3; */
        if (message.competitionId !== 0)
            writer.tag(3, WireType.Varint).int32(message.competitionId);
        /* int32 location_id = 4; */
        if (message.locationId !== 0)
            writer.tag(4, WireType.Varint).int32(message.locationId);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* repeated web_odds.WebStreamEventOddInt odds = 6; */
        for (let i = 0; i < message.odds.length; i++)
            WebStreamEventOddInt.internalBinaryWrite(message.odds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp start = 7; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bool is_disabled = 8; */
        if (message.isDisabled !== false)
            writer.tag(8, WireType.Varint).bool(message.isDisabled);
        /* common.EventResult result = 9; */
        if (message.result)
            EventResult.internalBinaryWrite(message.result, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string landbase_code = 10; */
        if (message.landbaseCode !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.landbaseCode);
        /* string type = 11; */
        if (message.type !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.type);
        /* string outright_type = 12; */
        if (message.outrightType !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.outrightType);
        /* int32 mon = 13; */
        if (message.mon !== 0)
            writer.tag(13, WireType.Varint).int32(message.mon);
        /* int32 number_of_odds = 14; */
        if (message.numberOfOdds !== 0)
            writer.tag(14, WireType.Varint).int32(message.numberOfOdds);
        /* int32 exefeed_id = 15; */
        if (message.exefeedId !== 0)
            writer.tag(15, WireType.Varint).int32(message.exefeedId);
        /* string profile = 16; */
        if (message.profile !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.profile);
        /* string betradar_id = 17; */
        if (message.betradarId !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.betradarId);
        /* string betradar_stream_id = 18; */
        if (message.betradarStreamId !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.betradarStreamId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.WebStreamEventInt
 */
export const WebStreamEventInt = new WebStreamEventInt$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebStreamEventOddInt$Type extends MessageType<WebStreamEventOddInt> {
    constructor() {
        super("web_odds.WebStreamEventOddInt", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "value", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "frame_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "current_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "live_player", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WebStreamEventOddInt>): WebStreamEventOddInt {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.value = 0;
        message.outcomeId = 0;
        message.limit = 0;
        message.frameNumber = 0;
        message.currentResult = "";
        message.isDisabled = false;
        message.status = 0;
        message.livePlayer = "";
        if (value !== undefined)
            reflectionMergePartial<WebStreamEventOddInt>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebStreamEventOddInt): WebStreamEventOddInt {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 value */ 2:
                    message.value = reader.int32();
                    break;
                case /* int32 outcome_id */ 3:
                    message.outcomeId = reader.int32();
                    break;
                case /* int32 limit */ 4:
                    message.limit = reader.int32();
                    break;
                case /* int32 frame_number */ 5:
                    message.frameNumber = reader.int32();
                    break;
                case /* string current_result */ 6:
                    message.currentResult = reader.string();
                    break;
                case /* bool is_disabled */ 7:
                    message.isDisabled = reader.bool();
                    break;
                case /* int32 status */ 8:
                    message.status = reader.int32();
                    break;
                case /* string live_player */ 9:
                    message.livePlayer = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebStreamEventOddInt, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 value = 2; */
        if (message.value !== 0)
            writer.tag(2, WireType.Varint).int32(message.value);
        /* int32 outcome_id = 3; */
        if (message.outcomeId !== 0)
            writer.tag(3, WireType.Varint).int32(message.outcomeId);
        /* int32 limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Varint).int32(message.limit);
        /* int32 frame_number = 5; */
        if (message.frameNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.frameNumber);
        /* string current_result = 6; */
        if (message.currentResult !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.currentResult);
        /* bool is_disabled = 7; */
        if (message.isDisabled !== false)
            writer.tag(7, WireType.Varint).bool(message.isDisabled);
        /* int32 status = 8; */
        if (message.status !== 0)
            writer.tag(8, WireType.Varint).int32(message.status);
        /* string live_player = 9; */
        if (message.livePlayer !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.livePlayer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.WebStreamEventOddInt
 */
export const WebStreamEventOddInt = new WebStreamEventOddInt$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LiveDisabled$Type extends MessageType<LiveDisabled> {
    constructor() {
        super("web_odds.LiveDisabled", [
            { no: 1, name: "all", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "sport_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<LiveDisabled>): LiveDisabled {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.all = false;
        message.sportIds = [];
        if (value !== undefined)
            reflectionMergePartial<LiveDisabled>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LiveDisabled): LiveDisabled {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool all */ 1:
                    message.all = reader.bool();
                    break;
                case /* repeated int32 sport_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.sportIds.push(reader.int32());
                    else
                        message.sportIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LiveDisabled, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool all = 1; */
        if (message.all !== false)
            writer.tag(1, WireType.Varint).bool(message.all);
        /* repeated int32 sport_ids = 2; */
        if (message.sportIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.sportIds.length; i++)
                writer.int32(message.sportIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.LiveDisabled
 */
export const LiveDisabled = new LiveDisabled$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlayerOutrightMarkets$Type extends MessageType<PlayerOutrightMarkets> {
    constructor() {
        super("web_odds.PlayerOutrightMarkets", [
            { no: 1, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "outright_markets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialMarket },
            { no: 3, name: "player_markets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialMarket }
        ]);
    }
    create(value?: PartialMessage<PlayerOutrightMarkets>): PlayerOutrightMarkets {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        message.outrightMarkets = [];
        message.playerMarkets = [];
        if (value !== undefined)
            reflectionMergePartial<PlayerOutrightMarkets>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlayerOutrightMarkets): PlayerOutrightMarkets {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 1:
                    message.sportId = reader.int32();
                    break;
                case /* repeated web_odds.InitialMarket outright_markets */ 2:
                    message.outrightMarkets.push(InitialMarket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated web_odds.InitialMarket player_markets */ 3:
                    message.playerMarkets.push(InitialMarket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlayerOutrightMarkets, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 1; */
        if (message.sportId !== 0)
            writer.tag(1, WireType.Varint).int32(message.sportId);
        /* repeated web_odds.InitialMarket outright_markets = 2; */
        for (let i = 0; i < message.outrightMarkets.length; i++)
            InitialMarket.internalBinaryWrite(message.outrightMarkets[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated web_odds.InitialMarket player_markets = 3; */
        for (let i = 0; i < message.playerMarkets.length; i++)
            InitialMarket.internalBinaryWrite(message.playerMarkets[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.PlayerOutrightMarkets
 */
export const PlayerOutrightMarkets = new PlayerOutrightMarkets$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LandingPage$Type extends MessageType<LandingPage> {
    constructor() {
        super("web_odds.LandingPage", [
            { no: 1, name: "top", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WebStreamEvent },
            { no: 2, name: "live", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WebStreamEvent },
            { no: 3, name: "prematch", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WebStreamEvent }
        ]);
    }
    create(value?: PartialMessage<LandingPage>): LandingPage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.top = [];
        message.live = [];
        message.prematch = [];
        if (value !== undefined)
            reflectionMergePartial<LandingPage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LandingPage): LandingPage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated web_odds.WebStreamEvent top */ 1:
                    message.top.push(WebStreamEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated web_odds.WebStreamEvent live */ 2:
                    message.live.push(WebStreamEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated web_odds.WebStreamEvent prematch */ 3:
                    message.prematch.push(WebStreamEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LandingPage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated web_odds.WebStreamEvent top = 1; */
        for (let i = 0; i < message.top.length; i++)
            WebStreamEvent.internalBinaryWrite(message.top[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated web_odds.WebStreamEvent live = 2; */
        for (let i = 0; i < message.live.length; i++)
            WebStreamEvent.internalBinaryWrite(message.live[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated web_odds.WebStreamEvent prematch = 3; */
        for (let i = 0; i < message.prematch.length; i++)
            WebStreamEvent.internalBinaryWrite(message.prematch[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.LandingPage
 */
export const LandingPage = new LandingPage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FeaturedEvent$Type extends MessageType<FeaturedEvent> {
    constructor() {
        super("web_odds.FeaturedEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FeaturedEvent>): FeaturedEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<FeaturedEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FeaturedEvent): FeaturedEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FeaturedEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.FeaturedEvent
 */
export const FeaturedEvent = new FeaturedEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FeaturedEvents$Type extends MessageType<FeaturedEvents> {
    constructor() {
        super("web_odds.FeaturedEvents", [
            { no: 1, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FeaturedEvent }
        ]);
    }
    create(value?: PartialMessage<FeaturedEvents>): FeaturedEvents {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<FeaturedEvents>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FeaturedEvents): FeaturedEvents {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated web_odds.FeaturedEvent events */ 1:
                    message.events.push(FeaturedEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FeaturedEvents, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated web_odds.FeaturedEvent events = 1; */
        for (let i = 0; i < message.events.length; i++)
            FeaturedEvent.internalBinaryWrite(message.events[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.FeaturedEvents
 */
export const FeaturedEvents = new FeaturedEvents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStreamUrlRequest$Type extends MessageType<GetStreamUrlRequest> {
    constructor() {
        super("web_odds.GetStreamUrlRequest", [
            { no: 1, name: "stream_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "stream_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "csid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "device_category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "real_ip", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetStreamUrlRequest>): GetStreamUrlRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.streamId = "";
        message.streamType = "";
        message.csid = "";
        message.deviceCategory = "";
        message.realIp = "";
        if (value !== undefined)
            reflectionMergePartial<GetStreamUrlRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetStreamUrlRequest): GetStreamUrlRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stream_id */ 1:
                    message.streamId = reader.string();
                    break;
                case /* string stream_type */ 2:
                    message.streamType = reader.string();
                    break;
                case /* string csid */ 3:
                    message.csid = reader.string();
                    break;
                case /* string device_category */ 4:
                    message.deviceCategory = reader.string();
                    break;
                case /* string real_ip */ 5:
                    message.realIp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetStreamUrlRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stream_id = 1; */
        if (message.streamId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.streamId);
        /* string stream_type = 2; */
        if (message.streamType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.streamType);
        /* string csid = 3; */
        if (message.csid !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.csid);
        /* string device_category = 4; */
        if (message.deviceCategory !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.deviceCategory);
        /* string real_ip = 5; */
        if (message.realIp !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.realIp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.GetStreamUrlRequest
 */
export const GetStreamUrlRequest = new GetStreamUrlRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamUrl$Type extends MessageType<StreamUrl> {
    constructor() {
        super("web_odds.StreamUrl", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<StreamUrl>): StreamUrl {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.url = "";
        if (value !== undefined)
            reflectionMergePartial<StreamUrl>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StreamUrl): StreamUrl {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* common.Error error */ 2:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StreamUrl, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* common.Error error = 2; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.StreamUrl
 */
export const StreamUrl = new StreamUrl$Type();
/**
 * @generated ServiceType for protobuf service web_odds.WebOddsService
 */
export const WebOddsService = new ServiceType("web_odds.WebOddsService", [
    { name: "GetInitialData", options: {}, I: InitialDataRequest, O: InitialDataResponse },
    { name: "EventsStream", serverStreaming: true, options: {}, I: Empty, O: EventStream },
    { name: "EventsStreamString", serverStreaming: true, options: {}, I: Empty, O: EventStreamString },
    { name: "GetSingleEvent", options: {}, I: WebStreamEvent, O: WebStreamEvent },
    { name: "GetLiveDisabled", options: {}, I: Empty, O: LiveDisabled },
    { name: "GetPlayerOutrightMarkets", options: {}, I: Empty, O: PlayerOutrightMarkets },
    { name: "GetMissingCompetition", options: {}, I: CompetitionId, O: Competition },
    { name: "GetLandingPage", options: {}, I: Empty, O: LandingPage },
    { name: "GetFeaturedEvents", options: {}, I: Empty, O: FeaturedEvents },
    { name: "GetStreamUrl", options: {}, I: GetStreamUrlRequest, O: StreamUrl }
]);
