/* React modules */
import { useState, useEffect } from 'react';

/* Our modules */
import { TicketError } from 'modules/ticket/ticket.types';
import { WalletTypes } from 'modules/wallet/wallet.types';
import useStores from 'common/hooks/useStores';
import { Dropdown, Icon } from 'components';
import TicketActions from 'modules/ticket/ui/TicketSidebar/Ticket/components/TicketActions';
import TicketRecords from 'modules/ticket/ui/TicketSidebar/Ticket/components/TicketRecords';
import TicketTotal from 'modules/ticket/ui/TicketSidebar/Ticket/components/TicketTotal';
import TicketStatus from 'modules/ticket/ui/TicketStatus';
import Login from 'modules/auth/ui/Login';
import './Ticket.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';

interface TicketControllerProps {
  isFix: boolean;
}

const TicketController = ({ isFix }: TicketControllerProps) => {
  const { t } = useTranslation();

  const standardWallet = {
    label: t('wallet.standard-account'),
    value: WalletTypes.STANDARD,
  };

  const promoWallet = {
    label: t('wallet.promo-bill'),
    value: WalletTypes.PROMO,
  };

  const promoBetBonusWallet = {
    label: t('wallet.free-bet-bonus'),
    value: WalletTypes.PROMO_BET_BONUS,
  };

  const [walletOptions, setWalletOptions] = useState<
    {
      label: string;
      value: WalletTypes;
    }[]
  >([standardWallet, promoWallet]);

  const {
    ticketBuilder,
    ticketsStore,
    ticketValidator,
    overlayStore,
    authStore,
    userStore,
  } = useStores();

  const { getQuickCode } = ticketsStore;

  const updateAmount = (val: any) => {
    const regExp = /[,=a-zA-Z]/g;
    let regExpResult = regExp.test(val.target.value);
    if (regExpResult) return;
    let value = '';
    if (val.target.value.includes('.')) {
      value = val.target.value.split('.')[0] + '.';
      value += val.target.value.split('.')[1].slice(0, 2);
      val.target.value = value;
    }
    ticketBuilder.setAmount(val.target.value.slice(0, 10));
  };

  const handleGetQuickCodeError = (errors: string[]) => {
    if ((errors || []).length) {
      if (errors.includes(TicketError.INVALID_SYSTEM_SLIP)) {
        toast.error(t('errors.invalid-system-slip'));
      }
    }
  };

  const handleQuickBet = () => {
    overlayStore.closeDrawer();
    ticketsStore.preview(ticketBuilder.slip);

    const { ticket } = ticketsStore;
    const { amount, systemGroups } = ticket || {};

    getQuickCode({ amount: amount || -1, systemGroups }).then((errors) => {
      if ((errors || []).length) {
        handleGetQuickCodeError(errors);
      } else {
        ticketValidator.clearServerErrors();

        overlayStore.openModal(<TicketStatus quickTicket={true} />, {
          className: 'visibe-tablet-down',
          wrapClassName: 'ticket-preview-modal-wrap',
          width: 'large',
          onClose: () => ticketValidator.clearServerErrors(),
          name: 'ticket-preview',
        });
      }
    });
  };

  const handlePlaceBet = () => {
    overlayStore.closeDrawer();

    if (!authStore.isLoggedIn) {
      const modalContent = (
        <Login isModal={true} onSuccess={() => overlayStore.closeModal()} />
      );
      overlayStore.openModal(modalContent, {
        className: 'visibe-tablet-up',
        width: 'small',
      });

      return;
    }

    const formattedSlip = formatSlipGroups(ticketBuilder.slip);
    ticketsStore.preview(formattedSlip);

    overlayStore.openModal(<TicketStatus quickTicket={false} />, {
      className: 'visibe-tablet-down',
      wrapClassName: 'ticket-preview-modal-wrap',
      width: 'large',
      onClose: () => ticketValidator.clearServerErrors(),
      name: 'ticket-preview',
    });
  };

  const formatSlipGroups = (slip: any) => {
    const newSystemGroups: any = [];
    const fixEvents: any = [];
    if (!isFix) {
      slip.systemGroups.forEach((group: any) => {
        const a = group?.system?.split('/')[0].trim();
        const b = group?.system?.split('/')[1].trim();
        if (a === b) {
          fixEvents.push(...group.events);
        } else {
          newSystemGroups.push(group);
        }
      });
      if (fixEvents.length) {
        newSystemGroups.splice(0, 0, {
          events: fixEvents,
          system: fixEvents.length + '  / ' + fixEvents.length,
        });
      }
      slip.systemGroups = newSystemGroups;
    }
    return slip;
  };

  useEffect(() => {
    if (!isFix) {
      ticketBuilder.setSystemSlip();
    }
  }, [isFix, ticketBuilder]);

  useEffect(() => {
    const promoBetBonusOption = walletOptions.find(
      (item) => item.value === WalletTypes.PROMO_BET_BONUS
    );

    if (userStore.hasPromoBetWallet && !promoBetBonusOption) {
      setWalletOptions([...walletOptions, promoBetBonusWallet]);
    } else {
      setWalletOptions(
        walletOptions.filter(
          (item) => item.value !== WalletTypes.PROMO_BET_BONUS
        )
      );
    }
  }, [userStore.hasPromoBetWallet]);

  return (
    <div className="regular" data-testid="regular-ticket">
      <div className="regular__body">
        <TicketRecords isSystem={!isFix} />
        <div className="ticket-info-wrapper">
          <div className="input-amount-wrapper">
            <input
              id="amount"
              value={ticketBuilder.amount}
              name="amount"
              type="number"
              placeholder="0.5"
              onChange={updateAmount}
              className="amount"
            />
            <p className="input-amount-wrapper__currency">EUR</p>
          </div>
          <div>
            {isFix && (
              <div className="regular__header">
                <div className="w-50 text-ellipsis">{t('slips.total-odd')}</div>
                <Tooltip
                  overlayClassName="tooltip"
                  placement="bottom"
                  overlay={ticketBuilder.totalOdd.toFixed(2)}
                >
                  <div className="w-50 text-right text-ellipsis">
                    {ticketBuilder.totalOdd.toFixed(2)}
                  </div>
                </Tooltip>
              </div>
            )}

            <div className="account">
              <Dropdown
                bg="dark"
                style={{ width: '90px' }}
                handleSelect={ticketBuilder.changeWallet}
                options={walletOptions}
              >
                <div className="d-flex align-items">
                  <span className="mr-2 text-white text-small">
                    {ticketBuilder.wallet === WalletTypes.STANDARD
                      ? standardWallet.label
                      : ticketBuilder.wallet === WalletTypes.PROMO_BET_BONUS
                      ? promoBetBonusWallet.label
                      : promoWallet.label}
                  </span>

                  <Icon name="caretDown" size="extra-small" />
                </div>
              </Dropdown>
            </div>

            <TicketTotal />

            <div>
              {ticketValidator.displayError && (
                <div className="mb-2 text-yellow text-small text-center">
                  {t(
                    ticketValidator.displayError.key,
                    ticketValidator.displayError.values
                  )}
                </div>
              )}

              {ticketValidator.displayWarning &&
                !ticketValidator.displayError && (
                  <div className="mb-2 text-yellow text-small text-center">
                    {t(
                      (ticketValidator.displayWarning as any).key,
                      (ticketValidator.displayWarning as any).values
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>

      <TicketActions placeBet={handlePlaceBet} quickBet={handleQuickBet} />
    </div>
  );
};

TicketController.defaultProps = {
  isFix: true,
};

export default observer(TicketController);
