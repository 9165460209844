/* React modules */
import { useCallback, useContext, useEffect } from 'react';

/* Our modules */
import { EVENT_TYPE } from 'modules/sports/sports.types';
import useStores from 'common/hooks/useStores';
import Odd from 'modules/sports/store/odd.store';
import { getEventsCountPerType } from 'modules/offer/services/offer-helpers.service';
import Carousel from 'components/Carousel';
import CountryIcon, { COUNTRY_ICONS } from 'components/CountryIcon';
import { Icon } from 'components';
import Loader from 'components/Loader';
import { OverlayContext } from 'pages/sport/Sport';
import { EventDetailsOdd } from 'modules/offer/ui/OfferMobile/EventDetails/EventDetailsOdd';
import MatchStatistic from 'pages/sport/EventAllOddsOverlay/components/MatchStatistic';
import DefaultLocationIcon from '../../../../../images/defaultLocationIcon.svg';
import './EventDetails.scss';

/* 3rd Party modules */
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const SLIDER_CONFIG = {
  arrows: false,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const RenderArrow = ({
  direction,
  visible,
}: {
  direction: 'left' | 'right';
  visible: boolean;
}) => {
  const ARROW_STYLES: any = { color: 'white' };

  if (direction === 'left') {
    ARROW_STYLES.transform = 'rotate(-90deg)';
  } else {
    ARROW_STYLES.transform = 'rotate(90deg)';
  }

  if (!visible) return <div />;

  return <div style={ARROW_STYLES}>&#9651;</div>;
};

const formatMarkets = (
  odds: Odd[],
  isSelected: (market: string) => boolean,
  lang: string
) => {
  const visibleOdds = (odds || []).filter((odd) =>
    odd.market ? isSelected(odd.market.name) : false
  );

  return (visibleOdds || []).reduce((markets: any[], odd: Odd) => {
    const market = (markets || []).find((m: any) => m.id === odd.market?.id);

    if (market) {
      market.odds = [...market.odds, odd];

      return markets;
    }

    return [
      ...markets,
      { market: odd.market?.getName(lang), id: odd.market?.id, odds: [odd] },
    ];
  }, []);
};

const EventDetails = observer(
  ({
    events,
    event,
    rowIndex,
    hasHeader = true,
  }: {
    events?: any;
    event: any;
    rowIndex?: number;
    hasHeader?: boolean;
  }) => {
    const { t, i18n } = useTranslation();
    const {
      overlayStore,
      sportsStore,
      offerFilters,
      marketSwitcher,
      offerStore: { liveDisabled, disabledSports },
    } = useStores();
    const { allEventsLoaded, offerCounters } = sportsStore;

    const {
      type,
      home,
      away,
      day,
      englishDay,
      locationName,
      competitionName,
      disabled,
      odds,
      eventTime,
      currentResult,
      sportId,
      betradarId,
      competitionShortDisplay,
    } = event;

    useEffect(() => {
      event.initOdds();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event]);

    const openAllOdds = useContext(OverlayContext);

    const oddsPerMarket = formatMarkets(
      odds,
      marketSwitcher.isSelectedOnMobile,
      i18n.language
    );

    const openOverlay = useCallback(
      () =>
        openAllOdds({
          event,
          odds,
          location: locationName,
          competition: competitionName,
        }),
      [event, odds, openAllOdds, locationName, competitionName]
    );

    const renderMarkets = (market: any, index: number) => {
      const oddsToShow = market.hasLimit ? 2 : 3;
      const limit = market?.odds[0]?.limit;
      const frame = market?.odds[0]?.frameNumber;

      return (
        <div
          key={`renderMarkets-div-${market.market.id}`}
          data-testid={`event-details-odds-markets-${index}`}
          className="event-mobile__odd-title"
        >
          <div className="d-flex space-between align-items">
            {hasHeader && (
              <RenderArrow direction="left" visible={index !== 0} />
            )}

            <div className="event-mobile__market mx-auto">
              {market.market} {frame ? frame : ''}
            </div>

            {hasHeader && (
              <RenderArrow
                direction="right"
                visible={index !== (oddsPerMarket || []).length - 1}
              />
            )}
          </div>

          <div className="event-mobile__odd-wrapper">
            {market.hasLimit && (
              <div className="event-mobile__odd-limit">
                <div>
                  <p>{t('sportsPage.limit')}</p>
                  <p className="text-strong mt-1">{limit}</p>
                </div>
              </div>
            )}

            {((market.odds || []).slice(0, oddsToShow) || []).map(
              (odd: any) => (
                <EventDetailsOdd
                  key={`renderMarkets-EventDetailsOdd-${odd.id}`}
                  odd={odd}
                  event={event}
                />
              )
            )}
          </div>
        </div>
      );
    };

    const rowClass = {
      live: type === EVENT_TYPE.LIVE,
      upcoming: type === EVENT_TYPE.UPCOMING,
      antepost: type === EVENT_TYPE.ANTEPOST,
      player: type === EVENT_TYPE.PLAYER,
      special: type === EVENT_TYPE.SPECIAL,
    };

    const isSuspended = () => {
      if (type === EVENT_TYPE.LIVE) {
        return disabled || liveDisabled || disabledSports.includes(sportId);
      }

      return disabled;
    };

    /* SORT MOBILE OFFER - START
     *
     * This block of code sort offer:
     *
     * 1. per market id
     * 2. for each market odds, sort odds per outcome id
     * 3. if has limit on any odd, sort odds per limit from high to low, and disabled odds move to the end of list
     * 4. set on maket new property "hasLimit", it will be needed for displaying limit on "renderMarkets" */

    (oddsPerMarket || []).sort((a: any, b: any) => (a.id > b.id ? 1 : -1));

    (oddsPerMarket || []).forEach((market: any) => {
      (market.odds || []).sort((a: any, b: any) =>
        a.outcome.id > b.outcome.id ? 1 : -1
      );
      // market.odds.sort((a: any, b: any) => (a.frameNumber > b.frameNumber ) ? 1 : -1);

      if ((market.odds || []).some((o: any) => o.limit)) {
        market.odds.sort((a: any, b: any) =>
          a.frameNumber > b.frameNumber ? 1 : -1
        );

        market.odds.sort((a: any, b: any) => (a.limit > b.limit ? 1 : -1));

        market.odds.sort((a: any, b: any) =>
          a.isDisabled > b.isDisabled ? 1 : -1
        );

        market.hasLimit = true;
      } else {
        market.hasLimit = false;
      }
    });
    // SORT MOBILE OFFER - END

    const openMatchStats = () => {
      overlayStore.openModal(<MatchStatistic betradarId={betradarId} />, {
        width: 'large',
        className: 'bg-black-700',
        wrapClassName: 'bg-black-700',
        name: 'sr-iframe',
        closable: false,
      });
    };

    //@ts-ignore
    const prevRow = (events || [])[rowIndex - 1];

    let streamEventsCount = 0;
    let localEventsCount = 0;

    if (prevRow !== undefined) {
      //@ts-ignore
      streamEventsCount = getEventsCountPerType(
        prevRow.sportId,
        offerCounters,
        offerFilters.timeFilter,
        type
      );

      //@ts-ignore
      localEventsCount = (events || []).length;
    }

    return (
      <>
        <div className="event-details" data-testid="event-details">
          {hasHeader && (
            <div className="ml-1 event-mobile__header align-col">
              <div className="w-15 align-col__center">{t('globals.time')}</div>

              <div className="w-15 align-col__center">
                {t('sportsPage.competition')}
              </div>

              <div className="w-70 align-col__left pl-2">
                {t('sportsPage.event')}
              </div>
            </div>
          )}

          {hasHeader && (
            <div className="event-mobile__rows">
              <div
                onClick={isSuspended() ? () => null : openOverlay}
                className={classnames('align-col event-mobile__row', rowClass)}
              >
                <div className="w-15">
                  {i18n.language === 'me' ? day : englishDay}
                </div>

                <div className="w-15 competition pt-1">
                  {COUNTRY_ICONS[locationName] !== undefined ? (
                    <CountryIcon
                      original
                      country={COUNTRY_ICONS[locationName]}
                    />
                  ) : (
                    <img
                      alt="Location"
                      style={{ height: '20px', width: '20px' }}
                      src={DefaultLocationIcon}
                    />
                  )}
                </div>

                <div className="w-70">
                  <p className="w-70 text-left pl-2">{home}</p>

                  <p className="w-30 text-right pr-2">
                    {(currentResult || []).map((period: any, index: number) => (
                      <span
                        key={`EventDetails-span-1-${index}`}
                        className="mr-2 event-mobile__result"
                      >
                        {period.homeScore}
                      </span>
                    ))}
                  </p>
                </div>
              </div>

              <div
                className={classnames('align-col event-mobile__row', rowClass)}
              >
                <div className="w-15 text-green-600">{eventTime}</div>

                <div className="w-15 competition">
                  {competitionShortDisplay}
                </div>

                <div className="w-70">
                  <p className="w-60 text-left pl-2">{away}</p>

                  <p className="w-40 text-right pr-2">
                    {(currentResult || []).map((period: any, index: number) => (
                      <span
                        key={`EventDetails-span-2-${index}`}
                        className="mr-2 event-mobile__result"
                      >
                        {period.awayScore}
                      </span>
                    ))}
                  </p>

                  {/*betradar statistic */}
                  {type === EVENT_TYPE.UPCOMING &&
                    betradarId &&
                    betradarId !== '' && (
                      <p className="w-40 text-right mb-6">
                        <Icon name="stats" onClick={openMatchStats} />
                      </p>
                    )}
                </div>
              </div>
            </div>
          )}

          {!isSuspended() &&
            (hasHeader ? (
              <Carousel sliderConfig={SLIDER_CONFIG}>
                {(oddsPerMarket || []).map(renderMarkets)}
              </Carousel>
            ) : (
              <div>{(oddsPerMarket || []).map(renderMarkets)}</div>
            ))}

          {isSuspended() && (
            <div className="event-mobile__disabled">
              <Icon name="lockSmall" size="regular" />

              <div className="ml-2">{t('errors.bet-not-possible')}</div>
            </div>
          )}
        </div>

        {(events || []).length - 1 === rowIndex &&
          !allEventsLoaded &&
          localEventsCount !== streamEventsCount && (
            <div style={{ height: '143px', background: '#303030' }}>
              <Loader height={'0px'} />
            </div>
          )}
      </>
    );
  }
);

export { EventDetails };
