/* React modules */

/* Our modules */
import { InitialOutcome } from 'generated-proto/proto/cache_service/cache_pb';
import SportMarketBase from 'modules/sports/market-base-model';
import { Market } from 'modules/sports/sports.types';

/* 3rd Party modules */

class SportMarket extends SportMarketBase {
  public outcomes: InitialOutcome[];

  constructor(data: Market) {
    super(data);

    const { outcomes, outcomesList } = data || {};

    let marketOutcomes: InitialOutcome[] = [];

    if ((outcomesList || []).length) {
      marketOutcomes = outcomesList || [];
    } else if (outcomes) {
      marketOutcomes = Array.from(new Map(Object.entries(outcomes)).values());
    }

    this.outcomes = (marketOutcomes || []).map((outcome) => ({
      ...outcome,
      displayName: outcome.exeLimit
        ? outcome.name.includes('+')
          ? '>'
          : '<'
        : outcome.name,
    }));
  }
}

export { SportMarket };
