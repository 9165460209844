import { http } from 'app/axios-config';

class QuickGamesApi {
  getSevenGameData() {
    return http.get('/games/seven/launch-details');
  }

  getSpribeGameData(game: string = 'aviator', isDemo: boolean) {
    const endpoint = isDemo ? 'launch-demo-url' : 'launch-url';
    return http.get(`/games/spribe/${endpoint}/${game}`);
  }

  getPokerLaunchURL() {
    return http.get<string>('/games/poker/launch-url');
  }

  getElbetLaunchURL(game: string) {
    return http.get('/games/elbet/instant-game-launch-url', {
      params: { game, version: 'desktop' },
    });
  }
}

export default QuickGamesApi;
