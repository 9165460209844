/* React modules */
import { useCallback, useEffect, useState } from 'react';

/* Our modules */
import { EVENT_TYPE, EventType } from 'modules/sports/sports.types';
import useStores from 'common/hooks/useStores';
import { TIME_FILTER_OPTIONS } from 'modules/offer/store/offer.filters';
import { SportNodeService } from 'modules/sports/services/sport-node.service';
import offerEventsService from 'modules/offer/services/offer-events.service';
import { getEventsCountPerType } from 'modules/offer/services/offer-helpers.service';
import { Icon, MobileFilter } from 'components';
import { IconName, SPORT_ICONS } from 'components/Icon/Icon';
import './SportsFilterMobile.scss';

/* 3rd Party modules */
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';

const EventTypeStyles: any = {
  [EVENT_TYPE.LIVE]: {
    iconName: 'live',
    textColor: 'green-600',
  },
  [EVENT_TYPE.UPCOMING]: {
    iconName: 'calendar',
    textColor: 'orange-300',
  },
};

const EventsCount = ({
  type,
  className,
  count,
}: {
  type: EventType;
  className?: string;
  count: number;
}) => {
  const styles = EventTypeStyles[type];

  return (
    <div className={`d-flex ${className}`}>
      <Icon name={styles.iconName as IconName} variant="lightgrey" />
      <span className={`text-${styles.textColor} ml-1`}>{count}</span>
    </div>
  );
};

export interface SportsFilterMobileProps {}

interface RenderLocationsProps {
  records: Array<SportNodeService>;
  onSelected: (arg: any) => void;
}

type SportIcon = keyof typeof SPORT_ICONS;

const RenderRecords = observer(
  ({ records, onSelected }: RenderLocationsProps) => {
    const { offerStore } = useStores();

    const isDisabled = (eventsCount: any) => {
      return Object.keys(eventsCount).every((type) => eventsCount[type] === 0);
    };

    const RenderSport = (sport: SportNodeService) => {
      const { sportsStore, offerFilters } = useStores();
      const { offerCounters, allEventsLoaded } = sportsStore;

      const eventsCount = offerEventsService.getEventsCount(
        [sport],
        offerStore.offerState
      );

      const inactive = isDisabled(eventsCount);

      return (
        <div
          key={`RenderRecords-div-${sport.id}`}
          className={classnames('sports-filter-mobile__record', { inactive })}
          onClick={() => (inactive ? null : onSelected(sport))}
        >
          <div className="d-flex align-items">
            <Icon
              size="large"
              className="mr-3"
              name={SPORT_ICONS[sport.name.toLowerCase() as SportIcon]}
            />
            <div>{sport.name?.toUpperCase()}</div>
          </div>

          <div className="d-flex">
            <EventsCount
              count={
                allEventsLoaded
                  ? eventsCount[EVENT_TYPE.UPCOMING]
                  : getEventsCountPerType(
                      sport.id,
                      offerCounters,
                      offerFilters.timeFilter,
                      EVENT_TYPE.UPCOMING
                    ) || 0
              }
              type={EVENT_TYPE.UPCOMING}
            />
            <EventsCount
              className="ml-2"
              count={
                allEventsLoaded
                  ? eventsCount[EVENT_TYPE.LIVE]
                  : getEventsCountPerType(
                      sport.id,
                      offerCounters,
                      offerFilters.timeFilter,
                      EVENT_TYPE.LIVE
                    ) || 0
              }
              type={EVENT_TYPE.LIVE}
            />
          </div>
        </div>
      );
    };

    return (
      <div className="sports-filter-mobile__records">
        {records.map(RenderSport)}
      </div>
    );
  }
);

const RenderTimeFilters = observer(({ closeDrawer }: any) => {
  const { offerFilters } = useStores();
  const { timeFilter, setTimeFilter } = offerFilters;

  return (
    <div className="d-flex align-items bg-black-500">
      <div className="sports-filter-mobile__time-filter">
        {TIME_FILTER_OPTIONS.map((filter) => (
          <div
            key={`RenderTimeFilters-div-${filter.label}`}
            className={classnames('filter-option', {
              selected: timeFilter === filter.value,
            })}
            onClick={() => setTimeFilter(filter.value)}
          >
            {filter.label.toUpperCase()}
          </div>
        ))}
      </div>
      <div onClick={closeDrawer} className="ml-auto mr-5 mt-2 heading-l">
        &times;
      </div>
    </div>
  );
});

const SportsFilterMobile = () => {
  const {
    // offerStore: { eventFilter, setActiveGroup },
    offerStore: { setActiveGroup },
    sportsStore: { sportsData },
    offerFilters: { selectedSport, setSelectedSport },
    marketSwitcher,
  } = useStores();

  const [drawerSports, setToggleSports] = useState<boolean>(false);

  const toggleSports = () => {
    setToggleSports(!drawerSports);
  };

  useEffect(() => {
    if (selectedSport?.name) {
      /*
      const eventType =
        eventFilter === EventsFilters.UPCOMING
          ? EVENT_TYPE.UPCOMING
          : EVENT_TYPE.LIVE;
      */

      marketSwitcher.updateMobileMarkets(selectedSport.id, EVENT_TYPE.LIVE);
    }
  }, [selectedSport, marketSwitcher]);

  const onSportSelect = (sport: SportNodeService) => {
    setActiveGroup(null);
    setSelectedSport(sport);

    if (sport) {
      toggleSports();
    }
  };

  const renderIcon = useCallback(() => {
    if (
      !selectedSport ||
      !SPORT_ICONS[selectedSport.name.toLowerCase() as SportIcon]
    ) {
      return null;
    }
    const iconName = SPORT_ICONS[
      selectedSport.name.toLowerCase() as SportIcon
    ] as IconName;
    if (!iconName) return;
    return <Icon className="mr-2" name={iconName} />;
  }, [selectedSport]);

  return (
    <MobileFilter
      isDrawerOpen={drawerSports}
      prefix="sports"
      onClick={toggleSports}
      onClose={toggleSports}
      dropdownContent={
        <div>
          <RenderTimeFilters closeDrawer={toggleSports} />
          <RenderRecords
            records={sportsData?.sportsList || []}
            onSelected={onSportSelect}
          />
        </div>
      }
    >
      <div className="d-flex space-between align-items">
        {renderIcon() ? (
          renderIcon()
        ) : (
          <Icon className="mr-2" name="football" />
        )}
        <span className="text-ellipsis">
          {selectedSport?.name?.toUpperCase() || 'FUDBAL'}
        </span>
        <Icon size="extra-small" className="ml-2 mr-1" name="caretDown" />
      </div>
    </MobileFilter>
  );
};

export default observer(SportsFilterMobile);
