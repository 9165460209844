/* React modules */

/* Our modules */
import { UserServiceClient } from 'generated-proto/proto/user/user_pb.client';
import { GetBettingPlacesBoRequest } from 'generated-proto/proto/user/user_pb';
import { getRpcTransport, getApiUrl } from 'libs/urlBuilder';

/* 3rd Party modules */

class BettingPlacesAPI {
  private client: UserServiceClient;

  constructor() {
    this.client = new UserServiceClient(getRpcTransport(getApiUrl()));
  }

  getBettingPlaces() {
    const request: GetBettingPlacesBoRequest = {};

    return this.client.getBettingPlacesBO(request);
  }
}

export { BettingPlacesAPI };
