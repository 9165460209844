// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/common/common.proto" (package "common", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
/**
 * @generated from protobuf message common.SlipGroup
 */
export interface SlipGroup {
    /**
     * @generated from protobuf field: string type = 1;
     */
    type: string;
    /**
     * @generated from protobuf field: repeated common.SlipSubGroup sub_groups = 3;
     */
    subGroups: SlipSubGroup[];
}
/**
 * @generated from protobuf message common.SlipSubGroup
 */
export interface SlipSubGroup {
    /**
     * @generated from protobuf field: string type = 1;
     */
    type: string;
    /**
     * @generated from protobuf field: string event_id = 2;
     */
    eventId: string;
    /**
     * @generated from protobuf field: repeated int32 odds = 8;
     */
    odds: number[];
    /**
     * @generated from protobuf field: repeated double odd_values = 9;
     */
    oddValues: number[];
}
/**
 * @generated from protobuf message common.SlipRequest
 */
export interface SlipRequest {
    /**
     * @generated from protobuf field: double amount = 2;
     */
    amount: number;
    /**
     * @generated from protobuf field: bool credit = 11;
     */
    credit: boolean;
    /**
     * @generated from protobuf field: string creditee = 13;
     */
    creditee: string;
    /**
     * @generated from protobuf field: string creditee_uuid = 14;
     */
    crediteeUuid: string;
    /**
     * @generated from protobuf field: string credit_note = 12;
     */
    creditNote: string;
    /**
     * @generated from protobuf field: repeated common.SlipGroup slip_groups = 4;
     */
    slipGroups: SlipGroup[];
    /**
     * @generated from protobuf field: string approval_reason = 10;
     */
    approvalReason: string;
    /**
     * @generated from protobuf field: string wallet_prefix = 7;
     */
    walletPrefix: string;
    /**
     * @generated from protobuf field: bool no_live_changes = 53;
     */
    noLiveChanges: boolean;
    /**
     * @generated from protobuf field: string ssn = 15;
     */
    ssn: string;
    /**
     * @generated from protobuf field: string passport_number = 16;
     */
    passportNumber: string;
    /**
     * @generated from protobuf field: string identification_document_country = 17;
     */
    identificationDocumentCountry: string;
    /**
     * @generated from protobuf field: string identification_document_type = 18;
     */
    identificationDocumentType: string;
}
/**
 * @generated from protobuf message common.Error
 */
export interface Error {
    /**
     * @generated from protobuf field: int32 grpc_code = 5;
     */
    grpcCode: number;
    /**
     * @generated from protobuf field: int32 http_code = 6;
     */
    httpCode: number;
    /**
     * @generated from protobuf field: repeated string message = 7;
     */
    message: string[];
}
/**
 * @generated from protobuf message common.EmptyError
 */
export interface EmptyError {
    /**
     * @generated from protobuf field: common.Error error = 9;
     */
    error?: Error;
}
/**
 * @generated from protobuf message common.SortBy
 */
export interface SortBy {
    /**
     * @generated from protobuf field: string field_name = 1;
     */
    fieldName: string;
    /**
     * @generated from protobuf field: bool desc = 2;
     */
    desc: boolean;
}
/**
 * @generated from protobuf message common.UserSlipOdd
 */
export interface UserSlipOdd {
    /**
     * @generated from protobuf field: string id = 10;
     */
    id: string;
    /**
     * @generated from protobuf field: double value = 6;
     */
    value: number;
    /**
     * @generated from protobuf field: double old_value = 86;
     */
    oldValue: number;
    /**
     * @generated from protobuf field: int32 outcome_id = 110;
     */
    outcomeId: number;
    /**
     * @generated from protobuf field: string market_name = 7;
     */
    marketName: string;
    /**
     * @generated from protobuf field: string outcome_name = 8;
     */
    outcomeName: string;
    /**
     * @generated from protobuf field: string outcome_tic = 119;
     */
    outcomeTic: string;
    /**
     * @generated from protobuf field: string outcome_group = 100;
     */
    outcomeGroup: string;
    /**
     * @generated from protobuf field: string status = 9;
     */
    status: string;
    /**
     * @generated from protobuf field: double limit = 41;
     */
    limit: number;
    /**
     * @generated from protobuf field: double old_limit = 87;
     */
    oldLimit: number;
    /**
     * @generated from protobuf field: int32 frame_number = 111;
     */
    frameNumber: number;
    /**
     * @generated from protobuf field: string current_result = 112;
     */
    currentResult: string;
    /**
     * @generated from protobuf field: string live_player = 113;
     */
    livePlayer: string;
}
/**
 * @generated from protobuf message common.UserSlipEvent
 */
export interface UserSlipEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: int32 competition_id = 2;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: int32 sport_id = 3;
     */
    sportId: number;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start_time = 5;
     */
    startTime?: Timestamp;
    /**
     * @generated from protobuf field: bool is_live = 6;
     */
    isLive: boolean;
    /**
     * @generated from protobuf field: bool is_suspicious = 7;
     */
    isSuspicious: boolean;
    /**
     * @generated from protobuf field: bool is_single_approval = 8;
     */
    isSingleApproval: boolean;
    /**
     * @generated from protobuf field: string type = 9;
     */
    type: string;
    /**
     * @generated from protobuf field: string landbase_code = 10;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: string note = 11;
     */
    note: string;
    /**
     * @generated from protobuf field: common.EventResult result = 12;
     */
    result?: EventResult;
    /**
     * @generated from protobuf field: common.EventResult place_bet_result = 13;
     */
    placeBetResult?: EventResult;
    /**
     * @generated from protobuf field: int32 pa_sport = 14;
     */
    paSport: number;
    /**
     * @generated from protobuf field: string pa_type = 15;
     */
    paType: string;
    /**
     * @generated from protobuf field: int32 exefeed_id = 16;
     */
    exefeedId: number;
    /**
     * @generated from protobuf field: int32 location_id = 17;
     */
    locationId: number;
    /**
     * @generated from protobuf field: string competition_short_name = 18;
     */
    competitionShortName: string;
    /**
     * @generated from protobuf field: string flag_code = 19;
     */
    flagCode: string;
}
/**
 * @generated from protobuf message common.UserSlipSubGroup
 */
export interface UserSlipSubGroup {
    /**
     * @generated from protobuf field: string type = 15;
     */
    type: string;
    /**
     * @generated from protobuf field: string status = 9;
     */
    status: string;
    /**
     * @generated from protobuf field: common.UserSlipEvent event = 16;
     */
    event?: UserSlipEvent;
    /**
     * @generated from protobuf field: repeated common.UserSlipOdd odds = 19;
     */
    odds: UserSlipOdd[];
}
/**
 * @generated from protobuf message common.UserSlipGroup
 */
export interface UserSlipGroup {
    /**
     * @generated from protobuf field: string type = 20;
     */
    type: string;
    /**
     * @generated from protobuf field: string status = 9;
     */
    status: string;
    /**
     * @generated from protobuf field: repeated common.UserSlipSubGroup sub_groups = 21;
     */
    subGroups: UserSlipSubGroup[];
}
/**
 * @generated from protobuf message common.Slip
 */
export interface Slip {
    /**
     * @generated from protobuf field: string id = 22;
     */
    id: string;
    /**
     * @generated from protobuf field: string short_uuid = 77;
     */
    shortUuid: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 104;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: int32 betting_place_id = 57;
     */
    bettingPlaceId: number;
    /**
     * @generated from protobuf field: string betting_place_name = 70;
     */
    bettingPlaceName: string;
    /**
     * @generated from protobuf field: int32 user_id = 52;
     */
    userId: number;
    /**
     * @generated from protobuf field: string user_first_name = 71;
     */
    userFirstName: string;
    /**
     * @generated from protobuf field: string user_last_name = 72;
     */
    userLastName: string;
    /**
     * @generated from protobuf field: string user_username = 303;
     */
    userUsername: string;
    /**
     * @generated from protobuf field: double betting_amount = 23;
     */
    bettingAmount: number;
    /**
     * @generated from protobuf field: double old_betting_amount = 85;
     */
    oldBettingAmount: number;
    /**
     * @generated from protobuf field: string status = 9;
     */
    status: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp betting_time = 26;
     */
    bettingTime?: Timestamp;
    /**
     * @generated from protobuf field: string account_type = 27;
     */
    accountType: string;
    /**
     * @generated from protobuf field: repeated common.UserSlipGroup slip_groups = 28;
     */
    slipGroups: UserSlipGroup[];
    /**
     * @generated from protobuf field: double betting_amount_per_combination = 29;
     */
    bettingAmountPerCombination: number;
    /**
     * @generated from protobuf field: int32 number_of_combinations = 47;
     */
    numberOfCombinations: number;
    /**
     * @generated from protobuf field: double max_winning_total = 112;
     */
    maxWinningTotal: number;
    /**
     * @generated from protobuf field: double min_winning_total = 113;
     */
    minWinningTotal: number;
    /**
     * @generated from protobuf field: double max_winning_amount = 24;
     */
    maxWinningAmount: number;
    /**
     * @generated from protobuf field: double min_winning_amount = 30;
     */
    minWinningAmount: number;
    /**
     * @generated from protobuf field: double max_bonus_amount = 31;
     */
    maxBonusAmount: number;
    /**
     * @generated from protobuf field: double min_bonus_amount = 44;
     */
    minBonusAmount: number;
    /**
     * @generated from protobuf field: double bonus_percent = 103;
     */
    bonusPercent: number;
    /**
     * @generated from protobuf field: double max_odds_value = 48;
     */
    maxOddsValue: number;
    /**
     * @generated from protobuf field: double min_odds_value = 49;
     */
    minOddsValue: number;
    /**
     * @generated from protobuf field: bool is_system = 39;
     */
    isSystem: boolean;
    /**
     * @generated from protobuf field: string slip_type = 50;
     */
    slipType: string;
    /**
     * @generated from protobuf field: common.Error error = 1;
     */
    error?: Error;
    /**
     * @generated from protobuf field: double actual_winning_amount = 40;
     */
    actualWinningAmount: number;
    /**
     * @generated from protobuf field: double actual_winning_no_bonus = 301;
     */
    actualWinningNoBonus: number;
    /**
     * @generated from protobuf field: bool has_cashout = 56;
     */
    hasCashout: boolean;
    /**
     * @generated from protobuf field: bool cashout = 73;
     */
    cashout: boolean;
    /**
     * @generated from protobuf field: double cashout_estimate = 121;
     */
    cashoutEstimate: number;
    /**
     * @generated from protobuf field: bool cashback = 74;
     */
    cashback: boolean;
    /**
     * @generated from protobuf field: bool has_started_events = 75;
     */
    hasStartedEvents: boolean;
    /**
     * @generated from protobuf field: string approval_reason = 79;
     */
    approvalReason: string;
    /**
     * @generated from protobuf field: int32 approved_by = 88;
     */
    approvedBy: number;
    /**
     * @generated from protobuf field: string approved_by_name = 126;
     */
    approvedByName: string;
    /**
     * @generated from protobuf field: int32 number_of_matches = 99;
     */
    numberOfMatches: number;
    /**
     * @generated from protobuf field: string creditee = 101;
     */
    creditee: string;
    /**
     * @generated from protobuf field: string credit_note = 102;
     */
    creditNote: string;
    /**
     * @generated from protobuf field: string canceled_by = 114;
     */
    canceledBy: string;
    /**
     * @generated from protobuf field: string canceled_by_name = 127;
     */
    canceledByName: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp cancel_time = 115;
     */
    cancelTime?: Timestamp;
    /**
     * @generated from protobuf field: string paid_by = 116;
     */
    paidBy: string;
    /**
     * @generated from protobuf field: string paid_by_name = 128;
     */
    paidByName: string;
    /**
     * @generated from protobuf field: string paid_betting_place_name = 117;
     */
    paidBettingPlaceName: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp paid_time = 118;
     */
    paidTime?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp resolved_time = 129;
     */
    resolvedTime?: Timestamp;
    /**
     * @generated from protobuf field: string wallet_prefix = 130;
     */
    walletPrefix: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp manual_change_time = 300;
     */
    manualChangeTime?: Timestamp;
    /**
     * @generated from protobuf field: bool is_printed = 302;
     */
    isPrinted: boolean;
    /**
     * @generated from protobuf field: bool cashout_paid_out = 304;
     */
    cashoutPaidOut: boolean;
    /**
     * @generated from protobuf field: int32 role_id = 444;
     */
    roleId: number;
}
/**
 * @generated from protobuf message common.EventTime
 */
export interface EventTime {
    /**
     * @generated from protobuf field: string M = 202 [json_name = "M"];
     */
    m: string;
    /**
     * @generated from protobuf field: string S = 203 [json_name = "S"];
     */
    s: string;
}
/**
 * @generated from protobuf message common.EventCurrentPhase
 */
export interface EventCurrentPhase {
    /**
     * @generated from protobuf field: string N = 200 [json_name = "N"];
     */
    n: string;
    /**
     * @generated from protobuf field: string S = 201 [json_name = "S"];
     */
    s: string;
    /**
     * @generated from protobuf field: common.EventTime T = 204 [json_name = "T"];
     */
    t?: EventTime;
}
/**
 * @generated from protobuf message common.EventCurrentResult
 */
export interface EventCurrentResult {
    /**
     * @generated from protobuf field: string G = 205 [json_name = "G"];
     */
    g: string;
    /**
     * @generated from protobuf field: string C = 206 [json_name = "C"];
     */
    c: string;
    /**
     * @generated from protobuf field: string RC = 207 [json_name = "RC"];
     */
    rC: string;
    /**
     * @generated from protobuf field: string YC = 208 [json_name = "YC"];
     */
    yC: string;
    /**
     * @generated from protobuf field: string P = 209 [json_name = "P"];
     */
    p: string;
    /**
     * @generated from protobuf field: string S = 210 [json_name = "S"];
     */
    s: string;
    /**
     * @generated from protobuf field: string L = 267 [json_name = "L"];
     */
    l: string;
    /**
     * @generated from protobuf field: string S180 = 268 [json_name = "S180"];
     */
    s180: string;
    /**
     * @generated from protobuf field: string FG = 269 [json_name = "FG"];
     */
    fG: string;
    /**
     * @generated from protobuf field: string PN = 270 [json_name = "PN"];
     */
    pN: string;
    /**
     * @generated from protobuf field: string TD = 271 [json_name = "TD"];
     */
    tD: string;
    /**
     * @generated from protobuf field: string SC = 272 [json_name = "SC"];
     */
    sC: string;
    /**
     * @generated from protobuf field: string SH = 273 [json_name = "SH"];
     */
    sH: string;
}
/**
 * @generated from protobuf message common.EventLiveResultHistoryElement
 */
export interface EventLiveResultHistoryElement {
    /**
     * @generated from protobuf field: common.EventCurrentPhase P = 211 [json_name = "P"];
     */
    p?: EventCurrentPhase;
    /**
     * @generated from protobuf field: common.EventCurrentResult R = 212 [json_name = "R"];
     */
    r?: EventCurrentResult;
}
/**
 * @generated from protobuf message common.EventResult
 */
export interface EventResult {
    /**
     * @generated from protobuf field: common.EventCurrentPhase current_phase = 213;
     */
    currentPhase?: EventCurrentPhase;
    /**
     * @generated from protobuf field: common.EventCurrentResult current_result = 214;
     */
    currentResult?: EventCurrentResult;
    /**
     * @generated from protobuf field: repeated common.EventLiveResultHistoryElement live_result_history = 215;
     */
    liveResultHistory: EventLiveResultHistoryElement[];
    /**
     * @generated from protobuf field: string live_result_stats = 216;
     */
    liveResultStats: string;
    /**
     * @generated from protobuf field: string prematch_result = 217;
     */
    prematchResult: string;
    /**
     * @generated from protobuf field: string live_result = 218;
     */
    liveResult: string;
    /**
     * @generated from protobuf field: bool confirmed = 219;
     */
    confirmed: boolean;
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message common.EventResultPeriod
 */
export interface EventResultPeriod {
    /**
     * @generated from protobuf field: string points = 141;
     */
    points: string;
    /**
     * @generated from protobuf field: string games = 142;
     */
    games: string;
    /**
     * @generated from protobuf field: string sets = 143;
     */
    sets: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message common.FastOdd
 */
export interface FastOdd {
    /**
     * @generated from protobuf field: int32 odd_id = 1;
     */
    oddId: number;
    /**
     * @generated from protobuf field: string event_id = 2;
     */
    eventId: string;
}
/**
 * @generated from protobuf message common.FastSlipGroup
 */
export interface FastSlipGroup {
    /**
     * @generated from protobuf field: string type = 1;
     */
    type: string;
    /**
     * @generated from protobuf field: repeated common.FastSlipSubGroup sub_groups = 2;
     */
    subGroups: FastSlipSubGroup[];
}
/**
 * @generated from protobuf message common.FastSlipSubGroup
 */
export interface FastSlipSubGroup {
    /**
     * @generated from protobuf field: string type = 1;
     */
    type: string;
    /**
     * @generated from protobuf field: repeated common.FastOdd odds = 2;
     */
    odds: FastOdd[];
}
/**
 * @generated from protobuf message common.FastSlipRequest
 */
export interface FastSlipRequest {
    /**
     * @generated from protobuf field: double amount = 1;
     */
    amount: number;
    /**
     * @generated from protobuf field: repeated common.FastSlipGroup slip_groups = 2;
     */
    slipGroups: FastSlipGroup[];
}
// @generated message type with reflection information, may provide speed optimized methods
class SlipGroup$Type extends MessageType<SlipGroup> {
    constructor() {
        super("common.SlipGroup", [
            { no: 1, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "sub_groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SlipSubGroup }
        ]);
    }
    create(value?: PartialMessage<SlipGroup>): SlipGroup {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = "";
        message.subGroups = [];
        if (value !== undefined)
            reflectionMergePartial<SlipGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SlipGroup): SlipGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 1:
                    message.type = reader.string();
                    break;
                case /* repeated common.SlipSubGroup sub_groups */ 3:
                    message.subGroups.push(SlipSubGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SlipGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 1; */
        if (message.type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.type);
        /* repeated common.SlipSubGroup sub_groups = 3; */
        for (let i = 0; i < message.subGroups.length; i++)
            SlipSubGroup.internalBinaryWrite(message.subGroups[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.SlipGroup
 */
export const SlipGroup = new SlipGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SlipSubGroup$Type extends MessageType<SlipSubGroup> {
    constructor() {
        super("common.SlipSubGroup", [
            { no: 1, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "odds", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "odd_values", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<SlipSubGroup>): SlipSubGroup {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = "";
        message.eventId = "";
        message.odds = [];
        message.oddValues = [];
        if (value !== undefined)
            reflectionMergePartial<SlipSubGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SlipSubGroup): SlipSubGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 1:
                    message.type = reader.string();
                    break;
                case /* string event_id */ 2:
                    message.eventId = reader.string();
                    break;
                case /* repeated int32 odds */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.odds.push(reader.int32());
                    else
                        message.odds.push(reader.int32());
                    break;
                case /* repeated double odd_values */ 9:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.oddValues.push(reader.double());
                    else
                        message.oddValues.push(reader.double());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SlipSubGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 1; */
        if (message.type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.type);
        /* string event_id = 2; */
        if (message.eventId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.eventId);
        /* repeated int32 odds = 8; */
        if (message.odds.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.odds.length; i++)
                writer.int32(message.odds[i]);
            writer.join();
        }
        /* repeated double odd_values = 9; */
        if (message.oddValues.length) {
            writer.tag(9, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.oddValues.length; i++)
                writer.double(message.oddValues[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.SlipSubGroup
 */
export const SlipSubGroup = new SlipSubGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SlipRequest$Type extends MessageType<SlipRequest> {
    constructor() {
        super("common.SlipRequest", [
            { no: 2, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 11, name: "credit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "creditee", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "creditee_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "credit_note", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "slip_groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SlipGroup },
            { no: 10, name: "approval_reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "wallet_prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 53, name: "no_live_changes", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "ssn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "passport_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "identification_document_country", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "identification_document_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SlipRequest>): SlipRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.amount = 0;
        message.credit = false;
        message.creditee = "";
        message.crediteeUuid = "";
        message.creditNote = "";
        message.slipGroups = [];
        message.approvalReason = "";
        message.walletPrefix = "";
        message.noLiveChanges = false;
        message.ssn = "";
        message.passportNumber = "";
        message.identificationDocumentCountry = "";
        message.identificationDocumentType = "";
        if (value !== undefined)
            reflectionMergePartial<SlipRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SlipRequest): SlipRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double amount */ 2:
                    message.amount = reader.double();
                    break;
                case /* bool credit */ 11:
                    message.credit = reader.bool();
                    break;
                case /* string creditee */ 13:
                    message.creditee = reader.string();
                    break;
                case /* string creditee_uuid */ 14:
                    message.crediteeUuid = reader.string();
                    break;
                case /* string credit_note */ 12:
                    message.creditNote = reader.string();
                    break;
                case /* repeated common.SlipGroup slip_groups */ 4:
                    message.slipGroups.push(SlipGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string approval_reason */ 10:
                    message.approvalReason = reader.string();
                    break;
                case /* string wallet_prefix */ 7:
                    message.walletPrefix = reader.string();
                    break;
                case /* bool no_live_changes */ 53:
                    message.noLiveChanges = reader.bool();
                    break;
                case /* string ssn */ 15:
                    message.ssn = reader.string();
                    break;
                case /* string passport_number */ 16:
                    message.passportNumber = reader.string();
                    break;
                case /* string identification_document_country */ 17:
                    message.identificationDocumentCountry = reader.string();
                    break;
                case /* string identification_document_type */ 18:
                    message.identificationDocumentType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SlipRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double amount = 2; */
        if (message.amount !== 0)
            writer.tag(2, WireType.Bit64).double(message.amount);
        /* bool credit = 11; */
        if (message.credit !== false)
            writer.tag(11, WireType.Varint).bool(message.credit);
        /* string creditee = 13; */
        if (message.creditee !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.creditee);
        /* string creditee_uuid = 14; */
        if (message.crediteeUuid !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.crediteeUuid);
        /* string credit_note = 12; */
        if (message.creditNote !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.creditNote);
        /* repeated common.SlipGroup slip_groups = 4; */
        for (let i = 0; i < message.slipGroups.length; i++)
            SlipGroup.internalBinaryWrite(message.slipGroups[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string approval_reason = 10; */
        if (message.approvalReason !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.approvalReason);
        /* string wallet_prefix = 7; */
        if (message.walletPrefix !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.walletPrefix);
        /* bool no_live_changes = 53; */
        if (message.noLiveChanges !== false)
            writer.tag(53, WireType.Varint).bool(message.noLiveChanges);
        /* string ssn = 15; */
        if (message.ssn !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.ssn);
        /* string passport_number = 16; */
        if (message.passportNumber !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.passportNumber);
        /* string identification_document_country = 17; */
        if (message.identificationDocumentCountry !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.identificationDocumentCountry);
        /* string identification_document_type = 18; */
        if (message.identificationDocumentType !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.identificationDocumentType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.SlipRequest
 */
export const SlipRequest = new SlipRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Error$Type extends MessageType<Error> {
    constructor() {
        super("common.Error", [
            { no: 5, name: "grpc_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "http_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "message", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Error>): Error {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.grpcCode = 0;
        message.httpCode = 0;
        message.message = [];
        if (value !== undefined)
            reflectionMergePartial<Error>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Error): Error {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 grpc_code */ 5:
                    message.grpcCode = reader.int32();
                    break;
                case /* int32 http_code */ 6:
                    message.httpCode = reader.int32();
                    break;
                case /* repeated string message */ 7:
                    message.message.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Error, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 grpc_code = 5; */
        if (message.grpcCode !== 0)
            writer.tag(5, WireType.Varint).int32(message.grpcCode);
        /* int32 http_code = 6; */
        if (message.httpCode !== 0)
            writer.tag(6, WireType.Varint).int32(message.httpCode);
        /* repeated string message = 7; */
        for (let i = 0; i < message.message.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.message[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.Error
 */
export const Error = new Error$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmptyError$Type extends MessageType<EmptyError> {
    constructor() {
        super("common.EmptyError", [
            { no: 9, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<EmptyError>): EmptyError {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EmptyError>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmptyError): EmptyError {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* common.Error error */ 9:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EmptyError, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* common.Error error = 9; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.EmptyError
 */
export const EmptyError = new EmptyError$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SortBy$Type extends MessageType<SortBy> {
    constructor() {
        super("common.SortBy", [
            { no: 1, name: "field_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "desc", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SortBy>): SortBy {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.fieldName = "";
        message.desc = false;
        if (value !== undefined)
            reflectionMergePartial<SortBy>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SortBy): SortBy {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string field_name */ 1:
                    message.fieldName = reader.string();
                    break;
                case /* bool desc */ 2:
                    message.desc = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SortBy, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string field_name = 1; */
        if (message.fieldName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.fieldName);
        /* bool desc = 2; */
        if (message.desc !== false)
            writer.tag(2, WireType.Varint).bool(message.desc);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.SortBy
 */
export const SortBy = new SortBy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserSlipOdd$Type extends MessageType<UserSlipOdd> {
    constructor() {
        super("common.UserSlipOdd", [
            { no: 10, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 86, name: "old_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 110, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "market_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "outcome_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 119, name: "outcome_tic", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 100, name: "outcome_group", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 41, name: "limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 87, name: "old_limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 111, name: "frame_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 112, name: "current_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 113, name: "live_player", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserSlipOdd>): UserSlipOdd {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.value = 0;
        message.oldValue = 0;
        message.outcomeId = 0;
        message.marketName = "";
        message.outcomeName = "";
        message.outcomeTic = "";
        message.outcomeGroup = "";
        message.status = "";
        message.limit = 0;
        message.oldLimit = 0;
        message.frameNumber = 0;
        message.currentResult = "";
        message.livePlayer = "";
        if (value !== undefined)
            reflectionMergePartial<UserSlipOdd>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserSlipOdd): UserSlipOdd {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 10:
                    message.id = reader.string();
                    break;
                case /* double value */ 6:
                    message.value = reader.double();
                    break;
                case /* double old_value */ 86:
                    message.oldValue = reader.double();
                    break;
                case /* int32 outcome_id */ 110:
                    message.outcomeId = reader.int32();
                    break;
                case /* string market_name */ 7:
                    message.marketName = reader.string();
                    break;
                case /* string outcome_name */ 8:
                    message.outcomeName = reader.string();
                    break;
                case /* string outcome_tic */ 119:
                    message.outcomeTic = reader.string();
                    break;
                case /* string outcome_group */ 100:
                    message.outcomeGroup = reader.string();
                    break;
                case /* string status */ 9:
                    message.status = reader.string();
                    break;
                case /* double limit */ 41:
                    message.limit = reader.double();
                    break;
                case /* double old_limit */ 87:
                    message.oldLimit = reader.double();
                    break;
                case /* int32 frame_number */ 111:
                    message.frameNumber = reader.int32();
                    break;
                case /* string current_result */ 112:
                    message.currentResult = reader.string();
                    break;
                case /* string live_player */ 113:
                    message.livePlayer = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserSlipOdd, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 10; */
        if (message.id !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.id);
        /* double value = 6; */
        if (message.value !== 0)
            writer.tag(6, WireType.Bit64).double(message.value);
        /* double old_value = 86; */
        if (message.oldValue !== 0)
            writer.tag(86, WireType.Bit64).double(message.oldValue);
        /* int32 outcome_id = 110; */
        if (message.outcomeId !== 0)
            writer.tag(110, WireType.Varint).int32(message.outcomeId);
        /* string market_name = 7; */
        if (message.marketName !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.marketName);
        /* string outcome_name = 8; */
        if (message.outcomeName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.outcomeName);
        /* string outcome_tic = 119; */
        if (message.outcomeTic !== "")
            writer.tag(119, WireType.LengthDelimited).string(message.outcomeTic);
        /* string outcome_group = 100; */
        if (message.outcomeGroup !== "")
            writer.tag(100, WireType.LengthDelimited).string(message.outcomeGroup);
        /* string status = 9; */
        if (message.status !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.status);
        /* double limit = 41; */
        if (message.limit !== 0)
            writer.tag(41, WireType.Bit64).double(message.limit);
        /* double old_limit = 87; */
        if (message.oldLimit !== 0)
            writer.tag(87, WireType.Bit64).double(message.oldLimit);
        /* int32 frame_number = 111; */
        if (message.frameNumber !== 0)
            writer.tag(111, WireType.Varint).int32(message.frameNumber);
        /* string current_result = 112; */
        if (message.currentResult !== "")
            writer.tag(112, WireType.LengthDelimited).string(message.currentResult);
        /* string live_player = 113; */
        if (message.livePlayer !== "")
            writer.tag(113, WireType.LengthDelimited).string(message.livePlayer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.UserSlipOdd
 */
export const UserSlipOdd = new UserSlipOdd$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserSlipEvent$Type extends MessageType<UserSlipEvent> {
    constructor() {
        super("common.UserSlipEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "start_time", kind: "message", T: () => Timestamp },
            { no: 6, name: "is_live", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "is_suspicious", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "is_single_approval", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "note", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "result", kind: "message", T: () => EventResult },
            { no: 13, name: "place_bet_result", kind: "message", T: () => EventResult },
            { no: 14, name: "pa_sport", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "pa_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "exefeed_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "competition_short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "flag_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserSlipEvent>): UserSlipEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.competitionId = 0;
        message.sportId = 0;
        message.name = "";
        message.isLive = false;
        message.isSuspicious = false;
        message.isSingleApproval = false;
        message.type = "";
        message.landbaseCode = "";
        message.note = "";
        message.paSport = 0;
        message.paType = "";
        message.exefeedId = 0;
        message.locationId = 0;
        message.competitionShortName = "";
        message.flagCode = "";
        if (value !== undefined)
            reflectionMergePartial<UserSlipEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserSlipEvent): UserSlipEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* int32 competition_id */ 2:
                    message.competitionId = reader.int32();
                    break;
                case /* int32 sport_id */ 3:
                    message.sportId = reader.int32();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* google.protobuf.Timestamp start_time */ 5:
                    message.startTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.startTime);
                    break;
                case /* bool is_live */ 6:
                    message.isLive = reader.bool();
                    break;
                case /* bool is_suspicious */ 7:
                    message.isSuspicious = reader.bool();
                    break;
                case /* bool is_single_approval */ 8:
                    message.isSingleApproval = reader.bool();
                    break;
                case /* string type */ 9:
                    message.type = reader.string();
                    break;
                case /* string landbase_code */ 10:
                    message.landbaseCode = reader.string();
                    break;
                case /* string note */ 11:
                    message.note = reader.string();
                    break;
                case /* common.EventResult result */ 12:
                    message.result = EventResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                case /* common.EventResult place_bet_result */ 13:
                    message.placeBetResult = EventResult.internalBinaryRead(reader, reader.uint32(), options, message.placeBetResult);
                    break;
                case /* int32 pa_sport */ 14:
                    message.paSport = reader.int32();
                    break;
                case /* string pa_type */ 15:
                    message.paType = reader.string();
                    break;
                case /* int32 exefeed_id */ 16:
                    message.exefeedId = reader.int32();
                    break;
                case /* int32 location_id */ 17:
                    message.locationId = reader.int32();
                    break;
                case /* string competition_short_name */ 18:
                    message.competitionShortName = reader.string();
                    break;
                case /* string flag_code */ 19:
                    message.flagCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserSlipEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int32 competition_id = 2; */
        if (message.competitionId !== 0)
            writer.tag(2, WireType.Varint).int32(message.competitionId);
        /* int32 sport_id = 3; */
        if (message.sportId !== 0)
            writer.tag(3, WireType.Varint).int32(message.sportId);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* google.protobuf.Timestamp start_time = 5; */
        if (message.startTime)
            Timestamp.internalBinaryWrite(message.startTime, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bool is_live = 6; */
        if (message.isLive !== false)
            writer.tag(6, WireType.Varint).bool(message.isLive);
        /* bool is_suspicious = 7; */
        if (message.isSuspicious !== false)
            writer.tag(7, WireType.Varint).bool(message.isSuspicious);
        /* bool is_single_approval = 8; */
        if (message.isSingleApproval !== false)
            writer.tag(8, WireType.Varint).bool(message.isSingleApproval);
        /* string type = 9; */
        if (message.type !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.type);
        /* string landbase_code = 10; */
        if (message.landbaseCode !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.landbaseCode);
        /* string note = 11; */
        if (message.note !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.note);
        /* common.EventResult result = 12; */
        if (message.result)
            EventResult.internalBinaryWrite(message.result, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* common.EventResult place_bet_result = 13; */
        if (message.placeBetResult)
            EventResult.internalBinaryWrite(message.placeBetResult, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* int32 pa_sport = 14; */
        if (message.paSport !== 0)
            writer.tag(14, WireType.Varint).int32(message.paSport);
        /* string pa_type = 15; */
        if (message.paType !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.paType);
        /* int32 exefeed_id = 16; */
        if (message.exefeedId !== 0)
            writer.tag(16, WireType.Varint).int32(message.exefeedId);
        /* int32 location_id = 17; */
        if (message.locationId !== 0)
            writer.tag(17, WireType.Varint).int32(message.locationId);
        /* string competition_short_name = 18; */
        if (message.competitionShortName !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.competitionShortName);
        /* string flag_code = 19; */
        if (message.flagCode !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.flagCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.UserSlipEvent
 */
export const UserSlipEvent = new UserSlipEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserSlipSubGroup$Type extends MessageType<UserSlipSubGroup> {
    constructor() {
        super("common.UserSlipSubGroup", [
            { no: 15, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "event", kind: "message", T: () => UserSlipEvent },
            { no: 19, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserSlipOdd }
        ]);
    }
    create(value?: PartialMessage<UserSlipSubGroup>): UserSlipSubGroup {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = "";
        message.status = "";
        message.odds = [];
        if (value !== undefined)
            reflectionMergePartial<UserSlipSubGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserSlipSubGroup): UserSlipSubGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 15:
                    message.type = reader.string();
                    break;
                case /* string status */ 9:
                    message.status = reader.string();
                    break;
                case /* common.UserSlipEvent event */ 16:
                    message.event = UserSlipEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                case /* repeated common.UserSlipOdd odds */ 19:
                    message.odds.push(UserSlipOdd.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserSlipSubGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 15; */
        if (message.type !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.type);
        /* string status = 9; */
        if (message.status !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.status);
        /* common.UserSlipEvent event = 16; */
        if (message.event)
            UserSlipEvent.internalBinaryWrite(message.event, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* repeated common.UserSlipOdd odds = 19; */
        for (let i = 0; i < message.odds.length; i++)
            UserSlipOdd.internalBinaryWrite(message.odds[i], writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.UserSlipSubGroup
 */
export const UserSlipSubGroup = new UserSlipSubGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserSlipGroup$Type extends MessageType<UserSlipGroup> {
    constructor() {
        super("common.UserSlipGroup", [
            { no: 20, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "sub_groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserSlipSubGroup }
        ]);
    }
    create(value?: PartialMessage<UserSlipGroup>): UserSlipGroup {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = "";
        message.status = "";
        message.subGroups = [];
        if (value !== undefined)
            reflectionMergePartial<UserSlipGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserSlipGroup): UserSlipGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 20:
                    message.type = reader.string();
                    break;
                case /* string status */ 9:
                    message.status = reader.string();
                    break;
                case /* repeated common.UserSlipSubGroup sub_groups */ 21:
                    message.subGroups.push(UserSlipSubGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserSlipGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 20; */
        if (message.type !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.type);
        /* string status = 9; */
        if (message.status !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.status);
        /* repeated common.UserSlipSubGroup sub_groups = 21; */
        for (let i = 0; i < message.subGroups.length; i++)
            UserSlipSubGroup.internalBinaryWrite(message.subGroups[i], writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.UserSlipGroup
 */
export const UserSlipGroup = new UserSlipGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Slip$Type extends MessageType<Slip> {
    constructor() {
        super("common.Slip", [
            { no: 22, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 77, name: "short_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 104, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 57, name: "betting_place_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 70, name: "betting_place_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 52, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 71, name: "user_first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 72, name: "user_last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 303, name: "user_username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "betting_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 85, name: "old_betting_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "betting_time", kind: "message", T: () => Timestamp },
            { no: 27, name: "account_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "slip_groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserSlipGroup },
            { no: 29, name: "betting_amount_per_combination", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 47, name: "number_of_combinations", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 112, name: "max_winning_total", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 113, name: "min_winning_total", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 24, name: "max_winning_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 30, name: "min_winning_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 31, name: "max_bonus_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 44, name: "min_bonus_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 103, name: "bonus_percent", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 48, name: "max_odds_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 49, name: "min_odds_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 39, name: "is_system", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 50, name: "slip_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 1, name: "error", kind: "message", T: () => Error },
            { no: 40, name: "actual_winning_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 301, name: "actual_winning_no_bonus", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 56, name: "has_cashout", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 73, name: "cashout", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 121, name: "cashout_estimate", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 74, name: "cashback", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 75, name: "has_started_events", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 79, name: "approval_reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 88, name: "approved_by", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 126, name: "approved_by_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 99, name: "number_of_matches", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 101, name: "creditee", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 102, name: "credit_note", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 114, name: "canceled_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 127, name: "canceled_by_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 115, name: "cancel_time", kind: "message", T: () => Timestamp },
            { no: 116, name: "paid_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 128, name: "paid_by_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 117, name: "paid_betting_place_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 118, name: "paid_time", kind: "message", T: () => Timestamp },
            { no: 129, name: "resolved_time", kind: "message", T: () => Timestamp },
            { no: 130, name: "wallet_prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 300, name: "manual_change_time", kind: "message", T: () => Timestamp },
            { no: 302, name: "is_printed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 304, name: "cashout_paid_out", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 444, name: "role_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Slip>): Slip {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.shortUuid = "";
        message.bettingPlaceId = 0;
        message.bettingPlaceName = "";
        message.userId = 0;
        message.userFirstName = "";
        message.userLastName = "";
        message.userUsername = "";
        message.bettingAmount = 0;
        message.oldBettingAmount = 0;
        message.status = "";
        message.accountType = "";
        message.slipGroups = [];
        message.bettingAmountPerCombination = 0;
        message.numberOfCombinations = 0;
        message.maxWinningTotal = 0;
        message.minWinningTotal = 0;
        message.maxWinningAmount = 0;
        message.minWinningAmount = 0;
        message.maxBonusAmount = 0;
        message.minBonusAmount = 0;
        message.bonusPercent = 0;
        message.maxOddsValue = 0;
        message.minOddsValue = 0;
        message.isSystem = false;
        message.slipType = "";
        message.actualWinningAmount = 0;
        message.actualWinningNoBonus = 0;
        message.hasCashout = false;
        message.cashout = false;
        message.cashoutEstimate = 0;
        message.cashback = false;
        message.hasStartedEvents = false;
        message.approvalReason = "";
        message.approvedBy = 0;
        message.approvedByName = "";
        message.numberOfMatches = 0;
        message.creditee = "";
        message.creditNote = "";
        message.canceledBy = "";
        message.canceledByName = "";
        message.paidBy = "";
        message.paidByName = "";
        message.paidBettingPlaceName = "";
        message.walletPrefix = "";
        message.isPrinted = false;
        message.cashoutPaidOut = false;
        message.roleId = 0;
        if (value !== undefined)
            reflectionMergePartial<Slip>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Slip): Slip {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 22:
                    message.id = reader.string();
                    break;
                case /* string short_uuid */ 77:
                    message.shortUuid = reader.string();
                    break;
                case /* google.protobuf.Timestamp updated_at */ 104:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* int32 betting_place_id */ 57:
                    message.bettingPlaceId = reader.int32();
                    break;
                case /* string betting_place_name */ 70:
                    message.bettingPlaceName = reader.string();
                    break;
                case /* int32 user_id */ 52:
                    message.userId = reader.int32();
                    break;
                case /* string user_first_name */ 71:
                    message.userFirstName = reader.string();
                    break;
                case /* string user_last_name */ 72:
                    message.userLastName = reader.string();
                    break;
                case /* string user_username */ 303:
                    message.userUsername = reader.string();
                    break;
                case /* double betting_amount */ 23:
                    message.bettingAmount = reader.double();
                    break;
                case /* double old_betting_amount */ 85:
                    message.oldBettingAmount = reader.double();
                    break;
                case /* string status */ 9:
                    message.status = reader.string();
                    break;
                case /* google.protobuf.Timestamp betting_time */ 26:
                    message.bettingTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.bettingTime);
                    break;
                case /* string account_type */ 27:
                    message.accountType = reader.string();
                    break;
                case /* repeated common.UserSlipGroup slip_groups */ 28:
                    message.slipGroups.push(UserSlipGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* double betting_amount_per_combination */ 29:
                    message.bettingAmountPerCombination = reader.double();
                    break;
                case /* int32 number_of_combinations */ 47:
                    message.numberOfCombinations = reader.int32();
                    break;
                case /* double max_winning_total */ 112:
                    message.maxWinningTotal = reader.double();
                    break;
                case /* double min_winning_total */ 113:
                    message.minWinningTotal = reader.double();
                    break;
                case /* double max_winning_amount */ 24:
                    message.maxWinningAmount = reader.double();
                    break;
                case /* double min_winning_amount */ 30:
                    message.minWinningAmount = reader.double();
                    break;
                case /* double max_bonus_amount */ 31:
                    message.maxBonusAmount = reader.double();
                    break;
                case /* double min_bonus_amount */ 44:
                    message.minBonusAmount = reader.double();
                    break;
                case /* double bonus_percent */ 103:
                    message.bonusPercent = reader.double();
                    break;
                case /* double max_odds_value */ 48:
                    message.maxOddsValue = reader.double();
                    break;
                case /* double min_odds_value */ 49:
                    message.minOddsValue = reader.double();
                    break;
                case /* bool is_system */ 39:
                    message.isSystem = reader.bool();
                    break;
                case /* string slip_type */ 50:
                    message.slipType = reader.string();
                    break;
                case /* common.Error error */ 1:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                case /* double actual_winning_amount */ 40:
                    message.actualWinningAmount = reader.double();
                    break;
                case /* double actual_winning_no_bonus */ 301:
                    message.actualWinningNoBonus = reader.double();
                    break;
                case /* bool has_cashout */ 56:
                    message.hasCashout = reader.bool();
                    break;
                case /* bool cashout */ 73:
                    message.cashout = reader.bool();
                    break;
                case /* double cashout_estimate */ 121:
                    message.cashoutEstimate = reader.double();
                    break;
                case /* bool cashback */ 74:
                    message.cashback = reader.bool();
                    break;
                case /* bool has_started_events */ 75:
                    message.hasStartedEvents = reader.bool();
                    break;
                case /* string approval_reason */ 79:
                    message.approvalReason = reader.string();
                    break;
                case /* int32 approved_by */ 88:
                    message.approvedBy = reader.int32();
                    break;
                case /* string approved_by_name */ 126:
                    message.approvedByName = reader.string();
                    break;
                case /* int32 number_of_matches */ 99:
                    message.numberOfMatches = reader.int32();
                    break;
                case /* string creditee */ 101:
                    message.creditee = reader.string();
                    break;
                case /* string credit_note */ 102:
                    message.creditNote = reader.string();
                    break;
                case /* string canceled_by */ 114:
                    message.canceledBy = reader.string();
                    break;
                case /* string canceled_by_name */ 127:
                    message.canceledByName = reader.string();
                    break;
                case /* google.protobuf.Timestamp cancel_time */ 115:
                    message.cancelTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.cancelTime);
                    break;
                case /* string paid_by */ 116:
                    message.paidBy = reader.string();
                    break;
                case /* string paid_by_name */ 128:
                    message.paidByName = reader.string();
                    break;
                case /* string paid_betting_place_name */ 117:
                    message.paidBettingPlaceName = reader.string();
                    break;
                case /* google.protobuf.Timestamp paid_time */ 118:
                    message.paidTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.paidTime);
                    break;
                case /* google.protobuf.Timestamp resolved_time */ 129:
                    message.resolvedTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.resolvedTime);
                    break;
                case /* string wallet_prefix */ 130:
                    message.walletPrefix = reader.string();
                    break;
                case /* google.protobuf.Timestamp manual_change_time */ 300:
                    message.manualChangeTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.manualChangeTime);
                    break;
                case /* bool is_printed */ 302:
                    message.isPrinted = reader.bool();
                    break;
                case /* bool cashout_paid_out */ 304:
                    message.cashoutPaidOut = reader.bool();
                    break;
                case /* int32 role_id */ 444:
                    message.roleId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Slip, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 22; */
        if (message.id !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.id);
        /* string short_uuid = 77; */
        if (message.shortUuid !== "")
            writer.tag(77, WireType.LengthDelimited).string(message.shortUuid);
        /* google.protobuf.Timestamp updated_at = 104; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(104, WireType.LengthDelimited).fork(), options).join();
        /* int32 betting_place_id = 57; */
        if (message.bettingPlaceId !== 0)
            writer.tag(57, WireType.Varint).int32(message.bettingPlaceId);
        /* string betting_place_name = 70; */
        if (message.bettingPlaceName !== "")
            writer.tag(70, WireType.LengthDelimited).string(message.bettingPlaceName);
        /* int32 user_id = 52; */
        if (message.userId !== 0)
            writer.tag(52, WireType.Varint).int32(message.userId);
        /* string user_first_name = 71; */
        if (message.userFirstName !== "")
            writer.tag(71, WireType.LengthDelimited).string(message.userFirstName);
        /* string user_last_name = 72; */
        if (message.userLastName !== "")
            writer.tag(72, WireType.LengthDelimited).string(message.userLastName);
        /* string user_username = 303; */
        if (message.userUsername !== "")
            writer.tag(303, WireType.LengthDelimited).string(message.userUsername);
        /* double betting_amount = 23; */
        if (message.bettingAmount !== 0)
            writer.tag(23, WireType.Bit64).double(message.bettingAmount);
        /* double old_betting_amount = 85; */
        if (message.oldBettingAmount !== 0)
            writer.tag(85, WireType.Bit64).double(message.oldBettingAmount);
        /* string status = 9; */
        if (message.status !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.status);
        /* google.protobuf.Timestamp betting_time = 26; */
        if (message.bettingTime)
            Timestamp.internalBinaryWrite(message.bettingTime, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* string account_type = 27; */
        if (message.accountType !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.accountType);
        /* repeated common.UserSlipGroup slip_groups = 28; */
        for (let i = 0; i < message.slipGroups.length; i++)
            UserSlipGroup.internalBinaryWrite(message.slipGroups[i], writer.tag(28, WireType.LengthDelimited).fork(), options).join();
        /* double betting_amount_per_combination = 29; */
        if (message.bettingAmountPerCombination !== 0)
            writer.tag(29, WireType.Bit64).double(message.bettingAmountPerCombination);
        /* int32 number_of_combinations = 47; */
        if (message.numberOfCombinations !== 0)
            writer.tag(47, WireType.Varint).int32(message.numberOfCombinations);
        /* double max_winning_total = 112; */
        if (message.maxWinningTotal !== 0)
            writer.tag(112, WireType.Bit64).double(message.maxWinningTotal);
        /* double min_winning_total = 113; */
        if (message.minWinningTotal !== 0)
            writer.tag(113, WireType.Bit64).double(message.minWinningTotal);
        /* double max_winning_amount = 24; */
        if (message.maxWinningAmount !== 0)
            writer.tag(24, WireType.Bit64).double(message.maxWinningAmount);
        /* double min_winning_amount = 30; */
        if (message.minWinningAmount !== 0)
            writer.tag(30, WireType.Bit64).double(message.minWinningAmount);
        /* double max_bonus_amount = 31; */
        if (message.maxBonusAmount !== 0)
            writer.tag(31, WireType.Bit64).double(message.maxBonusAmount);
        /* double min_bonus_amount = 44; */
        if (message.minBonusAmount !== 0)
            writer.tag(44, WireType.Bit64).double(message.minBonusAmount);
        /* double bonus_percent = 103; */
        if (message.bonusPercent !== 0)
            writer.tag(103, WireType.Bit64).double(message.bonusPercent);
        /* double max_odds_value = 48; */
        if (message.maxOddsValue !== 0)
            writer.tag(48, WireType.Bit64).double(message.maxOddsValue);
        /* double min_odds_value = 49; */
        if (message.minOddsValue !== 0)
            writer.tag(49, WireType.Bit64).double(message.minOddsValue);
        /* bool is_system = 39; */
        if (message.isSystem !== false)
            writer.tag(39, WireType.Varint).bool(message.isSystem);
        /* string slip_type = 50; */
        if (message.slipType !== "")
            writer.tag(50, WireType.LengthDelimited).string(message.slipType);
        /* common.Error error = 1; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* double actual_winning_amount = 40; */
        if (message.actualWinningAmount !== 0)
            writer.tag(40, WireType.Bit64).double(message.actualWinningAmount);
        /* double actual_winning_no_bonus = 301; */
        if (message.actualWinningNoBonus !== 0)
            writer.tag(301, WireType.Bit64).double(message.actualWinningNoBonus);
        /* bool has_cashout = 56; */
        if (message.hasCashout !== false)
            writer.tag(56, WireType.Varint).bool(message.hasCashout);
        /* bool cashout = 73; */
        if (message.cashout !== false)
            writer.tag(73, WireType.Varint).bool(message.cashout);
        /* double cashout_estimate = 121; */
        if (message.cashoutEstimate !== 0)
            writer.tag(121, WireType.Bit64).double(message.cashoutEstimate);
        /* bool cashback = 74; */
        if (message.cashback !== false)
            writer.tag(74, WireType.Varint).bool(message.cashback);
        /* bool has_started_events = 75; */
        if (message.hasStartedEvents !== false)
            writer.tag(75, WireType.Varint).bool(message.hasStartedEvents);
        /* string approval_reason = 79; */
        if (message.approvalReason !== "")
            writer.tag(79, WireType.LengthDelimited).string(message.approvalReason);
        /* int32 approved_by = 88; */
        if (message.approvedBy !== 0)
            writer.tag(88, WireType.Varint).int32(message.approvedBy);
        /* string approved_by_name = 126; */
        if (message.approvedByName !== "")
            writer.tag(126, WireType.LengthDelimited).string(message.approvedByName);
        /* int32 number_of_matches = 99; */
        if (message.numberOfMatches !== 0)
            writer.tag(99, WireType.Varint).int32(message.numberOfMatches);
        /* string creditee = 101; */
        if (message.creditee !== "")
            writer.tag(101, WireType.LengthDelimited).string(message.creditee);
        /* string credit_note = 102; */
        if (message.creditNote !== "")
            writer.tag(102, WireType.LengthDelimited).string(message.creditNote);
        /* string canceled_by = 114; */
        if (message.canceledBy !== "")
            writer.tag(114, WireType.LengthDelimited).string(message.canceledBy);
        /* string canceled_by_name = 127; */
        if (message.canceledByName !== "")
            writer.tag(127, WireType.LengthDelimited).string(message.canceledByName);
        /* google.protobuf.Timestamp cancel_time = 115; */
        if (message.cancelTime)
            Timestamp.internalBinaryWrite(message.cancelTime, writer.tag(115, WireType.LengthDelimited).fork(), options).join();
        /* string paid_by = 116; */
        if (message.paidBy !== "")
            writer.tag(116, WireType.LengthDelimited).string(message.paidBy);
        /* string paid_by_name = 128; */
        if (message.paidByName !== "")
            writer.tag(128, WireType.LengthDelimited).string(message.paidByName);
        /* string paid_betting_place_name = 117; */
        if (message.paidBettingPlaceName !== "")
            writer.tag(117, WireType.LengthDelimited).string(message.paidBettingPlaceName);
        /* google.protobuf.Timestamp paid_time = 118; */
        if (message.paidTime)
            Timestamp.internalBinaryWrite(message.paidTime, writer.tag(118, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp resolved_time = 129; */
        if (message.resolvedTime)
            Timestamp.internalBinaryWrite(message.resolvedTime, writer.tag(129, WireType.LengthDelimited).fork(), options).join();
        /* string wallet_prefix = 130; */
        if (message.walletPrefix !== "")
            writer.tag(130, WireType.LengthDelimited).string(message.walletPrefix);
        /* google.protobuf.Timestamp manual_change_time = 300; */
        if (message.manualChangeTime)
            Timestamp.internalBinaryWrite(message.manualChangeTime, writer.tag(300, WireType.LengthDelimited).fork(), options).join();
        /* bool is_printed = 302; */
        if (message.isPrinted !== false)
            writer.tag(302, WireType.Varint).bool(message.isPrinted);
        /* bool cashout_paid_out = 304; */
        if (message.cashoutPaidOut !== false)
            writer.tag(304, WireType.Varint).bool(message.cashoutPaidOut);
        /* int32 role_id = 444; */
        if (message.roleId !== 0)
            writer.tag(444, WireType.Varint).int32(message.roleId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.Slip
 */
export const Slip = new Slip$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventTime$Type extends MessageType<EventTime> {
    constructor() {
        super("common.EventTime", [
            { no: 202, name: "M", kind: "scalar", jsonName: "M", T: 9 /*ScalarType.STRING*/ },
            { no: 203, name: "S", kind: "scalar", jsonName: "S", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EventTime>): EventTime {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.m = "";
        message.s = "";
        if (value !== undefined)
            reflectionMergePartial<EventTime>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventTime): EventTime {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string M = 202 [json_name = "M"];*/ 202:
                    message.m = reader.string();
                    break;
                case /* string S = 203 [json_name = "S"];*/ 203:
                    message.s = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventTime, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string M = 202 [json_name = "M"]; */
        if (message.m !== "")
            writer.tag(202, WireType.LengthDelimited).string(message.m);
        /* string S = 203 [json_name = "S"]; */
        if (message.s !== "")
            writer.tag(203, WireType.LengthDelimited).string(message.s);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.EventTime
 */
export const EventTime = new EventTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventCurrentPhase$Type extends MessageType<EventCurrentPhase> {
    constructor() {
        super("common.EventCurrentPhase", [
            { no: 200, name: "N", kind: "scalar", jsonName: "N", T: 9 /*ScalarType.STRING*/ },
            { no: 201, name: "S", kind: "scalar", jsonName: "S", T: 9 /*ScalarType.STRING*/ },
            { no: 204, name: "T", kind: "message", jsonName: "T", T: () => EventTime }
        ]);
    }
    create(value?: PartialMessage<EventCurrentPhase>): EventCurrentPhase {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.n = "";
        message.s = "";
        if (value !== undefined)
            reflectionMergePartial<EventCurrentPhase>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventCurrentPhase): EventCurrentPhase {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string N = 200 [json_name = "N"];*/ 200:
                    message.n = reader.string();
                    break;
                case /* string S = 201 [json_name = "S"];*/ 201:
                    message.s = reader.string();
                    break;
                case /* common.EventTime T = 204 [json_name = "T"];*/ 204:
                    message.t = EventTime.internalBinaryRead(reader, reader.uint32(), options, message.t);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventCurrentPhase, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string N = 200 [json_name = "N"]; */
        if (message.n !== "")
            writer.tag(200, WireType.LengthDelimited).string(message.n);
        /* string S = 201 [json_name = "S"]; */
        if (message.s !== "")
            writer.tag(201, WireType.LengthDelimited).string(message.s);
        /* common.EventTime T = 204 [json_name = "T"]; */
        if (message.t)
            EventTime.internalBinaryWrite(message.t, writer.tag(204, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.EventCurrentPhase
 */
export const EventCurrentPhase = new EventCurrentPhase$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventCurrentResult$Type extends MessageType<EventCurrentResult> {
    constructor() {
        super("common.EventCurrentResult", [
            { no: 205, name: "G", kind: "scalar", jsonName: "G", T: 9 /*ScalarType.STRING*/ },
            { no: 206, name: "C", kind: "scalar", jsonName: "C", T: 9 /*ScalarType.STRING*/ },
            { no: 207, name: "RC", kind: "scalar", jsonName: "RC", T: 9 /*ScalarType.STRING*/ },
            { no: 208, name: "YC", kind: "scalar", jsonName: "YC", T: 9 /*ScalarType.STRING*/ },
            { no: 209, name: "P", kind: "scalar", jsonName: "P", T: 9 /*ScalarType.STRING*/ },
            { no: 210, name: "S", kind: "scalar", jsonName: "S", T: 9 /*ScalarType.STRING*/ },
            { no: 267, name: "L", kind: "scalar", jsonName: "L", T: 9 /*ScalarType.STRING*/ },
            { no: 268, name: "S180", kind: "scalar", jsonName: "S180", T: 9 /*ScalarType.STRING*/ },
            { no: 269, name: "FG", kind: "scalar", jsonName: "FG", T: 9 /*ScalarType.STRING*/ },
            { no: 270, name: "PN", kind: "scalar", jsonName: "PN", T: 9 /*ScalarType.STRING*/ },
            { no: 271, name: "TD", kind: "scalar", jsonName: "TD", T: 9 /*ScalarType.STRING*/ },
            { no: 272, name: "SC", kind: "scalar", jsonName: "SC", T: 9 /*ScalarType.STRING*/ },
            { no: 273, name: "SH", kind: "scalar", jsonName: "SH", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EventCurrentResult>): EventCurrentResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.g = "";
        message.c = "";
        message.rC = "";
        message.yC = "";
        message.p = "";
        message.s = "";
        message.l = "";
        message.s180 = "";
        message.fG = "";
        message.pN = "";
        message.tD = "";
        message.sC = "";
        message.sH = "";
        if (value !== undefined)
            reflectionMergePartial<EventCurrentResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventCurrentResult): EventCurrentResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string G = 205 [json_name = "G"];*/ 205:
                    message.g = reader.string();
                    break;
                case /* string C = 206 [json_name = "C"];*/ 206:
                    message.c = reader.string();
                    break;
                case /* string RC = 207 [json_name = "RC"];*/ 207:
                    message.rC = reader.string();
                    break;
                case /* string YC = 208 [json_name = "YC"];*/ 208:
                    message.yC = reader.string();
                    break;
                case /* string P = 209 [json_name = "P"];*/ 209:
                    message.p = reader.string();
                    break;
                case /* string S = 210 [json_name = "S"];*/ 210:
                    message.s = reader.string();
                    break;
                case /* string L = 267 [json_name = "L"];*/ 267:
                    message.l = reader.string();
                    break;
                case /* string S180 = 268 [json_name = "S180"];*/ 268:
                    message.s180 = reader.string();
                    break;
                case /* string FG = 269 [json_name = "FG"];*/ 269:
                    message.fG = reader.string();
                    break;
                case /* string PN = 270 [json_name = "PN"];*/ 270:
                    message.pN = reader.string();
                    break;
                case /* string TD = 271 [json_name = "TD"];*/ 271:
                    message.tD = reader.string();
                    break;
                case /* string SC = 272 [json_name = "SC"];*/ 272:
                    message.sC = reader.string();
                    break;
                case /* string SH = 273 [json_name = "SH"];*/ 273:
                    message.sH = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventCurrentResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string G = 205 [json_name = "G"]; */
        if (message.g !== "")
            writer.tag(205, WireType.LengthDelimited).string(message.g);
        /* string C = 206 [json_name = "C"]; */
        if (message.c !== "")
            writer.tag(206, WireType.LengthDelimited).string(message.c);
        /* string RC = 207 [json_name = "RC"]; */
        if (message.rC !== "")
            writer.tag(207, WireType.LengthDelimited).string(message.rC);
        /* string YC = 208 [json_name = "YC"]; */
        if (message.yC !== "")
            writer.tag(208, WireType.LengthDelimited).string(message.yC);
        /* string P = 209 [json_name = "P"]; */
        if (message.p !== "")
            writer.tag(209, WireType.LengthDelimited).string(message.p);
        /* string S = 210 [json_name = "S"]; */
        if (message.s !== "")
            writer.tag(210, WireType.LengthDelimited).string(message.s);
        /* string L = 267 [json_name = "L"]; */
        if (message.l !== "")
            writer.tag(267, WireType.LengthDelimited).string(message.l);
        /* string S180 = 268 [json_name = "S180"]; */
        if (message.s180 !== "")
            writer.tag(268, WireType.LengthDelimited).string(message.s180);
        /* string FG = 269 [json_name = "FG"]; */
        if (message.fG !== "")
            writer.tag(269, WireType.LengthDelimited).string(message.fG);
        /* string PN = 270 [json_name = "PN"]; */
        if (message.pN !== "")
            writer.tag(270, WireType.LengthDelimited).string(message.pN);
        /* string TD = 271 [json_name = "TD"]; */
        if (message.tD !== "")
            writer.tag(271, WireType.LengthDelimited).string(message.tD);
        /* string SC = 272 [json_name = "SC"]; */
        if (message.sC !== "")
            writer.tag(272, WireType.LengthDelimited).string(message.sC);
        /* string SH = 273 [json_name = "SH"]; */
        if (message.sH !== "")
            writer.tag(273, WireType.LengthDelimited).string(message.sH);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.EventCurrentResult
 */
export const EventCurrentResult = new EventCurrentResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventLiveResultHistoryElement$Type extends MessageType<EventLiveResultHistoryElement> {
    constructor() {
        super("common.EventLiveResultHistoryElement", [
            { no: 211, name: "P", kind: "message", jsonName: "P", T: () => EventCurrentPhase },
            { no: 212, name: "R", kind: "message", jsonName: "R", T: () => EventCurrentResult }
        ]);
    }
    create(value?: PartialMessage<EventLiveResultHistoryElement>): EventLiveResultHistoryElement {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EventLiveResultHistoryElement>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventLiveResultHistoryElement): EventLiveResultHistoryElement {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* common.EventCurrentPhase P = 211 [json_name = "P"];*/ 211:
                    message.p = EventCurrentPhase.internalBinaryRead(reader, reader.uint32(), options, message.p);
                    break;
                case /* common.EventCurrentResult R = 212 [json_name = "R"];*/ 212:
                    message.r = EventCurrentResult.internalBinaryRead(reader, reader.uint32(), options, message.r);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventLiveResultHistoryElement, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* common.EventCurrentPhase P = 211 [json_name = "P"]; */
        if (message.p)
            EventCurrentPhase.internalBinaryWrite(message.p, writer.tag(211, WireType.LengthDelimited).fork(), options).join();
        /* common.EventCurrentResult R = 212 [json_name = "R"]; */
        if (message.r)
            EventCurrentResult.internalBinaryWrite(message.r, writer.tag(212, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.EventLiveResultHistoryElement
 */
export const EventLiveResultHistoryElement = new EventLiveResultHistoryElement$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventResult$Type extends MessageType<EventResult> {
    constructor() {
        super("common.EventResult", [
            { no: 213, name: "current_phase", kind: "message", T: () => EventCurrentPhase },
            { no: 214, name: "current_result", kind: "message", T: () => EventCurrentResult },
            { no: 215, name: "live_result_history", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventLiveResultHistoryElement },
            { no: 216, name: "live_result_stats", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 217, name: "prematch_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 218, name: "live_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 219, name: "confirmed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<EventResult>): EventResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.liveResultHistory = [];
        message.liveResultStats = "";
        message.prematchResult = "";
        message.liveResult = "";
        message.confirmed = false;
        if (value !== undefined)
            reflectionMergePartial<EventResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventResult): EventResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* common.EventCurrentPhase current_phase */ 213:
                    message.currentPhase = EventCurrentPhase.internalBinaryRead(reader, reader.uint32(), options, message.currentPhase);
                    break;
                case /* common.EventCurrentResult current_result */ 214:
                    message.currentResult = EventCurrentResult.internalBinaryRead(reader, reader.uint32(), options, message.currentResult);
                    break;
                case /* repeated common.EventLiveResultHistoryElement live_result_history */ 215:
                    message.liveResultHistory.push(EventLiveResultHistoryElement.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string live_result_stats */ 216:
                    message.liveResultStats = reader.string();
                    break;
                case /* string prematch_result */ 217:
                    message.prematchResult = reader.string();
                    break;
                case /* string live_result */ 218:
                    message.liveResult = reader.string();
                    break;
                case /* bool confirmed */ 219:
                    message.confirmed = reader.bool();
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* common.EventCurrentPhase current_phase = 213; */
        if (message.currentPhase)
            EventCurrentPhase.internalBinaryWrite(message.currentPhase, writer.tag(213, WireType.LengthDelimited).fork(), options).join();
        /* common.EventCurrentResult current_result = 214; */
        if (message.currentResult)
            EventCurrentResult.internalBinaryWrite(message.currentResult, writer.tag(214, WireType.LengthDelimited).fork(), options).join();
        /* repeated common.EventLiveResultHistoryElement live_result_history = 215; */
        for (let i = 0; i < message.liveResultHistory.length; i++)
            EventLiveResultHistoryElement.internalBinaryWrite(message.liveResultHistory[i], writer.tag(215, WireType.LengthDelimited).fork(), options).join();
        /* string live_result_stats = 216; */
        if (message.liveResultStats !== "")
            writer.tag(216, WireType.LengthDelimited).string(message.liveResultStats);
        /* string prematch_result = 217; */
        if (message.prematchResult !== "")
            writer.tag(217, WireType.LengthDelimited).string(message.prematchResult);
        /* string live_result = 218; */
        if (message.liveResult !== "")
            writer.tag(218, WireType.LengthDelimited).string(message.liveResult);
        /* bool confirmed = 219; */
        if (message.confirmed !== false)
            writer.tag(219, WireType.Varint).bool(message.confirmed);
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.EventResult
 */
export const EventResult = new EventResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventResultPeriod$Type extends MessageType<EventResultPeriod> {
    constructor() {
        super("common.EventResultPeriod", [
            { no: 141, name: "points", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 142, name: "games", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 143, name: "sets", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EventResultPeriod>): EventResultPeriod {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.points = "";
        message.games = "";
        message.sets = "";
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<EventResultPeriod>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventResultPeriod): EventResultPeriod {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string points */ 141:
                    message.points = reader.string();
                    break;
                case /* string games */ 142:
                    message.games = reader.string();
                    break;
                case /* string sets */ 143:
                    message.sets = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventResultPeriod, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string points = 141; */
        if (message.points !== "")
            writer.tag(141, WireType.LengthDelimited).string(message.points);
        /* string games = 142; */
        if (message.games !== "")
            writer.tag(142, WireType.LengthDelimited).string(message.games);
        /* string sets = 143; */
        if (message.sets !== "")
            writer.tag(143, WireType.LengthDelimited).string(message.sets);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.EventResultPeriod
 */
export const EventResultPeriod = new EventResultPeriod$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FastOdd$Type extends MessageType<FastOdd> {
    constructor() {
        super("common.FastOdd", [
            { no: 1, name: "odd_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FastOdd>): FastOdd {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.oddId = 0;
        message.eventId = "";
        if (value !== undefined)
            reflectionMergePartial<FastOdd>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FastOdd): FastOdd {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 odd_id */ 1:
                    message.oddId = reader.int32();
                    break;
                case /* string event_id */ 2:
                    message.eventId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FastOdd, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 odd_id = 1; */
        if (message.oddId !== 0)
            writer.tag(1, WireType.Varint).int32(message.oddId);
        /* string event_id = 2; */
        if (message.eventId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.eventId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.FastOdd
 */
export const FastOdd = new FastOdd$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FastSlipGroup$Type extends MessageType<FastSlipGroup> {
    constructor() {
        super("common.FastSlipGroup", [
            { no: 1, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sub_groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FastSlipSubGroup }
        ]);
    }
    create(value?: PartialMessage<FastSlipGroup>): FastSlipGroup {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = "";
        message.subGroups = [];
        if (value !== undefined)
            reflectionMergePartial<FastSlipGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FastSlipGroup): FastSlipGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 1:
                    message.type = reader.string();
                    break;
                case /* repeated common.FastSlipSubGroup sub_groups */ 2:
                    message.subGroups.push(FastSlipSubGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FastSlipGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 1; */
        if (message.type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.type);
        /* repeated common.FastSlipSubGroup sub_groups = 2; */
        for (let i = 0; i < message.subGroups.length; i++)
            FastSlipSubGroup.internalBinaryWrite(message.subGroups[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.FastSlipGroup
 */
export const FastSlipGroup = new FastSlipGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FastSlipSubGroup$Type extends MessageType<FastSlipSubGroup> {
    constructor() {
        super("common.FastSlipSubGroup", [
            { no: 1, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FastOdd }
        ]);
    }
    create(value?: PartialMessage<FastSlipSubGroup>): FastSlipSubGroup {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = "";
        message.odds = [];
        if (value !== undefined)
            reflectionMergePartial<FastSlipSubGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FastSlipSubGroup): FastSlipSubGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 1:
                    message.type = reader.string();
                    break;
                case /* repeated common.FastOdd odds */ 2:
                    message.odds.push(FastOdd.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FastSlipSubGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 1; */
        if (message.type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.type);
        /* repeated common.FastOdd odds = 2; */
        for (let i = 0; i < message.odds.length; i++)
            FastOdd.internalBinaryWrite(message.odds[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.FastSlipSubGroup
 */
export const FastSlipSubGroup = new FastSlipSubGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FastSlipRequest$Type extends MessageType<FastSlipRequest> {
    constructor() {
        super("common.FastSlipRequest", [
            { no: 1, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "slip_groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FastSlipGroup }
        ]);
    }
    create(value?: PartialMessage<FastSlipRequest>): FastSlipRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.amount = 0;
        message.slipGroups = [];
        if (value !== undefined)
            reflectionMergePartial<FastSlipRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FastSlipRequest): FastSlipRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double amount */ 1:
                    message.amount = reader.double();
                    break;
                case /* repeated common.FastSlipGroup slip_groups */ 2:
                    message.slipGroups.push(FastSlipGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FastSlipRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double amount = 1; */
        if (message.amount !== 0)
            writer.tag(1, WireType.Bit64).double(message.amount);
        /* repeated common.FastSlipGroup slip_groups = 2; */
        for (let i = 0; i < message.slipGroups.length; i++)
            FastSlipGroup.internalBinaryWrite(message.slipGroups[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.FastSlipRequest
 */
export const FastSlipRequest = new FastSlipRequest$Type();
