import { makeAutoObservable } from 'mobx';

import Event from 'modules/sports/store/event.store';
import { EventType, EVENT_TYPE } from 'modules/sports/sports.types';
import sportsStore from 'modules/sports/store/sports.store';
import offerStore from 'modules/offer/store/offer.store';

export enum EventsSort {
  TIME = 0,
  COMPETITION,
}

export type EVENT_SORT = EventsSort.TIME | EventsSort.COMPETITION;

class OfferSort {
  activeSort: EVENT_SORT = EventsSort.TIME;

  constructor() {
    makeAutoObservable(this);
  }

  get isSortedByTime() {
    return this.activeSort === EventsSort.TIME;
  }

  setSort = (sortType: EVENT_SORT): void => {
    offerStore.setActiveGroup(null);
    this.activeSort = sortType;
  };

  sort = (events: Event[], type: EventType): Event[] => {
    if (type === EVENT_TYPE.ANTEPOST) {
      return this.sortByCompetition(events, type);
    }

    if (type === EVENT_TYPE.PLAYER) {
      return this.sortByTeamName(events, type);
    }

    const strategy = this.isSortedByTime
      ? this.sortByTime
      : this.sortByCompetition;

    return strategy(events, type);
  };

  sortByTime = (events: Event[], type: EventType): Event[] => {
    const sortedEvents = events
      .slice()
      .sort(this.sortByName)
      .sort(this.sortFavorites)
      .sort(this.sortByCompetitionOrder)
      .sort(this.timeSort);

    if (type === EVENT_TYPE.LIVE) {
      return sortedEvents.sort(this.sortByProfile);
    }

    return sortedEvents;
  };

  sortByTeamName = (events: Event[], type: EventType): Event[] => {
    events.forEach((event: any) => {
      const bracketIndex = event.name.indexOf('(') + 1;
      const teamShortName = event.name.substr(bracketIndex, 3);
      event.teamShortName = teamShortName;
    });
    events.sort((a: any, b: any) => {
      if (a.teamShortName === b.teamShortName) return a.home > b.home ? 1 : -1;
      if (a.start.seconds === b.start.seconds)
        return a.teamShortName > b.teamShortName ? 1 : -1;
      return a.start.seconds > b.start.seconds ? 1 : -1;
    });

    return events;
  };

  sortByCompetition = (events: Event[], type: EventType): Event[] => {
    const { sportsData } = sportsStore;
    if (!sportsData) return events;

    const sortedEvents = events
      .slice()
      .sort(this.sortByName)
      .sort(this.sortFavorites)
      .sort(this.sortByCompetitionOrder);

    if (type === EVENT_TYPE.LIVE) {
      return sortedEvents.sort(this.sortByProfile);
    } else if (type === EVENT_TYPE.ANTEPOST) {
      return sortedEvents.sort(this.sortByEventName);
    }

    return sortedEvents;
  };

  sortByEventName = (prevEvent: Event, nextEvent: Event) => {
    if (prevEvent.name < nextEvent.name) {
      return -1;
    } else if (prevEvent.name > nextEvent.name) {
      return 1;
    }

    return 0;
  };

  timeSort = (a: Event, b: Event) => {
    if (a.start.seconds === b.start.seconds) return a.home > b.home ? 1 : -1;
    return a.start.seconds - b.start.seconds;
  };

  sortByName = (a: Event, b: Event) => {
    if (a.locationName < b.locationName) {
      return -1;
    } else if (a.locationName > b.locationName) {
      return 1;
    }

    if (a.competitionName < b.competitionName) {
      return -1;
    } else if (a.competitionName > b.competitionName) {
      return 1;
    }

    if (a.start.seconds === b.start.seconds) return a.home > b.home ? 1 : -1;

    return a.start.seconds - b.start.seconds;
  };

  sortFavorites = (a: Event, b: Event) => {
    return (
      Number(b.competition?.isFavorite) - Number(a.competition?.isFavorite)
    );
  };

  sortByCompetitionOrder = (a: Event, b: Event) => {
    //@ts-ignore
    return (
      // @ts-ignore
      Number(a.competition?.competitionOrder) -
      // @ts-ignore
      Number(b.competition?.competitionOrder)
    );
  };

  sortByProfile = (a: Event, b: Event) => {
    return +a.profile - +b.profile;
  };

  adjustOfferForPlayersAndSpecials = (offer: any) => {
    let adjustedOffer: any = [];

    offer.forEach((table: any, i: number) => {
      if (
        table.type === EVENT_TYPE.PLAYER ||
        table.type === EVENT_TYPE.SPECIAL
      ) {
        Object.keys(table.groupedEvents).forEach(
          (key: string, index: number) => {
            adjustedOffer.push({
              events: table.groupedEvents[key],
              groupedEvents: {
                [key]: table.groupedEvents[key],
              },
              rows: table.groupedEvents[key].map((item: any) => {
                return { data: item, type: 'row' };
              }),
              sport: table.sport,
              sportId: table.sportId,
              type: table.type,
              subType: key,
            });
          }
        );
      } else {
        adjustedOffer.push(table);
      }
    });

    return adjustedOffer;
  };
}

export default new OfferSort();
