/* React modules */
import { createContext, useEffect, useState } from 'react';

/* Our modules */
import { EVENT_TYPE } from 'modules/sports/sports.types';
import useStores from 'common/hooks/useStores';
import feedStore from 'modules/feed/feed.store';
import {
  MarketGroupFilters,
  OddsMarkets,
} from 'pages/sport/EventAllOddsOverlay/components';
import StatsButton, {
  StatsButtonProps,
} from 'pages/sport/EventAllOddsOverlay/components/StatsButton';
import EventHeader from 'pages/sport/EventAllOddsOverlay/components/EventHeader';
import MatchStatistic from 'pages/sport/EventAllOddsOverlay/components/MatchStatistic';
import { Icon, Tabs } from 'components';
import Loader, { LoaderWrapper } from 'components/Loader';
import useWindowSize from 'libs/viewport';
import { isNumber } from 'libs/common-helpers';
import './EventAllOddsOverlay.scss';

/* 3rd Party modules */
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

interface EventAllOddsOverlayProps {
  visible: boolean;
  event: any;
  close: () => void;
}

export const OddsMarketsContext = createContext<any>(null);

const EventAllOddsOverlay = ({
  visible,
  event,
  close,
}: EventAllOddsOverlayProps) => {
  const { t } = useTranslation();

  const {
    authStore: { token },
    offerEvent,
    overlayStore,
    ticketBuilder,
    userStore: { user },
    sportsStore: {
      liveStreamURL,
      isLiveStreamPlaying,
      getLiveStreamURL,
      setLiveStreamURL,
      setIsLiveStreamPlaying,
    },
  } = useStores();

  const { events: slipEvents } = ticketBuilder;

  const { width } = useWindowSize();

  const [activeTab, setActiveTab] = useState(1);
  const [statsCollapsed, setStatsCollapsed] = useState<boolean>(false);
  const [frameLoad, setFrameLoad] = useState<boolean>(false);
  const [viewStats, setViewStats] = useState<boolean>(true);
  const [frameHeight, setFrameHeight] = useState<number>((width - 250) / 2);
  const [iframeHeight, setIframeHeight] = useState(0);

  const exeFeed_id = event.exefeedId;
  const exeFeedUid = 'default';
  const betradarId = event.betradarId;
  const lmtUrl = `https://tracker.exefeed.com/match-tracker/vDetails.php?e=${exeFeed_id}&ln=en&uid=${exeFeedUid}&bgc=1f1f1f!important&&fc=ffffff`;
  const lmtUrlMobile = `https://tracker.exefeed.com/match-tracker/index.php?e=${exeFeed_id}&w=90&ln=en&uid=${exeFeedUid}&bgc=303030!important&&fc=ffffff`;
  const fullStatisticUrl = `https://s5.sir.sportradar.com/sportingbookmakers/me/match/${betradarId}`;

  const hasStatistic = betradarId && betradarId !== '';

  const type = event.type;

  const onTabClick = (key: string) => {
    setActiveTab(+key);
  };

  const iframeHeightHandler = () => {
    setFrameLoad(true);
    let height = document.querySelector('#frame-wrapper');
    if (height) setIframeHeight(height.clientHeight - 50);
  };

  const fetchLiveStreamURL = async () => {
    if (user && event.liveStreamId) {
      const { id } = user;

      if (isNumber(id)) {
        await getLiveStreamURL(event.liveStreamId, id || -1, token);
      }
    }
  };

  const statsCollapsedHanlder = () => {
    setStatsCollapsed(!statsCollapsed);
  };

  useEffect(() => {
    iframeHeightHandler();
  }, [window.innerWidth]);

  useEffect(() => {
    const eventId = event.id;

    if (event) {
      feedStore.subscribeEvent(eventId);

      if (!event.allOddsLoaded) {
        event.getAllOdds();
      }

      offerEvent.setEvent(event);

      iframeHeightHandler();
    }

    return () => {
      if (eventId) {
        const targetEvent = slipEvents.find((e: any) => e.id === eventId);

        if (!targetEvent) {
          feedStore.unsubscribeEvent(eventId);
        }
      }
    };
  }, [event, offerEvent]);

  useEffect(() => {
    if (width < 768) {
      setFrameHeight(width / 1.8);
    } else {
      setFrameHeight((width - 250) / 1.8);
    }
  }, [width]);

  useEffect(() => {
    if (event.type === 'upcoming') {
      setViewStats(false);
      onTabClick('2');
    } else if (event.type === 'live') {
      setViewStats(true);
      onTabClick('1');
    }
  }, [event.type]);

  useEffect(() => {
    if (!visible) {
      setFrameLoad(false);
    }
  }, [visible]);

  useEffect(() => {
    setIsLiveStreamPlaying(false);
    setLiveStreamURL('');

    setFrameLoad(false);
  }, [event]);

  useEffect(() => {
    if (event.type === 'live' && event.liveStreamId && isLiveStreamPlaying) {
      fetchLiveStreamURL();
    }
  }, [isLiveStreamPlaying]);

  useEffect(() => {
    return () => {
      setLiveStreamURL('');
      setIsLiveStreamPlaying(false);
    };
  }, []);

  const openMatchStats = () => {
    overlayStore.openModal(
      <MatchStatistic
        setFrameLoad={() => setFrameLoad(true)}
        betradarId={betradarId}
      />,
      {
        width: 'large',
        className: 'bg-black-700',
        wrapClassName: 'bg-black-700',
        name: 'sr-iframe',
        closable: false,
      }
    );
  };

  if (!event || !offerEvent.event?.allOddsLoaded)
    return <div className={classnames('all-odds-overlay', { visible })}></div>;

  const StatsButtonMobile = ({ collapsed, onClick }: StatsButtonProps) => {
    const { t } = useTranslation();

    return (
      <div className="stats-button-mobile py-1" onClick={onClick}>
        {collapsed && (
          <span className="mr-1">{t('sportsPage.live-match-tracker')}</span>
        )}

        <Icon size="extra-small" name={collapsed ? 'caretDown' : 'caretUp'} />
      </div>
    );
  };

  return (
    <div className={classnames('all-odds-overlay', { visible })}>
      <LoaderWrapper name={offerEvent.event.id}>
        <div className="h-full overflow-auto">
          <EventHeader
            event={event}
            close={close}
            openMatchStats={() => openMatchStats()}
          />

          {event.liveStreamId && liveStreamURL && isLiveStreamPlaying && (
            <div className="xy-center">
              <ReactPlayer
                url={liveStreamURL}
                width="33vw"
                height="auto"
                playing
                config={{
                  file: { forceHLS: true },
                }}
              />
            </div>
          )}

          <div className="visible-desktop-down d-flex flex-column align-stretch max-height">
            {type !== EVENT_TYPE.PLAYER && type !== EVENT_TYPE.SPECIAL && (
              <div
                className={classnames('all-odds-overlay__stats full-width', {
                  hidden: statsCollapsed,
                })}
              >
                {exeFeed_id ? (
                  <div
                    style={{ height: frameHeight }}
                    className={classnames(
                      'all-odds-overlay__stats--iframe-wrapper',
                      {
                        'display-frame': frameLoad,
                      }
                    )}
                  >
                    <iframe
                      scrolling="no"
                      height={frameHeight}
                      width="100%"
                      frameBorder="none"
                      onLoad={() => setFrameLoad(true)}
                      src={lmtUrlMobile}
                      title="event-tracker"
                    />
                  </div>
                ) : null}
              </div>
            )}

            {viewStats &&
              type !== EVENT_TYPE.PLAYER &&
              type !== EVENT_TYPE.SPECIAL && (
                <StatsButtonMobile
                  collapsed={statsCollapsed}
                  onClick={() => statsCollapsedHanlder()}
                />
              )}

            {type !== EVENT_TYPE.PLAYER && type !== EVENT_TYPE.SPECIAL && (
              <MarketGroupFilters />
            )}

            <div className="all-odds-overlay__body">
              <div className="all-odds-overlay__markets-wrapper">
                <OddsMarketsContext.Provider value={event}>
                  <OddsMarkets />
                </OddsMarketsContext.Provider>
              </div>
            </div>
          </div>

          {type !== EVENT_TYPE.PLAYER && type !== EVENT_TYPE.SPECIAL && (
            <MarketGroupFilters />
          )}

          <div className="visible-desktop-up d-flex flex-column align-stretch max-height">
            <div id="frame-wrapper" className="all-odds-overlay__body">
              <div
                className={classnames('all-odds-overlay__markets-wrapper', {
                  half:
                    !statsCollapsed && viewStats && type !== EVENT_TYPE.PLAYER,
                })}
              >
                {(betradarId || type === EVENT_TYPE.LIVE) && (
                  <StatsButton
                    collapsed={statsCollapsed}
                    onClick={() => setStatsCollapsed(!statsCollapsed)}
                  />
                )}

                <OddsMarketsContext.Provider value={event}>
                  <OddsMarkets />
                </OddsMarketsContext.Provider>
              </div>

              {(betradarId || type === EVENT_TYPE.LIVE) && (
                <div
                  className={classnames('all-odds-overlay__frame-tabs', {
                    hidden: statsCollapsed,
                    'full-width': hasStatistic && type === EVENT_TYPE.UPCOMING,
                  })}
                >
                  <Tabs
                    items={[
                      ...(type === EVENT_TYPE.LIVE
                        ? [
                            {
                              key: '1',
                              label: t(
                                'sportsPage.live-match-tracker'
                              ).toUpperCase(),
                              children: (
                                <>
                                  {activeTab === 1 && (
                                    <div className="all-odds-overlay__stats">
                                      <div
                                        className={classnames(
                                          'all-odds-overlay__stats__iframe-wrapper',
                                          {
                                            'display-frame': frameLoad,
                                          }
                                        )}
                                      >
                                        {!frameLoad && (
                                          <div
                                            className="w-100 d-flex justify-end align-items mt-20"
                                            style={{ position: 'relative' }}
                                          >
                                            <Loader />
                                          </div>
                                        )}

                                        {frameLoad && (
                                          <iframe
                                            title="event-statistic"
                                            scrolling="yes"
                                            frameBorder="none"
                                            width="100%"
                                            height={iframeHeight}
                                            className="all-odds-overlay__stats__iframe"
                                            onLoad={() => iframeHeightHandler()}
                                            src={lmtUrl}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </>
                              ),
                            },
                          ]
                        : []),
                      ...(betradarId
                        ? [
                            {
                              key: '2',
                              label: t('globals.statistics'),
                              children: (
                                <>
                                  {activeTab === 2 && hasStatistic ? (
                                    <div className="all-odds-overlay__stats">
                                      <div
                                        className={classnames(
                                          'all-odds-overlay__stats__iframe-wrapper',
                                          {
                                            'display-frame': frameLoad,
                                          }
                                        )}
                                      >
                                        <iframe
                                          title="event-stats"
                                          scrolling="yes"
                                          frameBorder="none"
                                          height={iframeHeight}
                                          width="100%"
                                          className="all-odds-overlay__stats__iframe"
                                          onLoad={() => iframeHeightHandler()}
                                          src={fullStatisticUrl}
                                        />
                                        <Loader />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="sb-text-small text-grey-300 py-10 text-center">
                                      {t('globals.no-info-about-event')}
                                    </div>
                                  )}
                                </>
                              ),
                            },
                          ]
                        : []),
                    ]}
                    animated={false}
                    className="event-stats"
                    defaultActiveKey="1"
                    onTabClick={onTabClick}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </LoaderWrapper>
    </div>
  );
};

EventAllOddsOverlay.defaultProps = {
  visible: false,
  event: null,
};

export default observer(EventAllOddsOverlay);
