import { makeAutoObservable, runInAction } from 'mobx';

import { getCurrentTimestamp } from 'libs/datetime';
import sportsStore from 'modules/sports/store/sports.store';
import { SportNodeService } from 'modules/sports/services/sport-node.service';

export enum TimeFilters {
  ONE_HOUR = 1,
  THREE_HOURS = 3,
  ONE_DAY = 24,
  THREE_DAYS = 72,
  ALL = 0,
}

export type TIME_FILTER =
  | TimeFilters.ONE_HOUR
  | TimeFilters.THREE_HOURS
  | TimeFilters.ONE_DAY
  | TimeFilters.THREE_DAYS
  | TimeFilters.ALL;

export interface TIME_FILTER_OPTION {
  label: string;
  value: TIME_FILTER;
}

export const TIME_FILTER_OPTIONS: TIME_FILTER_OPTION[] = [
  { label: '1h', value: TimeFilters.ONE_HOUR },
  { label: '3h', value: TimeFilters.THREE_HOURS },
  { label: '1d', value: TimeFilters.ONE_DAY },
  { label: '3d', value: TimeFilters.THREE_DAYS },
  { label: 'all', value: TimeFilters.ALL },
];

export enum EventsFilters {
  ALL = 'all',
  LIVE = 'live',
  UPCOMING = 'upcoming',
  ANTEPOST = 'antepost',
  PLAYER = 'player',
  SPECIAL = 'special',
}

export type EVENT_FILTER =
  | EventsFilters.ALL
  | EventsFilters.LIVE
  | EventsFilters.UPCOMING
  | EventsFilters.ANTEPOST
  | EventsFilters.PLAYER
  | EventsFilters.SPECIAL;

export const EVENT_FILTER_OPTIONS: EVENT_FILTER[] = [
  EventsFilters.ALL,
  EventsFilters.LIVE,
  EventsFilters.UPCOMING,
  EventsFilters.ANTEPOST,
  EventsFilters.PLAYER,
  EventsFilters.SPECIAL,
];

class OfferFilters {
  /** selectedSport filter is used only on mobile view */
  selectedSport: SportNodeService | null = null;
  timeFilter: TIME_FILTER = TimeFilters.ALL;
  eventFilter: EVENT_FILTER = EventsFilters.ALL;
  competitions: Map<number, SportNodeService[]> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  get displayDefaultOffer(): boolean {
    return this.competitions.size === 0;
  }

  get activeEventTypes(): EVENT_FILTER[] {
    if (this.eventFilter === EventsFilters.ALL) {
      return [
        EventsFilters.LIVE,
        EventsFilters.UPCOMING,
        EventsFilters.ANTEPOST,
        EventsFilters.PLAYER,
        EventsFilters.SPECIAL,
      ];
    }

    return [this.eventFilter];
  }

  get isAllFilterActive(): boolean {
    return this.eventFilter === EventsFilters.ALL;
  }

  get activeSports(): number[] {
    if (this.displayDefaultOffer) return [10];
    return Array.from(this.competitions.keys());
  }

  get activeSportNodes(): any[] {
    const { sportsData } = sportsStore;

    if (sportsData) {
      return this.activeSports.map((sportId: number) =>
        sportsData.sportsList.find((s) => s.id === sportId)
      );
    }

    return [];
  }

  onSportsLoad = () => {
    const { sportsData } = sportsStore;

    if (sportsData) {
      this.setSelectedSport(sportsData.sportsList[0]);
    }
  };

  setSelectedSport = (sport: SportNodeService) => {
    runInAction(() => {
      this.selectedSport = sport;
    });
  };

  setTimeFilter = (value: TIME_FILTER) => {
    this.timeFilter = value;
  };

  setEventFilter = (value: EVENT_FILTER) => {
    this.eventFilter = value;
  };

  showCompetition = (sportId: number, competition: SportNodeService[]) => {
    const currentState = this.competitions.get(sportId) || [];
    const newState = [...currentState, ...competition];

    if (this.competitions.size > 0) {
      this.competitions.clear();
    }

    this.competitions.set(sportId, newState);
  };

  hideCompetition = (sportId: number, competitionIds: number[]) => {
    const currentState = this.competitions.get(sportId) || [];
    const newState = currentState.filter((c) => !competitionIds.includes(c.id));

    if (newState.length) {
      this.competitions.set(sportId, newState);
    } else {
      this.competitions.delete(sportId);
    }
  };

  isCompetitionSelected = (sportId: number, competitionIds: number[]) => {
    const competitions = this.competitions.get(sportId);
    if (!competitions) return false;

    return competitionIds.every((id: number) => {
      return !!competitions.find((c: SportNodeService) => c.id === id);
    });
  };

  isEventFilterActive = (filter: EVENT_FILTER) => {
    return this.activeEventTypes.includes(filter);
  };

  timeFilterFunction = (seconds: number) => {
    if (this.timeFilter === TimeFilters.ALL) return true;
    const currentTime = getCurrentTimestamp();
    const hourInMiliseconds = 60 * 60 * 1000;
    const limit = currentTime + hourInMiliseconds * this.timeFilter;
    return seconds * 1000 <= limit;
  };
}

export default new OfferFilters();
