// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/odds_stream/odds_stream.proto" (package "odds_stream", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { OddsStreamService } from "./odds_stream_pb";
import type { GetEventsResponse } from "./odds_stream_pb";
import type { EventStreamInt } from "./odds_stream_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { EventStream } from "./odds_stream_pb";
import type { Empty } from "../../google/protobuf/empty_pb";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service odds_stream.OddsStreamService
 */
export interface IOddsStreamServiceClient {
    /**
     * @generated from protobuf rpc: WebEventsStream(google.protobuf.Empty) returns (stream odds_stream.EventStream);
     */
    webEventsStream(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStream>;
    /**
     * @generated from protobuf rpc: WebEventsStreamOrdered(google.protobuf.Empty) returns (stream odds_stream.EventStreamInt);
     */
    webEventsStreamOrdered(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStreamInt>;
    /**
     * @generated from protobuf rpc: WebEventsStreamTop(google.protobuf.Empty) returns (stream odds_stream.EventStreamInt);
     */
    webEventsStreamTop(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStreamInt>;
    /**
     * @generated from protobuf rpc: WebEventsStreamRest(google.protobuf.Empty) returns (stream odds_stream.EventStreamInt);
     */
    webEventsStreamRest(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStreamInt>;
    /**
     * @generated from protobuf rpc: AppEventsStreamTop(google.protobuf.Empty) returns (stream odds_stream.EventStreamInt);
     */
    appEventsStreamTop(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStreamInt>;
    /**
     * @generated from protobuf rpc: AppEventsStreamRest(google.protobuf.Empty) returns (stream odds_stream.EventStreamInt);
     */
    appEventsStreamRest(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStreamInt>;
    /**
     * @generated from protobuf rpc: UnaryStreamGetEvents(google.protobuf.Empty) returns (stream odds_stream.GetEventsResponse);
     */
    unaryStreamGetEvents(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, GetEventsResponse>;
    /**
     * @generated from protobuf rpc: LiveEventsStream(google.protobuf.Empty) returns (stream odds_stream.GetEventsResponse);
     */
    liveEventsStream(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, GetEventsResponse>;
    /**
     * @generated from protobuf rpc: WebEventsLiveStream(google.protobuf.Empty) returns (stream odds_stream.EventStream);
     */
    webEventsLiveStream(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStream>;
    /**
     * @generated from protobuf rpc: WebEventsPrematchStream(google.protobuf.Empty) returns (stream odds_stream.EventStream);
     */
    webEventsPrematchStream(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStream>;
}
/**
 * @generated from protobuf service odds_stream.OddsStreamService
 */
export class OddsStreamServiceClient implements IOddsStreamServiceClient, ServiceInfo {
    typeName = OddsStreamService.typeName;
    methods = OddsStreamService.methods;
    options = OddsStreamService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: WebEventsStream(google.protobuf.Empty) returns (stream odds_stream.EventStream);
     */
    webEventsStream(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStream> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, EventStream>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: WebEventsStreamOrdered(google.protobuf.Empty) returns (stream odds_stream.EventStreamInt);
     */
    webEventsStreamOrdered(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStreamInt> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, EventStreamInt>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: WebEventsStreamTop(google.protobuf.Empty) returns (stream odds_stream.EventStreamInt);
     */
    webEventsStreamTop(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStreamInt> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, EventStreamInt>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: WebEventsStreamRest(google.protobuf.Empty) returns (stream odds_stream.EventStreamInt);
     */
    webEventsStreamRest(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStreamInt> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, EventStreamInt>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AppEventsStreamTop(google.protobuf.Empty) returns (stream odds_stream.EventStreamInt);
     */
    appEventsStreamTop(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStreamInt> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, EventStreamInt>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AppEventsStreamRest(google.protobuf.Empty) returns (stream odds_stream.EventStreamInt);
     */
    appEventsStreamRest(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStreamInt> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, EventStreamInt>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UnaryStreamGetEvents(google.protobuf.Empty) returns (stream odds_stream.GetEventsResponse);
     */
    unaryStreamGetEvents(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, GetEventsResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, GetEventsResponse>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: LiveEventsStream(google.protobuf.Empty) returns (stream odds_stream.GetEventsResponse);
     */
    liveEventsStream(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, GetEventsResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, GetEventsResponse>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: WebEventsLiveStream(google.protobuf.Empty) returns (stream odds_stream.EventStream);
     */
    webEventsLiveStream(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStream> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, EventStream>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: WebEventsPrematchStream(google.protobuf.Empty) returns (stream odds_stream.EventStream);
     */
    webEventsPrematchStream(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStream> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, EventStream>("serverStreaming", this._transport, method, opt, input);
    }
}
