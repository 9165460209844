// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/loyalty/loyalty.proto" (package "loyalty", syntax proto3)
// tslint:disable
import { EmptyError } from "../common/common_pb";
import { Empty } from "../../google/protobuf/empty_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Error } from "../common/common_pb";
/**
 * @generated from protobuf message loyalty.GetUsersRequest
 */
export interface GetUsersRequest {
    /**
     * @generated from protobuf field: int32 user_id = 1;
     */
    userId: number;
    /**
     * @generated from protobuf field: string loyalty_level = 2;
     */
    loyaltyLevel: string;
    /**
     * @generated from protobuf field: int32 offset = 4;
     */
    offset: number;
}
/**
 * @generated from protobuf message loyalty.UserTransactionsRequest
 */
export interface UserTransactionsRequest {
    /**
     * @generated from protobuf field: int32 user_id = 1;
     */
    userId: number;
    /**
     * @generated from protobuf field: string transaction_type = 2;
     */
    transactionType: string;
    /**
     * @generated from protobuf field: string loyalty_type = 3;
     */
    loyaltyType: string;
    /**
     * @generated from protobuf field: int32 start = 4;
     */
    start: number;
    /**
     * @generated from protobuf field: int32 end = 5;
     */
    end: number;
    /**
     * @generated from protobuf field: int32 offset = 6;
     */
    offset: number;
}
/**
 * @generated from protobuf message loyalty.Transactions
 */
export interface Transactions {
    /**
     * @generated from protobuf field: repeated loyalty.Transaction transactions = 1;
     */
    transactions: Transaction[];
    /**
     * @generated from protobuf field: common.Error error = 9;
     */
    error?: Error;
}
/**
 * @generated from protobuf message loyalty.Transaction
 */
export interface Transaction {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: double points = 2;
     */
    points: number;
    /**
     * @generated from protobuf field: int32 created_at = 3;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: string transaction_type = 4;
     */
    transactionType: string;
    /**
     * @generated from protobuf field: string loyalty_type = 5;
     */
    loyaltyType: string;
    /**
     * @generated from protobuf field: int32 expires_at = 6;
     */
    expiresAt: number;
}
/**
 * @generated from protobuf message loyalty.Users
 */
export interface Users {
    /**
     * @generated from protobuf field: repeated loyalty.UserLoyalty users = 1;
     */
    users: UserLoyalty[];
    /**
     * @generated from protobuf field: common.Error error = 9;
     */
    error?: Error;
}
/**
 * @generated from protobuf message loyalty.LevelCount
 */
export interface LevelCount {
    /**
     * @generated from protobuf field: map<string, int32> levels = 1;
     */
    levels: {
        [key: string]: number;
    };
    /**
     * @generated from protobuf field: common.Error error = 9;
     */
    error?: Error;
}
/**
 * @generated from protobuf message loyalty.DisableLoyaltyMessage
 */
export interface DisableLoyaltyMessage {
    /**
     * @generated from protobuf field: string disabled = 1;
     */
    disabled: string;
    /**
     * @generated from protobuf field: string disable_type = 2;
     */
    disableType: string;
    /**
     * @generated from protobuf field: common.Error error = 3;
     */
    error?: Error;
}
/**
 * @generated from protobuf message loyalty.DisableUserLoyaltyMessage
 */
export interface DisableUserLoyaltyMessage {
    /**
     * @generated from protobuf field: int32 user_id = 1;
     */
    userId: number;
    /**
     * @generated from protobuf field: string disabled = 2;
     */
    disabled: string;
    /**
     * @generated from protobuf field: common.Error error = 5;
     */
    error?: Error;
}
/**
 * @generated from protobuf message loyalty.GetOrdersBORequest
 */
export interface GetOrdersBORequest {
    /**
     * @generated from protobuf field: int32 user_id = 1;
     */
    userId: number;
    /**
     * @generated from protobuf field: int32 betting_place_id = 2;
     */
    bettingPlaceId: number;
    /**
     * @generated from protobuf field: string status = 3;
     */
    status: string;
    /**
     * @generated from protobuf field: int32 offset = 5;
     */
    offset: number;
    /**
     * @generated from protobuf field: string bp = 4;
     */
    bp: string;
}
/**
 * @generated from protobuf message loyalty.StoreItemOrdering
 */
export interface StoreItemOrdering {
    /**
     * @generated from protobuf field: map<string, int32> items = 1;
     */
    items: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message loyalty.GetOrdersRequest
 */
export interface GetOrdersRequest {
    /**
     * @generated from protobuf field: int32 offset = 1;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 start = 2;
     */
    start: number;
    /**
     * @generated from protobuf field: int32 end = 3;
     */
    end: number;
    /**
     * @generated from protobuf field: string code = 4;
     */
    code: string;
    /**
     * @generated from protobuf field: string status = 5;
     */
    status: string;
}
/**
 * @generated from protobuf message loyalty.StoreItems
 */
export interface StoreItems {
    /**
     * @generated from protobuf field: repeated loyalty.StoreItem items = 1;
     */
    items: StoreItem[];
}
/**
 * @generated from protobuf message loyalty.StoreItem
 */
export interface StoreItem {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: double price = 4;
     */
    price: number;
    /**
     * @generated from protobuf field: string image_url = 5;
     */
    imageUrl: string;
    /**
     * @generated from protobuf field: int32 quantity = 6;
     */
    quantity: number;
    /**
     * @generated from protobuf field: int32 reserved = 7;
     */
    reserved: number;
    /**
     * @generated from protobuf field: bool digital = 8;
     */
    digital: boolean;
    /**
     * @generated from protobuf field: string digital_type = 9;
     */
    digitalType: string;
    /**
     * @generated from protobuf field: double digital_amount = 10;
     */
    digitalAmount: number;
    /**
     * @generated from protobuf field: bool is_active = 11;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: int32 order = 12;
     */
    order: number;
    /**
     * @generated from protobuf field: common.Error error = 13;
     */
    error?: Error;
}
/**
 * @generated from protobuf message loyalty.Orders
 */
export interface Orders {
    /**
     * @generated from protobuf field: repeated loyalty.Order orders = 1;
     */
    orders: Order[];
}
/**
 * @generated from protobuf message loyalty.Order
 */
export interface Order {
    /**
     * @generated from protobuf field: double points = 1;
     */
    points: number;
    /**
     * @generated from protobuf field: string code = 2;
     */
    code: string;
    /**
     * @generated from protobuf field: int32 id = 3;
     */
    id: number;
    /**
     * @generated from protobuf field: int32 user_id = 9;
     */
    userId: number;
    /**
     * @generated from protobuf field: repeated loyalty.OrderItem items = 4;
     */
    items: OrderItem[];
    /**
     * @generated from protobuf field: int32 created_at = 5;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: string status = 6;
     */
    status: string;
    /**
     * @generated from protobuf field: int32 betting_place_id = 7;
     */
    bettingPlaceId: number;
    /**
     * @generated from protobuf field: common.Error error = 8;
     */
    error?: Error;
}
/**
 * @generated from protobuf message loyalty.OrderItem
 */
export interface OrderItem {
    /**
     * @generated from protobuf field: loyalty.StoreItem item = 1;
     */
    item?: StoreItem;
    /**
     * @generated from protobuf field: int32 quantity = 2;
     */
    quantity: number;
}
/**
 * @generated from protobuf message loyalty.WebLoyaltyConfig
 */
export interface WebLoyaltyConfig {
    /**
     * @generated from protobuf field: map<string, loyalty.LevelLimits> level_up_map = 5;
     */
    levelUpMap: {
        [key: string]: LevelLimits;
    };
    /**
     * @generated from protobuf field: string disabled_points = 6;
     */
    disabledPoints: string;
    /**
     * @generated from protobuf field: string disabled_market = 7;
     */
    disabledMarket: string;
    /**
     * @generated from protobuf field: common.Error error = 8;
     */
    error?: Error;
}
/**
 * @generated from protobuf message loyalty.LoyaltyConfig
 */
export interface LoyaltyConfig {
    /**
     * @generated from protobuf field: map<string, string> slip_coefficient_map = 1;
     */
    slipCoefficientMap: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: string casino_coefficient = 2;
     */
    casinoCoefficient: string;
    /**
     * @generated from protobuf field: map<string, string> slip_level_map = 3;
     */
    slipLevelMap: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: map<string, string> casino_level_map = 4;
     */
    casinoLevelMap: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: map<string, loyalty.LevelLimits> level_up_map = 5;
     */
    levelUpMap: {
        [key: string]: LevelLimits;
    };
    /**
     * @generated from protobuf field: string disabled_points = 6;
     */
    disabledPoints: string;
    /**
     * @generated from protobuf field: string disabled_market = 7;
     */
    disabledMarket: string;
    /**
     * @generated from protobuf field: common.Error error = 8;
     */
    error?: Error;
}
/**
 * @generated from protobuf message loyalty.LevelLimits
 */
export interface LevelLimits {
    /**
     * @generated from protobuf field: string level_stay = 1;
     */
    levelStay: string;
    /**
     * @generated from protobuf field: string level_up = 2;
     */
    levelUp: string;
}
/**
 * @generated from protobuf message loyalty.UserLoyalty
 */
export interface UserLoyalty {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
    /**
     * @generated from protobuf field: double points = 2;
     */
    points: number;
    /**
     * @generated from protobuf field: double level_points = 3;
     */
    levelPoints: number;
    /**
     * @generated from protobuf field: string loyalty_level = 4;
     */
    loyaltyLevel: string;
    /**
     * @generated from protobuf field: bool user_disabled = 5;
     */
    userDisabled: boolean;
    /**
     * @generated from protobuf field: loyalty.Order order = 6;
     */
    order?: Order;
    /**
     * @generated from protobuf field: loyalty.ExpireAt expire_next = 7;
     */
    expireNext?: ExpireAt;
    /**
     * @generated from protobuf field: string username = 8;
     */
    username: string;
    /**
     * @generated from protobuf field: common.Error error = 9;
     */
    error?: Error;
}
/**
 * @generated from protobuf message loyalty.ExpireAt
 */
export interface ExpireAt {
    /**
     * @generated from protobuf field: int32 time = 1;
     */
    time: number;
    /**
     * @generated from protobuf field: string points = 2;
     */
    points: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetUsersRequest$Type extends MessageType<GetUsersRequest> {
    constructor() {
        super("loyalty.GetUsersRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "loyalty_level", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetUsersRequest>): GetUsersRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = 0;
        message.loyaltyLevel = "";
        message.offset = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUsersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUsersRequest): GetUsersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string loyalty_level */ 2:
                    message.loyaltyLevel = reader.string();
                    break;
                case /* int32 offset */ 4:
                    message.offset = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUsersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string loyalty_level = 2; */
        if (message.loyaltyLevel !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.loyaltyLevel);
        /* int32 offset = 4; */
        if (message.offset !== 0)
            writer.tag(4, WireType.Varint).int32(message.offset);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.GetUsersRequest
 */
export const GetUsersRequest = new GetUsersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserTransactionsRequest$Type extends MessageType<UserTransactionsRequest> {
    constructor() {
        super("loyalty.UserTransactionsRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "transaction_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "loyalty_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "start", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "end", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<UserTransactionsRequest>): UserTransactionsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = 0;
        message.transactionType = "";
        message.loyaltyType = "";
        message.start = 0;
        message.end = 0;
        message.offset = 0;
        if (value !== undefined)
            reflectionMergePartial<UserTransactionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserTransactionsRequest): UserTransactionsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string transaction_type */ 2:
                    message.transactionType = reader.string();
                    break;
                case /* string loyalty_type */ 3:
                    message.loyaltyType = reader.string();
                    break;
                case /* int32 start */ 4:
                    message.start = reader.int32();
                    break;
                case /* int32 end */ 5:
                    message.end = reader.int32();
                    break;
                case /* int32 offset */ 6:
                    message.offset = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserTransactionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string transaction_type = 2; */
        if (message.transactionType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.transactionType);
        /* string loyalty_type = 3; */
        if (message.loyaltyType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.loyaltyType);
        /* int32 start = 4; */
        if (message.start !== 0)
            writer.tag(4, WireType.Varint).int32(message.start);
        /* int32 end = 5; */
        if (message.end !== 0)
            writer.tag(5, WireType.Varint).int32(message.end);
        /* int32 offset = 6; */
        if (message.offset !== 0)
            writer.tag(6, WireType.Varint).int32(message.offset);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.UserTransactionsRequest
 */
export const UserTransactionsRequest = new UserTransactionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Transactions$Type extends MessageType<Transactions> {
    constructor() {
        super("loyalty.Transactions", [
            { no: 1, name: "transactions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Transaction },
            { no: 9, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<Transactions>): Transactions {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.transactions = [];
        if (value !== undefined)
            reflectionMergePartial<Transactions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Transactions): Transactions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated loyalty.Transaction transactions */ 1:
                    message.transactions.push(Transaction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 9:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Transactions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated loyalty.Transaction transactions = 1; */
        for (let i = 0; i < message.transactions.length; i++)
            Transaction.internalBinaryWrite(message.transactions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 9; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.Transactions
 */
export const Transactions = new Transactions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Transaction$Type extends MessageType<Transaction> {
    constructor() {
        super("loyalty.Transaction", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "points", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "created_at", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "transaction_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "loyalty_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "expires_at", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Transaction>): Transaction {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.points = 0;
        message.createdAt = 0;
        message.transactionType = "";
        message.loyaltyType = "";
        message.expiresAt = 0;
        if (value !== undefined)
            reflectionMergePartial<Transaction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Transaction): Transaction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* double points */ 2:
                    message.points = reader.double();
                    break;
                case /* int32 created_at */ 3:
                    message.createdAt = reader.int32();
                    break;
                case /* string transaction_type */ 4:
                    message.transactionType = reader.string();
                    break;
                case /* string loyalty_type */ 5:
                    message.loyaltyType = reader.string();
                    break;
                case /* int32 expires_at */ 6:
                    message.expiresAt = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Transaction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* double points = 2; */
        if (message.points !== 0)
            writer.tag(2, WireType.Bit64).double(message.points);
        /* int32 created_at = 3; */
        if (message.createdAt !== 0)
            writer.tag(3, WireType.Varint).int32(message.createdAt);
        /* string transaction_type = 4; */
        if (message.transactionType !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.transactionType);
        /* string loyalty_type = 5; */
        if (message.loyaltyType !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.loyaltyType);
        /* int32 expires_at = 6; */
        if (message.expiresAt !== 0)
            writer.tag(6, WireType.Varint).int32(message.expiresAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.Transaction
 */
export const Transaction = new Transaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Users$Type extends MessageType<Users> {
    constructor() {
        super("loyalty.Users", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserLoyalty },
            { no: 9, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<Users>): Users {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.users = [];
        if (value !== undefined)
            reflectionMergePartial<Users>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Users): Users {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated loyalty.UserLoyalty users */ 1:
                    message.users.push(UserLoyalty.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 9:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Users, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated loyalty.UserLoyalty users = 1; */
        for (let i = 0; i < message.users.length; i++)
            UserLoyalty.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 9; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.Users
 */
export const Users = new Users$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LevelCount$Type extends MessageType<LevelCount> {
    constructor() {
        super("loyalty.LevelCount", [
            { no: 1, name: "levels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } },
            { no: 9, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<LevelCount>): LevelCount {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.levels = {};
        if (value !== undefined)
            reflectionMergePartial<LevelCount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LevelCount): LevelCount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, int32> levels */ 1:
                    this.binaryReadMap1(message.levels, reader, options);
                    break;
                case /* common.Error error */ 9:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: LevelCount["levels"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LevelCount["levels"] | undefined, val: LevelCount["levels"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field loyalty.LevelCount.levels");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: LevelCount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, int32> levels = 1; */
        for (let k of globalThis.Object.keys(message.levels))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.levels[k]).join();
        /* common.Error error = 9; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.LevelCount
 */
export const LevelCount = new LevelCount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisableLoyaltyMessage$Type extends MessageType<DisableLoyaltyMessage> {
    constructor() {
        super("loyalty.DisableLoyaltyMessage", [
            { no: 1, name: "disabled", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "disable_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<DisableLoyaltyMessage>): DisableLoyaltyMessage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.disabled = "";
        message.disableType = "";
        if (value !== undefined)
            reflectionMergePartial<DisableLoyaltyMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableLoyaltyMessage): DisableLoyaltyMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string disabled */ 1:
                    message.disabled = reader.string();
                    break;
                case /* string disable_type */ 2:
                    message.disableType = reader.string();
                    break;
                case /* common.Error error */ 3:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisableLoyaltyMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string disabled = 1; */
        if (message.disabled !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.disabled);
        /* string disable_type = 2; */
        if (message.disableType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.disableType);
        /* common.Error error = 3; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.DisableLoyaltyMessage
 */
export const DisableLoyaltyMessage = new DisableLoyaltyMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisableUserLoyaltyMessage$Type extends MessageType<DisableUserLoyaltyMessage> {
    constructor() {
        super("loyalty.DisableUserLoyaltyMessage", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "disabled", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<DisableUserLoyaltyMessage>): DisableUserLoyaltyMessage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = 0;
        message.disabled = "";
        if (value !== undefined)
            reflectionMergePartial<DisableUserLoyaltyMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableUserLoyaltyMessage): DisableUserLoyaltyMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string disabled */ 2:
                    message.disabled = reader.string();
                    break;
                case /* common.Error error */ 5:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisableUserLoyaltyMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string disabled = 2; */
        if (message.disabled !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.disabled);
        /* common.Error error = 5; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.DisableUserLoyaltyMessage
 */
export const DisableUserLoyaltyMessage = new DisableUserLoyaltyMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrdersBORequest$Type extends MessageType<GetOrdersBORequest> {
    constructor() {
        super("loyalty.GetOrdersBORequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "betting_place_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "bp", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOrdersBORequest>): GetOrdersBORequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = 0;
        message.bettingPlaceId = 0;
        message.status = "";
        message.offset = 0;
        message.bp = "";
        if (value !== undefined)
            reflectionMergePartial<GetOrdersBORequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrdersBORequest): GetOrdersBORequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* int32 betting_place_id */ 2:
                    message.bettingPlaceId = reader.int32();
                    break;
                case /* string status */ 3:
                    message.status = reader.string();
                    break;
                case /* int32 offset */ 5:
                    message.offset = reader.int32();
                    break;
                case /* string bp */ 4:
                    message.bp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOrdersBORequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* int32 betting_place_id = 2; */
        if (message.bettingPlaceId !== 0)
            writer.tag(2, WireType.Varint).int32(message.bettingPlaceId);
        /* string status = 3; */
        if (message.status !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.status);
        /* int32 offset = 5; */
        if (message.offset !== 0)
            writer.tag(5, WireType.Varint).int32(message.offset);
        /* string bp = 4; */
        if (message.bp !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.bp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.GetOrdersBORequest
 */
export const GetOrdersBORequest = new GetOrdersBORequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StoreItemOrdering$Type extends MessageType<StoreItemOrdering> {
    constructor() {
        super("loyalty.StoreItemOrdering", [
            { no: 1, name: "items", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } }
        ]);
    }
    create(value?: PartialMessage<StoreItemOrdering>): StoreItemOrdering {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = {};
        if (value !== undefined)
            reflectionMergePartial<StoreItemOrdering>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StoreItemOrdering): StoreItemOrdering {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, int32> items */ 1:
                    this.binaryReadMap1(message.items, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: StoreItemOrdering["items"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof StoreItemOrdering["items"] | undefined, val: StoreItemOrdering["items"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field loyalty.StoreItemOrdering.items");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: StoreItemOrdering, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, int32> items = 1; */
        for (let k of globalThis.Object.keys(message.items))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.items[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.StoreItemOrdering
 */
export const StoreItemOrdering = new StoreItemOrdering$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrdersRequest$Type extends MessageType<GetOrdersRequest> {
    constructor() {
        super("loyalty.GetOrdersRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "start", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "end", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOrdersRequest>): GetOrdersRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.offset = 0;
        message.start = 0;
        message.end = 0;
        message.code = "";
        message.status = "";
        if (value !== undefined)
            reflectionMergePartial<GetOrdersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrdersRequest): GetOrdersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 offset */ 1:
                    message.offset = reader.int32();
                    break;
                case /* int32 start */ 2:
                    message.start = reader.int32();
                    break;
                case /* int32 end */ 3:
                    message.end = reader.int32();
                    break;
                case /* string code */ 4:
                    message.code = reader.string();
                    break;
                case /* string status */ 5:
                    message.status = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOrdersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 offset = 1; */
        if (message.offset !== 0)
            writer.tag(1, WireType.Varint).int32(message.offset);
        /* int32 start = 2; */
        if (message.start !== 0)
            writer.tag(2, WireType.Varint).int32(message.start);
        /* int32 end = 3; */
        if (message.end !== 0)
            writer.tag(3, WireType.Varint).int32(message.end);
        /* string code = 4; */
        if (message.code !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.code);
        /* string status = 5; */
        if (message.status !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.GetOrdersRequest
 */
export const GetOrdersRequest = new GetOrdersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StoreItems$Type extends MessageType<StoreItems> {
    constructor() {
        super("loyalty.StoreItems", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StoreItem }
        ]);
    }
    create(value?: PartialMessage<StoreItems>): StoreItems {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<StoreItems>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StoreItems): StoreItems {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated loyalty.StoreItem items */ 1:
                    message.items.push(StoreItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StoreItems, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated loyalty.StoreItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            StoreItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.StoreItems
 */
export const StoreItems = new StoreItems$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StoreItem$Type extends MessageType<StoreItem> {
    constructor() {
        super("loyalty.StoreItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "price", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "image_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "reserved", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "digital", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "digital_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "digital_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 11, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<StoreItem>): StoreItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.description = "";
        message.price = 0;
        message.imageUrl = "";
        message.quantity = 0;
        message.reserved = 0;
        message.digital = false;
        message.digitalType = "";
        message.digitalAmount = 0;
        message.isActive = false;
        message.order = 0;
        if (value !== undefined)
            reflectionMergePartial<StoreItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StoreItem): StoreItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* double price */ 4:
                    message.price = reader.double();
                    break;
                case /* string image_url */ 5:
                    message.imageUrl = reader.string();
                    break;
                case /* int32 quantity */ 6:
                    message.quantity = reader.int32();
                    break;
                case /* int32 reserved */ 7:
                    message.reserved = reader.int32();
                    break;
                case /* bool digital */ 8:
                    message.digital = reader.bool();
                    break;
                case /* string digital_type */ 9:
                    message.digitalType = reader.string();
                    break;
                case /* double digital_amount */ 10:
                    message.digitalAmount = reader.double();
                    break;
                case /* bool is_active */ 11:
                    message.isActive = reader.bool();
                    break;
                case /* int32 order */ 12:
                    message.order = reader.int32();
                    break;
                case /* common.Error error */ 13:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StoreItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* double price = 4; */
        if (message.price !== 0)
            writer.tag(4, WireType.Bit64).double(message.price);
        /* string image_url = 5; */
        if (message.imageUrl !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.imageUrl);
        /* int32 quantity = 6; */
        if (message.quantity !== 0)
            writer.tag(6, WireType.Varint).int32(message.quantity);
        /* int32 reserved = 7; */
        if (message.reserved !== 0)
            writer.tag(7, WireType.Varint).int32(message.reserved);
        /* bool digital = 8; */
        if (message.digital !== false)
            writer.tag(8, WireType.Varint).bool(message.digital);
        /* string digital_type = 9; */
        if (message.digitalType !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.digitalType);
        /* double digital_amount = 10; */
        if (message.digitalAmount !== 0)
            writer.tag(10, WireType.Bit64).double(message.digitalAmount);
        /* bool is_active = 11; */
        if (message.isActive !== false)
            writer.tag(11, WireType.Varint).bool(message.isActive);
        /* int32 order = 12; */
        if (message.order !== 0)
            writer.tag(12, WireType.Varint).int32(message.order);
        /* common.Error error = 13; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.StoreItem
 */
export const StoreItem = new StoreItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Orders$Type extends MessageType<Orders> {
    constructor() {
        super("loyalty.Orders", [
            { no: 1, name: "orders", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Order }
        ]);
    }
    create(value?: PartialMessage<Orders>): Orders {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.orders = [];
        if (value !== undefined)
            reflectionMergePartial<Orders>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Orders): Orders {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated loyalty.Order orders */ 1:
                    message.orders.push(Order.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Orders, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated loyalty.Order orders = 1; */
        for (let i = 0; i < message.orders.length; i++)
            Order.internalBinaryWrite(message.orders[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.Orders
 */
export const Orders = new Orders$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Order$Type extends MessageType<Order> {
    constructor() {
        super("loyalty.Order", [
            { no: 1, name: "points", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrderItem },
            { no: 5, name: "created_at", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "betting_place_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<Order>): Order {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.points = 0;
        message.code = "";
        message.id = 0;
        message.userId = 0;
        message.items = [];
        message.createdAt = 0;
        message.status = "";
        message.bettingPlaceId = 0;
        if (value !== undefined)
            reflectionMergePartial<Order>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Order): Order {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double points */ 1:
                    message.points = reader.double();
                    break;
                case /* string code */ 2:
                    message.code = reader.string();
                    break;
                case /* int32 id */ 3:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 9:
                    message.userId = reader.int32();
                    break;
                case /* repeated loyalty.OrderItem items */ 4:
                    message.items.push(OrderItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 created_at */ 5:
                    message.createdAt = reader.int32();
                    break;
                case /* string status */ 6:
                    message.status = reader.string();
                    break;
                case /* int32 betting_place_id */ 7:
                    message.bettingPlaceId = reader.int32();
                    break;
                case /* common.Error error */ 8:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Order, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double points = 1; */
        if (message.points !== 0)
            writer.tag(1, WireType.Bit64).double(message.points);
        /* string code = 2; */
        if (message.code !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.code);
        /* int32 id = 3; */
        if (message.id !== 0)
            writer.tag(3, WireType.Varint).int32(message.id);
        /* int32 user_id = 9; */
        if (message.userId !== 0)
            writer.tag(9, WireType.Varint).int32(message.userId);
        /* repeated loyalty.OrderItem items = 4; */
        for (let i = 0; i < message.items.length; i++)
            OrderItem.internalBinaryWrite(message.items[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 created_at = 5; */
        if (message.createdAt !== 0)
            writer.tag(5, WireType.Varint).int32(message.createdAt);
        /* string status = 6; */
        if (message.status !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.status);
        /* int32 betting_place_id = 7; */
        if (message.bettingPlaceId !== 0)
            writer.tag(7, WireType.Varint).int32(message.bettingPlaceId);
        /* common.Error error = 8; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.Order
 */
export const Order = new Order$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrderItem$Type extends MessageType<OrderItem> {
    constructor() {
        super("loyalty.OrderItem", [
            { no: 1, name: "item", kind: "message", T: () => StoreItem },
            { no: 2, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<OrderItem>): OrderItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.quantity = 0;
        if (value !== undefined)
            reflectionMergePartial<OrderItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrderItem): OrderItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* loyalty.StoreItem item */ 1:
                    message.item = StoreItem.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                case /* int32 quantity */ 2:
                    message.quantity = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrderItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* loyalty.StoreItem item = 1; */
        if (message.item)
            StoreItem.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 quantity = 2; */
        if (message.quantity !== 0)
            writer.tag(2, WireType.Varint).int32(message.quantity);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.OrderItem
 */
export const OrderItem = new OrderItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebLoyaltyConfig$Type extends MessageType<WebLoyaltyConfig> {
    constructor() {
        super("loyalty.WebLoyaltyConfig", [
            { no: 5, name: "level_up_map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => LevelLimits } },
            { no: 6, name: "disabled_points", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "disabled_market", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<WebLoyaltyConfig>): WebLoyaltyConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.levelUpMap = {};
        message.disabledPoints = "";
        message.disabledMarket = "";
        if (value !== undefined)
            reflectionMergePartial<WebLoyaltyConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebLoyaltyConfig): WebLoyaltyConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, loyalty.LevelLimits> level_up_map */ 5:
                    this.binaryReadMap5(message.levelUpMap, reader, options);
                    break;
                case /* string disabled_points */ 6:
                    message.disabledPoints = reader.string();
                    break;
                case /* string disabled_market */ 7:
                    message.disabledMarket = reader.string();
                    break;
                case /* common.Error error */ 8:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: WebLoyaltyConfig["levelUpMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof WebLoyaltyConfig["levelUpMap"] | undefined, val: WebLoyaltyConfig["levelUpMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = LevelLimits.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field loyalty.WebLoyaltyConfig.level_up_map");
            }
        }
        map[key ?? ""] = val ?? LevelLimits.create();
    }
    internalBinaryWrite(message: WebLoyaltyConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, loyalty.LevelLimits> level_up_map = 5; */
        for (let k of globalThis.Object.keys(message.levelUpMap)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            LevelLimits.internalBinaryWrite(message.levelUpMap[k], writer, options);
            writer.join().join();
        }
        /* string disabled_points = 6; */
        if (message.disabledPoints !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.disabledPoints);
        /* string disabled_market = 7; */
        if (message.disabledMarket !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.disabledMarket);
        /* common.Error error = 8; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.WebLoyaltyConfig
 */
export const WebLoyaltyConfig = new WebLoyaltyConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoyaltyConfig$Type extends MessageType<LoyaltyConfig> {
    constructor() {
        super("loyalty.LoyaltyConfig", [
            { no: 1, name: "slip_coefficient_map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 2, name: "casino_coefficient", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "slip_level_map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 4, name: "casino_level_map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 5, name: "level_up_map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => LevelLimits } },
            { no: 6, name: "disabled_points", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "disabled_market", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<LoyaltyConfig>): LoyaltyConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.slipCoefficientMap = {};
        message.casinoCoefficient = "";
        message.slipLevelMap = {};
        message.casinoLevelMap = {};
        message.levelUpMap = {};
        message.disabledPoints = "";
        message.disabledMarket = "";
        if (value !== undefined)
            reflectionMergePartial<LoyaltyConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoyaltyConfig): LoyaltyConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> slip_coefficient_map */ 1:
                    this.binaryReadMap1(message.slipCoefficientMap, reader, options);
                    break;
                case /* string casino_coefficient */ 2:
                    message.casinoCoefficient = reader.string();
                    break;
                case /* map<string, string> slip_level_map */ 3:
                    this.binaryReadMap3(message.slipLevelMap, reader, options);
                    break;
                case /* map<string, string> casino_level_map */ 4:
                    this.binaryReadMap4(message.casinoLevelMap, reader, options);
                    break;
                case /* map<string, loyalty.LevelLimits> level_up_map */ 5:
                    this.binaryReadMap5(message.levelUpMap, reader, options);
                    break;
                case /* string disabled_points */ 6:
                    message.disabledPoints = reader.string();
                    break;
                case /* string disabled_market */ 7:
                    message.disabledMarket = reader.string();
                    break;
                case /* common.Error error */ 8:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: LoyaltyConfig["slipCoefficientMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LoyaltyConfig["slipCoefficientMap"] | undefined, val: LoyaltyConfig["slipCoefficientMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field loyalty.LoyaltyConfig.slip_coefficient_map");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    private binaryReadMap3(map: LoyaltyConfig["slipLevelMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LoyaltyConfig["slipLevelMap"] | undefined, val: LoyaltyConfig["slipLevelMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field loyalty.LoyaltyConfig.slip_level_map");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    private binaryReadMap4(map: LoyaltyConfig["casinoLevelMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LoyaltyConfig["casinoLevelMap"] | undefined, val: LoyaltyConfig["casinoLevelMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field loyalty.LoyaltyConfig.casino_level_map");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    private binaryReadMap5(map: LoyaltyConfig["levelUpMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LoyaltyConfig["levelUpMap"] | undefined, val: LoyaltyConfig["levelUpMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = LevelLimits.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field loyalty.LoyaltyConfig.level_up_map");
            }
        }
        map[key ?? ""] = val ?? LevelLimits.create();
    }
    internalBinaryWrite(message: LoyaltyConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> slip_coefficient_map = 1; */
        for (let k of globalThis.Object.keys(message.slipCoefficientMap))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.slipCoefficientMap[k]).join();
        /* string casino_coefficient = 2; */
        if (message.casinoCoefficient !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.casinoCoefficient);
        /* map<string, string> slip_level_map = 3; */
        for (let k of globalThis.Object.keys(message.slipLevelMap))
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.slipLevelMap[k]).join();
        /* map<string, string> casino_level_map = 4; */
        for (let k of globalThis.Object.keys(message.casinoLevelMap))
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.casinoLevelMap[k]).join();
        /* map<string, loyalty.LevelLimits> level_up_map = 5; */
        for (let k of globalThis.Object.keys(message.levelUpMap)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            LevelLimits.internalBinaryWrite(message.levelUpMap[k], writer, options);
            writer.join().join();
        }
        /* string disabled_points = 6; */
        if (message.disabledPoints !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.disabledPoints);
        /* string disabled_market = 7; */
        if (message.disabledMarket !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.disabledMarket);
        /* common.Error error = 8; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.LoyaltyConfig
 */
export const LoyaltyConfig = new LoyaltyConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LevelLimits$Type extends MessageType<LevelLimits> {
    constructor() {
        super("loyalty.LevelLimits", [
            { no: 1, name: "level_stay", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "level_up", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LevelLimits>): LevelLimits {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.levelStay = "";
        message.levelUp = "";
        if (value !== undefined)
            reflectionMergePartial<LevelLimits>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LevelLimits): LevelLimits {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string level_stay */ 1:
                    message.levelStay = reader.string();
                    break;
                case /* string level_up */ 2:
                    message.levelUp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LevelLimits, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string level_stay = 1; */
        if (message.levelStay !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.levelStay);
        /* string level_up = 2; */
        if (message.levelUp !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.levelUp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.LevelLimits
 */
export const LevelLimits = new LevelLimits$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserLoyalty$Type extends MessageType<UserLoyalty> {
    constructor() {
        super("loyalty.UserLoyalty", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "points", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "level_points", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "loyalty_level", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "user_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "order", kind: "message", T: () => Order },
            { no: 7, name: "expire_next", kind: "message", T: () => ExpireAt },
            { no: 8, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<UserLoyalty>): UserLoyalty {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = "";
        message.points = 0;
        message.levelPoints = 0;
        message.loyaltyLevel = "";
        message.userDisabled = false;
        message.username = "";
        if (value !== undefined)
            reflectionMergePartial<UserLoyalty>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserLoyalty): UserLoyalty {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                case /* double points */ 2:
                    message.points = reader.double();
                    break;
                case /* double level_points */ 3:
                    message.levelPoints = reader.double();
                    break;
                case /* string loyalty_level */ 4:
                    message.loyaltyLevel = reader.string();
                    break;
                case /* bool user_disabled */ 5:
                    message.userDisabled = reader.bool();
                    break;
                case /* loyalty.Order order */ 6:
                    message.order = Order.internalBinaryRead(reader, reader.uint32(), options, message.order);
                    break;
                case /* loyalty.ExpireAt expire_next */ 7:
                    message.expireNext = ExpireAt.internalBinaryRead(reader, reader.uint32(), options, message.expireNext);
                    break;
                case /* string username */ 8:
                    message.username = reader.string();
                    break;
                case /* common.Error error */ 9:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserLoyalty, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        /* double points = 2; */
        if (message.points !== 0)
            writer.tag(2, WireType.Bit64).double(message.points);
        /* double level_points = 3; */
        if (message.levelPoints !== 0)
            writer.tag(3, WireType.Bit64).double(message.levelPoints);
        /* string loyalty_level = 4; */
        if (message.loyaltyLevel !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.loyaltyLevel);
        /* bool user_disabled = 5; */
        if (message.userDisabled !== false)
            writer.tag(5, WireType.Varint).bool(message.userDisabled);
        /* loyalty.Order order = 6; */
        if (message.order)
            Order.internalBinaryWrite(message.order, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* loyalty.ExpireAt expire_next = 7; */
        if (message.expireNext)
            ExpireAt.internalBinaryWrite(message.expireNext, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string username = 8; */
        if (message.username !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.username);
        /* common.Error error = 9; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.UserLoyalty
 */
export const UserLoyalty = new UserLoyalty$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExpireAt$Type extends MessageType<ExpireAt> {
    constructor() {
        super("loyalty.ExpireAt", [
            { no: 1, name: "time", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "points", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ExpireAt>): ExpireAt {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.time = 0;
        message.points = "";
        if (value !== undefined)
            reflectionMergePartial<ExpireAt>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExpireAt): ExpireAt {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 time */ 1:
                    message.time = reader.int32();
                    break;
                case /* string points */ 2:
                    message.points = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExpireAt, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 time = 1; */
        if (message.time !== 0)
            writer.tag(1, WireType.Varint).int32(message.time);
        /* string points = 2; */
        if (message.points !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.points);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message loyalty.ExpireAt
 */
export const ExpireAt = new ExpireAt$Type();
/**
 * @generated ServiceType for protobuf service loyalty.LoyaltyBOService
 */
export const LoyaltyBOService = new ServiceType("loyalty.LoyaltyBOService", [
    { name: "GetLoyaltyConfig", options: {}, I: Empty, O: LoyaltyConfig },
    { name: "SetLoyaltyConfig", options: {}, I: LoyaltyConfig, O: LoyaltyConfig },
    { name: "DisableLoyalty", options: {}, I: DisableLoyaltyMessage, O: DisableLoyaltyMessage },
    { name: "DisableUserLoyalty", options: {}, I: DisableUserLoyaltyMessage, O: DisableUserLoyaltyMessage },
    { name: "GetUserLoyaltyBO", options: {}, I: UserLoyalty, O: UserLoyalty },
    { name: "CreateStoreItem", options: {}, I: StoreItem, O: StoreItem },
    { name: "UpdateStoreItem", options: {}, I: StoreItem, O: StoreItem },
    { name: "DeleteStoreItem", options: {}, I: StoreItem, O: StoreItem },
    { name: "UpdateStoreItemOrdering", options: {}, I: StoreItemOrdering, O: EmptyError },
    { name: "GetAllStoreItems", options: {}, I: Empty, O: StoreItems },
    { name: "GetOrdersBO", options: {}, I: GetOrdersBORequest, O: Orders },
    { name: "GetSingleOrderBO", options: {}, I: Order, O: Order },
    { name: "ApproveOrder", options: {}, I: Order, O: Order },
    { name: "DeliverOrder", options: {}, I: Order, O: Order },
    { name: "ConfirmOrder", options: {}, I: Order, O: Order },
    { name: "CancelOrderBO", options: {}, I: Order, O: Order },
    { name: "GetUsersBO", options: {}, I: GetUsersRequest, O: Users },
    { name: "GetLevelCount", options: {}, I: Empty, O: LevelCount },
    { name: "GetUserTransactions", options: {}, I: UserTransactionsRequest, O: Transactions }
]);
/**
 * @generated ServiceType for protobuf service loyalty.LoyaltyWebService
 */
export const LoyaltyWebService = new ServiceType("loyalty.LoyaltyWebService", [
    { name: "GetLoyalty", options: {}, I: Empty, O: UserLoyalty },
    { name: "GetStoreItems", options: {}, I: Empty, O: StoreItems },
    { name: "PlaceOrder", options: {}, I: Order, O: Order },
    { name: "CancelOrder", options: {}, I: Order, O: Order },
    { name: "GetOrders", options: {}, I: GetOrdersRequest, O: Orders },
    { name: "GetSingleOrder", options: {}, I: Order, O: Order },
    { name: "GetWebLoyaltyConfig", options: {}, I: Empty, O: WebLoyaltyConfig }
]);
