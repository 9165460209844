/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import SectionHeader from 'modules/user/ui/SectionHeader';
import TransactionsFilter from 'modules/user/ui/UserTransactions/TransactionsFilter';
import TransactionsTable from 'modules/user/ui/UserTransactions/TransactionsTable';
import TransactionsMobile from 'modules/user/ui/UserTransactions/TransactionsMobile';
import TransactionsMobileFilter from 'modules/user/ui/UserTransactions/TransactionsMobile/TransactionsMobileFilter';
import { LoaderWrapper } from 'components/Loader';
import Responsive from 'components/Responsive';
import { formatDateQuery, getOneMonthAgo } from 'libs/datetime';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export interface SelectOption {
  label: string;
  value: string;
}

type FilterDate = Date | null;

const UserTransactions = observer(() => {
  const { t } = useTranslation();
  const {
    userStore: { transactionFilters },
  } = useStores();

  const { setFilter } = transactionFilters;
  const { startDate: defaultStartDate, endDate: defaultEndDate } =
    getOneMonthAgo();
  const [dateRange, setDateRange] = useState<[FilterDate, FilterDate]>([
    defaultStartDate,
    defaultEndDate,
  ]);
  const [startDate, endDate] = dateRange;

  const onDateFilterChange = (range: [FilterDate, FilterDate]) => {
    setDateRange(range);
    if (range[0] && range[1]) {
      setFilter({
        from_date: formatDateQuery(range[0]),
        to_date: formatDateQuery(range[1]),
      });
    } else if (!range[0] && !range[1]) {
      setFilter({ from_date: null, to_date: null });
    }
  };

  const onTypeChange = (e: SelectOption) => {
    const filterValue = e.value === 'all' ? null : e.value;
    setFilter({ transaction_type: filterValue });
  };

  useEffect(() => {
    transactionFilters.getTransactions();

    return () => {
      transactionFilters.reset();
    };
  }, []);

  return (
    <div className="max-height">
      <SectionHeader title={t('payments.transactions')} />
      <TransactionsFilter
        onTypeChange={onTypeChange}
        onDateChange={onDateFilterChange}
        startDate={startDate}
        endDate={endDate}
      />
      <TransactionsMobileFilter
        onTypeChange={onTypeChange}
        onDateChange={onDateFilterChange}
        startDate={startDate}
        endDate={endDate}
      />
      <LoaderWrapper name="userTransactions">
        <Responsive
          toLaptop={<TransactionsMobile />}
          fromDesktop={<TransactionsTable />}
          display="block"
        />
      </LoaderWrapper>
    </div>
  );
});

export default UserTransactions;
