/* React modules */
import { useEffect, useCallback } from 'react';

/* Our modules */
import { OrderStatus } from 'modules/user/market.types';
import useStores from 'common/hooks/useStores';
import { SimpleTable, Icon } from 'components';
import Responsive from 'components/Responsive';
import SectionHeader from 'modules/user/ui/SectionHeader';
import { HistoryMobile } from 'modules/user/ui/UserMarket/OrderHistory/HistoryMobile/HistoryMobile';
import { SingleOrderModal } from 'modules/user/ui/UserMarket/OrderHistory/SingleOrderModal/SingleOrderModal';
import { formatDate, toDate } from 'libs/datetime';
import { logger } from 'libs/common-helpers';
import './OrderHistory.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const MarketHistoryList = observer(() => {
  const { t } = useTranslation();
  const { marketStore, overlayStore, bettingPlacesStore } = useStores();
  const { getOrders, hasOrders, fetchOrders, resetOrders, hasMoreOrders } =
    marketStore;
  const { bettingPlacesList } = bettingPlacesStore;

  const STATUS_DISPLAY: any = {
    [OrderStatus.PENDING]: {
      label: t('globals.pending'),
      color: 'orange-800',
    },
    [OrderStatus.SUCCESS]: {
      label: t('globals.success'),
      color: 'green',
    },
    [OrderStatus.DELIVERED]: {
      label: t('globals.delivered'),
      color: 'green',
    },
    [OrderStatus.APPROVED]: {
      label: t('globals.approved'),
      color: 'green',
    },
    [OrderStatus.CANCELED]: {
      label: t('globals.canceled'),
      color: 'red',
    },
    default: {
      label: '',
      bg: 'green',
    },
  };

  useEffect(() => {
    resetOrders();
    fetchOrders();
  }, []);

  const loadMore = () => {
    if (hasMoreOrders) {
      fetchOrders();
    }
  };

  const openSingleOrder = () => {
    try {
      overlayStore.openModal(<SingleOrderModal />, {
        width: 'large',
      });
    } catch (exception: any) {
      logger('MarketHistoryList -> openSingleOrder -> exception', exception);

      return null;
    }
  };

  const renderStatus = (field: any) => {
    const ticketStatus = STATUS_DISPLAY[field.value] || STATUS_DISPLAY.default;

    return (
      <div className={`uppercase text-${ticketStatus.color}`}>
        {ticketStatus.label}
      </div>
    );
  };

  const renderOrderDate = (field: any) => {
    return formatDate(toDate(field.value));
  };

  const renderAddress = (field: any) => {
    const orderAddress = bettingPlacesList?.find(
      (place: any) => place.id === field.value
    );
    if (orderAddress) {
      return `${orderAddress.address}, ${orderAddress.municipalityName}`;
    }

    return '/';
  };

  const columns = [
    { accessor: 'id', Header: 'ID' },
    {
      accessor: 'createdAt',
      Header: t('globals.date-caps'),
      Cell: renderOrderDate,
    },
    { accessor: 'code', Header: t('userMarket.code') },
    {
      accessor: 'bettingPlaceId',
      Header: t('userMarket.place'),
      Cell: renderAddress,
    },
    {
      accessor: 'status',
      Header: t('globals.status-caps'),
      Cell: renderStatus,
    },
    {
      id: 'search',
      Header: '',
      Cell: ({ row }: any) => <Icon name="search" />,
    },
  ];

  const noOrders = (
    <div
      className="d-flex justify-center align-items text-white sb-heading"
      style={{ height: '300px' }}
    >
      NEMA PORUDZBINA
    </div>
  );

  return (
    <Responsive
      display="block"
      fromDesktop={
        hasOrders ? (
          <SimpleTable
            columns={columns}
            data={getOrders}
            loadMore={loadMore}
            tableFooter={null}
            onRowClick={openSingleOrder}
          />
        ) : (
          noOrders
        )
      }
      toLaptop={
        hasOrders ? (
          <HistoryMobile loadMore={loadMore} openOrder={() => null} />
        ) : (
          noOrders
        )
      }
    />
  );
});

const OrderHistory = observer(() => {
  const { t } = useTranslation();
  const { bettingPlacesStore } = useStores();

  const init = useCallback(async () => {
    await bettingPlacesStore.getBettingPlaces();
  }, [bettingPlacesStore]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="market-history">
      <div className="max-height">
        <SectionHeader title={t('globals.order-history')} />
        <MarketHistoryList />
      </div>
    </div>
  );
});

export default OrderHistory;
