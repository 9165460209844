/* React modules */

/* Our modules */
import {
  StoreItem,
  Order,
  UserLoyalty,
  WebLoyaltyConfig,
} from 'generated-proto/proto/loyalty/loyalty_pb';
import { OrderError, CartItem } from 'modules/user/market.types';
import { MarketAPI } from 'modules/user/market.api';
import authStore from 'modules/auth/auth.store';
import overlayStore from 'libs/overlay-store';
import { logger } from 'libs/common-helpers';

/* 3rd Party modules */
import { makeAutoObservable, runInAction } from 'mobx';
import i18next from 'i18next';
import { toast } from 'react-toastify';

class MarketStore {
  api: MarketAPI;

  storeItems: StoreItem[] = [];
  orders: Order[] = [];
  singleOrder: Order | null = null;
  paginationOffset: number = 0;
  loyaltyConfig: WebLoyaltyConfig | null = null;
  loyalty: UserLoyalty | null = null;
  statusMarket: string = 'disabled';
  statusPoints: string = 'disabled';
  disabledLoyalty: boolean = true;

  cart: CartItem[] = [];
  hasMore: boolean = true;

  constructor() {
    this.api = new MarketAPI();

    makeAutoObservable(this);
  }

  get getSingleOrder() {
    return this.singleOrder;
  }

  get getStoreItems() {
    return this.storeItems;
  }

  get hasOrders() {
    return !!this.orders.length;
  }

  get hasMoreOrders() {
    return this.hasMore;
  }

  get getOrders() {
    return this.orders;
  }

  get cartCount() {
    return this.cart.length;
  }

  get getLoyalty() {
    return this.loyalty;
  }

  get getLoyaltyConfig() {
    return this.loyaltyConfig;
  }

  get totalPointsAmount() {
    let totalAmount = 0;

    this.cart.forEach((item: any) => {
      totalAmount += item.price;
    });

    return totalAmount;
  }

  get getCart() {
    return this.cart;
  }

  get isDigital() {
    return this.getCart.some((item: any) => item.digital);
  }

  get marketStatus() {
    return this.statusMarket;
  }

  get pointsStatus() {
    return this.statusPoints;
  }

  get isDisabledLoyalty() {
    return this.disabledLoyalty;
  }

  emptyCart = () => {
    this.cart = [];
  };

  placeOrder = async (payload: any) => {
    try {
      const {
        response: { error },
      } = await this.api.placeOrder(payload, authStore.token);

      if (!error) {
        toast.success(i18next.t('userMarket.order-success'));
        overlayStore.closeModal();
      } else {
        const { message } = error;

        if ((message || []).length) {
          switch (message[0]) {
            case OrderError.ORDER_ALREADY_EXISTS:
              toast.error(i18next.t('errors.another-order-exists'));
              break;
            case OrderError.NO_BETTING_PLACE_ID:
              toast.error(i18next.t('errors.choose-betting-place'));
              break;
            case OrderError.NOT_ENOUGH_POINTS:
              toast.error(i18next.t('errors.not-enough-points'));
              break;
            case OrderError.ITEM_NOT_AVAILABLE:
              toast.error(i18next.t('errors.item-not-available'));
              break;
            case OrderError.ITEM_NOT_FOUND:
              toast.error(i18next.t('errors.item-not-found'));
              break;
            case OrderError.CANNOT_ORDER_MIXED_ITEMS:
              toast.error(i18next.t('errors.cannot-order-mixed-items'));
              break;
            case OrderError.NO_ITEMS_IN_ORDER:
              toast.error(i18next.t('errors.no-items-in-order'));
              break;
            case OrderError.ZERO_ITEM_QUANTITY:
              toast.error(i18next.t('errors.zero-item-quantity'));
              break;
            case OrderError.CANNOT_ORDER_MORE_THAN_ONE_DIGITAL_ITEM_TYPE:
              toast.error(
                i18next.t('errors.cannot-order-more-than-one-digital-item')
              );
              break;
            default:
              toast.error(i18next.t('errors.system-error'));
          }
        }
      }
    } catch (exception: any) {
      toast.error(i18next.t('errors.system-error'));

      logger('MarketStore -> placeOrder -> exception', exception);
    } finally {
      this.fetchLoyalty();
    }
  };

  addToCart = (item: CartItem) => {
    this.cart.push(item);
  };

  removeFromCart = (id: number) => {
    this.cart = this.cart.filter((item: CartItem) => item.id !== id);
  };

  fetchStoreItems = async () => {
    try {
      if (authStore.token) {
        const {
          response: { items },
        } = await this.api.getStoreItems(authStore.token);

        runInAction(() => {
          this.storeItems = items;
        });
      }
    } catch (exception: any) {
      logger('MarketStore -> fetchStoreItems -> exception', exception);
    }
  };

  fetchOrders = async () => {
    try {
      const {
        response: { orders },
      } = await this.api.getOrders(this.paginationOffset, authStore.token);

      runInAction(() => {
        this.orders = [...this.orders, ...(orders || [])];

        if ((orders || []).length < 50) {
          this.hasMore = false;
          this.paginationOffset = 0;
        } else {
          this.paginationOffset += 50;
        }
      });
    } catch (exception: any) {
      logger('MarketStore -> fetchOrders -> exception', exception);
    }
  };

  fetchLoyalty = async () => {
    try {
      if (authStore.token) {
        const { response } = await this.api.getLoyalty(authStore.token);

        runInAction(() => {
          this.loyalty = response;

          this.disabledLoyalty = response.userDisabled;
        });
      }
    } catch (exception: any) {
      logger('MarketStore -> fetchLoyalty -> exception', exception);
    }
  };

  fetchLoyaltyConfig = async () => {
    const { response } = await this.api.getLoyaltyConfig(authStore.token);

    runInAction(() => {
      this.loyaltyConfig = response;

      this.statusMarket = response.disabledMarket;
      this.statusPoints = response.disabledPoints;
    });
  };

  resetOrders = () => {
    this.orders = [];
    this.paginationOffset = 0;
  };

  fetchSingleOrder = async (orderId: number) => {
    try {
      const { response } = await this.api.getSingleOrder(
        orderId,
        authStore.token
      );

      runInAction(() => {
        if (response) {
          const { error } = response;

          if (error) {
            const { message } = error;

            if ((message || []).length) {
              if (message[0] === OrderError.ORDER_DOES_NOT_EXIST) {
                toast.error(i18next.t('errors.order-does-not-exist'));
              }
            }
          }
        }

        this.singleOrder = response;
      });
    } catch (exception: any) {
      toast.error(i18next.t('errors.system-error'));

      logger('MarketStore -> fetchSingleOrder -> exception', exception);
    }
  };

  cancelOrder = async (orderId: number) => {
    try {
      const { response } = await this.api.cancelOrder(orderId, authStore.token);

      runInAction(() => {
        if (response) {
          const { error } = response;

          if (!error) {
            toast.success(i18next.t('userMarket.successfuly-canceled-order'));

            this.resetOrders();
            this.fetchOrders();
            this.fetchSingleOrder(orderId);
          } else {
            const { message } = error;

            if ((message || []).length) {
              if (message[0] === OrderError.CANNOT_CANCEL_ORDER) {
                toast.error(i18next.t('errors.cannot-cancel-order'));
              }
            }
          }
        }

        this.singleOrder = response;
      });
    } catch (exception: any) {
      toast.error(i18next.t('errors.system-error'));

      logger('MarketStore -> cancelOrder -> exception', exception);
    }
  };
}

export default new MarketStore();
