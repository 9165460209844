// @ts-nocheck
/* React modules */

/* Our modules */
import { Outcome } from 'modules/sports/sports.types';
import Event from 'modules/sports/store/event.store';
import Odd from 'modules/sports/store/odd.store';
import { basketballPlayerMarkets } from 'libs/market-constants';
import { months, engMonths } from 'libs/datetime';

/* 3rd Party modules */

/**
 *
 * @description
 * Purpose of this function is to filter outcomes for antepost events
 * that will never have odds. For example antepost markets have outcome "NE",
 * but events will never have odd for this outcome, so it sohuldn't be displayed.
 *
 * @param markets - list of antepost markets
 * @param events - antepost events
 * @returns List of markets with filtered outcomes.
 */
export const filterAntepostOutcomes = (markets: any[], events: Event[]) => {
  return markets.map((market: any) => {
    const marketCopy = Object.create(Object.getPrototypeOf(market));

    return Object.assign(marketCopy, market, {
      outcomes: market.outcomes.filter((outcome: any) => {
        return events.some((event: any) => {
          const outcomeOdd = event.rawOddsList.find(
            (odd: any) => odd.outcomeId === outcome.id
          );
          return !!outcomeOdd && outcomeOdd.value > 1;
        });
      }),
    });
  });
};

/**
 *
 * @param odds - list of event odds
 * @param lang - used to set name of the market based on selected language
 * @returns list of markets that have odds
 */
export const formatMarkets = (odds: Odd[], lang: string = 'me') => {
  return odds.reduce((markets: any[], odd: Odd) => {
    const market = markets.find((m: any) => m.id === odd.market?.id);

    if (market) {
      market.odds = [...market.odds, odd];
      return markets;
    }

    const newMarket = {
      market: odd.market?.getName(lang),
      id: odd.market?.id,
      odds: [odd],
    };

    return [...markets, newMarket];
  }, []);
};

/**
 *
 * @param odds
 * @returns object where keys are limits and values are odds with that limit
 */
export const formatOddsByLimit = (odds: Odd[]): any => {
  odds.sort((a: any, b: any) => a.isDisabled - b.isDisabled);

  return odds.reduce((limitGroups: any, odd: Odd) => {
    const limit = odd.limit || 0;

    return {
      ...limitGroups,
      [limit]: [...(limitGroups[limit] || []), odd],
    };
  }, {});
};

export const formatMarketsByFrames = (markets: any) => {
  let eventMarkets: any = [];

  markets.forEach((market: any) => {
    const hasFrames = market.odds.some((odd: any) => !!odd.frameNumber);
    const hasLimit = market.odds.some((odd: any) => !!odd.limit);

    if (hasFrames) {
      let frames: any = [];
      frames = market.odds.map((odd: any) => {
        if (odd.frameNumber) return odd.frameNumber;
      });
      frames = frames.filter(
        (item: any, i: any, ar: any) => ar.indexOf(item) === i
      );
      frames.sort((a: any, b: any) => (a > b ? 1 : -1));
      frames.forEach((frame: number) => {
        eventMarkets.push({
          ...market,
          name: `${market.name} ${frame}`,
          englishName: `${market.englishName} ${frame}`,
          albanianName: `${market.albanianName} ${frame}`,
          turkishName: `${market.turkishName} ${frame}`,
          russianName: `${market.russianName} ${frame}`,
          ukrainianName: `${market.ukrainianName} ${frame}`,
          italianName: `${market.italianName} ${frame}`,
          germanName: `${market.germanName} ${frame}`,
          odds: market.odds.filter((odd: any) => odd.frameNumber === frame),
          hasLimit,
          hasFrames,
          hasPlayer: false,
          getName: function (lang) {
            return market.name;
          },
        });
      });
    } else if ((!hasFrames && !hasLimit) || (hasLimit && !hasFrames)) {
      eventMarkets.push({
        ...market,
        hasLimit,
        hasFrames: false,
        hasPlayer: false,
        getName: function (lang) {
          return market.name;
        },
      });
    }
  });

  return eventMarkets;
};

export const formatMarketsByPlayer = (markets: any) => {
  let eventMarkets: any = [];

  markets.forEach((market: any) => {
    const hasPlayer = market.odds.some((odd: any) => !!odd.livePlayer);
    const hasLimit = market.odds.some((odd: any) => !!odd.limit);

    if (hasPlayer) {
      let players: any = [];
      players = market.odds.map((odd: any) => {
        if (odd.livePlayer) return odd.livePlayer;
      });
      players = players.filter(
        (item: any, i: any, ar: any) => ar.indexOf(item) === i
      );
      players.sort((a: any, b: any) => (a > b ? 1 : -1));
      players.forEach((player: number) => {
        eventMarkets.push({
          ...market,
          name: `${market.name} - ${player}`,
          englishName: `${market.englishName} - ${player}`,
          albanianName: `${market.albanianName} - ${player}`,
          turkishName: `${market.turkishName} - ${player}`,
          russianName: `${market.russianName} - ${player}`,
          ukrainianName: `${market.ukrainianName} - ${player}`,
          italianName: `${market.italianName} - ${player}`,
          germanName: `${market.germanName} - ${player}`,
          odds: market.odds.filter((odd: any) => odd.livePlayer === player),
          hasLimit,
          hasFrames: false,
          hasPlayer,
          getName: function (lang) {
            return `${market.name} - ${player}`;
          },
        });
      });
    } else if ((!hasPlayer && !hasLimit) || (hasLimit && !hasPlayer)) {
      eventMarkets.push({
        ...market,
        hasLimit,
        hasPlayer: false,
        hasFrames: false,
        getName: function (lang) {
          return market.name;
        },
      });
    }
  });

  return eventMarkets;
};

/**
 *
 * @param events list of all events with all outcomes
 * @returns array where keys are indexes and values are outcome names
 */
export const formatFootballPlayerOutcomes = (events: Event[]) => {
  let footballOutcomes: Outcome[] = [];

  (events || []).forEach((event) => {
    (event.odds || []).forEach((element) => {
      if (
        element.outcome &&
        !footballOutcomes.includes(element.outcome) &&
        element.outcome?.name !== ''
      ) {
        footballOutcomes.push(element.outcome);
      }
    });
  });

  footballOutcomes.sort((a, b) => (a.name > b.name ? 1 : -1));

  return footballOutcomes;
};

export const filterBasketballPlayerMarkets = (markets: any) => {
  return markets.filter((market: any) =>
    basketballPlayerMarkets.includes(market.id)
  );
};

export const getSportEventsCount = (
  sport: any,
  offerCounters: any,
  timeFilter: number
) => {
  let eventsCount = 0;
  const sportMap = offerCounters.get(String(sport.id));
  if (!sportMap) return;
  const currentSport = new Map(sportMap.locationsMap);

  for (const [sportId, sportInstance] of currentSport) {
    const competitionsMap = new Map(sportInstance.competitionsMap);

    for (const [locationId, location] of competitionsMap) {
      if (
        location.live ||
        location.prematch ||
        location.g ||
        location.o ||
        location.p
      ) {
        switch (timeFilter) {
          case 72:
            eventsCount += location?.live?.d3 || 0;
            eventsCount += location?.prematch?.d3 || 0;
            eventsCount += location?.g?.d3 || 0;
            eventsCount += location?.o?.d3 || 0;
            eventsCount += location?.p?.d3 || 0;
            break;

          case 24:
            eventsCount += location?.live?.d1 || 0;
            eventsCount += location?.prematch?.d1 || 0;
            eventsCount += location?.g?.d1 || 0;
            eventsCount += location?.o?.d1 || 0;
            eventsCount += location?.p?.d1 || 0;
            break;

          case 3:
            eventsCount += location?.live?.h3 || 0;
            eventsCount += location?.prematch?.h3 || 0;
            eventsCount += location?.g?.h3 || 0;
            eventsCount += location?.o?.h3 || 0;
            eventsCount += location?.p?.h3 || 0;
            break;

          case 1:
            eventsCount += location?.live?.h1 || 0;
            eventsCount += location?.prematch?.h1 || 0;
            eventsCount += location?.g?.h1 || 0;
            eventsCount += location?.o?.h1 || 0;
            eventsCount += location?.p?.h1 || 0;
            break;

          default:
            eventsCount += location?.live?.all || 0;
            eventsCount += location?.prematch?.all || 0;
            eventsCount += location?.g?.all || 0;
            eventsCount += location?.o?.all || 0;
            eventsCount += location?.p?.all || 0;
        }
      }
    }
  }
  return eventsCount;
};

export const getLocationEventsCount = (
  sportId: any,
  location: any,
  offerCounters: any,
  timeFilter: number
) => {
  let eventsCount = 0;
  const sportMap = offerCounters.get(String(sportId));
  if (!sportMap) return;
  const currentSport = new Map(sportMap.locationsMap);

  const locationMap = currentSport.get(String(location.id));
  if (!locationMap) return;
  const currentLocation = new Map(locationMap.competitionsMap);

  for (const [competitionId, competition] of currentLocation) {
    if (
      competition.live ||
      competition.prematch ||
      competition.g ||
      competition.o ||
      competition.p
    ) {
      switch (timeFilter) {
        case 72:
          eventsCount += competition?.live?.d3 || 0;
          eventsCount += competition?.prematch?.d3 || 0;
          eventsCount += competition?.g?.d3 || 0;
          eventsCount += competition?.o?.d3 || 0;
          eventsCount += competition?.p?.d3 || 0;
          break;

        case 24:
          eventsCount += competition?.live?.d1 || 0;
          eventsCount += competition?.prematch?.d1 || 0;
          eventsCount += competition?.g?.d1 || 0;
          eventsCount += competition?.o?.d1 || 0;
          eventsCount += competition?.p?.d1 || 0;
          break;

        case 3:
          eventsCount += competition?.live?.h3 || 0;
          eventsCount += competition?.prematch?.h3 || 0;
          eventsCount += competition?.g?.h3 || 0;
          eventsCount += competition?.o?.h3 || 0;
          eventsCount += competition?.p?.h3 || 0;
          break;

        case 1:
          eventsCount += competition?.live?.h1 || 0;
          eventsCount += competition?.prematch?.h1 || 0;
          eventsCount += competition?.g?.h1 || 0;
          eventsCount += competition?.o?.h1 || 0;
          eventsCount += competition?.p?.h1 || 0;
          break;

        default:
          eventsCount += competition?.prematch?.all || 0;
          eventsCount += competition?.g?.all || 0;
          eventsCount += competition?.o?.all || 0;
          eventsCount += competition?.p?.all || 0;
      }
    }
  }
  return eventsCount;
};

export const getCompetitionEventsCount = (
  sportId: any,
  locationId: any,
  competitionId,
  offerCounters: any,
  timeFilter: number
) => {
  let eventsCount = 0;
  const sportMap = offerCounters.get(String(sportId));
  if (!sportMap) return;
  const currentSport = new Map(sportMap.locationsMap);

  const locationMap = currentSport.get(String(locationId));
  if (!locationMap) return;
  const currentLocation = new Map(locationMap.competitionsMap);

  const competitionMap = currentLocation.get(String(competitionId));
  if (!competitionMap) return;
  const currentCompetition = new Map(locationMap.competitionsMap);

  for (const [id, competition] of currentCompetition) {
    if (Number(id) === Number(competitionId)) {
      if (
        competition.live ||
        competition.prematch ||
        competition.g ||
        competition.o ||
        competition.p
      ) {
        switch (timeFilter) {
          case 72:
            eventsCount += competition?.live?.d3 || 0;
            eventsCount += competition?.prematch?.d3 || 0;
            eventsCount += competition?.g?.d3 || 0;
            eventsCount += competition?.o?.d3 || 0;
            eventsCount += competition?.p?.d3 || 0;
            break;

          case 24:
            eventsCount += competition?.live?.d1 || 0;
            eventsCount += competition?.prematch?.d1 || 0;
            eventsCount += competition?.g?.d1 || 0;
            eventsCount += competition?.o?.d1 || 0;
            eventsCount += competition?.p?.d1 || 0;
            break;

          case 3:
            eventsCount += competition?.live?.h3 || 0;
            eventsCount += competition?.prematch?.h3 || 0;
            eventsCount += competition?.g?.h3 || 0;
            eventsCount += competition?.o?.h3 || 0;
            eventsCount += competition?.p?.h3 || 0;
            break;

          case 1:
            eventsCount += competition?.live?.h1 || 0;
            eventsCount += competition?.prematch?.h1 || 0;
            eventsCount += competition?.g?.h1 || 0;
            eventsCount += competition?.o?.h1 || 0;
            eventsCount += competition?.p?.h1 || 0;
            break;

          default:
            eventsCount += competition?.prematch?.all || 0;
            eventsCount += competition?.g?.all || 0;
            eventsCount += competition?.o?.all || 0;
            eventsCount += competition?.p?.all || 0;
        }
      }
    }
  }
  return eventsCount;
};

export const getEventsCountPerType = (
  sportId: any,
  offerCounters: any,
  timeFilter: number,
  type: string
) => {
  let eventsCount = 0;
  const sportMap = offerCounters.get(String(sportId));
  if (!sportMap) return;

  const currentSport = new Map(sportMap.locationsMap);
  for (const [sportId, sportInstance] of currentSport) {
    const competitionsMap = new Map(sportInstance.competitionsMap);

    for (const [locationId, location] of competitionsMap) {
      if (
        location.live ||
        location.prematch ||
        location.g ||
        location.o ||
        location.p
      ) {
        if (type === 'all') {
          switch (timeFilter) {
            case 72:
              eventsCount += location?.live?.d3 || 0;
              eventsCount += location?.prematch?.d3 || 0;
              eventsCount += location?.g?.d3 || 0;
              eventsCount += location?.o?.d3 || 0;
              eventsCount += location?.p?.d3 || 0;
              break;

            case 24:
              eventsCount += location?.live?.d1 || 0;
              eventsCount += location?.prematch?.d1 || 0;
              eventsCount += location?.g?.d1 || 0;
              eventsCount += location?.o?.d1 || 0;
              eventsCount += location?.p?.d1 || 0;
              break;

            case 3:
              eventsCount += location?.live?.h3 || 0;
              eventsCount += location?.prematch?.h3 || 0;
              eventsCount += location?.g?.h3 || 0;
              eventsCount += location?.o?.h3 || 0;
              eventsCount += location?.p?.h3 || 0;
              break;

            case 1:
              eventsCount += location?.live?.h1 || 0;
              eventsCount += location?.prematch?.h1 || 0;
              eventsCount += location?.g?.h1 || 0;
              eventsCount += location?.o?.h1 || 0;
              eventsCount += location?.p?.h1 || 0;
              break;

            default:
              eventsCount += location?.live?.all || 0;
              eventsCount += location?.prematch?.all || 0;
              eventsCount += location?.g?.all || 0;
              eventsCount += location?.o?.all || 0;
              eventsCount += location?.p?.all || 0;
          }
        }

        if (type === 'live') {
          switch (timeFilter) {
            case 72:
              eventsCount += location?.live?.d3 || 0;
              break;

            case 24:
              eventsCount += location?.live?.d1 || 0;
              break;

            case 3:
              eventsCount += location?.live?.h3 || 0;
              break;

            case 1:
              eventsCount += location?.live?.h1 || 0;
              break;

            default:
              eventsCount += location?.live?.all || 0;
          }
        }

        if (type === 'upcoming') {
          switch (timeFilter) {
            case 72:
              eventsCount += location?.prematch?.d3 || 0;
              break;

            case 24:
              eventsCount += location?.prematch?.d1 || 0;
              break;

            case 3:
              eventsCount += location?.prematch?.h3 || 0;
              break;

            case 1:
              eventsCount += location?.prematch?.h1 || 0;
              break;

            default:
              eventsCount += location?.prematch?.all || 0;
          }
        }

        if (type === 'antepost') {
          switch (timeFilter) {
            case 72:
              eventsCount += location?.o?.d3 || 0;
              break;

            case 24:
              eventsCount += location?.o?.d1 || 0;
              break;

            case 3:
              eventsCount += location?.o?.h3 || 0;
              break;

            case 1:
              eventsCount += location?.o?.h1 || 0;
              break;

            default:
              eventsCount += location?.o?.all || 0;
          }
        }

        if (type === 'player') {
          switch (timeFilter) {
            case 72:
              eventsCount += location?.p?.d3 || 0;
              break;

            case 24:
              eventsCount += location?.p?.d1 || 0;
              break;

            case 3:
              eventsCount += location?.p?.h3 || 0;
              break;

            case 1:
              eventsCount += location?.p?.h1 || 0;
              break;

            default:
              eventsCount += location?.p?.all || 0;
          }
        }

        if (type === 'special') {
          switch (timeFilter) {
            case 72:
              eventsCount += location?.g?.d3 || 0;
              break;

            case 24:
              eventsCount += location?.g?.d1 || 0;
              break;

            case 3:
              eventsCount += location?.g?.h3 || 0;
              break;

            case 1:
              eventsCount += location?.g?.h1 || 0;
              break;

            default:
              eventsCount += location?.g?.all || 0;
          }
        }
      }
    }
  }

  return eventsCount;
};

export const getDateEventsCount = (
  sportId: any,
  date: any,
  offerCounters: any,
  timeFilter: number
) => {
  let eventsCount = 0;

  const sportMap = offerCounters.get(String(sportId));
  if (!sportMap) return;

  const currentSport = new Map(sportMap.dateMap);
  if (!currentSport) return;

  const fullDate = parseDate(date);
  const dateMap = currentSport.get(String(fullDate));
  if (!dateMap) return;

  if (dateMap.live || dateMap.prematch || dateMap.g || dateMap.o || dateMap.p) {
    switch (timeFilter) {
      case 72:
        eventsCount += dateMap?.live?.d3 || 0;
        eventsCount += dateMap?.prematch?.d3 || 0;
        eventsCount += dateMap?.g?.d3 || 0;
        eventsCount += dateMap?.o?.d3 || 0;
        eventsCount += dateMap?.p?.d3 || 0;
        break;

      case 24:
        eventsCount += dateMap?.live?.d1 || 0;
        eventsCount += dateMap?.prematch?.d1 || 0;
        eventsCount += dateMap?.g?.d1 || 0;
        eventsCount += dateMap?.o?.d1 || 0;
        eventsCount += dateMap?.p?.d1 || 0;
        break;

      case 3:
        eventsCount += dateMap?.live?.h3 || 0;
        eventsCount += dateMap?.prematch?.h3 || 0;
        eventsCount += dateMap?.g?.h3 || 0;
        eventsCount += dateMap?.o?.h3 || 0;
        eventsCount += dateMap?.p?.h3 || 0;
        break;

      case 1:
        eventsCount += dateMap?.live?.h1 || 0;
        eventsCount += dateMap?.prematch?.h1 || 0;
        eventsCount += dateMap?.g?.h1 || 0;
        eventsCount += dateMap?.o?.h1 || 0;
        eventsCount += dateMap?.p?.h1 || 0;
        break;

      default:
        eventsCount += dateMap?.prematch?.all || 0;
        eventsCount += dateMap?.g?.all || 0;
        eventsCount += dateMap?.o?.all || 0;
        eventsCount += dateMap?.p?.all || 0;
    }
  }

  return eventsCount;
};

const parseDate = (date: string) => {
  let translatedDate = '';

  months.forEach((month: string, i) => {
    if (date.includes(month)) {
      translatedDate = date.replace('Maj', engMonths[i]);
    }
  });

  const rawDate = new Date(translatedDate);

  let day = rawDate.getDate();
  if (day < 10) {
    day = '0' + day;
  }

  let month = rawDate.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  const fullDate = `${rawDate.getFullYear()}-${month}-${day}`;

  return fullDate;
};

export const getMarketNameTranslationKey = (lang: string) => {
  switch (lang) {
    case 'me':
      return 'name';
    case 'en':
      return 'englishName';
    case 'al':
      return 'albanianName';
    case 'tr':
      return 'turkishName';
    case 'ru':
      return 'russianName';
    case 'ua':
      return 'ukrainianName';
    case 'it':
      return 'italianName';
    case 'de':
      return 'germanName';
    default:
      return '';
  }
};
