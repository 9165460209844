/* React modules */

/* Our modules */
import {
  InitialDataSportsMap,
  InitialDataMarketsMap,
  InitialCompetition,
} from 'generated-proto/proto/cache_service/cache_pb';
import {
  Sport,
  ILocation,
  MarketGroup,
  Market,
} from 'modules/sports/sports.types';

/* 3rd Party modules */

const sportSort = {
  fudbal: 1,
  košarka: 2,
  tenis: 3,
  rukomet: 4,
  hokej: 5,
  odbojka: 6,
} as any;

const parseSportsMapToArray = (initialDataMap: InitialDataSportsMap) => {
  const sportsMap = new Map(Object.entries(initialDataMap.sports));

  const sportsList: Sport[] = [];

  (sportsMap as Map<string, Sport>).forEach((sport) => {
    sportsList.push(sport);

    const locations = new Map(Object.entries(sport.locations));

    sport.locationsList = [];

    (locations as Map<string, ILocation>).forEach((location) => {
      (sport.locationsList || []).push(location);

      location.competitionsList = [];

      const competitions = new Map(Object.entries(location.competitions));

      competitions.forEach((competition) => {
        (location.competitionsList || []).push(competition);
      });
    });
  });

  return sportsList;
};

const parseMarketsMapToArray = (initialDataMap: InitialDataMarketsMap) => {
  const marketGroupsMap = new Map(Object.entries(initialDataMap.marketGroups));

  let marketGroupsList: MarketGroup[] = [];

  (marketGroupsMap as Map<string, MarketGroup>).forEach((marketGroup) => {
    marketGroupsList.push(marketGroup);

    const markets = new Map(Object.entries(marketGroup.markets));

    marketGroup.marketsList = [];

    (markets as Map<string, Market>).forEach((market) => {
      (marketGroup.marketsList || []).push(market);

      const outcomes = new Map(Object.entries(market.outcomes));

      market.outcomesList = [];

      outcomes.forEach((outcome) => {
        (market.outcomesList || []).push(outcome);
      });
    });
  });

  return marketGroupsList;
};

const sortSports = (a: Sport, b: Sport) => {
  if (!sportSort[a.name] && sportSort[b.name]) {
    return 7 - sportSort[b.name];
  }

  if (sportSort[a.name] && !sportSort[b.name]) {
    return sportSort[a.name] - 7;
  }

  return sportSort[a.name] - sportSort[b.name];
};

const sortLocations = (a: ILocation, b: ILocation) => {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

const sortCompetitions = (sportsList: Sport[]) => {
  // locationOrder
  // SORT COMPETITIONS PER COMPETITION ORDER, THEN PER COMPETITION NAME

  // Create a collator for case-insensitive, locale-aware string comparison
  const collator = new Intl.Collator(undefined, { sensitivity: 'base' });

  // Comparator function for competitions
  const compareCompetitions = (
    a: InitialCompetition,
    b: InitialCompetition
  ) => {
    if (a.competitionOrder !== b.competitionOrder) {
      return a.competitionOrder - b.competitionOrder;
    }

    // If competitionOrder is the same, sort by competition name
    return collator.compare(a.name, b.name);
  };

  sportsList.forEach((sport) => {
    (sport.locationsList || []).forEach((location) => {
      if (Array.isArray(location.competitionsList)) {
        location.competitionsList.sort(compareCompetitions);
      }
    });
  });

  return sportsList;
};

const sortSportsList = (sportList: Array<Sport>) =>
  sportList
    .map((sport) => ({
      ...sport,
      locationsList: (sport.locationsList || []).sort(sortLocations),
    }))
    .sort(sortSports);

/*
interface TopLeaguesTypes {
  fudbal: string[];
  košarka: string[];
  rukomet: string[];
  hokej: string[];
}

const topLeagues: TopLeaguesTypes = {
  fudbal: [
    'ENGLESKA 1',
    'FRANCUSKA 1',
    'ITALIJA 1',
    'NJEMAČKA 1',
    'NJEMAČKA KUP',
    'PORTUGAL 1',
    'ITALIJA KUP',
    'SRBIJA 1',
    'ŠPANIJA 1',
    'ŠPANIJA KUP',
  ],
  košarka: ['NBA', 'EUROCUP', 'EUROLEAGUE', 'ŠPANIJA 1'],
  rukomet: ['CHAMPIONS LEAGUE'],
  hokej: ['NHL'],
};

export const isTopLeague = (sportName: string, leagueName: string) => {
  const sportTopLeagues = topLeagues[sportName as keyof TopLeaguesTypes];
  if (sportTopLeagues) {
    return sportTopLeagues.includes(leagueName.toUpperCase());
  }
};

export const getAllLeaguesReducer = (accumulator: any, value: any) => {
  return [...accumulator, ...value.competitionsList];
};
*/

export {
  parseSportsMapToArray,
  parseMarketsMapToArray,
  sortCompetitions,
  sortSportsList,
};
