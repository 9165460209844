/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import Icon from 'components/Icon';
import SinglePromo from 'components/PromoBanner/SinglePromo/SinglePromo';
import Carousel from 'components/Carousel';
import { httpBanner } from 'app/axios-config';
import styles from './PromoBanner.module.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';

const PromoBanner = () => {
  const [isOpen, setOpen] = useState(true);
  const [promoDataOpened, setPromoDataOpened] = useState<any>(null);
  const [promoDataClosed, setPromoDataClosed] = useState<any>(null);

  const fetchBanners = async () => {
    const { data } = await httpBanner.get(`web/data.json`);

    if (data) {
      setPromoDataOpened(data.sport_promo_opened);
      setPromoDataClosed(data.sport_promo_closed);
    }
  };

  useEffect(() => {
    fetchBanners();

    window.setTimeout(() => {
      setOpen(false);
    }, 4000);
  }, []);

  const toggleCollapse = () => {
    setOpen(!isOpen);
  };

  const setWrapperClass = () => {
    return isOpen
      ? styles['promo-banner-wrapper__view--open']
      : styles['promo-banner-wrapper__view--closed'];
  };

  const sliderConfigOpen = {
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: 'linear',
    arrows: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  const sliderConfigClosed = {
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 800,
    dots: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: 'linear',
    draggable: false,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const hiddenClass = !isOpen
    ? styles['promo-banner__hidden']
    : styles['promo-banner__visible'];

  const collapsedClass = !isOpen
    ? styles['promo-banner__view-collapsed__visible']
    : '';

  let slider: any;

  const prevSlide = () => slider.slickPrev();
  const nextSlide = () => slider.slickNext();

  if (!promoDataOpened && !promoDataClosed) return null;

  return (
    <div className={`${styles['promo-banner-wrapper']} ${setWrapperClass()}`}>
      <div className={`${styles['promo-banner']} ${hiddenClass}`}>
        <div className={`${styles['promo-banner__content']}`}>
          <div className={styles['promo-banner__controls']}>
            <div className="promo-arrow">
              <Icon container="promo" onClick={prevSlide} name="caretLeft" />
            </div>
            <div className="promo-arrow">
              <Icon container="promo" onClick={nextSlide} name="caretRight" />
            </div>
          </div>
          <Carousel
            refHandler={(reference) => (slider = reference)}
            sliderConfig={sliderConfigOpen}
          >
            {promoDataOpened?.map((promo: any, index: number) =>
              promo ? (
                <SinglePromo
                  key={`PromoBanner-SinglePromo-1-${index}`}
                  promo={promo}
                  isOpen={true}
                />
              ) : null
            )}
          </Carousel>
        </div>
        <div className={styles['promo-banner__toggle']}>
          <div className={styles['promo-banner__toggle-promo']}>
            <span>PROMO</span>
          </div>
          <div
            className={styles['promo-banner__toggle-icon']}
            onClick={toggleCollapse}
          >
            <Icon size="extra-small" name="caretUp" />
          </div>
        </div>
      </div>

      <div
        className={`${styles['promo-banner__view-collapsed']} ${collapsedClass}`}
      >
        <div className={styles['promo-banner__view-collapsed__content']}>
          <Carousel sliderConfig={sliderConfigClosed}>
            {promoDataClosed?.map((promo: any, index: number) => {
              if (promo?.promo_status_me && promo?.promo_text_me) {
                return (
                  <SinglePromo
                    key={`PromoBanner-SinglePromo-2-${index}`}
                    promo={promo}
                    isOpen={false}
                  />
                );
              }
            })}
          </Carousel>
        </div>
        <div className={styles['promo-banner__view-collapsed__toggle']}>
          <div
            className={styles['promo-banner__view-collapsed__toggle-icon']}
            onClick={toggleCollapse}
          >
            <Icon size="extra-small" name="caretDown" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(PromoBanner);
