import {
  Deferred,
  RpcError,
  RpcInterceptor,
  RpcMetadata,
  RpcStatus,
  UnaryCall,
} from '@protobuf-ts/runtime-rpc';

const rpcUnaryRetry = (): RpcInterceptor => ({
  interceptUnary(next, method, input, options) {
    const maxRetries = 5,
      defHeader = new Deferred<RpcMetadata>(),
      defMessage = new Deferred<object>(),
      defStatus = new Deferred<RpcStatus>(),
      defTrailer = new Deferred<RpcMetadata>();

    let retries = 0,
      result = next(method, input, options);

    void (async () => {
      while (true) {
        try {
          await result;
          defHeader.resolve(result.headers);
          defMessage.resolve(result.response);
          defStatus.resolve(result.status);
          defTrailer.resolve(result.trailers);
        } catch (err) {
          if (err instanceof RpcError && ++retries <= maxRetries) {
            console.warn(
              `gRPC connection unavailable, retrying after 1s... (Retry ${retries} of ${maxRetries})`
            );

            await new Promise((resolve) => window.setTimeout(resolve, 1000));

            result = next(method, input, options);

            continue;
          }

          defHeader.rejectPending(err);
          defMessage.rejectPending(err);
          defStatus.rejectPending(err);
          defTrailer.rejectPending(err);

          break;
        }
      }
    })();

    return new UnaryCall(
      method,
      options.meta ?? {},
      input,
      defHeader.promise,
      defMessage.promise,
      defStatus.promise,
      defTrailer.promise
    );
  },
});

export { rpcUnaryRetry };
