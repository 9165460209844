/* React modules */
import { useEffect, useState } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import useWindowSize from 'libs/viewport';
import { CasinoSectionWithGames } from 'modules/gambling/gambling.types';
import GamesSlider from 'modules/gambling/ui/GamblingHomepage/GamesSlider';
import SingleBanner from 'modules/gambling/ui/SingleBanner';
import { JackpotCarousel } from 'modules/gambling/ui/JackpotCarousel/JackpotCarousel';
import { AmusnetBanner } from 'modules/gambling/ui/JackpotCarousel/components/AmusnetBanner';
import {
  SLIDER_CONFIG,
  calculateSliderConfig,
} from 'modules/gambling/ui/GamblingHomepage/sliderConfig';
import { httpBanner } from 'app/axios-config';
import './GamesSections.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export interface GamesSectionsProps {}

const GamesSections = observer(() => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const { gamblingStore, authStore } = useStores();
  const {
    toggleSectionFilter,
    gamesPerSection,
    getFavouriteGames,
    favouriteGames,
  } = gamblingStore;
  const viewport = useWindowSize();
  const [casinoBanner, setCasinoBanner] = useState<any>(null);

  useEffect(() => {
    fetchBanners();
    getFavouriteGames();
  }, []);

  useEffect(() => {
    if (viewport.width < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [viewport.width]);

  const fetchBanners = async () => {
    const { data } = await httpBanner.get(`web/data.json`);
    if (data) {
      setCasinoBanner(data.casino_main[0]);
    }
  };

  const sliderConfig = calculateSliderConfig(favouriteGames.length);

  return (
    <div className="games-sections">
      {casinoBanner && <SingleBanner banner={casinoBanner} />}

      <div className="mt-4">
        <JackpotCarousel>
          <AmusnetBanner />
        </JackpotCarousel>
      </div>

      {favouriteGames.length > 0 && authStore.isLoggedIn && (
        <div key={'favourite_games'} className="games-sections__section">
          <GamesSlider
            variant="horizontal"
            onClick={() =>
              toggleSectionFilter({
                tag_name: 'favourite_games',
                id: 0,
                position: null,
              })
            }
            title={t('casino.favourite-games')}
            sliderConfig={sliderConfig}
            sectionId={1}
            gamesList={favouriteGames}
            isMobile={isMobile}
          />
        </div>
      )}

      {gamesPerSection.map((section: CasinoSectionWithGames) => (
        <div
          key={`GamesSections-div-${section.id}`}
          className="games-sections__section"
        >
          <GamesSlider
            variant="horizontal"
            onClick={() => toggleSectionFilter(section)}
            title={section.tag_name}
            sliderConfig={SLIDER_CONFIG}
            sectionId={section.id}
            gamesList={section.games}
            isMobile={isMobile}
          />
        </div>
      ))}
    </div>
  );
});

export default GamesSections;
