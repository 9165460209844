// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/user/user.proto" (package "user", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { UserService } from "./user_pb";
import type { BMSecondaryWindow } from "./user_pb";
import type { BMCommandRequest } from "./user_pb";
import type { GetBettingMachineDevicesRequest } from "./user_pb";
import type { IndependentBMMenuItem } from "./user_pb";
import type { AllBMMenuItems } from "./user_pb";
import type { EditBMMenuItemRequest } from "./user_pb";
import type { BMMenuItem } from "./user_pb";
import type { NewBMMenuItem } from "./user_pb";
import type { BettingMachineExternalLinks } from "./user_pb";
import type { BettingMachineExternalLink } from "./user_pb";
import type { GetLiveBettingMachinesResponse } from "./user_pb";
import type { BettingPlaceId } from "./user_pb";
import type { GetBettingMachineByUsernameAndTokenResponse } from "./user_pb";
import type { GetBettingMachineByUsernameAndTokenRequest } from "./user_pb";
import type { BettingMachine } from "./user_pb";
import type { GetBettingMachinesResponse } from "./user_pb";
import type { GetBettingMachinesRequest } from "./user_pb";
import type { LandbasePlayer } from "./user_pb";
import type { GetLandbasePlayersRequest } from "./user_pb";
import type { GetLandbasePlayersResponse } from "./user_pb";
import type { AgentPayinRequest } from "./user_pb";
import type { GetAgentCommisionAndLimitResponse } from "./user_pb";
import type { Agent } from "./user_pb";
import type { GetAgentsResponse } from "./user_pb";
import type { GetAgentsRequest } from "./user_pb";
import type { BackOfficeUser } from "./user_pb";
import type { BoPermissionMap } from "./user_pb";
import type { GetBackOfficeUsersResponse } from "./user_pb";
import type { GetBackOfficeUsersRequest } from "./user_pb";
import type { Employee } from "./user_pb";
import type { GetEmployeesResponse } from "./user_pb";
import type { GetEmployeesRequest } from "./user_pb";
import type { SetActiveBettingPlaceRequest } from "./user_pb";
import type { BettingPlaceLimitsRequest } from "./user_pb";
import type { GetBPBettingMachinesResponse } from "./user_pb";
import type { CheckSameMunicipalityBPSResponse } from "./user_pb";
import type { CheckSameMunicipalityBPSRequest } from "./user_pb";
import type { BettingPlaceDeviceRequest } from "./user_pb";
import type { EditBettingPlaceRequest } from "./user_pb";
import type { NewBettingPlace } from "./user_pb";
import type { NewBettingPlaceRequest } from "./user_pb";
import type { GetAllowDepositResponse } from "./user_pb";
import type { GetAllowDepositRequest } from "./user_pb";
import type { SetAllowDepositRequest } from "./user_pb";
import type { TestBettingPlacesResponse } from "./user_pb";
import type { GetTestBettingPlaceResponse } from "./user_pb";
import type { TestBettingPlaceRequest } from "./user_pb";
import type { IsTestValues } from "./user_pb";
import type { CrediteeList } from "./user_pb";
import type { BettingPlace } from "./user_pb";
import type { ActiveBettingPlaceCodes } from "./user_pb";
import type { BettingPlaceDevice } from "./user_pb";
import type { GetBettingPlacesByMunicipalityResponse } from "./user_pb";
import type { ValidationPriorityObjects } from "./user_pb";
import type { EmptyError } from "../common/common_pb";
import type { GetBettingPlaceInfoByIsonisPlaceCodeResponse } from "./user_pb";
import type { GetBettingPlaceInfoByDeviceCodeResponse } from "./user_pb";
import type { DeviceCode } from "./user_pb";
import type { GetBettingPlacesBoResponse } from "./user_pb";
import type { GetBettingPlacesBoRequest } from "./user_pb";
import type { DuplexStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { GrantCacheBackBonusRequest } from "./user_pb";
import type { CheckSlipBonusResponse } from "./user_pb";
import type { CheckSlipBonusRequest } from "./user_pb";
import type { Bonus } from "./user_pb";
import type { CreateBonusRequest } from "./user_pb";
import type { BonusMetadata } from "./user_pb";
import type { Empty } from "../../google/protobuf/empty_pb";
import type { ReleaseFundsResponse } from "./user_pb";
import type { ConfirmFundsResponse } from "./user_pb";
import type { ReleaseFundsRequest } from "./user_pb";
import type { ReserveFundsResponse } from "./user_pb";
import type { ReserveFundsRequest } from "./user_pb";
import type { ListActivityResponse } from "./user_pb";
import type { ListActivityRequest } from "./user_pb";
import type { ListUsersResponse } from "./user_pb";
import type { ListUsersRequest } from "./user_pb";
import type { UpdateUserRequest } from "./user_pb";
import type { GetUserRequest } from "./user_pb";
import type { GetUserPermissionsResponse } from "./user_pb";
import type { GetUserPermissionsRequest } from "./user_pb";
import type { GetUserByEmailAndPasswordResponse } from "./user_pb";
import type { GetUserByEmailAndPasswordRequest } from "./user_pb";
import type { CreatePlatformUserRequest } from "./user_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { UserResponse } from "./user_pb";
import type { CreateUserRequest } from "./user_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Service definitions
 *
 * @generated from protobuf service user.UserService
 */
export interface IUserServiceClient {
    /**
     * @generated from protobuf rpc: CreateUser(user.CreateUserRequest) returns (user.UserResponse);
     */
    createUser(input: CreateUserRequest, options?: RpcOptions): UnaryCall<CreateUserRequest, UserResponse>;
    /**
     * @generated from protobuf rpc: CreatePlatformUser(user.CreatePlatformUserRequest) returns (user.UserResponse);
     */
    createPlatformUser(input: CreatePlatformUserRequest, options?: RpcOptions): UnaryCall<CreatePlatformUserRequest, UserResponse>;
    /**
     * @generated from protobuf rpc: GetUserByEmailAndPassword(user.GetUserByEmailAndPasswordRequest) returns (user.GetUserByEmailAndPasswordResponse);
     */
    getUserByEmailAndPassword(input: GetUserByEmailAndPasswordRequest, options?: RpcOptions): UnaryCall<GetUserByEmailAndPasswordRequest, GetUserByEmailAndPasswordResponse>;
    /**
     * @generated from protobuf rpc: GetUserPermissions(user.GetUserPermissionsRequest) returns (user.GetUserPermissionsResponse);
     */
    getUserPermissions(input: GetUserPermissionsRequest, options?: RpcOptions): UnaryCall<GetUserPermissionsRequest, GetUserPermissionsResponse>;
    /**
     * @generated from protobuf rpc: GetApprovalId(user.GetUserRequest) returns (user.GetUserRequest);
     */
    getApprovalId(input: GetUserRequest, options?: RpcOptions): UnaryCall<GetUserRequest, GetUserRequest>;
    /**
     * @generated from protobuf rpc: GetUser(user.GetUserRequest) returns (user.UserResponse);
     */
    getUser(input: GetUserRequest, options?: RpcOptions): UnaryCall<GetUserRequest, UserResponse>;
    /**
     * @generated from protobuf rpc: UpdateUser(user.UpdateUserRequest) returns (user.UserResponse);
     */
    updateUser(input: UpdateUserRequest, options?: RpcOptions): UnaryCall<UpdateUserRequest, UserResponse>;
    /**
     * @generated from protobuf rpc: ListUsers(user.ListUsersRequest) returns (user.ListUsersResponse);
     */
    listUsers(input: ListUsersRequest, options?: RpcOptions): UnaryCall<ListUsersRequest, ListUsersResponse>;
    /**
     * @generated from protobuf rpc: ListActivity(user.ListActivityRequest) returns (user.ListActivityResponse);
     */
    listActivity(input: ListActivityRequest, options?: RpcOptions): UnaryCall<ListActivityRequest, ListActivityResponse>;
    /**
     * User funds rpc
     *
     * @generated from protobuf rpc: ReserveFunds(user.ReserveFundsRequest) returns (user.ReserveFundsResponse);
     */
    reserveFunds(input: ReserveFundsRequest, options?: RpcOptions): UnaryCall<ReserveFundsRequest, ReserveFundsResponse>;
    /**
     * @generated from protobuf rpc: ConfirmFunds(user.ReleaseFundsRequest) returns (user.ConfirmFundsResponse);
     */
    confirmFunds(input: ReleaseFundsRequest, options?: RpcOptions): UnaryCall<ReleaseFundsRequest, ConfirmFundsResponse>;
    /**
     * @generated from protobuf rpc: ReleaseFunds(user.ReleaseFundsRequest) returns (user.ReleaseFundsResponse);
     */
    releaseFunds(input: ReleaseFundsRequest, options?: RpcOptions): UnaryCall<ReleaseFundsRequest, ReleaseFundsResponse>;
    /**
     * Bonus rpc
     *
     * @generated from protobuf rpc: GetBonusMetadata(google.protobuf.Empty) returns (user.BonusMetadata);
     */
    getBonusMetadata(input: Empty, options?: RpcOptions): UnaryCall<Empty, BonusMetadata>;
    /**
     * @generated from protobuf rpc: CreateBonus(user.CreateBonusRequest) returns (user.Bonus);
     */
    createBonus(input: CreateBonusRequest, options?: RpcOptions): UnaryCall<CreateBonusRequest, Bonus>;
    /**
     * @generated from protobuf rpc: CheckSlipBonus(user.CheckSlipBonusRequest) returns (user.CheckSlipBonusResponse);
     */
    checkSlipBonus(input: CheckSlipBonusRequest, options?: RpcOptions): UnaryCall<CheckSlipBonusRequest, CheckSlipBonusResponse>;
    /**
     * @generated from protobuf rpc: GrantCacheBackBonus(user.GrantCacheBackBonusRequest) returns (user.Bonus);
     */
    grantCacheBackBonus(input: GrantCacheBackBonusRequest, options?: RpcOptions): UnaryCall<GrantCacheBackBonusRequest, Bonus>;
    /**
     * @generated from protobuf rpc: CheckMultipleSlipsBonuses(stream user.CheckSlipBonusRequest) returns (stream user.CheckSlipBonusResponse);
     */
    checkMultipleSlipsBonuses(options?: RpcOptions): DuplexStreamingCall<CheckSlipBonusRequest, CheckSlipBonusResponse>;
    /**
     * Betting Place rpc
     *
     * @generated from protobuf rpc: GetBettingPlacesBO(user.GetBettingPlacesBoRequest) returns (user.GetBettingPlacesBoResponse);
     */
    getBettingPlacesBO(input: GetBettingPlacesBoRequest, options?: RpcOptions): UnaryCall<GetBettingPlacesBoRequest, GetBettingPlacesBoResponse>;
    /**
     * @generated from protobuf rpc: GetAllBettingPlacesBO(user.GetBettingPlacesBoRequest) returns (user.GetBettingPlacesBoResponse);
     */
    getAllBettingPlacesBO(input: GetBettingPlacesBoRequest, options?: RpcOptions): UnaryCall<GetBettingPlacesBoRequest, GetBettingPlacesBoResponse>;
    /**
     * @generated from protobuf rpc: GetBettingPlaceInfoByDeviceCode(user.DeviceCode) returns (user.GetBettingPlaceInfoByDeviceCodeResponse);
     */
    getBettingPlaceInfoByDeviceCode(input: DeviceCode, options?: RpcOptions): UnaryCall<DeviceCode, GetBettingPlaceInfoByDeviceCodeResponse>;
    /**
     * @generated from protobuf rpc: GetBettingPlaceInfoByIsonisPlaceCode(user.DeviceCode) returns (user.GetBettingPlaceInfoByIsonisPlaceCodeResponse);
     */
    getBettingPlaceInfoByIsonisPlaceCode(input: DeviceCode, options?: RpcOptions): UnaryCall<DeviceCode, GetBettingPlaceInfoByIsonisPlaceCodeResponse>;
    /**
     * @generated from protobuf rpc: ReportBettingPlaceStatus(google.protobuf.Empty) returns (common.EmptyError);
     */
    reportBettingPlaceStatus(input: Empty, options?: RpcOptions): UnaryCall<Empty, EmptyError>;
    /**
     * @generated from protobuf rpc: GetValidationPriorityObjects(google.protobuf.Empty) returns (user.ValidationPriorityObjects);
     */
    getValidationPriorityObjects(input: Empty, options?: RpcOptions): UnaryCall<Empty, ValidationPriorityObjects>;
    /**
     * @generated from protobuf rpc: GetBettingPlacesByMunicipality(google.protobuf.Empty) returns (user.GetBettingPlacesByMunicipalityResponse);
     */
    getBettingPlacesByMunicipality(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetBettingPlacesByMunicipalityResponse>;
    /**
     * @generated from protobuf rpc: DoublePrint(user.BettingPlaceDevice) returns (common.EmptyError);
     */
    doublePrint(input: BettingPlaceDevice, options?: RpcOptions): UnaryCall<BettingPlaceDevice, EmptyError>;
    /**
     * @generated from protobuf rpc: BlankSlip(user.BettingPlaceDevice) returns (common.EmptyError);
     */
    blankSlip(input: BettingPlaceDevice, options?: RpcOptions): UnaryCall<BettingPlaceDevice, EmptyError>;
    /**
     * @generated from protobuf rpc: NotReporting(user.BettingPlaceDevice) returns (common.EmptyError);
     */
    notReporting(input: BettingPlaceDevice, options?: RpcOptions): UnaryCall<BettingPlaceDevice, EmptyError>;
    /**
     * @generated from protobuf rpc: IsNotReporting(user.BettingPlaceDevice) returns (user.BettingPlaceDevice);
     */
    isNotReporting(input: BettingPlaceDevice, options?: RpcOptions): UnaryCall<BettingPlaceDevice, BettingPlaceDevice>;
    /**
     * @generated from protobuf rpc: GetActiveBettingPlaceCodes(user.GetBettingPlacesBoRequest) returns (user.ActiveBettingPlaceCodes);
     */
    getActiveBettingPlaceCodes(input: GetBettingPlacesBoRequest, options?: RpcOptions): UnaryCall<GetBettingPlacesBoRequest, ActiveBettingPlaceCodes>;
    /**
     * @generated from protobuf rpc: GetCrediteeList(user.BettingPlace) returns (user.CrediteeList);
     */
    getCrediteeList(input: BettingPlace, options?: RpcOptions): UnaryCall<BettingPlace, CrediteeList>;
    /**
     * @generated from protobuf rpc: SetTestBettingPlace(user.IsTestValues) returns (common.EmptyError);
     */
    setTestBettingPlace(input: IsTestValues, options?: RpcOptions): UnaryCall<IsTestValues, EmptyError>;
    /**
     * @generated from protobuf rpc: GetTestBettingPlace(user.TestBettingPlaceRequest) returns (user.GetTestBettingPlaceResponse);
     */
    getTestBettingPlace(input: TestBettingPlaceRequest, options?: RpcOptions): UnaryCall<TestBettingPlaceRequest, GetTestBettingPlaceResponse>;
    /**
     * @generated from protobuf rpc: GetTestBettingPlaces(google.protobuf.Empty) returns (user.TestBettingPlacesResponse);
     */
    getTestBettingPlaces(input: Empty, options?: RpcOptions): UnaryCall<Empty, TestBettingPlacesResponse>;
    /**
     * @generated from protobuf rpc: SetAllowDeposit(user.SetAllowDepositRequest) returns (common.EmptyError);
     */
    setAllowDeposit(input: SetAllowDepositRequest, options?: RpcOptions): UnaryCall<SetAllowDepositRequest, EmptyError>;
    /**
     * @generated from protobuf rpc: GetAllowDeposit(user.GetAllowDepositRequest) returns (user.GetAllowDepositResponse);
     */
    getAllowDeposit(input: GetAllowDepositRequest, options?: RpcOptions): UnaryCall<GetAllowDepositRequest, GetAllowDepositResponse>;
    /**
     * @generated from protobuf rpc: CreateNewBettingPlace(user.NewBettingPlaceRequest) returns (user.NewBettingPlace);
     */
    createNewBettingPlace(input: NewBettingPlaceRequest, options?: RpcOptions): UnaryCall<NewBettingPlaceRequest, NewBettingPlace>;
    /**
     * @generated from protobuf rpc: EditBettingPlace(user.EditBettingPlaceRequest) returns (user.NewBettingPlace);
     */
    editBettingPlace(input: EditBettingPlaceRequest, options?: RpcOptions): UnaryCall<EditBettingPlaceRequest, NewBettingPlace>;
    /**
     * @generated from protobuf rpc: CreateNewBettingPlaceDevice(user.BettingPlaceDeviceRequest) returns (user.BettingPlaceDevice);
     */
    createNewBettingPlaceDevice(input: BettingPlaceDeviceRequest, options?: RpcOptions): UnaryCall<BettingPlaceDeviceRequest, BettingPlaceDevice>;
    /**
     * @generated from protobuf rpc: EditBettingPlaceDevice(user.BettingPlaceDeviceRequest) returns (user.BettingPlaceDevice);
     */
    editBettingPlaceDevice(input: BettingPlaceDeviceRequest, options?: RpcOptions): UnaryCall<BettingPlaceDeviceRequest, BettingPlaceDevice>;
    /**
     * @generated from protobuf rpc: CheckSameMunicipalityBPS(user.CheckSameMunicipalityBPSRequest) returns (user.CheckSameMunicipalityBPSResponse);
     */
    checkSameMunicipalityBPS(input: CheckSameMunicipalityBPSRequest, options?: RpcOptions): UnaryCall<CheckSameMunicipalityBPSRequest, CheckSameMunicipalityBPSResponse>;
    /**
     * @generated from protobuf rpc: GetBPBettingMachines(google.protobuf.Empty) returns (user.GetBPBettingMachinesResponse);
     */
    getBPBettingMachines(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetBPBettingMachinesResponse>;
    /**
     * @generated from protobuf rpc: SetBettingPlaceMinimumLimits(user.BettingPlaceLimitsRequest) returns (common.EmptyError);
     */
    setBettingPlaceMinimumLimits(input: BettingPlaceLimitsRequest, options?: RpcOptions): UnaryCall<BettingPlaceLimitsRequest, EmptyError>;
    /**
     * @generated from protobuf rpc: RequestNewBettingPlaceLimits(user.BettingPlaceLimitsRequest) returns (common.EmptyError);
     */
    requestNewBettingPlaceLimits(input: BettingPlaceLimitsRequest, options?: RpcOptions): UnaryCall<BettingPlaceLimitsRequest, EmptyError>;
    /**
     * @generated from protobuf rpc: SetActiveBettingPlace(user.SetActiveBettingPlaceRequest) returns (common.EmptyError);
     */
    setActiveBettingPlace(input: SetActiveBettingPlaceRequest, options?: RpcOptions): UnaryCall<SetActiveBettingPlaceRequest, EmptyError>;
    /**
     * Employee
     *
     * @generated from protobuf rpc: GetEmployees(user.GetEmployeesRequest) returns (user.GetEmployeesResponse);
     */
    getEmployees(input: GetEmployeesRequest, options?: RpcOptions): UnaryCall<GetEmployeesRequest, GetEmployeesResponse>;
    /**
     * @generated from protobuf rpc: CreateEmployee(user.Employee) returns (user.Employee);
     */
    createEmployee(input: Employee, options?: RpcOptions): UnaryCall<Employee, Employee>;
    /**
     * @generated from protobuf rpc: EditEmployee(user.Employee) returns (user.Employee);
     */
    editEmployee(input: Employee, options?: RpcOptions): UnaryCall<Employee, Employee>;
    /**
     * @generated from protobuf rpc: ResetEmployeePassword(user.Employee) returns (user.Employee);
     */
    resetEmployeePassword(input: Employee, options?: RpcOptions): UnaryCall<Employee, Employee>;
    /**
     * @generated from protobuf rpc: ChangeEmployeePassword(user.Employee) returns (user.Employee);
     */
    changeEmployeePassword(input: Employee, options?: RpcOptions): UnaryCall<Employee, Employee>;
    /**
     * @generated from protobuf rpc: DeleteEmployee(user.Employee) returns (user.Employee);
     */
    deleteEmployee(input: Employee, options?: RpcOptions): UnaryCall<Employee, Employee>;
    /**
     * BackOffice
     *
     * @generated from protobuf rpc: GetBackOfficeUsers(user.GetBackOfficeUsersRequest) returns (user.GetBackOfficeUsersResponse);
     */
    getBackOfficeUsers(input: GetBackOfficeUsersRequest, options?: RpcOptions): UnaryCall<GetBackOfficeUsersRequest, GetBackOfficeUsersResponse>;
    /**
     * @generated from protobuf rpc: GetBackOfficePermissionMap(google.protobuf.Empty) returns (user.BoPermissionMap);
     */
    getBackOfficePermissionMap(input: Empty, options?: RpcOptions): UnaryCall<Empty, BoPermissionMap>;
    /**
     * @generated from protobuf rpc: UpdateBackOfficeUserPermission(user.BackOfficeUser) returns (common.EmptyError);
     */
    updateBackOfficeUserPermission(input: BackOfficeUser, options?: RpcOptions): UnaryCall<BackOfficeUser, EmptyError>;
    /**
     * @generated from protobuf rpc: CreateEditBackOfficeUser(user.BackOfficeUser) returns (user.BackOfficeUser);
     */
    createEditBackOfficeUser(input: BackOfficeUser, options?: RpcOptions): UnaryCall<BackOfficeUser, BackOfficeUser>;
    /**
     * @generated from protobuf rpc: DeleteBackOfficeUser(user.BackOfficeUser) returns (user.BackOfficeUser);
     */
    deleteBackOfficeUser(input: BackOfficeUser, options?: RpcOptions): UnaryCall<BackOfficeUser, BackOfficeUser>;
    /**
     * Agent
     *
     * @generated from protobuf rpc: GetAgents(user.GetAgentsRequest) returns (user.GetAgentsResponse);
     */
    getAgents(input: GetAgentsRequest, options?: RpcOptions): UnaryCall<GetAgentsRequest, GetAgentsResponse>;
    /**
     * @generated from protobuf rpc: CreateEditAgent(user.Agent) returns (user.Agent);
     */
    createEditAgent(input: Agent, options?: RpcOptions): UnaryCall<Agent, Agent>;
    /**
     * @generated from protobuf rpc: DeleteAgent(user.Agent) returns (user.Agent);
     */
    deleteAgent(input: Agent, options?: RpcOptions): UnaryCall<Agent, Agent>;
    /**
     * @generated from protobuf rpc: GetAgentCommissionAndLimit(user.Agent) returns (user.GetAgentCommisionAndLimitResponse);
     */
    getAgentCommissionAndLimit(input: Agent, options?: RpcOptions): UnaryCall<Agent, GetAgentCommisionAndLimitResponse>;
    /**
     * @generated from protobuf rpc: AgentPayin(user.AgentPayinRequest) returns (user.GetAgentCommisionAndLimitResponse);
     */
    agentPayin(input: AgentPayinRequest, options?: RpcOptions): UnaryCall<AgentPayinRequest, GetAgentCommisionAndLimitResponse>;
    /**
     * Landbase players
     *
     * @generated from protobuf rpc: GetLandbasePlayersBO(google.protobuf.Empty) returns (user.GetLandbasePlayersResponse);
     */
    getLandbasePlayersBO(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetLandbasePlayersResponse>;
    /**
     * @generated from protobuf rpc: GetBettingPlaceLandbasePlayers(user.GetLandbasePlayersRequest) returns (user.GetLandbasePlayersResponse);
     */
    getBettingPlaceLandbasePlayers(input: GetLandbasePlayersRequest, options?: RpcOptions): UnaryCall<GetLandbasePlayersRequest, GetLandbasePlayersResponse>;
    /**
     * @generated from protobuf rpc: CreateLandbasePlayer(user.LandbasePlayer) returns (user.LandbasePlayer);
     */
    createLandbasePlayer(input: LandbasePlayer, options?: RpcOptions): UnaryCall<LandbasePlayer, LandbasePlayer>;
    /**
     * @generated from protobuf rpc: EditLandbasePlayer(user.LandbasePlayer) returns (user.LandbasePlayer);
     */
    editLandbasePlayer(input: LandbasePlayer, options?: RpcOptions): UnaryCall<LandbasePlayer, LandbasePlayer>;
    /**
     * @generated from protobuf rpc: DeleteLandbasePlayer(user.LandbasePlayer) returns (common.EmptyError);
     */
    deleteLandbasePlayer(input: LandbasePlayer, options?: RpcOptions): UnaryCall<LandbasePlayer, EmptyError>;
    /**
     * BettingMachine
     *
     * @generated from protobuf rpc: GetBettingMachines(user.GetBettingMachinesRequest) returns (user.GetBettingMachinesResponse);
     */
    getBettingMachines(input: GetBettingMachinesRequest, options?: RpcOptions): UnaryCall<GetBettingMachinesRequest, GetBettingMachinesResponse>;
    /**
     * @generated from protobuf rpc: CreateBettingMachine(user.BettingMachine) returns (user.BettingMachine);
     */
    createBettingMachine(input: BettingMachine, options?: RpcOptions): UnaryCall<BettingMachine, BettingMachine>;
    /**
     * @generated from protobuf rpc: EditBettingMachine(user.BettingMachine) returns (user.BettingMachine);
     */
    editBettingMachine(input: BettingMachine, options?: RpcOptions): UnaryCall<BettingMachine, BettingMachine>;
    /**
     * @generated from protobuf rpc: DeleteBettingMachine(user.BettingMachine) returns (user.BettingMachine);
     */
    deleteBettingMachine(input: BettingMachine, options?: RpcOptions): UnaryCall<BettingMachine, BettingMachine>;
    /**
     * @generated from protobuf rpc: GetBettingMachineByUsernameAndToken(user.GetBettingMachineByUsernameAndTokenRequest) returns (user.GetBettingMachineByUsernameAndTokenResponse);
     */
    getBettingMachineByUsernameAndToken(input: GetBettingMachineByUsernameAndTokenRequest, options?: RpcOptions): UnaryCall<GetBettingMachineByUsernameAndTokenRequest, GetBettingMachineByUsernameAndTokenResponse>;
    /**
     * @generated from protobuf rpc: GetAllBettingMachinesForBettingPlace(user.BettingPlaceId) returns (user.GetBettingMachinesResponse);
     */
    getAllBettingMachinesForBettingPlace(input: BettingPlaceId, options?: RpcOptions): UnaryCall<BettingPlaceId, GetBettingMachinesResponse>;
    /**
     * @generated from protobuf rpc: GetLiveBettingMachines(google.protobuf.Empty) returns (user.GetLiveBettingMachinesResponse);
     */
    getLiveBettingMachines(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetLiveBettingMachinesResponse>;
    /**
     * @generated from protobuf rpc: EmptyBettingMachine(user.BettingMachine) returns (common.EmptyError);
     */
    emptyBettingMachine(input: BettingMachine, options?: RpcOptions): UnaryCall<BettingMachine, EmptyError>;
    /**
     * BettinMachineExternalLinks
     *
     * @generated from protobuf rpc: CreateBettingMachineExternalLink(user.BettingMachineExternalLink) returns (user.BettingMachineExternalLink);
     */
    createBettingMachineExternalLink(input: BettingMachineExternalLink, options?: RpcOptions): UnaryCall<BettingMachineExternalLink, BettingMachineExternalLink>;
    /**
     * @generated from protobuf rpc: DeleteBettingMachineExternalLink(user.BettingMachineExternalLink) returns (user.BettingMachineExternalLink);
     */
    deleteBettingMachineExternalLink(input: BettingMachineExternalLink, options?: RpcOptions): UnaryCall<BettingMachineExternalLink, BettingMachineExternalLink>;
    /**
     * @generated from protobuf rpc: EditBettingMachineExternalLink(user.BettingMachineExternalLink) returns (user.BettingMachineExternalLink);
     */
    editBettingMachineExternalLink(input: BettingMachineExternalLink, options?: RpcOptions): UnaryCall<BettingMachineExternalLink, BettingMachineExternalLink>;
    /**
     * @generated from protobuf rpc: GetBettingMachineExternalLinks(google.protobuf.Empty) returns (user.BettingMachineExternalLinks);
     */
    getBettingMachineExternalLinks(input: Empty, options?: RpcOptions): UnaryCall<Empty, BettingMachineExternalLinks>;
    /**
     * @generated from protobuf rpc: SetBettingMachineExternalLink(user.BettingMachineExternalLink) returns (google.protobuf.Empty);
     */
    setBettingMachineExternalLink(input: BettingMachineExternalLink, options?: RpcOptions): UnaryCall<BettingMachineExternalLink, Empty>;
    /**
     * BettingMachineMenu
     *
     * @generated from protobuf rpc: CreateNewBMMenuItem(user.NewBMMenuItem) returns (user.BMMenuItem);
     */
    createNewBMMenuItem(input: NewBMMenuItem, options?: RpcOptions): UnaryCall<NewBMMenuItem, BMMenuItem>;
    /**
     * @generated from protobuf rpc: EditBMMenuItem(user.EditBMMenuItemRequest) returns (user.BMMenuItem);
     */
    editBMMenuItem(input: EditBMMenuItemRequest, options?: RpcOptions): UnaryCall<EditBMMenuItemRequest, BMMenuItem>;
    /**
     * @generated from protobuf rpc: GetAllBMMenuItems(google.protobuf.Empty) returns (user.AllBMMenuItems);
     */
    getAllBMMenuItems(input: Empty, options?: RpcOptions): UnaryCall<Empty, AllBMMenuItems>;
    /**
     * @generated from protobuf rpc: UpdateGlobalActiveMenu(user.AllBMMenuItems) returns (common.EmptyError);
     */
    updateGlobalActiveMenu(input: AllBMMenuItems, options?: RpcOptions): UnaryCall<AllBMMenuItems, EmptyError>;
    /**
     * @generated from protobuf rpc: UpdateActiveMenuForIndependentBM(user.IndependentBMMenuItem) returns (common.EmptyError);
     */
    updateActiveMenuForIndependentBM(input: IndependentBMMenuItem, options?: RpcOptions): UnaryCall<IndependentBMMenuItem, EmptyError>;
    /**
     * @generated from protobuf rpc: UpdateGlobalAllActiveMenu(user.AllBMMenuItems) returns (common.EmptyError);
     */
    updateGlobalAllActiveMenu(input: AllBMMenuItems, options?: RpcOptions): UnaryCall<AllBMMenuItems, EmptyError>;
    /**
     * @generated from protobuf rpc: GetGlobalActiveMenu(google.protobuf.Empty) returns (user.AllBMMenuItems);
     */
    getGlobalActiveMenu(input: Empty, options?: RpcOptions): UnaryCall<Empty, AllBMMenuItems>;
    /**
     * @generated from protobuf rpc: GetIndependentActiveMenu(user.GetBettingMachineDevicesRequest) returns (user.AllBMMenuItems);
     */
    getIndependentActiveMenu(input: GetBettingMachineDevicesRequest, options?: RpcOptions): UnaryCall<GetBettingMachineDevicesRequest, AllBMMenuItems>;
    /**
     * @generated from protobuf rpc: DeleteBMMenuItem(user.AllBMMenuItems) returns (common.EmptyError);
     */
    deleteBMMenuItem(input: AllBMMenuItems, options?: RpcOptions): UnaryCall<AllBMMenuItems, EmptyError>;
    /**
     * @generated from protobuf rpc: GetBaseActiveMenu(user.GetBettingMachineDevicesRequest) returns (user.AllBMMenuItems);
     */
    getBaseActiveMenu(input: GetBettingMachineDevicesRequest, options?: RpcOptions): UnaryCall<GetBettingMachineDevicesRequest, AllBMMenuItems>;
    /**
     * BettingMachineHeader
     *
     * @generated from protobuf rpc: UpdateHeaderItems(user.AllBMMenuItems) returns (common.EmptyError);
     */
    updateHeaderItems(input: AllBMMenuItems, options?: RpcOptions): UnaryCall<AllBMMenuItems, EmptyError>;
    /**
     * @generated from protobuf rpc: UpdateHeaderItemsBP(user.AllBMMenuItems) returns (common.EmptyError);
     */
    updateHeaderItemsBP(input: AllBMMenuItems, options?: RpcOptions): UnaryCall<AllBMMenuItems, EmptyError>;
    /**
     * @generated from protobuf rpc: UpdateHeaderItemsBM(user.AllBMMenuItems) returns (common.EmptyError);
     */
    updateHeaderItemsBM(input: AllBMMenuItems, options?: RpcOptions): UnaryCall<AllBMMenuItems, EmptyError>;
    /**
     * @generated from protobuf rpc: GetHeaderItems(user.GetBettingMachineDevicesRequest) returns (user.AllBMMenuItems);
     */
    getHeaderItems(input: GetBettingMachineDevicesRequest, options?: RpcOptions): UnaryCall<GetBettingMachineDevicesRequest, AllBMMenuItems>;
    /**
     * @generated from protobuf rpc: GetBPHeaderItems(user.GetBettingMachineDevicesRequest) returns (user.AllBMMenuItems);
     */
    getBPHeaderItems(input: GetBettingMachineDevicesRequest, options?: RpcOptions): UnaryCall<GetBettingMachineDevicesRequest, AllBMMenuItems>;
    /**
     * @generated from protobuf rpc: GetBMHeaderItems(user.GetBettingMachineDevicesRequest) returns (user.AllBMMenuItems);
     */
    getBMHeaderItems(input: GetBettingMachineDevicesRequest, options?: RpcOptions): UnaryCall<GetBettingMachineDevicesRequest, AllBMMenuItems>;
    /**
     * BettingMachineWS
     *
     * @generated from protobuf rpc: ExecuteCommand(user.BMCommandRequest) returns (common.EmptyError);
     */
    executeCommand(input: BMCommandRequest, options?: RpcOptions): UnaryCall<BMCommandRequest, EmptyError>;
    /**
     * @generated from protobuf rpc: SetSecondaryBMWindow(user.BMSecondaryWindow) returns (common.EmptyError);
     */
    setSecondaryBMWindow(input: BMSecondaryWindow, options?: RpcOptions): UnaryCall<BMSecondaryWindow, EmptyError>;
    /**
     * @generated from protobuf rpc: GetSecondaryBMWindow(user.BMSecondaryWindow) returns (user.BMSecondaryWindow);
     */
    getSecondaryBMWindow(input: BMSecondaryWindow, options?: RpcOptions): UnaryCall<BMSecondaryWindow, BMSecondaryWindow>;
}
/**
 * Service definitions
 *
 * @generated from protobuf service user.UserService
 */
export class UserServiceClient implements IUserServiceClient, ServiceInfo {
    typeName = UserService.typeName;
    methods = UserService.methods;
    options = UserService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: CreateUser(user.CreateUserRequest) returns (user.UserResponse);
     */
    createUser(input: CreateUserRequest, options?: RpcOptions): UnaryCall<CreateUserRequest, UserResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateUserRequest, UserResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePlatformUser(user.CreatePlatformUserRequest) returns (user.UserResponse);
     */
    createPlatformUser(input: CreatePlatformUserRequest, options?: RpcOptions): UnaryCall<CreatePlatformUserRequest, UserResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreatePlatformUserRequest, UserResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserByEmailAndPassword(user.GetUserByEmailAndPasswordRequest) returns (user.GetUserByEmailAndPasswordResponse);
     */
    getUserByEmailAndPassword(input: GetUserByEmailAndPasswordRequest, options?: RpcOptions): UnaryCall<GetUserByEmailAndPasswordRequest, GetUserByEmailAndPasswordResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserByEmailAndPasswordRequest, GetUserByEmailAndPasswordResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserPermissions(user.GetUserPermissionsRequest) returns (user.GetUserPermissionsResponse);
     */
    getUserPermissions(input: GetUserPermissionsRequest, options?: RpcOptions): UnaryCall<GetUserPermissionsRequest, GetUserPermissionsResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserPermissionsRequest, GetUserPermissionsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetApprovalId(user.GetUserRequest) returns (user.GetUserRequest);
     */
    getApprovalId(input: GetUserRequest, options?: RpcOptions): UnaryCall<GetUserRequest, GetUserRequest> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserRequest, GetUserRequest>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUser(user.GetUserRequest) returns (user.UserResponse);
     */
    getUser(input: GetUserRequest, options?: RpcOptions): UnaryCall<GetUserRequest, UserResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserRequest, UserResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateUser(user.UpdateUserRequest) returns (user.UserResponse);
     */
    updateUser(input: UpdateUserRequest, options?: RpcOptions): UnaryCall<UpdateUserRequest, UserResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateUserRequest, UserResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListUsers(user.ListUsersRequest) returns (user.ListUsersResponse);
     */
    listUsers(input: ListUsersRequest, options?: RpcOptions): UnaryCall<ListUsersRequest, ListUsersResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUsersRequest, ListUsersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListActivity(user.ListActivityRequest) returns (user.ListActivityResponse);
     */
    listActivity(input: ListActivityRequest, options?: RpcOptions): UnaryCall<ListActivityRequest, ListActivityResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListActivityRequest, ListActivityResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * User funds rpc
     *
     * @generated from protobuf rpc: ReserveFunds(user.ReserveFundsRequest) returns (user.ReserveFundsResponse);
     */
    reserveFunds(input: ReserveFundsRequest, options?: RpcOptions): UnaryCall<ReserveFundsRequest, ReserveFundsResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReserveFundsRequest, ReserveFundsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ConfirmFunds(user.ReleaseFundsRequest) returns (user.ConfirmFundsResponse);
     */
    confirmFunds(input: ReleaseFundsRequest, options?: RpcOptions): UnaryCall<ReleaseFundsRequest, ConfirmFundsResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReleaseFundsRequest, ConfirmFundsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReleaseFunds(user.ReleaseFundsRequest) returns (user.ReleaseFundsResponse);
     */
    releaseFunds(input: ReleaseFundsRequest, options?: RpcOptions): UnaryCall<ReleaseFundsRequest, ReleaseFundsResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReleaseFundsRequest, ReleaseFundsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Bonus rpc
     *
     * @generated from protobuf rpc: GetBonusMetadata(google.protobuf.Empty) returns (user.BonusMetadata);
     */
    getBonusMetadata(input: Empty, options?: RpcOptions): UnaryCall<Empty, BonusMetadata> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, BonusMetadata>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateBonus(user.CreateBonusRequest) returns (user.Bonus);
     */
    createBonus(input: CreateBonusRequest, options?: RpcOptions): UnaryCall<CreateBonusRequest, Bonus> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateBonusRequest, Bonus>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CheckSlipBonus(user.CheckSlipBonusRequest) returns (user.CheckSlipBonusResponse);
     */
    checkSlipBonus(input: CheckSlipBonusRequest, options?: RpcOptions): UnaryCall<CheckSlipBonusRequest, CheckSlipBonusResponse> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckSlipBonusRequest, CheckSlipBonusResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GrantCacheBackBonus(user.GrantCacheBackBonusRequest) returns (user.Bonus);
     */
    grantCacheBackBonus(input: GrantCacheBackBonusRequest, options?: RpcOptions): UnaryCall<GrantCacheBackBonusRequest, Bonus> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<GrantCacheBackBonusRequest, Bonus>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CheckMultipleSlipsBonuses(stream user.CheckSlipBonusRequest) returns (stream user.CheckSlipBonusResponse);
     */
    checkMultipleSlipsBonuses(options?: RpcOptions): DuplexStreamingCall<CheckSlipBonusRequest, CheckSlipBonusResponse> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckSlipBonusRequest, CheckSlipBonusResponse>("duplex", this._transport, method, opt);
    }
    /**
     * Betting Place rpc
     *
     * @generated from protobuf rpc: GetBettingPlacesBO(user.GetBettingPlacesBoRequest) returns (user.GetBettingPlacesBoResponse);
     */
    getBettingPlacesBO(input: GetBettingPlacesBoRequest, options?: RpcOptions): UnaryCall<GetBettingPlacesBoRequest, GetBettingPlacesBoResponse> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetBettingPlacesBoRequest, GetBettingPlacesBoResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAllBettingPlacesBO(user.GetBettingPlacesBoRequest) returns (user.GetBettingPlacesBoResponse);
     */
    getAllBettingPlacesBO(input: GetBettingPlacesBoRequest, options?: RpcOptions): UnaryCall<GetBettingPlacesBoRequest, GetBettingPlacesBoResponse> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetBettingPlacesBoRequest, GetBettingPlacesBoResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBettingPlaceInfoByDeviceCode(user.DeviceCode) returns (user.GetBettingPlaceInfoByDeviceCodeResponse);
     */
    getBettingPlaceInfoByDeviceCode(input: DeviceCode, options?: RpcOptions): UnaryCall<DeviceCode, GetBettingPlaceInfoByDeviceCodeResponse> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeviceCode, GetBettingPlaceInfoByDeviceCodeResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBettingPlaceInfoByIsonisPlaceCode(user.DeviceCode) returns (user.GetBettingPlaceInfoByIsonisPlaceCodeResponse);
     */
    getBettingPlaceInfoByIsonisPlaceCode(input: DeviceCode, options?: RpcOptions): UnaryCall<DeviceCode, GetBettingPlaceInfoByIsonisPlaceCodeResponse> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeviceCode, GetBettingPlaceInfoByIsonisPlaceCodeResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReportBettingPlaceStatus(google.protobuf.Empty) returns (common.EmptyError);
     */
    reportBettingPlaceStatus(input: Empty, options?: RpcOptions): UnaryCall<Empty, EmptyError> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetValidationPriorityObjects(google.protobuf.Empty) returns (user.ValidationPriorityObjects);
     */
    getValidationPriorityObjects(input: Empty, options?: RpcOptions): UnaryCall<Empty, ValidationPriorityObjects> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, ValidationPriorityObjects>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBettingPlacesByMunicipality(google.protobuf.Empty) returns (user.GetBettingPlacesByMunicipalityResponse);
     */
    getBettingPlacesByMunicipality(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetBettingPlacesByMunicipalityResponse> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, GetBettingPlacesByMunicipalityResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DoublePrint(user.BettingPlaceDevice) returns (common.EmptyError);
     */
    doublePrint(input: BettingPlaceDevice, options?: RpcOptions): UnaryCall<BettingPlaceDevice, EmptyError> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingPlaceDevice, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BlankSlip(user.BettingPlaceDevice) returns (common.EmptyError);
     */
    blankSlip(input: BettingPlaceDevice, options?: RpcOptions): UnaryCall<BettingPlaceDevice, EmptyError> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingPlaceDevice, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: NotReporting(user.BettingPlaceDevice) returns (common.EmptyError);
     */
    notReporting(input: BettingPlaceDevice, options?: RpcOptions): UnaryCall<BettingPlaceDevice, EmptyError> {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingPlaceDevice, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: IsNotReporting(user.BettingPlaceDevice) returns (user.BettingPlaceDevice);
     */
    isNotReporting(input: BettingPlaceDevice, options?: RpcOptions): UnaryCall<BettingPlaceDevice, BettingPlaceDevice> {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingPlaceDevice, BettingPlaceDevice>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetActiveBettingPlaceCodes(user.GetBettingPlacesBoRequest) returns (user.ActiveBettingPlaceCodes);
     */
    getActiveBettingPlaceCodes(input: GetBettingPlacesBoRequest, options?: RpcOptions): UnaryCall<GetBettingPlacesBoRequest, ActiveBettingPlaceCodes> {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetBettingPlacesBoRequest, ActiveBettingPlaceCodes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCrediteeList(user.BettingPlace) returns (user.CrediteeList);
     */
    getCrediteeList(input: BettingPlace, options?: RpcOptions): UnaryCall<BettingPlace, CrediteeList> {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingPlace, CrediteeList>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetTestBettingPlace(user.IsTestValues) returns (common.EmptyError);
     */
    setTestBettingPlace(input: IsTestValues, options?: RpcOptions): UnaryCall<IsTestValues, EmptyError> {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept<IsTestValues, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTestBettingPlace(user.TestBettingPlaceRequest) returns (user.GetTestBettingPlaceResponse);
     */
    getTestBettingPlace(input: TestBettingPlaceRequest, options?: RpcOptions): UnaryCall<TestBettingPlaceRequest, GetTestBettingPlaceResponse> {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept<TestBettingPlaceRequest, GetTestBettingPlaceResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTestBettingPlaces(google.protobuf.Empty) returns (user.TestBettingPlacesResponse);
     */
    getTestBettingPlaces(input: Empty, options?: RpcOptions): UnaryCall<Empty, TestBettingPlacesResponse> {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, TestBettingPlacesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetAllowDeposit(user.SetAllowDepositRequest) returns (common.EmptyError);
     */
    setAllowDeposit(input: SetAllowDepositRequest, options?: RpcOptions): UnaryCall<SetAllowDepositRequest, EmptyError> {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetAllowDepositRequest, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAllowDeposit(user.GetAllowDepositRequest) returns (user.GetAllowDepositResponse);
     */
    getAllowDeposit(input: GetAllowDepositRequest, options?: RpcOptions): UnaryCall<GetAllowDepositRequest, GetAllowDepositResponse> {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllowDepositRequest, GetAllowDepositResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateNewBettingPlace(user.NewBettingPlaceRequest) returns (user.NewBettingPlace);
     */
    createNewBettingPlace(input: NewBettingPlaceRequest, options?: RpcOptions): UnaryCall<NewBettingPlaceRequest, NewBettingPlace> {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept<NewBettingPlaceRequest, NewBettingPlace>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EditBettingPlace(user.EditBettingPlaceRequest) returns (user.NewBettingPlace);
     */
    editBettingPlace(input: EditBettingPlaceRequest, options?: RpcOptions): UnaryCall<EditBettingPlaceRequest, NewBettingPlace> {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept<EditBettingPlaceRequest, NewBettingPlace>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateNewBettingPlaceDevice(user.BettingPlaceDeviceRequest) returns (user.BettingPlaceDevice);
     */
    createNewBettingPlaceDevice(input: BettingPlaceDeviceRequest, options?: RpcOptions): UnaryCall<BettingPlaceDeviceRequest, BettingPlaceDevice> {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingPlaceDeviceRequest, BettingPlaceDevice>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EditBettingPlaceDevice(user.BettingPlaceDeviceRequest) returns (user.BettingPlaceDevice);
     */
    editBettingPlaceDevice(input: BettingPlaceDeviceRequest, options?: RpcOptions): UnaryCall<BettingPlaceDeviceRequest, BettingPlaceDevice> {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingPlaceDeviceRequest, BettingPlaceDevice>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CheckSameMunicipalityBPS(user.CheckSameMunicipalityBPSRequest) returns (user.CheckSameMunicipalityBPSResponse);
     */
    checkSameMunicipalityBPS(input: CheckSameMunicipalityBPSRequest, options?: RpcOptions): UnaryCall<CheckSameMunicipalityBPSRequest, CheckSameMunicipalityBPSResponse> {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckSameMunicipalityBPSRequest, CheckSameMunicipalityBPSResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBPBettingMachines(google.protobuf.Empty) returns (user.GetBPBettingMachinesResponse);
     */
    getBPBettingMachines(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetBPBettingMachinesResponse> {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, GetBPBettingMachinesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetBettingPlaceMinimumLimits(user.BettingPlaceLimitsRequest) returns (common.EmptyError);
     */
    setBettingPlaceMinimumLimits(input: BettingPlaceLimitsRequest, options?: RpcOptions): UnaryCall<BettingPlaceLimitsRequest, EmptyError> {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingPlaceLimitsRequest, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RequestNewBettingPlaceLimits(user.BettingPlaceLimitsRequest) returns (common.EmptyError);
     */
    requestNewBettingPlaceLimits(input: BettingPlaceLimitsRequest, options?: RpcOptions): UnaryCall<BettingPlaceLimitsRequest, EmptyError> {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingPlaceLimitsRequest, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetActiveBettingPlace(user.SetActiveBettingPlaceRequest) returns (common.EmptyError);
     */
    setActiveBettingPlace(input: SetActiveBettingPlaceRequest, options?: RpcOptions): UnaryCall<SetActiveBettingPlaceRequest, EmptyError> {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetActiveBettingPlaceRequest, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * Employee
     *
     * @generated from protobuf rpc: GetEmployees(user.GetEmployeesRequest) returns (user.GetEmployeesResponse);
     */
    getEmployees(input: GetEmployeesRequest, options?: RpcOptions): UnaryCall<GetEmployeesRequest, GetEmployeesResponse> {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetEmployeesRequest, GetEmployeesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateEmployee(user.Employee) returns (user.Employee);
     */
    createEmployee(input: Employee, options?: RpcOptions): UnaryCall<Employee, Employee> {
        const method = this.methods[45], opt = this._transport.mergeOptions(options);
        return stackIntercept<Employee, Employee>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EditEmployee(user.Employee) returns (user.Employee);
     */
    editEmployee(input: Employee, options?: RpcOptions): UnaryCall<Employee, Employee> {
        const method = this.methods[46], opt = this._transport.mergeOptions(options);
        return stackIntercept<Employee, Employee>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ResetEmployeePassword(user.Employee) returns (user.Employee);
     */
    resetEmployeePassword(input: Employee, options?: RpcOptions): UnaryCall<Employee, Employee> {
        const method = this.methods[47], opt = this._transport.mergeOptions(options);
        return stackIntercept<Employee, Employee>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ChangeEmployeePassword(user.Employee) returns (user.Employee);
     */
    changeEmployeePassword(input: Employee, options?: RpcOptions): UnaryCall<Employee, Employee> {
        const method = this.methods[48], opt = this._transport.mergeOptions(options);
        return stackIntercept<Employee, Employee>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteEmployee(user.Employee) returns (user.Employee);
     */
    deleteEmployee(input: Employee, options?: RpcOptions): UnaryCall<Employee, Employee> {
        const method = this.methods[49], opt = this._transport.mergeOptions(options);
        return stackIntercept<Employee, Employee>("unary", this._transport, method, opt, input);
    }
    /**
     * BackOffice
     *
     * @generated from protobuf rpc: GetBackOfficeUsers(user.GetBackOfficeUsersRequest) returns (user.GetBackOfficeUsersResponse);
     */
    getBackOfficeUsers(input: GetBackOfficeUsersRequest, options?: RpcOptions): UnaryCall<GetBackOfficeUsersRequest, GetBackOfficeUsersResponse> {
        const method = this.methods[50], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetBackOfficeUsersRequest, GetBackOfficeUsersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBackOfficePermissionMap(google.protobuf.Empty) returns (user.BoPermissionMap);
     */
    getBackOfficePermissionMap(input: Empty, options?: RpcOptions): UnaryCall<Empty, BoPermissionMap> {
        const method = this.methods[51], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, BoPermissionMap>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateBackOfficeUserPermission(user.BackOfficeUser) returns (common.EmptyError);
     */
    updateBackOfficeUserPermission(input: BackOfficeUser, options?: RpcOptions): UnaryCall<BackOfficeUser, EmptyError> {
        const method = this.methods[52], opt = this._transport.mergeOptions(options);
        return stackIntercept<BackOfficeUser, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateEditBackOfficeUser(user.BackOfficeUser) returns (user.BackOfficeUser);
     */
    createEditBackOfficeUser(input: BackOfficeUser, options?: RpcOptions): UnaryCall<BackOfficeUser, BackOfficeUser> {
        const method = this.methods[53], opt = this._transport.mergeOptions(options);
        return stackIntercept<BackOfficeUser, BackOfficeUser>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteBackOfficeUser(user.BackOfficeUser) returns (user.BackOfficeUser);
     */
    deleteBackOfficeUser(input: BackOfficeUser, options?: RpcOptions): UnaryCall<BackOfficeUser, BackOfficeUser> {
        const method = this.methods[54], opt = this._transport.mergeOptions(options);
        return stackIntercept<BackOfficeUser, BackOfficeUser>("unary", this._transport, method, opt, input);
    }
    /**
     * Agent
     *
     * @generated from protobuf rpc: GetAgents(user.GetAgentsRequest) returns (user.GetAgentsResponse);
     */
    getAgents(input: GetAgentsRequest, options?: RpcOptions): UnaryCall<GetAgentsRequest, GetAgentsResponse> {
        const method = this.methods[55], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAgentsRequest, GetAgentsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateEditAgent(user.Agent) returns (user.Agent);
     */
    createEditAgent(input: Agent, options?: RpcOptions): UnaryCall<Agent, Agent> {
        const method = this.methods[56], opt = this._transport.mergeOptions(options);
        return stackIntercept<Agent, Agent>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteAgent(user.Agent) returns (user.Agent);
     */
    deleteAgent(input: Agent, options?: RpcOptions): UnaryCall<Agent, Agent> {
        const method = this.methods[57], opt = this._transport.mergeOptions(options);
        return stackIntercept<Agent, Agent>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAgentCommissionAndLimit(user.Agent) returns (user.GetAgentCommisionAndLimitResponse);
     */
    getAgentCommissionAndLimit(input: Agent, options?: RpcOptions): UnaryCall<Agent, GetAgentCommisionAndLimitResponse> {
        const method = this.methods[58], opt = this._transport.mergeOptions(options);
        return stackIntercept<Agent, GetAgentCommisionAndLimitResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AgentPayin(user.AgentPayinRequest) returns (user.GetAgentCommisionAndLimitResponse);
     */
    agentPayin(input: AgentPayinRequest, options?: RpcOptions): UnaryCall<AgentPayinRequest, GetAgentCommisionAndLimitResponse> {
        const method = this.methods[59], opt = this._transport.mergeOptions(options);
        return stackIntercept<AgentPayinRequest, GetAgentCommisionAndLimitResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Landbase players
     *
     * @generated from protobuf rpc: GetLandbasePlayersBO(google.protobuf.Empty) returns (user.GetLandbasePlayersResponse);
     */
    getLandbasePlayersBO(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetLandbasePlayersResponse> {
        const method = this.methods[60], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, GetLandbasePlayersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBettingPlaceLandbasePlayers(user.GetLandbasePlayersRequest) returns (user.GetLandbasePlayersResponse);
     */
    getBettingPlaceLandbasePlayers(input: GetLandbasePlayersRequest, options?: RpcOptions): UnaryCall<GetLandbasePlayersRequest, GetLandbasePlayersResponse> {
        const method = this.methods[61], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetLandbasePlayersRequest, GetLandbasePlayersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateLandbasePlayer(user.LandbasePlayer) returns (user.LandbasePlayer);
     */
    createLandbasePlayer(input: LandbasePlayer, options?: RpcOptions): UnaryCall<LandbasePlayer, LandbasePlayer> {
        const method = this.methods[62], opt = this._transport.mergeOptions(options);
        return stackIntercept<LandbasePlayer, LandbasePlayer>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EditLandbasePlayer(user.LandbasePlayer) returns (user.LandbasePlayer);
     */
    editLandbasePlayer(input: LandbasePlayer, options?: RpcOptions): UnaryCall<LandbasePlayer, LandbasePlayer> {
        const method = this.methods[63], opt = this._transport.mergeOptions(options);
        return stackIntercept<LandbasePlayer, LandbasePlayer>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteLandbasePlayer(user.LandbasePlayer) returns (common.EmptyError);
     */
    deleteLandbasePlayer(input: LandbasePlayer, options?: RpcOptions): UnaryCall<LandbasePlayer, EmptyError> {
        const method = this.methods[64], opt = this._transport.mergeOptions(options);
        return stackIntercept<LandbasePlayer, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * BettingMachine
     *
     * @generated from protobuf rpc: GetBettingMachines(user.GetBettingMachinesRequest) returns (user.GetBettingMachinesResponse);
     */
    getBettingMachines(input: GetBettingMachinesRequest, options?: RpcOptions): UnaryCall<GetBettingMachinesRequest, GetBettingMachinesResponse> {
        const method = this.methods[65], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetBettingMachinesRequest, GetBettingMachinesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateBettingMachine(user.BettingMachine) returns (user.BettingMachine);
     */
    createBettingMachine(input: BettingMachine, options?: RpcOptions): UnaryCall<BettingMachine, BettingMachine> {
        const method = this.methods[66], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingMachine, BettingMachine>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EditBettingMachine(user.BettingMachine) returns (user.BettingMachine);
     */
    editBettingMachine(input: BettingMachine, options?: RpcOptions): UnaryCall<BettingMachine, BettingMachine> {
        const method = this.methods[67], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingMachine, BettingMachine>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteBettingMachine(user.BettingMachine) returns (user.BettingMachine);
     */
    deleteBettingMachine(input: BettingMachine, options?: RpcOptions): UnaryCall<BettingMachine, BettingMachine> {
        const method = this.methods[68], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingMachine, BettingMachine>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBettingMachineByUsernameAndToken(user.GetBettingMachineByUsernameAndTokenRequest) returns (user.GetBettingMachineByUsernameAndTokenResponse);
     */
    getBettingMachineByUsernameAndToken(input: GetBettingMachineByUsernameAndTokenRequest, options?: RpcOptions): UnaryCall<GetBettingMachineByUsernameAndTokenRequest, GetBettingMachineByUsernameAndTokenResponse> {
        const method = this.methods[69], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetBettingMachineByUsernameAndTokenRequest, GetBettingMachineByUsernameAndTokenResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAllBettingMachinesForBettingPlace(user.BettingPlaceId) returns (user.GetBettingMachinesResponse);
     */
    getAllBettingMachinesForBettingPlace(input: BettingPlaceId, options?: RpcOptions): UnaryCall<BettingPlaceId, GetBettingMachinesResponse> {
        const method = this.methods[70], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingPlaceId, GetBettingMachinesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLiveBettingMachines(google.protobuf.Empty) returns (user.GetLiveBettingMachinesResponse);
     */
    getLiveBettingMachines(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetLiveBettingMachinesResponse> {
        const method = this.methods[71], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, GetLiveBettingMachinesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EmptyBettingMachine(user.BettingMachine) returns (common.EmptyError);
     */
    emptyBettingMachine(input: BettingMachine, options?: RpcOptions): UnaryCall<BettingMachine, EmptyError> {
        const method = this.methods[72], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingMachine, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * BettinMachineExternalLinks
     *
     * @generated from protobuf rpc: CreateBettingMachineExternalLink(user.BettingMachineExternalLink) returns (user.BettingMachineExternalLink);
     */
    createBettingMachineExternalLink(input: BettingMachineExternalLink, options?: RpcOptions): UnaryCall<BettingMachineExternalLink, BettingMachineExternalLink> {
        const method = this.methods[73], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingMachineExternalLink, BettingMachineExternalLink>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteBettingMachineExternalLink(user.BettingMachineExternalLink) returns (user.BettingMachineExternalLink);
     */
    deleteBettingMachineExternalLink(input: BettingMachineExternalLink, options?: RpcOptions): UnaryCall<BettingMachineExternalLink, BettingMachineExternalLink> {
        const method = this.methods[74], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingMachineExternalLink, BettingMachineExternalLink>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EditBettingMachineExternalLink(user.BettingMachineExternalLink) returns (user.BettingMachineExternalLink);
     */
    editBettingMachineExternalLink(input: BettingMachineExternalLink, options?: RpcOptions): UnaryCall<BettingMachineExternalLink, BettingMachineExternalLink> {
        const method = this.methods[75], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingMachineExternalLink, BettingMachineExternalLink>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBettingMachineExternalLinks(google.protobuf.Empty) returns (user.BettingMachineExternalLinks);
     */
    getBettingMachineExternalLinks(input: Empty, options?: RpcOptions): UnaryCall<Empty, BettingMachineExternalLinks> {
        const method = this.methods[76], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, BettingMachineExternalLinks>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetBettingMachineExternalLink(user.BettingMachineExternalLink) returns (google.protobuf.Empty);
     */
    setBettingMachineExternalLink(input: BettingMachineExternalLink, options?: RpcOptions): UnaryCall<BettingMachineExternalLink, Empty> {
        const method = this.methods[77], opt = this._transport.mergeOptions(options);
        return stackIntercept<BettingMachineExternalLink, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * BettingMachineMenu
     *
     * @generated from protobuf rpc: CreateNewBMMenuItem(user.NewBMMenuItem) returns (user.BMMenuItem);
     */
    createNewBMMenuItem(input: NewBMMenuItem, options?: RpcOptions): UnaryCall<NewBMMenuItem, BMMenuItem> {
        const method = this.methods[78], opt = this._transport.mergeOptions(options);
        return stackIntercept<NewBMMenuItem, BMMenuItem>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EditBMMenuItem(user.EditBMMenuItemRequest) returns (user.BMMenuItem);
     */
    editBMMenuItem(input: EditBMMenuItemRequest, options?: RpcOptions): UnaryCall<EditBMMenuItemRequest, BMMenuItem> {
        const method = this.methods[79], opt = this._transport.mergeOptions(options);
        return stackIntercept<EditBMMenuItemRequest, BMMenuItem>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAllBMMenuItems(google.protobuf.Empty) returns (user.AllBMMenuItems);
     */
    getAllBMMenuItems(input: Empty, options?: RpcOptions): UnaryCall<Empty, AllBMMenuItems> {
        const method = this.methods[80], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, AllBMMenuItems>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateGlobalActiveMenu(user.AllBMMenuItems) returns (common.EmptyError);
     */
    updateGlobalActiveMenu(input: AllBMMenuItems, options?: RpcOptions): UnaryCall<AllBMMenuItems, EmptyError> {
        const method = this.methods[81], opt = this._transport.mergeOptions(options);
        return stackIntercept<AllBMMenuItems, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateActiveMenuForIndependentBM(user.IndependentBMMenuItem) returns (common.EmptyError);
     */
    updateActiveMenuForIndependentBM(input: IndependentBMMenuItem, options?: RpcOptions): UnaryCall<IndependentBMMenuItem, EmptyError> {
        const method = this.methods[82], opt = this._transport.mergeOptions(options);
        return stackIntercept<IndependentBMMenuItem, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateGlobalAllActiveMenu(user.AllBMMenuItems) returns (common.EmptyError);
     */
    updateGlobalAllActiveMenu(input: AllBMMenuItems, options?: RpcOptions): UnaryCall<AllBMMenuItems, EmptyError> {
        const method = this.methods[83], opt = this._transport.mergeOptions(options);
        return stackIntercept<AllBMMenuItems, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetGlobalActiveMenu(google.protobuf.Empty) returns (user.AllBMMenuItems);
     */
    getGlobalActiveMenu(input: Empty, options?: RpcOptions): UnaryCall<Empty, AllBMMenuItems> {
        const method = this.methods[84], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, AllBMMenuItems>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetIndependentActiveMenu(user.GetBettingMachineDevicesRequest) returns (user.AllBMMenuItems);
     */
    getIndependentActiveMenu(input: GetBettingMachineDevicesRequest, options?: RpcOptions): UnaryCall<GetBettingMachineDevicesRequest, AllBMMenuItems> {
        const method = this.methods[85], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetBettingMachineDevicesRequest, AllBMMenuItems>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteBMMenuItem(user.AllBMMenuItems) returns (common.EmptyError);
     */
    deleteBMMenuItem(input: AllBMMenuItems, options?: RpcOptions): UnaryCall<AllBMMenuItems, EmptyError> {
        const method = this.methods[86], opt = this._transport.mergeOptions(options);
        return stackIntercept<AllBMMenuItems, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBaseActiveMenu(user.GetBettingMachineDevicesRequest) returns (user.AllBMMenuItems);
     */
    getBaseActiveMenu(input: GetBettingMachineDevicesRequest, options?: RpcOptions): UnaryCall<GetBettingMachineDevicesRequest, AllBMMenuItems> {
        const method = this.methods[87], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetBettingMachineDevicesRequest, AllBMMenuItems>("unary", this._transport, method, opt, input);
    }
    /**
     * BettingMachineHeader
     *
     * @generated from protobuf rpc: UpdateHeaderItems(user.AllBMMenuItems) returns (common.EmptyError);
     */
    updateHeaderItems(input: AllBMMenuItems, options?: RpcOptions): UnaryCall<AllBMMenuItems, EmptyError> {
        const method = this.methods[88], opt = this._transport.mergeOptions(options);
        return stackIntercept<AllBMMenuItems, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateHeaderItemsBP(user.AllBMMenuItems) returns (common.EmptyError);
     */
    updateHeaderItemsBP(input: AllBMMenuItems, options?: RpcOptions): UnaryCall<AllBMMenuItems, EmptyError> {
        const method = this.methods[89], opt = this._transport.mergeOptions(options);
        return stackIntercept<AllBMMenuItems, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateHeaderItemsBM(user.AllBMMenuItems) returns (common.EmptyError);
     */
    updateHeaderItemsBM(input: AllBMMenuItems, options?: RpcOptions): UnaryCall<AllBMMenuItems, EmptyError> {
        const method = this.methods[90], opt = this._transport.mergeOptions(options);
        return stackIntercept<AllBMMenuItems, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetHeaderItems(user.GetBettingMachineDevicesRequest) returns (user.AllBMMenuItems);
     */
    getHeaderItems(input: GetBettingMachineDevicesRequest, options?: RpcOptions): UnaryCall<GetBettingMachineDevicesRequest, AllBMMenuItems> {
        const method = this.methods[91], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetBettingMachineDevicesRequest, AllBMMenuItems>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBPHeaderItems(user.GetBettingMachineDevicesRequest) returns (user.AllBMMenuItems);
     */
    getBPHeaderItems(input: GetBettingMachineDevicesRequest, options?: RpcOptions): UnaryCall<GetBettingMachineDevicesRequest, AllBMMenuItems> {
        const method = this.methods[92], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetBettingMachineDevicesRequest, AllBMMenuItems>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBMHeaderItems(user.GetBettingMachineDevicesRequest) returns (user.AllBMMenuItems);
     */
    getBMHeaderItems(input: GetBettingMachineDevicesRequest, options?: RpcOptions): UnaryCall<GetBettingMachineDevicesRequest, AllBMMenuItems> {
        const method = this.methods[93], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetBettingMachineDevicesRequest, AllBMMenuItems>("unary", this._transport, method, opt, input);
    }
    /**
     * BettingMachineWS
     *
     * @generated from protobuf rpc: ExecuteCommand(user.BMCommandRequest) returns (common.EmptyError);
     */
    executeCommand(input: BMCommandRequest, options?: RpcOptions): UnaryCall<BMCommandRequest, EmptyError> {
        const method = this.methods[94], opt = this._transport.mergeOptions(options);
        return stackIntercept<BMCommandRequest, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetSecondaryBMWindow(user.BMSecondaryWindow) returns (common.EmptyError);
     */
    setSecondaryBMWindow(input: BMSecondaryWindow, options?: RpcOptions): UnaryCall<BMSecondaryWindow, EmptyError> {
        const method = this.methods[95], opt = this._transport.mergeOptions(options);
        return stackIntercept<BMSecondaryWindow, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSecondaryBMWindow(user.BMSecondaryWindow) returns (user.BMSecondaryWindow);
     */
    getSecondaryBMWindow(input: BMSecondaryWindow, options?: RpcOptions): UnaryCall<BMSecondaryWindow, BMSecondaryWindow> {
        const method = this.methods[96], opt = this._transport.mergeOptions(options);
        return stackIntercept<BMSecondaryWindow, BMSecondaryWindow>("unary", this._transport, method, opt, input);
    }
}
