/* React modules */

/* Our modules */
import { UserStatuses } from 'modules/user/user.models';
import useStores from 'common/hooks/useStores';
import UploadDocumentsForm from 'modules/user/ui/UserSettings/Verification/UploadDocumentsForm';
import { logger } from 'libs/common-helpers';
import './Verification.scss';

/* 3rd Party modules */
import Compressor from 'compressorjs';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const toBase64 = (fileList: FileList) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileList[0]);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const extractValue = (value: any) => {
  if (Array.isArray(value)) {
    return value.join('');
  }

  if (value?.constructor === Date) {
    return value.toISOString();
  }

  if (value?.constructor === Object) {
    return value.value;
  }

  return value;
};

const formatPayload = (data: any) => {
  return Object.keys(data).reduce((acc, key) => {
    return { ...acc, [key]: extractValue(data[key]) };
  }, {});
};

const formatFiles = async (data: any) => {
  let temp: any = {};

  for (const key in data) {
    if (data[key]?.constructor === FileList) {
      const fileValue = await toBase64(data[key]);
      temp[key] = fileValue;
    } else {
      temp[key] = data[key];
    }
  }
  return temp;
};

const Verification = observer(() => {
  const {
    userStore: { user, uploadUserDocuments, updateUserStatus },
  } = useStores();
  const { t } = useTranslation();

  const getRequestAndPayload = async (data: any) => {
    const filesData = await formatFiles(data);
    const formattedPayload = formatPayload(filesData);
    return {
      request: uploadUserDocuments,
      payload: formattedPayload,
    };
  };

  const compressImages = (files: any) => {
    Object.keys(files).forEach((key: string) => {
      if (files[key]) {
        new Compressor(files[key][0], {
          quality: 0.7,
          success: (compressedResult) => {
            files[key] = [compressedResult];
          },
        });
      }
    });
    return files;
  };

  const submit = async (files: any, documentType: any, expiryDate: string) => {
    if (files) {
      delete files.document_expiry_date;
    }

    const compressedFiles = compressImages(files);
    const { request, payload } = await getRequestAndPayload(compressedFiles);

    try {
      const selectedExpiryDate = expiryDate.includes('.')
        ? `${expiryDate.split('.')[2]}-${
            expiryDate.split('.')[1].length === 1
              ? '0' + expiryDate.split('.')[1]
              : expiryDate.split('.')[1]
          }-${expiryDate.split('.')[0]}`
        : '';

      const response = await request({
        ...payload,
        document_type: documentType,
        document_expiry_date: selectedExpiryDate,
      });

      updateUserStatus(response?.data.status);
    } catch (exception: any) {
      toast.error(exception.data?.detail);

      logger('Verification -> submit -> exception', exception);
    }
  };

  return (
    <div className="verification max-height">
      {user?.status === UserStatuses.ACTIVATED && (
        <UploadDocumentsForm onSubmit={submit} />
      )}
      {user?.status === UserStatuses.DOCUMENTS_UPLOADED && (
        <div className="verification__in-progress">
          {t('verification.verification-in-progress')}
        </div>
      )}
      {user?.status === UserStatuses.VALIDATED && (
        <div className="verification__in-progress">
          {t('verification.verification-complete')}
        </div>
      )}
    </div>
  );
});

export default Verification;
