// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/notifications/notifications.proto" (package "notifications", syntax proto3)
// tslint:disable
import { EmptyError } from "../common/common_pb";
import { Empty } from "../../google/protobuf/empty_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { EventResultPeriod } from "../common/common_pb";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
import { GetOddsResponse } from "../odds/odds_pb";
/**
 * @generated from protobuf message notifications.ConfirmBmMessageRequest
 */
export interface ConfirmBmMessageRequest {
    /**
     * @generated from protobuf field: string message_uuid = 1;
     */
    messageUuid: string;
}
/**
 * @generated from protobuf message notifications.WebNotification
 */
export interface WebNotification {
    /**
     * @generated from protobuf field: string data = 1;
     */
    data: string;
}
/**
 * @generated from protobuf message notifications.UserConfirmation
 */
export interface UserConfirmation {
    /**
     * @generated from protobuf field: string uuid = 1;
     */
    uuid: string;
}
/**
 * @generated from protobuf message notifications.SystemNotificationRequest
 */
export interface SystemNotificationRequest {
    /**
     * @generated from protobuf field: string data = 1;
     */
    data: string;
}
/**
 * @generated from protobuf message notifications.UserNotification
 */
export interface UserNotification {
    /**
     * @generated from protobuf field: string data = 1;
     */
    data: string;
    /**
     * @generated from protobuf field: int32 user_id = 2;
     */
    userId: number;
    /**
     * @generated from protobuf field: int32 role_id = 3;
     */
    roleId: number;
}
/**
 * @generated from protobuf message notifications.NotificationResponse
 */
export interface NotificationResponse {
    /**
     * @generated from protobuf field: string status = 3;
     */
    status: string;
}
/**
 * @generated from protobuf message notifications.OutcomeUpdateRequest
 */
export interface OutcomeUpdateRequest {
    /**
     * @generated from protobuf field: int32 outcome_id = 1;
     */
    outcomeId: number;
    /**
     * @generated from protobuf field: string outcome_name = 2;
     */
    outcomeName: string;
    /**
     * @generated from protobuf field: string outcome_code = 3;
     */
    outcomeCode: string;
    /**
     * @generated from protobuf field: string outcome_group = 4;
     */
    outcomeGroup: string;
    /**
     * @generated from protobuf field: string outcome_tic = 5;
     */
    outcomeTic: string;
}
/**
 * @generated from protobuf message notifications.ManualApprovalRequest
 */
export interface ManualApprovalRequest {
    /**
     * @generated from protobuf field: repeated string approval_errors = 1;
     */
    approvalErrors: string[];
    /**
     * @generated from protobuf field: odds.GetOddsResponse odds = 2;
     */
    odds?: GetOddsResponse;
    /**
     * @generated from protobuf field: string slip_data = 3;
     */
    slipData: string;
    /**
     * @generated from protobuf field: int32 approval_id = 4;
     */
    approvalId: number;
    /**
     * @generated from protobuf field: string slip_id = 5;
     */
    slipId: string;
    /**
     * @generated from protobuf field: string slip_type = 6;
     */
    slipType: string;
    /**
     * @generated from protobuf field: string short_uuid = 7;
     */
    shortUuid: string;
    /**
     * @generated from protobuf field: bool auto = 8;
     */
    auto: boolean;
}
/**
 * @generated from protobuf message notifications.ManualCashOutRequest
 */
export interface ManualCashOutRequest {
    /**
     * @generated from protobuf field: string slip_id = 1;
     */
    slipId: string;
    /**
     * @generated from protobuf field: int32 approval_id = 2;
     */
    approvalId: number;
    /**
     * @generated from protobuf field: string slip_data = 3;
     */
    slipData: string;
    /**
     * @generated from protobuf field: repeated notifications.CashOutOdd odds = 4;
     */
    odds: CashOutOdd[];
    /**
     * @generated from protobuf field: bool auto = 5;
     */
    auto: boolean;
}
/**
 * @generated from protobuf message notifications.LimitsRequest
 */
export interface LimitsRequest {
    /**
     * @generated from protobuf field: int32 betting_place_id = 1;
     */
    bettingPlaceId: number;
    /**
     * @generated from protobuf field: string betting_place_name = 2;
     */
    bettingPlaceName: string;
}
/**
 * @generated from protobuf message notifications.CashOutOdd
 */
export interface CashOutOdd {
    /**
     * @generated from protobuf field: string odd_id = 27;
     */
    oddId: string;
    /**
     * @generated from protobuf field: double odd_value = 28;
     */
    oddValue: number;
    /**
     * @generated from protobuf field: double current_value = 29;
     */
    currentValue: number;
    /**
     * @generated from protobuf field: double limit = 30;
     */
    limit: number;
    /**
     * @generated from protobuf field: string event_name = 31;
     */
    eventName: string;
    /**
     * @generated from protobuf field: string market_name = 32;
     */
    marketName: string;
    /**
     * @generated from protobuf field: string outcome_name = 33;
     */
    outcomeName: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 34;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: common.EventResultPeriod result = 35;
     */
    result?: EventResultPeriod;
    /**
     * @generated from protobuf field: int32 sport_id = 43;
     */
    sportId: number;
    /**
     * @generated from protobuf field: string landbase_code = 45;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: string status = 3;
     */
    status: string;
}
/**
 * @generated from protobuf message notifications.BroadcastDoublePrintRequest
 */
export interface BroadcastDoublePrintRequest {
    /**
     * @generated from protobuf field: string name = 36;
     */
    name: string;
    /**
     * @generated from protobuf field: string bet_place_name = 41;
     */
    betPlaceName: string;
    /**
     * @generated from protobuf field: bool double_print = 37;
     */
    doublePrint: boolean;
}
/**
 * @generated from protobuf message notifications.BroadcastBlankSlipRequest
 */
export interface BroadcastBlankSlipRequest {
    /**
     * @generated from protobuf field: string name = 36;
     */
    name: string;
    /**
     * @generated from protobuf field: string bet_place_name = 41;
     */
    betPlaceName: string;
    /**
     * @generated from protobuf field: bool blank_slip = 42;
     */
    blankSlip: boolean;
}
/**
 * @generated from protobuf message notifications.BettingPlacesNotification
 */
export interface BettingPlacesNotification {
    /**
     * @generated from protobuf field: string text = 38;
     */
    text: string;
}
/**
 * @generated from protobuf message notifications.Notification
 */
export interface Notification {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created = 15;
     */
    created?: Timestamp;
    /**
     * @generated from protobuf field: string text = 38;
     */
    text: string;
}
/**
 * @generated from protobuf message notifications.GetPushNotificationsResponse
 */
export interface GetPushNotificationsResponse {
    /**
     * @generated from protobuf field: repeated notifications.Notification notifications = 39;
     */
    notifications: Notification[];
}
// @generated message type with reflection information, may provide speed optimized methods
class ConfirmBmMessageRequest$Type extends MessageType<ConfirmBmMessageRequest> {
    constructor() {
        super("notifications.ConfirmBmMessageRequest", [
            { no: 1, name: "message_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ConfirmBmMessageRequest>): ConfirmBmMessageRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageUuid = "";
        if (value !== undefined)
            reflectionMergePartial<ConfirmBmMessageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConfirmBmMessageRequest): ConfirmBmMessageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message_uuid */ 1:
                    message.messageUuid = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConfirmBmMessageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message_uuid = 1; */
        if (message.messageUuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.messageUuid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.ConfirmBmMessageRequest
 */
export const ConfirmBmMessageRequest = new ConfirmBmMessageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebNotification$Type extends MessageType<WebNotification> {
    constructor() {
        super("notifications.WebNotification", [
            { no: 1, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WebNotification>): WebNotification {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.data = "";
        if (value !== undefined)
            reflectionMergePartial<WebNotification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebNotification): WebNotification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string data */ 1:
                    message.data = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebNotification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string data = 1; */
        if (message.data !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.WebNotification
 */
export const WebNotification = new WebNotification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserConfirmation$Type extends MessageType<UserConfirmation> {
    constructor() {
        super("notifications.UserConfirmation", [
            { no: 1, name: "uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserConfirmation>): UserConfirmation {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uuid = "";
        if (value !== undefined)
            reflectionMergePartial<UserConfirmation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserConfirmation): UserConfirmation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uuid */ 1:
                    message.uuid = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserConfirmation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uuid = 1; */
        if (message.uuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uuid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.UserConfirmation
 */
export const UserConfirmation = new UserConfirmation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SystemNotificationRequest$Type extends MessageType<SystemNotificationRequest> {
    constructor() {
        super("notifications.SystemNotificationRequest", [
            { no: 1, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SystemNotificationRequest>): SystemNotificationRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.data = "";
        if (value !== undefined)
            reflectionMergePartial<SystemNotificationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SystemNotificationRequest): SystemNotificationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string data */ 1:
                    message.data = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SystemNotificationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string data = 1; */
        if (message.data !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.SystemNotificationRequest
 */
export const SystemNotificationRequest = new SystemNotificationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserNotification$Type extends MessageType<UserNotification> {
    constructor() {
        super("notifications.UserNotification", [
            { no: 1, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "role_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<UserNotification>): UserNotification {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.data = "";
        message.userId = 0;
        message.roleId = 0;
        if (value !== undefined)
            reflectionMergePartial<UserNotification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserNotification): UserNotification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string data */ 1:
                    message.data = reader.string();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* int32 role_id */ 3:
                    message.roleId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserNotification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string data = 1; */
        if (message.data !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.data);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* int32 role_id = 3; */
        if (message.roleId !== 0)
            writer.tag(3, WireType.Varint).int32(message.roleId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.UserNotification
 */
export const UserNotification = new UserNotification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationResponse$Type extends MessageType<NotificationResponse> {
    constructor() {
        super("notifications.NotificationResponse", [
            { no: 3, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NotificationResponse>): NotificationResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = "";
        if (value !== undefined)
            reflectionMergePartial<NotificationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NotificationResponse): NotificationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string status */ 3:
                    message.status = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NotificationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string status = 3; */
        if (message.status !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.NotificationResponse
 */
export const NotificationResponse = new NotificationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OutcomeUpdateRequest$Type extends MessageType<OutcomeUpdateRequest> {
    constructor() {
        super("notifications.OutcomeUpdateRequest", [
            { no: 1, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "outcome_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "outcome_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "outcome_group", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "outcome_tic", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OutcomeUpdateRequest>): OutcomeUpdateRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.outcomeId = 0;
        message.outcomeName = "";
        message.outcomeCode = "";
        message.outcomeGroup = "";
        message.outcomeTic = "";
        if (value !== undefined)
            reflectionMergePartial<OutcomeUpdateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OutcomeUpdateRequest): OutcomeUpdateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 outcome_id */ 1:
                    message.outcomeId = reader.int32();
                    break;
                case /* string outcome_name */ 2:
                    message.outcomeName = reader.string();
                    break;
                case /* string outcome_code */ 3:
                    message.outcomeCode = reader.string();
                    break;
                case /* string outcome_group */ 4:
                    message.outcomeGroup = reader.string();
                    break;
                case /* string outcome_tic */ 5:
                    message.outcomeTic = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OutcomeUpdateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 outcome_id = 1; */
        if (message.outcomeId !== 0)
            writer.tag(1, WireType.Varint).int32(message.outcomeId);
        /* string outcome_name = 2; */
        if (message.outcomeName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.outcomeName);
        /* string outcome_code = 3; */
        if (message.outcomeCode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.outcomeCode);
        /* string outcome_group = 4; */
        if (message.outcomeGroup !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.outcomeGroup);
        /* string outcome_tic = 5; */
        if (message.outcomeTic !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.outcomeTic);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.OutcomeUpdateRequest
 */
export const OutcomeUpdateRequest = new OutcomeUpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ManualApprovalRequest$Type extends MessageType<ManualApprovalRequest> {
    constructor() {
        super("notifications.ManualApprovalRequest", [
            { no: 1, name: "approval_errors", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "odds", kind: "message", T: () => GetOddsResponse },
            { no: 3, name: "slip_data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "approval_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "slip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "slip_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "short_uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "auto", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ManualApprovalRequest>): ManualApprovalRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.approvalErrors = [];
        message.slipData = "";
        message.approvalId = 0;
        message.slipId = "";
        message.slipType = "";
        message.shortUuid = "";
        message.auto = false;
        if (value !== undefined)
            reflectionMergePartial<ManualApprovalRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ManualApprovalRequest): ManualApprovalRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string approval_errors */ 1:
                    message.approvalErrors.push(reader.string());
                    break;
                case /* odds.GetOddsResponse odds */ 2:
                    message.odds = GetOddsResponse.internalBinaryRead(reader, reader.uint32(), options, message.odds);
                    break;
                case /* string slip_data */ 3:
                    message.slipData = reader.string();
                    break;
                case /* int32 approval_id */ 4:
                    message.approvalId = reader.int32();
                    break;
                case /* string slip_id */ 5:
                    message.slipId = reader.string();
                    break;
                case /* string slip_type */ 6:
                    message.slipType = reader.string();
                    break;
                case /* string short_uuid */ 7:
                    message.shortUuid = reader.string();
                    break;
                case /* bool auto */ 8:
                    message.auto = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ManualApprovalRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string approval_errors = 1; */
        for (let i = 0; i < message.approvalErrors.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.approvalErrors[i]);
        /* odds.GetOddsResponse odds = 2; */
        if (message.odds)
            GetOddsResponse.internalBinaryWrite(message.odds, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string slip_data = 3; */
        if (message.slipData !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.slipData);
        /* int32 approval_id = 4; */
        if (message.approvalId !== 0)
            writer.tag(4, WireType.Varint).int32(message.approvalId);
        /* string slip_id = 5; */
        if (message.slipId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.slipId);
        /* string slip_type = 6; */
        if (message.slipType !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.slipType);
        /* string short_uuid = 7; */
        if (message.shortUuid !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.shortUuid);
        /* bool auto = 8; */
        if (message.auto !== false)
            writer.tag(8, WireType.Varint).bool(message.auto);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.ManualApprovalRequest
 */
export const ManualApprovalRequest = new ManualApprovalRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ManualCashOutRequest$Type extends MessageType<ManualCashOutRequest> {
    constructor() {
        super("notifications.ManualCashOutRequest", [
            { no: 1, name: "slip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "approval_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "slip_data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CashOutOdd },
            { no: 5, name: "auto", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ManualCashOutRequest>): ManualCashOutRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.slipId = "";
        message.approvalId = 0;
        message.slipData = "";
        message.odds = [];
        message.auto = false;
        if (value !== undefined)
            reflectionMergePartial<ManualCashOutRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ManualCashOutRequest): ManualCashOutRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string slip_id */ 1:
                    message.slipId = reader.string();
                    break;
                case /* int32 approval_id */ 2:
                    message.approvalId = reader.int32();
                    break;
                case /* string slip_data */ 3:
                    message.slipData = reader.string();
                    break;
                case /* repeated notifications.CashOutOdd odds */ 4:
                    message.odds.push(CashOutOdd.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool auto */ 5:
                    message.auto = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ManualCashOutRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string slip_id = 1; */
        if (message.slipId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.slipId);
        /* int32 approval_id = 2; */
        if (message.approvalId !== 0)
            writer.tag(2, WireType.Varint).int32(message.approvalId);
        /* string slip_data = 3; */
        if (message.slipData !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.slipData);
        /* repeated notifications.CashOutOdd odds = 4; */
        for (let i = 0; i < message.odds.length; i++)
            CashOutOdd.internalBinaryWrite(message.odds[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bool auto = 5; */
        if (message.auto !== false)
            writer.tag(5, WireType.Varint).bool(message.auto);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.ManualCashOutRequest
 */
export const ManualCashOutRequest = new ManualCashOutRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LimitsRequest$Type extends MessageType<LimitsRequest> {
    constructor() {
        super("notifications.LimitsRequest", [
            { no: 1, name: "betting_place_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "betting_place_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LimitsRequest>): LimitsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingPlaceId = 0;
        message.bettingPlaceName = "";
        if (value !== undefined)
            reflectionMergePartial<LimitsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LimitsRequest): LimitsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 betting_place_id */ 1:
                    message.bettingPlaceId = reader.int32();
                    break;
                case /* string betting_place_name */ 2:
                    message.bettingPlaceName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LimitsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 betting_place_id = 1; */
        if (message.bettingPlaceId !== 0)
            writer.tag(1, WireType.Varint).int32(message.bettingPlaceId);
        /* string betting_place_name = 2; */
        if (message.bettingPlaceName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.bettingPlaceName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.LimitsRequest
 */
export const LimitsRequest = new LimitsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CashOutOdd$Type extends MessageType<CashOutOdd> {
    constructor() {
        super("notifications.CashOutOdd", [
            { no: 27, name: "odd_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "odd_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 29, name: "current_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 30, name: "limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 31, name: "event_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "market_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 33, name: "outcome_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "start", kind: "message", T: () => Timestamp },
            { no: 35, name: "result", kind: "message", T: () => EventResultPeriod },
            { no: 43, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 45, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CashOutOdd>): CashOutOdd {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.oddId = "";
        message.oddValue = 0;
        message.currentValue = 0;
        message.limit = 0;
        message.eventName = "";
        message.marketName = "";
        message.outcomeName = "";
        message.sportId = 0;
        message.landbaseCode = "";
        message.status = "";
        if (value !== undefined)
            reflectionMergePartial<CashOutOdd>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CashOutOdd): CashOutOdd {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string odd_id */ 27:
                    message.oddId = reader.string();
                    break;
                case /* double odd_value */ 28:
                    message.oddValue = reader.double();
                    break;
                case /* double current_value */ 29:
                    message.currentValue = reader.double();
                    break;
                case /* double limit */ 30:
                    message.limit = reader.double();
                    break;
                case /* string event_name */ 31:
                    message.eventName = reader.string();
                    break;
                case /* string market_name */ 32:
                    message.marketName = reader.string();
                    break;
                case /* string outcome_name */ 33:
                    message.outcomeName = reader.string();
                    break;
                case /* google.protobuf.Timestamp start */ 34:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* common.EventResultPeriod result */ 35:
                    message.result = EventResultPeriod.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                case /* int32 sport_id */ 43:
                    message.sportId = reader.int32();
                    break;
                case /* string landbase_code */ 45:
                    message.landbaseCode = reader.string();
                    break;
                case /* string status */ 3:
                    message.status = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CashOutOdd, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string odd_id = 27; */
        if (message.oddId !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.oddId);
        /* double odd_value = 28; */
        if (message.oddValue !== 0)
            writer.tag(28, WireType.Bit64).double(message.oddValue);
        /* double current_value = 29; */
        if (message.currentValue !== 0)
            writer.tag(29, WireType.Bit64).double(message.currentValue);
        /* double limit = 30; */
        if (message.limit !== 0)
            writer.tag(30, WireType.Bit64).double(message.limit);
        /* string event_name = 31; */
        if (message.eventName !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.eventName);
        /* string market_name = 32; */
        if (message.marketName !== "")
            writer.tag(32, WireType.LengthDelimited).string(message.marketName);
        /* string outcome_name = 33; */
        if (message.outcomeName !== "")
            writer.tag(33, WireType.LengthDelimited).string(message.outcomeName);
        /* google.protobuf.Timestamp start = 34; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(34, WireType.LengthDelimited).fork(), options).join();
        /* common.EventResultPeriod result = 35; */
        if (message.result)
            EventResultPeriod.internalBinaryWrite(message.result, writer.tag(35, WireType.LengthDelimited).fork(), options).join();
        /* int32 sport_id = 43; */
        if (message.sportId !== 0)
            writer.tag(43, WireType.Varint).int32(message.sportId);
        /* string landbase_code = 45; */
        if (message.landbaseCode !== "")
            writer.tag(45, WireType.LengthDelimited).string(message.landbaseCode);
        /* string status = 3; */
        if (message.status !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.CashOutOdd
 */
export const CashOutOdd = new CashOutOdd$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BroadcastDoublePrintRequest$Type extends MessageType<BroadcastDoublePrintRequest> {
    constructor() {
        super("notifications.BroadcastDoublePrintRequest", [
            { no: 36, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 41, name: "bet_place_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 37, name: "double_print", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BroadcastDoublePrintRequest>): BroadcastDoublePrintRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.betPlaceName = "";
        message.doublePrint = false;
        if (value !== undefined)
            reflectionMergePartial<BroadcastDoublePrintRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BroadcastDoublePrintRequest): BroadcastDoublePrintRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 36:
                    message.name = reader.string();
                    break;
                case /* string bet_place_name */ 41:
                    message.betPlaceName = reader.string();
                    break;
                case /* bool double_print */ 37:
                    message.doublePrint = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BroadcastDoublePrintRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 36; */
        if (message.name !== "")
            writer.tag(36, WireType.LengthDelimited).string(message.name);
        /* string bet_place_name = 41; */
        if (message.betPlaceName !== "")
            writer.tag(41, WireType.LengthDelimited).string(message.betPlaceName);
        /* bool double_print = 37; */
        if (message.doublePrint !== false)
            writer.tag(37, WireType.Varint).bool(message.doublePrint);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.BroadcastDoublePrintRequest
 */
export const BroadcastDoublePrintRequest = new BroadcastDoublePrintRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BroadcastBlankSlipRequest$Type extends MessageType<BroadcastBlankSlipRequest> {
    constructor() {
        super("notifications.BroadcastBlankSlipRequest", [
            { no: 36, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 41, name: "bet_place_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 42, name: "blank_slip", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BroadcastBlankSlipRequest>): BroadcastBlankSlipRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.betPlaceName = "";
        message.blankSlip = false;
        if (value !== undefined)
            reflectionMergePartial<BroadcastBlankSlipRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BroadcastBlankSlipRequest): BroadcastBlankSlipRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 36:
                    message.name = reader.string();
                    break;
                case /* string bet_place_name */ 41:
                    message.betPlaceName = reader.string();
                    break;
                case /* bool blank_slip */ 42:
                    message.blankSlip = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BroadcastBlankSlipRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 36; */
        if (message.name !== "")
            writer.tag(36, WireType.LengthDelimited).string(message.name);
        /* string bet_place_name = 41; */
        if (message.betPlaceName !== "")
            writer.tag(41, WireType.LengthDelimited).string(message.betPlaceName);
        /* bool blank_slip = 42; */
        if (message.blankSlip !== false)
            writer.tag(42, WireType.Varint).bool(message.blankSlip);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.BroadcastBlankSlipRequest
 */
export const BroadcastBlankSlipRequest = new BroadcastBlankSlipRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BettingPlacesNotification$Type extends MessageType<BettingPlacesNotification> {
    constructor() {
        super("notifications.BettingPlacesNotification", [
            { no: 38, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BettingPlacesNotification>): BettingPlacesNotification {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.text = "";
        if (value !== undefined)
            reflectionMergePartial<BettingPlacesNotification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BettingPlacesNotification): BettingPlacesNotification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string text */ 38:
                    message.text = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BettingPlacesNotification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string text = 38; */
        if (message.text !== "")
            writer.tag(38, WireType.LengthDelimited).string(message.text);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.BettingPlacesNotification
 */
export const BettingPlacesNotification = new BettingPlacesNotification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Notification$Type extends MessageType<Notification> {
    constructor() {
        super("notifications.Notification", [
            { no: 15, name: "created", kind: "message", T: () => Timestamp },
            { no: 38, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Notification>): Notification {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.text = "";
        if (value !== undefined)
            reflectionMergePartial<Notification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Notification): Notification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp created */ 15:
                    message.created = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.created);
                    break;
                case /* string text */ 38:
                    message.text = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Notification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp created = 15; */
        if (message.created)
            Timestamp.internalBinaryWrite(message.created, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* string text = 38; */
        if (message.text !== "")
            writer.tag(38, WireType.LengthDelimited).string(message.text);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.Notification
 */
export const Notification = new Notification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPushNotificationsResponse$Type extends MessageType<GetPushNotificationsResponse> {
    constructor() {
        super("notifications.GetPushNotificationsResponse", [
            { no: 39, name: "notifications", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Notification }
        ]);
    }
    create(value?: PartialMessage<GetPushNotificationsResponse>): GetPushNotificationsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.notifications = [];
        if (value !== undefined)
            reflectionMergePartial<GetPushNotificationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPushNotificationsResponse): GetPushNotificationsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated notifications.Notification notifications */ 39:
                    message.notifications.push(Notification.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPushNotificationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated notifications.Notification notifications = 39; */
        for (let i = 0; i < message.notifications.length; i++)
            Notification.internalBinaryWrite(message.notifications[i], writer.tag(39, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message notifications.GetPushNotificationsResponse
 */
export const GetPushNotificationsResponse = new GetPushNotificationsResponse$Type();
/**
 * @generated ServiceType for protobuf service notifications.NotificationsService
 */
export const NotificationsService = new ServiceType("notifications.NotificationsService", [
    { name: "NotifyUser", options: {}, I: UserNotification, O: NotificationResponse },
    { name: "ConfirmNotification", options: {}, I: UserConfirmation, O: NotificationResponse },
    { name: "NotifyBettingPlaces", options: {}, I: BettingPlacesNotification, O: NotificationResponse },
    { name: "SystemNotification", options: {}, I: SystemNotificationRequest, O: NotificationResponse },
    { name: "GetPushNotifications", options: {}, I: Empty, O: GetPushNotificationsResponse },
    { name: "BroadcastDoublePrint", options: {}, I: BroadcastDoublePrintRequest, O: NotificationResponse },
    { name: "BroadcastBlankSlip", options: {}, I: BroadcastBlankSlipRequest, O: NotificationResponse },
    { name: "ValidationRulesUpdate", options: {}, I: Empty, O: NotificationResponse },
    { name: "WebUsersNotification", options: {}, I: WebNotification, O: NotificationResponse },
    { name: "OutcomeUpdateNotification", options: {}, I: OutcomeUpdateRequest, O: NotificationResponse },
    { name: "ConfirmBmMessage", options: {}, I: ConfirmBmMessageRequest, O: EmptyError }
]);
/**
 * @generated ServiceType for protobuf service notifications.ApproveNotificationsService
 */
export const ApproveNotificationsService = new ServiceType("notifications.ApproveNotificationsService", [
    { name: "SendForManualApproval", options: {}, I: ManualApprovalRequest, O: NotificationResponse },
    { name: "RemoveFromManualApproval", options: {}, I: ManualApprovalRequest, O: NotificationResponse },
    { name: "SendForManualCashOut", options: {}, I: ManualCashOutRequest, O: NotificationResponse },
    { name: "RemoveFromManualCashOut", options: {}, I: ManualCashOutRequest, O: NotificationResponse },
    { name: "SendMinimumLimits", options: {}, I: LimitsRequest, O: NotificationResponse },
    { name: "RequestNewLimits", options: {}, I: LimitsRequest, O: NotificationResponse }
]);
