// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/odds/web_odds.proto" (package "web_odds", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { WebOddsService } from "./web_odds_pb";
import type { StreamUrl } from "./web_odds_pb";
import type { GetStreamUrlRequest } from "./web_odds_pb";
import type { FeaturedEvents } from "./web_odds_pb";
import type { LandingPage } from "./web_odds_pb";
import type { Competition } from "./web_odds_pb";
import type { CompetitionId } from "./web_odds_pb";
import type { PlayerOutrightMarkets } from "./web_odds_pb";
import type { LiveDisabled } from "./web_odds_pb";
import type { WebStreamEvent } from "./web_odds_pb";
import type { EventStreamString } from "./web_odds_pb";
import type { EventStream } from "./web_odds_pb";
import type { Empty } from "../../google/protobuf/empty_pb";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { InitialDataResponse } from "./web_odds_pb";
import type { InitialDataRequest } from "./web_odds_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service web_odds.WebOddsService
 */
export interface IWebOddsServiceClient {
    /**
     * @generated from protobuf rpc: GetInitialData(web_odds.InitialDataRequest) returns (web_odds.InitialDataResponse);
     */
    getInitialData(input: InitialDataRequest, options?: RpcOptions): UnaryCall<InitialDataRequest, InitialDataResponse>;
    /**
     * @generated from protobuf rpc: EventsStream(google.protobuf.Empty) returns (stream web_odds.EventStream);
     */
    eventsStream(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStream>;
    /**
     * @generated from protobuf rpc: EventsStreamString(google.protobuf.Empty) returns (stream web_odds.EventStreamString);
     */
    eventsStreamString(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStreamString>;
    /**
     * @generated from protobuf rpc: GetSingleEvent(web_odds.WebStreamEvent) returns (web_odds.WebStreamEvent);
     */
    getSingleEvent(input: WebStreamEvent, options?: RpcOptions): UnaryCall<WebStreamEvent, WebStreamEvent>;
    /**
     * @generated from protobuf rpc: GetLiveDisabled(google.protobuf.Empty) returns (web_odds.LiveDisabled);
     */
    getLiveDisabled(input: Empty, options?: RpcOptions): UnaryCall<Empty, LiveDisabled>;
    /**
     * @generated from protobuf rpc: GetPlayerOutrightMarkets(google.protobuf.Empty) returns (web_odds.PlayerOutrightMarkets);
     */
    getPlayerOutrightMarkets(input: Empty, options?: RpcOptions): UnaryCall<Empty, PlayerOutrightMarkets>;
    /**
     * @generated from protobuf rpc: GetMissingCompetition(web_odds.CompetitionId) returns (web_odds.Competition);
     */
    getMissingCompetition(input: CompetitionId, options?: RpcOptions): UnaryCall<CompetitionId, Competition>;
    /**
     * @generated from protobuf rpc: GetLandingPage(google.protobuf.Empty) returns (web_odds.LandingPage);
     */
    getLandingPage(input: Empty, options?: RpcOptions): UnaryCall<Empty, LandingPage>;
    /**
     * @generated from protobuf rpc: GetFeaturedEvents(google.protobuf.Empty) returns (web_odds.FeaturedEvents);
     */
    getFeaturedEvents(input: Empty, options?: RpcOptions): UnaryCall<Empty, FeaturedEvents>;
    /**
     * @generated from protobuf rpc: GetStreamUrl(web_odds.GetStreamUrlRequest) returns (web_odds.StreamUrl);
     */
    getStreamUrl(input: GetStreamUrlRequest, options?: RpcOptions): UnaryCall<GetStreamUrlRequest, StreamUrl>;
}
/**
 * @generated from protobuf service web_odds.WebOddsService
 */
export class WebOddsServiceClient implements IWebOddsServiceClient, ServiceInfo {
    typeName = WebOddsService.typeName;
    methods = WebOddsService.methods;
    options = WebOddsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetInitialData(web_odds.InitialDataRequest) returns (web_odds.InitialDataResponse);
     */
    getInitialData(input: InitialDataRequest, options?: RpcOptions): UnaryCall<InitialDataRequest, InitialDataResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<InitialDataRequest, InitialDataResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EventsStream(google.protobuf.Empty) returns (stream web_odds.EventStream);
     */
    eventsStream(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStream> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, EventStream>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EventsStreamString(google.protobuf.Empty) returns (stream web_odds.EventStreamString);
     */
    eventsStreamString(input: Empty, options?: RpcOptions): ServerStreamingCall<Empty, EventStreamString> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, EventStreamString>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSingleEvent(web_odds.WebStreamEvent) returns (web_odds.WebStreamEvent);
     */
    getSingleEvent(input: WebStreamEvent, options?: RpcOptions): UnaryCall<WebStreamEvent, WebStreamEvent> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<WebStreamEvent, WebStreamEvent>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLiveDisabled(google.protobuf.Empty) returns (web_odds.LiveDisabled);
     */
    getLiveDisabled(input: Empty, options?: RpcOptions): UnaryCall<Empty, LiveDisabled> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, LiveDisabled>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPlayerOutrightMarkets(google.protobuf.Empty) returns (web_odds.PlayerOutrightMarkets);
     */
    getPlayerOutrightMarkets(input: Empty, options?: RpcOptions): UnaryCall<Empty, PlayerOutrightMarkets> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, PlayerOutrightMarkets>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMissingCompetition(web_odds.CompetitionId) returns (web_odds.Competition);
     */
    getMissingCompetition(input: CompetitionId, options?: RpcOptions): UnaryCall<CompetitionId, Competition> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<CompetitionId, Competition>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLandingPage(google.protobuf.Empty) returns (web_odds.LandingPage);
     */
    getLandingPage(input: Empty, options?: RpcOptions): UnaryCall<Empty, LandingPage> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, LandingPage>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetFeaturedEvents(google.protobuf.Empty) returns (web_odds.FeaturedEvents);
     */
    getFeaturedEvents(input: Empty, options?: RpcOptions): UnaryCall<Empty, FeaturedEvents> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, FeaturedEvents>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetStreamUrl(web_odds.GetStreamUrlRequest) returns (web_odds.StreamUrl);
     */
    getStreamUrl(input: GetStreamUrlRequest, options?: RpcOptions): UnaryCall<GetStreamUrlRequest, StreamUrl> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetStreamUrlRequest, StreamUrl>("unary", this._transport, method, opt, input);
    }
}
