/* React modules */
import { useContext } from 'react';

/* Our modules */
import { storeContext } from 'app/store-context';

/* 3rd Party modules */

const useStores = () => useContext(storeContext);

export default useStores;
