/* React modules */

/* Our modules */
import { CashDropWebServiceClient } from 'generated-proto/proto/loyalty/cashdrop_pb.client';
import { Drop } from 'generated-proto/proto/loyalty/cashdrop_pb';
import { getRpcTransport, getApiUrl } from 'libs/urlBuilder';

/* 3rd Party modules */
import { Empty } from 'generated-proto/google/protobuf/empty_pb';

class CashDropAPI {
  private client: CashDropWebServiceClient;

  constructor() {
    this.client = new CashDropWebServiceClient(getRpcTransport(getApiUrl()));
  }

  getDailyDrops(accessToken: string) {
    const request: Empty = {};

    return this.client.getDailyDrops(request, { meta: { accessToken } });
  }

  collect(dropId: number, accessToken: string) {
    const request: Drop = {
      id: dropId,
      start: 0,
      collectable: false,
      collectedBy: [],
      isDisabled: false,
    };

    return this.client.collect(request, { meta: { accessToken } });
  }

  getCashDropStatus(accessToken: string) {
    const request: Empty = {};

    return this.client.getCashDropGlobalDisabled(request, {
      meta: { accessToken },
    });
  }
}

export { CashDropAPI };
