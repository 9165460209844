/* React modules */

/* Our modules */
import i18n from 'app/localization/i18n';
import { WebDrop } from 'generated-proto/proto/loyalty/cashdrop_pb';
import {
  DropItem,
  CashDropError,
  CashDropStatus,
} from 'modules/user/cash-drop.types';
import { CashDropAPI } from 'modules/user/cash-dorp.api';
import authStore from 'modules/auth/auth.store';
import { toDate } from 'libs/datetime';
import { logger } from 'libs/common-helpers';

/* 3rd Party modules */
import { makeAutoObservable } from 'mobx';
import i18next from 'i18next';
import { toast } from 'react-toastify';

class CashDropStore {
  api: CashDropAPI;
  cashDrops: (WebDrop & { date: string; time: string })[] = [];
  activeDrop: DropItem | null = null;
  status: CashDropStatus.ENABLED | CashDropStatus.DISABLED =
    CashDropStatus.DISABLED;

  constructor() {
    makeAutoObservable(this);
    this.api = new CashDropAPI();
  }

  get dropsList() {
    return this.cashDrops;
  }

  get cashDropStatus() {
    return this.status;
  }

  getCashDrops = async () => {
    try {
      const {
        response: { drops },
      } = await this.api.getDailyDrops(authStore.token);

      if (drops) {
        this.cashDrops = this.parseDateTimeDrops(drops);
      }
    } catch (exception: any) {
      toast.error(i18next.t('errors.system-error'));

      logger('CashDropStore -> getCashDrops -> exception', exception);
    }
  };

  collect = async (dropId: number) => {
    try {
      const {
        response: { error },
      } = await this.api.collect(dropId, authStore.token);

      if (!error) {
        toast.success(i18next.t('globals.successfully-collected-cash-drop'));
      } else {
        const { message } = error;

        if ((message || []).length) {
          if (message[0].includes(CashDropError.USER_MIN_BALANCE)) {
            const minBalance = message[0].split(':')[1].substr(0, 5);

            toast.error(
              i18n.t('errors.err-cash-drop-user-min-balance', { a: minBalance })
            );
          } else if (message[0].includes(CashDropError.USER_MIN_BET)) {
            const minBet = message[0].split('USER_MIN_BET:')[1].substr(0, 3);

            const hours = message[0].split('IN_HOURS:')[1].substr(0, 3);

            toast.error(
              i18n.t('errors.err-cash-drop-user-min-bet', {
                a: minBet,
                b: hours,
              })
            );
          } else if (message[0].includes(CashDropError.DROP_NOT_ACTIVE)) {
            toast.error(i18n.t('errors.err-cash-drop-not-active'));
          } else if (message[0].includes(CashDropError.DROP_NOT_FOUND)) {
            toast.error(i18n.t('errors.err-cash-drop-not-found'));
          } else if (
            message[0].includes(CashDropError.DROP_ALREADY_COLLECTED_BY_USER)
          ) {
            toast.error(
              i18n.t('errors.err-cash-drop-already-collected-by-user')
            );
          } else if (
            message[0].includes(CashDropError.GROUP_ALREADY_COLLECTED_BY_USER)
          ) {
            toast.error(
              i18n.t('errors.err-cash-drop-group-already-collected-by-user')
            );
          } else if (message[0].includes(CashDropError.DROP_COLLECTED)) {
            toast.error(i18n.t('errors.err-cash-drop-already-collected'));
          }
        }
      }
    } catch (exception: any) {
      toast.error(i18next.t('errors.system-error'));

      logger('CashDropStore -> collect -> exception', exception);
    } finally {
      this.getCashDrops();
    }
  };

  getCashDropStatus = async () => {
    try {
      const {
        response: { status },
      } = await this.api.getCashDropStatus(authStore.token);

      if (status) {
        this.status = status as CashDropStatus;
      }
    } catch (exception: any) {
      toast.error(i18next.t('errors.system-error'));

      logger('CashDropStore -> getCashDropStatus -> exception', exception);
    }
  };

  setNewCashDrop = async (drop: any) => {
    const dateTime = toDate(drop.msg.start);
    const rawTime = dateTime.toLocaleTimeString();
    const time =
      rawTime.split(':')[0] + ':' + rawTime.split(':')[1].substr(0, 2);
    const date = dateTime.toLocaleDateString();

    const newCashDrop = {
      ...drop.msg,
      active: drop.msg.active,
      awardAmount: drop.msg.award_amount,
      awardType: drop.msg.award_type,
      collected: drop.msg.collected,
      date: date,
      time: time,
    };

    const result = this.cashDrops.find(
      (item: any) => item.id === newCashDrop.id
    );
    if (!result) {
      this.cashDrops.push(newCashDrop);
    } else {
      const index = this.cashDrops.findIndex(
        (item: any) => item.id === result.id
      );
      this.cashDrops[index] = newCashDrop;
    }
  };

  parseDateTimeDrops = (drops: WebDrop[]) => {
    const list = drops.map((drop) => {
      const dateTime = toDate(drop.start);

      const rawTime = dateTime.toLocaleTimeString();

      const time =
        rawTime.split(':')[0] + ':' + rawTime.split(':')[1].substr(0, 2);

      const date = dateTime.toLocaleDateString();

      return {
        ...drop,
        date: date,
        time: time,
      };
    });

    const sortedListPerDate = list.sort((a, b) => b.start - a.start);

    return sortedListPerDate;
  };

  resetCashDrop = () => {
    this.cashDrops = [];
  };
}

export default new CashDropStore();
