/* React modules */

/* Our modules */
import { SystemGroup } from 'modules/ticket/system-ticket';

/* 3rd Party modules */

const renderCombinations = (
  system: number[],
  events: any[],
  isFix: boolean
) => {
  if (!system.length) return '';

  if (isFix) return `${events.length}/${events.length}`;

  const combinations = system.reduce(
    (all: string, combination: number, i: number) => {
      if (i === 0) return `${combination} `;
      return `${all} + ${combination} `;
    },
    ''
  );

  return `${combinations} / ${events.length}`;
};

const getGroups = (
  systemGroups: SystemGroup[],
  events: any[],
  isFix: boolean
) => {
  const groups = systemGroups.filter((g: any) => !!g.events.length);

  return groups.map((group: any) => {
    const groupEvents = group.events.map(({ id }: any) =>
      events.find((e: any) => e.id === id)
    );
    const system = renderCombinations(group.system, group.events, isFix);

    return { events: groupEvents, system };
  });
};

const calculateTotalOdd = (events: any[]) => {
  return events.reduce((total: number, event: any) => {
    if (!event || !event.selectedOdds[0]) return total;
    return total * event.selectedOdds[0].value;
  }, 1);
};

const calculateNumberOfSlipParts = (
  taxThreshold: number,
  maxWinningTotal: number,
  bettingAmount: number,
  numberOfCombinations: number
) => {
  const MIN_BET_AMOUNT = 0.01;

  let numOfSlips = 1;

  if (maxWinningTotal - bettingAmount > taxThreshold) {
    const maxSlips = bettingAmount * 100;

    if (maxWinningTotal / maxSlips < taxThreshold) {
      numOfSlips = Math.ceil(maxWinningTotal / taxThreshold);

      for (
        let possibleSlips = numOfSlips;
        possibleSlips <= Math.floor(maxSlips);
        possibleSlips += 1
      ) {
        if ((bettingAmount * 100) % possibleSlips === 0) {
          numOfSlips = possibleSlips;
          break;
        }
      }

      if (numberOfCombinations > 1) {
        if (
          bettingAmount / numOfSlips / numberOfCombinations <
          MIN_BET_AMOUNT
        ) {
          numOfSlips = 1;
        }
      }
    }
  }

  return numOfSlips;
};

export {
  renderCombinations,
  getGroups,
  calculateTotalOdd,
  calculateNumberOfSlipParts,
};
