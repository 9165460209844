/* React modules */

/* Our modules */
import { TransactionTypes } from 'modules/user/user.models';
import useStores from 'common/hooks/useStores';
import { DatePicker, Icon, Select } from 'components';
import { formatDatePickerDate } from 'libs/datetime';
import './TransactionsFilter.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const TYPE_FILTER_OPTIONS = (t: any) => [
  { value: 'all', label: t('globals.all').toUpperCase() },
  {
    value: TransactionTypes.DEPOSIT,
    label: t('payments.transaction-deposit').toUpperCase(),
  },
  {
    value: TransactionTypes.WITHDRAW,
    label: t('payments.transaction-withdraw').toUpperCase(),
  },
  {
    value: TransactionTypes.WEB_BET,
    label: t('payments.transaction-web-bet').toUpperCase(),
  },
  {
    value: TransactionTypes.WEB_BET_ROLLBACK,
    label: t('payments.transaction-web-bet-rollback').toUpperCase(),
  },
  {
    value: TransactionTypes.WEB_WIN,
    label: t('payments.transaction-web-win').toUpperCase(),
  },
  {
    value: TransactionTypes.WEB_GAME_BET,
    label: t('payments.transaction-web-game-bet').toUpperCase(),
  },
  {
    value: TransactionTypes.WEB_GAME_WIN,
    label: t('payments.transaction-web-game-win').toUpperCase(),
  },
  {
    value: TransactionTypes.WEB_GAME_ROLLBACK,
    label: t('payments.transaction-web-game-rollback').toUpperCase(),
  },
  {
    value: TransactionTypes.WEB_CASINO_DEBIT,
    label: t('payments.transaction-web-casino-debit').toUpperCase(),
  },
  {
    value: TransactionTypes.WEB_CASINO_CREDIT,
    label: t('payments.transaction-web-casino-credit').toUpperCase(),
  },
  {
    value: TransactionTypes.WEB_CASINO_ROLLBACK,
    label: t('payments.transaction-web-casino-rollback').toUpperCase(),
  },
];

const TransactionsFilter = observer(
  ({ onTypeChange, onDateChange, startDate, endDate }: any) => {
    const { t } = useTranslation();

    const {
      userStore: { transactionFilters },
    } = useStores();

    const { filters } = transactionFilters;

    const typeFilterOptions = TYPE_FILTER_OPTIONS(t);

    return (
      <div className="transactions-filter visible-mobile-up">
        <div id="datepicker" className="d-flex align-items">
          <label htmlFor="filter-datepicker" className="d-flex">
            <Icon name="calendar" variant="lightgrey" className="mx-3" />
            <DatePicker
              placeholderText={t('globals.filter-by-date')}
              className="ticket-status-filter__datepicker ml-3"
              value={{
                from: formatDatePickerDate(startDate),
                to: formatDatePickerDate(endDate),
              }}
              onChange={onDateChange}
              isRange
              id="filter-datepicker-transactions"
            />
          </label>
        </div>
        <div id="select">
          <Select
            className="mb-1 select"
            options={typeFilterOptions}
            bg="sb-dark"
            size="sb-market"
            value={typeFilterOptions.filter((o) =>
              filters.transaction_type
                ? o.value === filters.transaction_type
                : o.value === 'all'
            )}
            shape="squared"
            onChange={onTypeChange}
          />
        </div>
      </div>
    );
  }
);

export default TransactionsFilter;
