/* React modules */

/* Our modules */
import useStores from 'common/hooks/useStores';
import { EventsFilters } from 'modules/offer/store/offer.filters';
import RenderGroupedEvents from 'modules/offer/ui/OfferMobile/RenderGroupedEvents';
import OfferMobileWrapper from 'modules/offer/ui/OfferMobile/OfferWrapper/OfferWrapper';
import OfferListView from 'modules/offer/ui/OfferMobile/OfferListView';
import Loader from 'components/Loader';
import './OfferMobile.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const OfferMobile = observer(() => {
  const { t } = useTranslation();

  const { offerStore, offerSort, offerFilters, sportsStore } = useStores();
  const { allEventsLoaded } = sportsStore;

  const displayLiveOffer = () => {
    return (
      offerFilters.isEventFilterActive(EventsFilters.LIVE) &&
      !offerFilters.isAllFilterActive
    );
  };

  const displayUpcomingOffer = () => {
    return (
      offerFilters.isEventFilterActive(EventsFilters.UPCOMING) &&
      !offerFilters.isAllFilterActive
    );
  };

  const displayAntepostOffer = () => {
    return (
      offerFilters.isEventFilterActive(EventsFilters.ANTEPOST) &&
      !offerFilters.isAllFilterActive
    );
  };

  const displayPlayerOffer = () => {
    return (
      offerFilters.isEventFilterActive(EventsFilters.PLAYER) &&
      !offerFilters.isAllFilterActive
    );
  };

  const displaySpecialOffer = () => {
    return (
      offerFilters.isEventFilterActive(EventsFilters.SPECIAL) &&
      !offerFilters.isAllFilterActive
    );
  };

  if (!offerFilters.selectedSport) return null;

  return (
    <div className="offer-mobile offer-mobile__overflow">
      {offerFilters.isAllFilterActive && (
        <OfferListView
          selectedSport={offerFilters.selectedSport}
          mobileOffer={offerStore.mobileOffer}
        />
      )}

      {displayLiveOffer() &&
        (offerSort.isSortedByTime ? (
          <OfferListView
            selectedSport={offerFilters.selectedSport}
            mobileOffer={offerStore.mobileOffer}
          />
        ) : (
          <OfferMobileWrapper
            icon="animatedLiveGreen"
            label={t(EventsFilters.LIVE)}
          >
            {offerStore.mobileOffer.map((offer: any, index: number) => (
              <RenderGroupedEvents
                key={`OfferMobile-RenderGroupedEvents-1-${index}`}
                offer={offer}
                groupedEvents={offer.groupedEvents}
              />
            ))}
          </OfferMobileWrapper>
        ))}

      {displayUpcomingOffer() && (
        <OfferMobileWrapper
          icon="calendarMobile"
          label={t(EventsFilters.UPCOMING)}
        >
          {offerStore.mobileOffer.map((offer: any, index: number) => (
            <RenderGroupedEvents
              key={`OfferMobile-RenderGroupedEvents-2-${index}`}
              offer={offer}
              groupedEvents={offer.groupedEvents}
            />
          ))}
        </OfferMobileWrapper>
      )}

      {displayAntepostOffer() && (
        <OfferMobileWrapper
          icon="calendarMobile"
          label={t(EventsFilters.ANTEPOST)}
        >
          {offerStore.mobileOffer.map((offer: any, index: number) => (
            <RenderGroupedEvents
              key={`OfferMobile-RenderGroupedEvents-3-${index}`}
              offer={offer}
              groupedEvents={offer.groupedEvents}
            />
          ))}
        </OfferMobileWrapper>
      )}

      {displayPlayerOffer() && (
        <OfferMobileWrapper
          icon="calendarMobile"
          label={t(EventsFilters.PLAYER)}
        >
          {offerStore.mobileOffer.map((offer: any, index: number) => (
            <RenderGroupedEvents
              key={`OfferMobile-RenderGroupedEvents-4-${index}`}
              offer={offer}
              groupedEvents={offer.groupedEvents}
            />
          ))}
        </OfferMobileWrapper>
      )}

      {displaySpecialOffer() && (
        <OfferMobileWrapper
          icon="calendarMobile"
          label={t(EventsFilters.SPECIAL)}
        >
          {offerStore.mobileOffer.map((offer: any, index: number) => (
            <RenderGroupedEvents
              key={`OfferMobile-RenderGroupedEvents-5-${index}`}
              offer={offer}
              groupedEvents={offer.groupedEvents}
            />
          ))}
        </OfferMobileWrapper>
      )}

      {!allEventsLoaded && (
        <div style={{ height: '100px', background: '#303030' }}>
          <Loader height={'0px'} />
        </div>
      )}
    </div>
  );
});

export default OfferMobile;
