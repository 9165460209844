// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/user/user.proto" (package "user", syntax proto3)
// tslint:disable
import { EmptyError } from "../common/common_pb";
import { Empty } from "../../google/protobuf/empty_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
import { Error } from "../common/common_pb";
/**
 * @generated from protobuf message user.GetLiveBettingMachinesResponse
 */
export interface GetLiveBettingMachinesResponse {
    /**
     * @generated from protobuf field: repeated int32 ids = 1;
     */
    ids: number[];
}
/**
 * @generated from protobuf message user.BMSecondaryWindow
 */
export interface BMSecondaryWindow {
    /**
     * @generated from protobuf field: int32 betting_machine_id = 1;
     */
    bettingMachineId: number;
    /**
     * @generated from protobuf field: string port = 2;
     */
    port: string;
}
/**
 * @generated from protobuf message user.BMCommandRequest
 */
export interface BMCommandRequest {
    /**
     * @generated from protobuf field: repeated int32 betting_machine_ids = 1;
     */
    bettingMachineIds: number[];
    /**
     * @generated from protobuf field: string type = 2;
     */
    type: string;
    /**
     * @generated from protobuf field: user.BMCommandData data = 3;
     */
    data?: BMCommandData;
}
/**
 * @generated from protobuf message user.BMCommandData
 */
export interface BMCommandData {
    /**
     * @generated from protobuf field: string dev = 1;
     */
    dev: string;
    /**
     * @generated from protobuf field: string action = 2;
     */
    action: string;
    /**
     * @generated from protobuf field: string port = 3;
     */
    port: string;
    /**
     * @generated from protobuf field: string external_url = 4;
     */
    externalUrl: string;
    /**
     * @generated from protobuf field: string external_id = 5;
     */
    externalId: string;
    /**
     * @generated from protobuf field: string external_name = 6;
     */
    externalName: string;
    /**
     * @generated from protobuf field: string orientation = 7;
     */
    orientation: string;
    /**
     * @generated from protobuf field: string resolution = 8;
     */
    resolution: string;
    /**
     * @generated from protobuf field: string refresh_rate = 9;
     */
    refreshRate: string;
}
/**
 * @generated from protobuf message user.IndependentBMMenuItem
 */
export interface IndependentBMMenuItem {
    /**
     * @generated from protobuf field: int32 betting_machine_id = 1;
     */
    bettingMachineId: number;
    /**
     * @generated from protobuf field: repeated user.BMMenuItem items = 2;
     */
    items: BMMenuItem[];
}
/**
 * @generated from protobuf message user.EditBMMenuItemRequest
 */
export interface EditBMMenuItemRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string type = 3;
     */
    type: string;
    /**
     * @generated from protobuf field: string link = 4;
     */
    link: string;
    /**
     * @generated from protobuf field: int32 order = 5;
     */
    order: number;
    /**
     * @generated from protobuf field: bool default = 6;
     */
    default: boolean;
    /**
     * @generated from protobuf field: string image = 7;
     */
    image: string;
    /**
     * @generated from protobuf field: int32 game_id = 8;
     */
    gameId: number;
    /**
     * @generated from protobuf field: string game_name = 9;
     */
    gameName: string;
    /**
     * @generated from protobuf field: bool is_live = 10;
     */
    isLive: boolean;
}
/**
 * @generated from protobuf message user.NewBMMenuItem
 */
export interface NewBMMenuItem {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string type = 2;
     */
    type: string;
    /**
     * @generated from protobuf field: string link = 3;
     */
    link: string;
    /**
     * @generated from protobuf field: string image = 4;
     */
    image: string;
    /**
     * @generated from protobuf field: int32 order = 5;
     */
    order: number;
    /**
     * @generated from protobuf field: bool default = 6;
     */
    default: boolean;
    /**
     * @generated from protobuf field: int32 game_id = 7;
     */
    gameId: number;
    /**
     * @generated from protobuf field: string game_name = 8;
     */
    gameName: string;
    /**
     * @generated from protobuf field: bool is_live = 9;
     */
    isLive: boolean;
}
/**
 * @generated from protobuf message user.BMMenuItem
 */
export interface BMMenuItem {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string type = 2;
     */
    type: string;
    /**
     * @generated from protobuf field: string link = 3;
     */
    link: string;
    /**
     * @generated from protobuf field: int32 id = 4;
     */
    id: number;
    /**
     * @generated from protobuf field: bool global_active = 5;
     */
    globalActive: boolean;
    /**
     * @generated from protobuf field: int32 order = 6;
     */
    order: number;
    /**
     * @generated from protobuf field: bool default = 7;
     */
    default: boolean;
    /**
     * @generated from protobuf field: string image = 8;
     */
    image: string;
    /**
     * @generated from protobuf field: int32 game_id = 9;
     */
    gameId: number;
    /**
     * @generated from protobuf field: string game_name = 10;
     */
    gameName: string;
    /**
     * @generated from protobuf field: bool is_live = 11;
     */
    isLive: boolean;
    /**
     * @generated from protobuf field: common.Error error = 12;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.AllBMMenuItems
 */
export interface AllBMMenuItems {
    /**
     * @generated from protobuf field: repeated user.BMMenuItem items = 1;
     */
    items: BMMenuItem[];
    /**
     * @generated from protobuf field: int32 betting_place_id = 2;
     */
    bettingPlaceId: number;
    /**
     * @generated from protobuf field: int32 betting_machine_id = 3;
     */
    bettingMachineId: number;
}
/**
 * @generated from protobuf message user.BettingPlaceId
 */
export interface BettingPlaceId {
    /**
     * @generated from protobuf field: int32 betting_place_id = 1;
     */
    bettingPlaceId: number;
}
/**
 * @generated from protobuf message user.SetBMMonitorUrlRequest
 */
export interface SetBMMonitorUrlRequest {
    /**
     * @generated from protobuf field: string host_name = 1;
     */
    hostName: string;
    /**
     * @generated from protobuf field: int32 monitor_id = 2;
     */
    monitorId: number;
    /**
     * @generated from protobuf field: string url = 3;
     */
    url: string;
}
/**
 * @generated from protobuf message user.BMMonitorUrlRequest
 */
export interface BMMonitorUrlRequest {
    /**
     * @generated from protobuf field: string host_name = 1;
     */
    hostName: string;
    /**
     * @generated from protobuf field: int32 monitor_id = 2;
     */
    monitorId: number;
}
/**
 * @generated from protobuf message user.BMMonitorResponse
 */
export interface BMMonitorResponse {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
}
/**
 * @generated from protobuf message user.IsTestValues
 */
export interface IsTestValues {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: bool test = 2;
     */
    test: boolean;
}
/**
 * @generated from protobuf message user.SetAllowDepositRequest
 */
export interface SetAllowDepositRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: bool allow_deposit = 2;
     */
    allowDeposit: boolean;
}
/**
 * @generated from protobuf message user.GetAllowDepositRequest
 */
export interface GetAllowDepositRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message user.GetAllowDepositResponse
 */
export interface GetAllowDepositResponse {
    /**
     * @generated from protobuf field: bool allow_deposit = 1;
     */
    allowDeposit: boolean;
}
/**
 * @generated from protobuf message user.SetActiveBettingPlaceRequest
 */
export interface SetActiveBettingPlaceRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: bool is_active = 2;
     */
    isActive: boolean;
}
/**
 * @generated from protobuf message user.BoPermissionMap
 */
export interface BoPermissionMap {
    /**
     * @generated from protobuf field: map<string, string> permissions = 1;
     */
    permissions: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message user.GetBackOfficeUsersRequest
 */
export interface GetBackOfficeUsersRequest {
    /**
     * @generated from protobuf field: user.Filter filter = 1;
     */
    filter?: Filter;
}
/**
 * @generated from protobuf message user.GetBackOfficeUsersResponse
 */
export interface GetBackOfficeUsersResponse {
    /**
     * @generated from protobuf field: repeated user.BackOfficeUser bo_users = 1;
     */
    boUsers: BackOfficeUser[];
}
/**
 * @generated from protobuf message user.BackOfficeUser
 */
export interface BackOfficeUser {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string first_name = 2;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 3;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string username = 4;
     */
    username: string;
    /**
     * @generated from protobuf field: string password = 5;
     */
    password: string;
    /**
     * @generated from protobuf field: map<string, bool> permissions = 7;
     */
    permissions: {
        [key: string]: boolean;
    };
    /**
     * @generated from protobuf field: common.Error error = 6;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.GetAgentsRequest
 */
export interface GetAgentsRequest {
    /**
     * @generated from protobuf field: user.Filter filter = 1;
     */
    filter?: Filter;
}
/**
 * @generated from protobuf message user.GetAgentsResponse
 */
export interface GetAgentsResponse {
    /**
     * @generated from protobuf field: repeated user.Agent agents = 1;
     */
    agents: Agent[];
}
/**
 * @generated from protobuf message user.Agent
 */
export interface Agent {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string first_name = 2;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 3;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string username = 4;
     */
    username: string;
    /**
     * @generated from protobuf field: string password = 5;
     */
    password: string;
    /**
     * @generated from protobuf field: double deposit_limit = 6;
     */
    depositLimit: number;
    /**
     * @generated from protobuf field: double commission = 7;
     */
    commission: number;
    /**
     * @generated from protobuf field: map<string, bool> permissions = 8;
     */
    permissions: {
        [key: string]: boolean;
    };
    /**
     * @generated from protobuf field: common.Error error = 9;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.GetAgentCommisionAndLimitResponse
 */
export interface GetAgentCommisionAndLimitResponse {
    /**
     * @generated from protobuf field: double deposit_limit = 1;
     */
    depositLimit: number;
    /**
     * @generated from protobuf field: double commission = 2;
     */
    commission: number;
    /**
     * @generated from protobuf field: double current_spent = 3;
     */
    currentSpent: number;
}
/**
 * @generated from protobuf message user.AgentPayinRequest
 */
export interface AgentPayinRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: double amount = 2;
     */
    amount: number;
}
/**
 * @generated from protobuf message user.Employee
 */
export interface Employee {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string first_name = 2;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 3;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string username = 4;
     */
    username: string;
    /**
     * @generated from protobuf field: string password = 5;
     */
    password: string;
    /**
     * @generated from protobuf field: common.Error error = 6;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.GetEmployeesRequest
 */
export interface GetEmployeesRequest {
    /**
     * @generated from protobuf field: user.Filter filter = 1;
     */
    filter?: Filter;
}
/**
 * @generated from protobuf message user.Filter
 */
export interface Filter {
    /**
     * @generated from protobuf field: string search_string = 1;
     */
    searchString: string;
}
/**
 * @generated from protobuf message user.GetEmployeesResponse
 */
export interface GetEmployeesResponse {
    /**
     * @generated from protobuf field: repeated user.Employee employees = 1;
     */
    employees: Employee[];
}
/**
 * @generated from protobuf message user.BettingMachine
 */
export interface BettingMachine {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string username = 2;
     */
    username: string;
    /**
     * @generated from protobuf field: int32 role = 3;
     */
    role: number;
    /**
     * @generated from protobuf field: int32 betting_place = 4;
     */
    bettingPlace: number;
    /**
     * @generated from protobuf field: string isonis_code = 5;
     */
    isonisCode: string;
    /**
     * @generated from protobuf field: string serial_number = 12;
     */
    serialNumber: string;
    /**
     * @generated from protobuf field: string identifier = 6;
     */
    identifier: string;
    /**
     * @generated from protobuf field: bool is_testing = 7;
     */
    isTesting: boolean;
    /**
     * @generated from protobuf field: string external = 8;
     */
    external: string;
    /**
     * @generated from protobuf field: string display = 9;
     */
    display: string;
    /**
     * @generated from protobuf field: string system = 10;
     */
    system: string;
    /**
     * @generated from protobuf field: bool is_deleted = 13;
     */
    isDeleted: boolean;
    /**
     * @generated from protobuf field: bool save_username = 14;
     */
    saveUsername: boolean;
    /**
     * @generated from protobuf field: common.Error error = 11;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.GetBettingMachinesRequest
 */
export interface GetBettingMachinesRequest {
    /**
     * @generated from protobuf field: user.Filter filter = 1;
     */
    filter?: Filter;
}
/**
 * @generated from protobuf message user.GetBettingMachinesResponse
 */
export interface GetBettingMachinesResponse {
    /**
     * @generated from protobuf field: repeated user.BettingMachine betting_machines = 1;
     */
    bettingMachines: BettingMachine[];
}
/**
 * @generated from protobuf message user.BettingMachineDevice
 */
export interface BettingMachineDevice {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 betting_machine = 3;
     */
    bettingMachine: number;
    /**
     * @generated from protobuf field: string status = 4;
     */
    status: string;
    /**
     * @generated from protobuf field: string manufacturer_id = 5;
     */
    manufacturerId: string;
    /**
     * @generated from protobuf field: string device_type = 6;
     */
    deviceType: string;
    /**
     * @generated from protobuf field: string url = 7;
     */
    url: string;
    /**
     * @generated from protobuf field: common.Error error = 8;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.GetBettingMachineDevicesRequest
 */
export interface GetBettingMachineDevicesRequest {
    /**
     * @generated from protobuf field: int32 betting_machine_id = 1;
     */
    bettingMachineId: number;
}
/**
 * @generated from protobuf message user.GetBettingMachineDevicesResponse
 */
export interface GetBettingMachineDevicesResponse {
    /**
     * @generated from protobuf field: repeated user.BettingMachineDevice betting_machine_devices = 1;
     */
    bettingMachineDevices: BettingMachineDevice[];
}
/**
 * @generated from protobuf message user.BettingMachineExternalLink
 */
export interface BettingMachineExternalLink {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string link = 2;
     */
    link: string;
    /**
     * @generated from protobuf field: int32 id = 3;
     */
    id: number;
    /**
     * @generated from protobuf field: int32 betting_machine_id = 4;
     */
    bettingMachineId: number;
    /**
     * @generated from protobuf field: string port = 5;
     */
    port: string;
    /**
     * @generated from protobuf field: common.Error error = 6;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.BettingMachineExternalLinks
 */
export interface BettingMachineExternalLinks {
    /**
     * @generated from protobuf field: repeated user.BettingMachineExternalLink external_links = 1;
     */
    externalLinks: BettingMachineExternalLink[];
}
/**
 * @generated from protobuf message user.CrediteeList
 */
export interface CrediteeList {
    /**
     * @generated from protobuf field: repeated user.Creditee creditees = 1;
     */
    creditees: Creditee[];
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.Creditee
 */
export interface Creditee {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string uuid = 2;
     */
    uuid: string;
    /**
     * @generated from protobuf field: int32 platform_user_id = 3;
     */
    platformUserId: number;
}
/**
 * @generated from protobuf message user.UserResponse
 */
export interface UserResponse {
    /**
     * @generated from protobuf field: user.User user = 18;
     */
    user?: User;
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.User
 */
export interface User {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string first_name = 2;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 3;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string email = 4;
     */
    email: string;
    /**
     * @generated from protobuf field: int32 role_id = 72;
     */
    roleId: number;
}
/**
 * @generated from protobuf message user.CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * @generated from protobuf field: string email = 4;
     */
    email: string;
    /**
     * @generated from protobuf field: string password = 5;
     */
    password: string;
    /**
     * @generated from protobuf field: string repeated_password = 7;
     */
    repeatedPassword: string;
}
/**
 * @generated from protobuf message user.CreatePlatformUserRequest
 */
export interface CreatePlatformUserRequest {
    /**
     * @generated from protobuf field: string first_name = 2;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 3;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string email = 4;
     */
    email: string;
    /**
     * @generated from protobuf field: string repeated_email = 34;
     */
    repeatedEmail: string;
    /**
     * @generated from protobuf field: string password = 5;
     */
    password: string;
    /**
     * @generated from protobuf field: string repeated_password = 7;
     */
    repeatedPassword: string;
    /**
     * @generated from protobuf field: string address = 6;
     */
    address: string;
    /**
     * @generated from protobuf field: string bio = 8;
     */
    bio: string;
    /**
     * @generated from protobuf field: string gender = 25;
     */
    gender: string;
    /**
     * @generated from protobuf field: string nickname = 26;
     */
    nickname: string;
    /**
     * @generated from protobuf field: string country = 27;
     */
    country: string;
    /**
     * @generated from protobuf field: string city = 28;
     */
    city: string;
    /**
     * @generated from protobuf field: string ssn = 31;
     */
    ssn: string;
    /**
     * @generated from protobuf field: string phone_number = 32;
     */
    phoneNumber: string;
    /**
     * @generated from protobuf field: string account_number = 33;
     */
    accountNumber: string;
    /**
     * @generated from protobuf field: string is_government_official = 29;
     */
    isGovernmentOfficial: string;
    /**
     * @generated from protobuf field: string terms_and_conditions = 30;
     */
    termsAndConditions: string;
    /**
     * @generated from protobuf field: string subscribed = 36;
     */
    subscribed: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp date_of_birth = 9;
     */
    dateOfBirth?: Timestamp;
}
/**
 * @generated from protobuf message user.GetUserByEmailAndPasswordRequest
 */
export interface GetUserByEmailAndPasswordRequest {
    /**
     * @generated from protobuf field: string email = 4;
     */
    email: string;
    /**
     * @generated from protobuf field: string password = 5;
     */
    password: string;
    /**
     * @generated from protobuf field: string device_code = 38;
     */
    deviceCode: string;
    /**
     * @generated from protobuf field: repeated int32 role_ids = 40;
     */
    roleIds: number[];
}
/**
 * @generated from protobuf message user.GetUserByEmailAndPasswordResponse
 */
export interface GetUserByEmailAndPasswordResponse {
    /**
     * @generated from protobuf field: string access_token = 11;
     */
    accessToken: string;
    /**
     * @generated from protobuf field: user.User user = 18;
     */
    user?: User;
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.GetBettingMachineByUsernameAndTokenRequest
 */
export interface GetBettingMachineByUsernameAndTokenRequest {
    /**
     * @generated from protobuf field: string username = 1;
     */
    username: string;
    /**
     * @generated from protobuf field: string token = 2;
     */
    token: string;
}
/**
 * @generated from protobuf message user.GetBettingMachineByUsernameAndTokenResponse
 */
export interface GetBettingMachineByUsernameAndTokenResponse {
    /**
     * @generated from protobuf field: string access_token = 11;
     */
    accessToken: string;
    /**
     * @generated from protobuf field: string blocked_actions = 2;
     */
    blockedActions: string;
    /**
     * @generated from protobuf field: user.BettingMachine betting_machine = 18;
     */
    bettingMachine?: BettingMachine;
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.GetUserRequest
 */
export interface GetUserRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int32 betting_place_id = 37;
     */
    bettingPlaceId: number;
}
/**
 * @generated from protobuf message user.UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string first_name = 2;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 3;
     */
    lastName: string;
    /**
     * @generated from protobuf field: string email = 4;
     */
    email: string;
    /**
     * @generated from protobuf field: string password = 5;
     */
    password: string;
    /**
     * @generated from protobuf field: string address = 6;
     */
    address: string;
    /**
     * @generated from protobuf field: string bio = 7;
     */
    bio: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp birth_date = 9;
     */
    birthDate?: Timestamp;
}
/**
 * @generated from protobuf message user.ListUsersRequest
 */
export interface ListUsersRequest {
    /**
     * @generated from protobuf field: int32 page_size = 34;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: int32 page_number = 35;
     */
    pageNumber: number;
}
/**
 * @generated from protobuf message user.ListUsersResponse
 */
export interface ListUsersResponse {
    /**
     * @generated from protobuf field: int32 total_pages = 13;
     */
    totalPages: number;
    /**
     * @generated from protobuf field: repeated user.User users = 14;
     */
    users: User[];
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.ReserveFundsRequest
 */
export interface ReserveFundsRequest {
    /**
     * @generated from protobuf field: string slip_id = 15;
     */
    slipId: string;
    /**
     * @generated from protobuf field: int32 user_id = 16;
     */
    userId: number;
    /**
     * @generated from protobuf field: double amount = 17;
     */
    amount: number;
}
/**
 * @generated from protobuf message user.ReserveFundsResponse
 */
export interface ReserveFundsResponse {
    /**
     * @generated from protobuf field: int32 betting_place_id = 19;
     */
    bettingPlaceId: number;
    /**
     * @generated from protobuf field: int32 account_id = 20;
     */
    accountId: number;
    /**
     * @generated from protobuf field: int32 creator = 21;
     */
    creator: number;
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.ReleaseFundsRequest
 */
export interface ReleaseFundsRequest {
    /**
     * @generated from protobuf field: string slip_id = 15;
     */
    slipId: string;
    /**
     * @generated from protobuf field: int32 user_id = 16;
     */
    userId: number;
}
/**
 * @generated from protobuf message user.ReleaseFundsResponse
 */
export interface ReleaseFundsResponse {
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.ConfirmFundsResponse
 */
export interface ConfirmFundsResponse {
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.GetBettingPlacesBoRequest
 */
export interface GetBettingPlacesBoRequest {
}
/**
 * @generated from protobuf message user.GetBettingPlacesBoResponse
 */
export interface GetBettingPlacesBoResponse {
    /**
     * @generated from protobuf field: repeated user.BettingPlace betting_places = 23;
     */
    bettingPlaces: BettingPlace[];
}
/**
 * @generated from protobuf message user.BettingPlace
 */
export interface BettingPlace {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 24;
     */
    name: string;
    /**
     * @generated from protobuf field: string address = 85;
     */
    address: string;
    /**
     * @generated from protobuf field: string municipality_name = 58;
     */
    municipalityName: string;
    /**
     * @generated from protobuf field: string code = 83;
     */
    code: string;
    /**
     * @generated from protobuf field: repeated user.BettingPlaceDevice devices = 62;
     */
    devices: BettingPlaceDevice[];
    /**
     * @generated from protobuf field: bool is_online = 86;
     */
    isOnline: boolean;
    /**
     * @generated from protobuf field: bool is_testing = 80;
     */
    isTesting: boolean;
    /**
     * @generated from protobuf field: bool is_active = 81;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: bool allow_deposit = 7;
     */
    allowDeposit: boolean;
    /**
     * @generated from protobuf field: double limit_live_bm = 9;
     */
    limitLiveBm: number;
    /**
     * @generated from protobuf field: double limit_mix_bm = 10;
     */
    limitMixBm: number;
    /**
     * @generated from protobuf field: double limit_prematch_bm = 11;
     */
    limitPrematchBm: number;
    /**
     * @generated from protobuf field: string blocked_actions = 12;
     */
    blockedActions: string;
}
/**
 * @generated from protobuf message user.NewBettingPlace
 */
export interface NewBettingPlace {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string address = 3;
     */
    address: string;
    /**
     * @generated from protobuf field: string municipality_name = 4;
     */
    municipalityName: string;
    /**
     * @generated from protobuf field: string code = 5;
     */
    code: string;
    /**
     * @generated from protobuf field: bool is_online = 6;
     */
    isOnline: boolean;
    /**
     * @generated from protobuf field: bool is_testing = 7;
     */
    isTesting: boolean;
    /**
     * @generated from protobuf field: bool allow_deposit = 8;
     */
    allowDeposit: boolean;
    /**
     * @generated from protobuf field: double limit_live_bm = 9;
     */
    limitLiveBm: number;
    /**
     * @generated from protobuf field: double limit_mix_bm = 10;
     */
    limitMixBm: number;
    /**
     * @generated from protobuf field: double limit_prematch_bm = 11;
     */
    limitPrematchBm: number;
    /**
     * @generated from protobuf field: string blocked_actions = 12;
     */
    blockedActions: string;
    /**
     * @generated from protobuf field: common.Error error = 13;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.NewBettingPlaceRequest
 */
export interface NewBettingPlaceRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string address = 2;
     */
    address: string;
    /**
     * @generated from protobuf field: int32 municipality_id = 3;
     */
    municipalityId: number;
    /**
     * @generated from protobuf field: bool is_testing = 4;
     */
    isTesting: boolean;
    /**
     * @generated from protobuf field: string code = 5;
     */
    code: string;
    /**
     * @generated from protobuf field: bool allow_deposit = 6;
     */
    allowDeposit: boolean;
    /**
     * @generated from protobuf field: double limit_live_bm = 7;
     */
    limitLiveBm: number;
    /**
     * @generated from protobuf field: double limit_mix_bm = 8;
     */
    limitMixBm: number;
    /**
     * @generated from protobuf field: double limit_prematch_bm = 9;
     */
    limitPrematchBm: number;
}
/**
 * @generated from protobuf message user.EditBettingPlaceRequest
 */
export interface EditBettingPlaceRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string address = 3;
     */
    address: string;
    /**
     * @generated from protobuf field: int32 municipality_id = 4;
     */
    municipalityId: number;
    /**
     * @generated from protobuf field: bool is_testing = 5;
     */
    isTesting: boolean;
    /**
     * @generated from protobuf field: bool is_online = 6;
     */
    isOnline: boolean;
    /**
     * @generated from protobuf field: string code = 7;
     */
    code: string;
    /**
     * @generated from protobuf field: bool allow_deposit = 8;
     */
    allowDeposit: boolean;
    /**
     * @generated from protobuf field: double limit_live_bm = 9;
     */
    limitLiveBm: number;
    /**
     * @generated from protobuf field: double limit_mix_bm = 10;
     */
    limitMixBm: number;
    /**
     * @generated from protobuf field: double limit_prematch_bm = 11;
     */
    limitPrematchBm: number;
    /**
     * @generated from protobuf field: string blocked_actions = 12;
     */
    blockedActions: string;
}
/**
 * @generated from protobuf message user.BettingPlaceDevice
 */
export interface BettingPlaceDevice {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 24;
     */
    name: string;
    /**
     * @generated from protobuf field: bool double_print = 84;
     */
    doublePrint: boolean;
    /**
     * @generated from protobuf field: bool blank_slip = 89;
     */
    blankSlip: boolean;
    /**
     * @generated from protobuf field: bool not_reporting = 90;
     */
    notReporting: boolean;
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.BettingPlaceDeviceRequest
 */
export interface BettingPlaceDeviceRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 betting_place_id = 2;
     */
    bettingPlaceId: number;
    /**
     * @generated from protobuf field: bool not_reporting = 3;
     */
    notReporting: boolean;
    /**
     * @generated from protobuf field: bool double_print = 4;
     */
    doublePrint: boolean;
    /**
     * @generated from protobuf field: bool blank_slip = 5;
     */
    blankSlip: boolean;
    /**
     * @generated from protobuf field: int32 id = 6;
     */
    id: number;
}
/**
 * @generated from protobuf message user.GetUserPermissionsRequest
 */
export interface GetUserPermissionsRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message user.GetUserPermissionsResponse
 */
export interface GetUserPermissionsResponse {
    /**
     * @generated from protobuf field: repeated string permissions = 39;
     */
    permissions: string[];
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
// Bonus messages

/**
 * @generated from protobuf message user.CreateBonusRequest
 */
export interface CreateBonusRequest {
    /**
     * @generated from protobuf field: string type = 41;
     */
    type: string;
    /**
     * @generated from protobuf field: string name = 24;
     */
    name: string;
    /**
     * @generated from protobuf field: double min_odd_value = 49;
     */
    minOddValue: number;
    /**
     * @generated from protobuf field: int32 total_games_lost = 53;
     */
    totalGamesLost: number;
    /**
     * @generated from protobuf field: repeated user.WinningSlipAward winning_slip_awards = 48;
     */
    winningSlipAwards: WinningSlipAward[];
    /**
     * @generated from protobuf field: repeated user.CacheBackAward cache_back_awards = 52;
     */
    cacheBackAwards: CacheBackAward[];
}
/**
 * @generated from protobuf message user.Bonus
 */
export interface Bonus {
    /**
     * @generated from protobuf field: double amount = 17;
     */
    amount: number;
    /**
     * @generated from protobuf field: int32 percent = 47;
     */
    percent: number;
    /**
     * @generated from protobuf field: int32 coefficient = 50;
     */
    coefficient: number;
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.BonusMetadata
 */
export interface BonusMetadata {
    /**
     * @generated from protobuf field: repeated user.BonusCategory bonus_category = 43;
     */
    bonusCategory: BonusCategory[];
}
/**
 * @generated from protobuf message user.BonusCategory
 */
export interface BonusCategory {
    /**
     * @generated from protobuf field: string name = 24;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated user.BonusType bonus_type = 44;
     */
    bonusType: BonusType[];
}
/**
 * @generated from protobuf message user.BonusType
 */
export interface BonusType {
    /**
     * @generated from protobuf field: string name = 24;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated user.BonusSubType subtype = 45;
     */
    subtype: BonusSubType[];
}
/**
 * @generated from protobuf message user.BonusSubType
 */
export interface BonusSubType {
    /**
     * @generated from protobuf field: string name = 24;
     */
    name: string;
}
/**
 * @generated from protobuf message user.WinningSlipAward
 */
export interface WinningSlipAward {
    /**
     * @generated from protobuf field: string name = 24;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 total_games_won = 46;
     */
    totalGamesWon: number;
    /**
     * @generated from protobuf field: int32 percent = 47;
     */
    percent: number;
}
/**
 * @generated from protobuf message user.CacheBackAward
 */
export interface CacheBackAward {
    /**
     * @generated from protobuf field: string name = 24;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 coefficient = 50;
     */
    coefficient: number;
    /**
     * @generated from protobuf field: int32 odd_threshold = 51;
     */
    oddThreshold: number;
}
/**
 * @generated from protobuf message user.CheckSlipBonusRequest
 */
export interface CheckSlipBonusRequest {
    /**
     * @generated from protobuf field: string slip_id = 15;
     */
    slipId: string;
    /**
     * @generated from protobuf field: repeated user.Combination combinations = 54;
     */
    combinations: Combination[];
}
/**
 * @generated from protobuf message user.CheckSlipBonusResponse
 */
export interface CheckSlipBonusResponse {
    /**
     * @generated from protobuf field: string slip_id = 15;
     */
    slipId: string;
    /**
     * @generated from protobuf field: double min_bonus_amount = 55;
     */
    minBonusAmount: number;
    /**
     * @generated from protobuf field: double max_bonus_amount = 56;
     */
    maxBonusAmount: number;
    /**
     * @generated from protobuf field: double bonus_percent = 88;
     */
    bonusPercent: number;
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.Combination
 */
export interface Combination {
    /**
     * @generated from protobuf field: double amount = 17;
     */
    amount: number;
    /**
     * @generated from protobuf field: repeated double odd_values = 57;
     */
    oddValues: number[];
}
/**
 * @generated from protobuf message user.GrantCacheBackBonusRequest
 */
export interface GrantCacheBackBonusRequest {
    /**
     * @generated from protobuf field: string slip_id = 15;
     */
    slipId: string;
    /**
     * @generated from protobuf field: double amount = 17;
     */
    amount: number;
    /**
     * @generated from protobuf field: double odd_threshold = 51;
     */
    oddThreshold: number;
}
/**
 * @generated from protobuf message user.DeviceCode
 */
export interface DeviceCode {
    /**
     * @generated from protobuf field: string device_code = 59;
     */
    deviceCode: string;
}
/**
 * @generated from protobuf message user.GetBettingPlaceInfoByDeviceCodeResponse
 */
export interface GetBettingPlaceInfoByDeviceCodeResponse {
    /**
     * @generated from protobuf field: user.BettingPlace betting_place = 60;
     */
    bettingPlace?: BettingPlace;
    /**
     * @generated from protobuf field: user.BettingPlaceDevice device = 61;
     */
    device?: BettingPlaceDevice;
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.GetBettingPlaceInfoByIsonisPlaceCodeResponse
 */
export interface GetBettingPlaceInfoByIsonisPlaceCodeResponse {
    /**
     * @generated from protobuf field: int32 betting_place_id = 19;
     */
    bettingPlaceId: number;
    /**
     * @generated from protobuf field: int32 betting_place_terminal_id = 91;
     */
    bettingPlaceTerminalId: number;
    /**
     * @generated from protobuf field: string serial_number = 92;
     */
    serialNumber: string;
    /**
     * @generated from protobuf field: string betting_place_code = 93;
     */
    bettingPlaceCode: string;
    /**
     * @generated from protobuf field: string name = 24;
     */
    name: string;
    /**
     * @generated from protobuf field: string address = 85;
     */
    address: string;
    /**
     * @generated from protobuf field: string municipality_name = 58;
     */
    municipalityName: string;
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.ValidationPriorityObjects
 */
export interface ValidationPriorityObjects {
    /**
     * @generated from protobuf field: map<int32, string> betting_places_map = 63;
     */
    bettingPlacesMap: {
        [key: number]: string;
    };
}
/**
 * @generated from protobuf message user.ActiveBettingPlaceCodes
 */
export interface ActiveBettingPlaceCodes {
    /**
     * @generated from protobuf field: map<int32, string> active_codes = 87;
     */
    activeCodes: {
        [key: number]: string;
    };
}
/**
 * @generated from protobuf message user.Municipality
 */
export interface Municipality {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 24;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated user.BettingPlace betting_places = 23;
     */
    bettingPlaces: BettingPlace[];
}
/**
 * @generated from protobuf message user.GetBettingPlacesByMunicipalityResponse
 */
export interface GetBettingPlacesByMunicipalityResponse {
    /**
     * @generated from protobuf field: repeated user.Municipality municipalities = 64;
     */
    municipalities: Municipality[];
}
/**
 * @generated from protobuf message user.ListActivityRequest
 */
export interface ListActivityRequest {
    /**
     * @generated from protobuf field: int32 page = 65;
     */
    page: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp date_from = 80;
     */
    dateFrom?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp date_to = 81;
     */
    dateTo?: Timestamp;
    /**
     * @generated from protobuf field: string query_string = 82;
     */
    queryString: string;
}
/**
 * @generated from protobuf message user.ListActivityResponse
 */
export interface ListActivityResponse {
    /**
     * @generated from protobuf field: repeated user.Activity activities = 66;
     */
    activities: Activity[];
    /**
     * @generated from protobuf field: int32 page = 67;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 total_pages = 68;
     */
    totalPages: number;
    /**
     * @generated from protobuf field: common.Error error = 22;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.Activity
 */
export interface Activity {
    /**
     * @generated from protobuf field: int32 user_id = 69;
     */
    userId: number;
    /**
     * @generated from protobuf field: string first_name = 70;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 71;
     */
    lastName: string;
    /**
     * @generated from protobuf field: int32 role_id = 72;
     */
    roleId: number;
    /**
     * @generated from protobuf field: int32 betting_place_id = 73;
     */
    bettingPlaceId: number;
    /**
     * @generated from protobuf field: string betting_place_name = 74;
     */
    bettingPlaceName: string;
    /**
     * @generated from protobuf field: string betting_place_code = 75;
     */
    bettingPlaceCode: string;
    /**
     * @generated from protobuf field: int32 device_id = 76;
     */
    deviceId: number;
    /**
     * @generated from protobuf field: string activity = 77;
     */
    activity: string;
    /**
     * @generated from protobuf field: string activity_kwargs = 78;
     */
    activityKwargs: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 79;
     */
    createdAt?: Timestamp;
}
/**
 * @generated from protobuf message user.DoublePrintRequest
 */
export interface DoublePrintRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message user.BlankSlipRequest
 */
export interface BlankSlipRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message user.TestBettingPlaceRequest
 */
export interface TestBettingPlaceRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message user.TestBettingPlacesResponse
 */
export interface TestBettingPlacesResponse {
    /**
     * @generated from protobuf field: repeated int32 ids = 1;
     */
    ids: number[];
}
/**
 * @generated from protobuf message user.GetTestBettingPlaceResponse
 */
export interface GetTestBettingPlaceResponse {
    /**
     * @generated from protobuf field: bool test = 1;
     */
    test: boolean;
}
/**
 * @generated from protobuf message user.CheckSameMunicipalityBPSRequest
 */
export interface CheckSameMunicipalityBPSRequest {
    /**
     * @generated from protobuf field: int32 bp1 = 1;
     */
    bp1: number;
    /**
     * @generated from protobuf field: int32 bp2 = 2;
     */
    bp2: number;
}
/**
 * @generated from protobuf message user.CheckSameMunicipalityBPSResponse
 */
export interface CheckSameMunicipalityBPSResponse {
    /**
     * @generated from protobuf field: bool check = 1;
     */
    check: boolean;
    /**
     * @generated from protobuf field: common.Error error = 6;
     */
    error?: Error;
}
/**
 * @generated from protobuf message user.GetBPBettingMachinesResponse
 */
export interface GetBPBettingMachinesResponse {
    /**
     * @generated from protobuf field: repeated user.BettingMachine betting_machines = 1;
     */
    bettingMachines: BettingMachine[];
}
/**
 * @generated from protobuf message user.BettingPlaceLimitsRequest
 */
export interface BettingPlaceLimitsRequest {
    /**
     * @generated from protobuf field: int32 betting_place_id = 1;
     */
    bettingPlaceId: number;
}
/**
 * @generated from protobuf message user.GetLandbasePlayersRequest
 */
export interface GetLandbasePlayersRequest {
    /**
     * @generated from protobuf field: int32 betting_place_id = 1;
     */
    bettingPlaceId: number;
}
/**
 * @generated from protobuf message user.GetLandbasePlayersResponse
 */
export interface GetLandbasePlayersResponse {
    /**
     * @generated from protobuf field: repeated user.LandbasePlayer landbase_players = 1;
     */
    landbasePlayers: LandbasePlayer[];
}
/**
 * @generated from protobuf message user.LandbasePlayer
 */
export interface LandbasePlayer {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string first_name = 2;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string last_name = 3;
     */
    lastName: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp date_of_birth = 4;
     */
    dateOfBirth?: Timestamp;
    /**
     * @generated from protobuf field: string jmbg = 5;
     */
    jmbg: string;
    /**
     * @generated from protobuf field: string passport_number = 6;
     */
    passportNumber: string;
    /**
     * @generated from protobuf field: string identification_document_country = 7;
     */
    identificationDocumentCountry: string;
    /**
     * @generated from protobuf field: string identification_document_type = 8;
     */
    identificationDocumentType: string;
    /**
     * @generated from protobuf field: string phone_number = 9;
     */
    phoneNumber: string;
    /**
     * @generated from protobuf field: repeated user.BettingPlace betting_places = 10;
     */
    bettingPlaces: BettingPlace[];
    /**
     * @generated from protobuf field: common.Error error = 11;
     */
    error?: Error;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetLiveBettingMachinesResponse$Type extends MessageType<GetLiveBettingMachinesResponse> {
    constructor() {
        super("user.GetLiveBettingMachinesResponse", [
            { no: 1, name: "ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetLiveBettingMachinesResponse>): GetLiveBettingMachinesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ids = [];
        if (value !== undefined)
            reflectionMergePartial<GetLiveBettingMachinesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLiveBettingMachinesResponse): GetLiveBettingMachinesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ids.push(reader.int32());
                    else
                        message.ids.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLiveBettingMachinesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int32 ids = 1; */
        if (message.ids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ids.length; i++)
                writer.int32(message.ids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetLiveBettingMachinesResponse
 */
export const GetLiveBettingMachinesResponse = new GetLiveBettingMachinesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BMSecondaryWindow$Type extends MessageType<BMSecondaryWindow> {
    constructor() {
        super("user.BMSecondaryWindow", [
            { no: 1, name: "betting_machine_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "port", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BMSecondaryWindow>): BMSecondaryWindow {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingMachineId = 0;
        message.port = "";
        if (value !== undefined)
            reflectionMergePartial<BMSecondaryWindow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BMSecondaryWindow): BMSecondaryWindow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 betting_machine_id */ 1:
                    message.bettingMachineId = reader.int32();
                    break;
                case /* string port */ 2:
                    message.port = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BMSecondaryWindow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 betting_machine_id = 1; */
        if (message.bettingMachineId !== 0)
            writer.tag(1, WireType.Varint).int32(message.bettingMachineId);
        /* string port = 2; */
        if (message.port !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.port);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BMSecondaryWindow
 */
export const BMSecondaryWindow = new BMSecondaryWindow$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BMCommandRequest$Type extends MessageType<BMCommandRequest> {
    constructor() {
        super("user.BMCommandRequest", [
            { no: 1, name: "betting_machine_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "data", kind: "message", T: () => BMCommandData }
        ]);
    }
    create(value?: PartialMessage<BMCommandRequest>): BMCommandRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingMachineIds = [];
        message.type = "";
        if (value !== undefined)
            reflectionMergePartial<BMCommandRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BMCommandRequest): BMCommandRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 betting_machine_ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.bettingMachineIds.push(reader.int32());
                    else
                        message.bettingMachineIds.push(reader.int32());
                    break;
                case /* string type */ 2:
                    message.type = reader.string();
                    break;
                case /* user.BMCommandData data */ 3:
                    message.data = BMCommandData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BMCommandRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int32 betting_machine_ids = 1; */
        if (message.bettingMachineIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.bettingMachineIds.length; i++)
                writer.int32(message.bettingMachineIds[i]);
            writer.join();
        }
        /* string type = 2; */
        if (message.type !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.type);
        /* user.BMCommandData data = 3; */
        if (message.data)
            BMCommandData.internalBinaryWrite(message.data, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BMCommandRequest
 */
export const BMCommandRequest = new BMCommandRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BMCommandData$Type extends MessageType<BMCommandData> {
    constructor() {
        super("user.BMCommandData", [
            { no: 1, name: "dev", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "action", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "port", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "external_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "external_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "external_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "orientation", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "resolution", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "refresh_rate", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BMCommandData>): BMCommandData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.dev = "";
        message.action = "";
        message.port = "";
        message.externalUrl = "";
        message.externalId = "";
        message.externalName = "";
        message.orientation = "";
        message.resolution = "";
        message.refreshRate = "";
        if (value !== undefined)
            reflectionMergePartial<BMCommandData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BMCommandData): BMCommandData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string dev */ 1:
                    message.dev = reader.string();
                    break;
                case /* string action */ 2:
                    message.action = reader.string();
                    break;
                case /* string port */ 3:
                    message.port = reader.string();
                    break;
                case /* string external_url */ 4:
                    message.externalUrl = reader.string();
                    break;
                case /* string external_id */ 5:
                    message.externalId = reader.string();
                    break;
                case /* string external_name */ 6:
                    message.externalName = reader.string();
                    break;
                case /* string orientation */ 7:
                    message.orientation = reader.string();
                    break;
                case /* string resolution */ 8:
                    message.resolution = reader.string();
                    break;
                case /* string refresh_rate */ 9:
                    message.refreshRate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BMCommandData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string dev = 1; */
        if (message.dev !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.dev);
        /* string action = 2; */
        if (message.action !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.action);
        /* string port = 3; */
        if (message.port !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.port);
        /* string external_url = 4; */
        if (message.externalUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.externalUrl);
        /* string external_id = 5; */
        if (message.externalId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.externalId);
        /* string external_name = 6; */
        if (message.externalName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.externalName);
        /* string orientation = 7; */
        if (message.orientation !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.orientation);
        /* string resolution = 8; */
        if (message.resolution !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.resolution);
        /* string refresh_rate = 9; */
        if (message.refreshRate !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.refreshRate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BMCommandData
 */
export const BMCommandData = new BMCommandData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IndependentBMMenuItem$Type extends MessageType<IndependentBMMenuItem> {
    constructor() {
        super("user.IndependentBMMenuItem", [
            { no: 1, name: "betting_machine_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BMMenuItem }
        ]);
    }
    create(value?: PartialMessage<IndependentBMMenuItem>): IndependentBMMenuItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingMachineId = 0;
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<IndependentBMMenuItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IndependentBMMenuItem): IndependentBMMenuItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 betting_machine_id */ 1:
                    message.bettingMachineId = reader.int32();
                    break;
                case /* repeated user.BMMenuItem items */ 2:
                    message.items.push(BMMenuItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IndependentBMMenuItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 betting_machine_id = 1; */
        if (message.bettingMachineId !== 0)
            writer.tag(1, WireType.Varint).int32(message.bettingMachineId);
        /* repeated user.BMMenuItem items = 2; */
        for (let i = 0; i < message.items.length; i++)
            BMMenuItem.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.IndependentBMMenuItem
 */
export const IndependentBMMenuItem = new IndependentBMMenuItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditBMMenuItemRequest$Type extends MessageType<EditBMMenuItemRequest> {
    constructor() {
        super("user.EditBMMenuItemRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "default", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "image", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "game_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "game_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "is_live", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<EditBMMenuItemRequest>): EditBMMenuItemRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.type = "";
        message.link = "";
        message.order = 0;
        message.default = false;
        message.image = "";
        message.gameId = 0;
        message.gameName = "";
        message.isLive = false;
        if (value !== undefined)
            reflectionMergePartial<EditBMMenuItemRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditBMMenuItemRequest): EditBMMenuItemRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string type */ 3:
                    message.type = reader.string();
                    break;
                case /* string link */ 4:
                    message.link = reader.string();
                    break;
                case /* int32 order */ 5:
                    message.order = reader.int32();
                    break;
                case /* bool default */ 6:
                    message.default = reader.bool();
                    break;
                case /* string image */ 7:
                    message.image = reader.string();
                    break;
                case /* int32 game_id */ 8:
                    message.gameId = reader.int32();
                    break;
                case /* string game_name */ 9:
                    message.gameName = reader.string();
                    break;
                case /* bool is_live */ 10:
                    message.isLive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditBMMenuItemRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string type = 3; */
        if (message.type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.type);
        /* string link = 4; */
        if (message.link !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.link);
        /* int32 order = 5; */
        if (message.order !== 0)
            writer.tag(5, WireType.Varint).int32(message.order);
        /* bool default = 6; */
        if (message.default !== false)
            writer.tag(6, WireType.Varint).bool(message.default);
        /* string image = 7; */
        if (message.image !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.image);
        /* int32 game_id = 8; */
        if (message.gameId !== 0)
            writer.tag(8, WireType.Varint).int32(message.gameId);
        /* string game_name = 9; */
        if (message.gameName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.gameName);
        /* bool is_live = 10; */
        if (message.isLive !== false)
            writer.tag(10, WireType.Varint).bool(message.isLive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.EditBMMenuItemRequest
 */
export const EditBMMenuItemRequest = new EditBMMenuItemRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewBMMenuItem$Type extends MessageType<NewBMMenuItem> {
    constructor() {
        super("user.NewBMMenuItem", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "image", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "default", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "game_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "game_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "is_live", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<NewBMMenuItem>): NewBMMenuItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.type = "";
        message.link = "";
        message.image = "";
        message.order = 0;
        message.default = false;
        message.gameId = 0;
        message.gameName = "";
        message.isLive = false;
        if (value !== undefined)
            reflectionMergePartial<NewBMMenuItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewBMMenuItem): NewBMMenuItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string type */ 2:
                    message.type = reader.string();
                    break;
                case /* string link */ 3:
                    message.link = reader.string();
                    break;
                case /* string image */ 4:
                    message.image = reader.string();
                    break;
                case /* int32 order */ 5:
                    message.order = reader.int32();
                    break;
                case /* bool default */ 6:
                    message.default = reader.bool();
                    break;
                case /* int32 game_id */ 7:
                    message.gameId = reader.int32();
                    break;
                case /* string game_name */ 8:
                    message.gameName = reader.string();
                    break;
                case /* bool is_live */ 9:
                    message.isLive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewBMMenuItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string type = 2; */
        if (message.type !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.type);
        /* string link = 3; */
        if (message.link !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.link);
        /* string image = 4; */
        if (message.image !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.image);
        /* int32 order = 5; */
        if (message.order !== 0)
            writer.tag(5, WireType.Varint).int32(message.order);
        /* bool default = 6; */
        if (message.default !== false)
            writer.tag(6, WireType.Varint).bool(message.default);
        /* int32 game_id = 7; */
        if (message.gameId !== 0)
            writer.tag(7, WireType.Varint).int32(message.gameId);
        /* string game_name = 8; */
        if (message.gameName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.gameName);
        /* bool is_live = 9; */
        if (message.isLive !== false)
            writer.tag(9, WireType.Varint).bool(message.isLive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.NewBMMenuItem
 */
export const NewBMMenuItem = new NewBMMenuItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BMMenuItem$Type extends MessageType<BMMenuItem> {
    constructor() {
        super("user.BMMenuItem", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "global_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "default", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "image", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "game_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "game_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "is_live", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<BMMenuItem>): BMMenuItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.type = "";
        message.link = "";
        message.id = 0;
        message.globalActive = false;
        message.order = 0;
        message.default = false;
        message.image = "";
        message.gameId = 0;
        message.gameName = "";
        message.isLive = false;
        if (value !== undefined)
            reflectionMergePartial<BMMenuItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BMMenuItem): BMMenuItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string type */ 2:
                    message.type = reader.string();
                    break;
                case /* string link */ 3:
                    message.link = reader.string();
                    break;
                case /* int32 id */ 4:
                    message.id = reader.int32();
                    break;
                case /* bool global_active */ 5:
                    message.globalActive = reader.bool();
                    break;
                case /* int32 order */ 6:
                    message.order = reader.int32();
                    break;
                case /* bool default */ 7:
                    message.default = reader.bool();
                    break;
                case /* string image */ 8:
                    message.image = reader.string();
                    break;
                case /* int32 game_id */ 9:
                    message.gameId = reader.int32();
                    break;
                case /* string game_name */ 10:
                    message.gameName = reader.string();
                    break;
                case /* bool is_live */ 11:
                    message.isLive = reader.bool();
                    break;
                case /* common.Error error */ 12:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BMMenuItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string type = 2; */
        if (message.type !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.type);
        /* string link = 3; */
        if (message.link !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.link);
        /* int32 id = 4; */
        if (message.id !== 0)
            writer.tag(4, WireType.Varint).int32(message.id);
        /* bool global_active = 5; */
        if (message.globalActive !== false)
            writer.tag(5, WireType.Varint).bool(message.globalActive);
        /* int32 order = 6; */
        if (message.order !== 0)
            writer.tag(6, WireType.Varint).int32(message.order);
        /* bool default = 7; */
        if (message.default !== false)
            writer.tag(7, WireType.Varint).bool(message.default);
        /* string image = 8; */
        if (message.image !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.image);
        /* int32 game_id = 9; */
        if (message.gameId !== 0)
            writer.tag(9, WireType.Varint).int32(message.gameId);
        /* string game_name = 10; */
        if (message.gameName !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.gameName);
        /* bool is_live = 11; */
        if (message.isLive !== false)
            writer.tag(11, WireType.Varint).bool(message.isLive);
        /* common.Error error = 12; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BMMenuItem
 */
export const BMMenuItem = new BMMenuItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllBMMenuItems$Type extends MessageType<AllBMMenuItems> {
    constructor() {
        super("user.AllBMMenuItems", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BMMenuItem },
            { no: 2, name: "betting_place_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "betting_machine_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AllBMMenuItems>): AllBMMenuItems {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        message.bettingPlaceId = 0;
        message.bettingMachineId = 0;
        if (value !== undefined)
            reflectionMergePartial<AllBMMenuItems>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllBMMenuItems): AllBMMenuItems {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated user.BMMenuItem items */ 1:
                    message.items.push(BMMenuItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 betting_place_id */ 2:
                    message.bettingPlaceId = reader.int32();
                    break;
                case /* int32 betting_machine_id */ 3:
                    message.bettingMachineId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AllBMMenuItems, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated user.BMMenuItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            BMMenuItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 betting_place_id = 2; */
        if (message.bettingPlaceId !== 0)
            writer.tag(2, WireType.Varint).int32(message.bettingPlaceId);
        /* int32 betting_machine_id = 3; */
        if (message.bettingMachineId !== 0)
            writer.tag(3, WireType.Varint).int32(message.bettingMachineId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.AllBMMenuItems
 */
export const AllBMMenuItems = new AllBMMenuItems$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BettingPlaceId$Type extends MessageType<BettingPlaceId> {
    constructor() {
        super("user.BettingPlaceId", [
            { no: 1, name: "betting_place_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<BettingPlaceId>): BettingPlaceId {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingPlaceId = 0;
        if (value !== undefined)
            reflectionMergePartial<BettingPlaceId>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BettingPlaceId): BettingPlaceId {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 betting_place_id */ 1:
                    message.bettingPlaceId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BettingPlaceId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 betting_place_id = 1; */
        if (message.bettingPlaceId !== 0)
            writer.tag(1, WireType.Varint).int32(message.bettingPlaceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BettingPlaceId
 */
export const BettingPlaceId = new BettingPlaceId$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetBMMonitorUrlRequest$Type extends MessageType<SetBMMonitorUrlRequest> {
    constructor() {
        super("user.SetBMMonitorUrlRequest", [
            { no: 1, name: "host_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "monitor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SetBMMonitorUrlRequest>): SetBMMonitorUrlRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.hostName = "";
        message.monitorId = 0;
        message.url = "";
        if (value !== undefined)
            reflectionMergePartial<SetBMMonitorUrlRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetBMMonitorUrlRequest): SetBMMonitorUrlRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string host_name */ 1:
                    message.hostName = reader.string();
                    break;
                case /* int32 monitor_id */ 2:
                    message.monitorId = reader.int32();
                    break;
                case /* string url */ 3:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetBMMonitorUrlRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string host_name = 1; */
        if (message.hostName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.hostName);
        /* int32 monitor_id = 2; */
        if (message.monitorId !== 0)
            writer.tag(2, WireType.Varint).int32(message.monitorId);
        /* string url = 3; */
        if (message.url !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.SetBMMonitorUrlRequest
 */
export const SetBMMonitorUrlRequest = new SetBMMonitorUrlRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BMMonitorUrlRequest$Type extends MessageType<BMMonitorUrlRequest> {
    constructor() {
        super("user.BMMonitorUrlRequest", [
            { no: 1, name: "host_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "monitor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<BMMonitorUrlRequest>): BMMonitorUrlRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.hostName = "";
        message.monitorId = 0;
        if (value !== undefined)
            reflectionMergePartial<BMMonitorUrlRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BMMonitorUrlRequest): BMMonitorUrlRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string host_name */ 1:
                    message.hostName = reader.string();
                    break;
                case /* int32 monitor_id */ 2:
                    message.monitorId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BMMonitorUrlRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string host_name = 1; */
        if (message.hostName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.hostName);
        /* int32 monitor_id = 2; */
        if (message.monitorId !== 0)
            writer.tag(2, WireType.Varint).int32(message.monitorId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BMMonitorUrlRequest
 */
export const BMMonitorUrlRequest = new BMMonitorUrlRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BMMonitorResponse$Type extends MessageType<BMMonitorResponse> {
    constructor() {
        super("user.BMMonitorResponse", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BMMonitorResponse>): BMMonitorResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.url = "";
        if (value !== undefined)
            reflectionMergePartial<BMMonitorResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BMMonitorResponse): BMMonitorResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BMMonitorResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BMMonitorResponse
 */
export const BMMonitorResponse = new BMMonitorResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IsTestValues$Type extends MessageType<IsTestValues> {
    constructor() {
        super("user.IsTestValues", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "test", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<IsTestValues>): IsTestValues {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.test = false;
        if (value !== undefined)
            reflectionMergePartial<IsTestValues>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IsTestValues): IsTestValues {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* bool test */ 2:
                    message.test = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IsTestValues, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* bool test = 2; */
        if (message.test !== false)
            writer.tag(2, WireType.Varint).bool(message.test);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.IsTestValues
 */
export const IsTestValues = new IsTestValues$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetAllowDepositRequest$Type extends MessageType<SetAllowDepositRequest> {
    constructor() {
        super("user.SetAllowDepositRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "allow_deposit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SetAllowDepositRequest>): SetAllowDepositRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.allowDeposit = false;
        if (value !== undefined)
            reflectionMergePartial<SetAllowDepositRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetAllowDepositRequest): SetAllowDepositRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* bool allow_deposit */ 2:
                    message.allowDeposit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetAllowDepositRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* bool allow_deposit = 2; */
        if (message.allowDeposit !== false)
            writer.tag(2, WireType.Varint).bool(message.allowDeposit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.SetAllowDepositRequest
 */
export const SetAllowDepositRequest = new SetAllowDepositRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllowDepositRequest$Type extends MessageType<GetAllowDepositRequest> {
    constructor() {
        super("user.GetAllowDepositRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetAllowDepositRequest>): GetAllowDepositRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<GetAllowDepositRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllowDepositRequest): GetAllowDepositRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllowDepositRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetAllowDepositRequest
 */
export const GetAllowDepositRequest = new GetAllowDepositRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllowDepositResponse$Type extends MessageType<GetAllowDepositResponse> {
    constructor() {
        super("user.GetAllowDepositResponse", [
            { no: 1, name: "allow_deposit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetAllowDepositResponse>): GetAllowDepositResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.allowDeposit = false;
        if (value !== undefined)
            reflectionMergePartial<GetAllowDepositResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllowDepositResponse): GetAllowDepositResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool allow_deposit */ 1:
                    message.allowDeposit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllowDepositResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool allow_deposit = 1; */
        if (message.allowDeposit !== false)
            writer.tag(1, WireType.Varint).bool(message.allowDeposit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetAllowDepositResponse
 */
export const GetAllowDepositResponse = new GetAllowDepositResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetActiveBettingPlaceRequest$Type extends MessageType<SetActiveBettingPlaceRequest> {
    constructor() {
        super("user.SetActiveBettingPlaceRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SetActiveBettingPlaceRequest>): SetActiveBettingPlaceRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.isActive = false;
        if (value !== undefined)
            reflectionMergePartial<SetActiveBettingPlaceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetActiveBettingPlaceRequest): SetActiveBettingPlaceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* bool is_active */ 2:
                    message.isActive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetActiveBettingPlaceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* bool is_active = 2; */
        if (message.isActive !== false)
            writer.tag(2, WireType.Varint).bool(message.isActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.SetActiveBettingPlaceRequest
 */
export const SetActiveBettingPlaceRequest = new SetActiveBettingPlaceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BoPermissionMap$Type extends MessageType<BoPermissionMap> {
    constructor() {
        super("user.BoPermissionMap", [
            { no: 1, name: "permissions", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<BoPermissionMap>): BoPermissionMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.permissions = {};
        if (value !== undefined)
            reflectionMergePartial<BoPermissionMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BoPermissionMap): BoPermissionMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> permissions */ 1:
                    this.binaryReadMap1(message.permissions, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: BoPermissionMap["permissions"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof BoPermissionMap["permissions"] | undefined, val: BoPermissionMap["permissions"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field user.BoPermissionMap.permissions");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: BoPermissionMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> permissions = 1; */
        for (let k of globalThis.Object.keys(message.permissions))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.permissions[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BoPermissionMap
 */
export const BoPermissionMap = new BoPermissionMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBackOfficeUsersRequest$Type extends MessageType<GetBackOfficeUsersRequest> {
    constructor() {
        super("user.GetBackOfficeUsersRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<GetBackOfficeUsersRequest>): GetBackOfficeUsersRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetBackOfficeUsersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBackOfficeUsersRequest): GetBackOfficeUsersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* user.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBackOfficeUsersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* user.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetBackOfficeUsersRequest
 */
export const GetBackOfficeUsersRequest = new GetBackOfficeUsersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBackOfficeUsersResponse$Type extends MessageType<GetBackOfficeUsersResponse> {
    constructor() {
        super("user.GetBackOfficeUsersResponse", [
            { no: 1, name: "bo_users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BackOfficeUser }
        ]);
    }
    create(value?: PartialMessage<GetBackOfficeUsersResponse>): GetBackOfficeUsersResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.boUsers = [];
        if (value !== undefined)
            reflectionMergePartial<GetBackOfficeUsersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBackOfficeUsersResponse): GetBackOfficeUsersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated user.BackOfficeUser bo_users */ 1:
                    message.boUsers.push(BackOfficeUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBackOfficeUsersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated user.BackOfficeUser bo_users = 1; */
        for (let i = 0; i < message.boUsers.length; i++)
            BackOfficeUser.internalBinaryWrite(message.boUsers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetBackOfficeUsersResponse
 */
export const GetBackOfficeUsersResponse = new GetBackOfficeUsersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BackOfficeUser$Type extends MessageType<BackOfficeUser> {
    constructor() {
        super("user.BackOfficeUser", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "permissions", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 8 /*ScalarType.BOOL*/ } },
            { no: 6, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<BackOfficeUser>): BackOfficeUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.firstName = "";
        message.lastName = "";
        message.username = "";
        message.password = "";
        message.permissions = {};
        if (value !== undefined)
            reflectionMergePartial<BackOfficeUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BackOfficeUser): BackOfficeUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string first_name */ 2:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 3:
                    message.lastName = reader.string();
                    break;
                case /* string username */ 4:
                    message.username = reader.string();
                    break;
                case /* string password */ 5:
                    message.password = reader.string();
                    break;
                case /* map<string, bool> permissions */ 7:
                    this.binaryReadMap7(message.permissions, reader, options);
                    break;
                case /* common.Error error */ 6:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap7(map: BackOfficeUser["permissions"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof BackOfficeUser["permissions"] | undefined, val: BackOfficeUser["permissions"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.bool();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field user.BackOfficeUser.permissions");
            }
        }
        map[key ?? ""] = val ?? false;
    }
    internalBinaryWrite(message: BackOfficeUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string first_name = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 3; */
        if (message.lastName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastName);
        /* string username = 4; */
        if (message.username !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.username);
        /* string password = 5; */
        if (message.password !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.password);
        /* map<string, bool> permissions = 7; */
        for (let k of globalThis.Object.keys(message.permissions))
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).bool(message.permissions[k]).join();
        /* common.Error error = 6; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BackOfficeUser
 */
export const BackOfficeUser = new BackOfficeUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgentsRequest$Type extends MessageType<GetAgentsRequest> {
    constructor() {
        super("user.GetAgentsRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<GetAgentsRequest>): GetAgentsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetAgentsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgentsRequest): GetAgentsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* user.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgentsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* user.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetAgentsRequest
 */
export const GetAgentsRequest = new GetAgentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgentsResponse$Type extends MessageType<GetAgentsResponse> {
    constructor() {
        super("user.GetAgentsResponse", [
            { no: 1, name: "agents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Agent }
        ]);
    }
    create(value?: PartialMessage<GetAgentsResponse>): GetAgentsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agents = [];
        if (value !== undefined)
            reflectionMergePartial<GetAgentsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgentsResponse): GetAgentsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated user.Agent agents */ 1:
                    message.agents.push(Agent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgentsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated user.Agent agents = 1; */
        for (let i = 0; i < message.agents.length; i++)
            Agent.internalBinaryWrite(message.agents[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetAgentsResponse
 */
export const GetAgentsResponse = new GetAgentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Agent$Type extends MessageType<Agent> {
    constructor() {
        super("user.Agent", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "deposit_limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "commission", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "permissions", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 8 /*ScalarType.BOOL*/ } },
            { no: 9, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<Agent>): Agent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.firstName = "";
        message.lastName = "";
        message.username = "";
        message.password = "";
        message.depositLimit = 0;
        message.commission = 0;
        message.permissions = {};
        if (value !== undefined)
            reflectionMergePartial<Agent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Agent): Agent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string first_name */ 2:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 3:
                    message.lastName = reader.string();
                    break;
                case /* string username */ 4:
                    message.username = reader.string();
                    break;
                case /* string password */ 5:
                    message.password = reader.string();
                    break;
                case /* double deposit_limit */ 6:
                    message.depositLimit = reader.double();
                    break;
                case /* double commission */ 7:
                    message.commission = reader.double();
                    break;
                case /* map<string, bool> permissions */ 8:
                    this.binaryReadMap8(message.permissions, reader, options);
                    break;
                case /* common.Error error */ 9:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap8(map: Agent["permissions"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Agent["permissions"] | undefined, val: Agent["permissions"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.bool();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field user.Agent.permissions");
            }
        }
        map[key ?? ""] = val ?? false;
    }
    internalBinaryWrite(message: Agent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string first_name = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 3; */
        if (message.lastName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastName);
        /* string username = 4; */
        if (message.username !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.username);
        /* string password = 5; */
        if (message.password !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.password);
        /* double deposit_limit = 6; */
        if (message.depositLimit !== 0)
            writer.tag(6, WireType.Bit64).double(message.depositLimit);
        /* double commission = 7; */
        if (message.commission !== 0)
            writer.tag(7, WireType.Bit64).double(message.commission);
        /* map<string, bool> permissions = 8; */
        for (let k of globalThis.Object.keys(message.permissions))
            writer.tag(8, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).bool(message.permissions[k]).join();
        /* common.Error error = 9; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.Agent
 */
export const Agent = new Agent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgentCommisionAndLimitResponse$Type extends MessageType<GetAgentCommisionAndLimitResponse> {
    constructor() {
        super("user.GetAgentCommisionAndLimitResponse", [
            { no: 1, name: "deposit_limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "commission", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "current_spent", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<GetAgentCommisionAndLimitResponse>): GetAgentCommisionAndLimitResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.depositLimit = 0;
        message.commission = 0;
        message.currentSpent = 0;
        if (value !== undefined)
            reflectionMergePartial<GetAgentCommisionAndLimitResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgentCommisionAndLimitResponse): GetAgentCommisionAndLimitResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double deposit_limit */ 1:
                    message.depositLimit = reader.double();
                    break;
                case /* double commission */ 2:
                    message.commission = reader.double();
                    break;
                case /* double current_spent */ 3:
                    message.currentSpent = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgentCommisionAndLimitResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double deposit_limit = 1; */
        if (message.depositLimit !== 0)
            writer.tag(1, WireType.Bit64).double(message.depositLimit);
        /* double commission = 2; */
        if (message.commission !== 0)
            writer.tag(2, WireType.Bit64).double(message.commission);
        /* double current_spent = 3; */
        if (message.currentSpent !== 0)
            writer.tag(3, WireType.Bit64).double(message.currentSpent);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetAgentCommisionAndLimitResponse
 */
export const GetAgentCommisionAndLimitResponse = new GetAgentCommisionAndLimitResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgentPayinRequest$Type extends MessageType<AgentPayinRequest> {
    constructor() {
        super("user.AgentPayinRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<AgentPayinRequest>): AgentPayinRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.amount = 0;
        if (value !== undefined)
            reflectionMergePartial<AgentPayinRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgentPayinRequest): AgentPayinRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double amount */ 2:
                    message.amount = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgentPayinRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double amount = 2; */
        if (message.amount !== 0)
            writer.tag(2, WireType.Bit64).double(message.amount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.AgentPayinRequest
 */
export const AgentPayinRequest = new AgentPayinRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Employee$Type extends MessageType<Employee> {
    constructor() {
        super("user.Employee", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<Employee>): Employee {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.firstName = "";
        message.lastName = "";
        message.username = "";
        message.password = "";
        if (value !== undefined)
            reflectionMergePartial<Employee>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Employee): Employee {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string first_name */ 2:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 3:
                    message.lastName = reader.string();
                    break;
                case /* string username */ 4:
                    message.username = reader.string();
                    break;
                case /* string password */ 5:
                    message.password = reader.string();
                    break;
                case /* common.Error error */ 6:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Employee, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string first_name = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 3; */
        if (message.lastName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastName);
        /* string username = 4; */
        if (message.username !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.username);
        /* string password = 5; */
        if (message.password !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.password);
        /* common.Error error = 6; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.Employee
 */
export const Employee = new Employee$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEmployeesRequest$Type extends MessageType<GetEmployeesRequest> {
    constructor() {
        super("user.GetEmployeesRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<GetEmployeesRequest>): GetEmployeesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetEmployeesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEmployeesRequest): GetEmployeesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* user.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEmployeesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* user.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetEmployeesRequest
 */
export const GetEmployeesRequest = new GetEmployeesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Filter$Type extends MessageType<Filter> {
    constructor() {
        super("user.Filter", [
            { no: 1, name: "search_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Filter>): Filter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.searchString = "";
        if (value !== undefined)
            reflectionMergePartial<Filter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filter): Filter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string search_string */ 1:
                    message.searchString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Filter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string search_string = 1; */
        if (message.searchString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.searchString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.Filter
 */
export const Filter = new Filter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEmployeesResponse$Type extends MessageType<GetEmployeesResponse> {
    constructor() {
        super("user.GetEmployeesResponse", [
            { no: 1, name: "employees", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Employee }
        ]);
    }
    create(value?: PartialMessage<GetEmployeesResponse>): GetEmployeesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.employees = [];
        if (value !== undefined)
            reflectionMergePartial<GetEmployeesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEmployeesResponse): GetEmployeesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated user.Employee employees */ 1:
                    message.employees.push(Employee.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEmployeesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated user.Employee employees = 1; */
        for (let i = 0; i < message.employees.length; i++)
            Employee.internalBinaryWrite(message.employees[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetEmployeesResponse
 */
export const GetEmployeesResponse = new GetEmployeesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BettingMachine$Type extends MessageType<BettingMachine> {
    constructor() {
        super("user.BettingMachine", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "role", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "betting_place", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "isonis_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "serial_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_testing", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "external", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "display", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "system", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "is_deleted", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "save_username", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<BettingMachine>): BettingMachine {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.username = "";
        message.role = 0;
        message.bettingPlace = 0;
        message.isonisCode = "";
        message.serialNumber = "";
        message.identifier = "";
        message.isTesting = false;
        message.external = "";
        message.display = "";
        message.system = "";
        message.isDeleted = false;
        message.saveUsername = false;
        if (value !== undefined)
            reflectionMergePartial<BettingMachine>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BettingMachine): BettingMachine {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string username */ 2:
                    message.username = reader.string();
                    break;
                case /* int32 role */ 3:
                    message.role = reader.int32();
                    break;
                case /* int32 betting_place */ 4:
                    message.bettingPlace = reader.int32();
                    break;
                case /* string isonis_code */ 5:
                    message.isonisCode = reader.string();
                    break;
                case /* string serial_number */ 12:
                    message.serialNumber = reader.string();
                    break;
                case /* string identifier */ 6:
                    message.identifier = reader.string();
                    break;
                case /* bool is_testing */ 7:
                    message.isTesting = reader.bool();
                    break;
                case /* string external */ 8:
                    message.external = reader.string();
                    break;
                case /* string display */ 9:
                    message.display = reader.string();
                    break;
                case /* string system */ 10:
                    message.system = reader.string();
                    break;
                case /* bool is_deleted */ 13:
                    message.isDeleted = reader.bool();
                    break;
                case /* bool save_username */ 14:
                    message.saveUsername = reader.bool();
                    break;
                case /* common.Error error */ 11:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BettingMachine, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string username = 2; */
        if (message.username !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.username);
        /* int32 role = 3; */
        if (message.role !== 0)
            writer.tag(3, WireType.Varint).int32(message.role);
        /* int32 betting_place = 4; */
        if (message.bettingPlace !== 0)
            writer.tag(4, WireType.Varint).int32(message.bettingPlace);
        /* string isonis_code = 5; */
        if (message.isonisCode !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.isonisCode);
        /* string serial_number = 12; */
        if (message.serialNumber !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.serialNumber);
        /* string identifier = 6; */
        if (message.identifier !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.identifier);
        /* bool is_testing = 7; */
        if (message.isTesting !== false)
            writer.tag(7, WireType.Varint).bool(message.isTesting);
        /* string external = 8; */
        if (message.external !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.external);
        /* string display = 9; */
        if (message.display !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.display);
        /* string system = 10; */
        if (message.system !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.system);
        /* bool is_deleted = 13; */
        if (message.isDeleted !== false)
            writer.tag(13, WireType.Varint).bool(message.isDeleted);
        /* bool save_username = 14; */
        if (message.saveUsername !== false)
            writer.tag(14, WireType.Varint).bool(message.saveUsername);
        /* common.Error error = 11; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BettingMachine
 */
export const BettingMachine = new BettingMachine$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBettingMachinesRequest$Type extends MessageType<GetBettingMachinesRequest> {
    constructor() {
        super("user.GetBettingMachinesRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<GetBettingMachinesRequest>): GetBettingMachinesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetBettingMachinesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBettingMachinesRequest): GetBettingMachinesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* user.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBettingMachinesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* user.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetBettingMachinesRequest
 */
export const GetBettingMachinesRequest = new GetBettingMachinesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBettingMachinesResponse$Type extends MessageType<GetBettingMachinesResponse> {
    constructor() {
        super("user.GetBettingMachinesResponse", [
            { no: 1, name: "betting_machines", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BettingMachine }
        ]);
    }
    create(value?: PartialMessage<GetBettingMachinesResponse>): GetBettingMachinesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingMachines = [];
        if (value !== undefined)
            reflectionMergePartial<GetBettingMachinesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBettingMachinesResponse): GetBettingMachinesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated user.BettingMachine betting_machines */ 1:
                    message.bettingMachines.push(BettingMachine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBettingMachinesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated user.BettingMachine betting_machines = 1; */
        for (let i = 0; i < message.bettingMachines.length; i++)
            BettingMachine.internalBinaryWrite(message.bettingMachines[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetBettingMachinesResponse
 */
export const GetBettingMachinesResponse = new GetBettingMachinesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BettingMachineDevice$Type extends MessageType<BettingMachineDevice> {
    constructor() {
        super("user.BettingMachineDevice", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "betting_machine", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "manufacturer_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "device_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<BettingMachineDevice>): BettingMachineDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.bettingMachine = 0;
        message.status = "";
        message.manufacturerId = "";
        message.deviceType = "";
        message.url = "";
        if (value !== undefined)
            reflectionMergePartial<BettingMachineDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BettingMachineDevice): BettingMachineDevice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 betting_machine */ 3:
                    message.bettingMachine = reader.int32();
                    break;
                case /* string status */ 4:
                    message.status = reader.string();
                    break;
                case /* string manufacturer_id */ 5:
                    message.manufacturerId = reader.string();
                    break;
                case /* string device_type */ 6:
                    message.deviceType = reader.string();
                    break;
                case /* string url */ 7:
                    message.url = reader.string();
                    break;
                case /* common.Error error */ 8:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BettingMachineDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 betting_machine = 3; */
        if (message.bettingMachine !== 0)
            writer.tag(3, WireType.Varint).int32(message.bettingMachine);
        /* string status = 4; */
        if (message.status !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.status);
        /* string manufacturer_id = 5; */
        if (message.manufacturerId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.manufacturerId);
        /* string device_type = 6; */
        if (message.deviceType !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.deviceType);
        /* string url = 7; */
        if (message.url !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.url);
        /* common.Error error = 8; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BettingMachineDevice
 */
export const BettingMachineDevice = new BettingMachineDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBettingMachineDevicesRequest$Type extends MessageType<GetBettingMachineDevicesRequest> {
    constructor() {
        super("user.GetBettingMachineDevicesRequest", [
            { no: 1, name: "betting_machine_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetBettingMachineDevicesRequest>): GetBettingMachineDevicesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingMachineId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetBettingMachineDevicesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBettingMachineDevicesRequest): GetBettingMachineDevicesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 betting_machine_id */ 1:
                    message.bettingMachineId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBettingMachineDevicesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 betting_machine_id = 1; */
        if (message.bettingMachineId !== 0)
            writer.tag(1, WireType.Varint).int32(message.bettingMachineId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetBettingMachineDevicesRequest
 */
export const GetBettingMachineDevicesRequest = new GetBettingMachineDevicesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBettingMachineDevicesResponse$Type extends MessageType<GetBettingMachineDevicesResponse> {
    constructor() {
        super("user.GetBettingMachineDevicesResponse", [
            { no: 1, name: "betting_machine_devices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BettingMachineDevice }
        ]);
    }
    create(value?: PartialMessage<GetBettingMachineDevicesResponse>): GetBettingMachineDevicesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingMachineDevices = [];
        if (value !== undefined)
            reflectionMergePartial<GetBettingMachineDevicesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBettingMachineDevicesResponse): GetBettingMachineDevicesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated user.BettingMachineDevice betting_machine_devices */ 1:
                    message.bettingMachineDevices.push(BettingMachineDevice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBettingMachineDevicesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated user.BettingMachineDevice betting_machine_devices = 1; */
        for (let i = 0; i < message.bettingMachineDevices.length; i++)
            BettingMachineDevice.internalBinaryWrite(message.bettingMachineDevices[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetBettingMachineDevicesResponse
 */
export const GetBettingMachineDevicesResponse = new GetBettingMachineDevicesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BettingMachineExternalLink$Type extends MessageType<BettingMachineExternalLink> {
    constructor() {
        super("user.BettingMachineExternalLink", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "betting_machine_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "port", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<BettingMachineExternalLink>): BettingMachineExternalLink {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.link = "";
        message.id = 0;
        message.bettingMachineId = 0;
        message.port = "";
        if (value !== undefined)
            reflectionMergePartial<BettingMachineExternalLink>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BettingMachineExternalLink): BettingMachineExternalLink {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string link */ 2:
                    message.link = reader.string();
                    break;
                case /* int32 id */ 3:
                    message.id = reader.int32();
                    break;
                case /* int32 betting_machine_id */ 4:
                    message.bettingMachineId = reader.int32();
                    break;
                case /* string port */ 5:
                    message.port = reader.string();
                    break;
                case /* common.Error error */ 6:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BettingMachineExternalLink, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string link = 2; */
        if (message.link !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.link);
        /* int32 id = 3; */
        if (message.id !== 0)
            writer.tag(3, WireType.Varint).int32(message.id);
        /* int32 betting_machine_id = 4; */
        if (message.bettingMachineId !== 0)
            writer.tag(4, WireType.Varint).int32(message.bettingMachineId);
        /* string port = 5; */
        if (message.port !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.port);
        /* common.Error error = 6; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BettingMachineExternalLink
 */
export const BettingMachineExternalLink = new BettingMachineExternalLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BettingMachineExternalLinks$Type extends MessageType<BettingMachineExternalLinks> {
    constructor() {
        super("user.BettingMachineExternalLinks", [
            { no: 1, name: "external_links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BettingMachineExternalLink }
        ]);
    }
    create(value?: PartialMessage<BettingMachineExternalLinks>): BettingMachineExternalLinks {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.externalLinks = [];
        if (value !== undefined)
            reflectionMergePartial<BettingMachineExternalLinks>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BettingMachineExternalLinks): BettingMachineExternalLinks {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated user.BettingMachineExternalLink external_links */ 1:
                    message.externalLinks.push(BettingMachineExternalLink.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BettingMachineExternalLinks, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated user.BettingMachineExternalLink external_links = 1; */
        for (let i = 0; i < message.externalLinks.length; i++)
            BettingMachineExternalLink.internalBinaryWrite(message.externalLinks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BettingMachineExternalLinks
 */
export const BettingMachineExternalLinks = new BettingMachineExternalLinks$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CrediteeList$Type extends MessageType<CrediteeList> {
    constructor() {
        super("user.CrediteeList", [
            { no: 1, name: "creditees", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Creditee },
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<CrediteeList>): CrediteeList {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.creditees = [];
        if (value !== undefined)
            reflectionMergePartial<CrediteeList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CrediteeList): CrediteeList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated user.Creditee creditees */ 1:
                    message.creditees.push(Creditee.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CrediteeList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated user.Creditee creditees = 1; */
        for (let i = 0; i < message.creditees.length; i++)
            Creditee.internalBinaryWrite(message.creditees[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.CrediteeList
 */
export const CrediteeList = new CrediteeList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Creditee$Type extends MessageType<Creditee> {
    constructor() {
        super("user.Creditee", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "platform_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Creditee>): Creditee {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.uuid = "";
        message.platformUserId = 0;
        if (value !== undefined)
            reflectionMergePartial<Creditee>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Creditee): Creditee {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string uuid */ 2:
                    message.uuid = reader.string();
                    break;
                case /* int32 platform_user_id */ 3:
                    message.platformUserId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Creditee, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string uuid = 2; */
        if (message.uuid !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.uuid);
        /* int32 platform_user_id = 3; */
        if (message.platformUserId !== 0)
            writer.tag(3, WireType.Varint).int32(message.platformUserId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.Creditee
 */
export const Creditee = new Creditee$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserResponse$Type extends MessageType<UserResponse> {
    constructor() {
        super("user.UserResponse", [
            { no: 18, name: "user", kind: "message", T: () => User },
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<UserResponse>): UserResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserResponse): UserResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* user.User user */ 18:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* user.User user = 18; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.UserResponse
 */
export const UserResponse = new UserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
    constructor() {
        super("user.User", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 72, name: "role_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<User>): User {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.firstName = "";
        message.lastName = "";
        message.email = "";
        message.roleId = 0;
        if (value !== undefined)
            reflectionMergePartial<User>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string first_name */ 2:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 3:
                    message.lastName = reader.string();
                    break;
                case /* string email */ 4:
                    message.email = reader.string();
                    break;
                case /* int32 role_id */ 72:
                    message.roleId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string first_name = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 3; */
        if (message.lastName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastName);
        /* string email = 4; */
        if (message.email !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.email);
        /* int32 role_id = 72; */
        if (message.roleId !== 0)
            writer.tag(72, WireType.Varint).int32(message.roleId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.User
 */
export const User = new User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateUserRequest$Type extends MessageType<CreateUserRequest> {
    constructor() {
        super("user.CreateUserRequest", [
            { no: 4, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "repeated_password", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateUserRequest>): CreateUserRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.email = "";
        message.password = "";
        message.repeatedPassword = "";
        if (value !== undefined)
            reflectionMergePartial<CreateUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateUserRequest): CreateUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 4:
                    message.email = reader.string();
                    break;
                case /* string password */ 5:
                    message.password = reader.string();
                    break;
                case /* string repeated_password */ 7:
                    message.repeatedPassword = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 4; */
        if (message.email !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.email);
        /* string password = 5; */
        if (message.password !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.password);
        /* string repeated_password = 7; */
        if (message.repeatedPassword !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.repeatedPassword);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.CreateUserRequest
 */
export const CreateUserRequest = new CreateUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreatePlatformUserRequest$Type extends MessageType<CreatePlatformUserRequest> {
    constructor() {
        super("user.CreatePlatformUserRequest", [
            { no: 2, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "repeated_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "repeated_password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "bio", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "gender", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "country", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "ssn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 33, name: "account_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "is_government_official", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "terms_and_conditions", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 36, name: "subscribed", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "date_of_birth", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<CreatePlatformUserRequest>): CreatePlatformUserRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.firstName = "";
        message.lastName = "";
        message.email = "";
        message.repeatedEmail = "";
        message.password = "";
        message.repeatedPassword = "";
        message.address = "";
        message.bio = "";
        message.gender = "";
        message.nickname = "";
        message.country = "";
        message.city = "";
        message.ssn = "";
        message.phoneNumber = "";
        message.accountNumber = "";
        message.isGovernmentOfficial = "";
        message.termsAndConditions = "";
        message.subscribed = "";
        if (value !== undefined)
            reflectionMergePartial<CreatePlatformUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreatePlatformUserRequest): CreatePlatformUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string first_name */ 2:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 3:
                    message.lastName = reader.string();
                    break;
                case /* string email */ 4:
                    message.email = reader.string();
                    break;
                case /* string repeated_email */ 34:
                    message.repeatedEmail = reader.string();
                    break;
                case /* string password */ 5:
                    message.password = reader.string();
                    break;
                case /* string repeated_password */ 7:
                    message.repeatedPassword = reader.string();
                    break;
                case /* string address */ 6:
                    message.address = reader.string();
                    break;
                case /* string bio */ 8:
                    message.bio = reader.string();
                    break;
                case /* string gender */ 25:
                    message.gender = reader.string();
                    break;
                case /* string nickname */ 26:
                    message.nickname = reader.string();
                    break;
                case /* string country */ 27:
                    message.country = reader.string();
                    break;
                case /* string city */ 28:
                    message.city = reader.string();
                    break;
                case /* string ssn */ 31:
                    message.ssn = reader.string();
                    break;
                case /* string phone_number */ 32:
                    message.phoneNumber = reader.string();
                    break;
                case /* string account_number */ 33:
                    message.accountNumber = reader.string();
                    break;
                case /* string is_government_official */ 29:
                    message.isGovernmentOfficial = reader.string();
                    break;
                case /* string terms_and_conditions */ 30:
                    message.termsAndConditions = reader.string();
                    break;
                case /* string subscribed */ 36:
                    message.subscribed = reader.string();
                    break;
                case /* google.protobuf.Timestamp date_of_birth */ 9:
                    message.dateOfBirth = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dateOfBirth);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreatePlatformUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string first_name = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 3; */
        if (message.lastName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastName);
        /* string email = 4; */
        if (message.email !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.email);
        /* string repeated_email = 34; */
        if (message.repeatedEmail !== "")
            writer.tag(34, WireType.LengthDelimited).string(message.repeatedEmail);
        /* string password = 5; */
        if (message.password !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.password);
        /* string repeated_password = 7; */
        if (message.repeatedPassword !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.repeatedPassword);
        /* string address = 6; */
        if (message.address !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.address);
        /* string bio = 8; */
        if (message.bio !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.bio);
        /* string gender = 25; */
        if (message.gender !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.gender);
        /* string nickname = 26; */
        if (message.nickname !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.nickname);
        /* string country = 27; */
        if (message.country !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.country);
        /* string city = 28; */
        if (message.city !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.city);
        /* string ssn = 31; */
        if (message.ssn !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.ssn);
        /* string phone_number = 32; */
        if (message.phoneNumber !== "")
            writer.tag(32, WireType.LengthDelimited).string(message.phoneNumber);
        /* string account_number = 33; */
        if (message.accountNumber !== "")
            writer.tag(33, WireType.LengthDelimited).string(message.accountNumber);
        /* string is_government_official = 29; */
        if (message.isGovernmentOfficial !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.isGovernmentOfficial);
        /* string terms_and_conditions = 30; */
        if (message.termsAndConditions !== "")
            writer.tag(30, WireType.LengthDelimited).string(message.termsAndConditions);
        /* string subscribed = 36; */
        if (message.subscribed !== "")
            writer.tag(36, WireType.LengthDelimited).string(message.subscribed);
        /* google.protobuf.Timestamp date_of_birth = 9; */
        if (message.dateOfBirth)
            Timestamp.internalBinaryWrite(message.dateOfBirth, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.CreatePlatformUserRequest
 */
export const CreatePlatformUserRequest = new CreatePlatformUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserByEmailAndPasswordRequest$Type extends MessageType<GetUserByEmailAndPasswordRequest> {
    constructor() {
        super("user.GetUserByEmailAndPasswordRequest", [
            { no: 4, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 38, name: "device_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 40, name: "role_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserByEmailAndPasswordRequest>): GetUserByEmailAndPasswordRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.email = "";
        message.password = "";
        message.deviceCode = "";
        message.roleIds = [];
        if (value !== undefined)
            reflectionMergePartial<GetUserByEmailAndPasswordRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserByEmailAndPasswordRequest): GetUserByEmailAndPasswordRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 4:
                    message.email = reader.string();
                    break;
                case /* string password */ 5:
                    message.password = reader.string();
                    break;
                case /* string device_code */ 38:
                    message.deviceCode = reader.string();
                    break;
                case /* repeated int32 role_ids */ 40:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.roleIds.push(reader.int32());
                    else
                        message.roleIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserByEmailAndPasswordRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 4; */
        if (message.email !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.email);
        /* string password = 5; */
        if (message.password !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.password);
        /* string device_code = 38; */
        if (message.deviceCode !== "")
            writer.tag(38, WireType.LengthDelimited).string(message.deviceCode);
        /* repeated int32 role_ids = 40; */
        if (message.roleIds.length) {
            writer.tag(40, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.roleIds.length; i++)
                writer.int32(message.roleIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetUserByEmailAndPasswordRequest
 */
export const GetUserByEmailAndPasswordRequest = new GetUserByEmailAndPasswordRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserByEmailAndPasswordResponse$Type extends MessageType<GetUserByEmailAndPasswordResponse> {
    constructor() {
        super("user.GetUserByEmailAndPasswordResponse", [
            { no: 11, name: "access_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "user", kind: "message", T: () => User },
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetUserByEmailAndPasswordResponse>): GetUserByEmailAndPasswordResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accessToken = "";
        if (value !== undefined)
            reflectionMergePartial<GetUserByEmailAndPasswordResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserByEmailAndPasswordResponse): GetUserByEmailAndPasswordResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string access_token */ 11:
                    message.accessToken = reader.string();
                    break;
                case /* user.User user */ 18:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserByEmailAndPasswordResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string access_token = 11; */
        if (message.accessToken !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.accessToken);
        /* user.User user = 18; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetUserByEmailAndPasswordResponse
 */
export const GetUserByEmailAndPasswordResponse = new GetUserByEmailAndPasswordResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBettingMachineByUsernameAndTokenRequest$Type extends MessageType<GetBettingMachineByUsernameAndTokenRequest> {
    constructor() {
        super("user.GetBettingMachineByUsernameAndTokenRequest", [
            { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetBettingMachineByUsernameAndTokenRequest>): GetBettingMachineByUsernameAndTokenRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.username = "";
        message.token = "";
        if (value !== undefined)
            reflectionMergePartial<GetBettingMachineByUsernameAndTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBettingMachineByUsernameAndTokenRequest): GetBettingMachineByUsernameAndTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string username */ 1:
                    message.username = reader.string();
                    break;
                case /* string token */ 2:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBettingMachineByUsernameAndTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string username = 1; */
        if (message.username !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.username);
        /* string token = 2; */
        if (message.token !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetBettingMachineByUsernameAndTokenRequest
 */
export const GetBettingMachineByUsernameAndTokenRequest = new GetBettingMachineByUsernameAndTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBettingMachineByUsernameAndTokenResponse$Type extends MessageType<GetBettingMachineByUsernameAndTokenResponse> {
    constructor() {
        super("user.GetBettingMachineByUsernameAndTokenResponse", [
            { no: 11, name: "access_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "blocked_actions", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "betting_machine", kind: "message", T: () => BettingMachine },
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetBettingMachineByUsernameAndTokenResponse>): GetBettingMachineByUsernameAndTokenResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accessToken = "";
        message.blockedActions = "";
        if (value !== undefined)
            reflectionMergePartial<GetBettingMachineByUsernameAndTokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBettingMachineByUsernameAndTokenResponse): GetBettingMachineByUsernameAndTokenResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string access_token */ 11:
                    message.accessToken = reader.string();
                    break;
                case /* string blocked_actions */ 2:
                    message.blockedActions = reader.string();
                    break;
                case /* user.BettingMachine betting_machine */ 18:
                    message.bettingMachine = BettingMachine.internalBinaryRead(reader, reader.uint32(), options, message.bettingMachine);
                    break;
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBettingMachineByUsernameAndTokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string access_token = 11; */
        if (message.accessToken !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.accessToken);
        /* string blocked_actions = 2; */
        if (message.blockedActions !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.blockedActions);
        /* user.BettingMachine betting_machine = 18; */
        if (message.bettingMachine)
            BettingMachine.internalBinaryWrite(message.bettingMachine, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetBettingMachineByUsernameAndTokenResponse
 */
export const GetBettingMachineByUsernameAndTokenResponse = new GetBettingMachineByUsernameAndTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserRequest$Type extends MessageType<GetUserRequest> {
    constructor() {
        super("user.GetUserRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 37, name: "betting_place_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserRequest>): GetUserRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.bettingPlaceId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserRequest): GetUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 betting_place_id */ 37:
                    message.bettingPlaceId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 betting_place_id = 37; */
        if (message.bettingPlaceId !== 0)
            writer.tag(37, WireType.Varint).int32(message.bettingPlaceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetUserRequest
 */
export const GetUserRequest = new GetUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserRequest$Type extends MessageType<UpdateUserRequest> {
    constructor() {
        super("user.UpdateUserRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "bio", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "birth_date", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<UpdateUserRequest>): UpdateUserRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.firstName = "";
        message.lastName = "";
        message.email = "";
        message.password = "";
        message.address = "";
        message.bio = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserRequest): UpdateUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string first_name */ 2:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 3:
                    message.lastName = reader.string();
                    break;
                case /* string email */ 4:
                    message.email = reader.string();
                    break;
                case /* string password */ 5:
                    message.password = reader.string();
                    break;
                case /* string address */ 6:
                    message.address = reader.string();
                    break;
                case /* string bio */ 7:
                    message.bio = reader.string();
                    break;
                case /* google.protobuf.Timestamp birth_date */ 9:
                    message.birthDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.birthDate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string first_name = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 3; */
        if (message.lastName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastName);
        /* string email = 4; */
        if (message.email !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.email);
        /* string password = 5; */
        if (message.password !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.password);
        /* string address = 6; */
        if (message.address !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.address);
        /* string bio = 7; */
        if (message.bio !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.bio);
        /* google.protobuf.Timestamp birth_date = 9; */
        if (message.birthDate)
            Timestamp.internalBinaryWrite(message.birthDate, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.UpdateUserRequest
 */
export const UpdateUserRequest = new UpdateUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUsersRequest$Type extends MessageType<ListUsersRequest> {
    constructor() {
        super("user.ListUsersRequest", [
            { no: 34, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 35, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListUsersRequest>): ListUsersRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.pageSize = 0;
        message.pageNumber = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUsersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUsersRequest): ListUsersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 34:
                    message.pageSize = reader.int32();
                    break;
                case /* int32 page_number */ 35:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUsersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 34; */
        if (message.pageSize !== 0)
            writer.tag(34, WireType.Varint).int32(message.pageSize);
        /* int32 page_number = 35; */
        if (message.pageNumber !== 0)
            writer.tag(35, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.ListUsersRequest
 */
export const ListUsersRequest = new ListUsersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUsersResponse$Type extends MessageType<ListUsersResponse> {
    constructor() {
        super("user.ListUsersResponse", [
            { no: 13, name: "total_pages", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User },
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<ListUsersResponse>): ListUsersResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.totalPages = 0;
        message.users = [];
        if (value !== undefined)
            reflectionMergePartial<ListUsersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUsersResponse): ListUsersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 total_pages */ 13:
                    message.totalPages = reader.int32();
                    break;
                case /* repeated user.User users */ 14:
                    message.users.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUsersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 total_pages = 13; */
        if (message.totalPages !== 0)
            writer.tag(13, WireType.Varint).int32(message.totalPages);
        /* repeated user.User users = 14; */
        for (let i = 0; i < message.users.length; i++)
            User.internalBinaryWrite(message.users[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.ListUsersResponse
 */
export const ListUsersResponse = new ListUsersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReserveFundsRequest$Type extends MessageType<ReserveFundsRequest> {
    constructor() {
        super("user.ReserveFundsRequest", [
            { no: 15, name: "slip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<ReserveFundsRequest>): ReserveFundsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.slipId = "";
        message.userId = 0;
        message.amount = 0;
        if (value !== undefined)
            reflectionMergePartial<ReserveFundsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReserveFundsRequest): ReserveFundsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string slip_id */ 15:
                    message.slipId = reader.string();
                    break;
                case /* int32 user_id */ 16:
                    message.userId = reader.int32();
                    break;
                case /* double amount */ 17:
                    message.amount = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReserveFundsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string slip_id = 15; */
        if (message.slipId !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.slipId);
        /* int32 user_id = 16; */
        if (message.userId !== 0)
            writer.tag(16, WireType.Varint).int32(message.userId);
        /* double amount = 17; */
        if (message.amount !== 0)
            writer.tag(17, WireType.Bit64).double(message.amount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.ReserveFundsRequest
 */
export const ReserveFundsRequest = new ReserveFundsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReserveFundsResponse$Type extends MessageType<ReserveFundsResponse> {
    constructor() {
        super("user.ReserveFundsResponse", [
            { no: 19, name: "betting_place_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 20, name: "account_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 21, name: "creator", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<ReserveFundsResponse>): ReserveFundsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingPlaceId = 0;
        message.accountId = 0;
        message.creator = 0;
        if (value !== undefined)
            reflectionMergePartial<ReserveFundsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReserveFundsResponse): ReserveFundsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 betting_place_id */ 19:
                    message.bettingPlaceId = reader.int32();
                    break;
                case /* int32 account_id */ 20:
                    message.accountId = reader.int32();
                    break;
                case /* int32 creator */ 21:
                    message.creator = reader.int32();
                    break;
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReserveFundsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 betting_place_id = 19; */
        if (message.bettingPlaceId !== 0)
            writer.tag(19, WireType.Varint).int32(message.bettingPlaceId);
        /* int32 account_id = 20; */
        if (message.accountId !== 0)
            writer.tag(20, WireType.Varint).int32(message.accountId);
        /* int32 creator = 21; */
        if (message.creator !== 0)
            writer.tag(21, WireType.Varint).int32(message.creator);
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.ReserveFundsResponse
 */
export const ReserveFundsResponse = new ReserveFundsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReleaseFundsRequest$Type extends MessageType<ReleaseFundsRequest> {
    constructor() {
        super("user.ReleaseFundsRequest", [
            { no: 15, name: "slip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ReleaseFundsRequest>): ReleaseFundsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.slipId = "";
        message.userId = 0;
        if (value !== undefined)
            reflectionMergePartial<ReleaseFundsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReleaseFundsRequest): ReleaseFundsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string slip_id */ 15:
                    message.slipId = reader.string();
                    break;
                case /* int32 user_id */ 16:
                    message.userId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReleaseFundsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string slip_id = 15; */
        if (message.slipId !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.slipId);
        /* int32 user_id = 16; */
        if (message.userId !== 0)
            writer.tag(16, WireType.Varint).int32(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.ReleaseFundsRequest
 */
export const ReleaseFundsRequest = new ReleaseFundsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReleaseFundsResponse$Type extends MessageType<ReleaseFundsResponse> {
    constructor() {
        super("user.ReleaseFundsResponse", [
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<ReleaseFundsResponse>): ReleaseFundsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ReleaseFundsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReleaseFundsResponse): ReleaseFundsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReleaseFundsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.ReleaseFundsResponse
 */
export const ReleaseFundsResponse = new ReleaseFundsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConfirmFundsResponse$Type extends MessageType<ConfirmFundsResponse> {
    constructor() {
        super("user.ConfirmFundsResponse", [
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<ConfirmFundsResponse>): ConfirmFundsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ConfirmFundsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConfirmFundsResponse): ConfirmFundsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConfirmFundsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.ConfirmFundsResponse
 */
export const ConfirmFundsResponse = new ConfirmFundsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBettingPlacesBoRequest$Type extends MessageType<GetBettingPlacesBoRequest> {
    constructor() {
        super("user.GetBettingPlacesBoRequest", []);
    }
    create(value?: PartialMessage<GetBettingPlacesBoRequest>): GetBettingPlacesBoRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetBettingPlacesBoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBettingPlacesBoRequest): GetBettingPlacesBoRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetBettingPlacesBoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetBettingPlacesBoRequest
 */
export const GetBettingPlacesBoRequest = new GetBettingPlacesBoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBettingPlacesBoResponse$Type extends MessageType<GetBettingPlacesBoResponse> {
    constructor() {
        super("user.GetBettingPlacesBoResponse", [
            { no: 23, name: "betting_places", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BettingPlace }
        ]);
    }
    create(value?: PartialMessage<GetBettingPlacesBoResponse>): GetBettingPlacesBoResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingPlaces = [];
        if (value !== undefined)
            reflectionMergePartial<GetBettingPlacesBoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBettingPlacesBoResponse): GetBettingPlacesBoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated user.BettingPlace betting_places */ 23:
                    message.bettingPlaces.push(BettingPlace.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBettingPlacesBoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated user.BettingPlace betting_places = 23; */
        for (let i = 0; i < message.bettingPlaces.length; i++)
            BettingPlace.internalBinaryWrite(message.bettingPlaces[i], writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetBettingPlacesBoResponse
 */
export const GetBettingPlacesBoResponse = new GetBettingPlacesBoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BettingPlace$Type extends MessageType<BettingPlace> {
    constructor() {
        super("user.BettingPlace", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 24, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 85, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 58, name: "municipality_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 83, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 62, name: "devices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BettingPlaceDevice },
            { no: 86, name: "is_online", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 80, name: "is_testing", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 81, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "allow_deposit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "limit_live_bm", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "limit_mix_bm", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 11, name: "limit_prematch_bm", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 12, name: "blocked_actions", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BettingPlace>): BettingPlace {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.address = "";
        message.municipalityName = "";
        message.code = "";
        message.devices = [];
        message.isOnline = false;
        message.isTesting = false;
        message.isActive = false;
        message.allowDeposit = false;
        message.limitLiveBm = 0;
        message.limitMixBm = 0;
        message.limitPrematchBm = 0;
        message.blockedActions = "";
        if (value !== undefined)
            reflectionMergePartial<BettingPlace>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BettingPlace): BettingPlace {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 24:
                    message.name = reader.string();
                    break;
                case /* string address */ 85:
                    message.address = reader.string();
                    break;
                case /* string municipality_name */ 58:
                    message.municipalityName = reader.string();
                    break;
                case /* string code */ 83:
                    message.code = reader.string();
                    break;
                case /* repeated user.BettingPlaceDevice devices */ 62:
                    message.devices.push(BettingPlaceDevice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool is_online */ 86:
                    message.isOnline = reader.bool();
                    break;
                case /* bool is_testing */ 80:
                    message.isTesting = reader.bool();
                    break;
                case /* bool is_active */ 81:
                    message.isActive = reader.bool();
                    break;
                case /* bool allow_deposit */ 7:
                    message.allowDeposit = reader.bool();
                    break;
                case /* double limit_live_bm */ 9:
                    message.limitLiveBm = reader.double();
                    break;
                case /* double limit_mix_bm */ 10:
                    message.limitMixBm = reader.double();
                    break;
                case /* double limit_prematch_bm */ 11:
                    message.limitPrematchBm = reader.double();
                    break;
                case /* string blocked_actions */ 12:
                    message.blockedActions = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BettingPlace, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 24; */
        if (message.name !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.name);
        /* string address = 85; */
        if (message.address !== "")
            writer.tag(85, WireType.LengthDelimited).string(message.address);
        /* string municipality_name = 58; */
        if (message.municipalityName !== "")
            writer.tag(58, WireType.LengthDelimited).string(message.municipalityName);
        /* string code = 83; */
        if (message.code !== "")
            writer.tag(83, WireType.LengthDelimited).string(message.code);
        /* repeated user.BettingPlaceDevice devices = 62; */
        for (let i = 0; i < message.devices.length; i++)
            BettingPlaceDevice.internalBinaryWrite(message.devices[i], writer.tag(62, WireType.LengthDelimited).fork(), options).join();
        /* bool is_online = 86; */
        if (message.isOnline !== false)
            writer.tag(86, WireType.Varint).bool(message.isOnline);
        /* bool is_testing = 80; */
        if (message.isTesting !== false)
            writer.tag(80, WireType.Varint).bool(message.isTesting);
        /* bool is_active = 81; */
        if (message.isActive !== false)
            writer.tag(81, WireType.Varint).bool(message.isActive);
        /* bool allow_deposit = 7; */
        if (message.allowDeposit !== false)
            writer.tag(7, WireType.Varint).bool(message.allowDeposit);
        /* double limit_live_bm = 9; */
        if (message.limitLiveBm !== 0)
            writer.tag(9, WireType.Bit64).double(message.limitLiveBm);
        /* double limit_mix_bm = 10; */
        if (message.limitMixBm !== 0)
            writer.tag(10, WireType.Bit64).double(message.limitMixBm);
        /* double limit_prematch_bm = 11; */
        if (message.limitPrematchBm !== 0)
            writer.tag(11, WireType.Bit64).double(message.limitPrematchBm);
        /* string blocked_actions = 12; */
        if (message.blockedActions !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.blockedActions);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BettingPlace
 */
export const BettingPlace = new BettingPlace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewBettingPlace$Type extends MessageType<NewBettingPlace> {
    constructor() {
        super("user.NewBettingPlace", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "municipality_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_online", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "is_testing", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "allow_deposit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "limit_live_bm", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "limit_mix_bm", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 11, name: "limit_prematch_bm", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 12, name: "blocked_actions", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<NewBettingPlace>): NewBettingPlace {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.address = "";
        message.municipalityName = "";
        message.code = "";
        message.isOnline = false;
        message.isTesting = false;
        message.allowDeposit = false;
        message.limitLiveBm = 0;
        message.limitMixBm = 0;
        message.limitPrematchBm = 0;
        message.blockedActions = "";
        if (value !== undefined)
            reflectionMergePartial<NewBettingPlace>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewBettingPlace): NewBettingPlace {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string address */ 3:
                    message.address = reader.string();
                    break;
                case /* string municipality_name */ 4:
                    message.municipalityName = reader.string();
                    break;
                case /* string code */ 5:
                    message.code = reader.string();
                    break;
                case /* bool is_online */ 6:
                    message.isOnline = reader.bool();
                    break;
                case /* bool is_testing */ 7:
                    message.isTesting = reader.bool();
                    break;
                case /* bool allow_deposit */ 8:
                    message.allowDeposit = reader.bool();
                    break;
                case /* double limit_live_bm */ 9:
                    message.limitLiveBm = reader.double();
                    break;
                case /* double limit_mix_bm */ 10:
                    message.limitMixBm = reader.double();
                    break;
                case /* double limit_prematch_bm */ 11:
                    message.limitPrematchBm = reader.double();
                    break;
                case /* string blocked_actions */ 12:
                    message.blockedActions = reader.string();
                    break;
                case /* common.Error error */ 13:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewBettingPlace, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string address = 3; */
        if (message.address !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.address);
        /* string municipality_name = 4; */
        if (message.municipalityName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.municipalityName);
        /* string code = 5; */
        if (message.code !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.code);
        /* bool is_online = 6; */
        if (message.isOnline !== false)
            writer.tag(6, WireType.Varint).bool(message.isOnline);
        /* bool is_testing = 7; */
        if (message.isTesting !== false)
            writer.tag(7, WireType.Varint).bool(message.isTesting);
        /* bool allow_deposit = 8; */
        if (message.allowDeposit !== false)
            writer.tag(8, WireType.Varint).bool(message.allowDeposit);
        /* double limit_live_bm = 9; */
        if (message.limitLiveBm !== 0)
            writer.tag(9, WireType.Bit64).double(message.limitLiveBm);
        /* double limit_mix_bm = 10; */
        if (message.limitMixBm !== 0)
            writer.tag(10, WireType.Bit64).double(message.limitMixBm);
        /* double limit_prematch_bm = 11; */
        if (message.limitPrematchBm !== 0)
            writer.tag(11, WireType.Bit64).double(message.limitPrematchBm);
        /* string blocked_actions = 12; */
        if (message.blockedActions !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.blockedActions);
        /* common.Error error = 13; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.NewBettingPlace
 */
export const NewBettingPlace = new NewBettingPlace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewBettingPlaceRequest$Type extends MessageType<NewBettingPlaceRequest> {
    constructor() {
        super("user.NewBettingPlaceRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "municipality_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "is_testing", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "allow_deposit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "limit_live_bm", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "limit_mix_bm", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "limit_prematch_bm", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<NewBettingPlaceRequest>): NewBettingPlaceRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.address = "";
        message.municipalityId = 0;
        message.isTesting = false;
        message.code = "";
        message.allowDeposit = false;
        message.limitLiveBm = 0;
        message.limitMixBm = 0;
        message.limitPrematchBm = 0;
        if (value !== undefined)
            reflectionMergePartial<NewBettingPlaceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewBettingPlaceRequest): NewBettingPlaceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string address */ 2:
                    message.address = reader.string();
                    break;
                case /* int32 municipality_id */ 3:
                    message.municipalityId = reader.int32();
                    break;
                case /* bool is_testing */ 4:
                    message.isTesting = reader.bool();
                    break;
                case /* string code */ 5:
                    message.code = reader.string();
                    break;
                case /* bool allow_deposit */ 6:
                    message.allowDeposit = reader.bool();
                    break;
                case /* double limit_live_bm */ 7:
                    message.limitLiveBm = reader.double();
                    break;
                case /* double limit_mix_bm */ 8:
                    message.limitMixBm = reader.double();
                    break;
                case /* double limit_prematch_bm */ 9:
                    message.limitPrematchBm = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewBettingPlaceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string address = 2; */
        if (message.address !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.address);
        /* int32 municipality_id = 3; */
        if (message.municipalityId !== 0)
            writer.tag(3, WireType.Varint).int32(message.municipalityId);
        /* bool is_testing = 4; */
        if (message.isTesting !== false)
            writer.tag(4, WireType.Varint).bool(message.isTesting);
        /* string code = 5; */
        if (message.code !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.code);
        /* bool allow_deposit = 6; */
        if (message.allowDeposit !== false)
            writer.tag(6, WireType.Varint).bool(message.allowDeposit);
        /* double limit_live_bm = 7; */
        if (message.limitLiveBm !== 0)
            writer.tag(7, WireType.Bit64).double(message.limitLiveBm);
        /* double limit_mix_bm = 8; */
        if (message.limitMixBm !== 0)
            writer.tag(8, WireType.Bit64).double(message.limitMixBm);
        /* double limit_prematch_bm = 9; */
        if (message.limitPrematchBm !== 0)
            writer.tag(9, WireType.Bit64).double(message.limitPrematchBm);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.NewBettingPlaceRequest
 */
export const NewBettingPlaceRequest = new NewBettingPlaceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditBettingPlaceRequest$Type extends MessageType<EditBettingPlaceRequest> {
    constructor() {
        super("user.EditBettingPlaceRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "municipality_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "is_testing", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "is_online", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "allow_deposit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "limit_live_bm", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 10, name: "limit_mix_bm", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 11, name: "limit_prematch_bm", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 12, name: "blocked_actions", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EditBettingPlaceRequest>): EditBettingPlaceRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.address = "";
        message.municipalityId = 0;
        message.isTesting = false;
        message.isOnline = false;
        message.code = "";
        message.allowDeposit = false;
        message.limitLiveBm = 0;
        message.limitMixBm = 0;
        message.limitPrematchBm = 0;
        message.blockedActions = "";
        if (value !== undefined)
            reflectionMergePartial<EditBettingPlaceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditBettingPlaceRequest): EditBettingPlaceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string address */ 3:
                    message.address = reader.string();
                    break;
                case /* int32 municipality_id */ 4:
                    message.municipalityId = reader.int32();
                    break;
                case /* bool is_testing */ 5:
                    message.isTesting = reader.bool();
                    break;
                case /* bool is_online */ 6:
                    message.isOnline = reader.bool();
                    break;
                case /* string code */ 7:
                    message.code = reader.string();
                    break;
                case /* bool allow_deposit */ 8:
                    message.allowDeposit = reader.bool();
                    break;
                case /* double limit_live_bm */ 9:
                    message.limitLiveBm = reader.double();
                    break;
                case /* double limit_mix_bm */ 10:
                    message.limitMixBm = reader.double();
                    break;
                case /* double limit_prematch_bm */ 11:
                    message.limitPrematchBm = reader.double();
                    break;
                case /* string blocked_actions */ 12:
                    message.blockedActions = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditBettingPlaceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string address = 3; */
        if (message.address !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.address);
        /* int32 municipality_id = 4; */
        if (message.municipalityId !== 0)
            writer.tag(4, WireType.Varint).int32(message.municipalityId);
        /* bool is_testing = 5; */
        if (message.isTesting !== false)
            writer.tag(5, WireType.Varint).bool(message.isTesting);
        /* bool is_online = 6; */
        if (message.isOnline !== false)
            writer.tag(6, WireType.Varint).bool(message.isOnline);
        /* string code = 7; */
        if (message.code !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.code);
        /* bool allow_deposit = 8; */
        if (message.allowDeposit !== false)
            writer.tag(8, WireType.Varint).bool(message.allowDeposit);
        /* double limit_live_bm = 9; */
        if (message.limitLiveBm !== 0)
            writer.tag(9, WireType.Bit64).double(message.limitLiveBm);
        /* double limit_mix_bm = 10; */
        if (message.limitMixBm !== 0)
            writer.tag(10, WireType.Bit64).double(message.limitMixBm);
        /* double limit_prematch_bm = 11; */
        if (message.limitPrematchBm !== 0)
            writer.tag(11, WireType.Bit64).double(message.limitPrematchBm);
        /* string blocked_actions = 12; */
        if (message.blockedActions !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.blockedActions);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.EditBettingPlaceRequest
 */
export const EditBettingPlaceRequest = new EditBettingPlaceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BettingPlaceDevice$Type extends MessageType<BettingPlaceDevice> {
    constructor() {
        super("user.BettingPlaceDevice", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 24, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 84, name: "double_print", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 89, name: "blank_slip", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 90, name: "not_reporting", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<BettingPlaceDevice>): BettingPlaceDevice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.doublePrint = false;
        message.blankSlip = false;
        message.notReporting = false;
        if (value !== undefined)
            reflectionMergePartial<BettingPlaceDevice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BettingPlaceDevice): BettingPlaceDevice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 24:
                    message.name = reader.string();
                    break;
                case /* bool double_print */ 84:
                    message.doublePrint = reader.bool();
                    break;
                case /* bool blank_slip */ 89:
                    message.blankSlip = reader.bool();
                    break;
                case /* bool not_reporting */ 90:
                    message.notReporting = reader.bool();
                    break;
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BettingPlaceDevice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 24; */
        if (message.name !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.name);
        /* bool double_print = 84; */
        if (message.doublePrint !== false)
            writer.tag(84, WireType.Varint).bool(message.doublePrint);
        /* bool blank_slip = 89; */
        if (message.blankSlip !== false)
            writer.tag(89, WireType.Varint).bool(message.blankSlip);
        /* bool not_reporting = 90; */
        if (message.notReporting !== false)
            writer.tag(90, WireType.Varint).bool(message.notReporting);
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BettingPlaceDevice
 */
export const BettingPlaceDevice = new BettingPlaceDevice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BettingPlaceDeviceRequest$Type extends MessageType<BettingPlaceDeviceRequest> {
    constructor() {
        super("user.BettingPlaceDeviceRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "betting_place_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "not_reporting", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "double_print", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "blank_slip", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<BettingPlaceDeviceRequest>): BettingPlaceDeviceRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.bettingPlaceId = 0;
        message.notReporting = false;
        message.doublePrint = false;
        message.blankSlip = false;
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<BettingPlaceDeviceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BettingPlaceDeviceRequest): BettingPlaceDeviceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* int32 betting_place_id */ 2:
                    message.bettingPlaceId = reader.int32();
                    break;
                case /* bool not_reporting */ 3:
                    message.notReporting = reader.bool();
                    break;
                case /* bool double_print */ 4:
                    message.doublePrint = reader.bool();
                    break;
                case /* bool blank_slip */ 5:
                    message.blankSlip = reader.bool();
                    break;
                case /* int32 id */ 6:
                    message.id = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BettingPlaceDeviceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* int32 betting_place_id = 2; */
        if (message.bettingPlaceId !== 0)
            writer.tag(2, WireType.Varint).int32(message.bettingPlaceId);
        /* bool not_reporting = 3; */
        if (message.notReporting !== false)
            writer.tag(3, WireType.Varint).bool(message.notReporting);
        /* bool double_print = 4; */
        if (message.doublePrint !== false)
            writer.tag(4, WireType.Varint).bool(message.doublePrint);
        /* bool blank_slip = 5; */
        if (message.blankSlip !== false)
            writer.tag(5, WireType.Varint).bool(message.blankSlip);
        /* int32 id = 6; */
        if (message.id !== 0)
            writer.tag(6, WireType.Varint).int32(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BettingPlaceDeviceRequest
 */
export const BettingPlaceDeviceRequest = new BettingPlaceDeviceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserPermissionsRequest$Type extends MessageType<GetUserPermissionsRequest> {
    constructor() {
        super("user.GetUserPermissionsRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserPermissionsRequest>): GetUserPermissionsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserPermissionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserPermissionsRequest): GetUserPermissionsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserPermissionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetUserPermissionsRequest
 */
export const GetUserPermissionsRequest = new GetUserPermissionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserPermissionsResponse$Type extends MessageType<GetUserPermissionsResponse> {
    constructor() {
        super("user.GetUserPermissionsResponse", [
            { no: 39, name: "permissions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetUserPermissionsResponse>): GetUserPermissionsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.permissions = [];
        if (value !== undefined)
            reflectionMergePartial<GetUserPermissionsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserPermissionsResponse): GetUserPermissionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string permissions */ 39:
                    message.permissions.push(reader.string());
                    break;
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserPermissionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string permissions = 39; */
        for (let i = 0; i < message.permissions.length; i++)
            writer.tag(39, WireType.LengthDelimited).string(message.permissions[i]);
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetUserPermissionsResponse
 */
export const GetUserPermissionsResponse = new GetUserPermissionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateBonusRequest$Type extends MessageType<CreateBonusRequest> {
    constructor() {
        super("user.CreateBonusRequest", [
            { no: 41, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 49, name: "min_odd_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 53, name: "total_games_lost", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 48, name: "winning_slip_awards", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WinningSlipAward },
            { no: 52, name: "cache_back_awards", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CacheBackAward }
        ]);
    }
    create(value?: PartialMessage<CreateBonusRequest>): CreateBonusRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = "";
        message.name = "";
        message.minOddValue = 0;
        message.totalGamesLost = 0;
        message.winningSlipAwards = [];
        message.cacheBackAwards = [];
        if (value !== undefined)
            reflectionMergePartial<CreateBonusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateBonusRequest): CreateBonusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 41:
                    message.type = reader.string();
                    break;
                case /* string name */ 24:
                    message.name = reader.string();
                    break;
                case /* double min_odd_value */ 49:
                    message.minOddValue = reader.double();
                    break;
                case /* int32 total_games_lost */ 53:
                    message.totalGamesLost = reader.int32();
                    break;
                case /* repeated user.WinningSlipAward winning_slip_awards */ 48:
                    message.winningSlipAwards.push(WinningSlipAward.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated user.CacheBackAward cache_back_awards */ 52:
                    message.cacheBackAwards.push(CacheBackAward.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateBonusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 41; */
        if (message.type !== "")
            writer.tag(41, WireType.LengthDelimited).string(message.type);
        /* string name = 24; */
        if (message.name !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.name);
        /* double min_odd_value = 49; */
        if (message.minOddValue !== 0)
            writer.tag(49, WireType.Bit64).double(message.minOddValue);
        /* int32 total_games_lost = 53; */
        if (message.totalGamesLost !== 0)
            writer.tag(53, WireType.Varint).int32(message.totalGamesLost);
        /* repeated user.WinningSlipAward winning_slip_awards = 48; */
        for (let i = 0; i < message.winningSlipAwards.length; i++)
            WinningSlipAward.internalBinaryWrite(message.winningSlipAwards[i], writer.tag(48, WireType.LengthDelimited).fork(), options).join();
        /* repeated user.CacheBackAward cache_back_awards = 52; */
        for (let i = 0; i < message.cacheBackAwards.length; i++)
            CacheBackAward.internalBinaryWrite(message.cacheBackAwards[i], writer.tag(52, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.CreateBonusRequest
 */
export const CreateBonusRequest = new CreateBonusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Bonus$Type extends MessageType<Bonus> {
    constructor() {
        super("user.Bonus", [
            { no: 17, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 47, name: "percent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 50, name: "coefficient", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<Bonus>): Bonus {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.amount = 0;
        message.percent = 0;
        message.coefficient = 0;
        if (value !== undefined)
            reflectionMergePartial<Bonus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Bonus): Bonus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double amount */ 17:
                    message.amount = reader.double();
                    break;
                case /* int32 percent */ 47:
                    message.percent = reader.int32();
                    break;
                case /* int32 coefficient */ 50:
                    message.coefficient = reader.int32();
                    break;
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Bonus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double amount = 17; */
        if (message.amount !== 0)
            writer.tag(17, WireType.Bit64).double(message.amount);
        /* int32 percent = 47; */
        if (message.percent !== 0)
            writer.tag(47, WireType.Varint).int32(message.percent);
        /* int32 coefficient = 50; */
        if (message.coefficient !== 0)
            writer.tag(50, WireType.Varint).int32(message.coefficient);
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.Bonus
 */
export const Bonus = new Bonus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BonusMetadata$Type extends MessageType<BonusMetadata> {
    constructor() {
        super("user.BonusMetadata", [
            { no: 43, name: "bonus_category", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BonusCategory }
        ]);
    }
    create(value?: PartialMessage<BonusMetadata>): BonusMetadata {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bonusCategory = [];
        if (value !== undefined)
            reflectionMergePartial<BonusMetadata>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BonusMetadata): BonusMetadata {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated user.BonusCategory bonus_category */ 43:
                    message.bonusCategory.push(BonusCategory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BonusMetadata, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated user.BonusCategory bonus_category = 43; */
        for (let i = 0; i < message.bonusCategory.length; i++)
            BonusCategory.internalBinaryWrite(message.bonusCategory[i], writer.tag(43, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BonusMetadata
 */
export const BonusMetadata = new BonusMetadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BonusCategory$Type extends MessageType<BonusCategory> {
    constructor() {
        super("user.BonusCategory", [
            { no: 24, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 44, name: "bonus_type", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BonusType }
        ]);
    }
    create(value?: PartialMessage<BonusCategory>): BonusCategory {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.bonusType = [];
        if (value !== undefined)
            reflectionMergePartial<BonusCategory>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BonusCategory): BonusCategory {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 24:
                    message.name = reader.string();
                    break;
                case /* repeated user.BonusType bonus_type */ 44:
                    message.bonusType.push(BonusType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BonusCategory, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 24; */
        if (message.name !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.name);
        /* repeated user.BonusType bonus_type = 44; */
        for (let i = 0; i < message.bonusType.length; i++)
            BonusType.internalBinaryWrite(message.bonusType[i], writer.tag(44, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BonusCategory
 */
export const BonusCategory = new BonusCategory$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BonusType$Type extends MessageType<BonusType> {
    constructor() {
        super("user.BonusType", [
            { no: 24, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 45, name: "subtype", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BonusSubType }
        ]);
    }
    create(value?: PartialMessage<BonusType>): BonusType {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.subtype = [];
        if (value !== undefined)
            reflectionMergePartial<BonusType>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BonusType): BonusType {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 24:
                    message.name = reader.string();
                    break;
                case /* repeated user.BonusSubType subtype */ 45:
                    message.subtype.push(BonusSubType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BonusType, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 24; */
        if (message.name !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.name);
        /* repeated user.BonusSubType subtype = 45; */
        for (let i = 0; i < message.subtype.length; i++)
            BonusSubType.internalBinaryWrite(message.subtype[i], writer.tag(45, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BonusType
 */
export const BonusType = new BonusType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BonusSubType$Type extends MessageType<BonusSubType> {
    constructor() {
        super("user.BonusSubType", [
            { no: 24, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BonusSubType>): BonusSubType {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<BonusSubType>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BonusSubType): BonusSubType {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 24:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BonusSubType, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 24; */
        if (message.name !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BonusSubType
 */
export const BonusSubType = new BonusSubType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WinningSlipAward$Type extends MessageType<WinningSlipAward> {
    constructor() {
        super("user.WinningSlipAward", [
            { no: 24, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 46, name: "total_games_won", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 47, name: "percent", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<WinningSlipAward>): WinningSlipAward {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.totalGamesWon = 0;
        message.percent = 0;
        if (value !== undefined)
            reflectionMergePartial<WinningSlipAward>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WinningSlipAward): WinningSlipAward {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 24:
                    message.name = reader.string();
                    break;
                case /* int32 total_games_won */ 46:
                    message.totalGamesWon = reader.int32();
                    break;
                case /* int32 percent */ 47:
                    message.percent = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WinningSlipAward, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 24; */
        if (message.name !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.name);
        /* int32 total_games_won = 46; */
        if (message.totalGamesWon !== 0)
            writer.tag(46, WireType.Varint).int32(message.totalGamesWon);
        /* int32 percent = 47; */
        if (message.percent !== 0)
            writer.tag(47, WireType.Varint).int32(message.percent);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.WinningSlipAward
 */
export const WinningSlipAward = new WinningSlipAward$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CacheBackAward$Type extends MessageType<CacheBackAward> {
    constructor() {
        super("user.CacheBackAward", [
            { no: 24, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 50, name: "coefficient", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 51, name: "odd_threshold", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CacheBackAward>): CacheBackAward {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.coefficient = 0;
        message.oddThreshold = 0;
        if (value !== undefined)
            reflectionMergePartial<CacheBackAward>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CacheBackAward): CacheBackAward {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 24:
                    message.name = reader.string();
                    break;
                case /* int32 coefficient */ 50:
                    message.coefficient = reader.int32();
                    break;
                case /* int32 odd_threshold */ 51:
                    message.oddThreshold = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CacheBackAward, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 24; */
        if (message.name !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.name);
        /* int32 coefficient = 50; */
        if (message.coefficient !== 0)
            writer.tag(50, WireType.Varint).int32(message.coefficient);
        /* int32 odd_threshold = 51; */
        if (message.oddThreshold !== 0)
            writer.tag(51, WireType.Varint).int32(message.oddThreshold);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.CacheBackAward
 */
export const CacheBackAward = new CacheBackAward$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckSlipBonusRequest$Type extends MessageType<CheckSlipBonusRequest> {
    constructor() {
        super("user.CheckSlipBonusRequest", [
            { no: 15, name: "slip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 54, name: "combinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Combination }
        ]);
    }
    create(value?: PartialMessage<CheckSlipBonusRequest>): CheckSlipBonusRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.slipId = "";
        message.combinations = [];
        if (value !== undefined)
            reflectionMergePartial<CheckSlipBonusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckSlipBonusRequest): CheckSlipBonusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string slip_id */ 15:
                    message.slipId = reader.string();
                    break;
                case /* repeated user.Combination combinations */ 54:
                    message.combinations.push(Combination.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckSlipBonusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string slip_id = 15; */
        if (message.slipId !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.slipId);
        /* repeated user.Combination combinations = 54; */
        for (let i = 0; i < message.combinations.length; i++)
            Combination.internalBinaryWrite(message.combinations[i], writer.tag(54, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.CheckSlipBonusRequest
 */
export const CheckSlipBonusRequest = new CheckSlipBonusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckSlipBonusResponse$Type extends MessageType<CheckSlipBonusResponse> {
    constructor() {
        super("user.CheckSlipBonusResponse", [
            { no: 15, name: "slip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 55, name: "min_bonus_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 56, name: "max_bonus_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 88, name: "bonus_percent", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<CheckSlipBonusResponse>): CheckSlipBonusResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.slipId = "";
        message.minBonusAmount = 0;
        message.maxBonusAmount = 0;
        message.bonusPercent = 0;
        if (value !== undefined)
            reflectionMergePartial<CheckSlipBonusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckSlipBonusResponse): CheckSlipBonusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string slip_id */ 15:
                    message.slipId = reader.string();
                    break;
                case /* double min_bonus_amount */ 55:
                    message.minBonusAmount = reader.double();
                    break;
                case /* double max_bonus_amount */ 56:
                    message.maxBonusAmount = reader.double();
                    break;
                case /* double bonus_percent */ 88:
                    message.bonusPercent = reader.double();
                    break;
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckSlipBonusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string slip_id = 15; */
        if (message.slipId !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.slipId);
        /* double min_bonus_amount = 55; */
        if (message.minBonusAmount !== 0)
            writer.tag(55, WireType.Bit64).double(message.minBonusAmount);
        /* double max_bonus_amount = 56; */
        if (message.maxBonusAmount !== 0)
            writer.tag(56, WireType.Bit64).double(message.maxBonusAmount);
        /* double bonus_percent = 88; */
        if (message.bonusPercent !== 0)
            writer.tag(88, WireType.Bit64).double(message.bonusPercent);
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.CheckSlipBonusResponse
 */
export const CheckSlipBonusResponse = new CheckSlipBonusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Combination$Type extends MessageType<Combination> {
    constructor() {
        super("user.Combination", [
            { no: 17, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 57, name: "odd_values", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<Combination>): Combination {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.amount = 0;
        message.oddValues = [];
        if (value !== undefined)
            reflectionMergePartial<Combination>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Combination): Combination {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double amount */ 17:
                    message.amount = reader.double();
                    break;
                case /* repeated double odd_values */ 57:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.oddValues.push(reader.double());
                    else
                        message.oddValues.push(reader.double());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Combination, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double amount = 17; */
        if (message.amount !== 0)
            writer.tag(17, WireType.Bit64).double(message.amount);
        /* repeated double odd_values = 57; */
        if (message.oddValues.length) {
            writer.tag(57, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.oddValues.length; i++)
                writer.double(message.oddValues[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.Combination
 */
export const Combination = new Combination$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrantCacheBackBonusRequest$Type extends MessageType<GrantCacheBackBonusRequest> {
    constructor() {
        super("user.GrantCacheBackBonusRequest", [
            { no: 15, name: "slip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 51, name: "odd_threshold", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<GrantCacheBackBonusRequest>): GrantCacheBackBonusRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.slipId = "";
        message.amount = 0;
        message.oddThreshold = 0;
        if (value !== undefined)
            reflectionMergePartial<GrantCacheBackBonusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GrantCacheBackBonusRequest): GrantCacheBackBonusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string slip_id */ 15:
                    message.slipId = reader.string();
                    break;
                case /* double amount */ 17:
                    message.amount = reader.double();
                    break;
                case /* double odd_threshold */ 51:
                    message.oddThreshold = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GrantCacheBackBonusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string slip_id = 15; */
        if (message.slipId !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.slipId);
        /* double amount = 17; */
        if (message.amount !== 0)
            writer.tag(17, WireType.Bit64).double(message.amount);
        /* double odd_threshold = 51; */
        if (message.oddThreshold !== 0)
            writer.tag(51, WireType.Bit64).double(message.oddThreshold);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GrantCacheBackBonusRequest
 */
export const GrantCacheBackBonusRequest = new GrantCacheBackBonusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceCode$Type extends MessageType<DeviceCode> {
    constructor() {
        super("user.DeviceCode", [
            { no: 59, name: "device_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeviceCode>): DeviceCode {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deviceCode = "";
        if (value !== undefined)
            reflectionMergePartial<DeviceCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceCode): DeviceCode {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string device_code */ 59:
                    message.deviceCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeviceCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string device_code = 59; */
        if (message.deviceCode !== "")
            writer.tag(59, WireType.LengthDelimited).string(message.deviceCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.DeviceCode
 */
export const DeviceCode = new DeviceCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBettingPlaceInfoByDeviceCodeResponse$Type extends MessageType<GetBettingPlaceInfoByDeviceCodeResponse> {
    constructor() {
        super("user.GetBettingPlaceInfoByDeviceCodeResponse", [
            { no: 60, name: "betting_place", kind: "message", T: () => BettingPlace },
            { no: 61, name: "device", kind: "message", T: () => BettingPlaceDevice },
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetBettingPlaceInfoByDeviceCodeResponse>): GetBettingPlaceInfoByDeviceCodeResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetBettingPlaceInfoByDeviceCodeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBettingPlaceInfoByDeviceCodeResponse): GetBettingPlaceInfoByDeviceCodeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* user.BettingPlace betting_place */ 60:
                    message.bettingPlace = BettingPlace.internalBinaryRead(reader, reader.uint32(), options, message.bettingPlace);
                    break;
                case /* user.BettingPlaceDevice device */ 61:
                    message.device = BettingPlaceDevice.internalBinaryRead(reader, reader.uint32(), options, message.device);
                    break;
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBettingPlaceInfoByDeviceCodeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* user.BettingPlace betting_place = 60; */
        if (message.bettingPlace)
            BettingPlace.internalBinaryWrite(message.bettingPlace, writer.tag(60, WireType.LengthDelimited).fork(), options).join();
        /* user.BettingPlaceDevice device = 61; */
        if (message.device)
            BettingPlaceDevice.internalBinaryWrite(message.device, writer.tag(61, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetBettingPlaceInfoByDeviceCodeResponse
 */
export const GetBettingPlaceInfoByDeviceCodeResponse = new GetBettingPlaceInfoByDeviceCodeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBettingPlaceInfoByIsonisPlaceCodeResponse$Type extends MessageType<GetBettingPlaceInfoByIsonisPlaceCodeResponse> {
    constructor() {
        super("user.GetBettingPlaceInfoByIsonisPlaceCodeResponse", [
            { no: 19, name: "betting_place_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 91, name: "betting_place_terminal_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 92, name: "serial_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 93, name: "betting_place_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 85, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 58, name: "municipality_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetBettingPlaceInfoByIsonisPlaceCodeResponse>): GetBettingPlaceInfoByIsonisPlaceCodeResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingPlaceId = 0;
        message.bettingPlaceTerminalId = 0;
        message.serialNumber = "";
        message.bettingPlaceCode = "";
        message.name = "";
        message.address = "";
        message.municipalityName = "";
        if (value !== undefined)
            reflectionMergePartial<GetBettingPlaceInfoByIsonisPlaceCodeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBettingPlaceInfoByIsonisPlaceCodeResponse): GetBettingPlaceInfoByIsonisPlaceCodeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 betting_place_id */ 19:
                    message.bettingPlaceId = reader.int32();
                    break;
                case /* int32 betting_place_terminal_id */ 91:
                    message.bettingPlaceTerminalId = reader.int32();
                    break;
                case /* string serial_number */ 92:
                    message.serialNumber = reader.string();
                    break;
                case /* string betting_place_code */ 93:
                    message.bettingPlaceCode = reader.string();
                    break;
                case /* string name */ 24:
                    message.name = reader.string();
                    break;
                case /* string address */ 85:
                    message.address = reader.string();
                    break;
                case /* string municipality_name */ 58:
                    message.municipalityName = reader.string();
                    break;
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBettingPlaceInfoByIsonisPlaceCodeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 betting_place_id = 19; */
        if (message.bettingPlaceId !== 0)
            writer.tag(19, WireType.Varint).int32(message.bettingPlaceId);
        /* int32 betting_place_terminal_id = 91; */
        if (message.bettingPlaceTerminalId !== 0)
            writer.tag(91, WireType.Varint).int32(message.bettingPlaceTerminalId);
        /* string serial_number = 92; */
        if (message.serialNumber !== "")
            writer.tag(92, WireType.LengthDelimited).string(message.serialNumber);
        /* string betting_place_code = 93; */
        if (message.bettingPlaceCode !== "")
            writer.tag(93, WireType.LengthDelimited).string(message.bettingPlaceCode);
        /* string name = 24; */
        if (message.name !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.name);
        /* string address = 85; */
        if (message.address !== "")
            writer.tag(85, WireType.LengthDelimited).string(message.address);
        /* string municipality_name = 58; */
        if (message.municipalityName !== "")
            writer.tag(58, WireType.LengthDelimited).string(message.municipalityName);
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetBettingPlaceInfoByIsonisPlaceCodeResponse
 */
export const GetBettingPlaceInfoByIsonisPlaceCodeResponse = new GetBettingPlaceInfoByIsonisPlaceCodeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidationPriorityObjects$Type extends MessageType<ValidationPriorityObjects> {
    constructor() {
        super("user.ValidationPriorityObjects", [
            { no: 63, name: "betting_places_map", kind: "map", K: 5 /*ScalarType.INT32*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<ValidationPriorityObjects>): ValidationPriorityObjects {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingPlacesMap = {};
        if (value !== undefined)
            reflectionMergePartial<ValidationPriorityObjects>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidationPriorityObjects): ValidationPriorityObjects {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<int32, string> betting_places_map */ 63:
                    this.binaryReadMap63(message.bettingPlacesMap, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap63(map: ValidationPriorityObjects["bettingPlacesMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ValidationPriorityObjects["bettingPlacesMap"] | undefined, val: ValidationPriorityObjects["bettingPlacesMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field user.ValidationPriorityObjects.betting_places_map");
            }
        }
        map[key ?? 0] = val ?? "";
    }
    internalBinaryWrite(message: ValidationPriorityObjects, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<int32, string> betting_places_map = 63; */
        for (let k of globalThis.Object.keys(message.bettingPlacesMap))
            writer.tag(63, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k)).tag(2, WireType.LengthDelimited).string(message.bettingPlacesMap[k as any]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.ValidationPriorityObjects
 */
export const ValidationPriorityObjects = new ValidationPriorityObjects$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActiveBettingPlaceCodes$Type extends MessageType<ActiveBettingPlaceCodes> {
    constructor() {
        super("user.ActiveBettingPlaceCodes", [
            { no: 87, name: "active_codes", kind: "map", K: 5 /*ScalarType.INT32*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<ActiveBettingPlaceCodes>): ActiveBettingPlaceCodes {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.activeCodes = {};
        if (value !== undefined)
            reflectionMergePartial<ActiveBettingPlaceCodes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActiveBettingPlaceCodes): ActiveBettingPlaceCodes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<int32, string> active_codes */ 87:
                    this.binaryReadMap87(message.activeCodes, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap87(map: ActiveBettingPlaceCodes["activeCodes"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ActiveBettingPlaceCodes["activeCodes"] | undefined, val: ActiveBettingPlaceCodes["activeCodes"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field user.ActiveBettingPlaceCodes.active_codes");
            }
        }
        map[key ?? 0] = val ?? "";
    }
    internalBinaryWrite(message: ActiveBettingPlaceCodes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<int32, string> active_codes = 87; */
        for (let k of globalThis.Object.keys(message.activeCodes))
            writer.tag(87, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k)).tag(2, WireType.LengthDelimited).string(message.activeCodes[k as any]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.ActiveBettingPlaceCodes
 */
export const ActiveBettingPlaceCodes = new ActiveBettingPlaceCodes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Municipality$Type extends MessageType<Municipality> {
    constructor() {
        super("user.Municipality", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 24, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "betting_places", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BettingPlace }
        ]);
    }
    create(value?: PartialMessage<Municipality>): Municipality {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.bettingPlaces = [];
        if (value !== undefined)
            reflectionMergePartial<Municipality>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Municipality): Municipality {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 24:
                    message.name = reader.string();
                    break;
                case /* repeated user.BettingPlace betting_places */ 23:
                    message.bettingPlaces.push(BettingPlace.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Municipality, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 24; */
        if (message.name !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.name);
        /* repeated user.BettingPlace betting_places = 23; */
        for (let i = 0; i < message.bettingPlaces.length; i++)
            BettingPlace.internalBinaryWrite(message.bettingPlaces[i], writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.Municipality
 */
export const Municipality = new Municipality$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBettingPlacesByMunicipalityResponse$Type extends MessageType<GetBettingPlacesByMunicipalityResponse> {
    constructor() {
        super("user.GetBettingPlacesByMunicipalityResponse", [
            { no: 64, name: "municipalities", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Municipality }
        ]);
    }
    create(value?: PartialMessage<GetBettingPlacesByMunicipalityResponse>): GetBettingPlacesByMunicipalityResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.municipalities = [];
        if (value !== undefined)
            reflectionMergePartial<GetBettingPlacesByMunicipalityResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBettingPlacesByMunicipalityResponse): GetBettingPlacesByMunicipalityResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated user.Municipality municipalities */ 64:
                    message.municipalities.push(Municipality.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBettingPlacesByMunicipalityResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated user.Municipality municipalities = 64; */
        for (let i = 0; i < message.municipalities.length; i++)
            Municipality.internalBinaryWrite(message.municipalities[i], writer.tag(64, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetBettingPlacesByMunicipalityResponse
 */
export const GetBettingPlacesByMunicipalityResponse = new GetBettingPlacesByMunicipalityResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListActivityRequest$Type extends MessageType<ListActivityRequest> {
    constructor() {
        super("user.ListActivityRequest", [
            { no: 65, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 80, name: "date_from", kind: "message", T: () => Timestamp },
            { no: 81, name: "date_to", kind: "message", T: () => Timestamp },
            { no: 82, name: "query_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListActivityRequest>): ListActivityRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.queryString = "";
        if (value !== undefined)
            reflectionMergePartial<ListActivityRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListActivityRequest): ListActivityRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 65:
                    message.page = reader.int32();
                    break;
                case /* google.protobuf.Timestamp date_from */ 80:
                    message.dateFrom = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dateFrom);
                    break;
                case /* google.protobuf.Timestamp date_to */ 81:
                    message.dateTo = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dateTo);
                    break;
                case /* string query_string */ 82:
                    message.queryString = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListActivityRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 65; */
        if (message.page !== 0)
            writer.tag(65, WireType.Varint).int32(message.page);
        /* google.protobuf.Timestamp date_from = 80; */
        if (message.dateFrom)
            Timestamp.internalBinaryWrite(message.dateFrom, writer.tag(80, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp date_to = 81; */
        if (message.dateTo)
            Timestamp.internalBinaryWrite(message.dateTo, writer.tag(81, WireType.LengthDelimited).fork(), options).join();
        /* string query_string = 82; */
        if (message.queryString !== "")
            writer.tag(82, WireType.LengthDelimited).string(message.queryString);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.ListActivityRequest
 */
export const ListActivityRequest = new ListActivityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListActivityResponse$Type extends MessageType<ListActivityResponse> {
    constructor() {
        super("user.ListActivityResponse", [
            { no: 66, name: "activities", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Activity },
            { no: 67, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 68, name: "total_pages", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<ListActivityResponse>): ListActivityResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.activities = [];
        message.page = 0;
        message.totalPages = 0;
        if (value !== undefined)
            reflectionMergePartial<ListActivityResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListActivityResponse): ListActivityResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated user.Activity activities */ 66:
                    message.activities.push(Activity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 page */ 67:
                    message.page = reader.int32();
                    break;
                case /* int32 total_pages */ 68:
                    message.totalPages = reader.int32();
                    break;
                case /* common.Error error */ 22:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListActivityResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated user.Activity activities = 66; */
        for (let i = 0; i < message.activities.length; i++)
            Activity.internalBinaryWrite(message.activities[i], writer.tag(66, WireType.LengthDelimited).fork(), options).join();
        /* int32 page = 67; */
        if (message.page !== 0)
            writer.tag(67, WireType.Varint).int32(message.page);
        /* int32 total_pages = 68; */
        if (message.totalPages !== 0)
            writer.tag(68, WireType.Varint).int32(message.totalPages);
        /* common.Error error = 22; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.ListActivityResponse
 */
export const ListActivityResponse = new ListActivityResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Activity$Type extends MessageType<Activity> {
    constructor() {
        super("user.Activity", [
            { no: 69, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 70, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 71, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 72, name: "role_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 73, name: "betting_place_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 74, name: "betting_place_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 75, name: "betting_place_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 76, name: "device_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 77, name: "activity", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 78, name: "activity_kwargs", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 79, name: "created_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<Activity>): Activity {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = 0;
        message.firstName = "";
        message.lastName = "";
        message.roleId = 0;
        message.bettingPlaceId = 0;
        message.bettingPlaceName = "";
        message.bettingPlaceCode = "";
        message.deviceId = 0;
        message.activity = "";
        message.activityKwargs = "";
        if (value !== undefined)
            reflectionMergePartial<Activity>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Activity): Activity {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 69:
                    message.userId = reader.int32();
                    break;
                case /* string first_name */ 70:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 71:
                    message.lastName = reader.string();
                    break;
                case /* int32 role_id */ 72:
                    message.roleId = reader.int32();
                    break;
                case /* int32 betting_place_id */ 73:
                    message.bettingPlaceId = reader.int32();
                    break;
                case /* string betting_place_name */ 74:
                    message.bettingPlaceName = reader.string();
                    break;
                case /* string betting_place_code */ 75:
                    message.bettingPlaceCode = reader.string();
                    break;
                case /* int32 device_id */ 76:
                    message.deviceId = reader.int32();
                    break;
                case /* string activity */ 77:
                    message.activity = reader.string();
                    break;
                case /* string activity_kwargs */ 78:
                    message.activityKwargs = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 79:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Activity, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 user_id = 69; */
        if (message.userId !== 0)
            writer.tag(69, WireType.Varint).int32(message.userId);
        /* string first_name = 70; */
        if (message.firstName !== "")
            writer.tag(70, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 71; */
        if (message.lastName !== "")
            writer.tag(71, WireType.LengthDelimited).string(message.lastName);
        /* int32 role_id = 72; */
        if (message.roleId !== 0)
            writer.tag(72, WireType.Varint).int32(message.roleId);
        /* int32 betting_place_id = 73; */
        if (message.bettingPlaceId !== 0)
            writer.tag(73, WireType.Varint).int32(message.bettingPlaceId);
        /* string betting_place_name = 74; */
        if (message.bettingPlaceName !== "")
            writer.tag(74, WireType.LengthDelimited).string(message.bettingPlaceName);
        /* string betting_place_code = 75; */
        if (message.bettingPlaceCode !== "")
            writer.tag(75, WireType.LengthDelimited).string(message.bettingPlaceCode);
        /* int32 device_id = 76; */
        if (message.deviceId !== 0)
            writer.tag(76, WireType.Varint).int32(message.deviceId);
        /* string activity = 77; */
        if (message.activity !== "")
            writer.tag(77, WireType.LengthDelimited).string(message.activity);
        /* string activity_kwargs = 78; */
        if (message.activityKwargs !== "")
            writer.tag(78, WireType.LengthDelimited).string(message.activityKwargs);
        /* google.protobuf.Timestamp created_at = 79; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(79, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.Activity
 */
export const Activity = new Activity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DoublePrintRequest$Type extends MessageType<DoublePrintRequest> {
    constructor() {
        super("user.DoublePrintRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DoublePrintRequest>): DoublePrintRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<DoublePrintRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DoublePrintRequest): DoublePrintRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DoublePrintRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.DoublePrintRequest
 */
export const DoublePrintRequest = new DoublePrintRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BlankSlipRequest$Type extends MessageType<BlankSlipRequest> {
    constructor() {
        super("user.BlankSlipRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<BlankSlipRequest>): BlankSlipRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<BlankSlipRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BlankSlipRequest): BlankSlipRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BlankSlipRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BlankSlipRequest
 */
export const BlankSlipRequest = new BlankSlipRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TestBettingPlaceRequest$Type extends MessageType<TestBettingPlaceRequest> {
    constructor() {
        super("user.TestBettingPlaceRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<TestBettingPlaceRequest>): TestBettingPlaceRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<TestBettingPlaceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TestBettingPlaceRequest): TestBettingPlaceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TestBettingPlaceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.TestBettingPlaceRequest
 */
export const TestBettingPlaceRequest = new TestBettingPlaceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TestBettingPlacesResponse$Type extends MessageType<TestBettingPlacesResponse> {
    constructor() {
        super("user.TestBettingPlacesResponse", [
            { no: 1, name: "ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<TestBettingPlacesResponse>): TestBettingPlacesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ids = [];
        if (value !== undefined)
            reflectionMergePartial<TestBettingPlacesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TestBettingPlacesResponse): TestBettingPlacesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ids.push(reader.int32());
                    else
                        message.ids.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TestBettingPlacesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int32 ids = 1; */
        if (message.ids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ids.length; i++)
                writer.int32(message.ids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.TestBettingPlacesResponse
 */
export const TestBettingPlacesResponse = new TestBettingPlacesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTestBettingPlaceResponse$Type extends MessageType<GetTestBettingPlaceResponse> {
    constructor() {
        super("user.GetTestBettingPlaceResponse", [
            { no: 1, name: "test", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetTestBettingPlaceResponse>): GetTestBettingPlaceResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.test = false;
        if (value !== undefined)
            reflectionMergePartial<GetTestBettingPlaceResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTestBettingPlaceResponse): GetTestBettingPlaceResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool test */ 1:
                    message.test = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTestBettingPlaceResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool test = 1; */
        if (message.test !== false)
            writer.tag(1, WireType.Varint).bool(message.test);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetTestBettingPlaceResponse
 */
export const GetTestBettingPlaceResponse = new GetTestBettingPlaceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckSameMunicipalityBPSRequest$Type extends MessageType<CheckSameMunicipalityBPSRequest> {
    constructor() {
        super("user.CheckSameMunicipalityBPSRequest", [
            { no: 1, name: "bp1", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "bp2", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CheckSameMunicipalityBPSRequest>): CheckSameMunicipalityBPSRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bp1 = 0;
        message.bp2 = 0;
        if (value !== undefined)
            reflectionMergePartial<CheckSameMunicipalityBPSRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckSameMunicipalityBPSRequest): CheckSameMunicipalityBPSRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 bp1 */ 1:
                    message.bp1 = reader.int32();
                    break;
                case /* int32 bp2 */ 2:
                    message.bp2 = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckSameMunicipalityBPSRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 bp1 = 1; */
        if (message.bp1 !== 0)
            writer.tag(1, WireType.Varint).int32(message.bp1);
        /* int32 bp2 = 2; */
        if (message.bp2 !== 0)
            writer.tag(2, WireType.Varint).int32(message.bp2);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.CheckSameMunicipalityBPSRequest
 */
export const CheckSameMunicipalityBPSRequest = new CheckSameMunicipalityBPSRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckSameMunicipalityBPSResponse$Type extends MessageType<CheckSameMunicipalityBPSResponse> {
    constructor() {
        super("user.CheckSameMunicipalityBPSResponse", [
            { no: 1, name: "check", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<CheckSameMunicipalityBPSResponse>): CheckSameMunicipalityBPSResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.check = false;
        if (value !== undefined)
            reflectionMergePartial<CheckSameMunicipalityBPSResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckSameMunicipalityBPSResponse): CheckSameMunicipalityBPSResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool check */ 1:
                    message.check = reader.bool();
                    break;
                case /* common.Error error */ 6:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckSameMunicipalityBPSResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool check = 1; */
        if (message.check !== false)
            writer.tag(1, WireType.Varint).bool(message.check);
        /* common.Error error = 6; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.CheckSameMunicipalityBPSResponse
 */
export const CheckSameMunicipalityBPSResponse = new CheckSameMunicipalityBPSResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBPBettingMachinesResponse$Type extends MessageType<GetBPBettingMachinesResponse> {
    constructor() {
        super("user.GetBPBettingMachinesResponse", [
            { no: 1, name: "betting_machines", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BettingMachine }
        ]);
    }
    create(value?: PartialMessage<GetBPBettingMachinesResponse>): GetBPBettingMachinesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingMachines = [];
        if (value !== undefined)
            reflectionMergePartial<GetBPBettingMachinesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBPBettingMachinesResponse): GetBPBettingMachinesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated user.BettingMachine betting_machines */ 1:
                    message.bettingMachines.push(BettingMachine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBPBettingMachinesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated user.BettingMachine betting_machines = 1; */
        for (let i = 0; i < message.bettingMachines.length; i++)
            BettingMachine.internalBinaryWrite(message.bettingMachines[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetBPBettingMachinesResponse
 */
export const GetBPBettingMachinesResponse = new GetBPBettingMachinesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BettingPlaceLimitsRequest$Type extends MessageType<BettingPlaceLimitsRequest> {
    constructor() {
        super("user.BettingPlaceLimitsRequest", [
            { no: 1, name: "betting_place_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<BettingPlaceLimitsRequest>): BettingPlaceLimitsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingPlaceId = 0;
        if (value !== undefined)
            reflectionMergePartial<BettingPlaceLimitsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BettingPlaceLimitsRequest): BettingPlaceLimitsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 betting_place_id */ 1:
                    message.bettingPlaceId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BettingPlaceLimitsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 betting_place_id = 1; */
        if (message.bettingPlaceId !== 0)
            writer.tag(1, WireType.Varint).int32(message.bettingPlaceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.BettingPlaceLimitsRequest
 */
export const BettingPlaceLimitsRequest = new BettingPlaceLimitsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLandbasePlayersRequest$Type extends MessageType<GetLandbasePlayersRequest> {
    constructor() {
        super("user.GetLandbasePlayersRequest", [
            { no: 1, name: "betting_place_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetLandbasePlayersRequest>): GetLandbasePlayersRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bettingPlaceId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetLandbasePlayersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLandbasePlayersRequest): GetLandbasePlayersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 betting_place_id */ 1:
                    message.bettingPlaceId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLandbasePlayersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 betting_place_id = 1; */
        if (message.bettingPlaceId !== 0)
            writer.tag(1, WireType.Varint).int32(message.bettingPlaceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetLandbasePlayersRequest
 */
export const GetLandbasePlayersRequest = new GetLandbasePlayersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLandbasePlayersResponse$Type extends MessageType<GetLandbasePlayersResponse> {
    constructor() {
        super("user.GetLandbasePlayersResponse", [
            { no: 1, name: "landbase_players", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LandbasePlayer }
        ]);
    }
    create(value?: PartialMessage<GetLandbasePlayersResponse>): GetLandbasePlayersResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.landbasePlayers = [];
        if (value !== undefined)
            reflectionMergePartial<GetLandbasePlayersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLandbasePlayersResponse): GetLandbasePlayersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated user.LandbasePlayer landbase_players */ 1:
                    message.landbasePlayers.push(LandbasePlayer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLandbasePlayersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated user.LandbasePlayer landbase_players = 1; */
        for (let i = 0; i < message.landbasePlayers.length; i++)
            LandbasePlayer.internalBinaryWrite(message.landbasePlayers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.GetLandbasePlayersResponse
 */
export const GetLandbasePlayersResponse = new GetLandbasePlayersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LandbasePlayer$Type extends MessageType<LandbasePlayer> {
    constructor() {
        super("user.LandbasePlayer", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "date_of_birth", kind: "message", T: () => Timestamp },
            { no: 5, name: "jmbg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "passport_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "identification_document_country", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "identification_document_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "betting_places", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BettingPlace },
            { no: 11, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<LandbasePlayer>): LandbasePlayer {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.firstName = "";
        message.lastName = "";
        message.jmbg = "";
        message.passportNumber = "";
        message.identificationDocumentCountry = "";
        message.identificationDocumentType = "";
        message.phoneNumber = "";
        message.bettingPlaces = [];
        if (value !== undefined)
            reflectionMergePartial<LandbasePlayer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LandbasePlayer): LandbasePlayer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string first_name */ 2:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 3:
                    message.lastName = reader.string();
                    break;
                case /* google.protobuf.Timestamp date_of_birth */ 4:
                    message.dateOfBirth = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dateOfBirth);
                    break;
                case /* string jmbg */ 5:
                    message.jmbg = reader.string();
                    break;
                case /* string passport_number */ 6:
                    message.passportNumber = reader.string();
                    break;
                case /* string identification_document_country */ 7:
                    message.identificationDocumentCountry = reader.string();
                    break;
                case /* string identification_document_type */ 8:
                    message.identificationDocumentType = reader.string();
                    break;
                case /* string phone_number */ 9:
                    message.phoneNumber = reader.string();
                    break;
                case /* repeated user.BettingPlace betting_places */ 10:
                    message.bettingPlaces.push(BettingPlace.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 11:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LandbasePlayer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string first_name = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 3; */
        if (message.lastName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastName);
        /* google.protobuf.Timestamp date_of_birth = 4; */
        if (message.dateOfBirth)
            Timestamp.internalBinaryWrite(message.dateOfBirth, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string jmbg = 5; */
        if (message.jmbg !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.jmbg);
        /* string passport_number = 6; */
        if (message.passportNumber !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.passportNumber);
        /* string identification_document_country = 7; */
        if (message.identificationDocumentCountry !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.identificationDocumentCountry);
        /* string identification_document_type = 8; */
        if (message.identificationDocumentType !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.identificationDocumentType);
        /* string phone_number = 9; */
        if (message.phoneNumber !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.phoneNumber);
        /* repeated user.BettingPlace betting_places = 10; */
        for (let i = 0; i < message.bettingPlaces.length; i++)
            BettingPlace.internalBinaryWrite(message.bettingPlaces[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 11; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message user.LandbasePlayer
 */
export const LandbasePlayer = new LandbasePlayer$Type();
/**
 * @generated ServiceType for protobuf service user.UserService
 */
export const UserService = new ServiceType("user.UserService", [
    { name: "CreateUser", options: {}, I: CreateUserRequest, O: UserResponse },
    { name: "CreatePlatformUser", options: {}, I: CreatePlatformUserRequest, O: UserResponse },
    { name: "GetUserByEmailAndPassword", options: {}, I: GetUserByEmailAndPasswordRequest, O: GetUserByEmailAndPasswordResponse },
    { name: "GetUserPermissions", options: {}, I: GetUserPermissionsRequest, O: GetUserPermissionsResponse },
    { name: "GetApprovalId", options: {}, I: GetUserRequest, O: GetUserRequest },
    { name: "GetUser", options: {}, I: GetUserRequest, O: UserResponse },
    { name: "UpdateUser", options: {}, I: UpdateUserRequest, O: UserResponse },
    { name: "ListUsers", options: {}, I: ListUsersRequest, O: ListUsersResponse },
    { name: "ListActivity", options: {}, I: ListActivityRequest, O: ListActivityResponse },
    { name: "ReserveFunds", options: {}, I: ReserveFundsRequest, O: ReserveFundsResponse },
    { name: "ConfirmFunds", options: {}, I: ReleaseFundsRequest, O: ConfirmFundsResponse },
    { name: "ReleaseFunds", options: {}, I: ReleaseFundsRequest, O: ReleaseFundsResponse },
    { name: "GetBonusMetadata", options: {}, I: Empty, O: BonusMetadata },
    { name: "CreateBonus", options: {}, I: CreateBonusRequest, O: Bonus },
    { name: "CheckSlipBonus", options: {}, I: CheckSlipBonusRequest, O: CheckSlipBonusResponse },
    { name: "GrantCacheBackBonus", options: {}, I: GrantCacheBackBonusRequest, O: Bonus },
    { name: "CheckMultipleSlipsBonuses", serverStreaming: true, clientStreaming: true, options: {}, I: CheckSlipBonusRequest, O: CheckSlipBonusResponse },
    { name: "GetBettingPlacesBO", options: {}, I: GetBettingPlacesBoRequest, O: GetBettingPlacesBoResponse },
    { name: "GetAllBettingPlacesBO", options: {}, I: GetBettingPlacesBoRequest, O: GetBettingPlacesBoResponse },
    { name: "GetBettingPlaceInfoByDeviceCode", options: {}, I: DeviceCode, O: GetBettingPlaceInfoByDeviceCodeResponse },
    { name: "GetBettingPlaceInfoByIsonisPlaceCode", options: {}, I: DeviceCode, O: GetBettingPlaceInfoByIsonisPlaceCodeResponse },
    { name: "ReportBettingPlaceStatus", options: {}, I: Empty, O: EmptyError },
    { name: "GetValidationPriorityObjects", options: {}, I: Empty, O: ValidationPriorityObjects },
    { name: "GetBettingPlacesByMunicipality", options: {}, I: Empty, O: GetBettingPlacesByMunicipalityResponse },
    { name: "DoublePrint", options: {}, I: BettingPlaceDevice, O: EmptyError },
    { name: "BlankSlip", options: {}, I: BettingPlaceDevice, O: EmptyError },
    { name: "NotReporting", options: {}, I: BettingPlaceDevice, O: EmptyError },
    { name: "IsNotReporting", options: {}, I: BettingPlaceDevice, O: BettingPlaceDevice },
    { name: "GetActiveBettingPlaceCodes", options: {}, I: GetBettingPlacesBoRequest, O: ActiveBettingPlaceCodes },
    { name: "GetCrediteeList", options: {}, I: BettingPlace, O: CrediteeList },
    { name: "SetTestBettingPlace", options: {}, I: IsTestValues, O: EmptyError },
    { name: "GetTestBettingPlace", options: {}, I: TestBettingPlaceRequest, O: GetTestBettingPlaceResponse },
    { name: "GetTestBettingPlaces", options: {}, I: Empty, O: TestBettingPlacesResponse },
    { name: "SetAllowDeposit", options: {}, I: SetAllowDepositRequest, O: EmptyError },
    { name: "GetAllowDeposit", options: {}, I: GetAllowDepositRequest, O: GetAllowDepositResponse },
    { name: "CreateNewBettingPlace", options: {}, I: NewBettingPlaceRequest, O: NewBettingPlace },
    { name: "EditBettingPlace", options: {}, I: EditBettingPlaceRequest, O: NewBettingPlace },
    { name: "CreateNewBettingPlaceDevice", options: {}, I: BettingPlaceDeviceRequest, O: BettingPlaceDevice },
    { name: "EditBettingPlaceDevice", options: {}, I: BettingPlaceDeviceRequest, O: BettingPlaceDevice },
    { name: "CheckSameMunicipalityBPS", options: {}, I: CheckSameMunicipalityBPSRequest, O: CheckSameMunicipalityBPSResponse },
    { name: "GetBPBettingMachines", options: {}, I: Empty, O: GetBPBettingMachinesResponse },
    { name: "SetBettingPlaceMinimumLimits", options: {}, I: BettingPlaceLimitsRequest, O: EmptyError },
    { name: "RequestNewBettingPlaceLimits", options: {}, I: BettingPlaceLimitsRequest, O: EmptyError },
    { name: "SetActiveBettingPlace", options: {}, I: SetActiveBettingPlaceRequest, O: EmptyError },
    { name: "GetEmployees", options: {}, I: GetEmployeesRequest, O: GetEmployeesResponse },
    { name: "CreateEmployee", options: {}, I: Employee, O: Employee },
    { name: "EditEmployee", options: {}, I: Employee, O: Employee },
    { name: "ResetEmployeePassword", options: {}, I: Employee, O: Employee },
    { name: "ChangeEmployeePassword", options: {}, I: Employee, O: Employee },
    { name: "DeleteEmployee", options: {}, I: Employee, O: Employee },
    { name: "GetBackOfficeUsers", options: {}, I: GetBackOfficeUsersRequest, O: GetBackOfficeUsersResponse },
    { name: "GetBackOfficePermissionMap", options: {}, I: Empty, O: BoPermissionMap },
    { name: "UpdateBackOfficeUserPermission", options: {}, I: BackOfficeUser, O: EmptyError },
    { name: "CreateEditBackOfficeUser", options: {}, I: BackOfficeUser, O: BackOfficeUser },
    { name: "DeleteBackOfficeUser", options: {}, I: BackOfficeUser, O: BackOfficeUser },
    { name: "GetAgents", options: {}, I: GetAgentsRequest, O: GetAgentsResponse },
    { name: "CreateEditAgent", options: {}, I: Agent, O: Agent },
    { name: "DeleteAgent", options: {}, I: Agent, O: Agent },
    { name: "GetAgentCommissionAndLimit", options: {}, I: Agent, O: GetAgentCommisionAndLimitResponse },
    { name: "AgentPayin", options: {}, I: AgentPayinRequest, O: GetAgentCommisionAndLimitResponse },
    { name: "GetLandbasePlayersBO", options: {}, I: Empty, O: GetLandbasePlayersResponse },
    { name: "GetBettingPlaceLandbasePlayers", options: {}, I: GetLandbasePlayersRequest, O: GetLandbasePlayersResponse },
    { name: "CreateLandbasePlayer", options: {}, I: LandbasePlayer, O: LandbasePlayer },
    { name: "EditLandbasePlayer", options: {}, I: LandbasePlayer, O: LandbasePlayer },
    { name: "DeleteLandbasePlayer", options: {}, I: LandbasePlayer, O: EmptyError },
    { name: "GetBettingMachines", options: {}, I: GetBettingMachinesRequest, O: GetBettingMachinesResponse },
    { name: "CreateBettingMachine", options: {}, I: BettingMachine, O: BettingMachine },
    { name: "EditBettingMachine", options: {}, I: BettingMachine, O: BettingMachine },
    { name: "DeleteBettingMachine", options: {}, I: BettingMachine, O: BettingMachine },
    { name: "GetBettingMachineByUsernameAndToken", options: {}, I: GetBettingMachineByUsernameAndTokenRequest, O: GetBettingMachineByUsernameAndTokenResponse },
    { name: "GetAllBettingMachinesForBettingPlace", options: {}, I: BettingPlaceId, O: GetBettingMachinesResponse },
    { name: "GetLiveBettingMachines", options: {}, I: Empty, O: GetLiveBettingMachinesResponse },
    { name: "EmptyBettingMachine", options: {}, I: BettingMachine, O: EmptyError },
    { name: "CreateBettingMachineExternalLink", options: {}, I: BettingMachineExternalLink, O: BettingMachineExternalLink },
    { name: "DeleteBettingMachineExternalLink", options: {}, I: BettingMachineExternalLink, O: BettingMachineExternalLink },
    { name: "EditBettingMachineExternalLink", options: {}, I: BettingMachineExternalLink, O: BettingMachineExternalLink },
    { name: "GetBettingMachineExternalLinks", options: {}, I: Empty, O: BettingMachineExternalLinks },
    { name: "SetBettingMachineExternalLink", options: {}, I: BettingMachineExternalLink, O: Empty },
    { name: "CreateNewBMMenuItem", options: {}, I: NewBMMenuItem, O: BMMenuItem },
    { name: "EditBMMenuItem", options: {}, I: EditBMMenuItemRequest, O: BMMenuItem },
    { name: "GetAllBMMenuItems", options: {}, I: Empty, O: AllBMMenuItems },
    { name: "UpdateGlobalActiveMenu", options: {}, I: AllBMMenuItems, O: EmptyError },
    { name: "UpdateActiveMenuForIndependentBM", options: {}, I: IndependentBMMenuItem, O: EmptyError },
    { name: "UpdateGlobalAllActiveMenu", options: {}, I: AllBMMenuItems, O: EmptyError },
    { name: "GetGlobalActiveMenu", options: {}, I: Empty, O: AllBMMenuItems },
    { name: "GetIndependentActiveMenu", options: {}, I: GetBettingMachineDevicesRequest, O: AllBMMenuItems },
    { name: "DeleteBMMenuItem", options: {}, I: AllBMMenuItems, O: EmptyError },
    { name: "GetBaseActiveMenu", options: {}, I: GetBettingMachineDevicesRequest, O: AllBMMenuItems },
    { name: "UpdateHeaderItems", options: {}, I: AllBMMenuItems, O: EmptyError },
    { name: "UpdateHeaderItemsBP", options: {}, I: AllBMMenuItems, O: EmptyError },
    { name: "UpdateHeaderItemsBM", options: {}, I: AllBMMenuItems, O: EmptyError },
    { name: "GetHeaderItems", options: {}, I: GetBettingMachineDevicesRequest, O: AllBMMenuItems },
    { name: "GetBPHeaderItems", options: {}, I: GetBettingMachineDevicesRequest, O: AllBMMenuItems },
    { name: "GetBMHeaderItems", options: {}, I: GetBettingMachineDevicesRequest, O: AllBMMenuItems },
    { name: "ExecuteCommand", options: {}, I: BMCommandRequest, O: EmptyError },
    { name: "SetSecondaryBMWindow", options: {}, I: BMSecondaryWindow, O: EmptyError },
    { name: "GetSecondaryBMWindow", options: {}, I: BMSecondaryWindow, O: BMSecondaryWindow }
]);
