/* React modules */

/* Our modules */
import i18n from 'app/localization/i18n';
import { Slip, Error } from 'generated-proto/proto/common/common_pb';
import { FastSlip } from 'generated-proto/proto/slip/web_slip_pb';
import {
  SingleTicket,
  TicketStatuses,
  PlaceBetPayload,
  SlipPayloadGroup,
  ApprovalStatuses,
  TicketStatus,
  TicketStatusGroups,
  PreviewData,
  TicketOptions,
} from 'modules/ticket/ticket.types';
import { WalletTypes } from 'modules/wallet/wallet.types';
import { TICKET_FILTERS } from 'modules/ticket/ticket.constants';
import { RESULTS_MAP } from 'modules/sports/constants';
import { TicketAPI } from 'modules/ticket/ticket.api';
import { NotificationsAPI } from 'modules/notifications/notifications.api';
import ticketValidator from 'modules/ticket/store/ticket-validator';
import ticketBuilder from 'modules/ticket/store/ticket-builder';
import Odd from 'modules/sports/store/odd.store';
import userStore from 'modules/user/user.store';
import authStore from 'modules/auth/auth.store';
import sportsStore from 'modules/sports/store/sports.store';
import {
  parseResult,
  getEventCompetitors,
} from 'modules/sports/store/event.store';
import loaderStore from 'components/Loader/loader.store';
import { NODE_TYPE } from 'modules/sports/services/sport-node.service';
import { parseJSONString, isNumber, logger } from 'libs/common-helpers';

/* 3rd Party modules */
import { runInAction, makeAutoObservable } from 'mobx';
import i18next from 'i18next';
import { toast } from 'react-toastify';

const TICKETS_PAGE_SIZE = 35;

class TicketsStore {
  ticket: PreviewData | null = null;
  api: TicketAPI;
  notificationsApi: NotificationsAPI;
  quickCode: string | null = null;
  approvalTickets: any[] = [];
  cashoutAmount: any = null;
  shouldAcceptChanges: boolean = true;
  approvalTimeout: number = 0;
  timerActive: boolean = false;
  copyCount: number = 0;

  cashoutTimerActive: boolean = false;
  cashoutDenied: boolean = false;
  validTime: number = 0;
  ticketsRequestInProgress = false;
  quickSlipCodes: FastSlip[] = [];
  topWinningTickets: Slip[] = [];
  tickets: Slip[] = [];

  ticketsPagination = {
    pageSize: TICKETS_PAGE_SIZE,
    page: 1,
    hasNext: true,
  };

  taxThreshold = 0;

  constructor() {
    this.api = new TicketAPI();
    this.notificationsApi = new NotificationsAPI();

    makeAutoObservable(this);
  }

  /* Approval handlers - BEGIN */
  onApprovalStatusChange = async (data: any) => {
    if (data) {
      const { uuid } = data;

      const { isLoggedIn, token } = authStore;

      if (isLoggedIn && uuid) {
        await this.notificationsApi.confirmMessageReceived(uuid, token);
      }

      this.approvalTickets.push(data);
    }

    userStore.getUserData();
  };

  onCashoutApproved = async (data: any) => {
    this.cashoutDenied = false;

    if (data) {
      const { uuid, valid_time, cashout_amount } = data;

      const { isLoggedIn, token } = authStore;

      if (isLoggedIn && uuid) {
        await this.notificationsApi.confirmMessageReceived(uuid, token);
      }

      runInAction(() => {
        this.cashoutTimerActive = true;

        this.validTime = valid_time;
        this.cashoutAmount = cashout_amount;
      });
    }

    loaderStore.setActive(false);
  };

  onCashoutDenied = async (data: any) => {
    if (data) {
      const { uuid } = data;

      const { isLoggedIn, token } = authStore;

      if (isLoggedIn && uuid) {
        await this.notificationsApi.confirmMessageReceived(uuid, token);
      }
    }

    this.clearCashout();

    loaderStore.setActive(false);
  };
  /* Approval handlers - END */

  getApprovalHandlers() {
    return {
      [ApprovalStatuses.APPROVED]: this.onApprovalStatusChange,
      [ApprovalStatuses.DENIED]: this.onApprovalStatusChange,
      [ApprovalStatuses.CHANGED]: this.onApprovalStatusChange,
      [ApprovalStatuses.CASHOUT_APPROVED]: this.onCashoutApproved,
      [ApprovalStatuses.CASHOUT_DENIED]: this.onCashoutDenied,
    };
  }

  openApprovalSocket(token: string): void {
    this.api.listenForApprovalChanges(token, this.onApprovalStatusChange);
  }

  closeApprovalSocket(): void {
    this.api.closeSocket();
  }

  setShouldAcceptChanges = (val: boolean) => {
    this.shouldAcceptChanges = val;
  };

  get approvalTimer() {
    return new Date(this.approvalTimeout * 1000 - Date.now()).getSeconds();
  }

  get cashoutTimer() {
    return new Date(this.validTime * 1000 - Date.now()).getSeconds();
  }

  get manuallyApprovedTickets() {
    return this.approvalTickets.filter(
      (t: any) => t.type === ApprovalStatuses.APPROVED
    );
  }

  get manuallyDeniedTickets() {
    return this.approvalTickets.filter(
      (t: any) => t.type === ApprovalStatuses.DENIED
    );
  }

  get manuallyChangedTickets() {
    return this.approvalTickets.filter(
      (t: any) => t.type === ApprovalStatuses.CHANGED
    );
  }

  removeTicketFromApproval = (slipId: string) => {
    this.approvalTickets = this.approvalTickets.filter(
      (t) => t.slip_id !== slipId
    );
  };

  getTopWinningTickets = async () => {
    try {
      const {
        response: { slips },
      } = await this.api.getTopWinningTickets();

      runInAction(() => (this.topWinningTickets = slips || []));
    } catch (exception: any) {
      logger('TicketsStore -> getTopWinningTickets -> exception', exception);
    }
  };

  getSlipData = (groups: any): SlipPayloadGroup[] => {
    return (groups || []).map((group: any) => ({
      system:
        group.system.replaceAll(' ', '') ||
        `${group.events.length}/${group.events.length}`,
      events: (group.events || []).map((e: any) => ({
        event_id: e.id,
        odds: (e.selectedOdds || []).map((odd: Odd) => ({
          id: odd.id,
          odd_value: odd.value,
        })),
      })),
    }));
  };

  getSlipOdds = (groups: any): SlipPayloadGroup[] => {
    return (groups || []).map((group: any) => ({
      system:
        group.system.replaceAll(' ', '') ||
        `${group.events.length}/${group.events.length}`,
      events: (group.events || []).map((e: any) => ({
        event_id: e.id,
        odds: (e.selectedOdds || []).map((odd: Odd) => ({
          id: odd.value?.toString(),
        })),
      })),
    }));
  };

  clearApprovalTimer() {
    this.timerActive = false;
  }

  clearCashout() {
    this.validTime = 0;
    this.cashoutTimerActive = false;
    this.cashoutAmount = null;
  }

  onPlaceBetSuccess = async (slip: Slip) => {
    this.preview(slip, { fromApi: true });

    this.displayNotification(slip.status as TicketStatuses);

    await userStore.getUserData();

    if (!ticketBuilder.shouldSaveOdds) {
      ticketBuilder.removeAll();
    }
  };

  onPlaceBetError = (error: Error) => {
    toast.error(i18next.t('errors.place-bet-error'));

    if (error) {
      const { message } = error;

      if ((message || []).length) {
        ticketValidator.onServerError(message);
      }
    }
  };

  placeBet = async ({
    amount,
    systemGroups,
  }: {
    amount: number;
    systemGroups: any;
  }) => {
    if (!authStore.token) return;

    loaderStore.setActive(true);

    const payload: PlaceBetPayload = {
      approval_reason: null,
      user: null,
      amount,
      slip_groups: this.getSlipData(systemGroups),
      shouldAcceptChanges: this.shouldAcceptChanges,
    };

    if (ticketBuilder.wallet === WalletTypes.PROMO) {
      payload.wallet = 'free_bet';
    } else if (ticketBuilder.wallet === WalletTypes.PROMO_BET_BONUS) {
      payload.wallet_prefix = 'promo_bet';
    }

    const { response } = await this.api.placeBet(payload, authStore.token);

    loaderStore.setActive(false);

    if (response) {
      const { error } = response;

      if (error) {
        const { message } = error;

        if ((message || []).length) {
          if (message[0] === TicketStatus.USER_BLOCKED) {
            toast.error(i18n.t('errors.user-blocked-error'));
          } else {
            this.onPlaceBetError(error);
          }
        }
      } else {
        this.onPlaceBetSuccess(response);
      }
    }
  };

  placeBetLive = async ({
    amount,
    systemGroups,
  }: {
    amount: number;
    systemGroups: any;
  }) => {
    if (!authStore.token) return;

    loaderStore.setActive(true);

    const payload: PlaceBetPayload = {
      approval_reason: null,
      user: null,
      amount,
      slip_groups: this.getSlipData(systemGroups),
      shouldAcceptChanges: this.shouldAcceptChanges,
    };

    if (ticketBuilder.wallet === WalletTypes.PROMO) {
      payload.wallet = 'free_bet';
    } else if (ticketBuilder.wallet === WalletTypes.PROMO_BET_BONUS) {
      payload.wallet_prefix = 'promo_bet';
    }

    const { response } = await this.api.placeBet(
      payload,
      authStore.token,
      true
    );

    loaderStore.setActive(false);

    if (response) {
      const { error } = response;

      if (error) {
        const { message } = error;

        if ((message || []).length) {
          if (message[0] === TicketStatus.USER_BLOCKED) {
            toast.error(i18n.t('errors.user-blocked-error'));
          } else {
            this.onPlaceBetError(error);
          }
        }
      } else {
        this.onPlaceBetSuccess(response);
      }
    }
  };

  calculateSystemSlip = async ({
    amount,
    systemGroups,
  }: {
    amount: string;
    systemGroups: any;
  }) => {
    const payload: any = {
      approval_reason: null,
      user: null,
      amount,
      slip_groups: this.getSlipOdds(systemGroups),
    };

    const { response } = await this.api.calculateSlipValues(
      payload,
      authStore.token
    );

    return response;
  };

  getQuickCode = async ({
    amount,
    systemGroups,
  }: {
    amount: number;
    systemGroups: any;
  }) => {
    loaderStore.setActive(true);

    const payload: PlaceBetPayload = {
      approval_reason: null,
      user: null,
      amount,
      slip_groups: this.getSlipData(systemGroups),
    };

    try {
      const { response } = await this.api.getQuickCode(
        payload,
        authStore.token
      );

      if (response) {
        const { code, error } = response;

        this.quickCode = code || null;

        if (error) {
          const { message } = error;

          return (message || []).length ? message : [];
        }
      }
    } catch (exception: any) {
      this.onPlaceBetError(exception.data);

      logger('TicketsStore -> getQuickCode -> exception', exception);
    } finally {
      loaderStore.setActive(false);
    }

    return [];
  };

  getUserQuickCodes = async () => {
    if (!authStore.token) return;

    loaderStore.addLoader('tickets-table');

    try {
      const {
        response: { fastSlips },
      } = await this.api.getUserQuickCodes(authStore.token);

      runInAction(() => {
        this.quickSlipCodes = fastSlips || [];
      });
    } catch (exception: any) {
      logger('TicketsStore -> getUserQuickCodes -> exception', exception);
    } finally {
      loaderStore.removeLoader('tickets-table');
    }
  };

  resetCashoutAmount = () => {
    this.cashoutAmount = null;
  };

  acceptTicketChanges = async () => {
    if (!authStore.isLoggedIn) return;

    const { ticket } = this;

    if (!ticket || !ticket.slipId) return;

    this.clearApprovalTimer();

    try {
      const { slipId, shortUuid } = ticket;

      await this.api.acceptTicketChanges(slipId, authStore.token);

      toast.success('Tiket je uspešno uplaćen');

      if (shortUuid) {
        this.getTicket(shortUuid, { disableTimer: true });
      }
    } catch (exception: any) {
      toast.error(i18n.t('errors.system-error'));

      logger('TicketsStore -> acceptTicketChanges -> exception', exception);
    } finally {
      userStore.getUserData();
    }
  };

  denyTicketChanges = async (timeExceeded: boolean = false) => {
    const { isLoggedIn, token } = authStore;

    if (!isLoggedIn) return;

    const { ticket } = this;

    if (!ticket || !ticket.slipId) return;

    this.clearApprovalTimer();

    try {
      await this.api.denyTicketChanges(ticket.slipId, token);

      toast.success(
        timeExceeded
          ? i18n.t('verification.deny-changes-timer')
          : i18n.t('verification.deny-changes')
      );

      return true;
    } catch (exception: any) {
      toast.error(i18n.t('errors.system-error'));

      logger('TicketsStore -> denyTicketChanges -> exception', exception);
    } finally {
      userStore.getUserData();
    }
  };

  get canCopyTicket() {
    return this.copyCount === 0;
  }

  resetCopyCount = () => {
    this.copyCount = 0;
  };

  copyTicket = () => {
    this.copyCount += 1;

    ticketBuilder.removeAll();

    if (this.ticket) {
      const { systemGroups, isFix } = this.ticket;

      (systemGroups || []).forEach((group: any) => {
        (group.subGroupsList || []).forEach((event: any) => {
          const e = (sportsStore.allEvents || []).find(
            (e: any) => event.event.id === e.id
          );

          if (e) {
            (event.oddsList || []).forEach((odd: any) => {
              let selectedOdd = (e.odds || []).find(
                (o: any) => +o.id === +odd.id
              );

              if (selectedOdd) {
                selectedOdd = {
                  ...odd,
                  ...selectedOdd,
                  id: selectedOdd.id,
                  eventId: e.id,
                  displayValue: String(selectedOdd.value),
                  visible: true,
                  market: {
                    name: odd.marketName,
                    getName: () => odd.marketName,
                  },
                };

                ticketBuilder.onOddSelected({ ...e, odd: { ...selectedOdd } });
              }
            });
          }
        });
      });

      if (!isFix) this.groupCopiedSystemTicket();
    }

    if (ticketBuilder.eventsCount < 1) {
      toast.error(i18n.t('errors.no-events-for-copy'));

      this.resetCopyCount();
    }
  };

  groupCopiedSystemTicket = () => {
    ticketBuilder.setSystemTicket(true);

    if (this.ticket) {
      const { systemGroups } = this.ticket;

      (systemGroups || []).forEach((group: any, index) => {
        const systemTypes = group.type
          .split('/')[0]
          .split('+')
          .map((item: string) => +item);

        ticketBuilder.system.setSystem(
          systemTypes,
          ticketBuilder.system.groups[index]
        );

        (group.subGroupsList || []).forEach((subGroup: any, i: number) => {
          if (index > 0) {
            ticketBuilder.system.move(
              subGroup.event.id,
              ticketBuilder.system.groups[index]
            );
          }
        });
      });
    }
  };

  resetTickets = () => {
    runInAction(() => {
      this.ticketsPagination.hasNext = true;
      this.ticketsPagination.page = 0;
      this.tickets = [];
    });
  };

  setTicketsPage = (page: number) => {
    this.ticketsPagination.page = page;
  };

  getTickets = async (
    filterGroup: TicketStatusGroups,
    dateFilter?: [Date, Date],
    id?: string
  ) => {
    this.ticketsRequestInProgress = true;

    loaderStore.addLoader('tickets-table');

    let status: TicketStatus[] = TICKET_FILTERS[filterGroup];

    try {
      const {
        response: { slips },
      } = await this.api.getTickets(
        this.ticketsPagination,
        status,
        authStore.token,
        dateFilter,
        id
      );

      runInAction(() => {
        this.ticketsPagination.hasNext =
          (slips || []).length === this.ticketsPagination.pageSize;

        this.tickets.push(...(slips || []));
      });
    } catch (exception: any) {
      logger('TicketsStore -> getTickets -> exception', exception);
    } finally {
      loaderStore.removeLoader('tickets-table');

      this.ticketsRequestInProgress = false;
    }
  };

  getTicket = async (
    id: string,
    options?: { isNew?: boolean; disableTimer: boolean }
  ) => {
    const { isLoggedIn, token } = authStore;

    if (!isLoggedIn) return;

    try {
      if (id) {
        const { response } = await this.api.getTicket(id, token);

        if (response) {
          const { status, manualChangeTime } = response;

          if (
            !options?.disableTimer &&
            manualChangeTime?.seconds &&
            status !== TicketStatus.DENIED
          ) {
            this.approvalTimeout = manualChangeTime?.seconds;
            this.timerActive = true;
          }

          if (status === TicketStatus.DENIED) {
            this.clearApprovalTimer();
          }

          if (options && options.isNew) {
            this.displayNotification(status as TicketStatuses);
          }

          this.preview(response, { fromApi: true });
        }
      }

      return this.ticket;
    } catch (exception: any) {
      toast.error(i18n.t('errors.ticket-error-reading'));

      logger('TicketsStore -> getTicket -> exception', exception);
    }
  };

  getSharedTicket = async (id: string) => {
    try {
      const { response } = await this.api.getSharedTicket(id);

      if (response) {
        this.preview(response, { fromApi: true });
      }

      return this.ticket;
    } catch (exception: any) {
      toast.error(i18n.t('errors.ticket-error-reading'));

      logger('TicketsStore -> getSharedTicket -> exception', exception);
    }
  };

  getQuickTicket = async (code: string, options?: { isNew: boolean }) => {
    const { isLoggedIn, token } = authStore;

    if (!isLoggedIn) return;

    try {
      const { response } = await this.api.getQuickTicket(code, token);

      if (response) {
        const { status } = response;

        if (options && options.isNew) {
          this.displayNotification(status as TicketStatuses);
        }

        this.preview(response, { fromApi: true });
      }

      return this.ticket;
    } catch (exception: any) {
      toast.error(i18n.t('errors.ticket-error-reading'));

      logger('TicketsStore -> getQuickTicket -> exception', exception);
    }
  };

  displayNotification = (status: TicketStatuses) => {
    switch (status) {
      case TicketStatus.APPROVING:
        toast.info(i18n.t('verification.ticket-waiting-approval'));
        break;
      case TicketStatus.NOT_RESOLVED:
        toast.success(i18n.t('verification.ticket-paid'));
        break;
      default:
        break;
    }
  };

  preview = (ticket: any, options?: TicketOptions) => {
    if (ticket) {
      this.ticket = (options || {}).fromApi ? this.normalize(ticket) : ticket;
    }
  };

  sendSlipForCashout = async () => {
    const { isLoggedIn, token } = authStore;

    if (!isLoggedIn || !this.ticket?.slipId) return;

    loaderStore.setActive(true);

    const { response } = await this.api.sendSlipForCashout(
      this.ticket.slipId,
      token
    );

    if (response) {
      const { error } = response;

      if (error) {
        toast.error(i18n.t('slips.slip-not-eligible-for-cashout'));
      }
    }

    loaderStore.setActive(false);
  };

  acceptCashout = async () => {
    this.clearCashout();

    const { isLoggedIn, token } = authStore;

    if (!isLoggedIn || !(this.ticket && this.ticket.slipId)) return;

    loaderStore.setActive(true);

    await this.api.acceptCashout(this.ticket.slipId, token);

    loaderStore.setActive(false);

    toast.success(i18n.t('slips.cashout-accepted'));

    this.getTicket(this.ticket.shortUuid || '', { disableTimer: true });
  };

  denyCashout = async (deniedManually: boolean = false) => {
    this.cashoutDenied = true;

    this.clearCashout();

    if (!authStore.token || !this.ticket?.slipId) return;

    loaderStore.setActive(true);

    await this.api.denyCashout(this.ticket.slipId, authStore.token);

    loaderStore.setActive(false);

    toast.info(
      i18n.t(
        deniedManually ? 'slips.cashout-denied' : 'slips.cashout-denied-timer'
      )
    );
  };

  cancelCashout = async () => {
    this.clearCashout();

    this.cashoutDenied = true;

    loaderStore.setActive(false);

    if (!authStore.token || !this.ticket?.slipId) return;

    await this.api.cancelCashout(this.ticket.slipId, authStore.token);
  };

  normalize = (ticket: SingleTicket) => {
    const {
      id,
      status,
      bettingAmount,
      actualWinningAmount,
      maxWinningAmount,
      maxWinningTotal,
      minWinningAmount,
      minWinningTotal,
      maxBonusAmount,
      minBonusAmount,
      numberOfMatches,
      maxOddsValue,
      minOddsValue,
      slipGroups,
      bettingTime,
      actualWinningNoBonus,
      isSystem,
      shortUuid,
      oldBettingAmount,
      hasCashout,
      cashout,
      bonusPercent,
      slipType,
    } = ticket;

    return {
      slipId: id,
      shortUuid,
      bonus: maxBonusAmount || 0,
      minBonus: minBonusAmount || 0,
      winningAmount: maxWinningTotal,
      potentialWinningAmount: maxWinningAmount,
      minWinningTotal,
      minWinningAmount,
      actualWinningAmount,
      actualWinningNoBonus,
      totalOdd: maxOddsValue,
      minOdd: minOddsValue,
      amount: bettingAmount,
      cashout,
      bonusPercent,
      slipType,
      hasCashout,
      oldAmount: oldBettingAmount,
      eventsCount: numberOfMatches,
      systemGroups: (slipGroups || []).map((group: any) => ({
        ...group,
        system: group.type,
        events: (group.subGroups || []).map(({ event, odds, status }: any) => {
          const sport = sportsStore.sportsData?.findSportById(
            event?.sportId || -1
          );

          const competition = sport?.findNode(
            NODE_TYPE.COMPETITION,
            event?.competitionId || -1
          );

          const location = sport?.findNode(
            NODE_TYPE.LOCATION,
            event?.locationId || -1
          );

          const eventFromStream = competition?.children.find((e: any) => {
            return e.id === event?.id;
          });

          const [home, away] = event ? getEventCompetitors(event) : ['', ''];

          return {
            ...event,
            sportId: event?.sportId,
            locationId: event?.locationId,
            locationName: location?.name,
            competitionId: event?.competitionId,
            competitionName: competition?.name,
            code: event?.landbaseCode,
            sportName: sport?.name,
            name: event?.name,
            status,
            odd: {
              ...odds[0],
              outcome: odds[0].outcome,
              outcomeName: odds[0].outcomeName,
              market: {
                id: odds[0].marketId,
                name: odds[0].marketName,
                englishName: odds[0].englishName,
                albanianName: odds[0].albanianName,
                turkishName: odds[0].turkishName,
                russianName: odds[0].russianName,
                ukrainianName: odds[0].ukrainianName,
                italianName: odds[0].italianName,
                germanName: odds[0].germanName,
                getName: () => odds[0].marketName,
              },
            },
            selectedOdds: (odds || []).map((odd: any) => ({
              oddId: odd.id,
              status: odd.status,
              limit: odd.limit,
              oldLimit: odd.oldLimit,
              oldValue: odd.oldValue.toFixed(2),
              displayValue: odd.value.toFixed(2),
              outcome: { name: odd.outcomeName, id: odd.outcomeId },
              outcomeDisplay:
                odd.outcomeTic || `${odd.outcomeGroup} ${odd.outcomeName}`,
              market: { name: odd.marketName, getName: () => odd.marketName },
              livePlayer: odd.livePlayer ? odd.livePlayer : '',
              event: eventFromStream || {
                home,
                away,
                currentResult: this.formatShortResult(
                  event?.result,
                  sport?.name || '',
                  status
                ),
                periodsShort: this.formatShortPeriods(
                  event?.result,
                  sport?.name || ''
                ),
              },
            })),

            start: event?.startTime,
          };
        }),
      })),
      isFix: !isSystem,
      status,
      created_at: bettingTime,
    };
  };

  formatShortResult(result: any, sportName: string, status: any) {
    const resultMap = RESULTS_MAP[sportName];

    if (!result || !resultMap) return [];

    const { currentResult } = result;

    const { currentScoringTypes, finalScoringTypes, replacingScoreValues } =
      resultMap;

    const scoringTypes =
      status !== '__NOT_RESOLVED__' && !!finalScoringTypes
        ? finalScoringTypes
        : currentScoringTypes;

    return (scoringTypes || []).map((scoringType: any) => {
      const { key, name } = scoringType;

      const result =
        currentResult && key
          ? currentResult[key] || currentResult[key.toLowerCase()]
          : null;

      const parsed = parseResult(result, replacingScoreValues);

      return {
        name,
        homeScore: parsed[0],
        awayScore: parsed[1],
      };
    });
  }

  formatShortPeriods(result: any, sportName: string) {
    const resultMap = RESULTS_MAP[sportName];

    if (!result || !resultMap) return [];

    const { liveResultStats } = result;

    const { replacingScoreValues, shortPeriodKeys, periodScoringKey } =
      resultMap;

    return (shortPeriodKeys || [])
      .map((key: string) => {
        const parsedData = liveResultStats
          ? parseJSONString(liveResultStats)
          : null;

        const periodResult = parsedData && key ? parsedData[key] : null;

        if (!periodResult) return null;

        const result = periodScoringKey
          ? periodResult[periodScoringKey] ||
            periodResult[periodScoringKey.toLowerCase()]
          : null;

        const parsed = parseResult(result, replacingScoreValues);

        return {
          name: key,
          homeScore: parsed[0],
          awayScore: parsed[1],
        };
      })
      .filter((r: any) => !!r);
  }

  getTaxThresholdConfiguration = async () => {
    try {
      const { response } = await this.api.getTaxThresholdConfiguration(
        authStore.token
      );

      if (response) {
        const { taxThreshold } = response;

        if (isNumber(taxThreshold)) {
          this.taxThreshold = taxThreshold;
        }
      }
    } catch (exception: any) {
      logger(
        'tickets-store -> getTaxThresholdConfiguration -> exception',
        exception
      );
    }
  };
}

export const ticketsStore = new TicketsStore();
