// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/odds/odds.proto" (package "odds", syntax proto3)
// tslint:disable
import { EmptyError } from "../common/common_pb";
import { Empty } from "../../google/protobuf/empty_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { SortBy } from "../common/common_pb";
import { EventCurrentPhase } from "../common/common_pb";
import { EventResult } from "../common/common_pb";
import { FastSlipGroup } from "../common/common_pb";
import { SlipGroup } from "../common/common_pb";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
import { Error } from "../common/common_pb";
/**
 * get live lb codes
 *
 * @generated from protobuf message odds.GetActiveLBCodesRequest
 */
export interface GetActiveLBCodesRequest {
    /**
     * @generated from protobuf field: int32 sport_id = 1;
     */
    sportId: number;
}
/**
 * @generated from protobuf message odds.GetActiveLBCodesResponse
 */
export interface GetActiveLBCodesResponse {
    /**
     * @generated from protobuf field: repeated string lbcodes = 1;
     */
    lbcodes: string[];
}
/**
 * @generated from protobuf message odds.AssignLandBaseCodeRequest
 */
export interface AssignLandBaseCodeRequest {
    /**
     * @generated from protobuf field: int32 provider_id = 1;
     */
    providerId: number;
    /**
     * @generated from protobuf field: int32 sport_id = 2;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated int32 competition_ids = 6;
     */
    competitionIds: number[];
    /**
     * @generated from protobuf field: int32 competition_id = 3;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: string event_id = 4;
     */
    eventId: string;
    /**
     * @generated from protobuf field: odds.Filter filter = 5;
     */
    filter?: Filter;
}
/**
 * @generated from protobuf message odds.AssignLandBaseCodeResponse
 */
export interface AssignLandBaseCodeResponse {
    /**
     * @generated from protobuf field: repeated odds.Event events = 1;
     */
    events: Event[];
    /**
     * @generated from protobuf field: common.Error error = 2;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.LandBaseCounters
 */
export interface LandBaseCounters {
    /**
     * @generated from protobuf field: repeated odds.LandBaseCounter counters = 1;
     */
    counters: LandBaseCounter[];
}
/**
 * @generated from protobuf message odds.LandBaseCounter
 */
export interface LandBaseCounter {
    /**
     * @generated from protobuf field: int32 sport_id = 1;
     */
    sportId: number;
    /**
     * @generated from protobuf field: int32 min_value = 2;
     */
    minValue: number;
    /**
     * @generated from protobuf field: int32 max_value = 3;
     */
    maxValue: number;
    /**
     * @generated from protobuf field: int32 next_value = 4;
     */
    nextValue: number;
    /**
     * @generated from protobuf field: common.Error error = 5;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.DuplicatesRequest
 */
export interface DuplicatesRequest {
    /**
     * @generated from protobuf field: int32 provider_id = 1;
     */
    providerId: number;
}
/**
 * @generated from protobuf message odds.DuplicatesResponse
 */
export interface DuplicatesResponse {
    /**
     * @generated from protobuf field: map<string, odds.EventList> landbase_code_map = 1;
     */
    landbaseCodeMap: {
        [key: string]: EventList;
    };
    /**
     * @generated from protobuf field: common.Error error = 5;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.EventList
 */
export interface EventList {
    /**
     * @generated from protobuf field: repeated odds.Event events = 1;
     */
    events: Event[];
}
/**
 * @generated from protobuf message odds.GetEventsCreateOfferResponse
 */
export interface GetEventsCreateOfferResponse {
    /**
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 total_pages = 2;
     */
    totalPages: number;
    /**
     * @generated from protobuf field: map<int32, odds.Competition> competitions = 3;
     */
    competitions: {
        [key: number]: Competition;
    };
}
/**
 * @generated from protobuf message odds.GetMinLimitRequest
 */
export interface GetMinLimitRequest {
    /**
     * @generated from protobuf field: map<string, odds.EventOutcome> odds_map = 1;
     */
    oddsMap: {
        [key: string]: EventOutcome;
    };
}
/**
 * @generated from protobuf message odds.EventOutcome
 */
export interface EventOutcome {
    /**
     * @generated from protobuf field: string event_id = 1;
     */
    eventId: string;
    /**
     * @generated from protobuf field: int32 outcome_id = 2;
     */
    outcomeId: number;
}
/**
 * @generated from protobuf message odds.GetMinLimitResponse
 */
export interface GetMinLimitResponse {
    /**
     * @generated from protobuf field: map<string, double> limits_map = 1;
     */
    limitsMap: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message odds.UpdateEventResultRequest
 */
export interface UpdateEventResultRequest {
    /**
     * @generated from protobuf field: string event_id = 1;
     */
    eventId: string;
    /**
     * @generated from protobuf field: string result = 2;
     */
    result: string;
    /**
     * @generated from protobuf field: bool resolve = 3;
     */
    resolve: boolean;
}
/**
 * @generated from protobuf message odds.TimeCancelRequest
 */
export interface TimeCancelRequest {
    /**
     * @generated from protobuf field: string event_id = 1;
     */
    eventId: string;
    /**
     * @generated from protobuf field: int32 timestamp = 2;
     */
    timestamp: number;
}
/**
 * @generated from protobuf message odds.CompetitionsForLinkingRequest
 */
export interface CompetitionsForLinkingRequest {
    /**
     * @generated from protobuf field: int32 sport_id = 1;
     */
    sportId: number;
    /**
     * @generated from protobuf field: int32 location_id = 2;
     */
    locationId: number;
    /**
     * @generated from protobuf field: string search_string = 3;
     */
    searchString: string;
    /**
     * @generated from protobuf field: string link_filter = 4;
     */
    linkFilter: string;
    /**
     * @generated from protobuf field: int32 page = 5;
     */
    page: number;
}
/**
 * @generated from protobuf message odds.CompetitionsForLinkingResponse
 */
export interface CompetitionsForLinkingResponse {
    /**
     * @generated from protobuf field: repeated odds.CompetitionForLinking competitions = 1;
     */
    competitions: CompetitionForLinking[];
    /**
     * @generated from protobuf field: int32 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 page_count = 3;
     */
    pageCount: number;
}
/**
 * @generated from protobuf message odds.CompetitionForLinking
 */
export interface CompetitionForLinking {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string location = 3;
     */
    location: string;
    /**
     * @generated from protobuf field: repeated odds.ProviderCompetition live_competition = 4;
     */
    liveCompetition: ProviderCompetition[];
    /**
     * @generated from protobuf field: repeated odds.ProviderCompetition prematch_competition = 5;
     */
    prematchCompetition: ProviderCompetition[];
    /**
     * @generated from protobuf field: string short_name = 6;
     */
    shortName: string;
    /**
     * @generated from protobuf field: int32 location_id = 7;
     */
    locationId: number;
    /**
     * @generated from protobuf field: string standings_url = 8;
     */
    standingsUrl: string;
}
/**
 * @generated from protobuf message odds.ProviderCompetition
 */
export interface ProviderCompetition {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string location = 46;
     */
    location: string;
    /**
     * @generated from protobuf field: int32 factory_competition_id = 129;
     */
    factoryCompetitionId: number;
    /**
     * @generated from protobuf field: string factory_competition_name = 130;
     */
    factoryCompetitionName: string;
}
/**
 * @generated from protobuf message odds.PASport
 */
export interface PASport {
    /**
     * @generated from protobuf field: int32 competition_id = 1;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: int32 sport_id = 2;
     */
    sportId: number;
    /**
     * @generated from protobuf field: string type = 3;
     */
    type: string;
}
/**
 * @generated from protobuf message odds.BlockEventReasons
 */
export interface BlockEventReasons {
    /**
     * @generated from protobuf field: repeated string reasons = 1;
     */
    reasons: string[];
}
/**
 * @generated from protobuf message odds.BlockEventRequest
 */
export interface BlockEventRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string reason = 2;
     */
    reason: string;
}
/**
 * @generated from protobuf message odds.GetEventsForLinkingRequest
 */
export interface GetEventsForLinkingRequest {
    /**
     * @generated from protobuf field: string search_string = 1;
     */
    searchString: string;
    /**
     * @generated from protobuf field: int32 sport_id = 2;
     */
    sportId: number;
}
/**
 * @generated from protobuf message odds.UpdateLandBaseCodes
 */
export interface UpdateLandBaseCodes {
    /**
     * @generated from protobuf field: string event_id = 12;
     */
    eventId: string;
    /**
     * @generated from protobuf field: int32 competition_id = 4;
     */
    competitionId: number;
}
/**
 * @generated from protobuf message odds.IsonisOutcomeArgs
 */
export interface IsonisOutcomeArgs {
    /**
     * @generated from protobuf field: repeated odds.IsonisOutArg outcome_args = 1;
     */
    outcomeArgs: IsonisOutArg[];
}
/**
 * @generated from protobuf message odds.IsonisOutArg
 */
export interface IsonisOutArg {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: repeated string isonis_results_prematch_args = 2;
     */
    isonisResultsPrematchArgs: string[];
    /**
     * @generated from protobuf field: repeated string isonis_results_live_args = 3;
     */
    isonisResultsLiveArgs: string[];
    /**
     * @generated from protobuf field: string isonis_name = 4;
     */
    isonisName: string;
}
/**
 * @generated from protobuf message odds.LandBaseCode
 */
export interface LandBaseCode {
    /**
     * @generated from protobuf field: string landbase_code = 80;
     */
    landbaseCode: string;
}
/**
 * @generated from protobuf message odds.CancelledEventsRequest
 */
export interface CancelledEventsRequest {
    /**
     * @generated from protobuf field: int32 page = 161;
     */
    page: number;
    /**
     * @generated from protobuf field: string search_string = 157;
     */
    searchString: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp date_from = 159;
     */
    dateFrom?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp date_to = 160;
     */
    dateTo?: Timestamp;
}
/**
 * @generated from protobuf message odds.CancelledEventsResponse
 */
export interface CancelledEventsResponse {
    /**
     * @generated from protobuf field: repeated odds.Event events = 17;
     */
    events: Event[];
    /**
     * @generated from protobuf field: int32 page = 161;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 total_pages = 162;
     */
    totalPages: number;
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.GetDailyListRequest
 */
export interface GetDailyListRequest {
    /**
     * @generated from protobuf field: repeated int32 sport_ids = 182;
     */
    sportIds: number[];
    /**
     * @generated from protobuf field: bool full_offer = 1;
     */
    fullOffer: boolean;
}
/**
 * @generated from protobuf message odds.GetDailyListResponse
 */
export interface GetDailyListResponse {
    /**
     * @generated from protobuf field: repeated odds.DailySport sports = 269;
     */
    sports: DailySport[];
}
/**
 * @generated from protobuf message odds.DailySport
 */
export interface DailySport {
    /**
     * @generated from protobuf field: int32 id = 5;
     */
    id: number;
    /**
     * @generated from protobuf field: repeated odds.DailyEvent events = 270;
     */
    events: DailyEvent[];
}
/**
 * @generated from protobuf message odds.DailyEvent
 */
export interface DailyEvent {
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string competition_name = 127;
     */
    competitionName: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 22;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: string landbase_code = 80;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: repeated odds.DailyMarkets markets = 271;
     */
    markets: DailyMarkets[];
}
/**
 * @generated from protobuf message odds.DailyMarkets
 */
export interface DailyMarkets {
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated odds.DailyOutcomes outcomes = 272;
     */
    outcomes: DailyOutcomes[];
}
/**
 * @generated from protobuf message odds.DailyOutcomes
 */
export interface DailyOutcomes {
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: double value = 8;
     */
    value: number;
}
/**
 * @generated from protobuf message odds.LiveOutcomeOrderResponse
 */
export interface LiveOutcomeOrderResponse {
    /**
     * @generated from protobuf field: repeated odds.LiveOrderSport sports = 1;
     */
    sports: LiveOrderSport[];
}
/**
 * @generated from protobuf message odds.LiveOrderSport
 */
export interface LiveOrderSport {
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated odds.LiveMarket live_markets = 2;
     */
    liveMarkets: LiveMarket[];
}
/**
 * @generated from protobuf message odds.LiveMarket
 */
export interface LiveMarket {
    /**
     * @generated from protobuf field: int32 id = 5;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated int32 outcome_ids = 4;
     */
    outcomeIds: number[];
}
/**
 * @generated from protobuf message odds.SyncLandBaseRequest
 */
export interface SyncLandBaseRequest {
    /**
     * @generated from protobuf field: repeated string live_ids = 241;
     */
    liveIds: string[];
    /**
     * @generated from protobuf field: repeated string prematch_ids = 242;
     */
    prematchIds: string[];
    /**
     * @generated from protobuf field: repeated string outright_ids = 250;
     */
    outrightIds: string[];
    /**
     * @generated from protobuf field: repeated string player_ids = 251;
     */
    playerIds: string[];
    /**
     * @generated from protobuf field: repeated string group_ids = 252;
     */
    groupIds: string[];
}
/**
 * @generated from protobuf message odds.SyncLandBaseResponse
 */
export interface SyncLandBaseResponse {
    /**
     * @generated from protobuf field: repeated string deleted_prematch = 243;
     */
    deletedPrematch: string[];
    /**
     * @generated from protobuf field: repeated odds.StreamEvent created_prematch = 244;
     */
    createdPrematch: StreamEvent[];
    /**
     * @generated from protobuf field: repeated string deleted_live = 245;
     */
    deletedLive: string[];
    /**
     * @generated from protobuf field: repeated odds.StreamEvent created_live = 246;
     */
    createdLive: StreamEvent[];
    /**
     * @generated from protobuf field: repeated string deleted_outright = 253;
     */
    deletedOutright: string[];
    /**
     * @generated from protobuf field: repeated odds.StreamEvent created_outright = 254;
     */
    createdOutright: StreamEvent[];
    /**
     * @generated from protobuf field: repeated string deleted_player = 255;
     */
    deletedPlayer: string[];
    /**
     * @generated from protobuf field: repeated odds.StreamEvent created_player = 256;
     */
    createdPlayer: StreamEvent[];
    /**
     * @generated from protobuf field: repeated string deleted_group = 257;
     */
    deletedGroup: string[];
    /**
     * @generated from protobuf field: repeated odds.StreamEvent created_group = 258;
     */
    createdGroup: StreamEvent[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.GetMarketTemplatesRequest
 */
export interface GetMarketTemplatesRequest {
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
}
/**
 * @generated from protobuf message odds.LiveDisableStatus
 */
export interface LiveDisableStatus {
    /**
     * @generated from protobuf field: bool disable_all = 193;
     */
    disableAll: boolean;
    /**
     * @generated from protobuf field: repeated int32 disabled_sports = 194;
     */
    disabledSports: number[];
}
/**
 * @generated from protobuf message odds.UpdateMissingSlipResultsRequest
 */
export interface UpdateMissingSlipResultsRequest {
    /**
     * @generated from protobuf field: repeated string event_ids = 62;
     */
    eventIds: string[];
}
/**
 * @generated from protobuf message odds.GetCompetitorsRequest
 */
export interface GetCompetitorsRequest {
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: int32 competition_id = 4;
     */
    competitionId: number;
}
/**
 * @generated from protobuf message odds.GetLocationsBoResponse
 */
export interface GetLocationsBoResponse {
    /**
     * @generated from protobuf field: repeated odds.Location locations = 23;
     */
    locations: Location[];
}
/**
 * @generated from protobuf message odds.GetCompetitionsBoResponse
 */
export interface GetCompetitionsBoResponse {
    /**
     * @generated from protobuf field: repeated odds.Competition competitions = 25;
     */
    competitions: Competition[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.LocationOrderRequest
 */
export interface LocationOrderRequest {
    /**
     * @generated from protobuf field: repeated int32 ids = 1;
     */
    ids: number[];
}
/**
 * @generated from protobuf message odds.SportLocationRequest
 */
export interface SportLocationRequest {
    /**
     * @generated from protobuf field: int32 location = 1;
     */
    location: number;
    /**
     * @generated from protobuf field: int32 sport = 2;
     */
    sport: number;
}
/**
 * @generated from protobuf message odds.UpdateMarketGroupsBoRequest
 */
export interface UpdateMarketGroupsBoRequest {
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated odds.MarketGroupConfig market_group_configs = 51;
     */
    marketGroupConfigs: MarketGroupConfig[];
}
/**
 * @generated from protobuf message odds.GetCompetitorsBoResponse
 */
export interface GetCompetitorsBoResponse {
    /**
     * @generated from protobuf field: repeated odds.Competitor competitors = 44;
     */
    competitors: Competitor[];
}
/**
 * @generated from protobuf message odds.GetSportsBoReponse
 */
export interface GetSportsBoReponse {
    /**
     * @generated from protobuf field: repeated odds.Sport sports = 13;
     */
    sports: Sport[];
}
/**
 * @generated from protobuf message odds.GetAllMarketGroupsBoRequest
 */
export interface GetAllMarketGroupsBoRequest {
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
}
/**
 * @generated from protobuf message odds.GetAllMarketGroupsBoResponse
 */
export interface GetAllMarketGroupsBoResponse {
    /**
     * @generated from protobuf field: repeated odds.MarketGroupConfig market_group_configs = 51;
     */
    marketGroupConfigs: MarketGroupConfig[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.MarketGroupConfig
 */
export interface MarketGroupConfig {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated int32 market_ids = 52;
     */
    marketIds: number[];
}
/**
 * @generated from protobuf message odds.GetAllMarketsBoRequest
 */
export interface GetAllMarketsBoRequest {
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
}
/**
 * @generated from protobuf message odds.GetAllMarketsBoResponse
 */
export interface GetAllMarketsBoResponse {
    /**
     * @generated from protobuf field: repeated odds.MarketConfig market_configs = 53;
     */
    marketConfigs: MarketConfig[];
}
/**
 * @generated from protobuf message odds.MarketConfig
 */
export interface MarketConfig {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message odds.Competitor
 */
export interface Competitor {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: repeated odds.Competition competitions = 25;
     */
    competitions: Competition[];
    /**
     * @generated from protobuf field: string location_name = 108;
     */
    locationName: string;
    /**
     * @generated from protobuf field: string sport_name = 107;
     */
    sportName: string;
    /**
     * @generated from protobuf field: string short_name = 48;
     */
    shortName: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp last_updated = 50;
     */
    lastUpdated?: Timestamp;
    /**
     * @generated from protobuf field: repeated odds.Link links = 98;
     */
    links: Link[];
    /**
     * @generated from protobuf field: repeated odds.Player players = 138;
     */
    players: Player[];
}
/**
 * @generated from protobuf message odds.Link
 */
export interface Link {
    /**
     * @generated from protobuf field: int32 provider_id = 33;
     */
    providerId: number;
    /**
     * @generated from protobuf field: string provider_name = 99;
     */
    providerName: string;
    /**
     * @generated from protobuf field: int32 provider_competitor_id = 82;
     */
    providerCompetitorId: number;
    /**
     * @generated from protobuf field: string provider_competitor_name = 100;
     */
    providerCompetitorName: string;
    /**
     * @generated from protobuf field: int32 provider_player_id = 101;
     */
    providerPlayerId: number;
    /**
     * @generated from protobuf field: string provider_player_name = 105;
     */
    providerPlayerName: string;
    /**
     * @generated from protobuf field: int32 provider_outcome_id = 120;
     */
    providerOutcomeId: number;
    /**
     * @generated from protobuf field: string provider_outcome_name = 121;
     */
    providerOutcomeName: string;
    /**
     * @generated from protobuf field: int32 provider_competition_id = 126;
     */
    providerCompetitionId: number;
    /**
     * @generated from protobuf field: string provider_competition_name = 127;
     */
    providerCompetitionName: string;
}
/**
 * @generated from protobuf message odds.GetForProviderRequest
 */
export interface GetForProviderRequest {
    /**
     * @generated from protobuf field: int32 provider_id = 33;
     */
    providerId: number;
}
/**
 * @generated from protobuf message odds.GetInitialDataRequest
 */
export interface GetInitialDataRequest {
    /**
     * @generated from protobuf field: bool only_published = 42;
     */
    onlyPublished: boolean;
}
/**
 * @generated from protobuf message odds.GetOddsRequest
 */
export interface GetOddsRequest {
    /**
     * @generated from protobuf field: repeated common.SlipGroup slip_groups = 30;
     */
    slipGroups: SlipGroup[];
}
/**
 * @generated from protobuf message odds.GetFastOddsRequest
 */
export interface GetFastOddsRequest {
    /**
     * @generated from protobuf field: repeated common.FastSlipGroup slip_groups = 30;
     */
    slipGroups: FastSlipGroup[];
}
/**
 * @generated from protobuf message odds.ToggleFavoriteRequest
 */
export interface ToggleFavoriteRequest {
    /**
     * @generated from protobuf field: repeated int32 competition_ids = 41;
     */
    competitionIds: number[];
}
/**
 * @generated from protobuf message odds.PublishEventEvent
 */
export interface PublishEventEvent {
    /**
     * @generated from protobuf field: string event_id = 12;
     */
    eventId: string;
    /**
     * @generated from protobuf field: int32 live_provider_id = 111;
     */
    liveProviderId: number;
    /**
     * @generated from protobuf field: int32 prematch_provider_id = 112;
     */
    prematchProviderId: number;
}
/**
 * @generated from protobuf message odds.PublishEventsBoRequest
 */
export interface PublishEventsBoRequest {
    /**
     * @generated from protobuf field: repeated odds.PublishEventEvent events = 39;
     */
    events: PublishEventEvent[];
    /**
     * @generated from protobuf field: string type = 93;
     */
    type: string;
}
/**
 * @generated from protobuf message odds.PublishEventsBoResponse
 */
export interface PublishEventsBoResponse {
    /**
     * @generated from protobuf field: repeated odds.PublishEventEvent events = 39;
     */
    events: PublishEventEvent[];
    /**
     * @generated from protobuf field: int32 number_of_published = 220;
     */
    numberOfPublished: number;
}
/**
 * @generated from protobuf message odds.Event
 */
export interface Event {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string alias = 3;
     */
    alias: string;
    /**
     * @generated from protobuf field: int32 competition_id = 4;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: string competition_name = 154;
     */
    competitionName: string;
    /**
     * @generated from protobuf field: int32 location_id = 109;
     */
    locationId: number;
    /**
     * @generated from protobuf field: string location_name = 108;
     */
    locationName: string;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated odds.EventOdd odds = 181;
     */
    odds: EventOdd[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 22;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp end_betting_time = 140;
     */
    endBettingTime?: Timestamp;
    /**
     * @generated from protobuf field: bool is_published = 43;
     */
    isPublished: boolean;
    /**
     * @generated from protobuf field: bool is_disabled = 136;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: bool manual_disable = 192;
     */
    manualDisable: boolean;
    /**
     * @generated from protobuf field: string manual_disable_reason = 330;
     */
    manualDisableReason: string;
    /**
     * @generated from protobuf field: string om_name = 331;
     */
    omName: string;
    /**
     * @generated from protobuf field: string exe_name = 332;
     */
    exeName: string;
    /**
     * @generated from protobuf field: bool is_suspicious = 176;
     */
    isSuspicious: boolean;
    /**
     * @generated from protobuf field: bool is_single_approval = 178;
     */
    isSingleApproval: boolean;
    /**
     * @generated from protobuf field: odds.SuspiciousLevel suspicious_level = 174;
     */
    suspiciousLevel?: SuspiciousLevel;
    /**
     * @generated from protobuf field: string landbase_code = 80;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: repeated string landbase_codes = 237;
     */
    landbaseCodes: string[];
    /**
     * @generated from protobuf field: string new_landbase_code = 226;
     */
    newLandbaseCode: string;
    /**
     * @generated from protobuf field: string type = 93;
     */
    type: string;
    /**
     * @generated from protobuf field: repeated odds.Provider providers = 38;
     */
    providers: Provider[];
    /**
     * @generated from protobuf field: int32 live_provider_id = 111;
     */
    liveProviderId: number;
    /**
     * @generated from protobuf field: int32 prematch_provider_id = 112;
     */
    prematchProviderId: number;
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
    /**
     * @generated from protobuf field: string outright_type = 132;
     */
    outrightType: string;
    /**
     * @generated from protobuf field: repeated odds.Competitor competitors = 44;
     */
    competitors: Competitor[];
    /**
     * @generated from protobuf field: common.EventResult result = 145;
     */
    result?: EventResult;
    /**
     * @generated from protobuf field: bool has_result = 146;
     */
    hasResult: boolean;
    /**
     * @generated from protobuf field: bool can_be_linked = 147;
     */
    canBeLinked: boolean;
    /**
     * @generated from protobuf field: int32 num_of_slips = 151;
     */
    numOfSlips: number;
    /**
     * @generated from protobuf field: int32 num_unresolved_odds = 166;
     */
    numUnresolvedOdds: number;
    /**
     * @generated from protobuf field: int32 num_of_odds = 169;
     */
    numOfOdds: number;
    /**
     * @generated from protobuf field: string final_result = 167;
     */
    finalResult: string;
    /**
     * @generated from protobuf field: string note = 173;
     */
    note: string;
    /**
     * @generated from protobuf field: string factory_competitor_name = 96;
     */
    factoryCompetitorName: string;
    /**
     * @generated from protobuf field: string factory_player_name = 104;
     */
    factoryPlayerName: string;
    /**
     * @generated from protobuf field: int32 minutes = 187;
     */
    minutes: number;
    /**
     * @generated from protobuf field: int32 seconds = 188;
     */
    seconds: number;
    /**
     * @generated from protobuf field: int32 market_template_id = 224;
     */
    marketTemplateId: number;
    /**
     * @generated from protobuf field: bool can_finish = 275;
     */
    canFinish: boolean;
    /**
     * @generated from protobuf field: string live_event_name = 300;
     */
    liveEventName: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp live_event_start = 301;
     */
    liveEventStart?: Timestamp;
    /**
     * @generated from protobuf field: bool is_blocked = 302;
     */
    isBlocked: boolean;
    /**
     * @generated from protobuf field: string block_reason = 303;
     */
    blockReason: string;
    /**
     * @generated from protobuf field: bool auto_link = 304;
     */
    autoLink: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp time_cancel_timestamp = 333;
     */
    timeCancelTimestamp?: Timestamp;
    /**
     * @generated from protobuf field: bool exefeed_prematch = 334;
     */
    exefeedPrematch: boolean;
    /**
     * @generated from protobuf field: string result_format = 335;
     */
    resultFormat: string;
    /**
     * @generated from protobuf field: int32 provider_event_id = 336;
     */
    providerEventId: number;
}
/**
 * @generated from protobuf message odds.LandBaseCodeEvent
 */
export interface LandBaseCodeEvent {
    /**
     * @generated from protobuf field: odds.StreamEvent event = 1;
     */
    event?: StreamEvent;
    /**
     * @generated from protobuf field: common.Error error = 2;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.StreamEvent
 */
export interface StreamEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string note = 3;
     */
    note: string;
    /**
     * @generated from protobuf field: int32 competition_id = 4;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: int32 location_id = 5;
     */
    locationId: number;
    /**
     * @generated from protobuf field: int32 sport_id = 6;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated odds.StreamEventOdd odds = 7;
     */
    odds: StreamEventOdd[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 8;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: bool is_disabled = 9;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: bool is_suspicious = 10;
     */
    isSuspicious: boolean;
    /**
     * @generated from protobuf field: bool is_single_approval = 11;
     */
    isSingleApproval: boolean;
    /**
     * @generated from protobuf field: string profile = 12;
     */
    profile: string;
    /**
     * @generated from protobuf field: common.EventCurrentPhase current_phase = 13;
     */
    currentPhase?: EventCurrentPhase;
    /**
     * @generated from protobuf field: string landbase_code = 14;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: string type = 15;
     */
    type: string;
    /**
     * @generated from protobuf field: string outright_type = 16;
     */
    outrightType: string;
    /**
     * @generated from protobuf field: int32 mon = 17;
     */
    mon: number;
    /**
     * @generated from protobuf field: common.EventResult result = 18;
     */
    result?: EventResult;
}
/**
 * @generated from protobuf message odds.StreamEventOdd
 */
export interface StreamEventOdd {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: double value = 8;
     */
    value: number;
    /**
     * @generated from protobuf field: int32 outcome_id = 11;
     */
    outcomeId: number;
    /**
     * @generated from protobuf field: double limit = 122;
     */
    limit: number;
    /**
     * @generated from protobuf field: int32 frame_number = 238;
     */
    frameNumber: number;
    /**
     * @generated from protobuf field: string current_result = 239;
     */
    currentResult: string;
    /**
     * @generated from protobuf field: bool is_disabled = 136;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: string live_player = 9;
     */
    livePlayer: string;
}
/**
 * @generated from protobuf message odds.CreateEventRequest
 */
export interface CreateEventRequest {
    /**
     * @generated from protobuf field: string type = 93;
     */
    type: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string event_id = 12;
     */
    eventId: string;
    /**
     * @generated from protobuf field: int32 competition_id = 4;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: repeated odds.Outcome outcomes = 27;
     */
    outcomes: Outcome[];
    /**
     * @generated from protobuf field: string outright_type = 132;
     */
    outrightType: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp end_betting_time = 140;
     */
    endBettingTime?: Timestamp;
}
/**
 * @generated from protobuf message odds.EventFwwRequest
 */
export interface EventFwwRequest {
    /**
     * @generated from protobuf field: string type = 1;
     */
    type: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string event_id = 3;
     */
    eventId: string;
    /**
     * @generated from protobuf field: int32 competition_id = 4;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: int32 start = 5;
     */
    start: number;
}
/**
 * @generated from protobuf message odds.OddFwwRequest
 */
export interface OddFwwRequest {
    /**
     * @generated from protobuf field: string odd_id = 11;
     */
    oddId: string;
    /**
     * @generated from protobuf field: double limit = 1;
     */
    limit: number;
    /**
     * @generated from protobuf field: double prematch_odd = 2;
     */
    prematchOdd: number;
    /**
     * @generated from protobuf field: string event_id = 3;
     */
    eventId: string;
    /**
     * @generated from protobuf field: int32 outcome_id = 4;
     */
    outcomeId: number;
}
/**
 * @generated from protobuf message odds.CompetitionFwwRequest
 */
export interface CompetitionFwwRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 location_id = 2;
     */
    locationId: number;
    /**
     * @generated from protobuf field: int32 sport_id = 3;
     */
    sportId: number;
    /**
     * @generated from protobuf field: int32 pa_sport_id = 4;
     */
    paSportId: number;
    /**
     * @generated from protobuf field: string pa_type = 5;
     */
    paType: string;
}
/**
 * @generated from protobuf message odds.EventOdd
 */
export interface EventOdd {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: double value = 8;
     */
    value: number;
    /**
     * @generated from protobuf field: int32 outcome_id = 11;
     */
    outcomeId: number;
    /**
     * @generated from protobuf field: int32 market_id = 94;
     */
    marketId: number;
    /**
     * @generated from protobuf field: double limit = 122;
     */
    limit: number;
    /**
     * @generated from protobuf field: int32 frame_number = 238;
     */
    frameNumber: number;
    /**
     * @generated from protobuf field: string current_result = 239;
     */
    currentResult: string;
    /**
     * @generated from protobuf field: string outcome_name = 85;
     */
    outcomeName: string;
    /**
     * @generated from protobuf field: string outcome_group = 191;
     */
    outcomeGroup: string;
    /**
     * @generated from protobuf field: string market_name = 84;
     */
    marketName: string;
    /**
     * @generated from protobuf field: int32 factory_competitor_id = 83;
     */
    factoryCompetitorId: number;
    /**
     * @generated from protobuf field: string factory_competitor_name = 96;
     */
    factoryCompetitorName: string;
    /**
     * @generated from protobuf field: string status = 91;
     */
    status: string;
    /**
     * @generated from protobuf field: int32 num_of_slips = 151;
     */
    numOfSlips: number;
    /**
     * @generated from protobuf field: bool is_disabled = 136;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: string landbase_code = 80;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: string outcome_code = 81;
     */
    outcomeCode: string;
    /**
     * @generated from protobuf field: string live_player = 9;
     */
    livePlayer: string;
}
/**
 * @generated from protobuf message odds.MarketGroup
 */
export interface MarketGroup {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 order = 55;
     */
    order: number;
    /**
     * @generated from protobuf field: repeated odds.Market markets = 6;
     */
    markets: Market[];
}
/**
 * @generated from protobuf message odds.Market
 */
export interface Market {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated odds.Outcome outcomes = 27;
     */
    outcomes: Outcome[];
    /**
     * @generated from protobuf field: bool has_limit = 185;
     */
    hasLimit: boolean;
}
/**
 * @generated from protobuf message odds.Outcome
 */
export interface Outcome {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: odds.Sport sport = 120;
     */
    sport?: Sport;
    /**
     * @generated from protobuf field: odds.Market market = 121;
     */
    market?: Market;
    /**
     * @generated from protobuf field: string outcome_code = 81;
     */
    outcomeCode: string;
    /**
     * @generated from protobuf field: string outcome_group = 191;
     */
    outcomeGroup: string;
    /**
     * @generated from protobuf field: string outcome_tic = 274;
     */
    outcomeTic: string;
    /**
     * @generated from protobuf field: string exe_limit = 240;
     */
    exeLimit: string;
    /**
     * @generated from protobuf field: odds.Odd odd = 19;
     */
    odd?: Odd;
    /**
     * @generated from protobuf field: string description = 118;
     */
    description: string;
    /**
     * @generated from protobuf field: string short_description = 119;
     */
    shortDescription: string;
    /**
     * @generated from protobuf field: int32 factory_competitor_id = 83;
     */
    factoryCompetitorId: number;
    /**
     * @generated from protobuf field: int32 factory_player_id = 102;
     */
    factoryPlayerId: number;
    /**
     * @generated from protobuf field: repeated odds.Link links = 98;
     */
    links: Link[];
    /**
     * @generated from protobuf field: int32 live_market_id = 261;
     */
    liveMarketId: number;
    /**
     * @generated from protobuf field: bool has_limit = 279;
     */
    hasLimit: boolean;
}
/**
 * @generated from protobuf message odds.Odd
 */
export interface Odd {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string value = 8;
     */
    value: string;
    /**
     * @generated from protobuf field: double limit = 122;
     */
    limit: number;
    /**
     * @generated from protobuf field: string status = 91;
     */
    status: string;
    /**
     * @generated from protobuf field: bool is_disabled = 136;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: bool is_fixed = 183;
     */
    isFixed: boolean;
    /**
     * @generated from protobuf field: double prematch_odd = 2;
     */
    prematchOdd: number;
    /**
     * @generated from protobuf field: string event_id = 3;
     */
    eventId: string;
    /**
     * @generated from protobuf field: int32 outcome_id = 4;
     */
    outcomeId: number;
    /**
     * @generated from protobuf field: common.Error error = 77;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.Sport
 */
export interface Sport {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 event_count = 24;
     */
    eventCount: number;
    /**
     * @generated from protobuf field: int32 filtered_event_count = 171;
     */
    filteredEventCount: number;
    /**
     * @generated from protobuf field: bool has_players = 137;
     */
    hasPlayers: boolean;
    /**
     * @generated from protobuf field: bool has_groups = 141;
     */
    hasGroups: boolean;
    /**
     * @generated from protobuf field: bool manual_results = 144;
     */
    manualResults: boolean;
    /**
     * @generated from protobuf field: repeated odds.Location locations = 23;
     */
    locations: Location[];
    /**
     * @generated from protobuf field: repeated int32 market_ids = 52;
     */
    marketIds: number[];
    /**
     * @generated from protobuf field: odds.BasicOffer basic_offer_live = 150;
     */
    basicOfferLive?: BasicOffer;
    /**
     * @generated from protobuf field: odds.BasicOffer basic_offer_prematch = 151;
     */
    basicOfferPrematch?: BasicOffer;
}
/**
 * @generated from protobuf message odds.BasicOffer
 */
export interface BasicOffer {
    /**
     * @generated from protobuf field: repeated odds.BasicOfferMarket markets = 1;
     */
    markets: BasicOfferMarket[];
}
/**
 * @generated from protobuf message odds.BasicOfferMarket
 */
export interface BasicOfferMarket {
    /**
     * @generated from protobuf field: int32 market_id = 1;
     */
    marketId: number;
    /**
     * @generated from protobuf field: repeated int32 outcome_ids = 2;
     */
    outcomeIds: number[];
}
/**
 * @generated from protobuf message odds.Location
 */
export interface Location {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 event_count = 24;
     */
    eventCount: number;
    /**
     * @generated from protobuf field: int32 filtered_event_count = 171;
     */
    filteredEventCount: number;
    /**
     * @generated from protobuf field: repeated odds.Competition competitions = 25;
     */
    competitions: Competition[];
}
/**
 * @generated from protobuf message odds.Competition
 */
export interface Competition {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string short_name = 48;
     */
    shortName: string;
    /**
     * @generated from protobuf field: int32 event_count = 24;
     */
    eventCount: number;
    /**
     * @generated from protobuf field: int32 filtered_event_count = 171;
     */
    filteredEventCount: number;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: string sport_name = 107;
     */
    sportName: string;
    /**
     * @generated from protobuf field: bool is_favorite = 40;
     */
    isFavorite: boolean;
    /**
     * @generated from protobuf field: bool is_automatic = 110;
     */
    isAutomatic: boolean;
    /**
     * @generated from protobuf field: int32 location_id = 109;
     */
    locationId: number;
    /**
     * @generated from protobuf field: string location_name = 108;
     */
    locationName: string;
    /**
     * @generated from protobuf field: repeated odds.Competitor competitors = 44;
     */
    competitors: Competitor[];
    /**
     * @generated from protobuf field: repeated odds.Link links = 98;
     */
    links: Link[];
    /**
     * @generated from protobuf field: repeated odds.Event events = 17;
     */
    events: Event[];
    /**
     * @generated from protobuf field: int32 market_template_id = 224;
     */
    marketTemplateId: number;
    /**
     * @generated from protobuf field: int32 pa_sport_id = 305;
     */
    paSportId: number;
    /**
     * @generated from protobuf field: int32 pa_sport_name = 306;
     */
    paSportName: number;
    /**
     * @generated from protobuf field: string pa_type = 307;
     */
    paType: string;
}
/**
 * @generated from protobuf message odds.OddResponse
 */
export interface OddResponse {
    /**
     * @generated from protobuf field: string odd_id = 1;
     */
    oddId: string;
    /**
     * @generated from protobuf field: int32 int_id = 45;
     */
    intId: number;
    /**
     * @generated from protobuf field: double odd_value = 2;
     */
    oddValue: number;
    /**
     * @generated from protobuf field: int32 frame_number = 46;
     */
    frameNumber: number;
    /**
     * @generated from protobuf field: string current_result = 47;
     */
    currentResult: string;
    /**
     * @generated from protobuf field: double provider_odd_value = 3;
     */
    providerOddValue: number;
    /**
     * @generated from protobuf field: int32 sport_id = 4;
     */
    sportId: number;
    /**
     * @generated from protobuf field: string event_id = 5;
     */
    eventId: string;
    /**
     * @generated from protobuf field: string event_type = 6;
     */
    eventType: string;
    /**
     * @generated from protobuf field: string event_profile = 7;
     */
    eventProfile: string;
    /**
     * @generated from protobuf field: bool event_disabled = 8;
     */
    eventDisabled: boolean;
    /**
     * @generated from protobuf field: bool event_finished = 9;
     */
    eventFinished: boolean;
    /**
     * @generated from protobuf field: bool event_published = 10;
     */
    eventPublished: boolean;
    /**
     * @generated from protobuf field: bool event_suspicious = 11;
     */
    eventSuspicious: boolean;
    /**
     * @generated from protobuf field: bool event_single_approval = 12;
     */
    eventSingleApproval: boolean;
    /**
     * @generated from protobuf field: string event_landbase_code = 13;
     */
    eventLandbaseCode: string;
    /**
     * @generated from protobuf field: string suspicious_level = 14;
     */
    suspiciousLevel: string;
    /**
     * @generated from protobuf field: int32 competition_id = 15;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: string competition_name = 16;
     */
    competitionName: string;
    /**
     * @generated from protobuf field: string competition_short_name = 43;
     */
    competitionShortName: string;
    /**
     * @generated from protobuf field: string flag_code = 44;
     */
    flagCode: string;
    /**
     * @generated from protobuf field: double competition_amount = 17;
     */
    competitionAmount: number;
    /**
     * @generated from protobuf field: double competition_max_single = 18;
     */
    competitionMaxSingle: number;
    /**
     * @generated from protobuf field: double competition_max_multiple = 19;
     */
    competitionMaxMultiple: number;
    /**
     * @generated from protobuf field: double competition_single_betting_amount = 20;
     */
    competitionSingleBettingAmount: number;
    /**
     * @generated from protobuf field: double competition_multiple_betting_amount = 21;
     */
    competitionMultipleBettingAmount: number;
    /**
     * @generated from protobuf field: int32 location_id = 22;
     */
    locationId: number;
    /**
     * @generated from protobuf field: int32 market_id = 23;
     */
    marketId: number;
    /**
     * @generated from protobuf field: bool is_live = 24;
     */
    isLive: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 25;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp end_betting_time = 26;
     */
    endBettingTime?: Timestamp;
    /**
     * @generated from protobuf field: string market_name = 27;
     */
    marketName: string;
    /**
     * @generated from protobuf field: string outcome_name = 28;
     */
    outcomeName: string;
    /**
     * @generated from protobuf field: string outcome_tic = 29;
     */
    outcomeTic: string;
    /**
     * @generated from protobuf field: int32 outcome_id = 30;
     */
    outcomeId: number;
    /**
     * @generated from protobuf field: common.EventResult result = 31;
     */
    result?: EventResult;
    /**
     * @generated from protobuf field: string outcome_group = 32;
     */
    outcomeGroup: string;
    /**
     * @generated from protobuf field: double limit = 33;
     */
    limit: number;
    /**
     * @generated from protobuf field: string event_name = 34;
     */
    eventName: string;
    /**
     * @generated from protobuf field: string outright_type = 35;
     */
    outrightType: string;
    /**
     * @generated from protobuf field: bool is_disabled = 36;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: string landbase_code = 37;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: string event_note = 38;
     */
    eventNote: string;
    /**
     * @generated from protobuf field: double outcome_limit = 39;
     */
    outcomeLimit: number;
    /**
     * @generated from protobuf field: int32 pa_sport = 40;
     */
    paSport: number;
    /**
     * @generated from protobuf field: string pa_type = 41;
     */
    paType: string;
    /**
     * @generated from protobuf field: int32 exefeed_id = 42;
     */
    exefeedId: number;
    /**
     * @generated from protobuf field: string live_player = 48;
     */
    livePlayer: string;
}
/**
 * @generated from protobuf message odds.GetInitialDataResponse
 */
export interface GetInitialDataResponse {
    /**
     * @generated from protobuf field: repeated odds.MarketGroup market_groups = 29;
     */
    marketGroups: MarketGroup[];
    /**
     * @generated from protobuf field: repeated odds.Sport sports = 13;
     */
    sports: Sport[];
}
/**
 * @generated from protobuf message odds.GetInitialDataForBoResponse
 */
export interface GetInitialDataForBoResponse {
    /**
     * @generated from protobuf field: repeated odds.Sport sports = 13;
     */
    sports: Sport[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.GetProvidersResponse
 */
export interface GetProvidersResponse {
    /**
     * @generated from protobuf field: repeated odds.Provider providers = 38;
     */
    providers: Provider[];
}
/**
 * @generated from protobuf message odds.GetEventsResponse
 */
export interface GetEventsResponse {
    /**
     * @generated from protobuf field: odds.LiveEvents live_events = 36;
     */
    liveEvents?: LiveEvents;
    /**
     * @generated from protobuf field: odds.UpcomingEvents upcoming_events = 37;
     */
    upcomingEvents?: UpcomingEvents;
    /**
     * @generated from protobuf field: odds.UpcomingEvents outright_events = 247;
     */
    outrightEvents?: UpcomingEvents;
    /**
     * @generated from protobuf field: odds.UpcomingEvents player_events = 248;
     */
    playerEvents?: UpcomingEvents;
    /**
     * @generated from protobuf field: odds.UpcomingEvents group_events = 249;
     */
    groupEvents?: UpcomingEvents;
}
/**
 * @generated from protobuf message odds.LiveEvents
 */
export interface LiveEvents {
    /**
     * @generated from protobuf field: repeated odds.StreamEvent events = 17;
     */
    events: StreamEvent[];
    /**
     * @generated from protobuf field: bool disable_all = 193;
     */
    disableAll: boolean;
    /**
     * @generated from protobuf field: repeated int32 disabled_sports = 194;
     */
    disabledSports: number[];
}
/**
 * @generated from protobuf message odds.LiveEventsBO
 */
export interface LiveEventsBO {
    /**
     * @generated from protobuf field: repeated odds.Event events = 17;
     */
    events: Event[];
    /**
     * @generated from protobuf field: bool disable_all = 193;
     */
    disableAll: boolean;
    /**
     * @generated from protobuf field: repeated int32 disabled_sports = 194;
     */
    disabledSports: number[];
}
/**
 * @generated from protobuf message odds.UpcomingEvents
 */
export interface UpcomingEvents {
    /**
     * @generated from protobuf field: repeated odds.StreamEvent events = 17;
     */
    events: StreamEvent[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.AllEvents
 */
export interface AllEvents {
    /**
     * @generated from protobuf field: repeated odds.Event events = 17;
     */
    events: Event[];
}
/**
 * @generated from protobuf message odds.SlipSubGroupOddResponse
 */
export interface SlipSubGroupOddResponse {
    /**
     * @generated from protobuf field: repeated odds.OddResponse odds = 18;
     */
    odds: OddResponse[];
    /**
     * @generated from protobuf field: string type = 31;
     */
    type: string;
}
/**
 * @generated from protobuf message odds.SlipGroupOddResponse
 */
export interface SlipGroupOddResponse {
    /**
     * @generated from protobuf field: repeated odds.SlipSubGroupOddResponse sub_groups = 77;
     */
    subGroups: SlipSubGroupOddResponse[];
    /**
     * @generated from protobuf field: string type = 31;
     */
    type: string;
}
/**
 * @generated from protobuf message odds.GetOddsResponse
 */
export interface GetOddsResponse {
    /**
     * @generated from protobuf field: repeated odds.SlipGroupOddResponse slip_groups = 32;
     */
    slipGroups: SlipGroupOddResponse[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.Provider
 */
export interface Provider {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string alias = 3;
     */
    alias: string;
    /**
     * @generated from protobuf field: bool live_provider = 105;
     */
    liveProvider: boolean;
    /**
     * @generated from protobuf field: bool prematch_provider = 106;
     */
    prematchProvider: boolean;
    /**
     * @generated from protobuf field: repeated odds.ProviderCompetitor provider_competitors = 95;
     */
    providerCompetitors: ProviderCompetitor[];
    /**
     * @generated from protobuf field: repeated odds.ProviderPlayer provider_players = 103;
     */
    providerPlayers: ProviderPlayer[];
    /**
     * @generated from protobuf field: repeated odds.ProviderOutcome provider_outcomes = 117;
     */
    providerOutcomes: ProviderOutcome[];
    /**
     * @generated from protobuf field: repeated odds.ProviderCompetition provider_competitions = 128;
     */
    providerCompetitions: ProviderCompetition[];
}
/**
 * @generated from protobuf message odds.CreateOfferEvent
 */
export interface CreateOfferEvent {
    /**
     * @generated from protobuf field: int32 live_provider_id = 111;
     */
    liveProviderId: number;
    /**
     * @generated from protobuf field: int32 prematch_provider_id = 112;
     */
    prematchProviderId: number;
    /**
     * @generated from protobuf field: string live_event_id = 113;
     */
    liveEventId: string;
    /**
     * @generated from protobuf field: string prematch_event_id = 114;
     */
    prematchEventId: string;
}
/**
 * @generated from protobuf message odds.CreateOfferRequest
 */
export interface CreateOfferRequest {
    /**
     * @generated from protobuf field: repeated odds.CreateOfferEvent events = 115;
     */
    events: CreateOfferEvent[];
}
/**
 * @generated from protobuf message odds.GetAllLandBaseOffersResponse
 */
export interface GetAllLandBaseOffersResponse {
    /**
     * @generated from protobuf field: repeated odds.SingleLandBaseOfferResponse offers = 59;
     */
    offers: SingleLandBaseOfferResponse[];
}
/**
 * @generated from protobuf message odds.SingleLandBaseOfferResponse
 */
export interface SingleLandBaseOfferResponse {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 56;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp end = 57;
     */
    end?: Timestamp;
    /**
     * @generated from protobuf field: int32 id = 58;
     */
    id: number;
}
/**
 * @generated from protobuf message odds.CreateLandBaseOfferRequest
 */
export interface CreateLandBaseOfferRequest {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 60;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp end = 61;
     */
    end?: Timestamp;
    /**
     * @generated from protobuf field: repeated string event_ids = 62;
     */
    eventIds: string[];
}
/**
 * @generated from protobuf message odds.CreateLandBaseOfferResponse
 */
export interface CreateLandBaseOfferResponse {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 60;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp end = 61;
     */
    end?: Timestamp;
    /**
     * @generated from protobuf field: repeated string event_ids = 62;
     */
    eventIds: string[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
    /**
     * @generated from protobuf field: int32 id = 71;
     */
    id: number;
}
/**
 * @generated from protobuf message odds.TimeRange
 */
export interface TimeRange {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 64;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp end = 65;
     */
    end?: Timestamp;
}
/**
 * @generated from protobuf message odds.LandBaseId
 */
export interface LandBaseId {
    /**
     * @generated from protobuf field: int32 id = 66;
     */
    id: number;
}
/**
 * @generated from protobuf message odds.EditLandBaseOfferRequest
 */
export interface EditLandBaseOfferRequest {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 73;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp end = 74;
     */
    end?: Timestamp;
    /**
     * @generated from protobuf field: repeated string event_ids = 75;
     */
    eventIds: string[];
    /**
     * @generated from protobuf field: int32 id = 76;
     */
    id: number;
}
/**
 * @generated from protobuf message odds.EditLandBaseOfferResponse
 */
export interface EditLandBaseOfferResponse {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 67;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp end = 68;
     */
    end?: Timestamp;
    /**
     * @generated from protobuf field: repeated string event_ids = 69;
     */
    eventIds: string[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
    /**
     * @generated from protobuf field: int32 id = 72;
     */
    id: number;
}
/**
 * @generated from protobuf message odds.SearchEventsRequest
 */
export interface SearchEventsRequest {
    /**
     * @generated from protobuf field: string criteria = 79;
     */
    criteria: string;
}
/**
 * @generated from protobuf message odds.SearchOutcomesRequest
 */
export interface SearchOutcomesRequest {
    /**
     * @generated from protobuf field: string event_id = 12;
     */
    eventId: string;
    /**
     * @generated from protobuf field: string outcome_code = 81;
     */
    outcomeCode: string;
}
/**
 * @generated from protobuf message odds.SearchOutcomesResponse
 */
export interface SearchOutcomesResponse {
    /**
     * @generated from protobuf field: repeated odds.Outcome outcomes = 27;
     */
    outcomes: Outcome[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.SearchCompetitorRequest
 */
export interface SearchCompetitorRequest {
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message odds.LinkCompetitorRequest
 */
export interface LinkCompetitorRequest {
    /**
     * @generated from protobuf field: int32 factory_competitor_id = 83;
     */
    factoryCompetitorId: number;
    /**
     * @generated from protobuf field: repeated odds.Link links = 98;
     */
    links: Link[];
}
/**
 * @generated from protobuf message odds.LinkCompetitionRequest
 */
export interface LinkCompetitionRequest {
    /**
     * @generated from protobuf field: int32 competition_id = 4;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: int32 live_competition_id = 5;
     */
    liveCompetitionId: number;
    /**
     * @generated from protobuf field: int32 prematch_competition_id = 6;
     */
    prematchCompetitionId: number;
}
/**
 * @generated from protobuf message odds.GetStatusesForEventsAndOddsRequest
 */
export interface GetStatusesForEventsAndOddsRequest {
    /**
     * @generated from protobuf field: repeated string event_ids = 86;
     */
    eventIds: string[];
    /**
     * @generated from protobuf field: repeated string odd_ids = 87;
     */
    oddIds: string[];
}
/**
 * @generated from protobuf message odds.OddStatus
 */
export interface OddStatus {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string status = 2;
     */
    status: string;
    /**
     * @generated from protobuf field: double value = 3;
     */
    value: number;
    /**
     * @generated from protobuf field: bool is_live = 4;
     */
    isLive: boolean;
    /**
     * @generated from protobuf field: bool is_disabled = 5;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 6;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: string type = 7;
     */
    type: string;
    /**
     * @generated from protobuf field: string result = 8;
     */
    result: string;
}
/**
 * @generated from protobuf message odds.GetStatusesForEventsAndOddsResponse
 */
export interface GetStatusesForEventsAndOddsResponse {
    /**
     * @generated from protobuf field: repeated odds.OddStatus odds = 89;
     */
    odds: OddStatus[];
}
/**
 * @generated from protobuf message odds.GetProviderCompetitorsRequest
 */
export interface GetProviderCompetitorsRequest {
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
}
/**
 * @generated from protobuf message odds.GetProviderCompetitorsResponse
 */
export interface GetProviderCompetitorsResponse {
    /**
     * @generated from protobuf field: repeated odds.Provider providers = 38;
     */
    providers: Provider[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.GetProviderCompetitionsRequest
 */
export interface GetProviderCompetitionsRequest {
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
}
/**
 * @generated from protobuf message odds.GetProviderCompetitionsResponse
 */
export interface GetProviderCompetitionsResponse {
    /**
     * @generated from protobuf field: repeated odds.Provider providers = 38;
     */
    providers: Provider[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.ProviderCompetitor
 */
export interface ProviderCompetitor {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated string competition_names = 45;
     */
    competitionNames: string[];
    /**
     * @generated from protobuf field: repeated string location = 46;
     */
    location: string[];
    /**
     * @generated from protobuf field: int32 factory_competitor_id = 83;
     */
    factoryCompetitorId: number;
    /**
     * @generated from protobuf field: string factory_competitor_name = 96;
     */
    factoryCompetitorName: string;
}
/**
 * @generated from protobuf message odds.ProviderPlayer
 */
export interface ProviderPlayer {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 provider_competitor_id = 82;
     */
    providerCompetitorId: number;
    /**
     * @generated from protobuf field: string provider_competitor_name = 100;
     */
    providerCompetitorName: string;
    /**
     * @generated from protobuf field: int32 factory_player_id = 102;
     */
    factoryPlayerId: number;
    /**
     * @generated from protobuf field: string factory_player_name = 104;
     */
    factoryPlayerName: string;
}
/**
 * @generated from protobuf message odds.ProviderOutcome
 */
export interface ProviderOutcome {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 factory_sport_id = 34;
     */
    factorySportId: number;
}
/**
 * @generated from protobuf message odds.GetPlayersResponse
 */
export interface GetPlayersResponse {
    /**
     * @generated from protobuf field: repeated odds.Player players = 138;
     */
    players: Player[];
}
/**
 * @generated from protobuf message odds.Player
 */
export interface Player {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string short_name = 48;
     */
    shortName: string;
    /**
     * @generated from protobuf field: string factory_competitor_name = 96;
     */
    factoryCompetitorName: string;
    /**
     * @generated from protobuf field: repeated string competition_names = 45;
     */
    competitionNames: string[];
    /**
     * @generated from protobuf field: string location_name = 108;
     */
    locationName: string;
    /**
     * @generated from protobuf field: string sport = 47;
     */
    sport: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp last_updated = 50;
     */
    lastUpdated?: Timestamp;
    /**
     * @generated from protobuf field: repeated odds.Link links = 98;
     */
    links: Link[];
}
/**
 * @generated from protobuf message odds.AddPlayerRequest
 */
export interface AddPlayerRequest {
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string short_name = 48;
     */
    shortName: string;
    /**
     * @generated from protobuf field: int32 factory_competitor_id = 83;
     */
    factoryCompetitorId: number;
}
/**
 * @generated from protobuf message odds.AddPlayerResponse
 */
export interface AddPlayerResponse {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string short_name = 48;
     */
    shortName: string;
    /**
     * @generated from protobuf field: int32 factory_competitor_id = 83;
     */
    factoryCompetitorId: number;
    /**
     * @generated from protobuf field: string factory_competitor_name = 96;
     */
    factoryCompetitorName: string;
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.EditPlayer
 */
export interface EditPlayer {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string short_name = 48;
     */
    shortName: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.LinkPlayerRequest
 */
export interface LinkPlayerRequest {
    /**
     * @generated from protobuf field: int32 factory_player_id = 102;
     */
    factoryPlayerId: number;
    /**
     * @generated from protobuf field: repeated odds.Link links = 98;
     */
    links: Link[];
}
/**
 * @generated from protobuf message odds.GetProviderPlayersRequest
 */
export interface GetProviderPlayersRequest {
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
}
/**
 * @generated from protobuf message odds.GetProviderPlayersResponse
 */
export interface GetProviderPlayersResponse {
    /**
     * @generated from protobuf field: repeated odds.Provider providers = 38;
     */
    providers: Provider[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.AddCompetitorRequest
 */
export interface AddCompetitorRequest {
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string short_name = 48;
     */
    shortName: string;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: int32 location_id = 109;
     */
    locationId: number;
    /**
     * @generated from protobuf field: repeated int32 competition_ids = 41;
     */
    competitionIds: number[];
}
/**
 * @generated from protobuf message odds.AddCompetitionRequest
 */
export interface AddCompetitionRequest {
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string short_name = 48;
     */
    shortName: string;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: int32 location_id = 109;
     */
    locationId: number;
}
/**
 * @generated from protobuf message odds.AddCompetitionResponse
 */
export interface AddCompetitionResponse {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string short_name = 48;
     */
    shortName: string;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.AddCompetitorResponse
 */
export interface AddCompetitorResponse {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string short_name = 48;
     */
    shortName: string;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated odds.Competition competitions = 25;
     */
    competitions: Competition[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.EditCompetitorRequest
 */
export interface EditCompetitorRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string short_name = 48;
     */
    shortName: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: int32 location_id = 109;
     */
    locationId: number;
    /**
     * @generated from protobuf field: repeated int32 competition_ids = 41;
     */
    competitionIds: number[];
}
/**
 * @generated from protobuf message odds.EditCompetitionRequest
 */
export interface EditCompetitionRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string short_name = 48;
     */
    shortName: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: int32 location_id = 109;
     */
    locationId: number;
    /**
     * @generated from protobuf field: string standings_url = 6;
     */
    standingsUrl: string;
}
/**
 * @generated from protobuf message odds.AutoAddCompetitionEventsRequest
 */
export interface AutoAddCompetitionEventsRequest {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message odds.GetOutcomesResponse
 */
export interface GetOutcomesResponse {
    /**
     * @generated from protobuf field: repeated odds.Outcome outcomes = 27;
     */
    outcomes: Outcome[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.GetOutcomesRequest
 */
export interface GetOutcomesRequest {
    /**
     * @generated from protobuf field: int32 sport_id = 1;
     */
    sportId: number;
    /**
     * @generated from protobuf field: string outcome_name = 2;
     */
    outcomeName: string;
}
/**
 * @generated from protobuf message odds.GetProviderOutcomesResponse
 */
export interface GetProviderOutcomesResponse {
    /**
     * @generated from protobuf field: repeated odds.Provider providers = 38;
     */
    providers: Provider[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.GetEventTypesResponse
 */
export interface GetEventTypesResponse {
    /**
     * @generated from protobuf field: map<string, string> types = 124;
     */
    types: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message odds.ConfigureEventStartTimeRequest
 */
export interface ConfigureEventStartTimeRequest {
    /**
     * @generated from protobuf field: string event_id = 12;
     */
    eventId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 22;
     */
    start?: Timestamp;
}
/**
 * @generated from protobuf message odds.GetEventsByTypeRequest
 */
export interface GetEventsByTypeRequest {
    /**
     * @generated from protobuf field: string type = 93;
     */
    type: string;
}
/**
 * @generated from protobuf message odds.GetEventsByTypeResponse
 */
export interface GetEventsByTypeResponse {
    /**
     * @generated from protobuf field: repeated odds.Event events = 17;
     */
    events: Event[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.EditEventRequest
 */
export interface EditEventRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string type = 93;
     */
    type: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 competition_id = 4;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: string outright_type = 132;
     */
    outrightType: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp end_betting_time = 140;
     */
    endBettingTime?: Timestamp;
    /**
     * @generated from protobuf field: repeated odds.Outcome outcomes = 27;
     */
    outcomes: Outcome[];
}
/**
 * @generated from protobuf message odds.ResolveOddRequest
 */
export interface ResolveOddRequest {
    /**
     * @generated from protobuf field: string event_id = 12;
     */
    eventId: string;
    /**
     * @generated from protobuf field: repeated string passed_odd_ids = 126;
     */
    passedOddIds: string[];
    /**
     * @generated from protobuf field: repeated string failed_odd_ids = 127;
     */
    failedOddIds: string[];
    /**
     * @generated from protobuf field: repeated string cancelled_odd_ids = 128;
     */
    cancelledOddIds: string[];
    /**
     * @generated from protobuf field: repeated string not_resolved_odd_ids = 131;
     */
    notResolvedOddIds: string[];
}
/**
 * @generated from protobuf message odds.GetEventOddsRequest
 */
export interface GetEventOddsRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message odds.GetEventOddsResponse
 */
export interface GetEventOddsResponse {
    /**
     * @generated from protobuf field: repeated odds.Market markets = 6;
     */
    markets: Market[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.DisableEventRequest
 */
export interface DisableEventRequest {
    /**
     * @generated from protobuf field: string event_id = 12;
     */
    eventId: string;
}
/**
 * @generated from protobuf message odds.GetEventsWithPlayersRequest
 */
export interface GetEventsWithPlayersRequest {
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
}
/**
 * @generated from protobuf message odds.GetEventsWithPlayersResponse
 */
export interface GetEventsWithPlayersResponse {
    /**
     * @generated from protobuf field: repeated odds.Event events = 17;
     */
    events: Event[];
}
/**
 * @generated from protobuf message odds.GetMarketsResponse
 */
export interface GetMarketsResponse {
    /**
     * @generated from protobuf field: repeated odds.Market markets = 6;
     */
    markets: Market[];
}
/**
 * @generated from protobuf message odds.GetUnresolvedEventsResponse
 */
export interface GetUnresolvedEventsResponse {
    /**
     * @generated from protobuf field: repeated odds.Event events = 17;
     */
    events: Event[];
}
/**
 * @generated from protobuf message odds.GetSportsWithCompetitionsResponse
 */
export interface GetSportsWithCompetitionsResponse {
    /**
     * @generated from protobuf field: repeated odds.Sport sports = 13;
     */
    sports: Sport[];
}
/**
 * @generated from protobuf message odds.GetEventsForManualResultsResponse
 */
export interface GetEventsForManualResultsResponse {
    /**
     * @generated from protobuf field: repeated odds.Event events = 17;
     */
    events: Event[];
}
/**
 * @generated from protobuf message odds.EditManualResultRequest
 */
export interface EditManualResultRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: common.EventResult result = 145;
     */
    result?: EventResult;
}
/**
 * @generated from protobuf message odds.LinkEventsRequest
 */
export interface LinkEventsRequest {
    /**
     * @generated from protobuf field: string prematch_event_id = 1;
     */
    prematchEventId: string;
    /**
     * @generated from protobuf field: string live_event_id = 2;
     */
    liveEventId: string;
    /**
     * @generated from protobuf field: bool link_competitors = 3;
     */
    linkCompetitors: boolean;
}
/**
 * @generated from protobuf message odds.GenerateListPDFRequest
 */
export interface GenerateListPDFRequest {
}
/**
 * @generated from protobuf message odds.GenerateListPDFResponse
 */
export interface GenerateListPDFResponse {
    /**
     * @generated from protobuf field: bytes data = 150;
     */
    data: Uint8Array;
}
/**
 * @generated from protobuf message odds.GetSideBarRequest
 */
export interface GetSideBarRequest {
    /**
     * @generated from protobuf field: int32 provider_id = 5;
     */
    providerId: number;
    /**
     * @generated from protobuf field: string type = 93;
     */
    type: string;
    /**
     * @generated from protobuf field: string frontend_page_name = 155;
     */
    frontendPageName: string;
    /**
     * @generated from protobuf field: odds.Filter filter = 158;
     */
    filter?: Filter;
}
/**
 * @generated from protobuf message odds.GetSideBarResponse
 */
export interface GetSideBarResponse {
    /**
     * @generated from protobuf field: repeated odds.Sport sports = 13;
     */
    sports: Sport[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.Filter
 */
export interface Filter {
    /**
     * @generated from protobuf field: string search_string = 1;
     */
    searchString: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp date_from = 2;
     */
    dateFrom?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp date_to = 3;
     */
    dateTo?: Timestamp;
    /**
     * @generated from protobuf field: int32 num_odds_from = 4;
     */
    numOddsFrom: number;
    /**
     * @generated from protobuf field: int32 num_odds_to = 5;
     */
    numOddsTo: number;
    /**
     * @generated from protobuf field: string is_published = 6;
     */
    isPublished: string;
    /**
     * @generated from protobuf field: string is_auto = 7;
     */
    isAuto: string;
    /**
     * @generated from protobuf field: string is_linked = 8;
     */
    isLinked: string;
    /**
     * @generated from protobuf field: string has_result = 9;
     */
    hasResult: string;
    /**
     * @generated from protobuf field: int32 sport_id = 10;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated int32 provider_ids = 11;
     */
    providerIds: number[];
    /**
     * @generated from protobuf field: string resolved = 12;
     */
    resolved: string;
    /**
     * @generated from protobuf field: bool by_start = 13;
     */
    byStart: boolean;
    /**
     * @generated from protobuf field: string has_lb_code = 14;
     */
    hasLbCode: string;
    /**
     * @generated from protobuf field: string has_new_lb_code = 15;
     */
    hasNewLbCode: string;
}
/**
 * @generated from protobuf message odds.GetPaginatedEventsRequest
 */
export interface GetPaginatedEventsRequest {
    /**
     * @generated from protobuf field: repeated int32 competition_ids = 1;
     */
    competitionIds: number[];
    /**
     * @generated from protobuf field: odds.Filter filter = 2;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: string type = 3;
     */
    type: string;
    /**
     * @generated from protobuf field: int32 page = 4;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 provider_id = 5;
     */
    providerId: number;
    /**
     * @generated from protobuf field: string event_id = 6;
     */
    eventId: string;
}
/**
 * @generated from protobuf message odds.PaginatedEvents
 */
export interface PaginatedEvents {
    /**
     * @generated from protobuf field: int32 page = 161;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 total_pages = 162;
     */
    totalPages: number;
    /**
     * @generated from protobuf field: repeated odds.Competition competitions = 25;
     */
    competitions: Competition[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.CancelAllUnplayedRequest
 */
export interface CancelAllUnplayedRequest {
    /**
     * @generated from protobuf field: string type = 93;
     */
    type: string;
}
/**
 * @generated from protobuf message odds.ValidationPriorityObjects
 */
export interface ValidationPriorityObjects {
    /**
     * @generated from protobuf field: map<int32, string> competitions_map = 168;
     */
    competitionsMap: {
        [key: number]: string;
    };
    /**
     * @generated from protobuf field: map<int32, string> locations_map = 169;
     */
    locationsMap: {
        [key: number]: string;
    };
    /**
     * @generated from protobuf field: map<int32, string> sports_map = 170;
     */
    sportsMap: {
        [key: number]: string;
    };
}
/**
 * @generated from protobuf message odds.AddNoteRequest
 */
export interface AddNoteRequest {
    /**
     * @generated from protobuf field: string event_id = 12;
     */
    eventId: string;
    /**
     * @generated from protobuf field: string note = 172;
     */
    note: string;
}
/**
 * @generated from protobuf message odds.SuspiciousEvent
 */
export interface SuspiciousEvent {
    /**
     * @generated from protobuf field: string event_id = 12;
     */
    eventId: string;
    /**
     * @generated from protobuf field: string suspicious_level = 174;
     */
    suspiciousLevel: string;
    /**
     * @generated from protobuf field: string landbase_code = 80;
     */
    landbaseCode: string;
}
/**
 * @generated from protobuf message odds.UpdateOddsRequest
 */
export interface UpdateOddsRequest {
    /**
     * @generated from protobuf field: repeated string deleted_odd_ids = 87;
     */
    deletedOddIds: string[];
    /**
     * @generated from protobuf field: repeated string fixed_odd_ids = 184;
     */
    fixedOddIds: string[];
    /**
     * @generated from protobuf field: string event_id = 12;
     */
    eventId: string;
}
/**
 * @generated from protobuf message odds.SuspiciousLevels
 */
export interface SuspiciousLevels {
    /**
     * @generated from protobuf field: repeated odds.SuspiciousLevel suspicious_levels = 177;
     */
    suspiciousLevels: SuspiciousLevel[];
}
/**
 * @generated from protobuf message odds.SuspiciousLevel
 */
export interface SuspiciousLevel {
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string value = 8;
     */
    value: string;
}
/**
 * @generated from protobuf message odds.MatchProfiles
 */
export interface MatchProfiles {
    /**
     * @generated from protobuf field: repeated odds.MatchProfile match_profiles = 190;
     */
    matchProfiles: MatchProfile[];
}
/**
 * @generated from protobuf message odds.MatchProfile
 */
export interface MatchProfile {
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string value = 8;
     */
    value: string;
}
/**
 * @generated from protobuf message odds.SingleApproval
 */
export interface SingleApproval {
    /**
     * @generated from protobuf field: string event_id = 12;
     */
    eventId: string;
}
/**
 * @generated from protobuf message odds.UpdateChangedLimitOddsRequest
 */
export interface UpdateChangedLimitOddsRequest {
    /**
     * @generated from protobuf field: repeated odds.EventOdd odds = 181;
     */
    odds: EventOdd[];
}
/**
 * @generated from protobuf message odds.UpdateChangedLimitOddsResponse
 */
export interface UpdateChangedLimitOddsResponse {
    /**
     * @generated from protobuf field: map<string, string> odd_ids_map = 180;
     */
    oddIdsMap: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message odds.ResetLandbaseCodesRequest
 */
export interface ResetLandbaseCodesRequest {
    /**
     * @generated from protobuf field: repeated int32 sport_ids = 182;
     */
    sportIds: number[];
}
/**
 * @generated from protobuf message odds.GetPaginatedRequest
 */
export interface GetPaginatedRequest {
    /**
     * @generated from protobuf field: int32 page = 161;
     */
    page: number;
    /**
     * @generated from protobuf field: odds.Filter filter = 158;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: bool include_providers = 276;
     */
    includeProviders: boolean;
}
/**
 * @generated from protobuf message odds.GetPaginatedCompetitorsBoResponse
 */
export interface GetPaginatedCompetitorsBoResponse {
    /**
     * @generated from protobuf field: repeated odds.Competitor competitors = 44;
     */
    competitors: Competitor[];
    /**
     * @generated from protobuf field: int32 page = 161;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 total_pages = 162;
     */
    totalPages: number;
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.GetPaginatedCompetitionsBoResponse
 */
export interface GetPaginatedCompetitionsBoResponse {
    /**
     * @generated from protobuf field: repeated odds.Competition competitions = 25;
     */
    competitions: Competition[];
    /**
     * @generated from protobuf field: int32 page = 161;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 total_pages = 162;
     */
    totalPages: number;
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.DisableLive
 */
export interface DisableLive {
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: string status = 6;
     */
    status: string;
}
/**
 * @generated from protobuf message odds.GetCompetitionBetLimitRequest
 */
export interface GetCompetitionBetLimitRequest {
    /**
     * @generated from protobuf field: int32 page = 161;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: int32 location_id = 109;
     */
    locationId: number;
    /**
     * @generated from protobuf field: string competition_name = 96;
     */
    competitionName: string;
    /**
     * @generated from protobuf field: bool prematch = 273;
     */
    prematch: boolean;
    /**
     * @generated from protobuf field: repeated common.SortBy sort_by = 260;
     */
    sortBy: SortBy[];
}
/**
 * @generated from protobuf message odds.CompetitionLimit
 */
export interface CompetitionLimit {
    /**
     * @generated from protobuf field: int32 competition_id = 4;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: string competition_name = 96;
     */
    competitionName: string;
    /**
     * @generated from protobuf field: string location_name = 108;
     */
    locationName: string;
    /**
     * @generated from protobuf field: string sport_name = 107;
     */
    sportName: string;
    /**
     * @generated from protobuf field: repeated string days_of_month = 195;
     */
    daysOfMonth: string[];
    /**
     * @generated from protobuf field: repeated string days_of_week = 196;
     */
    daysOfWeek: string[];
    /**
     * @generated from protobuf field: double max_amount_single = 198;
     */
    maxAmountSingle: number;
    /**
     * @generated from protobuf field: double max_amount_multiple = 228;
     */
    maxAmountMultiple: number;
    /**
     * @generated from protobuf field: double single_betting_amount = 264;
     */
    singleBettingAmount: number;
    /**
     * @generated from protobuf field: double multiple_betting_amount = 265;
     */
    multipleBettingAmount: number;
    /**
     * @generated from protobuf field: double amount_single = 220;
     */
    amountSingle: number;
    /**
     * @generated from protobuf field: double amount_multiple = 229;
     */
    amountMultiple: number;
    /**
     * @generated from protobuf field: double amount_total = 230;
     */
    amountTotal: number;
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.GetCompetitionBetLimitResponse
 */
export interface GetCompetitionBetLimitResponse {
    /**
     * @generated from protobuf field: odds.CompetitionLimit global_limit = 199;
     */
    globalLimit?: CompetitionLimit;
    /**
     * @generated from protobuf field: repeated odds.CompetitionLimit competition_limits = 221;
     */
    competitionLimits: CompetitionLimit[];
    /**
     * @generated from protobuf field: int32 page = 161;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 total_pages = 162;
     */
    totalPages: number;
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.UpdateCompetitionLimit
 */
export interface UpdateCompetitionLimit {
    /**
     * @generated from protobuf field: repeated int32 competition_ids = 41;
     */
    competitionIds: number[];
    /**
     * @generated from protobuf field: double amount = 220;
     */
    amount: number;
    /**
     * @generated from protobuf field: bool is_single = 227;
     */
    isSingle: boolean;
}
/**
 * @generated from protobuf message odds.MarketTemplate
 */
export interface MarketTemplate {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated int32 outcome_ids = 125;
     */
    outcomeIds: number[];
    /**
     * @generated from protobuf field: bool is_active = 126;
     */
    isActive: boolean;
}
/**
 * @generated from protobuf message odds.MarketTemplateResponse
 */
export interface MarketTemplateResponse {
    /**
     * @generated from protobuf field: repeated odds.MarketTemplate templates = 225;
     */
    templates: MarketTemplate[];
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.ChangeMarketTemplateRequest
 */
export interface ChangeMarketTemplateRequest {
    /**
     * @generated from protobuf field: string event_id = 12;
     */
    eventId: string;
    /**
     * @generated from protobuf field: int32 competition_id = 4;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: int32 market_template_id = 224;
     */
    marketTemplateId: number;
}
/**
 * @generated from protobuf message odds.LandBaseResultsRequest
 */
export interface LandBaseResultsRequest {
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: string search_string = 157;
     */
    searchString: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp date_from = 159;
     */
    dateFrom?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp date_to = 160;
     */
    dateTo?: Timestamp;
    /**
     * @generated from protobuf field: bool esport = 266;
     */
    esport: boolean;
    /**
     * @generated from protobuf field: int32 page = 161;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 per_page = 77;
     */
    perPage: number;
}
/**
 * @generated from protobuf message odds.LandBaseResultEvent
 */
export interface LandBaseResultEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: string landbase_code = 80;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 22;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: common.EventResult result = 145;
     */
    result?: EventResult;
}
/**
 * @generated from protobuf message odds.LandBaseResultsResponse
 */
export interface LandBaseResultsResponse {
    /**
     * @generated from protobuf field: repeated odds.LandBaseResultEvent events = 44;
     */
    events: LandBaseResultEvent[];
    /**
     * @generated from protobuf field: int32 page = 161;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 total_pages = 162;
     */
    totalPages: number;
    /**
     * @generated from protobuf field: common.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.SpecialMarketIds
 */
export interface SpecialMarketIds {
    /**
     * @generated from protobuf field: repeated int32 market_ids = 52;
     */
    marketIds: number[];
}
/**
 * @generated from protobuf message odds.CancelEventOddsRequest
 */
export interface CancelEventOddsRequest {
    /**
     * @generated from protobuf field: string event_id = 1;
     */
    eventId: string;
    /**
     * @generated from protobuf field: common.Error error = 2;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds.OddStatusHistory
 */
export interface OddStatusHistory {
    /**
     * @generated from protobuf field: string odd_id = 1;
     */
    oddId: string;
    /**
     * @generated from protobuf field: string status = 2;
     */
    status: string;
    /**
     * @generated from protobuf field: string previous_status = 3;
     */
    previousStatus: string;
    /**
     * @generated from protobuf field: string field = 4;
     */
    field: string;
    /**
     * @generated from protobuf field: int32 resolved_by = 5;
     */
    resolvedBy: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 6;
     */
    createdAt?: Timestamp;
}
/**
 * @generated from protobuf message odds.GetOddStatusHistoryResponse
 */
export interface GetOddStatusHistoryResponse {
    /**
     * @generated from protobuf field: repeated odds.OddStatusHistory odd_statuses = 89;
     */
    oddStatuses: OddStatusHistory[];
}
/**
 * @generated from protobuf message odds.FeaturedEvent
 */
export interface FeaturedEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message odds.FeaturedEvents
 */
export interface FeaturedEvents {
    /**
     * @generated from protobuf field: repeated odds.Event events = 1;
     */
    events: Event[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetActiveLBCodesRequest$Type extends MessageType<GetActiveLBCodesRequest> {
    constructor() {
        super("odds.GetActiveLBCodesRequest", [
            { no: 1, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetActiveLBCodesRequest>): GetActiveLBCodesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetActiveLBCodesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetActiveLBCodesRequest): GetActiveLBCodesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 1:
                    message.sportId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetActiveLBCodesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 1; */
        if (message.sportId !== 0)
            writer.tag(1, WireType.Varint).int32(message.sportId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetActiveLBCodesRequest
 */
export const GetActiveLBCodesRequest = new GetActiveLBCodesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActiveLBCodesResponse$Type extends MessageType<GetActiveLBCodesResponse> {
    constructor() {
        super("odds.GetActiveLBCodesResponse", [
            { no: 1, name: "lbcodes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetActiveLBCodesResponse>): GetActiveLBCodesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.lbcodes = [];
        if (value !== undefined)
            reflectionMergePartial<GetActiveLBCodesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetActiveLBCodesResponse): GetActiveLBCodesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string lbcodes */ 1:
                    message.lbcodes.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetActiveLBCodesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string lbcodes = 1; */
        for (let i = 0; i < message.lbcodes.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.lbcodes[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetActiveLBCodesResponse
 */
export const GetActiveLBCodesResponse = new GetActiveLBCodesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignLandBaseCodeRequest$Type extends MessageType<AssignLandBaseCodeRequest> {
    constructor() {
        super("odds.AssignLandBaseCodeRequest", [
            { no: 1, name: "provider_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "competition_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "filter", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<AssignLandBaseCodeRequest>): AssignLandBaseCodeRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.providerId = 0;
        message.sportId = 0;
        message.competitionIds = [];
        message.competitionId = 0;
        message.eventId = "";
        if (value !== undefined)
            reflectionMergePartial<AssignLandBaseCodeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssignLandBaseCodeRequest): AssignLandBaseCodeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 provider_id */ 1:
                    message.providerId = reader.int32();
                    break;
                case /* int32 sport_id */ 2:
                    message.sportId = reader.int32();
                    break;
                case /* repeated int32 competition_ids */ 6:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.competitionIds.push(reader.int32());
                    else
                        message.competitionIds.push(reader.int32());
                    break;
                case /* int32 competition_id */ 3:
                    message.competitionId = reader.int32();
                    break;
                case /* string event_id */ 4:
                    message.eventId = reader.string();
                    break;
                case /* odds.Filter filter */ 5:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssignLandBaseCodeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 provider_id = 1; */
        if (message.providerId !== 0)
            writer.tag(1, WireType.Varint).int32(message.providerId);
        /* int32 sport_id = 2; */
        if (message.sportId !== 0)
            writer.tag(2, WireType.Varint).int32(message.sportId);
        /* repeated int32 competition_ids = 6; */
        if (message.competitionIds.length) {
            writer.tag(6, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.competitionIds.length; i++)
                writer.int32(message.competitionIds[i]);
            writer.join();
        }
        /* int32 competition_id = 3; */
        if (message.competitionId !== 0)
            writer.tag(3, WireType.Varint).int32(message.competitionId);
        /* string event_id = 4; */
        if (message.eventId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.eventId);
        /* odds.Filter filter = 5; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.AssignLandBaseCodeRequest
 */
export const AssignLandBaseCodeRequest = new AssignLandBaseCodeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignLandBaseCodeResponse$Type extends MessageType<AssignLandBaseCodeResponse> {
    constructor() {
        super("odds.AssignLandBaseCodeResponse", [
            { no: 1, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event },
            { no: 2, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<AssignLandBaseCodeResponse>): AssignLandBaseCodeResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<AssignLandBaseCodeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssignLandBaseCodeResponse): AssignLandBaseCodeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Event events */ 1:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 2:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssignLandBaseCodeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Event events = 1; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 2; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.AssignLandBaseCodeResponse
 */
export const AssignLandBaseCodeResponse = new AssignLandBaseCodeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LandBaseCounters$Type extends MessageType<LandBaseCounters> {
    constructor() {
        super("odds.LandBaseCounters", [
            { no: 1, name: "counters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LandBaseCounter }
        ]);
    }
    create(value?: PartialMessage<LandBaseCounters>): LandBaseCounters {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.counters = [];
        if (value !== undefined)
            reflectionMergePartial<LandBaseCounters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LandBaseCounters): LandBaseCounters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.LandBaseCounter counters */ 1:
                    message.counters.push(LandBaseCounter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LandBaseCounters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.LandBaseCounter counters = 1; */
        for (let i = 0; i < message.counters.length; i++)
            LandBaseCounter.internalBinaryWrite(message.counters[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LandBaseCounters
 */
export const LandBaseCounters = new LandBaseCounters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LandBaseCounter$Type extends MessageType<LandBaseCounter> {
    constructor() {
        super("odds.LandBaseCounter", [
            { no: 1, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "min_value", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "max_value", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "next_value", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<LandBaseCounter>): LandBaseCounter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        message.minValue = 0;
        message.maxValue = 0;
        message.nextValue = 0;
        if (value !== undefined)
            reflectionMergePartial<LandBaseCounter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LandBaseCounter): LandBaseCounter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 1:
                    message.sportId = reader.int32();
                    break;
                case /* int32 min_value */ 2:
                    message.minValue = reader.int32();
                    break;
                case /* int32 max_value */ 3:
                    message.maxValue = reader.int32();
                    break;
                case /* int32 next_value */ 4:
                    message.nextValue = reader.int32();
                    break;
                case /* common.Error error */ 5:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LandBaseCounter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 1; */
        if (message.sportId !== 0)
            writer.tag(1, WireType.Varint).int32(message.sportId);
        /* int32 min_value = 2; */
        if (message.minValue !== 0)
            writer.tag(2, WireType.Varint).int32(message.minValue);
        /* int32 max_value = 3; */
        if (message.maxValue !== 0)
            writer.tag(3, WireType.Varint).int32(message.maxValue);
        /* int32 next_value = 4; */
        if (message.nextValue !== 0)
            writer.tag(4, WireType.Varint).int32(message.nextValue);
        /* common.Error error = 5; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LandBaseCounter
 */
export const LandBaseCounter = new LandBaseCounter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DuplicatesRequest$Type extends MessageType<DuplicatesRequest> {
    constructor() {
        super("odds.DuplicatesRequest", [
            { no: 1, name: "provider_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DuplicatesRequest>): DuplicatesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.providerId = 0;
        if (value !== undefined)
            reflectionMergePartial<DuplicatesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DuplicatesRequest): DuplicatesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 provider_id */ 1:
                    message.providerId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DuplicatesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 provider_id = 1; */
        if (message.providerId !== 0)
            writer.tag(1, WireType.Varint).int32(message.providerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.DuplicatesRequest
 */
export const DuplicatesRequest = new DuplicatesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DuplicatesResponse$Type extends MessageType<DuplicatesResponse> {
    constructor() {
        super("odds.DuplicatesResponse", [
            { no: 1, name: "landbase_code_map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => EventList } },
            { no: 5, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<DuplicatesResponse>): DuplicatesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.landbaseCodeMap = {};
        if (value !== undefined)
            reflectionMergePartial<DuplicatesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DuplicatesResponse): DuplicatesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, odds.EventList> landbase_code_map */ 1:
                    this.binaryReadMap1(message.landbaseCodeMap, reader, options);
                    break;
                case /* common.Error error */ 5:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: DuplicatesResponse["landbaseCodeMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof DuplicatesResponse["landbaseCodeMap"] | undefined, val: DuplicatesResponse["landbaseCodeMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = EventList.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field odds.DuplicatesResponse.landbase_code_map");
            }
        }
        map[key ?? ""] = val ?? EventList.create();
    }
    internalBinaryWrite(message: DuplicatesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, odds.EventList> landbase_code_map = 1; */
        for (let k of globalThis.Object.keys(message.landbaseCodeMap)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            EventList.internalBinaryWrite(message.landbaseCodeMap[k], writer, options);
            writer.join().join();
        }
        /* common.Error error = 5; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.DuplicatesResponse
 */
export const DuplicatesResponse = new DuplicatesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventList$Type extends MessageType<EventList> {
    constructor() {
        super("odds.EventList", [
            { no: 1, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event }
        ]);
    }
    create(value?: PartialMessage<EventList>): EventList {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<EventList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventList): EventList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Event events */ 1:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Event events = 1; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.EventList
 */
export const EventList = new EventList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsCreateOfferResponse$Type extends MessageType<GetEventsCreateOfferResponse> {
    constructor() {
        super("odds.GetEventsCreateOfferResponse", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "total_pages", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "competitions", kind: "map", K: 5 /*ScalarType.INT32*/, V: { kind: "message", T: () => Competition } }
        ]);
    }
    create(value?: PartialMessage<GetEventsCreateOfferResponse>): GetEventsCreateOfferResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.totalPages = 0;
        message.competitions = {};
        if (value !== undefined)
            reflectionMergePartial<GetEventsCreateOfferResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsCreateOfferResponse): GetEventsCreateOfferResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 total_pages */ 2:
                    message.totalPages = reader.int32();
                    break;
                case /* map<int32, odds.Competition> competitions */ 3:
                    this.binaryReadMap3(message.competitions, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: GetEventsCreateOfferResponse["competitions"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetEventsCreateOfferResponse["competitions"] | undefined, val: GetEventsCreateOfferResponse["competitions"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = Competition.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field odds.GetEventsCreateOfferResponse.competitions");
            }
        }
        map[key ?? 0] = val ?? Competition.create();
    }
    internalBinaryWrite(message: GetEventsCreateOfferResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 total_pages = 2; */
        if (message.totalPages !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalPages);
        /* map<int32, odds.Competition> competitions = 3; */
        for (let k of globalThis.Object.keys(message.competitions)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k));
            writer.tag(2, WireType.LengthDelimited).fork();
            Competition.internalBinaryWrite(message.competitions[k as any], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetEventsCreateOfferResponse
 */
export const GetEventsCreateOfferResponse = new GetEventsCreateOfferResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMinLimitRequest$Type extends MessageType<GetMinLimitRequest> {
    constructor() {
        super("odds.GetMinLimitRequest", [
            { no: 1, name: "odds_map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => EventOutcome } }
        ]);
    }
    create(value?: PartialMessage<GetMinLimitRequest>): GetMinLimitRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.oddsMap = {};
        if (value !== undefined)
            reflectionMergePartial<GetMinLimitRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMinLimitRequest): GetMinLimitRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, odds.EventOutcome> odds_map */ 1:
                    this.binaryReadMap1(message.oddsMap, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetMinLimitRequest["oddsMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetMinLimitRequest["oddsMap"] | undefined, val: GetMinLimitRequest["oddsMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = EventOutcome.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field odds.GetMinLimitRequest.odds_map");
            }
        }
        map[key ?? ""] = val ?? EventOutcome.create();
    }
    internalBinaryWrite(message: GetMinLimitRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, odds.EventOutcome> odds_map = 1; */
        for (let k of globalThis.Object.keys(message.oddsMap)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            EventOutcome.internalBinaryWrite(message.oddsMap[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetMinLimitRequest
 */
export const GetMinLimitRequest = new GetMinLimitRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventOutcome$Type extends MessageType<EventOutcome> {
    constructor() {
        super("odds.EventOutcome", [
            { no: 1, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<EventOutcome>): EventOutcome {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventId = "";
        message.outcomeId = 0;
        if (value !== undefined)
            reflectionMergePartial<EventOutcome>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventOutcome): EventOutcome {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_id */ 1:
                    message.eventId = reader.string();
                    break;
                case /* int32 outcome_id */ 2:
                    message.outcomeId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventOutcome, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event_id = 1; */
        if (message.eventId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.eventId);
        /* int32 outcome_id = 2; */
        if (message.outcomeId !== 0)
            writer.tag(2, WireType.Varint).int32(message.outcomeId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.EventOutcome
 */
export const EventOutcome = new EventOutcome$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMinLimitResponse$Type extends MessageType<GetMinLimitResponse> {
    constructor() {
        super("odds.GetMinLimitResponse", [
            { no: 1, name: "limits_map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ } }
        ]);
    }
    create(value?: PartialMessage<GetMinLimitResponse>): GetMinLimitResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.limitsMap = {};
        if (value !== undefined)
            reflectionMergePartial<GetMinLimitResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMinLimitResponse): GetMinLimitResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, double> limits_map */ 1:
                    this.binaryReadMap1(message.limitsMap, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetMinLimitResponse["limitsMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetMinLimitResponse["limitsMap"] | undefined, val: GetMinLimitResponse["limitsMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.double();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field odds.GetMinLimitResponse.limits_map");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: GetMinLimitResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, double> limits_map = 1; */
        for (let k of globalThis.Object.keys(message.limitsMap))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Bit64).double(message.limitsMap[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetMinLimitResponse
 */
export const GetMinLimitResponse = new GetMinLimitResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateEventResultRequest$Type extends MessageType<UpdateEventResultRequest> {
    constructor() {
        super("odds.UpdateEventResultRequest", [
            { no: 1, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "resolve", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateEventResultRequest>): UpdateEventResultRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventId = "";
        message.result = "";
        message.resolve = false;
        if (value !== undefined)
            reflectionMergePartial<UpdateEventResultRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateEventResultRequest): UpdateEventResultRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_id */ 1:
                    message.eventId = reader.string();
                    break;
                case /* string result */ 2:
                    message.result = reader.string();
                    break;
                case /* bool resolve */ 3:
                    message.resolve = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateEventResultRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event_id = 1; */
        if (message.eventId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.eventId);
        /* string result = 2; */
        if (message.result !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.result);
        /* bool resolve = 3; */
        if (message.resolve !== false)
            writer.tag(3, WireType.Varint).bool(message.resolve);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.UpdateEventResultRequest
 */
export const UpdateEventResultRequest = new UpdateEventResultRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeCancelRequest$Type extends MessageType<TimeCancelRequest> {
    constructor() {
        super("odds.TimeCancelRequest", [
            { no: 1, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "timestamp", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<TimeCancelRequest>): TimeCancelRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventId = "";
        message.timestamp = 0;
        if (value !== undefined)
            reflectionMergePartial<TimeCancelRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeCancelRequest): TimeCancelRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_id */ 1:
                    message.eventId = reader.string();
                    break;
                case /* int32 timestamp */ 2:
                    message.timestamp = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeCancelRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event_id = 1; */
        if (message.eventId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.eventId);
        /* int32 timestamp = 2; */
        if (message.timestamp !== 0)
            writer.tag(2, WireType.Varint).int32(message.timestamp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.TimeCancelRequest
 */
export const TimeCancelRequest = new TimeCancelRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompetitionsForLinkingRequest$Type extends MessageType<CompetitionsForLinkingRequest> {
    constructor() {
        super("odds.CompetitionsForLinkingRequest", [
            { no: 1, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "search_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "link_filter", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CompetitionsForLinkingRequest>): CompetitionsForLinkingRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        message.locationId = 0;
        message.searchString = "";
        message.linkFilter = "";
        message.page = 0;
        if (value !== undefined)
            reflectionMergePartial<CompetitionsForLinkingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompetitionsForLinkingRequest): CompetitionsForLinkingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 1:
                    message.sportId = reader.int32();
                    break;
                case /* int32 location_id */ 2:
                    message.locationId = reader.int32();
                    break;
                case /* string search_string */ 3:
                    message.searchString = reader.string();
                    break;
                case /* string link_filter */ 4:
                    message.linkFilter = reader.string();
                    break;
                case /* int32 page */ 5:
                    message.page = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompetitionsForLinkingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 1; */
        if (message.sportId !== 0)
            writer.tag(1, WireType.Varint).int32(message.sportId);
        /* int32 location_id = 2; */
        if (message.locationId !== 0)
            writer.tag(2, WireType.Varint).int32(message.locationId);
        /* string search_string = 3; */
        if (message.searchString !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.searchString);
        /* string link_filter = 4; */
        if (message.linkFilter !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.linkFilter);
        /* int32 page = 5; */
        if (message.page !== 0)
            writer.tag(5, WireType.Varint).int32(message.page);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.CompetitionsForLinkingRequest
 */
export const CompetitionsForLinkingRequest = new CompetitionsForLinkingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompetitionsForLinkingResponse$Type extends MessageType<CompetitionsForLinkingResponse> {
    constructor() {
        super("odds.CompetitionsForLinkingResponse", [
            { no: 1, name: "competitions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CompetitionForLinking },
            { no: 2, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "page_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CompetitionsForLinkingResponse>): CompetitionsForLinkingResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.competitions = [];
        message.page = 0;
        message.pageCount = 0;
        if (value !== undefined)
            reflectionMergePartial<CompetitionsForLinkingResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompetitionsForLinkingResponse): CompetitionsForLinkingResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.CompetitionForLinking competitions */ 1:
                    message.competitions.push(CompetitionForLinking.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 page */ 2:
                    message.page = reader.int32();
                    break;
                case /* int32 page_count */ 3:
                    message.pageCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompetitionsForLinkingResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.CompetitionForLinking competitions = 1; */
        for (let i = 0; i < message.competitions.length; i++)
            CompetitionForLinking.internalBinaryWrite(message.competitions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int32(message.page);
        /* int32 page_count = 3; */
        if (message.pageCount !== 0)
            writer.tag(3, WireType.Varint).int32(message.pageCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.CompetitionsForLinkingResponse
 */
export const CompetitionsForLinkingResponse = new CompetitionsForLinkingResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompetitionForLinking$Type extends MessageType<CompetitionForLinking> {
    constructor() {
        super("odds.CompetitionForLinking", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "live_competition", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProviderCompetition },
            { no: 5, name: "prematch_competition", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProviderCompetition },
            { no: 6, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "standings_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompetitionForLinking>): CompetitionForLinking {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.location = "";
        message.liveCompetition = [];
        message.prematchCompetition = [];
        message.shortName = "";
        message.locationId = 0;
        message.standingsUrl = "";
        if (value !== undefined)
            reflectionMergePartial<CompetitionForLinking>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompetitionForLinking): CompetitionForLinking {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string location */ 3:
                    message.location = reader.string();
                    break;
                case /* repeated odds.ProviderCompetition live_competition */ 4:
                    message.liveCompetition.push(ProviderCompetition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated odds.ProviderCompetition prematch_competition */ 5:
                    message.prematchCompetition.push(ProviderCompetition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string short_name */ 6:
                    message.shortName = reader.string();
                    break;
                case /* int32 location_id */ 7:
                    message.locationId = reader.int32();
                    break;
                case /* string standings_url */ 8:
                    message.standingsUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompetitionForLinking, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string location = 3; */
        if (message.location !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.location);
        /* repeated odds.ProviderCompetition live_competition = 4; */
        for (let i = 0; i < message.liveCompetition.length; i++)
            ProviderCompetition.internalBinaryWrite(message.liveCompetition[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated odds.ProviderCompetition prematch_competition = 5; */
        for (let i = 0; i < message.prematchCompetition.length; i++)
            ProviderCompetition.internalBinaryWrite(message.prematchCompetition[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string short_name = 6; */
        if (message.shortName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.shortName);
        /* int32 location_id = 7; */
        if (message.locationId !== 0)
            writer.tag(7, WireType.Varint).int32(message.locationId);
        /* string standings_url = 8; */
        if (message.standingsUrl !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.standingsUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.CompetitionForLinking
 */
export const CompetitionForLinking = new CompetitionForLinking$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProviderCompetition$Type extends MessageType<ProviderCompetition> {
    constructor() {
        super("odds.ProviderCompetition", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 46, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 129, name: "factory_competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 130, name: "factory_competition_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProviderCompetition>): ProviderCompetition {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.location = "";
        message.factoryCompetitionId = 0;
        message.factoryCompetitionName = "";
        if (value !== undefined)
            reflectionMergePartial<ProviderCompetition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProviderCompetition): ProviderCompetition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string location */ 46:
                    message.location = reader.string();
                    break;
                case /* int32 factory_competition_id */ 129:
                    message.factoryCompetitionId = reader.int32();
                    break;
                case /* string factory_competition_name */ 130:
                    message.factoryCompetitionName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProviderCompetition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string location = 46; */
        if (message.location !== "")
            writer.tag(46, WireType.LengthDelimited).string(message.location);
        /* int32 factory_competition_id = 129; */
        if (message.factoryCompetitionId !== 0)
            writer.tag(129, WireType.Varint).int32(message.factoryCompetitionId);
        /* string factory_competition_name = 130; */
        if (message.factoryCompetitionName !== "")
            writer.tag(130, WireType.LengthDelimited).string(message.factoryCompetitionName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.ProviderCompetition
 */
export const ProviderCompetition = new ProviderCompetition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PASport$Type extends MessageType<PASport> {
    constructor() {
        super("odds.PASport", [
            { no: 1, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PASport>): PASport {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.competitionId = 0;
        message.sportId = 0;
        message.type = "";
        if (value !== undefined)
            reflectionMergePartial<PASport>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PASport): PASport {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 competition_id */ 1:
                    message.competitionId = reader.int32();
                    break;
                case /* int32 sport_id */ 2:
                    message.sportId = reader.int32();
                    break;
                case /* string type */ 3:
                    message.type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PASport, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 competition_id = 1; */
        if (message.competitionId !== 0)
            writer.tag(1, WireType.Varint).int32(message.competitionId);
        /* int32 sport_id = 2; */
        if (message.sportId !== 0)
            writer.tag(2, WireType.Varint).int32(message.sportId);
        /* string type = 3; */
        if (message.type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.PASport
 */
export const PASport = new PASport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BlockEventReasons$Type extends MessageType<BlockEventReasons> {
    constructor() {
        super("odds.BlockEventReasons", [
            { no: 1, name: "reasons", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BlockEventReasons>): BlockEventReasons {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.reasons = [];
        if (value !== undefined)
            reflectionMergePartial<BlockEventReasons>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BlockEventReasons): BlockEventReasons {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string reasons */ 1:
                    message.reasons.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BlockEventReasons, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string reasons = 1; */
        for (let i = 0; i < message.reasons.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.reasons[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.BlockEventReasons
 */
export const BlockEventReasons = new BlockEventReasons$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BlockEventRequest$Type extends MessageType<BlockEventRequest> {
    constructor() {
        super("odds.BlockEventRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BlockEventRequest>): BlockEventRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.reason = "";
        if (value !== undefined)
            reflectionMergePartial<BlockEventRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BlockEventRequest): BlockEventRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string reason */ 2:
                    message.reason = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BlockEventRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string reason = 2; */
        if (message.reason !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.reason);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.BlockEventRequest
 */
export const BlockEventRequest = new BlockEventRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsForLinkingRequest$Type extends MessageType<GetEventsForLinkingRequest> {
    constructor() {
        super("odds.GetEventsForLinkingRequest", [
            { no: 1, name: "search_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetEventsForLinkingRequest>): GetEventsForLinkingRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.searchString = "";
        message.sportId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetEventsForLinkingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsForLinkingRequest): GetEventsForLinkingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string search_string */ 1:
                    message.searchString = reader.string();
                    break;
                case /* int32 sport_id */ 2:
                    message.sportId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsForLinkingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string search_string = 1; */
        if (message.searchString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.searchString);
        /* int32 sport_id = 2; */
        if (message.sportId !== 0)
            writer.tag(2, WireType.Varint).int32(message.sportId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetEventsForLinkingRequest
 */
export const GetEventsForLinkingRequest = new GetEventsForLinkingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateLandBaseCodes$Type extends MessageType<UpdateLandBaseCodes> {
    constructor() {
        super("odds.UpdateLandBaseCodes", [
            { no: 12, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateLandBaseCodes>): UpdateLandBaseCodes {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventId = "";
        message.competitionId = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateLandBaseCodes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateLandBaseCodes): UpdateLandBaseCodes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_id */ 12:
                    message.eventId = reader.string();
                    break;
                case /* int32 competition_id */ 4:
                    message.competitionId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateLandBaseCodes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event_id = 12; */
        if (message.eventId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.eventId);
        /* int32 competition_id = 4; */
        if (message.competitionId !== 0)
            writer.tag(4, WireType.Varint).int32(message.competitionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.UpdateLandBaseCodes
 */
export const UpdateLandBaseCodes = new UpdateLandBaseCodes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IsonisOutcomeArgs$Type extends MessageType<IsonisOutcomeArgs> {
    constructor() {
        super("odds.IsonisOutcomeArgs", [
            { no: 1, name: "outcome_args", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => IsonisOutArg }
        ]);
    }
    create(value?: PartialMessage<IsonisOutcomeArgs>): IsonisOutcomeArgs {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.outcomeArgs = [];
        if (value !== undefined)
            reflectionMergePartial<IsonisOutcomeArgs>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IsonisOutcomeArgs): IsonisOutcomeArgs {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.IsonisOutArg outcome_args */ 1:
                    message.outcomeArgs.push(IsonisOutArg.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IsonisOutcomeArgs, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.IsonisOutArg outcome_args = 1; */
        for (let i = 0; i < message.outcomeArgs.length; i++)
            IsonisOutArg.internalBinaryWrite(message.outcomeArgs[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.IsonisOutcomeArgs
 */
export const IsonisOutcomeArgs = new IsonisOutcomeArgs$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IsonisOutArg$Type extends MessageType<IsonisOutArg> {
    constructor() {
        super("odds.IsonisOutArg", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "isonis_results_prematch_args", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "isonis_results_live_args", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "isonis_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IsonisOutArg>): IsonisOutArg {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.isonisResultsPrematchArgs = [];
        message.isonisResultsLiveArgs = [];
        message.isonisName = "";
        if (value !== undefined)
            reflectionMergePartial<IsonisOutArg>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IsonisOutArg): IsonisOutArg {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* repeated string isonis_results_prematch_args */ 2:
                    message.isonisResultsPrematchArgs.push(reader.string());
                    break;
                case /* repeated string isonis_results_live_args */ 3:
                    message.isonisResultsLiveArgs.push(reader.string());
                    break;
                case /* string isonis_name */ 4:
                    message.isonisName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IsonisOutArg, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* repeated string isonis_results_prematch_args = 2; */
        for (let i = 0; i < message.isonisResultsPrematchArgs.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.isonisResultsPrematchArgs[i]);
        /* repeated string isonis_results_live_args = 3; */
        for (let i = 0; i < message.isonisResultsLiveArgs.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.isonisResultsLiveArgs[i]);
        /* string isonis_name = 4; */
        if (message.isonisName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.isonisName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.IsonisOutArg
 */
export const IsonisOutArg = new IsonisOutArg$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LandBaseCode$Type extends MessageType<LandBaseCode> {
    constructor() {
        super("odds.LandBaseCode", [
            { no: 80, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LandBaseCode>): LandBaseCode {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.landbaseCode = "";
        if (value !== undefined)
            reflectionMergePartial<LandBaseCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LandBaseCode): LandBaseCode {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string landbase_code */ 80:
                    message.landbaseCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LandBaseCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string landbase_code = 80; */
        if (message.landbaseCode !== "")
            writer.tag(80, WireType.LengthDelimited).string(message.landbaseCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LandBaseCode
 */
export const LandBaseCode = new LandBaseCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelledEventsRequest$Type extends MessageType<CancelledEventsRequest> {
    constructor() {
        super("odds.CancelledEventsRequest", [
            { no: 161, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 157, name: "search_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 159, name: "date_from", kind: "message", T: () => Timestamp },
            { no: 160, name: "date_to", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<CancelledEventsRequest>): CancelledEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.searchString = "";
        if (value !== undefined)
            reflectionMergePartial<CancelledEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelledEventsRequest): CancelledEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 161:
                    message.page = reader.int32();
                    break;
                case /* string search_string */ 157:
                    message.searchString = reader.string();
                    break;
                case /* google.protobuf.Timestamp date_from */ 159:
                    message.dateFrom = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dateFrom);
                    break;
                case /* google.protobuf.Timestamp date_to */ 160:
                    message.dateTo = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dateTo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelledEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 161; */
        if (message.page !== 0)
            writer.tag(161, WireType.Varint).int32(message.page);
        /* string search_string = 157; */
        if (message.searchString !== "")
            writer.tag(157, WireType.LengthDelimited).string(message.searchString);
        /* google.protobuf.Timestamp date_from = 159; */
        if (message.dateFrom)
            Timestamp.internalBinaryWrite(message.dateFrom, writer.tag(159, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp date_to = 160; */
        if (message.dateTo)
            Timestamp.internalBinaryWrite(message.dateTo, writer.tag(160, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.CancelledEventsRequest
 */
export const CancelledEventsRequest = new CancelledEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelledEventsResponse$Type extends MessageType<CancelledEventsResponse> {
    constructor() {
        super("odds.CancelledEventsResponse", [
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event },
            { no: 161, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 162, name: "total_pages", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<CancelledEventsResponse>): CancelledEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        message.page = 0;
        message.totalPages = 0;
        if (value !== undefined)
            reflectionMergePartial<CancelledEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelledEventsResponse): CancelledEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Event events */ 17:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 page */ 161:
                    message.page = reader.int32();
                    break;
                case /* int32 total_pages */ 162:
                    message.totalPages = reader.int32();
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelledEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Event events = 17; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* int32 page = 161; */
        if (message.page !== 0)
            writer.tag(161, WireType.Varint).int32(message.page);
        /* int32 total_pages = 162; */
        if (message.totalPages !== 0)
            writer.tag(162, WireType.Varint).int32(message.totalPages);
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.CancelledEventsResponse
 */
export const CancelledEventsResponse = new CancelledEventsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDailyListRequest$Type extends MessageType<GetDailyListRequest> {
    constructor() {
        super("odds.GetDailyListRequest", [
            { no: 182, name: "sport_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 1, name: "full_offer", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetDailyListRequest>): GetDailyListRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportIds = [];
        message.fullOffer = false;
        if (value !== undefined)
            reflectionMergePartial<GetDailyListRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDailyListRequest): GetDailyListRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 sport_ids */ 182:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.sportIds.push(reader.int32());
                    else
                        message.sportIds.push(reader.int32());
                    break;
                case /* bool full_offer */ 1:
                    message.fullOffer = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDailyListRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int32 sport_ids = 182; */
        if (message.sportIds.length) {
            writer.tag(182, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.sportIds.length; i++)
                writer.int32(message.sportIds[i]);
            writer.join();
        }
        /* bool full_offer = 1; */
        if (message.fullOffer !== false)
            writer.tag(1, WireType.Varint).bool(message.fullOffer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetDailyListRequest
 */
export const GetDailyListRequest = new GetDailyListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDailyListResponse$Type extends MessageType<GetDailyListResponse> {
    constructor() {
        super("odds.GetDailyListResponse", [
            { no: 269, name: "sports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DailySport }
        ]);
    }
    create(value?: PartialMessage<GetDailyListResponse>): GetDailyListResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sports = [];
        if (value !== undefined)
            reflectionMergePartial<GetDailyListResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDailyListResponse): GetDailyListResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.DailySport sports */ 269:
                    message.sports.push(DailySport.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDailyListResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.DailySport sports = 269; */
        for (let i = 0; i < message.sports.length; i++)
            DailySport.internalBinaryWrite(message.sports[i], writer.tag(269, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetDailyListResponse
 */
export const GetDailyListResponse = new GetDailyListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DailySport$Type extends MessageType<DailySport> {
    constructor() {
        super("odds.DailySport", [
            { no: 5, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 270, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DailyEvent }
        ]);
    }
    create(value?: PartialMessage<DailySport>): DailySport {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<DailySport>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DailySport): DailySport {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 5:
                    message.id = reader.int32();
                    break;
                case /* repeated odds.DailyEvent events */ 270:
                    message.events.push(DailyEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DailySport, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 5; */
        if (message.id !== 0)
            writer.tag(5, WireType.Varint).int32(message.id);
        /* repeated odds.DailyEvent events = 270; */
        for (let i = 0; i < message.events.length; i++)
            DailyEvent.internalBinaryWrite(message.events[i], writer.tag(270, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.DailySport
 */
export const DailySport = new DailySport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DailyEvent$Type extends MessageType<DailyEvent> {
    constructor() {
        super("odds.DailyEvent", [
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 127, name: "competition_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "start", kind: "message", T: () => Timestamp },
            { no: 80, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 271, name: "markets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DailyMarkets }
        ]);
    }
    create(value?: PartialMessage<DailyEvent>): DailyEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.competitionName = "";
        message.landbaseCode = "";
        message.markets = [];
        if (value !== undefined)
            reflectionMergePartial<DailyEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DailyEvent): DailyEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string competition_name */ 127:
                    message.competitionName = reader.string();
                    break;
                case /* google.protobuf.Timestamp start */ 22:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* string landbase_code */ 80:
                    message.landbaseCode = reader.string();
                    break;
                case /* repeated odds.DailyMarkets markets */ 271:
                    message.markets.push(DailyMarkets.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DailyEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string competition_name = 127; */
        if (message.competitionName !== "")
            writer.tag(127, WireType.LengthDelimited).string(message.competitionName);
        /* google.protobuf.Timestamp start = 22; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* string landbase_code = 80; */
        if (message.landbaseCode !== "")
            writer.tag(80, WireType.LengthDelimited).string(message.landbaseCode);
        /* repeated odds.DailyMarkets markets = 271; */
        for (let i = 0; i < message.markets.length; i++)
            DailyMarkets.internalBinaryWrite(message.markets[i], writer.tag(271, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.DailyEvent
 */
export const DailyEvent = new DailyEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DailyMarkets$Type extends MessageType<DailyMarkets> {
    constructor() {
        super("odds.DailyMarkets", [
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 272, name: "outcomes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DailyOutcomes }
        ]);
    }
    create(value?: PartialMessage<DailyMarkets>): DailyMarkets {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.outcomes = [];
        if (value !== undefined)
            reflectionMergePartial<DailyMarkets>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DailyMarkets): DailyMarkets {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* repeated odds.DailyOutcomes outcomes */ 272:
                    message.outcomes.push(DailyOutcomes.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DailyMarkets, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* repeated odds.DailyOutcomes outcomes = 272; */
        for (let i = 0; i < message.outcomes.length; i++)
            DailyOutcomes.internalBinaryWrite(message.outcomes[i], writer.tag(272, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.DailyMarkets
 */
export const DailyMarkets = new DailyMarkets$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DailyOutcomes$Type extends MessageType<DailyOutcomes> {
    constructor() {
        super("odds.DailyOutcomes", [
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<DailyOutcomes>): DailyOutcomes {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.value = 0;
        if (value !== undefined)
            reflectionMergePartial<DailyOutcomes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DailyOutcomes): DailyOutcomes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* double value */ 8:
                    message.value = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DailyOutcomes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* double value = 8; */
        if (message.value !== 0)
            writer.tag(8, WireType.Bit64).double(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.DailyOutcomes
 */
export const DailyOutcomes = new DailyOutcomes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LiveOutcomeOrderResponse$Type extends MessageType<LiveOutcomeOrderResponse> {
    constructor() {
        super("odds.LiveOutcomeOrderResponse", [
            { no: 1, name: "sports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LiveOrderSport }
        ]);
    }
    create(value?: PartialMessage<LiveOutcomeOrderResponse>): LiveOutcomeOrderResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sports = [];
        if (value !== undefined)
            reflectionMergePartial<LiveOutcomeOrderResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LiveOutcomeOrderResponse): LiveOutcomeOrderResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.LiveOrderSport sports */ 1:
                    message.sports.push(LiveOrderSport.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LiveOutcomeOrderResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.LiveOrderSport sports = 1; */
        for (let i = 0; i < message.sports.length; i++)
            LiveOrderSport.internalBinaryWrite(message.sports[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LiveOutcomeOrderResponse
 */
export const LiveOutcomeOrderResponse = new LiveOutcomeOrderResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LiveOrderSport$Type extends MessageType<LiveOrderSport> {
    constructor() {
        super("odds.LiveOrderSport", [
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "live_markets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LiveMarket }
        ]);
    }
    create(value?: PartialMessage<LiveOrderSport>): LiveOrderSport {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        message.liveMarkets = [];
        if (value !== undefined)
            reflectionMergePartial<LiveOrderSport>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LiveOrderSport): LiveOrderSport {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* repeated odds.LiveMarket live_markets */ 2:
                    message.liveMarkets.push(LiveMarket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LiveOrderSport, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* repeated odds.LiveMarket live_markets = 2; */
        for (let i = 0; i < message.liveMarkets.length; i++)
            LiveMarket.internalBinaryWrite(message.liveMarkets[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LiveOrderSport
 */
export const LiveOrderSport = new LiveOrderSport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LiveMarket$Type extends MessageType<LiveMarket> {
    constructor() {
        super("odds.LiveMarket", [
            { no: 5, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "outcome_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<LiveMarket>): LiveMarket {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.outcomeIds = [];
        if (value !== undefined)
            reflectionMergePartial<LiveMarket>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LiveMarket): LiveMarket {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 5:
                    message.id = reader.int32();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* repeated int32 outcome_ids */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.outcomeIds.push(reader.int32());
                    else
                        message.outcomeIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LiveMarket, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 5; */
        if (message.id !== 0)
            writer.tag(5, WireType.Varint).int32(message.id);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* repeated int32 outcome_ids = 4; */
        if (message.outcomeIds.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.outcomeIds.length; i++)
                writer.int32(message.outcomeIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LiveMarket
 */
export const LiveMarket = new LiveMarket$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncLandBaseRequest$Type extends MessageType<SyncLandBaseRequest> {
    constructor() {
        super("odds.SyncLandBaseRequest", [
            { no: 241, name: "live_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 242, name: "prematch_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 250, name: "outright_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 251, name: "player_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 252, name: "group_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SyncLandBaseRequest>): SyncLandBaseRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.liveIds = [];
        message.prematchIds = [];
        message.outrightIds = [];
        message.playerIds = [];
        message.groupIds = [];
        if (value !== undefined)
            reflectionMergePartial<SyncLandBaseRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncLandBaseRequest): SyncLandBaseRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string live_ids */ 241:
                    message.liveIds.push(reader.string());
                    break;
                case /* repeated string prematch_ids */ 242:
                    message.prematchIds.push(reader.string());
                    break;
                case /* repeated string outright_ids */ 250:
                    message.outrightIds.push(reader.string());
                    break;
                case /* repeated string player_ids */ 251:
                    message.playerIds.push(reader.string());
                    break;
                case /* repeated string group_ids */ 252:
                    message.groupIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncLandBaseRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string live_ids = 241; */
        for (let i = 0; i < message.liveIds.length; i++)
            writer.tag(241, WireType.LengthDelimited).string(message.liveIds[i]);
        /* repeated string prematch_ids = 242; */
        for (let i = 0; i < message.prematchIds.length; i++)
            writer.tag(242, WireType.LengthDelimited).string(message.prematchIds[i]);
        /* repeated string outright_ids = 250; */
        for (let i = 0; i < message.outrightIds.length; i++)
            writer.tag(250, WireType.LengthDelimited).string(message.outrightIds[i]);
        /* repeated string player_ids = 251; */
        for (let i = 0; i < message.playerIds.length; i++)
            writer.tag(251, WireType.LengthDelimited).string(message.playerIds[i]);
        /* repeated string group_ids = 252; */
        for (let i = 0; i < message.groupIds.length; i++)
            writer.tag(252, WireType.LengthDelimited).string(message.groupIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SyncLandBaseRequest
 */
export const SyncLandBaseRequest = new SyncLandBaseRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncLandBaseResponse$Type extends MessageType<SyncLandBaseResponse> {
    constructor() {
        super("odds.SyncLandBaseResponse", [
            { no: 243, name: "deleted_prematch", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 244, name: "created_prematch", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StreamEvent },
            { no: 245, name: "deleted_live", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 246, name: "created_live", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StreamEvent },
            { no: 253, name: "deleted_outright", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 254, name: "created_outright", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StreamEvent },
            { no: 255, name: "deleted_player", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 256, name: "created_player", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StreamEvent },
            { no: 257, name: "deleted_group", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 258, name: "created_group", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StreamEvent },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<SyncLandBaseResponse>): SyncLandBaseResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deletedPrematch = [];
        message.createdPrematch = [];
        message.deletedLive = [];
        message.createdLive = [];
        message.deletedOutright = [];
        message.createdOutright = [];
        message.deletedPlayer = [];
        message.createdPlayer = [];
        message.deletedGroup = [];
        message.createdGroup = [];
        if (value !== undefined)
            reflectionMergePartial<SyncLandBaseResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncLandBaseResponse): SyncLandBaseResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string deleted_prematch */ 243:
                    message.deletedPrematch.push(reader.string());
                    break;
                case /* repeated odds.StreamEvent created_prematch */ 244:
                    message.createdPrematch.push(StreamEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string deleted_live */ 245:
                    message.deletedLive.push(reader.string());
                    break;
                case /* repeated odds.StreamEvent created_live */ 246:
                    message.createdLive.push(StreamEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string deleted_outright */ 253:
                    message.deletedOutright.push(reader.string());
                    break;
                case /* repeated odds.StreamEvent created_outright */ 254:
                    message.createdOutright.push(StreamEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string deleted_player */ 255:
                    message.deletedPlayer.push(reader.string());
                    break;
                case /* repeated odds.StreamEvent created_player */ 256:
                    message.createdPlayer.push(StreamEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string deleted_group */ 257:
                    message.deletedGroup.push(reader.string());
                    break;
                case /* repeated odds.StreamEvent created_group */ 258:
                    message.createdGroup.push(StreamEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncLandBaseResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string deleted_prematch = 243; */
        for (let i = 0; i < message.deletedPrematch.length; i++)
            writer.tag(243, WireType.LengthDelimited).string(message.deletedPrematch[i]);
        /* repeated odds.StreamEvent created_prematch = 244; */
        for (let i = 0; i < message.createdPrematch.length; i++)
            StreamEvent.internalBinaryWrite(message.createdPrematch[i], writer.tag(244, WireType.LengthDelimited).fork(), options).join();
        /* repeated string deleted_live = 245; */
        for (let i = 0; i < message.deletedLive.length; i++)
            writer.tag(245, WireType.LengthDelimited).string(message.deletedLive[i]);
        /* repeated odds.StreamEvent created_live = 246; */
        for (let i = 0; i < message.createdLive.length; i++)
            StreamEvent.internalBinaryWrite(message.createdLive[i], writer.tag(246, WireType.LengthDelimited).fork(), options).join();
        /* repeated string deleted_outright = 253; */
        for (let i = 0; i < message.deletedOutright.length; i++)
            writer.tag(253, WireType.LengthDelimited).string(message.deletedOutright[i]);
        /* repeated odds.StreamEvent created_outright = 254; */
        for (let i = 0; i < message.createdOutright.length; i++)
            StreamEvent.internalBinaryWrite(message.createdOutright[i], writer.tag(254, WireType.LengthDelimited).fork(), options).join();
        /* repeated string deleted_player = 255; */
        for (let i = 0; i < message.deletedPlayer.length; i++)
            writer.tag(255, WireType.LengthDelimited).string(message.deletedPlayer[i]);
        /* repeated odds.StreamEvent created_player = 256; */
        for (let i = 0; i < message.createdPlayer.length; i++)
            StreamEvent.internalBinaryWrite(message.createdPlayer[i], writer.tag(256, WireType.LengthDelimited).fork(), options).join();
        /* repeated string deleted_group = 257; */
        for (let i = 0; i < message.deletedGroup.length; i++)
            writer.tag(257, WireType.LengthDelimited).string(message.deletedGroup[i]);
        /* repeated odds.StreamEvent created_group = 258; */
        for (let i = 0; i < message.createdGroup.length; i++)
            StreamEvent.internalBinaryWrite(message.createdGroup[i], writer.tag(258, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SyncLandBaseResponse
 */
export const SyncLandBaseResponse = new SyncLandBaseResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMarketTemplatesRequest$Type extends MessageType<GetMarketTemplatesRequest> {
    constructor() {
        super("odds.GetMarketTemplatesRequest", [
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetMarketTemplatesRequest>): GetMarketTemplatesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMarketTemplatesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMarketTemplatesRequest): GetMarketTemplatesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMarketTemplatesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetMarketTemplatesRequest
 */
export const GetMarketTemplatesRequest = new GetMarketTemplatesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LiveDisableStatus$Type extends MessageType<LiveDisableStatus> {
    constructor() {
        super("odds.LiveDisableStatus", [
            { no: 193, name: "disable_all", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 194, name: "disabled_sports", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<LiveDisableStatus>): LiveDisableStatus {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.disableAll = false;
        message.disabledSports = [];
        if (value !== undefined)
            reflectionMergePartial<LiveDisableStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LiveDisableStatus): LiveDisableStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool disable_all */ 193:
                    message.disableAll = reader.bool();
                    break;
                case /* repeated int32 disabled_sports */ 194:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.disabledSports.push(reader.int32());
                    else
                        message.disabledSports.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LiveDisableStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool disable_all = 193; */
        if (message.disableAll !== false)
            writer.tag(193, WireType.Varint).bool(message.disableAll);
        /* repeated int32 disabled_sports = 194; */
        if (message.disabledSports.length) {
            writer.tag(194, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.disabledSports.length; i++)
                writer.int32(message.disabledSports[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LiveDisableStatus
 */
export const LiveDisableStatus = new LiveDisableStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateMissingSlipResultsRequest$Type extends MessageType<UpdateMissingSlipResultsRequest> {
    constructor() {
        super("odds.UpdateMissingSlipResultsRequest", [
            { no: 62, name: "event_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateMissingSlipResultsRequest>): UpdateMissingSlipResultsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventIds = [];
        if (value !== undefined)
            reflectionMergePartial<UpdateMissingSlipResultsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateMissingSlipResultsRequest): UpdateMissingSlipResultsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string event_ids */ 62:
                    message.eventIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateMissingSlipResultsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string event_ids = 62; */
        for (let i = 0; i < message.eventIds.length; i++)
            writer.tag(62, WireType.LengthDelimited).string(message.eventIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.UpdateMissingSlipResultsRequest
 */
export const UpdateMissingSlipResultsRequest = new UpdateMissingSlipResultsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompetitorsRequest$Type extends MessageType<GetCompetitorsRequest> {
    constructor() {
        super("odds.GetCompetitorsRequest", [
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetCompetitorsRequest>): GetCompetitorsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        message.competitionId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetCompetitorsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompetitorsRequest): GetCompetitorsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* int32 competition_id */ 4:
                    message.competitionId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompetitorsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* int32 competition_id = 4; */
        if (message.competitionId !== 0)
            writer.tag(4, WireType.Varint).int32(message.competitionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetCompetitorsRequest
 */
export const GetCompetitorsRequest = new GetCompetitorsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLocationsBoResponse$Type extends MessageType<GetLocationsBoResponse> {
    constructor() {
        super("odds.GetLocationsBoResponse", [
            { no: 23, name: "locations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Location }
        ]);
    }
    create(value?: PartialMessage<GetLocationsBoResponse>): GetLocationsBoResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.locations = [];
        if (value !== undefined)
            reflectionMergePartial<GetLocationsBoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLocationsBoResponse): GetLocationsBoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Location locations */ 23:
                    message.locations.push(Location.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLocationsBoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Location locations = 23; */
        for (let i = 0; i < message.locations.length; i++)
            Location.internalBinaryWrite(message.locations[i], writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetLocationsBoResponse
 */
export const GetLocationsBoResponse = new GetLocationsBoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompetitionsBoResponse$Type extends MessageType<GetCompetitionsBoResponse> {
    constructor() {
        super("odds.GetCompetitionsBoResponse", [
            { no: 25, name: "competitions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Competition },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetCompetitionsBoResponse>): GetCompetitionsBoResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.competitions = [];
        if (value !== undefined)
            reflectionMergePartial<GetCompetitionsBoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompetitionsBoResponse): GetCompetitionsBoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Competition competitions */ 25:
                    message.competitions.push(Competition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompetitionsBoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Competition competitions = 25; */
        for (let i = 0; i < message.competitions.length; i++)
            Competition.internalBinaryWrite(message.competitions[i], writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetCompetitionsBoResponse
 */
export const GetCompetitionsBoResponse = new GetCompetitionsBoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationOrderRequest$Type extends MessageType<LocationOrderRequest> {
    constructor() {
        super("odds.LocationOrderRequest", [
            { no: 1, name: "ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<LocationOrderRequest>): LocationOrderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ids = [];
        if (value !== undefined)
            reflectionMergePartial<LocationOrderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LocationOrderRequest): LocationOrderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ids.push(reader.int32());
                    else
                        message.ids.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LocationOrderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int32 ids = 1; */
        if (message.ids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ids.length; i++)
                writer.int32(message.ids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LocationOrderRequest
 */
export const LocationOrderRequest = new LocationOrderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SportLocationRequest$Type extends MessageType<SportLocationRequest> {
    constructor() {
        super("odds.SportLocationRequest", [
            { no: 1, name: "location", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "sport", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SportLocationRequest>): SportLocationRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.location = 0;
        message.sport = 0;
        if (value !== undefined)
            reflectionMergePartial<SportLocationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SportLocationRequest): SportLocationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 location */ 1:
                    message.location = reader.int32();
                    break;
                case /* int32 sport */ 2:
                    message.sport = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SportLocationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 location = 1; */
        if (message.location !== 0)
            writer.tag(1, WireType.Varint).int32(message.location);
        /* int32 sport = 2; */
        if (message.sport !== 0)
            writer.tag(2, WireType.Varint).int32(message.sport);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SportLocationRequest
 */
export const SportLocationRequest = new SportLocationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateMarketGroupsBoRequest$Type extends MessageType<UpdateMarketGroupsBoRequest> {
    constructor() {
        super("odds.UpdateMarketGroupsBoRequest", [
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 51, name: "market_group_configs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MarketGroupConfig }
        ]);
    }
    create(value?: PartialMessage<UpdateMarketGroupsBoRequest>): UpdateMarketGroupsBoRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        message.marketGroupConfigs = [];
        if (value !== undefined)
            reflectionMergePartial<UpdateMarketGroupsBoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateMarketGroupsBoRequest): UpdateMarketGroupsBoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* repeated odds.MarketGroupConfig market_group_configs */ 51:
                    message.marketGroupConfigs.push(MarketGroupConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateMarketGroupsBoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* repeated odds.MarketGroupConfig market_group_configs = 51; */
        for (let i = 0; i < message.marketGroupConfigs.length; i++)
            MarketGroupConfig.internalBinaryWrite(message.marketGroupConfigs[i], writer.tag(51, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.UpdateMarketGroupsBoRequest
 */
export const UpdateMarketGroupsBoRequest = new UpdateMarketGroupsBoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompetitorsBoResponse$Type extends MessageType<GetCompetitorsBoResponse> {
    constructor() {
        super("odds.GetCompetitorsBoResponse", [
            { no: 44, name: "competitors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Competitor }
        ]);
    }
    create(value?: PartialMessage<GetCompetitorsBoResponse>): GetCompetitorsBoResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.competitors = [];
        if (value !== undefined)
            reflectionMergePartial<GetCompetitorsBoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompetitorsBoResponse): GetCompetitorsBoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Competitor competitors */ 44:
                    message.competitors.push(Competitor.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompetitorsBoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Competitor competitors = 44; */
        for (let i = 0; i < message.competitors.length; i++)
            Competitor.internalBinaryWrite(message.competitors[i], writer.tag(44, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetCompetitorsBoResponse
 */
export const GetCompetitorsBoResponse = new GetCompetitorsBoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSportsBoReponse$Type extends MessageType<GetSportsBoReponse> {
    constructor() {
        super("odds.GetSportsBoReponse", [
            { no: 13, name: "sports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Sport }
        ]);
    }
    create(value?: PartialMessage<GetSportsBoReponse>): GetSportsBoReponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sports = [];
        if (value !== undefined)
            reflectionMergePartial<GetSportsBoReponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSportsBoReponse): GetSportsBoReponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Sport sports */ 13:
                    message.sports.push(Sport.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSportsBoReponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Sport sports = 13; */
        for (let i = 0; i < message.sports.length; i++)
            Sport.internalBinaryWrite(message.sports[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetSportsBoReponse
 */
export const GetSportsBoReponse = new GetSportsBoReponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllMarketGroupsBoRequest$Type extends MessageType<GetAllMarketGroupsBoRequest> {
    constructor() {
        super("odds.GetAllMarketGroupsBoRequest", [
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetAllMarketGroupsBoRequest>): GetAllMarketGroupsBoRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetAllMarketGroupsBoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllMarketGroupsBoRequest): GetAllMarketGroupsBoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllMarketGroupsBoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetAllMarketGroupsBoRequest
 */
export const GetAllMarketGroupsBoRequest = new GetAllMarketGroupsBoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllMarketGroupsBoResponse$Type extends MessageType<GetAllMarketGroupsBoResponse> {
    constructor() {
        super("odds.GetAllMarketGroupsBoResponse", [
            { no: 51, name: "market_group_configs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MarketGroupConfig },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetAllMarketGroupsBoResponse>): GetAllMarketGroupsBoResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.marketGroupConfigs = [];
        if (value !== undefined)
            reflectionMergePartial<GetAllMarketGroupsBoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllMarketGroupsBoResponse): GetAllMarketGroupsBoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.MarketGroupConfig market_group_configs */ 51:
                    message.marketGroupConfigs.push(MarketGroupConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllMarketGroupsBoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.MarketGroupConfig market_group_configs = 51; */
        for (let i = 0; i < message.marketGroupConfigs.length; i++)
            MarketGroupConfig.internalBinaryWrite(message.marketGroupConfigs[i], writer.tag(51, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetAllMarketGroupsBoResponse
 */
export const GetAllMarketGroupsBoResponse = new GetAllMarketGroupsBoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarketGroupConfig$Type extends MessageType<MarketGroupConfig> {
    constructor() {
        super("odds.MarketGroupConfig", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 52, name: "market_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<MarketGroupConfig>): MarketGroupConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.marketIds = [];
        if (value !== undefined)
            reflectionMergePartial<MarketGroupConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MarketGroupConfig): MarketGroupConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated int32 market_ids */ 52:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.marketIds.push(reader.int32());
                    else
                        message.marketIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MarketGroupConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated int32 market_ids = 52; */
        if (message.marketIds.length) {
            writer.tag(52, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.marketIds.length; i++)
                writer.int32(message.marketIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.MarketGroupConfig
 */
export const MarketGroupConfig = new MarketGroupConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllMarketsBoRequest$Type extends MessageType<GetAllMarketsBoRequest> {
    constructor() {
        super("odds.GetAllMarketsBoRequest", [
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetAllMarketsBoRequest>): GetAllMarketsBoRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetAllMarketsBoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllMarketsBoRequest): GetAllMarketsBoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllMarketsBoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetAllMarketsBoRequest
 */
export const GetAllMarketsBoRequest = new GetAllMarketsBoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllMarketsBoResponse$Type extends MessageType<GetAllMarketsBoResponse> {
    constructor() {
        super("odds.GetAllMarketsBoResponse", [
            { no: 53, name: "market_configs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MarketConfig }
        ]);
    }
    create(value?: PartialMessage<GetAllMarketsBoResponse>): GetAllMarketsBoResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.marketConfigs = [];
        if (value !== undefined)
            reflectionMergePartial<GetAllMarketsBoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllMarketsBoResponse): GetAllMarketsBoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.MarketConfig market_configs */ 53:
                    message.marketConfigs.push(MarketConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllMarketsBoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.MarketConfig market_configs = 53; */
        for (let i = 0; i < message.marketConfigs.length; i++)
            MarketConfig.internalBinaryWrite(message.marketConfigs[i], writer.tag(53, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetAllMarketsBoResponse
 */
export const GetAllMarketsBoResponse = new GetAllMarketsBoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarketConfig$Type extends MessageType<MarketConfig> {
    constructor() {
        super("odds.MarketConfig", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MarketConfig>): MarketConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<MarketConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MarketConfig): MarketConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MarketConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.MarketConfig
 */
export const MarketConfig = new MarketConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Competitor$Type extends MessageType<Competitor> {
    constructor() {
        super("odds.Competitor", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 25, name: "competitions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Competition },
            { no: 108, name: "location_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 107, name: "sport_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 48, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 50, name: "last_updated", kind: "message", T: () => Timestamp },
            { no: 98, name: "links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Link },
            { no: 138, name: "players", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Player }
        ]);
    }
    create(value?: PartialMessage<Competitor>): Competitor {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.competitions = [];
        message.locationName = "";
        message.sportName = "";
        message.shortName = "";
        message.name = "";
        message.links = [];
        message.players = [];
        if (value !== undefined)
            reflectionMergePartial<Competitor>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Competitor): Competitor {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* repeated odds.Competition competitions */ 25:
                    message.competitions.push(Competition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string location_name */ 108:
                    message.locationName = reader.string();
                    break;
                case /* string sport_name */ 107:
                    message.sportName = reader.string();
                    break;
                case /* string short_name */ 48:
                    message.shortName = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* google.protobuf.Timestamp last_updated */ 50:
                    message.lastUpdated = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.lastUpdated);
                    break;
                case /* repeated odds.Link links */ 98:
                    message.links.push(Link.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated odds.Player players */ 138:
                    message.players.push(Player.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Competitor, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* repeated odds.Competition competitions = 25; */
        for (let i = 0; i < message.competitions.length; i++)
            Competition.internalBinaryWrite(message.competitions[i], writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* string location_name = 108; */
        if (message.locationName !== "")
            writer.tag(108, WireType.LengthDelimited).string(message.locationName);
        /* string sport_name = 107; */
        if (message.sportName !== "")
            writer.tag(107, WireType.LengthDelimited).string(message.sportName);
        /* string short_name = 48; */
        if (message.shortName !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.shortName);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* google.protobuf.Timestamp last_updated = 50; */
        if (message.lastUpdated)
            Timestamp.internalBinaryWrite(message.lastUpdated, writer.tag(50, WireType.LengthDelimited).fork(), options).join();
        /* repeated odds.Link links = 98; */
        for (let i = 0; i < message.links.length; i++)
            Link.internalBinaryWrite(message.links[i], writer.tag(98, WireType.LengthDelimited).fork(), options).join();
        /* repeated odds.Player players = 138; */
        for (let i = 0; i < message.players.length; i++)
            Player.internalBinaryWrite(message.players[i], writer.tag(138, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.Competitor
 */
export const Competitor = new Competitor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Link$Type extends MessageType<Link> {
    constructor() {
        super("odds.Link", [
            { no: 33, name: "provider_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 99, name: "provider_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 82, name: "provider_competitor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 100, name: "provider_competitor_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 101, name: "provider_player_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 105, name: "provider_player_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 120, name: "provider_outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 121, name: "provider_outcome_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 126, name: "provider_competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 127, name: "provider_competition_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Link>): Link {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.providerId = 0;
        message.providerName = "";
        message.providerCompetitorId = 0;
        message.providerCompetitorName = "";
        message.providerPlayerId = 0;
        message.providerPlayerName = "";
        message.providerOutcomeId = 0;
        message.providerOutcomeName = "";
        message.providerCompetitionId = 0;
        message.providerCompetitionName = "";
        if (value !== undefined)
            reflectionMergePartial<Link>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Link): Link {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 provider_id */ 33:
                    message.providerId = reader.int32();
                    break;
                case /* string provider_name */ 99:
                    message.providerName = reader.string();
                    break;
                case /* int32 provider_competitor_id */ 82:
                    message.providerCompetitorId = reader.int32();
                    break;
                case /* string provider_competitor_name */ 100:
                    message.providerCompetitorName = reader.string();
                    break;
                case /* int32 provider_player_id */ 101:
                    message.providerPlayerId = reader.int32();
                    break;
                case /* string provider_player_name */ 105:
                    message.providerPlayerName = reader.string();
                    break;
                case /* int32 provider_outcome_id */ 120:
                    message.providerOutcomeId = reader.int32();
                    break;
                case /* string provider_outcome_name */ 121:
                    message.providerOutcomeName = reader.string();
                    break;
                case /* int32 provider_competition_id */ 126:
                    message.providerCompetitionId = reader.int32();
                    break;
                case /* string provider_competition_name */ 127:
                    message.providerCompetitionName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Link, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 provider_id = 33; */
        if (message.providerId !== 0)
            writer.tag(33, WireType.Varint).int32(message.providerId);
        /* string provider_name = 99; */
        if (message.providerName !== "")
            writer.tag(99, WireType.LengthDelimited).string(message.providerName);
        /* int32 provider_competitor_id = 82; */
        if (message.providerCompetitorId !== 0)
            writer.tag(82, WireType.Varint).int32(message.providerCompetitorId);
        /* string provider_competitor_name = 100; */
        if (message.providerCompetitorName !== "")
            writer.tag(100, WireType.LengthDelimited).string(message.providerCompetitorName);
        /* int32 provider_player_id = 101; */
        if (message.providerPlayerId !== 0)
            writer.tag(101, WireType.Varint).int32(message.providerPlayerId);
        /* string provider_player_name = 105; */
        if (message.providerPlayerName !== "")
            writer.tag(105, WireType.LengthDelimited).string(message.providerPlayerName);
        /* int32 provider_outcome_id = 120; */
        if (message.providerOutcomeId !== 0)
            writer.tag(120, WireType.Varint).int32(message.providerOutcomeId);
        /* string provider_outcome_name = 121; */
        if (message.providerOutcomeName !== "")
            writer.tag(121, WireType.LengthDelimited).string(message.providerOutcomeName);
        /* int32 provider_competition_id = 126; */
        if (message.providerCompetitionId !== 0)
            writer.tag(126, WireType.Varint).int32(message.providerCompetitionId);
        /* string provider_competition_name = 127; */
        if (message.providerCompetitionName !== "")
            writer.tag(127, WireType.LengthDelimited).string(message.providerCompetitionName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.Link
 */
export const Link = new Link$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetForProviderRequest$Type extends MessageType<GetForProviderRequest> {
    constructor() {
        super("odds.GetForProviderRequest", [
            { no: 33, name: "provider_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetForProviderRequest>): GetForProviderRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.providerId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetForProviderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetForProviderRequest): GetForProviderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 provider_id */ 33:
                    message.providerId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetForProviderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 provider_id = 33; */
        if (message.providerId !== 0)
            writer.tag(33, WireType.Varint).int32(message.providerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetForProviderRequest
 */
export const GetForProviderRequest = new GetForProviderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInitialDataRequest$Type extends MessageType<GetInitialDataRequest> {
    constructor() {
        super("odds.GetInitialDataRequest", [
            { no: 42, name: "only_published", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetInitialDataRequest>): GetInitialDataRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.onlyPublished = false;
        if (value !== undefined)
            reflectionMergePartial<GetInitialDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInitialDataRequest): GetInitialDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool only_published */ 42:
                    message.onlyPublished = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInitialDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool only_published = 42; */
        if (message.onlyPublished !== false)
            writer.tag(42, WireType.Varint).bool(message.onlyPublished);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetInitialDataRequest
 */
export const GetInitialDataRequest = new GetInitialDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOddsRequest$Type extends MessageType<GetOddsRequest> {
    constructor() {
        super("odds.GetOddsRequest", [
            { no: 30, name: "slip_groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SlipGroup }
        ]);
    }
    create(value?: PartialMessage<GetOddsRequest>): GetOddsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.slipGroups = [];
        if (value !== undefined)
            reflectionMergePartial<GetOddsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOddsRequest): GetOddsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated common.SlipGroup slip_groups */ 30:
                    message.slipGroups.push(SlipGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOddsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated common.SlipGroup slip_groups = 30; */
        for (let i = 0; i < message.slipGroups.length; i++)
            SlipGroup.internalBinaryWrite(message.slipGroups[i], writer.tag(30, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetOddsRequest
 */
export const GetOddsRequest = new GetOddsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFastOddsRequest$Type extends MessageType<GetFastOddsRequest> {
    constructor() {
        super("odds.GetFastOddsRequest", [
            { no: 30, name: "slip_groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FastSlipGroup }
        ]);
    }
    create(value?: PartialMessage<GetFastOddsRequest>): GetFastOddsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.slipGroups = [];
        if (value !== undefined)
            reflectionMergePartial<GetFastOddsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFastOddsRequest): GetFastOddsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated common.FastSlipGroup slip_groups */ 30:
                    message.slipGroups.push(FastSlipGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFastOddsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated common.FastSlipGroup slip_groups = 30; */
        for (let i = 0; i < message.slipGroups.length; i++)
            FastSlipGroup.internalBinaryWrite(message.slipGroups[i], writer.tag(30, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetFastOddsRequest
 */
export const GetFastOddsRequest = new GetFastOddsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToggleFavoriteRequest$Type extends MessageType<ToggleFavoriteRequest> {
    constructor() {
        super("odds.ToggleFavoriteRequest", [
            { no: 41, name: "competition_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ToggleFavoriteRequest>): ToggleFavoriteRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.competitionIds = [];
        if (value !== undefined)
            reflectionMergePartial<ToggleFavoriteRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ToggleFavoriteRequest): ToggleFavoriteRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 competition_ids */ 41:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.competitionIds.push(reader.int32());
                    else
                        message.competitionIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ToggleFavoriteRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int32 competition_ids = 41; */
        if (message.competitionIds.length) {
            writer.tag(41, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.competitionIds.length; i++)
                writer.int32(message.competitionIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.ToggleFavoriteRequest
 */
export const ToggleFavoriteRequest = new ToggleFavoriteRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PublishEventEvent$Type extends MessageType<PublishEventEvent> {
    constructor() {
        super("odds.PublishEventEvent", [
            { no: 12, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 111, name: "live_provider_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 112, name: "prematch_provider_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PublishEventEvent>): PublishEventEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventId = "";
        message.liveProviderId = 0;
        message.prematchProviderId = 0;
        if (value !== undefined)
            reflectionMergePartial<PublishEventEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PublishEventEvent): PublishEventEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_id */ 12:
                    message.eventId = reader.string();
                    break;
                case /* int32 live_provider_id */ 111:
                    message.liveProviderId = reader.int32();
                    break;
                case /* int32 prematch_provider_id */ 112:
                    message.prematchProviderId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PublishEventEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event_id = 12; */
        if (message.eventId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.eventId);
        /* int32 live_provider_id = 111; */
        if (message.liveProviderId !== 0)
            writer.tag(111, WireType.Varint).int32(message.liveProviderId);
        /* int32 prematch_provider_id = 112; */
        if (message.prematchProviderId !== 0)
            writer.tag(112, WireType.Varint).int32(message.prematchProviderId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.PublishEventEvent
 */
export const PublishEventEvent = new PublishEventEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PublishEventsBoRequest$Type extends MessageType<PublishEventsBoRequest> {
    constructor() {
        super("odds.PublishEventsBoRequest", [
            { no: 39, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PublishEventEvent },
            { no: 93, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PublishEventsBoRequest>): PublishEventsBoRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        message.type = "";
        if (value !== undefined)
            reflectionMergePartial<PublishEventsBoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PublishEventsBoRequest): PublishEventsBoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.PublishEventEvent events */ 39:
                    message.events.push(PublishEventEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string type */ 93:
                    message.type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PublishEventsBoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.PublishEventEvent events = 39; */
        for (let i = 0; i < message.events.length; i++)
            PublishEventEvent.internalBinaryWrite(message.events[i], writer.tag(39, WireType.LengthDelimited).fork(), options).join();
        /* string type = 93; */
        if (message.type !== "")
            writer.tag(93, WireType.LengthDelimited).string(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.PublishEventsBoRequest
 */
export const PublishEventsBoRequest = new PublishEventsBoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PublishEventsBoResponse$Type extends MessageType<PublishEventsBoResponse> {
    constructor() {
        super("odds.PublishEventsBoResponse", [
            { no: 39, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PublishEventEvent },
            { no: 220, name: "number_of_published", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PublishEventsBoResponse>): PublishEventsBoResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        message.numberOfPublished = 0;
        if (value !== undefined)
            reflectionMergePartial<PublishEventsBoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PublishEventsBoResponse): PublishEventsBoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.PublishEventEvent events */ 39:
                    message.events.push(PublishEventEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 number_of_published */ 220:
                    message.numberOfPublished = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PublishEventsBoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.PublishEventEvent events = 39; */
        for (let i = 0; i < message.events.length; i++)
            PublishEventEvent.internalBinaryWrite(message.events[i], writer.tag(39, WireType.LengthDelimited).fork(), options).join();
        /* int32 number_of_published = 220; */
        if (message.numberOfPublished !== 0)
            writer.tag(220, WireType.Varint).int32(message.numberOfPublished);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.PublishEventsBoResponse
 */
export const PublishEventsBoResponse = new PublishEventsBoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Event$Type extends MessageType<Event> {
    constructor() {
        super("odds.Event", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "alias", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 154, name: "competition_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 109, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 108, name: "location_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 181, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventOdd },
            { no: 22, name: "start", kind: "message", T: () => Timestamp },
            { no: 140, name: "end_betting_time", kind: "message", T: () => Timestamp },
            { no: 43, name: "is_published", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 136, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 192, name: "manual_disable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 330, name: "manual_disable_reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 331, name: "om_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 332, name: "exe_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 176, name: "is_suspicious", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 178, name: "is_single_approval", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 174, name: "suspicious_level", kind: "message", T: () => SuspiciousLevel },
            { no: 80, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 237, name: "landbase_codes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 226, name: "new_landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 93, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 38, name: "providers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Provider },
            { no: 111, name: "live_provider_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 112, name: "prematch_provider_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 78, name: "error", kind: "message", T: () => Error },
            { no: 132, name: "outright_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 44, name: "competitors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Competitor },
            { no: 145, name: "result", kind: "message", T: () => EventResult },
            { no: 146, name: "has_result", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 147, name: "can_be_linked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 151, name: "num_of_slips", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 166, name: "num_unresolved_odds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 169, name: "num_of_odds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 167, name: "final_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 173, name: "note", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 96, name: "factory_competitor_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 104, name: "factory_player_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 187, name: "minutes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 188, name: "seconds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 224, name: "market_template_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 275, name: "can_finish", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 300, name: "live_event_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 301, name: "live_event_start", kind: "message", T: () => Timestamp },
            { no: 302, name: "is_blocked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 303, name: "block_reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 304, name: "auto_link", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 333, name: "time_cancel_timestamp", kind: "message", T: () => Timestamp },
            { no: 334, name: "exefeed_prematch", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 335, name: "result_format", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 336, name: "provider_event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Event>): Event {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.alias = "";
        message.competitionId = 0;
        message.competitionName = "";
        message.locationId = 0;
        message.locationName = "";
        message.sportId = 0;
        message.odds = [];
        message.isPublished = false;
        message.isDisabled = false;
        message.manualDisable = false;
        message.manualDisableReason = "";
        message.omName = "";
        message.exeName = "";
        message.isSuspicious = false;
        message.isSingleApproval = false;
        message.landbaseCode = "";
        message.landbaseCodes = [];
        message.newLandbaseCode = "";
        message.type = "";
        message.providers = [];
        message.liveProviderId = 0;
        message.prematchProviderId = 0;
        message.outrightType = "";
        message.competitors = [];
        message.hasResult = false;
        message.canBeLinked = false;
        message.numOfSlips = 0;
        message.numUnresolvedOdds = 0;
        message.numOfOdds = 0;
        message.finalResult = "";
        message.note = "";
        message.factoryCompetitorName = "";
        message.factoryPlayerName = "";
        message.minutes = 0;
        message.seconds = 0;
        message.marketTemplateId = 0;
        message.canFinish = false;
        message.liveEventName = "";
        message.isBlocked = false;
        message.blockReason = "";
        message.autoLink = false;
        message.exefeedPrematch = false;
        message.resultFormat = "";
        message.providerEventId = 0;
        if (value !== undefined)
            reflectionMergePartial<Event>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Event): Event {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string alias */ 3:
                    message.alias = reader.string();
                    break;
                case /* int32 competition_id */ 4:
                    message.competitionId = reader.int32();
                    break;
                case /* string competition_name */ 154:
                    message.competitionName = reader.string();
                    break;
                case /* int32 location_id */ 109:
                    message.locationId = reader.int32();
                    break;
                case /* string location_name */ 108:
                    message.locationName = reader.string();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* repeated odds.EventOdd odds */ 181:
                    message.odds.push(EventOdd.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp start */ 22:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end_betting_time */ 140:
                    message.endBettingTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.endBettingTime);
                    break;
                case /* bool is_published */ 43:
                    message.isPublished = reader.bool();
                    break;
                case /* bool is_disabled */ 136:
                    message.isDisabled = reader.bool();
                    break;
                case /* bool manual_disable */ 192:
                    message.manualDisable = reader.bool();
                    break;
                case /* string manual_disable_reason */ 330:
                    message.manualDisableReason = reader.string();
                    break;
                case /* string om_name */ 331:
                    message.omName = reader.string();
                    break;
                case /* string exe_name */ 332:
                    message.exeName = reader.string();
                    break;
                case /* bool is_suspicious */ 176:
                    message.isSuspicious = reader.bool();
                    break;
                case /* bool is_single_approval */ 178:
                    message.isSingleApproval = reader.bool();
                    break;
                case /* odds.SuspiciousLevel suspicious_level */ 174:
                    message.suspiciousLevel = SuspiciousLevel.internalBinaryRead(reader, reader.uint32(), options, message.suspiciousLevel);
                    break;
                case /* string landbase_code */ 80:
                    message.landbaseCode = reader.string();
                    break;
                case /* repeated string landbase_codes */ 237:
                    message.landbaseCodes.push(reader.string());
                    break;
                case /* string new_landbase_code */ 226:
                    message.newLandbaseCode = reader.string();
                    break;
                case /* string type */ 93:
                    message.type = reader.string();
                    break;
                case /* repeated odds.Provider providers */ 38:
                    message.providers.push(Provider.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 live_provider_id */ 111:
                    message.liveProviderId = reader.int32();
                    break;
                case /* int32 prematch_provider_id */ 112:
                    message.prematchProviderId = reader.int32();
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                case /* string outright_type */ 132:
                    message.outrightType = reader.string();
                    break;
                case /* repeated odds.Competitor competitors */ 44:
                    message.competitors.push(Competitor.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.EventResult result */ 145:
                    message.result = EventResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                case /* bool has_result */ 146:
                    message.hasResult = reader.bool();
                    break;
                case /* bool can_be_linked */ 147:
                    message.canBeLinked = reader.bool();
                    break;
                case /* int32 num_of_slips */ 151:
                    message.numOfSlips = reader.int32();
                    break;
                case /* int32 num_unresolved_odds */ 166:
                    message.numUnresolvedOdds = reader.int32();
                    break;
                case /* int32 num_of_odds */ 169:
                    message.numOfOdds = reader.int32();
                    break;
                case /* string final_result */ 167:
                    message.finalResult = reader.string();
                    break;
                case /* string note */ 173:
                    message.note = reader.string();
                    break;
                case /* string factory_competitor_name */ 96:
                    message.factoryCompetitorName = reader.string();
                    break;
                case /* string factory_player_name */ 104:
                    message.factoryPlayerName = reader.string();
                    break;
                case /* int32 minutes */ 187:
                    message.minutes = reader.int32();
                    break;
                case /* int32 seconds */ 188:
                    message.seconds = reader.int32();
                    break;
                case /* int32 market_template_id */ 224:
                    message.marketTemplateId = reader.int32();
                    break;
                case /* bool can_finish */ 275:
                    message.canFinish = reader.bool();
                    break;
                case /* string live_event_name */ 300:
                    message.liveEventName = reader.string();
                    break;
                case /* google.protobuf.Timestamp live_event_start */ 301:
                    message.liveEventStart = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.liveEventStart);
                    break;
                case /* bool is_blocked */ 302:
                    message.isBlocked = reader.bool();
                    break;
                case /* string block_reason */ 303:
                    message.blockReason = reader.string();
                    break;
                case /* bool auto_link */ 304:
                    message.autoLink = reader.bool();
                    break;
                case /* google.protobuf.Timestamp time_cancel_timestamp */ 333:
                    message.timeCancelTimestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timeCancelTimestamp);
                    break;
                case /* bool exefeed_prematch */ 334:
                    message.exefeedPrematch = reader.bool();
                    break;
                case /* string result_format */ 335:
                    message.resultFormat = reader.string();
                    break;
                case /* int32 provider_event_id */ 336:
                    message.providerEventId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Event, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string alias = 3; */
        if (message.alias !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.alias);
        /* int32 competition_id = 4; */
        if (message.competitionId !== 0)
            writer.tag(4, WireType.Varint).int32(message.competitionId);
        /* string competition_name = 154; */
        if (message.competitionName !== "")
            writer.tag(154, WireType.LengthDelimited).string(message.competitionName);
        /* int32 location_id = 109; */
        if (message.locationId !== 0)
            writer.tag(109, WireType.Varint).int32(message.locationId);
        /* string location_name = 108; */
        if (message.locationName !== "")
            writer.tag(108, WireType.LengthDelimited).string(message.locationName);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* repeated odds.EventOdd odds = 181; */
        for (let i = 0; i < message.odds.length; i++)
            EventOdd.internalBinaryWrite(message.odds[i], writer.tag(181, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp start = 22; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end_betting_time = 140; */
        if (message.endBettingTime)
            Timestamp.internalBinaryWrite(message.endBettingTime, writer.tag(140, WireType.LengthDelimited).fork(), options).join();
        /* bool is_published = 43; */
        if (message.isPublished !== false)
            writer.tag(43, WireType.Varint).bool(message.isPublished);
        /* bool is_disabled = 136; */
        if (message.isDisabled !== false)
            writer.tag(136, WireType.Varint).bool(message.isDisabled);
        /* bool manual_disable = 192; */
        if (message.manualDisable !== false)
            writer.tag(192, WireType.Varint).bool(message.manualDisable);
        /* string manual_disable_reason = 330; */
        if (message.manualDisableReason !== "")
            writer.tag(330, WireType.LengthDelimited).string(message.manualDisableReason);
        /* string om_name = 331; */
        if (message.omName !== "")
            writer.tag(331, WireType.LengthDelimited).string(message.omName);
        /* string exe_name = 332; */
        if (message.exeName !== "")
            writer.tag(332, WireType.LengthDelimited).string(message.exeName);
        /* bool is_suspicious = 176; */
        if (message.isSuspicious !== false)
            writer.tag(176, WireType.Varint).bool(message.isSuspicious);
        /* bool is_single_approval = 178; */
        if (message.isSingleApproval !== false)
            writer.tag(178, WireType.Varint).bool(message.isSingleApproval);
        /* odds.SuspiciousLevel suspicious_level = 174; */
        if (message.suspiciousLevel)
            SuspiciousLevel.internalBinaryWrite(message.suspiciousLevel, writer.tag(174, WireType.LengthDelimited).fork(), options).join();
        /* string landbase_code = 80; */
        if (message.landbaseCode !== "")
            writer.tag(80, WireType.LengthDelimited).string(message.landbaseCode);
        /* repeated string landbase_codes = 237; */
        for (let i = 0; i < message.landbaseCodes.length; i++)
            writer.tag(237, WireType.LengthDelimited).string(message.landbaseCodes[i]);
        /* string new_landbase_code = 226; */
        if (message.newLandbaseCode !== "")
            writer.tag(226, WireType.LengthDelimited).string(message.newLandbaseCode);
        /* string type = 93; */
        if (message.type !== "")
            writer.tag(93, WireType.LengthDelimited).string(message.type);
        /* repeated odds.Provider providers = 38; */
        for (let i = 0; i < message.providers.length; i++)
            Provider.internalBinaryWrite(message.providers[i], writer.tag(38, WireType.LengthDelimited).fork(), options).join();
        /* int32 live_provider_id = 111; */
        if (message.liveProviderId !== 0)
            writer.tag(111, WireType.Varint).int32(message.liveProviderId);
        /* int32 prematch_provider_id = 112; */
        if (message.prematchProviderId !== 0)
            writer.tag(112, WireType.Varint).int32(message.prematchProviderId);
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        /* string outright_type = 132; */
        if (message.outrightType !== "")
            writer.tag(132, WireType.LengthDelimited).string(message.outrightType);
        /* repeated odds.Competitor competitors = 44; */
        for (let i = 0; i < message.competitors.length; i++)
            Competitor.internalBinaryWrite(message.competitors[i], writer.tag(44, WireType.LengthDelimited).fork(), options).join();
        /* common.EventResult result = 145; */
        if (message.result)
            EventResult.internalBinaryWrite(message.result, writer.tag(145, WireType.LengthDelimited).fork(), options).join();
        /* bool has_result = 146; */
        if (message.hasResult !== false)
            writer.tag(146, WireType.Varint).bool(message.hasResult);
        /* bool can_be_linked = 147; */
        if (message.canBeLinked !== false)
            writer.tag(147, WireType.Varint).bool(message.canBeLinked);
        /* int32 num_of_slips = 151; */
        if (message.numOfSlips !== 0)
            writer.tag(151, WireType.Varint).int32(message.numOfSlips);
        /* int32 num_unresolved_odds = 166; */
        if (message.numUnresolvedOdds !== 0)
            writer.tag(166, WireType.Varint).int32(message.numUnresolvedOdds);
        /* int32 num_of_odds = 169; */
        if (message.numOfOdds !== 0)
            writer.tag(169, WireType.Varint).int32(message.numOfOdds);
        /* string final_result = 167; */
        if (message.finalResult !== "")
            writer.tag(167, WireType.LengthDelimited).string(message.finalResult);
        /* string note = 173; */
        if (message.note !== "")
            writer.tag(173, WireType.LengthDelimited).string(message.note);
        /* string factory_competitor_name = 96; */
        if (message.factoryCompetitorName !== "")
            writer.tag(96, WireType.LengthDelimited).string(message.factoryCompetitorName);
        /* string factory_player_name = 104; */
        if (message.factoryPlayerName !== "")
            writer.tag(104, WireType.LengthDelimited).string(message.factoryPlayerName);
        /* int32 minutes = 187; */
        if (message.minutes !== 0)
            writer.tag(187, WireType.Varint).int32(message.minutes);
        /* int32 seconds = 188; */
        if (message.seconds !== 0)
            writer.tag(188, WireType.Varint).int32(message.seconds);
        /* int32 market_template_id = 224; */
        if (message.marketTemplateId !== 0)
            writer.tag(224, WireType.Varint).int32(message.marketTemplateId);
        /* bool can_finish = 275; */
        if (message.canFinish !== false)
            writer.tag(275, WireType.Varint).bool(message.canFinish);
        /* string live_event_name = 300; */
        if (message.liveEventName !== "")
            writer.tag(300, WireType.LengthDelimited).string(message.liveEventName);
        /* google.protobuf.Timestamp live_event_start = 301; */
        if (message.liveEventStart)
            Timestamp.internalBinaryWrite(message.liveEventStart, writer.tag(301, WireType.LengthDelimited).fork(), options).join();
        /* bool is_blocked = 302; */
        if (message.isBlocked !== false)
            writer.tag(302, WireType.Varint).bool(message.isBlocked);
        /* string block_reason = 303; */
        if (message.blockReason !== "")
            writer.tag(303, WireType.LengthDelimited).string(message.blockReason);
        /* bool auto_link = 304; */
        if (message.autoLink !== false)
            writer.tag(304, WireType.Varint).bool(message.autoLink);
        /* google.protobuf.Timestamp time_cancel_timestamp = 333; */
        if (message.timeCancelTimestamp)
            Timestamp.internalBinaryWrite(message.timeCancelTimestamp, writer.tag(333, WireType.LengthDelimited).fork(), options).join();
        /* bool exefeed_prematch = 334; */
        if (message.exefeedPrematch !== false)
            writer.tag(334, WireType.Varint).bool(message.exefeedPrematch);
        /* string result_format = 335; */
        if (message.resultFormat !== "")
            writer.tag(335, WireType.LengthDelimited).string(message.resultFormat);
        /* int32 provider_event_id = 336; */
        if (message.providerEventId !== 0)
            writer.tag(336, WireType.Varint).int32(message.providerEventId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.Event
 */
export const Event = new Event$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LandBaseCodeEvent$Type extends MessageType<LandBaseCodeEvent> {
    constructor() {
        super("odds.LandBaseCodeEvent", [
            { no: 1, name: "event", kind: "message", T: () => StreamEvent },
            { no: 2, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<LandBaseCodeEvent>): LandBaseCodeEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<LandBaseCodeEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LandBaseCodeEvent): LandBaseCodeEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* odds.StreamEvent event */ 1:
                    message.event = StreamEvent.internalBinaryRead(reader, reader.uint32(), options, message.event);
                    break;
                case /* common.Error error */ 2:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LandBaseCodeEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* odds.StreamEvent event = 1; */
        if (message.event)
            StreamEvent.internalBinaryWrite(message.event, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 2; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LandBaseCodeEvent
 */
export const LandBaseCodeEvent = new LandBaseCodeEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamEvent$Type extends MessageType<StreamEvent> {
    constructor() {
        super("odds.StreamEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "note", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StreamEventOdd },
            { no: 8, name: "start", kind: "message", T: () => Timestamp },
            { no: 9, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "is_suspicious", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "is_single_approval", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "profile", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "current_phase", kind: "message", T: () => EventCurrentPhase },
            { no: 14, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "outright_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "mon", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "result", kind: "message", T: () => EventResult }
        ]);
    }
    create(value?: PartialMessage<StreamEvent>): StreamEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.note = "";
        message.competitionId = 0;
        message.locationId = 0;
        message.sportId = 0;
        message.odds = [];
        message.isDisabled = false;
        message.isSuspicious = false;
        message.isSingleApproval = false;
        message.profile = "";
        message.landbaseCode = "";
        message.type = "";
        message.outrightType = "";
        message.mon = 0;
        if (value !== undefined)
            reflectionMergePartial<StreamEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StreamEvent): StreamEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string note */ 3:
                    message.note = reader.string();
                    break;
                case /* int32 competition_id */ 4:
                    message.competitionId = reader.int32();
                    break;
                case /* int32 location_id */ 5:
                    message.locationId = reader.int32();
                    break;
                case /* int32 sport_id */ 6:
                    message.sportId = reader.int32();
                    break;
                case /* repeated odds.StreamEventOdd odds */ 7:
                    message.odds.push(StreamEventOdd.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp start */ 8:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* bool is_disabled */ 9:
                    message.isDisabled = reader.bool();
                    break;
                case /* bool is_suspicious */ 10:
                    message.isSuspicious = reader.bool();
                    break;
                case /* bool is_single_approval */ 11:
                    message.isSingleApproval = reader.bool();
                    break;
                case /* string profile */ 12:
                    message.profile = reader.string();
                    break;
                case /* common.EventCurrentPhase current_phase */ 13:
                    message.currentPhase = EventCurrentPhase.internalBinaryRead(reader, reader.uint32(), options, message.currentPhase);
                    break;
                case /* string landbase_code */ 14:
                    message.landbaseCode = reader.string();
                    break;
                case /* string type */ 15:
                    message.type = reader.string();
                    break;
                case /* string outright_type */ 16:
                    message.outrightType = reader.string();
                    break;
                case /* int32 mon */ 17:
                    message.mon = reader.int32();
                    break;
                case /* common.EventResult result */ 18:
                    message.result = EventResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StreamEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string note = 3; */
        if (message.note !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.note);
        /* int32 competition_id = 4; */
        if (message.competitionId !== 0)
            writer.tag(4, WireType.Varint).int32(message.competitionId);
        /* int32 location_id = 5; */
        if (message.locationId !== 0)
            writer.tag(5, WireType.Varint).int32(message.locationId);
        /* int32 sport_id = 6; */
        if (message.sportId !== 0)
            writer.tag(6, WireType.Varint).int32(message.sportId);
        /* repeated odds.StreamEventOdd odds = 7; */
        for (let i = 0; i < message.odds.length; i++)
            StreamEventOdd.internalBinaryWrite(message.odds[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp start = 8; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* bool is_disabled = 9; */
        if (message.isDisabled !== false)
            writer.tag(9, WireType.Varint).bool(message.isDisabled);
        /* bool is_suspicious = 10; */
        if (message.isSuspicious !== false)
            writer.tag(10, WireType.Varint).bool(message.isSuspicious);
        /* bool is_single_approval = 11; */
        if (message.isSingleApproval !== false)
            writer.tag(11, WireType.Varint).bool(message.isSingleApproval);
        /* string profile = 12; */
        if (message.profile !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.profile);
        /* common.EventCurrentPhase current_phase = 13; */
        if (message.currentPhase)
            EventCurrentPhase.internalBinaryWrite(message.currentPhase, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* string landbase_code = 14; */
        if (message.landbaseCode !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.landbaseCode);
        /* string type = 15; */
        if (message.type !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.type);
        /* string outright_type = 16; */
        if (message.outrightType !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.outrightType);
        /* int32 mon = 17; */
        if (message.mon !== 0)
            writer.tag(17, WireType.Varint).int32(message.mon);
        /* common.EventResult result = 18; */
        if (message.result)
            EventResult.internalBinaryWrite(message.result, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.StreamEvent
 */
export const StreamEvent = new StreamEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamEventOdd$Type extends MessageType<StreamEventOdd> {
    constructor() {
        super("odds.StreamEventOdd", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 11, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 122, name: "limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 238, name: "frame_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 239, name: "current_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 136, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "live_player", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StreamEventOdd>): StreamEventOdd {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.value = 0;
        message.outcomeId = 0;
        message.limit = 0;
        message.frameNumber = 0;
        message.currentResult = "";
        message.isDisabled = false;
        message.livePlayer = "";
        if (value !== undefined)
            reflectionMergePartial<StreamEventOdd>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StreamEventOdd): StreamEventOdd {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double value */ 8:
                    message.value = reader.double();
                    break;
                case /* int32 outcome_id */ 11:
                    message.outcomeId = reader.int32();
                    break;
                case /* double limit */ 122:
                    message.limit = reader.double();
                    break;
                case /* int32 frame_number */ 238:
                    message.frameNumber = reader.int32();
                    break;
                case /* string current_result */ 239:
                    message.currentResult = reader.string();
                    break;
                case /* bool is_disabled */ 136:
                    message.isDisabled = reader.bool();
                    break;
                case /* string live_player */ 9:
                    message.livePlayer = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StreamEventOdd, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double value = 8; */
        if (message.value !== 0)
            writer.tag(8, WireType.Bit64).double(message.value);
        /* int32 outcome_id = 11; */
        if (message.outcomeId !== 0)
            writer.tag(11, WireType.Varint).int32(message.outcomeId);
        /* double limit = 122; */
        if (message.limit !== 0)
            writer.tag(122, WireType.Bit64).double(message.limit);
        /* int32 frame_number = 238; */
        if (message.frameNumber !== 0)
            writer.tag(238, WireType.Varint).int32(message.frameNumber);
        /* string current_result = 239; */
        if (message.currentResult !== "")
            writer.tag(239, WireType.LengthDelimited).string(message.currentResult);
        /* bool is_disabled = 136; */
        if (message.isDisabled !== false)
            writer.tag(136, WireType.Varint).bool(message.isDisabled);
        /* string live_player = 9; */
        if (message.livePlayer !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.livePlayer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.StreamEventOdd
 */
export const StreamEventOdd = new StreamEventOdd$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateEventRequest$Type extends MessageType<CreateEventRequest> {
    constructor() {
        super("odds.CreateEventRequest", [
            { no: 93, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 27, name: "outcomes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Outcome },
            { no: 132, name: "outright_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 140, name: "end_betting_time", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<CreateEventRequest>): CreateEventRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = "";
        message.name = "";
        message.eventId = "";
        message.competitionId = 0;
        message.outcomes = [];
        message.outrightType = "";
        if (value !== undefined)
            reflectionMergePartial<CreateEventRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateEventRequest): CreateEventRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 93:
                    message.type = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string event_id */ 12:
                    message.eventId = reader.string();
                    break;
                case /* int32 competition_id */ 4:
                    message.competitionId = reader.int32();
                    break;
                case /* repeated odds.Outcome outcomes */ 27:
                    message.outcomes.push(Outcome.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string outright_type */ 132:
                    message.outrightType = reader.string();
                    break;
                case /* google.protobuf.Timestamp end_betting_time */ 140:
                    message.endBettingTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.endBettingTime);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateEventRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 93; */
        if (message.type !== "")
            writer.tag(93, WireType.LengthDelimited).string(message.type);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string event_id = 12; */
        if (message.eventId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.eventId);
        /* int32 competition_id = 4; */
        if (message.competitionId !== 0)
            writer.tag(4, WireType.Varint).int32(message.competitionId);
        /* repeated odds.Outcome outcomes = 27; */
        for (let i = 0; i < message.outcomes.length; i++)
            Outcome.internalBinaryWrite(message.outcomes[i], writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        /* string outright_type = 132; */
        if (message.outrightType !== "")
            writer.tag(132, WireType.LengthDelimited).string(message.outrightType);
        /* google.protobuf.Timestamp end_betting_time = 140; */
        if (message.endBettingTime)
            Timestamp.internalBinaryWrite(message.endBettingTime, writer.tag(140, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.CreateEventRequest
 */
export const CreateEventRequest = new CreateEventRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventFwwRequest$Type extends MessageType<EventFwwRequest> {
    constructor() {
        super("odds.EventFwwRequest", [
            { no: 1, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "start", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<EventFwwRequest>): EventFwwRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = "";
        message.name = "";
        message.eventId = "";
        message.competitionId = 0;
        message.start = 0;
        if (value !== undefined)
            reflectionMergePartial<EventFwwRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventFwwRequest): EventFwwRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 1:
                    message.type = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string event_id */ 3:
                    message.eventId = reader.string();
                    break;
                case /* int32 competition_id */ 4:
                    message.competitionId = reader.int32();
                    break;
                case /* int32 start */ 5:
                    message.start = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventFwwRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 1; */
        if (message.type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.type);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string event_id = 3; */
        if (message.eventId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.eventId);
        /* int32 competition_id = 4; */
        if (message.competitionId !== 0)
            writer.tag(4, WireType.Varint).int32(message.competitionId);
        /* int32 start = 5; */
        if (message.start !== 0)
            writer.tag(5, WireType.Varint).int32(message.start);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.EventFwwRequest
 */
export const EventFwwRequest = new EventFwwRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OddFwwRequest$Type extends MessageType<OddFwwRequest> {
    constructor() {
        super("odds.OddFwwRequest", [
            { no: 11, name: "odd_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 1, name: "limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "prematch_odd", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<OddFwwRequest>): OddFwwRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.oddId = "";
        message.limit = 0;
        message.prematchOdd = 0;
        message.eventId = "";
        message.outcomeId = 0;
        if (value !== undefined)
            reflectionMergePartial<OddFwwRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OddFwwRequest): OddFwwRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string odd_id */ 11:
                    message.oddId = reader.string();
                    break;
                case /* double limit */ 1:
                    message.limit = reader.double();
                    break;
                case /* double prematch_odd */ 2:
                    message.prematchOdd = reader.double();
                    break;
                case /* string event_id */ 3:
                    message.eventId = reader.string();
                    break;
                case /* int32 outcome_id */ 4:
                    message.outcomeId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OddFwwRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string odd_id = 11; */
        if (message.oddId !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.oddId);
        /* double limit = 1; */
        if (message.limit !== 0)
            writer.tag(1, WireType.Bit64).double(message.limit);
        /* double prematch_odd = 2; */
        if (message.prematchOdd !== 0)
            writer.tag(2, WireType.Bit64).double(message.prematchOdd);
        /* string event_id = 3; */
        if (message.eventId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.eventId);
        /* int32 outcome_id = 4; */
        if (message.outcomeId !== 0)
            writer.tag(4, WireType.Varint).int32(message.outcomeId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.OddFwwRequest
 */
export const OddFwwRequest = new OddFwwRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompetitionFwwRequest$Type extends MessageType<CompetitionFwwRequest> {
    constructor() {
        super("odds.CompetitionFwwRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "pa_sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "pa_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompetitionFwwRequest>): CompetitionFwwRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.locationId = 0;
        message.sportId = 0;
        message.paSportId = 0;
        message.paType = "";
        if (value !== undefined)
            reflectionMergePartial<CompetitionFwwRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompetitionFwwRequest): CompetitionFwwRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* int32 location_id */ 2:
                    message.locationId = reader.int32();
                    break;
                case /* int32 sport_id */ 3:
                    message.sportId = reader.int32();
                    break;
                case /* int32 pa_sport_id */ 4:
                    message.paSportId = reader.int32();
                    break;
                case /* string pa_type */ 5:
                    message.paType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompetitionFwwRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* int32 location_id = 2; */
        if (message.locationId !== 0)
            writer.tag(2, WireType.Varint).int32(message.locationId);
        /* int32 sport_id = 3; */
        if (message.sportId !== 0)
            writer.tag(3, WireType.Varint).int32(message.sportId);
        /* int32 pa_sport_id = 4; */
        if (message.paSportId !== 0)
            writer.tag(4, WireType.Varint).int32(message.paSportId);
        /* string pa_type = 5; */
        if (message.paType !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.paType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.CompetitionFwwRequest
 */
export const CompetitionFwwRequest = new CompetitionFwwRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventOdd$Type extends MessageType<EventOdd> {
    constructor() {
        super("odds.EventOdd", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 11, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 94, name: "market_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 122, name: "limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 238, name: "frame_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 239, name: "current_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 85, name: "outcome_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 191, name: "outcome_group", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 84, name: "market_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 83, name: "factory_competitor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 96, name: "factory_competitor_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 91, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 151, name: "num_of_slips", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 136, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 80, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 81, name: "outcome_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "live_player", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EventOdd>): EventOdd {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.value = 0;
        message.outcomeId = 0;
        message.marketId = 0;
        message.limit = 0;
        message.frameNumber = 0;
        message.currentResult = "";
        message.outcomeName = "";
        message.outcomeGroup = "";
        message.marketName = "";
        message.factoryCompetitorId = 0;
        message.factoryCompetitorName = "";
        message.status = "";
        message.numOfSlips = 0;
        message.isDisabled = false;
        message.landbaseCode = "";
        message.outcomeCode = "";
        message.livePlayer = "";
        if (value !== undefined)
            reflectionMergePartial<EventOdd>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventOdd): EventOdd {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* double value */ 8:
                    message.value = reader.double();
                    break;
                case /* int32 outcome_id */ 11:
                    message.outcomeId = reader.int32();
                    break;
                case /* int32 market_id */ 94:
                    message.marketId = reader.int32();
                    break;
                case /* double limit */ 122:
                    message.limit = reader.double();
                    break;
                case /* int32 frame_number */ 238:
                    message.frameNumber = reader.int32();
                    break;
                case /* string current_result */ 239:
                    message.currentResult = reader.string();
                    break;
                case /* string outcome_name */ 85:
                    message.outcomeName = reader.string();
                    break;
                case /* string outcome_group */ 191:
                    message.outcomeGroup = reader.string();
                    break;
                case /* string market_name */ 84:
                    message.marketName = reader.string();
                    break;
                case /* int32 factory_competitor_id */ 83:
                    message.factoryCompetitorId = reader.int32();
                    break;
                case /* string factory_competitor_name */ 96:
                    message.factoryCompetitorName = reader.string();
                    break;
                case /* string status */ 91:
                    message.status = reader.string();
                    break;
                case /* int32 num_of_slips */ 151:
                    message.numOfSlips = reader.int32();
                    break;
                case /* bool is_disabled */ 136:
                    message.isDisabled = reader.bool();
                    break;
                case /* string landbase_code */ 80:
                    message.landbaseCode = reader.string();
                    break;
                case /* string outcome_code */ 81:
                    message.outcomeCode = reader.string();
                    break;
                case /* string live_player */ 9:
                    message.livePlayer = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventOdd, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* double value = 8; */
        if (message.value !== 0)
            writer.tag(8, WireType.Bit64).double(message.value);
        /* int32 outcome_id = 11; */
        if (message.outcomeId !== 0)
            writer.tag(11, WireType.Varint).int32(message.outcomeId);
        /* int32 market_id = 94; */
        if (message.marketId !== 0)
            writer.tag(94, WireType.Varint).int32(message.marketId);
        /* double limit = 122; */
        if (message.limit !== 0)
            writer.tag(122, WireType.Bit64).double(message.limit);
        /* int32 frame_number = 238; */
        if (message.frameNumber !== 0)
            writer.tag(238, WireType.Varint).int32(message.frameNumber);
        /* string current_result = 239; */
        if (message.currentResult !== "")
            writer.tag(239, WireType.LengthDelimited).string(message.currentResult);
        /* string outcome_name = 85; */
        if (message.outcomeName !== "")
            writer.tag(85, WireType.LengthDelimited).string(message.outcomeName);
        /* string outcome_group = 191; */
        if (message.outcomeGroup !== "")
            writer.tag(191, WireType.LengthDelimited).string(message.outcomeGroup);
        /* string market_name = 84; */
        if (message.marketName !== "")
            writer.tag(84, WireType.LengthDelimited).string(message.marketName);
        /* int32 factory_competitor_id = 83; */
        if (message.factoryCompetitorId !== 0)
            writer.tag(83, WireType.Varint).int32(message.factoryCompetitorId);
        /* string factory_competitor_name = 96; */
        if (message.factoryCompetitorName !== "")
            writer.tag(96, WireType.LengthDelimited).string(message.factoryCompetitorName);
        /* string status = 91; */
        if (message.status !== "")
            writer.tag(91, WireType.LengthDelimited).string(message.status);
        /* int32 num_of_slips = 151; */
        if (message.numOfSlips !== 0)
            writer.tag(151, WireType.Varint).int32(message.numOfSlips);
        /* bool is_disabled = 136; */
        if (message.isDisabled !== false)
            writer.tag(136, WireType.Varint).bool(message.isDisabled);
        /* string landbase_code = 80; */
        if (message.landbaseCode !== "")
            writer.tag(80, WireType.LengthDelimited).string(message.landbaseCode);
        /* string outcome_code = 81; */
        if (message.outcomeCode !== "")
            writer.tag(81, WireType.LengthDelimited).string(message.outcomeCode);
        /* string live_player = 9; */
        if (message.livePlayer !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.livePlayer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.EventOdd
 */
export const EventOdd = new EventOdd$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarketGroup$Type extends MessageType<MarketGroup> {
    constructor() {
        super("odds.MarketGroup", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 55, name: "order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "markets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Market }
        ]);
    }
    create(value?: PartialMessage<MarketGroup>): MarketGroup {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.order = 0;
        message.markets = [];
        if (value !== undefined)
            reflectionMergePartial<MarketGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MarketGroup): MarketGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 order */ 55:
                    message.order = reader.int32();
                    break;
                case /* repeated odds.Market markets */ 6:
                    message.markets.push(Market.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MarketGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 order = 55; */
        if (message.order !== 0)
            writer.tag(55, WireType.Varint).int32(message.order);
        /* repeated odds.Market markets = 6; */
        for (let i = 0; i < message.markets.length; i++)
            Market.internalBinaryWrite(message.markets[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.MarketGroup
 */
export const MarketGroup = new MarketGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Market$Type extends MessageType<Market> {
    constructor() {
        super("odds.Market", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 27, name: "outcomes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Outcome },
            { no: 185, name: "has_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Market>): Market {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.sportId = 0;
        message.outcomes = [];
        message.hasLimit = false;
        if (value !== undefined)
            reflectionMergePartial<Market>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Market): Market {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* repeated odds.Outcome outcomes */ 27:
                    message.outcomes.push(Outcome.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool has_limit */ 185:
                    message.hasLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Market, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* repeated odds.Outcome outcomes = 27; */
        for (let i = 0; i < message.outcomes.length; i++)
            Outcome.internalBinaryWrite(message.outcomes[i], writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        /* bool has_limit = 185; */
        if (message.hasLimit !== false)
            writer.tag(185, WireType.Varint).bool(message.hasLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.Market
 */
export const Market = new Market$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Outcome$Type extends MessageType<Outcome> {
    constructor() {
        super("odds.Outcome", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 120, name: "sport", kind: "message", T: () => Sport },
            { no: 121, name: "market", kind: "message", T: () => Market },
            { no: 81, name: "outcome_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 191, name: "outcome_group", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 274, name: "outcome_tic", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 240, name: "exe_limit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "odd", kind: "message", T: () => Odd },
            { no: 118, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 119, name: "short_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 83, name: "factory_competitor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 102, name: "factory_player_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 98, name: "links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Link },
            { no: 261, name: "live_market_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 279, name: "has_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Outcome>): Outcome {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.outcomeCode = "";
        message.outcomeGroup = "";
        message.outcomeTic = "";
        message.exeLimit = "";
        message.description = "";
        message.shortDescription = "";
        message.factoryCompetitorId = 0;
        message.factoryPlayerId = 0;
        message.links = [];
        message.liveMarketId = 0;
        message.hasLimit = false;
        if (value !== undefined)
            reflectionMergePartial<Outcome>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Outcome): Outcome {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* odds.Sport sport */ 120:
                    message.sport = Sport.internalBinaryRead(reader, reader.uint32(), options, message.sport);
                    break;
                case /* odds.Market market */ 121:
                    message.market = Market.internalBinaryRead(reader, reader.uint32(), options, message.market);
                    break;
                case /* string outcome_code */ 81:
                    message.outcomeCode = reader.string();
                    break;
                case /* string outcome_group */ 191:
                    message.outcomeGroup = reader.string();
                    break;
                case /* string outcome_tic */ 274:
                    message.outcomeTic = reader.string();
                    break;
                case /* string exe_limit */ 240:
                    message.exeLimit = reader.string();
                    break;
                case /* odds.Odd odd */ 19:
                    message.odd = Odd.internalBinaryRead(reader, reader.uint32(), options, message.odd);
                    break;
                case /* string description */ 118:
                    message.description = reader.string();
                    break;
                case /* string short_description */ 119:
                    message.shortDescription = reader.string();
                    break;
                case /* int32 factory_competitor_id */ 83:
                    message.factoryCompetitorId = reader.int32();
                    break;
                case /* int32 factory_player_id */ 102:
                    message.factoryPlayerId = reader.int32();
                    break;
                case /* repeated odds.Link links */ 98:
                    message.links.push(Link.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 live_market_id */ 261:
                    message.liveMarketId = reader.int32();
                    break;
                case /* bool has_limit */ 279:
                    message.hasLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Outcome, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* odds.Sport sport = 120; */
        if (message.sport)
            Sport.internalBinaryWrite(message.sport, writer.tag(120, WireType.LengthDelimited).fork(), options).join();
        /* odds.Market market = 121; */
        if (message.market)
            Market.internalBinaryWrite(message.market, writer.tag(121, WireType.LengthDelimited).fork(), options).join();
        /* string outcome_code = 81; */
        if (message.outcomeCode !== "")
            writer.tag(81, WireType.LengthDelimited).string(message.outcomeCode);
        /* string outcome_group = 191; */
        if (message.outcomeGroup !== "")
            writer.tag(191, WireType.LengthDelimited).string(message.outcomeGroup);
        /* string outcome_tic = 274; */
        if (message.outcomeTic !== "")
            writer.tag(274, WireType.LengthDelimited).string(message.outcomeTic);
        /* string exe_limit = 240; */
        if (message.exeLimit !== "")
            writer.tag(240, WireType.LengthDelimited).string(message.exeLimit);
        /* odds.Odd odd = 19; */
        if (message.odd)
            Odd.internalBinaryWrite(message.odd, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* string description = 118; */
        if (message.description !== "")
            writer.tag(118, WireType.LengthDelimited).string(message.description);
        /* string short_description = 119; */
        if (message.shortDescription !== "")
            writer.tag(119, WireType.LengthDelimited).string(message.shortDescription);
        /* int32 factory_competitor_id = 83; */
        if (message.factoryCompetitorId !== 0)
            writer.tag(83, WireType.Varint).int32(message.factoryCompetitorId);
        /* int32 factory_player_id = 102; */
        if (message.factoryPlayerId !== 0)
            writer.tag(102, WireType.Varint).int32(message.factoryPlayerId);
        /* repeated odds.Link links = 98; */
        for (let i = 0; i < message.links.length; i++)
            Link.internalBinaryWrite(message.links[i], writer.tag(98, WireType.LengthDelimited).fork(), options).join();
        /* int32 live_market_id = 261; */
        if (message.liveMarketId !== 0)
            writer.tag(261, WireType.Varint).int32(message.liveMarketId);
        /* bool has_limit = 279; */
        if (message.hasLimit !== false)
            writer.tag(279, WireType.Varint).bool(message.hasLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.Outcome
 */
export const Outcome = new Outcome$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Odd$Type extends MessageType<Odd> {
    constructor() {
        super("odds.Odd", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 122, name: "limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 91, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 136, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 183, name: "is_fixed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "prematch_odd", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 77, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<Odd>): Odd {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.value = "";
        message.limit = 0;
        message.status = "";
        message.isDisabled = false;
        message.isFixed = false;
        message.prematchOdd = 0;
        message.eventId = "";
        message.outcomeId = 0;
        if (value !== undefined)
            reflectionMergePartial<Odd>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Odd): Odd {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string value */ 8:
                    message.value = reader.string();
                    break;
                case /* double limit */ 122:
                    message.limit = reader.double();
                    break;
                case /* string status */ 91:
                    message.status = reader.string();
                    break;
                case /* bool is_disabled */ 136:
                    message.isDisabled = reader.bool();
                    break;
                case /* bool is_fixed */ 183:
                    message.isFixed = reader.bool();
                    break;
                case /* double prematch_odd */ 2:
                    message.prematchOdd = reader.double();
                    break;
                case /* string event_id */ 3:
                    message.eventId = reader.string();
                    break;
                case /* int32 outcome_id */ 4:
                    message.outcomeId = reader.int32();
                    break;
                case /* common.Error error */ 77:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Odd, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string value = 8; */
        if (message.value !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.value);
        /* double limit = 122; */
        if (message.limit !== 0)
            writer.tag(122, WireType.Bit64).double(message.limit);
        /* string status = 91; */
        if (message.status !== "")
            writer.tag(91, WireType.LengthDelimited).string(message.status);
        /* bool is_disabled = 136; */
        if (message.isDisabled !== false)
            writer.tag(136, WireType.Varint).bool(message.isDisabled);
        /* bool is_fixed = 183; */
        if (message.isFixed !== false)
            writer.tag(183, WireType.Varint).bool(message.isFixed);
        /* double prematch_odd = 2; */
        if (message.prematchOdd !== 0)
            writer.tag(2, WireType.Bit64).double(message.prematchOdd);
        /* string event_id = 3; */
        if (message.eventId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.eventId);
        /* int32 outcome_id = 4; */
        if (message.outcomeId !== 0)
            writer.tag(4, WireType.Varint).int32(message.outcomeId);
        /* common.Error error = 77; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(77, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.Odd
 */
export const Odd = new Odd$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Sport$Type extends MessageType<Sport> {
    constructor() {
        super("odds.Sport", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "event_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 171, name: "filtered_event_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 137, name: "has_players", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 141, name: "has_groups", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 144, name: "manual_results", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 23, name: "locations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Location },
            { no: 52, name: "market_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 150, name: "basic_offer_live", kind: "message", T: () => BasicOffer },
            { no: 151, name: "basic_offer_prematch", kind: "message", T: () => BasicOffer }
        ]);
    }
    create(value?: PartialMessage<Sport>): Sport {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.eventCount = 0;
        message.filteredEventCount = 0;
        message.hasPlayers = false;
        message.hasGroups = false;
        message.manualResults = false;
        message.locations = [];
        message.marketIds = [];
        if (value !== undefined)
            reflectionMergePartial<Sport>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Sport): Sport {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 event_count */ 24:
                    message.eventCount = reader.int32();
                    break;
                case /* int32 filtered_event_count */ 171:
                    message.filteredEventCount = reader.int32();
                    break;
                case /* bool has_players */ 137:
                    message.hasPlayers = reader.bool();
                    break;
                case /* bool has_groups */ 141:
                    message.hasGroups = reader.bool();
                    break;
                case /* bool manual_results */ 144:
                    message.manualResults = reader.bool();
                    break;
                case /* repeated odds.Location locations */ 23:
                    message.locations.push(Location.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated int32 market_ids */ 52:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.marketIds.push(reader.int32());
                    else
                        message.marketIds.push(reader.int32());
                    break;
                case /* odds.BasicOffer basic_offer_live */ 150:
                    message.basicOfferLive = BasicOffer.internalBinaryRead(reader, reader.uint32(), options, message.basicOfferLive);
                    break;
                case /* odds.BasicOffer basic_offer_prematch */ 151:
                    message.basicOfferPrematch = BasicOffer.internalBinaryRead(reader, reader.uint32(), options, message.basicOfferPrematch);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Sport, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 event_count = 24; */
        if (message.eventCount !== 0)
            writer.tag(24, WireType.Varint).int32(message.eventCount);
        /* int32 filtered_event_count = 171; */
        if (message.filteredEventCount !== 0)
            writer.tag(171, WireType.Varint).int32(message.filteredEventCount);
        /* bool has_players = 137; */
        if (message.hasPlayers !== false)
            writer.tag(137, WireType.Varint).bool(message.hasPlayers);
        /* bool has_groups = 141; */
        if (message.hasGroups !== false)
            writer.tag(141, WireType.Varint).bool(message.hasGroups);
        /* bool manual_results = 144; */
        if (message.manualResults !== false)
            writer.tag(144, WireType.Varint).bool(message.manualResults);
        /* repeated odds.Location locations = 23; */
        for (let i = 0; i < message.locations.length; i++)
            Location.internalBinaryWrite(message.locations[i], writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* repeated int32 market_ids = 52; */
        if (message.marketIds.length) {
            writer.tag(52, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.marketIds.length; i++)
                writer.int32(message.marketIds[i]);
            writer.join();
        }
        /* odds.BasicOffer basic_offer_live = 150; */
        if (message.basicOfferLive)
            BasicOffer.internalBinaryWrite(message.basicOfferLive, writer.tag(150, WireType.LengthDelimited).fork(), options).join();
        /* odds.BasicOffer basic_offer_prematch = 151; */
        if (message.basicOfferPrematch)
            BasicOffer.internalBinaryWrite(message.basicOfferPrematch, writer.tag(151, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.Sport
 */
export const Sport = new Sport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BasicOffer$Type extends MessageType<BasicOffer> {
    constructor() {
        super("odds.BasicOffer", [
            { no: 1, name: "markets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BasicOfferMarket }
        ]);
    }
    create(value?: PartialMessage<BasicOffer>): BasicOffer {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.markets = [];
        if (value !== undefined)
            reflectionMergePartial<BasicOffer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BasicOffer): BasicOffer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.BasicOfferMarket markets */ 1:
                    message.markets.push(BasicOfferMarket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BasicOffer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.BasicOfferMarket markets = 1; */
        for (let i = 0; i < message.markets.length; i++)
            BasicOfferMarket.internalBinaryWrite(message.markets[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.BasicOffer
 */
export const BasicOffer = new BasicOffer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BasicOfferMarket$Type extends MessageType<BasicOfferMarket> {
    constructor() {
        super("odds.BasicOfferMarket", [
            { no: 1, name: "market_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "outcome_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<BasicOfferMarket>): BasicOfferMarket {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.marketId = 0;
        message.outcomeIds = [];
        if (value !== undefined)
            reflectionMergePartial<BasicOfferMarket>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BasicOfferMarket): BasicOfferMarket {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 market_id */ 1:
                    message.marketId = reader.int32();
                    break;
                case /* repeated int32 outcome_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.outcomeIds.push(reader.int32());
                    else
                        message.outcomeIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BasicOfferMarket, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 market_id = 1; */
        if (message.marketId !== 0)
            writer.tag(1, WireType.Varint).int32(message.marketId);
        /* repeated int32 outcome_ids = 2; */
        if (message.outcomeIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.outcomeIds.length; i++)
                writer.int32(message.outcomeIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.BasicOfferMarket
 */
export const BasicOfferMarket = new BasicOfferMarket$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Location$Type extends MessageType<Location> {
    constructor() {
        super("odds.Location", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "event_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 171, name: "filtered_event_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 25, name: "competitions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Competition }
        ]);
    }
    create(value?: PartialMessage<Location>): Location {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.eventCount = 0;
        message.filteredEventCount = 0;
        message.competitions = [];
        if (value !== undefined)
            reflectionMergePartial<Location>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Location): Location {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 event_count */ 24:
                    message.eventCount = reader.int32();
                    break;
                case /* int32 filtered_event_count */ 171:
                    message.filteredEventCount = reader.int32();
                    break;
                case /* repeated odds.Competition competitions */ 25:
                    message.competitions.push(Competition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Location, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 event_count = 24; */
        if (message.eventCount !== 0)
            writer.tag(24, WireType.Varint).int32(message.eventCount);
        /* int32 filtered_event_count = 171; */
        if (message.filteredEventCount !== 0)
            writer.tag(171, WireType.Varint).int32(message.filteredEventCount);
        /* repeated odds.Competition competitions = 25; */
        for (let i = 0; i < message.competitions.length; i++)
            Competition.internalBinaryWrite(message.competitions[i], writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.Location
 */
export const Location = new Location$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Competition$Type extends MessageType<Competition> {
    constructor() {
        super("odds.Competition", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 48, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "event_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 171, name: "filtered_event_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 107, name: "sport_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 40, name: "is_favorite", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 110, name: "is_automatic", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 109, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 108, name: "location_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 44, name: "competitors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Competitor },
            { no: 98, name: "links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Link },
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event },
            { no: 224, name: "market_template_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 305, name: "pa_sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 306, name: "pa_sport_name", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 307, name: "pa_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Competition>): Competition {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.shortName = "";
        message.eventCount = 0;
        message.filteredEventCount = 0;
        message.sportId = 0;
        message.sportName = "";
        message.isFavorite = false;
        message.isAutomatic = false;
        message.locationId = 0;
        message.locationName = "";
        message.competitors = [];
        message.links = [];
        message.events = [];
        message.marketTemplateId = 0;
        message.paSportId = 0;
        message.paSportName = 0;
        message.paType = "";
        if (value !== undefined)
            reflectionMergePartial<Competition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Competition): Competition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string short_name */ 48:
                    message.shortName = reader.string();
                    break;
                case /* int32 event_count */ 24:
                    message.eventCount = reader.int32();
                    break;
                case /* int32 filtered_event_count */ 171:
                    message.filteredEventCount = reader.int32();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* string sport_name */ 107:
                    message.sportName = reader.string();
                    break;
                case /* bool is_favorite */ 40:
                    message.isFavorite = reader.bool();
                    break;
                case /* bool is_automatic */ 110:
                    message.isAutomatic = reader.bool();
                    break;
                case /* int32 location_id */ 109:
                    message.locationId = reader.int32();
                    break;
                case /* string location_name */ 108:
                    message.locationName = reader.string();
                    break;
                case /* repeated odds.Competitor competitors */ 44:
                    message.competitors.push(Competitor.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated odds.Link links */ 98:
                    message.links.push(Link.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated odds.Event events */ 17:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 market_template_id */ 224:
                    message.marketTemplateId = reader.int32();
                    break;
                case /* int32 pa_sport_id */ 305:
                    message.paSportId = reader.int32();
                    break;
                case /* int32 pa_sport_name */ 306:
                    message.paSportName = reader.int32();
                    break;
                case /* string pa_type */ 307:
                    message.paType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Competition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string short_name = 48; */
        if (message.shortName !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.shortName);
        /* int32 event_count = 24; */
        if (message.eventCount !== 0)
            writer.tag(24, WireType.Varint).int32(message.eventCount);
        /* int32 filtered_event_count = 171; */
        if (message.filteredEventCount !== 0)
            writer.tag(171, WireType.Varint).int32(message.filteredEventCount);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* string sport_name = 107; */
        if (message.sportName !== "")
            writer.tag(107, WireType.LengthDelimited).string(message.sportName);
        /* bool is_favorite = 40; */
        if (message.isFavorite !== false)
            writer.tag(40, WireType.Varint).bool(message.isFavorite);
        /* bool is_automatic = 110; */
        if (message.isAutomatic !== false)
            writer.tag(110, WireType.Varint).bool(message.isAutomatic);
        /* int32 location_id = 109; */
        if (message.locationId !== 0)
            writer.tag(109, WireType.Varint).int32(message.locationId);
        /* string location_name = 108; */
        if (message.locationName !== "")
            writer.tag(108, WireType.LengthDelimited).string(message.locationName);
        /* repeated odds.Competitor competitors = 44; */
        for (let i = 0; i < message.competitors.length; i++)
            Competitor.internalBinaryWrite(message.competitors[i], writer.tag(44, WireType.LengthDelimited).fork(), options).join();
        /* repeated odds.Link links = 98; */
        for (let i = 0; i < message.links.length; i++)
            Link.internalBinaryWrite(message.links[i], writer.tag(98, WireType.LengthDelimited).fork(), options).join();
        /* repeated odds.Event events = 17; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* int32 market_template_id = 224; */
        if (message.marketTemplateId !== 0)
            writer.tag(224, WireType.Varint).int32(message.marketTemplateId);
        /* int32 pa_sport_id = 305; */
        if (message.paSportId !== 0)
            writer.tag(305, WireType.Varint).int32(message.paSportId);
        /* int32 pa_sport_name = 306; */
        if (message.paSportName !== 0)
            writer.tag(306, WireType.Varint).int32(message.paSportName);
        /* string pa_type = 307; */
        if (message.paType !== "")
            writer.tag(307, WireType.LengthDelimited).string(message.paType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.Competition
 */
export const Competition = new Competition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OddResponse$Type extends MessageType<OddResponse> {
    constructor() {
        super("odds.OddResponse", [
            { no: 1, name: "odd_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 45, name: "int_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "odd_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 46, name: "frame_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 47, name: "current_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "provider_odd_value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "event_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "event_profile", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "event_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "event_finished", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "event_published", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "event_suspicious", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "event_single_approval", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "event_landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "suspicious_level", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "competition_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 43, name: "competition_short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 44, name: "flag_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "competition_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 18, name: "competition_max_single", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 19, name: "competition_max_multiple", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 20, name: "competition_single_betting_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 21, name: "competition_multiple_betting_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 22, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 23, name: "market_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 24, name: "is_live", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 25, name: "start", kind: "message", T: () => Timestamp },
            { no: 26, name: "end_betting_time", kind: "message", T: () => Timestamp },
            { no: 27, name: "market_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "outcome_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "outcome_tic", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 31, name: "result", kind: "message", T: () => EventResult },
            { no: 32, name: "outcome_group", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 33, name: "limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 34, name: "event_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 35, name: "outright_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 36, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 37, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 38, name: "event_note", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 39, name: "outcome_limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 40, name: "pa_sport", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 41, name: "pa_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 42, name: "exefeed_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 48, name: "live_player", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OddResponse>): OddResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.oddId = "";
        message.intId = 0;
        message.oddValue = 0;
        message.frameNumber = 0;
        message.currentResult = "";
        message.providerOddValue = 0;
        message.sportId = 0;
        message.eventId = "";
        message.eventType = "";
        message.eventProfile = "";
        message.eventDisabled = false;
        message.eventFinished = false;
        message.eventPublished = false;
        message.eventSuspicious = false;
        message.eventSingleApproval = false;
        message.eventLandbaseCode = "";
        message.suspiciousLevel = "";
        message.competitionId = 0;
        message.competitionName = "";
        message.competitionShortName = "";
        message.flagCode = "";
        message.competitionAmount = 0;
        message.competitionMaxSingle = 0;
        message.competitionMaxMultiple = 0;
        message.competitionSingleBettingAmount = 0;
        message.competitionMultipleBettingAmount = 0;
        message.locationId = 0;
        message.marketId = 0;
        message.isLive = false;
        message.marketName = "";
        message.outcomeName = "";
        message.outcomeTic = "";
        message.outcomeId = 0;
        message.outcomeGroup = "";
        message.limit = 0;
        message.eventName = "";
        message.outrightType = "";
        message.isDisabled = false;
        message.landbaseCode = "";
        message.eventNote = "";
        message.outcomeLimit = 0;
        message.paSport = 0;
        message.paType = "";
        message.exefeedId = 0;
        message.livePlayer = "";
        if (value !== undefined)
            reflectionMergePartial<OddResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OddResponse): OddResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string odd_id */ 1:
                    message.oddId = reader.string();
                    break;
                case /* int32 int_id */ 45:
                    message.intId = reader.int32();
                    break;
                case /* double odd_value */ 2:
                    message.oddValue = reader.double();
                    break;
                case /* int32 frame_number */ 46:
                    message.frameNumber = reader.int32();
                    break;
                case /* string current_result */ 47:
                    message.currentResult = reader.string();
                    break;
                case /* double provider_odd_value */ 3:
                    message.providerOddValue = reader.double();
                    break;
                case /* int32 sport_id */ 4:
                    message.sportId = reader.int32();
                    break;
                case /* string event_id */ 5:
                    message.eventId = reader.string();
                    break;
                case /* string event_type */ 6:
                    message.eventType = reader.string();
                    break;
                case /* string event_profile */ 7:
                    message.eventProfile = reader.string();
                    break;
                case /* bool event_disabled */ 8:
                    message.eventDisabled = reader.bool();
                    break;
                case /* bool event_finished */ 9:
                    message.eventFinished = reader.bool();
                    break;
                case /* bool event_published */ 10:
                    message.eventPublished = reader.bool();
                    break;
                case /* bool event_suspicious */ 11:
                    message.eventSuspicious = reader.bool();
                    break;
                case /* bool event_single_approval */ 12:
                    message.eventSingleApproval = reader.bool();
                    break;
                case /* string event_landbase_code */ 13:
                    message.eventLandbaseCode = reader.string();
                    break;
                case /* string suspicious_level */ 14:
                    message.suspiciousLevel = reader.string();
                    break;
                case /* int32 competition_id */ 15:
                    message.competitionId = reader.int32();
                    break;
                case /* string competition_name */ 16:
                    message.competitionName = reader.string();
                    break;
                case /* string competition_short_name */ 43:
                    message.competitionShortName = reader.string();
                    break;
                case /* string flag_code */ 44:
                    message.flagCode = reader.string();
                    break;
                case /* double competition_amount */ 17:
                    message.competitionAmount = reader.double();
                    break;
                case /* double competition_max_single */ 18:
                    message.competitionMaxSingle = reader.double();
                    break;
                case /* double competition_max_multiple */ 19:
                    message.competitionMaxMultiple = reader.double();
                    break;
                case /* double competition_single_betting_amount */ 20:
                    message.competitionSingleBettingAmount = reader.double();
                    break;
                case /* double competition_multiple_betting_amount */ 21:
                    message.competitionMultipleBettingAmount = reader.double();
                    break;
                case /* int32 location_id */ 22:
                    message.locationId = reader.int32();
                    break;
                case /* int32 market_id */ 23:
                    message.marketId = reader.int32();
                    break;
                case /* bool is_live */ 24:
                    message.isLive = reader.bool();
                    break;
                case /* google.protobuf.Timestamp start */ 25:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end_betting_time */ 26:
                    message.endBettingTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.endBettingTime);
                    break;
                case /* string market_name */ 27:
                    message.marketName = reader.string();
                    break;
                case /* string outcome_name */ 28:
                    message.outcomeName = reader.string();
                    break;
                case /* string outcome_tic */ 29:
                    message.outcomeTic = reader.string();
                    break;
                case /* int32 outcome_id */ 30:
                    message.outcomeId = reader.int32();
                    break;
                case /* common.EventResult result */ 31:
                    message.result = EventResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                case /* string outcome_group */ 32:
                    message.outcomeGroup = reader.string();
                    break;
                case /* double limit */ 33:
                    message.limit = reader.double();
                    break;
                case /* string event_name */ 34:
                    message.eventName = reader.string();
                    break;
                case /* string outright_type */ 35:
                    message.outrightType = reader.string();
                    break;
                case /* bool is_disabled */ 36:
                    message.isDisabled = reader.bool();
                    break;
                case /* string landbase_code */ 37:
                    message.landbaseCode = reader.string();
                    break;
                case /* string event_note */ 38:
                    message.eventNote = reader.string();
                    break;
                case /* double outcome_limit */ 39:
                    message.outcomeLimit = reader.double();
                    break;
                case /* int32 pa_sport */ 40:
                    message.paSport = reader.int32();
                    break;
                case /* string pa_type */ 41:
                    message.paType = reader.string();
                    break;
                case /* int32 exefeed_id */ 42:
                    message.exefeedId = reader.int32();
                    break;
                case /* string live_player */ 48:
                    message.livePlayer = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OddResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string odd_id = 1; */
        if (message.oddId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.oddId);
        /* int32 int_id = 45; */
        if (message.intId !== 0)
            writer.tag(45, WireType.Varint).int32(message.intId);
        /* double odd_value = 2; */
        if (message.oddValue !== 0)
            writer.tag(2, WireType.Bit64).double(message.oddValue);
        /* int32 frame_number = 46; */
        if (message.frameNumber !== 0)
            writer.tag(46, WireType.Varint).int32(message.frameNumber);
        /* string current_result = 47; */
        if (message.currentResult !== "")
            writer.tag(47, WireType.LengthDelimited).string(message.currentResult);
        /* double provider_odd_value = 3; */
        if (message.providerOddValue !== 0)
            writer.tag(3, WireType.Bit64).double(message.providerOddValue);
        /* int32 sport_id = 4; */
        if (message.sportId !== 0)
            writer.tag(4, WireType.Varint).int32(message.sportId);
        /* string event_id = 5; */
        if (message.eventId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.eventId);
        /* string event_type = 6; */
        if (message.eventType !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.eventType);
        /* string event_profile = 7; */
        if (message.eventProfile !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.eventProfile);
        /* bool event_disabled = 8; */
        if (message.eventDisabled !== false)
            writer.tag(8, WireType.Varint).bool(message.eventDisabled);
        /* bool event_finished = 9; */
        if (message.eventFinished !== false)
            writer.tag(9, WireType.Varint).bool(message.eventFinished);
        /* bool event_published = 10; */
        if (message.eventPublished !== false)
            writer.tag(10, WireType.Varint).bool(message.eventPublished);
        /* bool event_suspicious = 11; */
        if (message.eventSuspicious !== false)
            writer.tag(11, WireType.Varint).bool(message.eventSuspicious);
        /* bool event_single_approval = 12; */
        if (message.eventSingleApproval !== false)
            writer.tag(12, WireType.Varint).bool(message.eventSingleApproval);
        /* string event_landbase_code = 13; */
        if (message.eventLandbaseCode !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.eventLandbaseCode);
        /* string suspicious_level = 14; */
        if (message.suspiciousLevel !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.suspiciousLevel);
        /* int32 competition_id = 15; */
        if (message.competitionId !== 0)
            writer.tag(15, WireType.Varint).int32(message.competitionId);
        /* string competition_name = 16; */
        if (message.competitionName !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.competitionName);
        /* string competition_short_name = 43; */
        if (message.competitionShortName !== "")
            writer.tag(43, WireType.LengthDelimited).string(message.competitionShortName);
        /* string flag_code = 44; */
        if (message.flagCode !== "")
            writer.tag(44, WireType.LengthDelimited).string(message.flagCode);
        /* double competition_amount = 17; */
        if (message.competitionAmount !== 0)
            writer.tag(17, WireType.Bit64).double(message.competitionAmount);
        /* double competition_max_single = 18; */
        if (message.competitionMaxSingle !== 0)
            writer.tag(18, WireType.Bit64).double(message.competitionMaxSingle);
        /* double competition_max_multiple = 19; */
        if (message.competitionMaxMultiple !== 0)
            writer.tag(19, WireType.Bit64).double(message.competitionMaxMultiple);
        /* double competition_single_betting_amount = 20; */
        if (message.competitionSingleBettingAmount !== 0)
            writer.tag(20, WireType.Bit64).double(message.competitionSingleBettingAmount);
        /* double competition_multiple_betting_amount = 21; */
        if (message.competitionMultipleBettingAmount !== 0)
            writer.tag(21, WireType.Bit64).double(message.competitionMultipleBettingAmount);
        /* int32 location_id = 22; */
        if (message.locationId !== 0)
            writer.tag(22, WireType.Varint).int32(message.locationId);
        /* int32 market_id = 23; */
        if (message.marketId !== 0)
            writer.tag(23, WireType.Varint).int32(message.marketId);
        /* bool is_live = 24; */
        if (message.isLive !== false)
            writer.tag(24, WireType.Varint).bool(message.isLive);
        /* google.protobuf.Timestamp start = 25; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end_betting_time = 26; */
        if (message.endBettingTime)
            Timestamp.internalBinaryWrite(message.endBettingTime, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* string market_name = 27; */
        if (message.marketName !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.marketName);
        /* string outcome_name = 28; */
        if (message.outcomeName !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.outcomeName);
        /* string outcome_tic = 29; */
        if (message.outcomeTic !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.outcomeTic);
        /* int32 outcome_id = 30; */
        if (message.outcomeId !== 0)
            writer.tag(30, WireType.Varint).int32(message.outcomeId);
        /* common.EventResult result = 31; */
        if (message.result)
            EventResult.internalBinaryWrite(message.result, writer.tag(31, WireType.LengthDelimited).fork(), options).join();
        /* string outcome_group = 32; */
        if (message.outcomeGroup !== "")
            writer.tag(32, WireType.LengthDelimited).string(message.outcomeGroup);
        /* double limit = 33; */
        if (message.limit !== 0)
            writer.tag(33, WireType.Bit64).double(message.limit);
        /* string event_name = 34; */
        if (message.eventName !== "")
            writer.tag(34, WireType.LengthDelimited).string(message.eventName);
        /* string outright_type = 35; */
        if (message.outrightType !== "")
            writer.tag(35, WireType.LengthDelimited).string(message.outrightType);
        /* bool is_disabled = 36; */
        if (message.isDisabled !== false)
            writer.tag(36, WireType.Varint).bool(message.isDisabled);
        /* string landbase_code = 37; */
        if (message.landbaseCode !== "")
            writer.tag(37, WireType.LengthDelimited).string(message.landbaseCode);
        /* string event_note = 38; */
        if (message.eventNote !== "")
            writer.tag(38, WireType.LengthDelimited).string(message.eventNote);
        /* double outcome_limit = 39; */
        if (message.outcomeLimit !== 0)
            writer.tag(39, WireType.Bit64).double(message.outcomeLimit);
        /* int32 pa_sport = 40; */
        if (message.paSport !== 0)
            writer.tag(40, WireType.Varint).int32(message.paSport);
        /* string pa_type = 41; */
        if (message.paType !== "")
            writer.tag(41, WireType.LengthDelimited).string(message.paType);
        /* int32 exefeed_id = 42; */
        if (message.exefeedId !== 0)
            writer.tag(42, WireType.Varint).int32(message.exefeedId);
        /* string live_player = 48; */
        if (message.livePlayer !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.livePlayer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.OddResponse
 */
export const OddResponse = new OddResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInitialDataResponse$Type extends MessageType<GetInitialDataResponse> {
    constructor() {
        super("odds.GetInitialDataResponse", [
            { no: 29, name: "market_groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MarketGroup },
            { no: 13, name: "sports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Sport }
        ]);
    }
    create(value?: PartialMessage<GetInitialDataResponse>): GetInitialDataResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.marketGroups = [];
        message.sports = [];
        if (value !== undefined)
            reflectionMergePartial<GetInitialDataResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInitialDataResponse): GetInitialDataResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.MarketGroup market_groups */ 29:
                    message.marketGroups.push(MarketGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated odds.Sport sports */ 13:
                    message.sports.push(Sport.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInitialDataResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.MarketGroup market_groups = 29; */
        for (let i = 0; i < message.marketGroups.length; i++)
            MarketGroup.internalBinaryWrite(message.marketGroups[i], writer.tag(29, WireType.LengthDelimited).fork(), options).join();
        /* repeated odds.Sport sports = 13; */
        for (let i = 0; i < message.sports.length; i++)
            Sport.internalBinaryWrite(message.sports[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetInitialDataResponse
 */
export const GetInitialDataResponse = new GetInitialDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInitialDataForBoResponse$Type extends MessageType<GetInitialDataForBoResponse> {
    constructor() {
        super("odds.GetInitialDataForBoResponse", [
            { no: 13, name: "sports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Sport },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetInitialDataForBoResponse>): GetInitialDataForBoResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sports = [];
        if (value !== undefined)
            reflectionMergePartial<GetInitialDataForBoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInitialDataForBoResponse): GetInitialDataForBoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Sport sports */ 13:
                    message.sports.push(Sport.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInitialDataForBoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Sport sports = 13; */
        for (let i = 0; i < message.sports.length; i++)
            Sport.internalBinaryWrite(message.sports[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetInitialDataForBoResponse
 */
export const GetInitialDataForBoResponse = new GetInitialDataForBoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProvidersResponse$Type extends MessageType<GetProvidersResponse> {
    constructor() {
        super("odds.GetProvidersResponse", [
            { no: 38, name: "providers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Provider }
        ]);
    }
    create(value?: PartialMessage<GetProvidersResponse>): GetProvidersResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.providers = [];
        if (value !== undefined)
            reflectionMergePartial<GetProvidersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProvidersResponse): GetProvidersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Provider providers */ 38:
                    message.providers.push(Provider.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProvidersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Provider providers = 38; */
        for (let i = 0; i < message.providers.length; i++)
            Provider.internalBinaryWrite(message.providers[i], writer.tag(38, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetProvidersResponse
 */
export const GetProvidersResponse = new GetProvidersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsResponse$Type extends MessageType<GetEventsResponse> {
    constructor() {
        super("odds.GetEventsResponse", [
            { no: 36, name: "live_events", kind: "message", T: () => LiveEvents },
            { no: 37, name: "upcoming_events", kind: "message", T: () => UpcomingEvents },
            { no: 247, name: "outright_events", kind: "message", T: () => UpcomingEvents },
            { no: 248, name: "player_events", kind: "message", T: () => UpcomingEvents },
            { no: 249, name: "group_events", kind: "message", T: () => UpcomingEvents }
        ]);
    }
    create(value?: PartialMessage<GetEventsResponse>): GetEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsResponse): GetEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* odds.LiveEvents live_events */ 36:
                    message.liveEvents = LiveEvents.internalBinaryRead(reader, reader.uint32(), options, message.liveEvents);
                    break;
                case /* odds.UpcomingEvents upcoming_events */ 37:
                    message.upcomingEvents = UpcomingEvents.internalBinaryRead(reader, reader.uint32(), options, message.upcomingEvents);
                    break;
                case /* odds.UpcomingEvents outright_events */ 247:
                    message.outrightEvents = UpcomingEvents.internalBinaryRead(reader, reader.uint32(), options, message.outrightEvents);
                    break;
                case /* odds.UpcomingEvents player_events */ 248:
                    message.playerEvents = UpcomingEvents.internalBinaryRead(reader, reader.uint32(), options, message.playerEvents);
                    break;
                case /* odds.UpcomingEvents group_events */ 249:
                    message.groupEvents = UpcomingEvents.internalBinaryRead(reader, reader.uint32(), options, message.groupEvents);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* odds.LiveEvents live_events = 36; */
        if (message.liveEvents)
            LiveEvents.internalBinaryWrite(message.liveEvents, writer.tag(36, WireType.LengthDelimited).fork(), options).join();
        /* odds.UpcomingEvents upcoming_events = 37; */
        if (message.upcomingEvents)
            UpcomingEvents.internalBinaryWrite(message.upcomingEvents, writer.tag(37, WireType.LengthDelimited).fork(), options).join();
        /* odds.UpcomingEvents outright_events = 247; */
        if (message.outrightEvents)
            UpcomingEvents.internalBinaryWrite(message.outrightEvents, writer.tag(247, WireType.LengthDelimited).fork(), options).join();
        /* odds.UpcomingEvents player_events = 248; */
        if (message.playerEvents)
            UpcomingEvents.internalBinaryWrite(message.playerEvents, writer.tag(248, WireType.LengthDelimited).fork(), options).join();
        /* odds.UpcomingEvents group_events = 249; */
        if (message.groupEvents)
            UpcomingEvents.internalBinaryWrite(message.groupEvents, writer.tag(249, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetEventsResponse
 */
export const GetEventsResponse = new GetEventsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LiveEvents$Type extends MessageType<LiveEvents> {
    constructor() {
        super("odds.LiveEvents", [
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StreamEvent },
            { no: 193, name: "disable_all", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 194, name: "disabled_sports", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<LiveEvents>): LiveEvents {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        message.disableAll = false;
        message.disabledSports = [];
        if (value !== undefined)
            reflectionMergePartial<LiveEvents>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LiveEvents): LiveEvents {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.StreamEvent events */ 17:
                    message.events.push(StreamEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool disable_all */ 193:
                    message.disableAll = reader.bool();
                    break;
                case /* repeated int32 disabled_sports */ 194:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.disabledSports.push(reader.int32());
                    else
                        message.disabledSports.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LiveEvents, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.StreamEvent events = 17; */
        for (let i = 0; i < message.events.length; i++)
            StreamEvent.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* bool disable_all = 193; */
        if (message.disableAll !== false)
            writer.tag(193, WireType.Varint).bool(message.disableAll);
        /* repeated int32 disabled_sports = 194; */
        if (message.disabledSports.length) {
            writer.tag(194, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.disabledSports.length; i++)
                writer.int32(message.disabledSports[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LiveEvents
 */
export const LiveEvents = new LiveEvents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LiveEventsBO$Type extends MessageType<LiveEventsBO> {
    constructor() {
        super("odds.LiveEventsBO", [
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event },
            { no: 193, name: "disable_all", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 194, name: "disabled_sports", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<LiveEventsBO>): LiveEventsBO {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        message.disableAll = false;
        message.disabledSports = [];
        if (value !== undefined)
            reflectionMergePartial<LiveEventsBO>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LiveEventsBO): LiveEventsBO {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Event events */ 17:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool disable_all */ 193:
                    message.disableAll = reader.bool();
                    break;
                case /* repeated int32 disabled_sports */ 194:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.disabledSports.push(reader.int32());
                    else
                        message.disabledSports.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LiveEventsBO, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Event events = 17; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* bool disable_all = 193; */
        if (message.disableAll !== false)
            writer.tag(193, WireType.Varint).bool(message.disableAll);
        /* repeated int32 disabled_sports = 194; */
        if (message.disabledSports.length) {
            writer.tag(194, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.disabledSports.length; i++)
                writer.int32(message.disabledSports[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LiveEventsBO
 */
export const LiveEventsBO = new LiveEventsBO$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpcomingEvents$Type extends MessageType<UpcomingEvents> {
    constructor() {
        super("odds.UpcomingEvents", [
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StreamEvent },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<UpcomingEvents>): UpcomingEvents {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<UpcomingEvents>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpcomingEvents): UpcomingEvents {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.StreamEvent events */ 17:
                    message.events.push(StreamEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpcomingEvents, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.StreamEvent events = 17; */
        for (let i = 0; i < message.events.length; i++)
            StreamEvent.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.UpcomingEvents
 */
export const UpcomingEvents = new UpcomingEvents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllEvents$Type extends MessageType<AllEvents> {
    constructor() {
        super("odds.AllEvents", [
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event }
        ]);
    }
    create(value?: PartialMessage<AllEvents>): AllEvents {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<AllEvents>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllEvents): AllEvents {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Event events */ 17:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AllEvents, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Event events = 17; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.AllEvents
 */
export const AllEvents = new AllEvents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SlipSubGroupOddResponse$Type extends MessageType<SlipSubGroupOddResponse> {
    constructor() {
        super("odds.SlipSubGroupOddResponse", [
            { no: 18, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OddResponse },
            { no: 31, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SlipSubGroupOddResponse>): SlipSubGroupOddResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.odds = [];
        message.type = "";
        if (value !== undefined)
            reflectionMergePartial<SlipSubGroupOddResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SlipSubGroupOddResponse): SlipSubGroupOddResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.OddResponse odds */ 18:
                    message.odds.push(OddResponse.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string type */ 31:
                    message.type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SlipSubGroupOddResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.OddResponse odds = 18; */
        for (let i = 0; i < message.odds.length; i++)
            OddResponse.internalBinaryWrite(message.odds[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* string type = 31; */
        if (message.type !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SlipSubGroupOddResponse
 */
export const SlipSubGroupOddResponse = new SlipSubGroupOddResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SlipGroupOddResponse$Type extends MessageType<SlipGroupOddResponse> {
    constructor() {
        super("odds.SlipGroupOddResponse", [
            { no: 77, name: "sub_groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SlipSubGroupOddResponse },
            { no: 31, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SlipGroupOddResponse>): SlipGroupOddResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.subGroups = [];
        message.type = "";
        if (value !== undefined)
            reflectionMergePartial<SlipGroupOddResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SlipGroupOddResponse): SlipGroupOddResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.SlipSubGroupOddResponse sub_groups */ 77:
                    message.subGroups.push(SlipSubGroupOddResponse.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string type */ 31:
                    message.type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SlipGroupOddResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.SlipSubGroupOddResponse sub_groups = 77; */
        for (let i = 0; i < message.subGroups.length; i++)
            SlipSubGroupOddResponse.internalBinaryWrite(message.subGroups[i], writer.tag(77, WireType.LengthDelimited).fork(), options).join();
        /* string type = 31; */
        if (message.type !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SlipGroupOddResponse
 */
export const SlipGroupOddResponse = new SlipGroupOddResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOddsResponse$Type extends MessageType<GetOddsResponse> {
    constructor() {
        super("odds.GetOddsResponse", [
            { no: 32, name: "slip_groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SlipGroupOddResponse },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetOddsResponse>): GetOddsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.slipGroups = [];
        if (value !== undefined)
            reflectionMergePartial<GetOddsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOddsResponse): GetOddsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.SlipGroupOddResponse slip_groups */ 32:
                    message.slipGroups.push(SlipGroupOddResponse.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOddsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.SlipGroupOddResponse slip_groups = 32; */
        for (let i = 0; i < message.slipGroups.length; i++)
            SlipGroupOddResponse.internalBinaryWrite(message.slipGroups[i], writer.tag(32, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetOddsResponse
 */
export const GetOddsResponse = new GetOddsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Provider$Type extends MessageType<Provider> {
    constructor() {
        super("odds.Provider", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "alias", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 105, name: "live_provider", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 106, name: "prematch_provider", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 95, name: "provider_competitors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProviderCompetitor },
            { no: 103, name: "provider_players", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProviderPlayer },
            { no: 117, name: "provider_outcomes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProviderOutcome },
            { no: 128, name: "provider_competitions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProviderCompetition }
        ]);
    }
    create(value?: PartialMessage<Provider>): Provider {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.alias = "";
        message.liveProvider = false;
        message.prematchProvider = false;
        message.providerCompetitors = [];
        message.providerPlayers = [];
        message.providerOutcomes = [];
        message.providerCompetitions = [];
        if (value !== undefined)
            reflectionMergePartial<Provider>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Provider): Provider {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string alias */ 3:
                    message.alias = reader.string();
                    break;
                case /* bool live_provider */ 105:
                    message.liveProvider = reader.bool();
                    break;
                case /* bool prematch_provider */ 106:
                    message.prematchProvider = reader.bool();
                    break;
                case /* repeated odds.ProviderCompetitor provider_competitors */ 95:
                    message.providerCompetitors.push(ProviderCompetitor.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated odds.ProviderPlayer provider_players */ 103:
                    message.providerPlayers.push(ProviderPlayer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated odds.ProviderOutcome provider_outcomes */ 117:
                    message.providerOutcomes.push(ProviderOutcome.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated odds.ProviderCompetition provider_competitions */ 128:
                    message.providerCompetitions.push(ProviderCompetition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Provider, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string alias = 3; */
        if (message.alias !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.alias);
        /* bool live_provider = 105; */
        if (message.liveProvider !== false)
            writer.tag(105, WireType.Varint).bool(message.liveProvider);
        /* bool prematch_provider = 106; */
        if (message.prematchProvider !== false)
            writer.tag(106, WireType.Varint).bool(message.prematchProvider);
        /* repeated odds.ProviderCompetitor provider_competitors = 95; */
        for (let i = 0; i < message.providerCompetitors.length; i++)
            ProviderCompetitor.internalBinaryWrite(message.providerCompetitors[i], writer.tag(95, WireType.LengthDelimited).fork(), options).join();
        /* repeated odds.ProviderPlayer provider_players = 103; */
        for (let i = 0; i < message.providerPlayers.length; i++)
            ProviderPlayer.internalBinaryWrite(message.providerPlayers[i], writer.tag(103, WireType.LengthDelimited).fork(), options).join();
        /* repeated odds.ProviderOutcome provider_outcomes = 117; */
        for (let i = 0; i < message.providerOutcomes.length; i++)
            ProviderOutcome.internalBinaryWrite(message.providerOutcomes[i], writer.tag(117, WireType.LengthDelimited).fork(), options).join();
        /* repeated odds.ProviderCompetition provider_competitions = 128; */
        for (let i = 0; i < message.providerCompetitions.length; i++)
            ProviderCompetition.internalBinaryWrite(message.providerCompetitions[i], writer.tag(128, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.Provider
 */
export const Provider = new Provider$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateOfferEvent$Type extends MessageType<CreateOfferEvent> {
    constructor() {
        super("odds.CreateOfferEvent", [
            { no: 111, name: "live_provider_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 112, name: "prematch_provider_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 113, name: "live_event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 114, name: "prematch_event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateOfferEvent>): CreateOfferEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.liveProviderId = 0;
        message.prematchProviderId = 0;
        message.liveEventId = "";
        message.prematchEventId = "";
        if (value !== undefined)
            reflectionMergePartial<CreateOfferEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateOfferEvent): CreateOfferEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 live_provider_id */ 111:
                    message.liveProviderId = reader.int32();
                    break;
                case /* int32 prematch_provider_id */ 112:
                    message.prematchProviderId = reader.int32();
                    break;
                case /* string live_event_id */ 113:
                    message.liveEventId = reader.string();
                    break;
                case /* string prematch_event_id */ 114:
                    message.prematchEventId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateOfferEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 live_provider_id = 111; */
        if (message.liveProviderId !== 0)
            writer.tag(111, WireType.Varint).int32(message.liveProviderId);
        /* int32 prematch_provider_id = 112; */
        if (message.prematchProviderId !== 0)
            writer.tag(112, WireType.Varint).int32(message.prematchProviderId);
        /* string live_event_id = 113; */
        if (message.liveEventId !== "")
            writer.tag(113, WireType.LengthDelimited).string(message.liveEventId);
        /* string prematch_event_id = 114; */
        if (message.prematchEventId !== "")
            writer.tag(114, WireType.LengthDelimited).string(message.prematchEventId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.CreateOfferEvent
 */
export const CreateOfferEvent = new CreateOfferEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateOfferRequest$Type extends MessageType<CreateOfferRequest> {
    constructor() {
        super("odds.CreateOfferRequest", [
            { no: 115, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CreateOfferEvent }
        ]);
    }
    create(value?: PartialMessage<CreateOfferRequest>): CreateOfferRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<CreateOfferRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateOfferRequest): CreateOfferRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.CreateOfferEvent events */ 115:
                    message.events.push(CreateOfferEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateOfferRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.CreateOfferEvent events = 115; */
        for (let i = 0; i < message.events.length; i++)
            CreateOfferEvent.internalBinaryWrite(message.events[i], writer.tag(115, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.CreateOfferRequest
 */
export const CreateOfferRequest = new CreateOfferRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllLandBaseOffersResponse$Type extends MessageType<GetAllLandBaseOffersResponse> {
    constructor() {
        super("odds.GetAllLandBaseOffersResponse", [
            { no: 59, name: "offers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SingleLandBaseOfferResponse }
        ]);
    }
    create(value?: PartialMessage<GetAllLandBaseOffersResponse>): GetAllLandBaseOffersResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.offers = [];
        if (value !== undefined)
            reflectionMergePartial<GetAllLandBaseOffersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllLandBaseOffersResponse): GetAllLandBaseOffersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.SingleLandBaseOfferResponse offers */ 59:
                    message.offers.push(SingleLandBaseOfferResponse.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllLandBaseOffersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.SingleLandBaseOfferResponse offers = 59; */
        for (let i = 0; i < message.offers.length; i++)
            SingleLandBaseOfferResponse.internalBinaryWrite(message.offers[i], writer.tag(59, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetAllLandBaseOffersResponse
 */
export const GetAllLandBaseOffersResponse = new GetAllLandBaseOffersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SingleLandBaseOfferResponse$Type extends MessageType<SingleLandBaseOfferResponse> {
    constructor() {
        super("odds.SingleLandBaseOfferResponse", [
            { no: 56, name: "start", kind: "message", T: () => Timestamp },
            { no: 57, name: "end", kind: "message", T: () => Timestamp },
            { no: 58, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SingleLandBaseOfferResponse>): SingleLandBaseOfferResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<SingleLandBaseOfferResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SingleLandBaseOfferResponse): SingleLandBaseOfferResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp start */ 56:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 57:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                case /* int32 id */ 58:
                    message.id = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SingleLandBaseOfferResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp start = 56; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(56, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 57; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(57, WireType.LengthDelimited).fork(), options).join();
        /* int32 id = 58; */
        if (message.id !== 0)
            writer.tag(58, WireType.Varint).int32(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SingleLandBaseOfferResponse
 */
export const SingleLandBaseOfferResponse = new SingleLandBaseOfferResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateLandBaseOfferRequest$Type extends MessageType<CreateLandBaseOfferRequest> {
    constructor() {
        super("odds.CreateLandBaseOfferRequest", [
            { no: 60, name: "start", kind: "message", T: () => Timestamp },
            { no: 61, name: "end", kind: "message", T: () => Timestamp },
            { no: 62, name: "event_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateLandBaseOfferRequest>): CreateLandBaseOfferRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventIds = [];
        if (value !== undefined)
            reflectionMergePartial<CreateLandBaseOfferRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateLandBaseOfferRequest): CreateLandBaseOfferRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp start */ 60:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 61:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                case /* repeated string event_ids */ 62:
                    message.eventIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateLandBaseOfferRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp start = 60; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(60, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 61; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(61, WireType.LengthDelimited).fork(), options).join();
        /* repeated string event_ids = 62; */
        for (let i = 0; i < message.eventIds.length; i++)
            writer.tag(62, WireType.LengthDelimited).string(message.eventIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.CreateLandBaseOfferRequest
 */
export const CreateLandBaseOfferRequest = new CreateLandBaseOfferRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateLandBaseOfferResponse$Type extends MessageType<CreateLandBaseOfferResponse> {
    constructor() {
        super("odds.CreateLandBaseOfferResponse", [
            { no: 60, name: "start", kind: "message", T: () => Timestamp },
            { no: 61, name: "end", kind: "message", T: () => Timestamp },
            { no: 62, name: "event_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 78, name: "error", kind: "message", T: () => Error },
            { no: 71, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CreateLandBaseOfferResponse>): CreateLandBaseOfferResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventIds = [];
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<CreateLandBaseOfferResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateLandBaseOfferResponse): CreateLandBaseOfferResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp start */ 60:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 61:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                case /* repeated string event_ids */ 62:
                    message.eventIds.push(reader.string());
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                case /* int32 id */ 71:
                    message.id = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateLandBaseOfferResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp start = 60; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(60, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 61; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(61, WireType.LengthDelimited).fork(), options).join();
        /* repeated string event_ids = 62; */
        for (let i = 0; i < message.eventIds.length; i++)
            writer.tag(62, WireType.LengthDelimited).string(message.eventIds[i]);
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        /* int32 id = 71; */
        if (message.id !== 0)
            writer.tag(71, WireType.Varint).int32(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.CreateLandBaseOfferResponse
 */
export const CreateLandBaseOfferResponse = new CreateLandBaseOfferResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeRange$Type extends MessageType<TimeRange> {
    constructor() {
        super("odds.TimeRange", [
            { no: 64, name: "start", kind: "message", T: () => Timestamp },
            { no: 65, name: "end", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<TimeRange>): TimeRange {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<TimeRange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeRange): TimeRange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp start */ 64:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 65:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeRange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp start = 64; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(64, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 65; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(65, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.TimeRange
 */
export const TimeRange = new TimeRange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LandBaseId$Type extends MessageType<LandBaseId> {
    constructor() {
        super("odds.LandBaseId", [
            { no: 66, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<LandBaseId>): LandBaseId {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<LandBaseId>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LandBaseId): LandBaseId {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 66:
                    message.id = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LandBaseId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 66; */
        if (message.id !== 0)
            writer.tag(66, WireType.Varint).int32(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LandBaseId
 */
export const LandBaseId = new LandBaseId$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditLandBaseOfferRequest$Type extends MessageType<EditLandBaseOfferRequest> {
    constructor() {
        super("odds.EditLandBaseOfferRequest", [
            { no: 73, name: "start", kind: "message", T: () => Timestamp },
            { no: 74, name: "end", kind: "message", T: () => Timestamp },
            { no: 75, name: "event_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 76, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<EditLandBaseOfferRequest>): EditLandBaseOfferRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventIds = [];
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<EditLandBaseOfferRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditLandBaseOfferRequest): EditLandBaseOfferRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp start */ 73:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 74:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                case /* repeated string event_ids */ 75:
                    message.eventIds.push(reader.string());
                    break;
                case /* int32 id */ 76:
                    message.id = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditLandBaseOfferRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp start = 73; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(73, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 74; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(74, WireType.LengthDelimited).fork(), options).join();
        /* repeated string event_ids = 75; */
        for (let i = 0; i < message.eventIds.length; i++)
            writer.tag(75, WireType.LengthDelimited).string(message.eventIds[i]);
        /* int32 id = 76; */
        if (message.id !== 0)
            writer.tag(76, WireType.Varint).int32(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.EditLandBaseOfferRequest
 */
export const EditLandBaseOfferRequest = new EditLandBaseOfferRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditLandBaseOfferResponse$Type extends MessageType<EditLandBaseOfferResponse> {
    constructor() {
        super("odds.EditLandBaseOfferResponse", [
            { no: 67, name: "start", kind: "message", T: () => Timestamp },
            { no: 68, name: "end", kind: "message", T: () => Timestamp },
            { no: 69, name: "event_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 78, name: "error", kind: "message", T: () => Error },
            { no: 72, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<EditLandBaseOfferResponse>): EditLandBaseOfferResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventIds = [];
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<EditLandBaseOfferResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditLandBaseOfferResponse): EditLandBaseOfferResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp start */ 67:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 68:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                case /* repeated string event_ids */ 69:
                    message.eventIds.push(reader.string());
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                case /* int32 id */ 72:
                    message.id = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditLandBaseOfferResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp start = 67; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(67, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 68; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(68, WireType.LengthDelimited).fork(), options).join();
        /* repeated string event_ids = 69; */
        for (let i = 0; i < message.eventIds.length; i++)
            writer.tag(69, WireType.LengthDelimited).string(message.eventIds[i]);
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        /* int32 id = 72; */
        if (message.id !== 0)
            writer.tag(72, WireType.Varint).int32(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.EditLandBaseOfferResponse
 */
export const EditLandBaseOfferResponse = new EditLandBaseOfferResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchEventsRequest$Type extends MessageType<SearchEventsRequest> {
    constructor() {
        super("odds.SearchEventsRequest", [
            { no: 79, name: "criteria", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchEventsRequest>): SearchEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.criteria = "";
        if (value !== undefined)
            reflectionMergePartial<SearchEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchEventsRequest): SearchEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string criteria */ 79:
                    message.criteria = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string criteria = 79; */
        if (message.criteria !== "")
            writer.tag(79, WireType.LengthDelimited).string(message.criteria);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SearchEventsRequest
 */
export const SearchEventsRequest = new SearchEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOutcomesRequest$Type extends MessageType<SearchOutcomesRequest> {
    constructor() {
        super("odds.SearchOutcomesRequest", [
            { no: 12, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 81, name: "outcome_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchOutcomesRequest>): SearchOutcomesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventId = "";
        message.outcomeCode = "";
        if (value !== undefined)
            reflectionMergePartial<SearchOutcomesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOutcomesRequest): SearchOutcomesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_id */ 12:
                    message.eventId = reader.string();
                    break;
                case /* string outcome_code */ 81:
                    message.outcomeCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOutcomesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event_id = 12; */
        if (message.eventId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.eventId);
        /* string outcome_code = 81; */
        if (message.outcomeCode !== "")
            writer.tag(81, WireType.LengthDelimited).string(message.outcomeCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SearchOutcomesRequest
 */
export const SearchOutcomesRequest = new SearchOutcomesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchOutcomesResponse$Type extends MessageType<SearchOutcomesResponse> {
    constructor() {
        super("odds.SearchOutcomesResponse", [
            { no: 27, name: "outcomes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Outcome },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<SearchOutcomesResponse>): SearchOutcomesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.outcomes = [];
        if (value !== undefined)
            reflectionMergePartial<SearchOutcomesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchOutcomesResponse): SearchOutcomesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Outcome outcomes */ 27:
                    message.outcomes.push(Outcome.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchOutcomesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Outcome outcomes = 27; */
        for (let i = 0; i < message.outcomes.length; i++)
            Outcome.internalBinaryWrite(message.outcomes[i], writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SearchOutcomesResponse
 */
export const SearchOutcomesResponse = new SearchOutcomesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchCompetitorRequest$Type extends MessageType<SearchCompetitorRequest> {
    constructor() {
        super("odds.SearchCompetitorRequest", [
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchCompetitorRequest>): SearchCompetitorRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<SearchCompetitorRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchCompetitorRequest): SearchCompetitorRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchCompetitorRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SearchCompetitorRequest
 */
export const SearchCompetitorRequest = new SearchCompetitorRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LinkCompetitorRequest$Type extends MessageType<LinkCompetitorRequest> {
    constructor() {
        super("odds.LinkCompetitorRequest", [
            { no: 83, name: "factory_competitor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 98, name: "links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Link }
        ]);
    }
    create(value?: PartialMessage<LinkCompetitorRequest>): LinkCompetitorRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.factoryCompetitorId = 0;
        message.links = [];
        if (value !== undefined)
            reflectionMergePartial<LinkCompetitorRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LinkCompetitorRequest): LinkCompetitorRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 factory_competitor_id */ 83:
                    message.factoryCompetitorId = reader.int32();
                    break;
                case /* repeated odds.Link links */ 98:
                    message.links.push(Link.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LinkCompetitorRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 factory_competitor_id = 83; */
        if (message.factoryCompetitorId !== 0)
            writer.tag(83, WireType.Varint).int32(message.factoryCompetitorId);
        /* repeated odds.Link links = 98; */
        for (let i = 0; i < message.links.length; i++)
            Link.internalBinaryWrite(message.links[i], writer.tag(98, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LinkCompetitorRequest
 */
export const LinkCompetitorRequest = new LinkCompetitorRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LinkCompetitionRequest$Type extends MessageType<LinkCompetitionRequest> {
    constructor() {
        super("odds.LinkCompetitionRequest", [
            { no: 4, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "live_competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "prematch_competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<LinkCompetitionRequest>): LinkCompetitionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.competitionId = 0;
        message.liveCompetitionId = 0;
        message.prematchCompetitionId = 0;
        if (value !== undefined)
            reflectionMergePartial<LinkCompetitionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LinkCompetitionRequest): LinkCompetitionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 competition_id */ 4:
                    message.competitionId = reader.int32();
                    break;
                case /* int32 live_competition_id */ 5:
                    message.liveCompetitionId = reader.int32();
                    break;
                case /* int32 prematch_competition_id */ 6:
                    message.prematchCompetitionId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LinkCompetitionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 competition_id = 4; */
        if (message.competitionId !== 0)
            writer.tag(4, WireType.Varint).int32(message.competitionId);
        /* int32 live_competition_id = 5; */
        if (message.liveCompetitionId !== 0)
            writer.tag(5, WireType.Varint).int32(message.liveCompetitionId);
        /* int32 prematch_competition_id = 6; */
        if (message.prematchCompetitionId !== 0)
            writer.tag(6, WireType.Varint).int32(message.prematchCompetitionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LinkCompetitionRequest
 */
export const LinkCompetitionRequest = new LinkCompetitionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStatusesForEventsAndOddsRequest$Type extends MessageType<GetStatusesForEventsAndOddsRequest> {
    constructor() {
        super("odds.GetStatusesForEventsAndOddsRequest", [
            { no: 86, name: "event_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 87, name: "odd_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetStatusesForEventsAndOddsRequest>): GetStatusesForEventsAndOddsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventIds = [];
        message.oddIds = [];
        if (value !== undefined)
            reflectionMergePartial<GetStatusesForEventsAndOddsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetStatusesForEventsAndOddsRequest): GetStatusesForEventsAndOddsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string event_ids */ 86:
                    message.eventIds.push(reader.string());
                    break;
                case /* repeated string odd_ids */ 87:
                    message.oddIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetStatusesForEventsAndOddsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string event_ids = 86; */
        for (let i = 0; i < message.eventIds.length; i++)
            writer.tag(86, WireType.LengthDelimited).string(message.eventIds[i]);
        /* repeated string odd_ids = 87; */
        for (let i = 0; i < message.oddIds.length; i++)
            writer.tag(87, WireType.LengthDelimited).string(message.oddIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetStatusesForEventsAndOddsRequest
 */
export const GetStatusesForEventsAndOddsRequest = new GetStatusesForEventsAndOddsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OddStatus$Type extends MessageType<OddStatus> {
    constructor() {
        super("odds.OddStatus", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "is_live", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "start", kind: "message", T: () => Timestamp },
            { no: 7, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "result", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OddStatus>): OddStatus {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.status = "";
        message.value = 0;
        message.isLive = false;
        message.isDisabled = false;
        message.type = "";
        message.result = "";
        if (value !== undefined)
            reflectionMergePartial<OddStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OddStatus): OddStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string status */ 2:
                    message.status = reader.string();
                    break;
                case /* double value */ 3:
                    message.value = reader.double();
                    break;
                case /* bool is_live */ 4:
                    message.isLive = reader.bool();
                    break;
                case /* bool is_disabled */ 5:
                    message.isDisabled = reader.bool();
                    break;
                case /* google.protobuf.Timestamp start */ 6:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* string type */ 7:
                    message.type = reader.string();
                    break;
                case /* string result */ 8:
                    message.result = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OddStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string status = 2; */
        if (message.status !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.status);
        /* double value = 3; */
        if (message.value !== 0)
            writer.tag(3, WireType.Bit64).double(message.value);
        /* bool is_live = 4; */
        if (message.isLive !== false)
            writer.tag(4, WireType.Varint).bool(message.isLive);
        /* bool is_disabled = 5; */
        if (message.isDisabled !== false)
            writer.tag(5, WireType.Varint).bool(message.isDisabled);
        /* google.protobuf.Timestamp start = 6; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string type = 7; */
        if (message.type !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.type);
        /* string result = 8; */
        if (message.result !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.result);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.OddStatus
 */
export const OddStatus = new OddStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStatusesForEventsAndOddsResponse$Type extends MessageType<GetStatusesForEventsAndOddsResponse> {
    constructor() {
        super("odds.GetStatusesForEventsAndOddsResponse", [
            { no: 89, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OddStatus }
        ]);
    }
    create(value?: PartialMessage<GetStatusesForEventsAndOddsResponse>): GetStatusesForEventsAndOddsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.odds = [];
        if (value !== undefined)
            reflectionMergePartial<GetStatusesForEventsAndOddsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetStatusesForEventsAndOddsResponse): GetStatusesForEventsAndOddsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.OddStatus odds */ 89:
                    message.odds.push(OddStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetStatusesForEventsAndOddsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.OddStatus odds = 89; */
        for (let i = 0; i < message.odds.length; i++)
            OddStatus.internalBinaryWrite(message.odds[i], writer.tag(89, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetStatusesForEventsAndOddsResponse
 */
export const GetStatusesForEventsAndOddsResponse = new GetStatusesForEventsAndOddsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProviderCompetitorsRequest$Type extends MessageType<GetProviderCompetitorsRequest> {
    constructor() {
        super("odds.GetProviderCompetitorsRequest", [
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetProviderCompetitorsRequest>): GetProviderCompetitorsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetProviderCompetitorsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProviderCompetitorsRequest): GetProviderCompetitorsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProviderCompetitorsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetProviderCompetitorsRequest
 */
export const GetProviderCompetitorsRequest = new GetProviderCompetitorsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProviderCompetitorsResponse$Type extends MessageType<GetProviderCompetitorsResponse> {
    constructor() {
        super("odds.GetProviderCompetitorsResponse", [
            { no: 38, name: "providers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Provider },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetProviderCompetitorsResponse>): GetProviderCompetitorsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.providers = [];
        if (value !== undefined)
            reflectionMergePartial<GetProviderCompetitorsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProviderCompetitorsResponse): GetProviderCompetitorsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Provider providers */ 38:
                    message.providers.push(Provider.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProviderCompetitorsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Provider providers = 38; */
        for (let i = 0; i < message.providers.length; i++)
            Provider.internalBinaryWrite(message.providers[i], writer.tag(38, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetProviderCompetitorsResponse
 */
export const GetProviderCompetitorsResponse = new GetProviderCompetitorsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProviderCompetitionsRequest$Type extends MessageType<GetProviderCompetitionsRequest> {
    constructor() {
        super("odds.GetProviderCompetitionsRequest", [
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetProviderCompetitionsRequest>): GetProviderCompetitionsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetProviderCompetitionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProviderCompetitionsRequest): GetProviderCompetitionsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProviderCompetitionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetProviderCompetitionsRequest
 */
export const GetProviderCompetitionsRequest = new GetProviderCompetitionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProviderCompetitionsResponse$Type extends MessageType<GetProviderCompetitionsResponse> {
    constructor() {
        super("odds.GetProviderCompetitionsResponse", [
            { no: 38, name: "providers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Provider },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetProviderCompetitionsResponse>): GetProviderCompetitionsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.providers = [];
        if (value !== undefined)
            reflectionMergePartial<GetProviderCompetitionsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProviderCompetitionsResponse): GetProviderCompetitionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Provider providers */ 38:
                    message.providers.push(Provider.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProviderCompetitionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Provider providers = 38; */
        for (let i = 0; i < message.providers.length; i++)
            Provider.internalBinaryWrite(message.providers[i], writer.tag(38, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetProviderCompetitionsResponse
 */
export const GetProviderCompetitionsResponse = new GetProviderCompetitionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProviderCompetitor$Type extends MessageType<ProviderCompetitor> {
    constructor() {
        super("odds.ProviderCompetitor", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 45, name: "competition_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 46, name: "location", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 83, name: "factory_competitor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 96, name: "factory_competitor_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProviderCompetitor>): ProviderCompetitor {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.competitionNames = [];
        message.location = [];
        message.factoryCompetitorId = 0;
        message.factoryCompetitorName = "";
        if (value !== undefined)
            reflectionMergePartial<ProviderCompetitor>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProviderCompetitor): ProviderCompetitor {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated string competition_names */ 45:
                    message.competitionNames.push(reader.string());
                    break;
                case /* repeated string location */ 46:
                    message.location.push(reader.string());
                    break;
                case /* int32 factory_competitor_id */ 83:
                    message.factoryCompetitorId = reader.int32();
                    break;
                case /* string factory_competitor_name */ 96:
                    message.factoryCompetitorName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProviderCompetitor, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated string competition_names = 45; */
        for (let i = 0; i < message.competitionNames.length; i++)
            writer.tag(45, WireType.LengthDelimited).string(message.competitionNames[i]);
        /* repeated string location = 46; */
        for (let i = 0; i < message.location.length; i++)
            writer.tag(46, WireType.LengthDelimited).string(message.location[i]);
        /* int32 factory_competitor_id = 83; */
        if (message.factoryCompetitorId !== 0)
            writer.tag(83, WireType.Varint).int32(message.factoryCompetitorId);
        /* string factory_competitor_name = 96; */
        if (message.factoryCompetitorName !== "")
            writer.tag(96, WireType.LengthDelimited).string(message.factoryCompetitorName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.ProviderCompetitor
 */
export const ProviderCompetitor = new ProviderCompetitor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProviderPlayer$Type extends MessageType<ProviderPlayer> {
    constructor() {
        super("odds.ProviderPlayer", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 82, name: "provider_competitor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 100, name: "provider_competitor_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 102, name: "factory_player_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 104, name: "factory_player_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProviderPlayer>): ProviderPlayer {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.providerCompetitorId = 0;
        message.providerCompetitorName = "";
        message.factoryPlayerId = 0;
        message.factoryPlayerName = "";
        if (value !== undefined)
            reflectionMergePartial<ProviderPlayer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProviderPlayer): ProviderPlayer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 provider_competitor_id */ 82:
                    message.providerCompetitorId = reader.int32();
                    break;
                case /* string provider_competitor_name */ 100:
                    message.providerCompetitorName = reader.string();
                    break;
                case /* int32 factory_player_id */ 102:
                    message.factoryPlayerId = reader.int32();
                    break;
                case /* string factory_player_name */ 104:
                    message.factoryPlayerName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProviderPlayer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 provider_competitor_id = 82; */
        if (message.providerCompetitorId !== 0)
            writer.tag(82, WireType.Varint).int32(message.providerCompetitorId);
        /* string provider_competitor_name = 100; */
        if (message.providerCompetitorName !== "")
            writer.tag(100, WireType.LengthDelimited).string(message.providerCompetitorName);
        /* int32 factory_player_id = 102; */
        if (message.factoryPlayerId !== 0)
            writer.tag(102, WireType.Varint).int32(message.factoryPlayerId);
        /* string factory_player_name = 104; */
        if (message.factoryPlayerName !== "")
            writer.tag(104, WireType.LengthDelimited).string(message.factoryPlayerName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.ProviderPlayer
 */
export const ProviderPlayer = new ProviderPlayer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProviderOutcome$Type extends MessageType<ProviderOutcome> {
    constructor() {
        super("odds.ProviderOutcome", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "factory_sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ProviderOutcome>): ProviderOutcome {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.factorySportId = 0;
        if (value !== undefined)
            reflectionMergePartial<ProviderOutcome>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProviderOutcome): ProviderOutcome {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 factory_sport_id */ 34:
                    message.factorySportId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProviderOutcome, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 factory_sport_id = 34; */
        if (message.factorySportId !== 0)
            writer.tag(34, WireType.Varint).int32(message.factorySportId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.ProviderOutcome
 */
export const ProviderOutcome = new ProviderOutcome$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPlayersResponse$Type extends MessageType<GetPlayersResponse> {
    constructor() {
        super("odds.GetPlayersResponse", [
            { no: 138, name: "players", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Player }
        ]);
    }
    create(value?: PartialMessage<GetPlayersResponse>): GetPlayersResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.players = [];
        if (value !== undefined)
            reflectionMergePartial<GetPlayersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPlayersResponse): GetPlayersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Player players */ 138:
                    message.players.push(Player.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPlayersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Player players = 138; */
        for (let i = 0; i < message.players.length; i++)
            Player.internalBinaryWrite(message.players[i], writer.tag(138, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetPlayersResponse
 */
export const GetPlayersResponse = new GetPlayersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Player$Type extends MessageType<Player> {
    constructor() {
        super("odds.Player", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 48, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 96, name: "factory_competitor_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 45, name: "competition_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 108, name: "location_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 47, name: "sport", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 50, name: "last_updated", kind: "message", T: () => Timestamp },
            { no: 98, name: "links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Link }
        ]);
    }
    create(value?: PartialMessage<Player>): Player {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.shortName = "";
        message.factoryCompetitorName = "";
        message.competitionNames = [];
        message.locationName = "";
        message.sport = "";
        message.links = [];
        if (value !== undefined)
            reflectionMergePartial<Player>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Player): Player {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string short_name */ 48:
                    message.shortName = reader.string();
                    break;
                case /* string factory_competitor_name */ 96:
                    message.factoryCompetitorName = reader.string();
                    break;
                case /* repeated string competition_names */ 45:
                    message.competitionNames.push(reader.string());
                    break;
                case /* string location_name */ 108:
                    message.locationName = reader.string();
                    break;
                case /* string sport */ 47:
                    message.sport = reader.string();
                    break;
                case /* google.protobuf.Timestamp last_updated */ 50:
                    message.lastUpdated = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.lastUpdated);
                    break;
                case /* repeated odds.Link links */ 98:
                    message.links.push(Link.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Player, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string short_name = 48; */
        if (message.shortName !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.shortName);
        /* string factory_competitor_name = 96; */
        if (message.factoryCompetitorName !== "")
            writer.tag(96, WireType.LengthDelimited).string(message.factoryCompetitorName);
        /* repeated string competition_names = 45; */
        for (let i = 0; i < message.competitionNames.length; i++)
            writer.tag(45, WireType.LengthDelimited).string(message.competitionNames[i]);
        /* string location_name = 108; */
        if (message.locationName !== "")
            writer.tag(108, WireType.LengthDelimited).string(message.locationName);
        /* string sport = 47; */
        if (message.sport !== "")
            writer.tag(47, WireType.LengthDelimited).string(message.sport);
        /* google.protobuf.Timestamp last_updated = 50; */
        if (message.lastUpdated)
            Timestamp.internalBinaryWrite(message.lastUpdated, writer.tag(50, WireType.LengthDelimited).fork(), options).join();
        /* repeated odds.Link links = 98; */
        for (let i = 0; i < message.links.length; i++)
            Link.internalBinaryWrite(message.links[i], writer.tag(98, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.Player
 */
export const Player = new Player$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddPlayerRequest$Type extends MessageType<AddPlayerRequest> {
    constructor() {
        super("odds.AddPlayerRequest", [
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 48, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 83, name: "factory_competitor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AddPlayerRequest>): AddPlayerRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.shortName = "";
        message.factoryCompetitorId = 0;
        if (value !== undefined)
            reflectionMergePartial<AddPlayerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddPlayerRequest): AddPlayerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string short_name */ 48:
                    message.shortName = reader.string();
                    break;
                case /* int32 factory_competitor_id */ 83:
                    message.factoryCompetitorId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddPlayerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string short_name = 48; */
        if (message.shortName !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.shortName);
        /* int32 factory_competitor_id = 83; */
        if (message.factoryCompetitorId !== 0)
            writer.tag(83, WireType.Varint).int32(message.factoryCompetitorId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.AddPlayerRequest
 */
export const AddPlayerRequest = new AddPlayerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddPlayerResponse$Type extends MessageType<AddPlayerResponse> {
    constructor() {
        super("odds.AddPlayerResponse", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 48, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 83, name: "factory_competitor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 96, name: "factory_competitor_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<AddPlayerResponse>): AddPlayerResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.shortName = "";
        message.factoryCompetitorId = 0;
        message.factoryCompetitorName = "";
        if (value !== undefined)
            reflectionMergePartial<AddPlayerResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddPlayerResponse): AddPlayerResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string short_name */ 48:
                    message.shortName = reader.string();
                    break;
                case /* int32 factory_competitor_id */ 83:
                    message.factoryCompetitorId = reader.int32();
                    break;
                case /* string factory_competitor_name */ 96:
                    message.factoryCompetitorName = reader.string();
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddPlayerResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string short_name = 48; */
        if (message.shortName !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.shortName);
        /* int32 factory_competitor_id = 83; */
        if (message.factoryCompetitorId !== 0)
            writer.tag(83, WireType.Varint).int32(message.factoryCompetitorId);
        /* string factory_competitor_name = 96; */
        if (message.factoryCompetitorName !== "")
            writer.tag(96, WireType.LengthDelimited).string(message.factoryCompetitorName);
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.AddPlayerResponse
 */
export const AddPlayerResponse = new AddPlayerResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditPlayer$Type extends MessageType<EditPlayer> {
    constructor() {
        super("odds.EditPlayer", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 48, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<EditPlayer>): EditPlayer {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.shortName = "";
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<EditPlayer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditPlayer): EditPlayer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string short_name */ 48:
                    message.shortName = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditPlayer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string short_name = 48; */
        if (message.shortName !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.shortName);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.EditPlayer
 */
export const EditPlayer = new EditPlayer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LinkPlayerRequest$Type extends MessageType<LinkPlayerRequest> {
    constructor() {
        super("odds.LinkPlayerRequest", [
            { no: 102, name: "factory_player_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 98, name: "links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Link }
        ]);
    }
    create(value?: PartialMessage<LinkPlayerRequest>): LinkPlayerRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.factoryPlayerId = 0;
        message.links = [];
        if (value !== undefined)
            reflectionMergePartial<LinkPlayerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LinkPlayerRequest): LinkPlayerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 factory_player_id */ 102:
                    message.factoryPlayerId = reader.int32();
                    break;
                case /* repeated odds.Link links */ 98:
                    message.links.push(Link.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LinkPlayerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 factory_player_id = 102; */
        if (message.factoryPlayerId !== 0)
            writer.tag(102, WireType.Varint).int32(message.factoryPlayerId);
        /* repeated odds.Link links = 98; */
        for (let i = 0; i < message.links.length; i++)
            Link.internalBinaryWrite(message.links[i], writer.tag(98, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LinkPlayerRequest
 */
export const LinkPlayerRequest = new LinkPlayerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProviderPlayersRequest$Type extends MessageType<GetProviderPlayersRequest> {
    constructor() {
        super("odds.GetProviderPlayersRequest", [
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetProviderPlayersRequest>): GetProviderPlayersRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetProviderPlayersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProviderPlayersRequest): GetProviderPlayersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProviderPlayersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetProviderPlayersRequest
 */
export const GetProviderPlayersRequest = new GetProviderPlayersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProviderPlayersResponse$Type extends MessageType<GetProviderPlayersResponse> {
    constructor() {
        super("odds.GetProviderPlayersResponse", [
            { no: 38, name: "providers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Provider },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetProviderPlayersResponse>): GetProviderPlayersResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.providers = [];
        if (value !== undefined)
            reflectionMergePartial<GetProviderPlayersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProviderPlayersResponse): GetProviderPlayersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Provider providers */ 38:
                    message.providers.push(Provider.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProviderPlayersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Provider providers = 38; */
        for (let i = 0; i < message.providers.length; i++)
            Provider.internalBinaryWrite(message.providers[i], writer.tag(38, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetProviderPlayersResponse
 */
export const GetProviderPlayersResponse = new GetProviderPlayersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddCompetitorRequest$Type extends MessageType<AddCompetitorRequest> {
    constructor() {
        super("odds.AddCompetitorRequest", [
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 48, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 109, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 41, name: "competition_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AddCompetitorRequest>): AddCompetitorRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.shortName = "";
        message.sportId = 0;
        message.locationId = 0;
        message.competitionIds = [];
        if (value !== undefined)
            reflectionMergePartial<AddCompetitorRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddCompetitorRequest): AddCompetitorRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string short_name */ 48:
                    message.shortName = reader.string();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* int32 location_id */ 109:
                    message.locationId = reader.int32();
                    break;
                case /* repeated int32 competition_ids */ 41:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.competitionIds.push(reader.int32());
                    else
                        message.competitionIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddCompetitorRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string short_name = 48; */
        if (message.shortName !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.shortName);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* int32 location_id = 109; */
        if (message.locationId !== 0)
            writer.tag(109, WireType.Varint).int32(message.locationId);
        /* repeated int32 competition_ids = 41; */
        if (message.competitionIds.length) {
            writer.tag(41, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.competitionIds.length; i++)
                writer.int32(message.competitionIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.AddCompetitorRequest
 */
export const AddCompetitorRequest = new AddCompetitorRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddCompetitionRequest$Type extends MessageType<AddCompetitionRequest> {
    constructor() {
        super("odds.AddCompetitionRequest", [
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 48, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 109, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AddCompetitionRequest>): AddCompetitionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.shortName = "";
        message.sportId = 0;
        message.locationId = 0;
        if (value !== undefined)
            reflectionMergePartial<AddCompetitionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddCompetitionRequest): AddCompetitionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string short_name */ 48:
                    message.shortName = reader.string();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* int32 location_id */ 109:
                    message.locationId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddCompetitionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string short_name = 48; */
        if (message.shortName !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.shortName);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* int32 location_id = 109; */
        if (message.locationId !== 0)
            writer.tag(109, WireType.Varint).int32(message.locationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.AddCompetitionRequest
 */
export const AddCompetitionRequest = new AddCompetitionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddCompetitionResponse$Type extends MessageType<AddCompetitionResponse> {
    constructor() {
        super("odds.AddCompetitionResponse", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 48, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<AddCompetitionResponse>): AddCompetitionResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.shortName = "";
        message.sportId = 0;
        if (value !== undefined)
            reflectionMergePartial<AddCompetitionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddCompetitionResponse): AddCompetitionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string short_name */ 48:
                    message.shortName = reader.string();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddCompetitionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string short_name = 48; */
        if (message.shortName !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.shortName);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.AddCompetitionResponse
 */
export const AddCompetitionResponse = new AddCompetitionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddCompetitorResponse$Type extends MessageType<AddCompetitorResponse> {
    constructor() {
        super("odds.AddCompetitorResponse", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 48, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 25, name: "competitions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Competition },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<AddCompetitorResponse>): AddCompetitorResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.shortName = "";
        message.sportId = 0;
        message.competitions = [];
        if (value !== undefined)
            reflectionMergePartial<AddCompetitorResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddCompetitorResponse): AddCompetitorResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string short_name */ 48:
                    message.shortName = reader.string();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* repeated odds.Competition competitions */ 25:
                    message.competitions.push(Competition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddCompetitorResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string short_name = 48; */
        if (message.shortName !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.shortName);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* repeated odds.Competition competitions = 25; */
        for (let i = 0; i < message.competitions.length; i++)
            Competition.internalBinaryWrite(message.competitions[i], writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.AddCompetitorResponse
 */
export const AddCompetitorResponse = new AddCompetitorResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditCompetitorRequest$Type extends MessageType<EditCompetitorRequest> {
    constructor() {
        super("odds.EditCompetitorRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 48, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 109, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 41, name: "competition_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<EditCompetitorRequest>): EditCompetitorRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.shortName = "";
        message.name = "";
        message.sportId = 0;
        message.locationId = 0;
        message.competitionIds = [];
        if (value !== undefined)
            reflectionMergePartial<EditCompetitorRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditCompetitorRequest): EditCompetitorRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string short_name */ 48:
                    message.shortName = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* int32 location_id */ 109:
                    message.locationId = reader.int32();
                    break;
                case /* repeated int32 competition_ids */ 41:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.competitionIds.push(reader.int32());
                    else
                        message.competitionIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditCompetitorRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string short_name = 48; */
        if (message.shortName !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.shortName);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* int32 location_id = 109; */
        if (message.locationId !== 0)
            writer.tag(109, WireType.Varint).int32(message.locationId);
        /* repeated int32 competition_ids = 41; */
        if (message.competitionIds.length) {
            writer.tag(41, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.competitionIds.length; i++)
                writer.int32(message.competitionIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.EditCompetitorRequest
 */
export const EditCompetitorRequest = new EditCompetitorRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditCompetitionRequest$Type extends MessageType<EditCompetitionRequest> {
    constructor() {
        super("odds.EditCompetitionRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 48, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 109, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "standings_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EditCompetitionRequest>): EditCompetitionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.shortName = "";
        message.name = "";
        message.sportId = 0;
        message.locationId = 0;
        message.standingsUrl = "";
        if (value !== undefined)
            reflectionMergePartial<EditCompetitionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditCompetitionRequest): EditCompetitionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string short_name */ 48:
                    message.shortName = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* int32 location_id */ 109:
                    message.locationId = reader.int32();
                    break;
                case /* string standings_url */ 6:
                    message.standingsUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditCompetitionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string short_name = 48; */
        if (message.shortName !== "")
            writer.tag(48, WireType.LengthDelimited).string(message.shortName);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* int32 location_id = 109; */
        if (message.locationId !== 0)
            writer.tag(109, WireType.Varint).int32(message.locationId);
        /* string standings_url = 6; */
        if (message.standingsUrl !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.standingsUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.EditCompetitionRequest
 */
export const EditCompetitionRequest = new EditCompetitionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AutoAddCompetitionEventsRequest$Type extends MessageType<AutoAddCompetitionEventsRequest> {
    constructor() {
        super("odds.AutoAddCompetitionEventsRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AutoAddCompetitionEventsRequest>): AutoAddCompetitionEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<AutoAddCompetitionEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AutoAddCompetitionEventsRequest): AutoAddCompetitionEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AutoAddCompetitionEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.AutoAddCompetitionEventsRequest
 */
export const AutoAddCompetitionEventsRequest = new AutoAddCompetitionEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOutcomesResponse$Type extends MessageType<GetOutcomesResponse> {
    constructor() {
        super("odds.GetOutcomesResponse", [
            { no: 27, name: "outcomes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Outcome },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetOutcomesResponse>): GetOutcomesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.outcomes = [];
        if (value !== undefined)
            reflectionMergePartial<GetOutcomesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOutcomesResponse): GetOutcomesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Outcome outcomes */ 27:
                    message.outcomes.push(Outcome.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOutcomesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Outcome outcomes = 27; */
        for (let i = 0; i < message.outcomes.length; i++)
            Outcome.internalBinaryWrite(message.outcomes[i], writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetOutcomesResponse
 */
export const GetOutcomesResponse = new GetOutcomesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOutcomesRequest$Type extends MessageType<GetOutcomesRequest> {
    constructor() {
        super("odds.GetOutcomesRequest", [
            { no: 1, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "outcome_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOutcomesRequest>): GetOutcomesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        message.outcomeName = "";
        if (value !== undefined)
            reflectionMergePartial<GetOutcomesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOutcomesRequest): GetOutcomesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 1:
                    message.sportId = reader.int32();
                    break;
                case /* string outcome_name */ 2:
                    message.outcomeName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOutcomesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 1; */
        if (message.sportId !== 0)
            writer.tag(1, WireType.Varint).int32(message.sportId);
        /* string outcome_name = 2; */
        if (message.outcomeName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.outcomeName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetOutcomesRequest
 */
export const GetOutcomesRequest = new GetOutcomesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProviderOutcomesResponse$Type extends MessageType<GetProviderOutcomesResponse> {
    constructor() {
        super("odds.GetProviderOutcomesResponse", [
            { no: 38, name: "providers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Provider },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetProviderOutcomesResponse>): GetProviderOutcomesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.providers = [];
        if (value !== undefined)
            reflectionMergePartial<GetProviderOutcomesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProviderOutcomesResponse): GetProviderOutcomesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Provider providers */ 38:
                    message.providers.push(Provider.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProviderOutcomesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Provider providers = 38; */
        for (let i = 0; i < message.providers.length; i++)
            Provider.internalBinaryWrite(message.providers[i], writer.tag(38, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetProviderOutcomesResponse
 */
export const GetProviderOutcomesResponse = new GetProviderOutcomesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventTypesResponse$Type extends MessageType<GetEventTypesResponse> {
    constructor() {
        super("odds.GetEventTypesResponse", [
            { no: 124, name: "types", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<GetEventTypesResponse>): GetEventTypesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.types = {};
        if (value !== undefined)
            reflectionMergePartial<GetEventTypesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventTypesResponse): GetEventTypesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> types */ 124:
                    this.binaryReadMap124(message.types, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap124(map: GetEventTypesResponse["types"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetEventTypesResponse["types"] | undefined, val: GetEventTypesResponse["types"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field odds.GetEventTypesResponse.types");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: GetEventTypesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> types = 124; */
        for (let k of globalThis.Object.keys(message.types))
            writer.tag(124, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.types[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetEventTypesResponse
 */
export const GetEventTypesResponse = new GetEventTypesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConfigureEventStartTimeRequest$Type extends MessageType<ConfigureEventStartTimeRequest> {
    constructor() {
        super("odds.ConfigureEventStartTimeRequest", [
            { no: 12, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "start", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<ConfigureEventStartTimeRequest>): ConfigureEventStartTimeRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventId = "";
        if (value !== undefined)
            reflectionMergePartial<ConfigureEventStartTimeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConfigureEventStartTimeRequest): ConfigureEventStartTimeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_id */ 12:
                    message.eventId = reader.string();
                    break;
                case /* google.protobuf.Timestamp start */ 22:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConfigureEventStartTimeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event_id = 12; */
        if (message.eventId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.eventId);
        /* google.protobuf.Timestamp start = 22; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.ConfigureEventStartTimeRequest
 */
export const ConfigureEventStartTimeRequest = new ConfigureEventStartTimeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsByTypeRequest$Type extends MessageType<GetEventsByTypeRequest> {
    constructor() {
        super("odds.GetEventsByTypeRequest", [
            { no: 93, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetEventsByTypeRequest>): GetEventsByTypeRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = "";
        if (value !== undefined)
            reflectionMergePartial<GetEventsByTypeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsByTypeRequest): GetEventsByTypeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 93:
                    message.type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsByTypeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 93; */
        if (message.type !== "")
            writer.tag(93, WireType.LengthDelimited).string(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetEventsByTypeRequest
 */
export const GetEventsByTypeRequest = new GetEventsByTypeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsByTypeResponse$Type extends MessageType<GetEventsByTypeResponse> {
    constructor() {
        super("odds.GetEventsByTypeResponse", [
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetEventsByTypeResponse>): GetEventsByTypeResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<GetEventsByTypeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsByTypeResponse): GetEventsByTypeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Event events */ 17:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsByTypeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Event events = 17; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetEventsByTypeResponse
 */
export const GetEventsByTypeResponse = new GetEventsByTypeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditEventRequest$Type extends MessageType<EditEventRequest> {
    constructor() {
        super("odds.EditEventRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 93, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 132, name: "outright_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 140, name: "end_betting_time", kind: "message", T: () => Timestamp },
            { no: 27, name: "outcomes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Outcome }
        ]);
    }
    create(value?: PartialMessage<EditEventRequest>): EditEventRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.type = "";
        message.name = "";
        message.competitionId = 0;
        message.outrightType = "";
        message.outcomes = [];
        if (value !== undefined)
            reflectionMergePartial<EditEventRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditEventRequest): EditEventRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string type */ 93:
                    message.type = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 competition_id */ 4:
                    message.competitionId = reader.int32();
                    break;
                case /* string outright_type */ 132:
                    message.outrightType = reader.string();
                    break;
                case /* google.protobuf.Timestamp end_betting_time */ 140:
                    message.endBettingTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.endBettingTime);
                    break;
                case /* repeated odds.Outcome outcomes */ 27:
                    message.outcomes.push(Outcome.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditEventRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string type = 93; */
        if (message.type !== "")
            writer.tag(93, WireType.LengthDelimited).string(message.type);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 competition_id = 4; */
        if (message.competitionId !== 0)
            writer.tag(4, WireType.Varint).int32(message.competitionId);
        /* string outright_type = 132; */
        if (message.outrightType !== "")
            writer.tag(132, WireType.LengthDelimited).string(message.outrightType);
        /* google.protobuf.Timestamp end_betting_time = 140; */
        if (message.endBettingTime)
            Timestamp.internalBinaryWrite(message.endBettingTime, writer.tag(140, WireType.LengthDelimited).fork(), options).join();
        /* repeated odds.Outcome outcomes = 27; */
        for (let i = 0; i < message.outcomes.length; i++)
            Outcome.internalBinaryWrite(message.outcomes[i], writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.EditEventRequest
 */
export const EditEventRequest = new EditEventRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResolveOddRequest$Type extends MessageType<ResolveOddRequest> {
    constructor() {
        super("odds.ResolveOddRequest", [
            { no: 12, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 126, name: "passed_odd_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 127, name: "failed_odd_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 128, name: "cancelled_odd_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 131, name: "not_resolved_odd_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResolveOddRequest>): ResolveOddRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventId = "";
        message.passedOddIds = [];
        message.failedOddIds = [];
        message.cancelledOddIds = [];
        message.notResolvedOddIds = [];
        if (value !== undefined)
            reflectionMergePartial<ResolveOddRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResolveOddRequest): ResolveOddRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_id */ 12:
                    message.eventId = reader.string();
                    break;
                case /* repeated string passed_odd_ids */ 126:
                    message.passedOddIds.push(reader.string());
                    break;
                case /* repeated string failed_odd_ids */ 127:
                    message.failedOddIds.push(reader.string());
                    break;
                case /* repeated string cancelled_odd_ids */ 128:
                    message.cancelledOddIds.push(reader.string());
                    break;
                case /* repeated string not_resolved_odd_ids */ 131:
                    message.notResolvedOddIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResolveOddRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event_id = 12; */
        if (message.eventId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.eventId);
        /* repeated string passed_odd_ids = 126; */
        for (let i = 0; i < message.passedOddIds.length; i++)
            writer.tag(126, WireType.LengthDelimited).string(message.passedOddIds[i]);
        /* repeated string failed_odd_ids = 127; */
        for (let i = 0; i < message.failedOddIds.length; i++)
            writer.tag(127, WireType.LengthDelimited).string(message.failedOddIds[i]);
        /* repeated string cancelled_odd_ids = 128; */
        for (let i = 0; i < message.cancelledOddIds.length; i++)
            writer.tag(128, WireType.LengthDelimited).string(message.cancelledOddIds[i]);
        /* repeated string not_resolved_odd_ids = 131; */
        for (let i = 0; i < message.notResolvedOddIds.length; i++)
            writer.tag(131, WireType.LengthDelimited).string(message.notResolvedOddIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.ResolveOddRequest
 */
export const ResolveOddRequest = new ResolveOddRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventOddsRequest$Type extends MessageType<GetEventOddsRequest> {
    constructor() {
        super("odds.GetEventOddsRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetEventOddsRequest>): GetEventOddsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<GetEventOddsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventOddsRequest): GetEventOddsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventOddsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetEventOddsRequest
 */
export const GetEventOddsRequest = new GetEventOddsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventOddsResponse$Type extends MessageType<GetEventOddsResponse> {
    constructor() {
        super("odds.GetEventOddsResponse", [
            { no: 6, name: "markets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Market },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetEventOddsResponse>): GetEventOddsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.markets = [];
        if (value !== undefined)
            reflectionMergePartial<GetEventOddsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventOddsResponse): GetEventOddsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Market markets */ 6:
                    message.markets.push(Market.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventOddsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Market markets = 6; */
        for (let i = 0; i < message.markets.length; i++)
            Market.internalBinaryWrite(message.markets[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetEventOddsResponse
 */
export const GetEventOddsResponse = new GetEventOddsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisableEventRequest$Type extends MessageType<DisableEventRequest> {
    constructor() {
        super("odds.DisableEventRequest", [
            { no: 12, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DisableEventRequest>): DisableEventRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventId = "";
        if (value !== undefined)
            reflectionMergePartial<DisableEventRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableEventRequest): DisableEventRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_id */ 12:
                    message.eventId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisableEventRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event_id = 12; */
        if (message.eventId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.eventId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.DisableEventRequest
 */
export const DisableEventRequest = new DisableEventRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsWithPlayersRequest$Type extends MessageType<GetEventsWithPlayersRequest> {
    constructor() {
        super("odds.GetEventsWithPlayersRequest", [
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetEventsWithPlayersRequest>): GetEventsWithPlayersRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetEventsWithPlayersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsWithPlayersRequest): GetEventsWithPlayersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsWithPlayersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetEventsWithPlayersRequest
 */
export const GetEventsWithPlayersRequest = new GetEventsWithPlayersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsWithPlayersResponse$Type extends MessageType<GetEventsWithPlayersResponse> {
    constructor() {
        super("odds.GetEventsWithPlayersResponse", [
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event }
        ]);
    }
    create(value?: PartialMessage<GetEventsWithPlayersResponse>): GetEventsWithPlayersResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<GetEventsWithPlayersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsWithPlayersResponse): GetEventsWithPlayersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Event events */ 17:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsWithPlayersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Event events = 17; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetEventsWithPlayersResponse
 */
export const GetEventsWithPlayersResponse = new GetEventsWithPlayersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMarketsResponse$Type extends MessageType<GetMarketsResponse> {
    constructor() {
        super("odds.GetMarketsResponse", [
            { no: 6, name: "markets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Market }
        ]);
    }
    create(value?: PartialMessage<GetMarketsResponse>): GetMarketsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.markets = [];
        if (value !== undefined)
            reflectionMergePartial<GetMarketsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMarketsResponse): GetMarketsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Market markets */ 6:
                    message.markets.push(Market.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMarketsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Market markets = 6; */
        for (let i = 0; i < message.markets.length; i++)
            Market.internalBinaryWrite(message.markets[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetMarketsResponse
 */
export const GetMarketsResponse = new GetMarketsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUnresolvedEventsResponse$Type extends MessageType<GetUnresolvedEventsResponse> {
    constructor() {
        super("odds.GetUnresolvedEventsResponse", [
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event }
        ]);
    }
    create(value?: PartialMessage<GetUnresolvedEventsResponse>): GetUnresolvedEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<GetUnresolvedEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUnresolvedEventsResponse): GetUnresolvedEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Event events */ 17:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUnresolvedEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Event events = 17; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetUnresolvedEventsResponse
 */
export const GetUnresolvedEventsResponse = new GetUnresolvedEventsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSportsWithCompetitionsResponse$Type extends MessageType<GetSportsWithCompetitionsResponse> {
    constructor() {
        super("odds.GetSportsWithCompetitionsResponse", [
            { no: 13, name: "sports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Sport }
        ]);
    }
    create(value?: PartialMessage<GetSportsWithCompetitionsResponse>): GetSportsWithCompetitionsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sports = [];
        if (value !== undefined)
            reflectionMergePartial<GetSportsWithCompetitionsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSportsWithCompetitionsResponse): GetSportsWithCompetitionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Sport sports */ 13:
                    message.sports.push(Sport.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSportsWithCompetitionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Sport sports = 13; */
        for (let i = 0; i < message.sports.length; i++)
            Sport.internalBinaryWrite(message.sports[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetSportsWithCompetitionsResponse
 */
export const GetSportsWithCompetitionsResponse = new GetSportsWithCompetitionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsForManualResultsResponse$Type extends MessageType<GetEventsForManualResultsResponse> {
    constructor() {
        super("odds.GetEventsForManualResultsResponse", [
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event }
        ]);
    }
    create(value?: PartialMessage<GetEventsForManualResultsResponse>): GetEventsForManualResultsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<GetEventsForManualResultsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsForManualResultsResponse): GetEventsForManualResultsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Event events */ 17:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsForManualResultsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Event events = 17; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetEventsForManualResultsResponse
 */
export const GetEventsForManualResultsResponse = new GetEventsForManualResultsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditManualResultRequest$Type extends MessageType<EditManualResultRequest> {
    constructor() {
        super("odds.EditManualResultRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 145, name: "result", kind: "message", T: () => EventResult }
        ]);
    }
    create(value?: PartialMessage<EditManualResultRequest>): EditManualResultRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<EditManualResultRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditManualResultRequest): EditManualResultRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* common.EventResult result */ 145:
                    message.result = EventResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EditManualResultRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* common.EventResult result = 145; */
        if (message.result)
            EventResult.internalBinaryWrite(message.result, writer.tag(145, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.EditManualResultRequest
 */
export const EditManualResultRequest = new EditManualResultRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LinkEventsRequest$Type extends MessageType<LinkEventsRequest> {
    constructor() {
        super("odds.LinkEventsRequest", [
            { no: 1, name: "prematch_event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "live_event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "link_competitors", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<LinkEventsRequest>): LinkEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.prematchEventId = "";
        message.liveEventId = "";
        message.linkCompetitors = false;
        if (value !== undefined)
            reflectionMergePartial<LinkEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LinkEventsRequest): LinkEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string prematch_event_id */ 1:
                    message.prematchEventId = reader.string();
                    break;
                case /* string live_event_id */ 2:
                    message.liveEventId = reader.string();
                    break;
                case /* bool link_competitors */ 3:
                    message.linkCompetitors = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LinkEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string prematch_event_id = 1; */
        if (message.prematchEventId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.prematchEventId);
        /* string live_event_id = 2; */
        if (message.liveEventId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.liveEventId);
        /* bool link_competitors = 3; */
        if (message.linkCompetitors !== false)
            writer.tag(3, WireType.Varint).bool(message.linkCompetitors);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LinkEventsRequest
 */
export const LinkEventsRequest = new LinkEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateListPDFRequest$Type extends MessageType<GenerateListPDFRequest> {
    constructor() {
        super("odds.GenerateListPDFRequest", []);
    }
    create(value?: PartialMessage<GenerateListPDFRequest>): GenerateListPDFRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GenerateListPDFRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateListPDFRequest): GenerateListPDFRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GenerateListPDFRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GenerateListPDFRequest
 */
export const GenerateListPDFRequest = new GenerateListPDFRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateListPDFResponse$Type extends MessageType<GenerateListPDFResponse> {
    constructor() {
        super("odds.GenerateListPDFResponse", [
            { no: 150, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GenerateListPDFResponse>): GenerateListPDFResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.data = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<GenerateListPDFResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateListPDFResponse): GenerateListPDFResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes data */ 150:
                    message.data = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenerateListPDFResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes data = 150; */
        if (message.data.length)
            writer.tag(150, WireType.LengthDelimited).bytes(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GenerateListPDFResponse
 */
export const GenerateListPDFResponse = new GenerateListPDFResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSideBarRequest$Type extends MessageType<GetSideBarRequest> {
    constructor() {
        super("odds.GetSideBarRequest", [
            { no: 5, name: "provider_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 93, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 155, name: "frontend_page_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 158, name: "filter", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<GetSideBarRequest>): GetSideBarRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.providerId = 0;
        message.type = "";
        message.frontendPageName = "";
        if (value !== undefined)
            reflectionMergePartial<GetSideBarRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSideBarRequest): GetSideBarRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 provider_id */ 5:
                    message.providerId = reader.int32();
                    break;
                case /* string type */ 93:
                    message.type = reader.string();
                    break;
                case /* string frontend_page_name */ 155:
                    message.frontendPageName = reader.string();
                    break;
                case /* odds.Filter filter */ 158:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSideBarRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 provider_id = 5; */
        if (message.providerId !== 0)
            writer.tag(5, WireType.Varint).int32(message.providerId);
        /* string type = 93; */
        if (message.type !== "")
            writer.tag(93, WireType.LengthDelimited).string(message.type);
        /* string frontend_page_name = 155; */
        if (message.frontendPageName !== "")
            writer.tag(155, WireType.LengthDelimited).string(message.frontendPageName);
        /* odds.Filter filter = 158; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(158, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetSideBarRequest
 */
export const GetSideBarRequest = new GetSideBarRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSideBarResponse$Type extends MessageType<GetSideBarResponse> {
    constructor() {
        super("odds.GetSideBarResponse", [
            { no: 13, name: "sports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Sport },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetSideBarResponse>): GetSideBarResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sports = [];
        if (value !== undefined)
            reflectionMergePartial<GetSideBarResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSideBarResponse): GetSideBarResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Sport sports */ 13:
                    message.sports.push(Sport.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSideBarResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Sport sports = 13; */
        for (let i = 0; i < message.sports.length; i++)
            Sport.internalBinaryWrite(message.sports[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetSideBarResponse
 */
export const GetSideBarResponse = new GetSideBarResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Filter$Type extends MessageType<Filter> {
    constructor() {
        super("odds.Filter", [
            { no: 1, name: "search_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "date_from", kind: "message", T: () => Timestamp },
            { no: 3, name: "date_to", kind: "message", T: () => Timestamp },
            { no: 4, name: "num_odds_from", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "num_odds_to", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "is_published", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_auto", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "is_linked", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "has_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "provider_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "resolved", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "by_start", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "has_lb_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "has_new_lb_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Filter>): Filter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.searchString = "";
        message.numOddsFrom = 0;
        message.numOddsTo = 0;
        message.isPublished = "";
        message.isAuto = "";
        message.isLinked = "";
        message.hasResult = "";
        message.sportId = 0;
        message.providerIds = [];
        message.resolved = "";
        message.byStart = false;
        message.hasLbCode = "";
        message.hasNewLbCode = "";
        if (value !== undefined)
            reflectionMergePartial<Filter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filter): Filter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string search_string */ 1:
                    message.searchString = reader.string();
                    break;
                case /* google.protobuf.Timestamp date_from */ 2:
                    message.dateFrom = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dateFrom);
                    break;
                case /* google.protobuf.Timestamp date_to */ 3:
                    message.dateTo = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dateTo);
                    break;
                case /* int32 num_odds_from */ 4:
                    message.numOddsFrom = reader.int32();
                    break;
                case /* int32 num_odds_to */ 5:
                    message.numOddsTo = reader.int32();
                    break;
                case /* string is_published */ 6:
                    message.isPublished = reader.string();
                    break;
                case /* string is_auto */ 7:
                    message.isAuto = reader.string();
                    break;
                case /* string is_linked */ 8:
                    message.isLinked = reader.string();
                    break;
                case /* string has_result */ 9:
                    message.hasResult = reader.string();
                    break;
                case /* int32 sport_id */ 10:
                    message.sportId = reader.int32();
                    break;
                case /* repeated int32 provider_ids */ 11:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.providerIds.push(reader.int32());
                    else
                        message.providerIds.push(reader.int32());
                    break;
                case /* string resolved */ 12:
                    message.resolved = reader.string();
                    break;
                case /* bool by_start */ 13:
                    message.byStart = reader.bool();
                    break;
                case /* string has_lb_code */ 14:
                    message.hasLbCode = reader.string();
                    break;
                case /* string has_new_lb_code */ 15:
                    message.hasNewLbCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Filter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string search_string = 1; */
        if (message.searchString !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.searchString);
        /* google.protobuf.Timestamp date_from = 2; */
        if (message.dateFrom)
            Timestamp.internalBinaryWrite(message.dateFrom, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp date_to = 3; */
        if (message.dateTo)
            Timestamp.internalBinaryWrite(message.dateTo, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 num_odds_from = 4; */
        if (message.numOddsFrom !== 0)
            writer.tag(4, WireType.Varint).int32(message.numOddsFrom);
        /* int32 num_odds_to = 5; */
        if (message.numOddsTo !== 0)
            writer.tag(5, WireType.Varint).int32(message.numOddsTo);
        /* string is_published = 6; */
        if (message.isPublished !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.isPublished);
        /* string is_auto = 7; */
        if (message.isAuto !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.isAuto);
        /* string is_linked = 8; */
        if (message.isLinked !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.isLinked);
        /* string has_result = 9; */
        if (message.hasResult !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.hasResult);
        /* int32 sport_id = 10; */
        if (message.sportId !== 0)
            writer.tag(10, WireType.Varint).int32(message.sportId);
        /* repeated int32 provider_ids = 11; */
        if (message.providerIds.length) {
            writer.tag(11, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.providerIds.length; i++)
                writer.int32(message.providerIds[i]);
            writer.join();
        }
        /* string resolved = 12; */
        if (message.resolved !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.resolved);
        /* bool by_start = 13; */
        if (message.byStart !== false)
            writer.tag(13, WireType.Varint).bool(message.byStart);
        /* string has_lb_code = 14; */
        if (message.hasLbCode !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.hasLbCode);
        /* string has_new_lb_code = 15; */
        if (message.hasNewLbCode !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.hasNewLbCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.Filter
 */
export const Filter = new Filter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPaginatedEventsRequest$Type extends MessageType<GetPaginatedEventsRequest> {
    constructor() {
        super("odds.GetPaginatedEventsRequest", [
            { no: 1, name: "competition_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "filter", kind: "message", T: () => Filter },
            { no: 3, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "provider_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPaginatedEventsRequest>): GetPaginatedEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.competitionIds = [];
        message.type = "";
        message.page = 0;
        message.providerId = 0;
        message.eventId = "";
        if (value !== undefined)
            reflectionMergePartial<GetPaginatedEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPaginatedEventsRequest): GetPaginatedEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 competition_ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.competitionIds.push(reader.int32());
                    else
                        message.competitionIds.push(reader.int32());
                    break;
                case /* odds.Filter filter */ 2:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* string type */ 3:
                    message.type = reader.string();
                    break;
                case /* int32 page */ 4:
                    message.page = reader.int32();
                    break;
                case /* int32 provider_id */ 5:
                    message.providerId = reader.int32();
                    break;
                case /* string event_id */ 6:
                    message.eventId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPaginatedEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int32 competition_ids = 1; */
        if (message.competitionIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.competitionIds.length; i++)
                writer.int32(message.competitionIds[i]);
            writer.join();
        }
        /* odds.Filter filter = 2; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string type = 3; */
        if (message.type !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.type);
        /* int32 page = 4; */
        if (message.page !== 0)
            writer.tag(4, WireType.Varint).int32(message.page);
        /* int32 provider_id = 5; */
        if (message.providerId !== 0)
            writer.tag(5, WireType.Varint).int32(message.providerId);
        /* string event_id = 6; */
        if (message.eventId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.eventId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetPaginatedEventsRequest
 */
export const GetPaginatedEventsRequest = new GetPaginatedEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaginatedEvents$Type extends MessageType<PaginatedEvents> {
    constructor() {
        super("odds.PaginatedEvents", [
            { no: 161, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 162, name: "total_pages", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 25, name: "competitions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Competition },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<PaginatedEvents>): PaginatedEvents {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.totalPages = 0;
        message.competitions = [];
        if (value !== undefined)
            reflectionMergePartial<PaginatedEvents>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PaginatedEvents): PaginatedEvents {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 161:
                    message.page = reader.int32();
                    break;
                case /* int32 total_pages */ 162:
                    message.totalPages = reader.int32();
                    break;
                case /* repeated odds.Competition competitions */ 25:
                    message.competitions.push(Competition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PaginatedEvents, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 161; */
        if (message.page !== 0)
            writer.tag(161, WireType.Varint).int32(message.page);
        /* int32 total_pages = 162; */
        if (message.totalPages !== 0)
            writer.tag(162, WireType.Varint).int32(message.totalPages);
        /* repeated odds.Competition competitions = 25; */
        for (let i = 0; i < message.competitions.length; i++)
            Competition.internalBinaryWrite(message.competitions[i], writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.PaginatedEvents
 */
export const PaginatedEvents = new PaginatedEvents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelAllUnplayedRequest$Type extends MessageType<CancelAllUnplayedRequest> {
    constructor() {
        super("odds.CancelAllUnplayedRequest", [
            { no: 93, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CancelAllUnplayedRequest>): CancelAllUnplayedRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = "";
        if (value !== undefined)
            reflectionMergePartial<CancelAllUnplayedRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelAllUnplayedRequest): CancelAllUnplayedRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 93:
                    message.type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelAllUnplayedRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 93; */
        if (message.type !== "")
            writer.tag(93, WireType.LengthDelimited).string(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.CancelAllUnplayedRequest
 */
export const CancelAllUnplayedRequest = new CancelAllUnplayedRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidationPriorityObjects$Type extends MessageType<ValidationPriorityObjects> {
    constructor() {
        super("odds.ValidationPriorityObjects", [
            { no: 168, name: "competitions_map", kind: "map", K: 5 /*ScalarType.INT32*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 169, name: "locations_map", kind: "map", K: 5 /*ScalarType.INT32*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 170, name: "sports_map", kind: "map", K: 5 /*ScalarType.INT32*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<ValidationPriorityObjects>): ValidationPriorityObjects {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.competitionsMap = {};
        message.locationsMap = {};
        message.sportsMap = {};
        if (value !== undefined)
            reflectionMergePartial<ValidationPriorityObjects>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidationPriorityObjects): ValidationPriorityObjects {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<int32, string> competitions_map */ 168:
                    this.binaryReadMap168(message.competitionsMap, reader, options);
                    break;
                case /* map<int32, string> locations_map */ 169:
                    this.binaryReadMap169(message.locationsMap, reader, options);
                    break;
                case /* map<int32, string> sports_map */ 170:
                    this.binaryReadMap170(message.sportsMap, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap168(map: ValidationPriorityObjects["competitionsMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ValidationPriorityObjects["competitionsMap"] | undefined, val: ValidationPriorityObjects["competitionsMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field odds.ValidationPriorityObjects.competitions_map");
            }
        }
        map[key ?? 0] = val ?? "";
    }
    private binaryReadMap169(map: ValidationPriorityObjects["locationsMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ValidationPriorityObjects["locationsMap"] | undefined, val: ValidationPriorityObjects["locationsMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field odds.ValidationPriorityObjects.locations_map");
            }
        }
        map[key ?? 0] = val ?? "";
    }
    private binaryReadMap170(map: ValidationPriorityObjects["sportsMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ValidationPriorityObjects["sportsMap"] | undefined, val: ValidationPriorityObjects["sportsMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field odds.ValidationPriorityObjects.sports_map");
            }
        }
        map[key ?? 0] = val ?? "";
    }
    internalBinaryWrite(message: ValidationPriorityObjects, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<int32, string> competitions_map = 168; */
        for (let k of globalThis.Object.keys(message.competitionsMap))
            writer.tag(168, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k)).tag(2, WireType.LengthDelimited).string(message.competitionsMap[k as any]).join();
        /* map<int32, string> locations_map = 169; */
        for (let k of globalThis.Object.keys(message.locationsMap))
            writer.tag(169, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k)).tag(2, WireType.LengthDelimited).string(message.locationsMap[k as any]).join();
        /* map<int32, string> sports_map = 170; */
        for (let k of globalThis.Object.keys(message.sportsMap))
            writer.tag(170, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k)).tag(2, WireType.LengthDelimited).string(message.sportsMap[k as any]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.ValidationPriorityObjects
 */
export const ValidationPriorityObjects = new ValidationPriorityObjects$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddNoteRequest$Type extends MessageType<AddNoteRequest> {
    constructor() {
        super("odds.AddNoteRequest", [
            { no: 12, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 172, name: "note", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddNoteRequest>): AddNoteRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventId = "";
        message.note = "";
        if (value !== undefined)
            reflectionMergePartial<AddNoteRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddNoteRequest): AddNoteRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_id */ 12:
                    message.eventId = reader.string();
                    break;
                case /* string note */ 172:
                    message.note = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddNoteRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event_id = 12; */
        if (message.eventId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.eventId);
        /* string note = 172; */
        if (message.note !== "")
            writer.tag(172, WireType.LengthDelimited).string(message.note);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.AddNoteRequest
 */
export const AddNoteRequest = new AddNoteRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SuspiciousEvent$Type extends MessageType<SuspiciousEvent> {
    constructor() {
        super("odds.SuspiciousEvent", [
            { no: 12, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 174, name: "suspicious_level", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 80, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SuspiciousEvent>): SuspiciousEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventId = "";
        message.suspiciousLevel = "";
        message.landbaseCode = "";
        if (value !== undefined)
            reflectionMergePartial<SuspiciousEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SuspiciousEvent): SuspiciousEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_id */ 12:
                    message.eventId = reader.string();
                    break;
                case /* string suspicious_level */ 174:
                    message.suspiciousLevel = reader.string();
                    break;
                case /* string landbase_code */ 80:
                    message.landbaseCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SuspiciousEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event_id = 12; */
        if (message.eventId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.eventId);
        /* string suspicious_level = 174; */
        if (message.suspiciousLevel !== "")
            writer.tag(174, WireType.LengthDelimited).string(message.suspiciousLevel);
        /* string landbase_code = 80; */
        if (message.landbaseCode !== "")
            writer.tag(80, WireType.LengthDelimited).string(message.landbaseCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SuspiciousEvent
 */
export const SuspiciousEvent = new SuspiciousEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOddsRequest$Type extends MessageType<UpdateOddsRequest> {
    constructor() {
        super("odds.UpdateOddsRequest", [
            { no: 87, name: "deleted_odd_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 184, name: "fixed_odd_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateOddsRequest>): UpdateOddsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deletedOddIds = [];
        message.fixedOddIds = [];
        message.eventId = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateOddsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateOddsRequest): UpdateOddsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string deleted_odd_ids */ 87:
                    message.deletedOddIds.push(reader.string());
                    break;
                case /* repeated string fixed_odd_ids */ 184:
                    message.fixedOddIds.push(reader.string());
                    break;
                case /* string event_id */ 12:
                    message.eventId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateOddsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string deleted_odd_ids = 87; */
        for (let i = 0; i < message.deletedOddIds.length; i++)
            writer.tag(87, WireType.LengthDelimited).string(message.deletedOddIds[i]);
        /* repeated string fixed_odd_ids = 184; */
        for (let i = 0; i < message.fixedOddIds.length; i++)
            writer.tag(184, WireType.LengthDelimited).string(message.fixedOddIds[i]);
        /* string event_id = 12; */
        if (message.eventId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.eventId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.UpdateOddsRequest
 */
export const UpdateOddsRequest = new UpdateOddsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SuspiciousLevels$Type extends MessageType<SuspiciousLevels> {
    constructor() {
        super("odds.SuspiciousLevels", [
            { no: 177, name: "suspicious_levels", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SuspiciousLevel }
        ]);
    }
    create(value?: PartialMessage<SuspiciousLevels>): SuspiciousLevels {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.suspiciousLevels = [];
        if (value !== undefined)
            reflectionMergePartial<SuspiciousLevels>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SuspiciousLevels): SuspiciousLevels {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.SuspiciousLevel suspicious_levels */ 177:
                    message.suspiciousLevels.push(SuspiciousLevel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SuspiciousLevels, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.SuspiciousLevel suspicious_levels = 177; */
        for (let i = 0; i < message.suspiciousLevels.length; i++)
            SuspiciousLevel.internalBinaryWrite(message.suspiciousLevels[i], writer.tag(177, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SuspiciousLevels
 */
export const SuspiciousLevels = new SuspiciousLevels$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SuspiciousLevel$Type extends MessageType<SuspiciousLevel> {
    constructor() {
        super("odds.SuspiciousLevel", [
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SuspiciousLevel>): SuspiciousLevel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.value = "";
        if (value !== undefined)
            reflectionMergePartial<SuspiciousLevel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SuspiciousLevel): SuspiciousLevel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string value */ 8:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SuspiciousLevel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string value = 8; */
        if (message.value !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SuspiciousLevel
 */
export const SuspiciousLevel = new SuspiciousLevel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchProfiles$Type extends MessageType<MatchProfiles> {
    constructor() {
        super("odds.MatchProfiles", [
            { no: 190, name: "match_profiles", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchProfile }
        ]);
    }
    create(value?: PartialMessage<MatchProfiles>): MatchProfiles {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchProfiles = [];
        if (value !== undefined)
            reflectionMergePartial<MatchProfiles>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchProfiles): MatchProfiles {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.MatchProfile match_profiles */ 190:
                    message.matchProfiles.push(MatchProfile.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchProfiles, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.MatchProfile match_profiles = 190; */
        for (let i = 0; i < message.matchProfiles.length; i++)
            MatchProfile.internalBinaryWrite(message.matchProfiles[i], writer.tag(190, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.MatchProfiles
 */
export const MatchProfiles = new MatchProfiles$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchProfile$Type extends MessageType<MatchProfile> {
    constructor() {
        super("odds.MatchProfile", [
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MatchProfile>): MatchProfile {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.value = "";
        if (value !== undefined)
            reflectionMergePartial<MatchProfile>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchProfile): MatchProfile {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string value */ 8:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchProfile, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string value = 8; */
        if (message.value !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.MatchProfile
 */
export const MatchProfile = new MatchProfile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SingleApproval$Type extends MessageType<SingleApproval> {
    constructor() {
        super("odds.SingleApproval", [
            { no: 12, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SingleApproval>): SingleApproval {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventId = "";
        if (value !== undefined)
            reflectionMergePartial<SingleApproval>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SingleApproval): SingleApproval {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_id */ 12:
                    message.eventId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SingleApproval, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event_id = 12; */
        if (message.eventId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.eventId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SingleApproval
 */
export const SingleApproval = new SingleApproval$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateChangedLimitOddsRequest$Type extends MessageType<UpdateChangedLimitOddsRequest> {
    constructor() {
        super("odds.UpdateChangedLimitOddsRequest", [
            { no: 181, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventOdd }
        ]);
    }
    create(value?: PartialMessage<UpdateChangedLimitOddsRequest>): UpdateChangedLimitOddsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.odds = [];
        if (value !== undefined)
            reflectionMergePartial<UpdateChangedLimitOddsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateChangedLimitOddsRequest): UpdateChangedLimitOddsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.EventOdd odds */ 181:
                    message.odds.push(EventOdd.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateChangedLimitOddsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.EventOdd odds = 181; */
        for (let i = 0; i < message.odds.length; i++)
            EventOdd.internalBinaryWrite(message.odds[i], writer.tag(181, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.UpdateChangedLimitOddsRequest
 */
export const UpdateChangedLimitOddsRequest = new UpdateChangedLimitOddsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateChangedLimitOddsResponse$Type extends MessageType<UpdateChangedLimitOddsResponse> {
    constructor() {
        super("odds.UpdateChangedLimitOddsResponse", [
            { no: 180, name: "odd_ids_map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<UpdateChangedLimitOddsResponse>): UpdateChangedLimitOddsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.oddIdsMap = {};
        if (value !== undefined)
            reflectionMergePartial<UpdateChangedLimitOddsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateChangedLimitOddsResponse): UpdateChangedLimitOddsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> odd_ids_map */ 180:
                    this.binaryReadMap180(message.oddIdsMap, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap180(map: UpdateChangedLimitOddsResponse["oddIdsMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof UpdateChangedLimitOddsResponse["oddIdsMap"] | undefined, val: UpdateChangedLimitOddsResponse["oddIdsMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field odds.UpdateChangedLimitOddsResponse.odd_ids_map");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: UpdateChangedLimitOddsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> odd_ids_map = 180; */
        for (let k of globalThis.Object.keys(message.oddIdsMap))
            writer.tag(180, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.oddIdsMap[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.UpdateChangedLimitOddsResponse
 */
export const UpdateChangedLimitOddsResponse = new UpdateChangedLimitOddsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetLandbaseCodesRequest$Type extends MessageType<ResetLandbaseCodesRequest> {
    constructor() {
        super("odds.ResetLandbaseCodesRequest", [
            { no: 182, name: "sport_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ResetLandbaseCodesRequest>): ResetLandbaseCodesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportIds = [];
        if (value !== undefined)
            reflectionMergePartial<ResetLandbaseCodesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetLandbaseCodesRequest): ResetLandbaseCodesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 sport_ids */ 182:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.sportIds.push(reader.int32());
                    else
                        message.sportIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetLandbaseCodesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int32 sport_ids = 182; */
        if (message.sportIds.length) {
            writer.tag(182, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.sportIds.length; i++)
                writer.int32(message.sportIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.ResetLandbaseCodesRequest
 */
export const ResetLandbaseCodesRequest = new ResetLandbaseCodesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPaginatedRequest$Type extends MessageType<GetPaginatedRequest> {
    constructor() {
        super("odds.GetPaginatedRequest", [
            { no: 161, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 158, name: "filter", kind: "message", T: () => Filter },
            { no: 276, name: "include_providers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetPaginatedRequest>): GetPaginatedRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.includeProviders = false;
        if (value !== undefined)
            reflectionMergePartial<GetPaginatedRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPaginatedRequest): GetPaginatedRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 161:
                    message.page = reader.int32();
                    break;
                case /* odds.Filter filter */ 158:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* bool include_providers */ 276:
                    message.includeProviders = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPaginatedRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 161; */
        if (message.page !== 0)
            writer.tag(161, WireType.Varint).int32(message.page);
        /* odds.Filter filter = 158; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(158, WireType.LengthDelimited).fork(), options).join();
        /* bool include_providers = 276; */
        if (message.includeProviders !== false)
            writer.tag(276, WireType.Varint).bool(message.includeProviders);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetPaginatedRequest
 */
export const GetPaginatedRequest = new GetPaginatedRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPaginatedCompetitorsBoResponse$Type extends MessageType<GetPaginatedCompetitorsBoResponse> {
    constructor() {
        super("odds.GetPaginatedCompetitorsBoResponse", [
            { no: 44, name: "competitors", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Competitor },
            { no: 161, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 162, name: "total_pages", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetPaginatedCompetitorsBoResponse>): GetPaginatedCompetitorsBoResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.competitors = [];
        message.page = 0;
        message.totalPages = 0;
        if (value !== undefined)
            reflectionMergePartial<GetPaginatedCompetitorsBoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPaginatedCompetitorsBoResponse): GetPaginatedCompetitorsBoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Competitor competitors */ 44:
                    message.competitors.push(Competitor.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 page */ 161:
                    message.page = reader.int32();
                    break;
                case /* int32 total_pages */ 162:
                    message.totalPages = reader.int32();
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPaginatedCompetitorsBoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Competitor competitors = 44; */
        for (let i = 0; i < message.competitors.length; i++)
            Competitor.internalBinaryWrite(message.competitors[i], writer.tag(44, WireType.LengthDelimited).fork(), options).join();
        /* int32 page = 161; */
        if (message.page !== 0)
            writer.tag(161, WireType.Varint).int32(message.page);
        /* int32 total_pages = 162; */
        if (message.totalPages !== 0)
            writer.tag(162, WireType.Varint).int32(message.totalPages);
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetPaginatedCompetitorsBoResponse
 */
export const GetPaginatedCompetitorsBoResponse = new GetPaginatedCompetitorsBoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPaginatedCompetitionsBoResponse$Type extends MessageType<GetPaginatedCompetitionsBoResponse> {
    constructor() {
        super("odds.GetPaginatedCompetitionsBoResponse", [
            { no: 25, name: "competitions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Competition },
            { no: 161, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 162, name: "total_pages", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetPaginatedCompetitionsBoResponse>): GetPaginatedCompetitionsBoResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.competitions = [];
        message.page = 0;
        message.totalPages = 0;
        if (value !== undefined)
            reflectionMergePartial<GetPaginatedCompetitionsBoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPaginatedCompetitionsBoResponse): GetPaginatedCompetitionsBoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Competition competitions */ 25:
                    message.competitions.push(Competition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 page */ 161:
                    message.page = reader.int32();
                    break;
                case /* int32 total_pages */ 162:
                    message.totalPages = reader.int32();
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPaginatedCompetitionsBoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Competition competitions = 25; */
        for (let i = 0; i < message.competitions.length; i++)
            Competition.internalBinaryWrite(message.competitions[i], writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* int32 page = 161; */
        if (message.page !== 0)
            writer.tag(161, WireType.Varint).int32(message.page);
        /* int32 total_pages = 162; */
        if (message.totalPages !== 0)
            writer.tag(162, WireType.Varint).int32(message.totalPages);
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetPaginatedCompetitionsBoResponse
 */
export const GetPaginatedCompetitionsBoResponse = new GetPaginatedCompetitionsBoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DisableLive$Type extends MessageType<DisableLive> {
    constructor() {
        super("odds.DisableLive", [
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DisableLive>): DisableLive {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        message.status = "";
        if (value !== undefined)
            reflectionMergePartial<DisableLive>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DisableLive): DisableLive {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* string status */ 6:
                    message.status = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DisableLive, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* string status = 6; */
        if (message.status !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.DisableLive
 */
export const DisableLive = new DisableLive$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompetitionBetLimitRequest$Type extends MessageType<GetCompetitionBetLimitRequest> {
    constructor() {
        super("odds.GetCompetitionBetLimitRequest", [
            { no: 161, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 109, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 96, name: "competition_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 273, name: "prematch", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 260, name: "sort_by", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SortBy }
        ]);
    }
    create(value?: PartialMessage<GetCompetitionBetLimitRequest>): GetCompetitionBetLimitRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.sportId = 0;
        message.locationId = 0;
        message.competitionName = "";
        message.prematch = false;
        message.sortBy = [];
        if (value !== undefined)
            reflectionMergePartial<GetCompetitionBetLimitRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompetitionBetLimitRequest): GetCompetitionBetLimitRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 161:
                    message.page = reader.int32();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* int32 location_id */ 109:
                    message.locationId = reader.int32();
                    break;
                case /* string competition_name */ 96:
                    message.competitionName = reader.string();
                    break;
                case /* bool prematch */ 273:
                    message.prematch = reader.bool();
                    break;
                case /* repeated common.SortBy sort_by */ 260:
                    message.sortBy.push(SortBy.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompetitionBetLimitRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 161; */
        if (message.page !== 0)
            writer.tag(161, WireType.Varint).int32(message.page);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* int32 location_id = 109; */
        if (message.locationId !== 0)
            writer.tag(109, WireType.Varint).int32(message.locationId);
        /* string competition_name = 96; */
        if (message.competitionName !== "")
            writer.tag(96, WireType.LengthDelimited).string(message.competitionName);
        /* bool prematch = 273; */
        if (message.prematch !== false)
            writer.tag(273, WireType.Varint).bool(message.prematch);
        /* repeated common.SortBy sort_by = 260; */
        for (let i = 0; i < message.sortBy.length; i++)
            SortBy.internalBinaryWrite(message.sortBy[i], writer.tag(260, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetCompetitionBetLimitRequest
 */
export const GetCompetitionBetLimitRequest = new GetCompetitionBetLimitRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompetitionLimit$Type extends MessageType<CompetitionLimit> {
    constructor() {
        super("odds.CompetitionLimit", [
            { no: 4, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 96, name: "competition_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 108, name: "location_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 107, name: "sport_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 195, name: "days_of_month", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 196, name: "days_of_week", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 198, name: "max_amount_single", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 228, name: "max_amount_multiple", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 264, name: "single_betting_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 265, name: "multiple_betting_amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 220, name: "amount_single", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 229, name: "amount_multiple", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 230, name: "amount_total", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<CompetitionLimit>): CompetitionLimit {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.competitionId = 0;
        message.competitionName = "";
        message.locationName = "";
        message.sportName = "";
        message.daysOfMonth = [];
        message.daysOfWeek = [];
        message.maxAmountSingle = 0;
        message.maxAmountMultiple = 0;
        message.singleBettingAmount = 0;
        message.multipleBettingAmount = 0;
        message.amountSingle = 0;
        message.amountMultiple = 0;
        message.amountTotal = 0;
        if (value !== undefined)
            reflectionMergePartial<CompetitionLimit>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompetitionLimit): CompetitionLimit {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 competition_id */ 4:
                    message.competitionId = reader.int32();
                    break;
                case /* string competition_name */ 96:
                    message.competitionName = reader.string();
                    break;
                case /* string location_name */ 108:
                    message.locationName = reader.string();
                    break;
                case /* string sport_name */ 107:
                    message.sportName = reader.string();
                    break;
                case /* repeated string days_of_month */ 195:
                    message.daysOfMonth.push(reader.string());
                    break;
                case /* repeated string days_of_week */ 196:
                    message.daysOfWeek.push(reader.string());
                    break;
                case /* double max_amount_single */ 198:
                    message.maxAmountSingle = reader.double();
                    break;
                case /* double max_amount_multiple */ 228:
                    message.maxAmountMultiple = reader.double();
                    break;
                case /* double single_betting_amount */ 264:
                    message.singleBettingAmount = reader.double();
                    break;
                case /* double multiple_betting_amount */ 265:
                    message.multipleBettingAmount = reader.double();
                    break;
                case /* double amount_single */ 220:
                    message.amountSingle = reader.double();
                    break;
                case /* double amount_multiple */ 229:
                    message.amountMultiple = reader.double();
                    break;
                case /* double amount_total */ 230:
                    message.amountTotal = reader.double();
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompetitionLimit, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 competition_id = 4; */
        if (message.competitionId !== 0)
            writer.tag(4, WireType.Varint).int32(message.competitionId);
        /* string competition_name = 96; */
        if (message.competitionName !== "")
            writer.tag(96, WireType.LengthDelimited).string(message.competitionName);
        /* string location_name = 108; */
        if (message.locationName !== "")
            writer.tag(108, WireType.LengthDelimited).string(message.locationName);
        /* string sport_name = 107; */
        if (message.sportName !== "")
            writer.tag(107, WireType.LengthDelimited).string(message.sportName);
        /* repeated string days_of_month = 195; */
        for (let i = 0; i < message.daysOfMonth.length; i++)
            writer.tag(195, WireType.LengthDelimited).string(message.daysOfMonth[i]);
        /* repeated string days_of_week = 196; */
        for (let i = 0; i < message.daysOfWeek.length; i++)
            writer.tag(196, WireType.LengthDelimited).string(message.daysOfWeek[i]);
        /* double max_amount_single = 198; */
        if (message.maxAmountSingle !== 0)
            writer.tag(198, WireType.Bit64).double(message.maxAmountSingle);
        /* double max_amount_multiple = 228; */
        if (message.maxAmountMultiple !== 0)
            writer.tag(228, WireType.Bit64).double(message.maxAmountMultiple);
        /* double single_betting_amount = 264; */
        if (message.singleBettingAmount !== 0)
            writer.tag(264, WireType.Bit64).double(message.singleBettingAmount);
        /* double multiple_betting_amount = 265; */
        if (message.multipleBettingAmount !== 0)
            writer.tag(265, WireType.Bit64).double(message.multipleBettingAmount);
        /* double amount_single = 220; */
        if (message.amountSingle !== 0)
            writer.tag(220, WireType.Bit64).double(message.amountSingle);
        /* double amount_multiple = 229; */
        if (message.amountMultiple !== 0)
            writer.tag(229, WireType.Bit64).double(message.amountMultiple);
        /* double amount_total = 230; */
        if (message.amountTotal !== 0)
            writer.tag(230, WireType.Bit64).double(message.amountTotal);
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.CompetitionLimit
 */
export const CompetitionLimit = new CompetitionLimit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompetitionBetLimitResponse$Type extends MessageType<GetCompetitionBetLimitResponse> {
    constructor() {
        super("odds.GetCompetitionBetLimitResponse", [
            { no: 199, name: "global_limit", kind: "message", T: () => CompetitionLimit },
            { no: 221, name: "competition_limits", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CompetitionLimit },
            { no: 161, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 162, name: "total_pages", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<GetCompetitionBetLimitResponse>): GetCompetitionBetLimitResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.competitionLimits = [];
        message.page = 0;
        message.totalPages = 0;
        if (value !== undefined)
            reflectionMergePartial<GetCompetitionBetLimitResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompetitionBetLimitResponse): GetCompetitionBetLimitResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* odds.CompetitionLimit global_limit */ 199:
                    message.globalLimit = CompetitionLimit.internalBinaryRead(reader, reader.uint32(), options, message.globalLimit);
                    break;
                case /* repeated odds.CompetitionLimit competition_limits */ 221:
                    message.competitionLimits.push(CompetitionLimit.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 page */ 161:
                    message.page = reader.int32();
                    break;
                case /* int32 total_pages */ 162:
                    message.totalPages = reader.int32();
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompetitionBetLimitResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* odds.CompetitionLimit global_limit = 199; */
        if (message.globalLimit)
            CompetitionLimit.internalBinaryWrite(message.globalLimit, writer.tag(199, WireType.LengthDelimited).fork(), options).join();
        /* repeated odds.CompetitionLimit competition_limits = 221; */
        for (let i = 0; i < message.competitionLimits.length; i++)
            CompetitionLimit.internalBinaryWrite(message.competitionLimits[i], writer.tag(221, WireType.LengthDelimited).fork(), options).join();
        /* int32 page = 161; */
        if (message.page !== 0)
            writer.tag(161, WireType.Varint).int32(message.page);
        /* int32 total_pages = 162; */
        if (message.totalPages !== 0)
            writer.tag(162, WireType.Varint).int32(message.totalPages);
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetCompetitionBetLimitResponse
 */
export const GetCompetitionBetLimitResponse = new GetCompetitionBetLimitResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCompetitionLimit$Type extends MessageType<UpdateCompetitionLimit> {
    constructor() {
        super("odds.UpdateCompetitionLimit", [
            { no: 41, name: "competition_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 220, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 227, name: "is_single", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateCompetitionLimit>): UpdateCompetitionLimit {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.competitionIds = [];
        message.amount = 0;
        message.isSingle = false;
        if (value !== undefined)
            reflectionMergePartial<UpdateCompetitionLimit>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCompetitionLimit): UpdateCompetitionLimit {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 competition_ids */ 41:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.competitionIds.push(reader.int32());
                    else
                        message.competitionIds.push(reader.int32());
                    break;
                case /* double amount */ 220:
                    message.amount = reader.double();
                    break;
                case /* bool is_single */ 227:
                    message.isSingle = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCompetitionLimit, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int32 competition_ids = 41; */
        if (message.competitionIds.length) {
            writer.tag(41, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.competitionIds.length; i++)
                writer.int32(message.competitionIds[i]);
            writer.join();
        }
        /* double amount = 220; */
        if (message.amount !== 0)
            writer.tag(220, WireType.Bit64).double(message.amount);
        /* bool is_single = 227; */
        if (message.isSingle !== false)
            writer.tag(227, WireType.Varint).bool(message.isSingle);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.UpdateCompetitionLimit
 */
export const UpdateCompetitionLimit = new UpdateCompetitionLimit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarketTemplate$Type extends MessageType<MarketTemplate> {
    constructor() {
        super("odds.MarketTemplate", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 125, name: "outcome_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 126, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MarketTemplate>): MarketTemplate {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.sportId = 0;
        message.name = "";
        message.outcomeIds = [];
        message.isActive = false;
        if (value !== undefined)
            reflectionMergePartial<MarketTemplate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MarketTemplate): MarketTemplate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated int32 outcome_ids */ 125:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.outcomeIds.push(reader.int32());
                    else
                        message.outcomeIds.push(reader.int32());
                    break;
                case /* bool is_active */ 126:
                    message.isActive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MarketTemplate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated int32 outcome_ids = 125; */
        if (message.outcomeIds.length) {
            writer.tag(125, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.outcomeIds.length; i++)
                writer.int32(message.outcomeIds[i]);
            writer.join();
        }
        /* bool is_active = 126; */
        if (message.isActive !== false)
            writer.tag(126, WireType.Varint).bool(message.isActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.MarketTemplate
 */
export const MarketTemplate = new MarketTemplate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarketTemplateResponse$Type extends MessageType<MarketTemplateResponse> {
    constructor() {
        super("odds.MarketTemplateResponse", [
            { no: 225, name: "templates", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MarketTemplate },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<MarketTemplateResponse>): MarketTemplateResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.templates = [];
        if (value !== undefined)
            reflectionMergePartial<MarketTemplateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MarketTemplateResponse): MarketTemplateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.MarketTemplate templates */ 225:
                    message.templates.push(MarketTemplate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MarketTemplateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.MarketTemplate templates = 225; */
        for (let i = 0; i < message.templates.length; i++)
            MarketTemplate.internalBinaryWrite(message.templates[i], writer.tag(225, WireType.LengthDelimited).fork(), options).join();
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.MarketTemplateResponse
 */
export const MarketTemplateResponse = new MarketTemplateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangeMarketTemplateRequest$Type extends MessageType<ChangeMarketTemplateRequest> {
    constructor() {
        super("odds.ChangeMarketTemplateRequest", [
            { no: 12, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 224, name: "market_template_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ChangeMarketTemplateRequest>): ChangeMarketTemplateRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventId = "";
        message.competitionId = 0;
        message.marketTemplateId = 0;
        if (value !== undefined)
            reflectionMergePartial<ChangeMarketTemplateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChangeMarketTemplateRequest): ChangeMarketTemplateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_id */ 12:
                    message.eventId = reader.string();
                    break;
                case /* int32 competition_id */ 4:
                    message.competitionId = reader.int32();
                    break;
                case /* int32 market_template_id */ 224:
                    message.marketTemplateId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChangeMarketTemplateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event_id = 12; */
        if (message.eventId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.eventId);
        /* int32 competition_id = 4; */
        if (message.competitionId !== 0)
            writer.tag(4, WireType.Varint).int32(message.competitionId);
        /* int32 market_template_id = 224; */
        if (message.marketTemplateId !== 0)
            writer.tag(224, WireType.Varint).int32(message.marketTemplateId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.ChangeMarketTemplateRequest
 */
export const ChangeMarketTemplateRequest = new ChangeMarketTemplateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LandBaseResultsRequest$Type extends MessageType<LandBaseResultsRequest> {
    constructor() {
        super("odds.LandBaseResultsRequest", [
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 157, name: "search_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 159, name: "date_from", kind: "message", T: () => Timestamp },
            { no: 160, name: "date_to", kind: "message", T: () => Timestamp },
            { no: 266, name: "esport", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 161, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 77, name: "per_page", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<LandBaseResultsRequest>): LandBaseResultsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        message.searchString = "";
        message.esport = false;
        message.page = 0;
        message.perPage = 0;
        if (value !== undefined)
            reflectionMergePartial<LandBaseResultsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LandBaseResultsRequest): LandBaseResultsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* string search_string */ 157:
                    message.searchString = reader.string();
                    break;
                case /* google.protobuf.Timestamp date_from */ 159:
                    message.dateFrom = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dateFrom);
                    break;
                case /* google.protobuf.Timestamp date_to */ 160:
                    message.dateTo = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.dateTo);
                    break;
                case /* bool esport */ 266:
                    message.esport = reader.bool();
                    break;
                case /* int32 page */ 161:
                    message.page = reader.int32();
                    break;
                case /* int32 per_page */ 77:
                    message.perPage = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LandBaseResultsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* string search_string = 157; */
        if (message.searchString !== "")
            writer.tag(157, WireType.LengthDelimited).string(message.searchString);
        /* google.protobuf.Timestamp date_from = 159; */
        if (message.dateFrom)
            Timestamp.internalBinaryWrite(message.dateFrom, writer.tag(159, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp date_to = 160; */
        if (message.dateTo)
            Timestamp.internalBinaryWrite(message.dateTo, writer.tag(160, WireType.LengthDelimited).fork(), options).join();
        /* bool esport = 266; */
        if (message.esport !== false)
            writer.tag(266, WireType.Varint).bool(message.esport);
        /* int32 page = 161; */
        if (message.page !== 0)
            writer.tag(161, WireType.Varint).int32(message.page);
        /* int32 per_page = 77; */
        if (message.perPage !== 0)
            writer.tag(77, WireType.Varint).int32(message.perPage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LandBaseResultsRequest
 */
export const LandBaseResultsRequest = new LandBaseResultsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LandBaseResultEvent$Type extends MessageType<LandBaseResultEvent> {
    constructor() {
        super("odds.LandBaseResultEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 80, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "start", kind: "message", T: () => Timestamp },
            { no: 145, name: "result", kind: "message", T: () => EventResult }
        ]);
    }
    create(value?: PartialMessage<LandBaseResultEvent>): LandBaseResultEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.sportId = 0;
        message.landbaseCode = "";
        if (value !== undefined)
            reflectionMergePartial<LandBaseResultEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LandBaseResultEvent): LandBaseResultEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* string landbase_code */ 80:
                    message.landbaseCode = reader.string();
                    break;
                case /* google.protobuf.Timestamp start */ 22:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* common.EventResult result */ 145:
                    message.result = EventResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LandBaseResultEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* string landbase_code = 80; */
        if (message.landbaseCode !== "")
            writer.tag(80, WireType.LengthDelimited).string(message.landbaseCode);
        /* google.protobuf.Timestamp start = 22; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* common.EventResult result = 145; */
        if (message.result)
            EventResult.internalBinaryWrite(message.result, writer.tag(145, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LandBaseResultEvent
 */
export const LandBaseResultEvent = new LandBaseResultEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LandBaseResultsResponse$Type extends MessageType<LandBaseResultsResponse> {
    constructor() {
        super("odds.LandBaseResultsResponse", [
            { no: 44, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LandBaseResultEvent },
            { no: 161, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 162, name: "total_pages", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<LandBaseResultsResponse>): LandBaseResultsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        message.page = 0;
        message.totalPages = 0;
        if (value !== undefined)
            reflectionMergePartial<LandBaseResultsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LandBaseResultsResponse): LandBaseResultsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.LandBaseResultEvent events */ 44:
                    message.events.push(LandBaseResultEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 page */ 161:
                    message.page = reader.int32();
                    break;
                case /* int32 total_pages */ 162:
                    message.totalPages = reader.int32();
                    break;
                case /* common.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LandBaseResultsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.LandBaseResultEvent events = 44; */
        for (let i = 0; i < message.events.length; i++)
            LandBaseResultEvent.internalBinaryWrite(message.events[i], writer.tag(44, WireType.LengthDelimited).fork(), options).join();
        /* int32 page = 161; */
        if (message.page !== 0)
            writer.tag(161, WireType.Varint).int32(message.page);
        /* int32 total_pages = 162; */
        if (message.totalPages !== 0)
            writer.tag(162, WireType.Varint).int32(message.totalPages);
        /* common.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.LandBaseResultsResponse
 */
export const LandBaseResultsResponse = new LandBaseResultsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpecialMarketIds$Type extends MessageType<SpecialMarketIds> {
    constructor() {
        super("odds.SpecialMarketIds", [
            { no: 52, name: "market_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SpecialMarketIds>): SpecialMarketIds {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.marketIds = [];
        if (value !== undefined)
            reflectionMergePartial<SpecialMarketIds>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SpecialMarketIds): SpecialMarketIds {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 market_ids */ 52:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.marketIds.push(reader.int32());
                    else
                        message.marketIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SpecialMarketIds, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int32 market_ids = 52; */
        if (message.marketIds.length) {
            writer.tag(52, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.marketIds.length; i++)
                writer.int32(message.marketIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.SpecialMarketIds
 */
export const SpecialMarketIds = new SpecialMarketIds$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelEventOddsRequest$Type extends MessageType<CancelEventOddsRequest> {
    constructor() {
        super("odds.CancelEventOddsRequest", [
            { no: 1, name: "event_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<CancelEventOddsRequest>): CancelEventOddsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventId = "";
        if (value !== undefined)
            reflectionMergePartial<CancelEventOddsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelEventOddsRequest): CancelEventOddsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event_id */ 1:
                    message.eventId = reader.string();
                    break;
                case /* common.Error error */ 2:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelEventOddsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event_id = 1; */
        if (message.eventId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.eventId);
        /* common.Error error = 2; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.CancelEventOddsRequest
 */
export const CancelEventOddsRequest = new CancelEventOddsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OddStatusHistory$Type extends MessageType<OddStatusHistory> {
    constructor() {
        super("odds.OddStatusHistory", [
            { no: 1, name: "odd_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "previous_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "field", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "resolved_by", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "created_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<OddStatusHistory>): OddStatusHistory {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.oddId = "";
        message.status = "";
        message.previousStatus = "";
        message.field = "";
        message.resolvedBy = 0;
        if (value !== undefined)
            reflectionMergePartial<OddStatusHistory>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OddStatusHistory): OddStatusHistory {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string odd_id */ 1:
                    message.oddId = reader.string();
                    break;
                case /* string status */ 2:
                    message.status = reader.string();
                    break;
                case /* string previous_status */ 3:
                    message.previousStatus = reader.string();
                    break;
                case /* string field */ 4:
                    message.field = reader.string();
                    break;
                case /* int32 resolved_by */ 5:
                    message.resolvedBy = reader.int32();
                    break;
                case /* google.protobuf.Timestamp created_at */ 6:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OddStatusHistory, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string odd_id = 1; */
        if (message.oddId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.oddId);
        /* string status = 2; */
        if (message.status !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.status);
        /* string previous_status = 3; */
        if (message.previousStatus !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.previousStatus);
        /* string field = 4; */
        if (message.field !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.field);
        /* int32 resolved_by = 5; */
        if (message.resolvedBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.resolvedBy);
        /* google.protobuf.Timestamp created_at = 6; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.OddStatusHistory
 */
export const OddStatusHistory = new OddStatusHistory$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOddStatusHistoryResponse$Type extends MessageType<GetOddStatusHistoryResponse> {
    constructor() {
        super("odds.GetOddStatusHistoryResponse", [
            { no: 89, name: "odd_statuses", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OddStatusHistory }
        ]);
    }
    create(value?: PartialMessage<GetOddStatusHistoryResponse>): GetOddStatusHistoryResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.oddStatuses = [];
        if (value !== undefined)
            reflectionMergePartial<GetOddStatusHistoryResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOddStatusHistoryResponse): GetOddStatusHistoryResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.OddStatusHistory odd_statuses */ 89:
                    message.oddStatuses.push(OddStatusHistory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOddStatusHistoryResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.OddStatusHistory odd_statuses = 89; */
        for (let i = 0; i < message.oddStatuses.length; i++)
            OddStatusHistory.internalBinaryWrite(message.oddStatuses[i], writer.tag(89, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.GetOddStatusHistoryResponse
 */
export const GetOddStatusHistoryResponse = new GetOddStatusHistoryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FeaturedEvent$Type extends MessageType<FeaturedEvent> {
    constructor() {
        super("odds.FeaturedEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FeaturedEvent>): FeaturedEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<FeaturedEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FeaturedEvent): FeaturedEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FeaturedEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.FeaturedEvent
 */
export const FeaturedEvent = new FeaturedEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FeaturedEvents$Type extends MessageType<FeaturedEvents> {
    constructor() {
        super("odds.FeaturedEvents", [
            { no: 1, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event }
        ]);
    }
    create(value?: PartialMessage<FeaturedEvents>): FeaturedEvents {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<FeaturedEvents>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FeaturedEvents): FeaturedEvents {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds.Event events */ 1:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FeaturedEvents, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds.Event events = 1; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds.FeaturedEvents
 */
export const FeaturedEvents = new FeaturedEvents$Type();
/**
 * @generated ServiceType for protobuf service odds.OddsService
 */
export const OddsService = new ServiceType("odds.OddsService", [
    { name: "UnaryUnaryGetInitialData", options: {}, I: GetInitialDataRequest, O: GetInitialDataResponse },
    { name: "UnaryStreamGetEvents", serverStreaming: true, options: {}, I: Empty, O: GetEventsResponse },
    { name: "LiveEventsStream", serverStreaming: true, options: {}, I: Empty, O: GetEventsResponse },
    { name: "GetEvent", options: {}, I: StreamEvent, O: StreamEvent },
    { name: "GetOdds", options: {}, I: GetOddsRequest, O: GetOddsResponse },
    { name: "GetFastOdds", options: {}, I: GetFastOddsRequest, O: GetOddsResponse },
    { name: "GetStatusesForEventsAndOdds", options: {}, I: GetStatusesForEventsAndOddsRequest, O: GetStatusesForEventsAndOddsResponse },
    { name: "UpdateMissingSlipResults", options: {}, I: UpdateMissingSlipResultsRequest, O: Empty },
    { name: "UnaryUnaryGetInitialDataForProviderBO", options: {}, I: GetForProviderRequest, O: GetInitialDataForBoResponse },
    { name: "GetProvidersBO", options: {}, I: Empty, O: GetProvidersResponse },
    { name: "UnaryUnaryGetEventsForProviderBO", options: {}, I: GetForProviderRequest, O: UpcomingEvents },
    { name: "UnaryUnaryPublishEventsBO", options: {}, I: PublishEventsBoRequest, O: PublishEventsBoResponse },
    { name: "GetAllEventsBO", options: {}, I: Empty, O: AllEvents },
    { name: "CreateOfferBO", options: {}, I: CreateOfferRequest, O: EmptyError },
    { name: "CreateEventBO", options: {}, I: CreateEventRequest, O: EmptyError },
    { name: "ToggleFavoriteCompetitions", options: {}, I: ToggleFavoriteRequest, O: EmptyError },
    { name: "GetCompetitorsBO", options: {}, I: Empty, O: GetCompetitorsBoResponse },
    { name: "GetCompetitorsForManualEventsBO", options: {}, I: GetCompetitorsRequest, O: GetCompetitorsBoResponse },
    { name: "GetAllMarketGroupsBO", options: {}, I: GetAllMarketGroupsBoRequest, O: GetAllMarketGroupsBoResponse },
    { name: "GetAllMarketsBO", options: {}, I: GetAllMarketsBoRequest, O: GetAllMarketsBoResponse },
    { name: "GetSportsBO", options: {}, I: Empty, O: GetSportsBoReponse },
    { name: "UpdateMarketGroupsBO", options: {}, I: UpdateMarketGroupsBoRequest, O: EmptyError },
    { name: "GetCompetitionsBO", options: {}, I: Empty, O: GetCompetitionsBoResponse },
    { name: "GetCompetitionsForManualEventsBO", options: {}, I: Sport, O: GetCompetitionsBoResponse },
    { name: "GetLocationsBO", options: {}, I: Empty, O: GetLocationsBoResponse },
    { name: "GetAllLandBaseOffers", options: {}, I: Empty, O: GetAllLandBaseOffersResponse },
    { name: "CreateLandBaseOffer", options: {}, I: CreateLandBaseOfferRequest, O: CreateLandBaseOfferResponse },
    { name: "GetEventsInTimeRange", options: {}, I: TimeRange, O: AllEvents },
    { name: "EditLandBaseOffer", options: {}, I: EditLandBaseOfferRequest, O: EditLandBaseOfferResponse },
    { name: "GetEventsForLandBaseOffer", options: {}, I: LandBaseId, O: AllEvents },
    { name: "SearchCompetitorsBO", options: {}, I: SearchCompetitorRequest, O: GetCompetitorsBoResponse },
    { name: "LinkCompetitorBO", options: {}, I: LinkCompetitorRequest, O: EmptyError },
    { name: "GetProviderCompetitorsBO", options: {}, I: GetProviderCompetitorsRequest, O: GetProviderCompetitorsResponse },
    { name: "GetPlayersBO", options: {}, I: Empty, O: GetPlayersResponse },
    { name: "AddPlayerBO", options: {}, I: AddPlayerRequest, O: AddPlayerResponse },
    { name: "EditPlayerBO", options: {}, I: EditPlayer, O: EditPlayer },
    { name: "LinkPlayerBO", options: {}, I: LinkPlayerRequest, O: EmptyError },
    { name: "GetProviderPlayersBO", options: {}, I: GetProviderPlayersRequest, O: GetProviderPlayersResponse },
    { name: "AddCompetitorBO", options: {}, I: AddCompetitorRequest, O: AddCompetitorResponse },
    { name: "EditCompetitorBO", options: {}, I: EditCompetitorRequest, O: EmptyError },
    { name: "AutoAddCompetitionEventsBO", options: {}, I: AutoAddCompetitionEventsRequest, O: EmptyError },
    { name: "GetOutcomesBO", options: {}, I: Sport, O: GetOutcomesResponse },
    { name: "GetFilteredOutcomesBO", options: {}, I: GetOutcomesRequest, O: GetOutcomesResponse },
    { name: "GetProviderOutcomesBO", options: {}, I: Empty, O: GetProviderOutcomesResponse },
    { name: "EditOutcomeBO", options: {}, I: Outcome, O: EmptyError },
    { name: "GetEventTypesBO", options: {}, I: Empty, O: GetEventTypesResponse },
    { name: "ConfigureEventStartTimeBO", options: {}, I: ConfigureEventStartTimeRequest, O: Event },
    { name: "GetEventsByType", options: {}, I: GetEventsByTypeRequest, O: GetEventsByTypeResponse },
    { name: "EditEventBO", options: {}, I: EditEventRequest, O: EmptyError },
    { name: "PublishManualEventsBO", options: {}, I: PublishEventsBoRequest, O: PublishEventsBoResponse },
    { name: "ManualResolveOdd", options: {}, I: ResolveOddRequest, O: EmptyError },
    { name: "AddCompetitionBO", options: {}, I: AddCompetitionRequest, O: AddCompetitionResponse },
    { name: "EditCompetitionBO", options: {}, I: EditCompetitionRequest, O: EmptyError },
    { name: "GetAllLiveEventsBO", options: {}, I: Empty, O: LiveEventsBO },
    { name: "GetEventOdds", options: {}, I: GetEventOddsRequest, O: GetEventOddsResponse },
    { name: "DisableEvent", options: {}, I: DisableEventRequest, O: EmptyError },
    { name: "GetEventsWithPlayersBO", options: {}, I: GetEventsWithPlayersRequest, O: GetEventsWithPlayersResponse },
    { name: "GetPlayerMarketsBO", options: {}, I: Empty, O: GetMarketsResponse },
    { name: "GetGroupMarketsBO", options: {}, I: Empty, O: GetMarketsResponse },
    { name: "GetEventResult", options: {}, I: Event, O: EventResult },
    { name: "GetUnresolvedEventsBO", serverStreaming: true, options: {}, I: Empty, O: GetUnresolvedEventsResponse },
    { name: "GetSportsWithCompetitionsBO", options: {}, I: Empty, O: GetSportsWithCompetitionsResponse },
    { name: "GetEventsForManualResultsBO", serverStreaming: true, options: {}, I: Empty, O: GetEventsForManualResultsResponse },
    { name: "EditManualResultBO", options: {}, I: EditManualResultRequest, O: EmptyError },
    { name: "GetEventsResultData", options: {}, I: AllEvents, O: AllEvents },
    { name: "GenerateListPDF", serverStreaming: true, options: {}, I: GenerateListPDFRequest, O: GenerateListPDFResponse },
    { name: "ResetLandbaseCodes", options: {}, I: ResetLandbaseCodesRequest, O: Empty },
    { name: "CancelAllUnplayed", options: {}, I: CancelAllUnplayedRequest, O: EmptyError },
    { name: "GetValidationPriorityObjects", options: {}, I: Empty, O: ValidationPriorityObjects },
    { name: "AddNoteToEvent", options: {}, I: AddNoteRequest, O: Event },
    { name: "SetSuspiciousEvent", options: {}, I: SuspiciousEvent, O: EmptyError },
    { name: "UpdateOdds", options: {}, I: UpdateOddsRequest, O: GetEventOddsResponse },
    { name: "GetMarketsForValidationBO", options: {}, I: GetAllMarketsBoRequest, O: GetMarketsResponse },
    { name: "GetSuspiciousLevels", options: {}, I: Empty, O: SuspiciousLevels },
    { name: "GetMatchProfiles", options: {}, I: Empty, O: MatchProfiles },
    { name: "ToggleSingleApproval", options: {}, I: SingleApproval, O: EmptyError },
    { name: "UpdateChangedLimitOdds", options: {}, I: UpdateChangedLimitOddsRequest, O: UpdateChangedLimitOddsResponse },
    { name: "DisableLiveAll", options: {}, I: DisableLive, O: DisableLive },
    { name: "DisableLiveSport", options: {}, I: DisableLive, O: DisableLive },
    { name: "ForceNewLandBaseCode", options: {}, I: Event, O: Empty },
    { name: "GetCancelledEvents", options: {}, I: Empty, O: AllEvents },
    { name: "GetCancelledEventsPaginated", options: {}, I: CancelledEventsRequest, O: CancelledEventsResponse },
    { name: "CancelEvent", options: {}, I: Event, O: Empty },
    { name: "FinishCancelledEvent", options: {}, I: Event, O: EmptyError },
    { name: "FinishEvent", options: {}, I: Event, O: EmptyError },
    { name: "UpdateEventResultBO", options: {}, I: UpdateEventResultRequest, O: EmptyError },
    { name: "CancelEventsOdds", options: {}, I: CancelEventOddsRequest, O: EmptyError },
    { name: "GetOddStatusHistory", options: {}, I: Odd, O: GetOddStatusHistoryResponse },
    { name: "UpdateAllLandBaseCodes", options: {}, I: UpdateLandBaseCodes, O: EmptyError },
    { name: "UpdateCompetitionLandBaseCodes", options: {}, I: UpdateLandBaseCodes, O: EmptyError },
    { name: "UpdateEventLandBaseCodes", options: {}, I: UpdateLandBaseCodes, O: EmptyError },
    { name: "GetEventsForLinking", options: {}, I: GetEventsForLinkingRequest, O: AllEvents },
    { name: "LinkEvents", options: {}, I: LinkEventsRequest, O: EmptyError },
    { name: "UnlinkEvents", options: {}, I: Event, O: EmptyError },
    { name: "ToggleBlockEvent", options: {}, I: BlockEventRequest, O: EmptyError },
    { name: "GetBlockEventReasons", options: {}, I: Empty, O: BlockEventReasons },
    { name: "GetMarketTemplates", options: {}, I: Empty, O: MarketTemplateResponse },
    { name: "ChangeMarketTemplate", options: {}, I: ChangeMarketTemplateRequest, O: EmptyError },
    { name: "GetMarketTemplatesForSport", options: {}, I: GetMarketTemplatesRequest, O: MarketTemplateResponse },
    { name: "CreateMarketTemplate", options: {}, I: MarketTemplate, O: EmptyError },
    { name: "UpdateMarketTemplate", options: {}, I: MarketTemplate, O: EmptyError },
    { name: "ToggleMarketTemplate", options: {}, I: MarketTemplate, O: EmptyError },
    { name: "GetPaginatedCompetitionsBO", options: {}, I: GetPaginatedRequest, O: GetPaginatedCompetitionsBoResponse },
    { name: "GetCompetitionsForLocationAndSportBO", options: {}, I: SportLocationRequest, O: GetCompetitionsBoResponse },
    { name: "UpdateLocationOrderBO", options: {}, I: LocationOrderRequest, O: EmptyError },
    { name: "GetCompetitionsForLinkingBO", options: {}, I: CompetitionsForLinkingRequest, O: CompetitionsForLinkingResponse },
    { name: "GetProviderCompetitionsBO", options: {}, I: CompetitionsForLinkingRequest, O: CompetitionsForLinkingResponse },
    { name: "LinkCompetitionBO", options: {}, I: LinkCompetitionRequest, O: EmptyError },
    { name: "UnlinkCompetitionBO", options: {}, I: LinkCompetitionRequest, O: EmptyError },
    { name: "GetGlobalCompetitionBetLimit", options: {}, I: GetCompetitionBetLimitRequest, O: GetCompetitionBetLimitResponse },
    { name: "GetCompetitionBetLimit", options: {}, I: GetCompetitionBetLimitRequest, O: GetCompetitionBetLimitResponse },
    { name: "GetSingleCompetitionBetLimit", options: {}, I: CompetitionLimit, O: CompetitionLimit },
    { name: "SetCompetitionBetLimit", options: {}, I: CompetitionLimit, O: EmptyError },
    { name: "ResetCompetitionBetLimit", options: {}, I: CompetitionLimit, O: EmptyError },
    { name: "RemoveCompetitionBetLimit", options: {}, I: CompetitionLimit, O: EmptyError },
    { name: "SetGlobalCompetitionBetLimit", options: {}, I: CompetitionLimit, O: EmptyError },
    { name: "UpdateCompetitionLimitAmount", options: {}, I: UpdateCompetitionLimit, O: EmptyError },
    { name: "BrokenRpc", options: {}, I: Empty, O: Empty },
    { name: "SearchEvents", options: {}, I: SearchEventsRequest, O: AllEvents },
    { name: "SearchOutcomes", options: {}, I: SearchOutcomesRequest, O: SearchOutcomesResponse },
    { name: "GetOutcomesLandBase", options: {}, I: Empty, O: GetOutcomesResponse },
    { name: "GetEventOddsBO", options: {}, I: GetEventOddsRequest, O: Event },
    { name: "MarketOrderBySport", options: {}, I: Empty, O: GetSportsBoReponse },
    { name: "GetFinishedEventsWithResults", options: {}, I: LandBaseResultsRequest, O: LandBaseResultsResponse },
    { name: "GetTennisSpecialMarkets", options: {}, I: Empty, O: SpecialMarketIds },
    { name: "GetLiveDisableStatus", options: {}, I: Empty, O: LiveDisableStatus },
    { name: "SyncLandBase", options: {}, I: SyncLandBaseRequest, O: SyncLandBaseResponse },
    { name: "GetLiveOrder", options: {}, I: Empty, O: LiveOutcomeOrderResponse },
    { name: "GetMissingLiveEvent", options: {}, I: Event, O: StreamEvent },
    { name: "GetMissingPrematchEvent", options: {}, I: Event, O: StreamEvent },
    { name: "GetDailyList", options: {}, I: GetDailyListRequest, O: GetDailyListResponse },
    { name: "GetEventByLandBaseCode", options: {}, I: LandBaseCode, O: LandBaseCodeEvent },
    { name: "GetActiveLandbaseCodes", options: {}, I: GetActiveLBCodesRequest, O: GetActiveLBCodesResponse },
    { name: "GetSideBarBO", options: {}, I: GetSideBarRequest, O: GetSideBarResponse },
    { name: "GetEventsPublishOfferBO", options: {}, I: GetPaginatedEventsRequest, O: PaginatedEvents },
    { name: "GetEventsCreateOfferBO", options: {}, I: GetPaginatedEventsRequest, O: PaginatedEvents },
    { name: "GetFwwEventsCreateOffer", options: {}, I: GetPaginatedEventsRequest, O: PaginatedEvents },
    { name: "GetEventsResolvingBO", options: {}, I: GetPaginatedEventsRequest, O: PaginatedEvents },
    { name: "GetSingleEventResolvingBO", options: {}, I: GetPaginatedEventsRequest, O: Event },
    { name: "GetEventsResultsBO", options: {}, I: GetPaginatedEventsRequest, O: PaginatedEvents },
    { name: "PublishPaginatedEventsBO", options: {}, I: PublishEventsBoRequest, O: PublishEventsBoResponse },
    { name: "PublishPaginatedManualEventsBO", options: {}, I: PublishEventsBoRequest, O: PublishEventsBoResponse },
    { name: "GetPaginatedCompetitorsBO", options: {}, I: GetPaginatedRequest, O: GetPaginatedCompetitorsBoResponse },
    { name: "PublishFilteredEventsBO", options: {}, I: GetPaginatedEventsRequest, O: PublishEventsBoResponse },
    { name: "GetIsonisOutcomeArgs", options: {}, I: Empty, O: IsonisOutcomeArgs },
    { name: "SetPASport", options: {}, I: PASport, O: EmptyError },
    { name: "TimeCancelEvent", options: {}, I: TimeCancelRequest, O: EmptyError },
    { name: "UndoTimeCancelEvent", options: {}, I: TimeCancelRequest, O: EmptyError },
    { name: "GetMinLimit", options: {}, I: GetMinLimitRequest, O: GetMinLimitResponse },
    { name: "GetLandBaseCounters", options: {}, I: Empty, O: LandBaseCounters },
    { name: "UpdateLandBaseCounter", options: {}, I: LandBaseCounter, O: LandBaseCounter },
    { name: "SetNextLandBaseCounter", options: {}, I: LandBaseCounter, O: LandBaseCounter },
    { name: "ResetLandBaseCounter", options: {}, I: LandBaseCounter, O: LandBaseCounter },
    { name: "ResetAllLandBaseCounters", options: {}, I: Empty, O: EmptyError },
    { name: "AssignLandBaseCode", options: {}, I: AssignLandBaseCodeRequest, O: EmptyError },
    { name: "GetEventsCreateOffer", options: {}, I: AssignLandBaseCodeRequest, O: GetEventsCreateOfferResponse },
    { name: "AssignLandBaseCodes", options: {}, I: AssignLandBaseCodeRequest, O: AssignLandBaseCodeResponse },
    { name: "RemoveLandBaseCodes", options: {}, I: AssignLandBaseCodeRequest, O: EmptyError },
    { name: "GetDuplicates", options: {}, I: DuplicatesRequest, O: DuplicatesResponse },
    { name: "CreateFwwEvent", options: {}, I: EventFwwRequest, O: Event },
    { name: "EditFwwEvent", options: {}, I: EventFwwRequest, O: Event },
    { name: "CreateFwwOdd", options: {}, I: OddFwwRequest, O: Odd },
    { name: "EditFwwOdd", options: {}, I: OddFwwRequest, O: Odd },
    { name: "CreateLocation", options: {}, I: Location, O: Location },
    { name: "CreateFwwCompetition", options: {}, I: CompetitionFwwRequest, O: Competition },
    { name: "SetFeaturedEvents", options: {}, I: FeaturedEvents, O: Empty },
    { name: "GetFeaturedEventsBO", options: {}, I: Empty, O: FeaturedEvents },
    { name: "GetFeaturedEvents", options: {}, I: Empty, O: FeaturedEvents }
]);
