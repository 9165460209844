import React, { useState, useEffect } from 'react';
import { formatTime } from 'libs/datetime';

const Clock = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  const setupClock = () => {
    return window.setInterval(() => setCurrentTime(new Date()), 60000);
  };

  useEffect(() => {
    const currentSeconds = currentTime.getSeconds();
    const secondsLeft = 60 - currentSeconds;
    let clock: any;

    window.setTimeout(() => {
      setCurrentTime(new Date());
      clock = setupClock();
    }, secondsLeft * 1000);

    return () => {
      if (clock) {
        window.clearInterval(clock);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <span>{formatTime(currentTime)}</span>;
};

export default Clock;
