/* React modules */

/* Our modules */
import { InitialBasicOffer } from 'generated-proto/proto/cache_service/cache_pb';
import {
  PlayerOutrightMarkets,
  InitialMarket,
} from 'generated-proto/proto/odds/web_odds_pb';
import { Sport, MarketGroup, Market } from 'modules/sports/sports.types';
import { SportMarket } from 'modules/sports/market-model';
import { SportMarketGroup } from 'modules/sports/market-group-model';

/* 3rd Party modules */

class MarketService {
  private sportId: number;
  private marketGroups: SportMarketGroup[];

  public groups: SportMarketGroup[] = [];
  public marketsList: SportMarket[] = [];
  public initialMarkets: any;

  constructor(
    sport: Sport,
    marketGroups: MarketGroup[],
    outrightPlayersMarkets: PlayerOutrightMarkets
  ) {
    const { outrightMarkets, playerMarkets } = outrightPlayersMarkets;

    this.sportId = sport.id;

    this.marketGroups = (marketGroups || []).map(
      (group) => new SportMarketGroup(group)
    );

    this.groups = this.getSportGroups();

    this.marketsList = [
      ...this.getSportMarkets(),
      ...(outrightMarkets || []).map((m: any) => new SportMarket(m)),
      ...(playerMarkets || []).map((m: any) => new SportMarket(m)),
    ];

    this.initialMarkets = {
      live: sport.basicOfferLive
        ? this.getInitialMarkets(sport.basicOfferLive)
        : [],
      upcoming: sport.basicOfferPrematch
        ? this.getInitialMarkets(sport.basicOfferPrematch)
        : [],
      antepost: this.formatMarkets(outrightMarkets),
      player: this.formatMarkets(playerMarkets),
      special: this.formatMarkets(playerMarkets),
    };
  }

  formatMarkets(markets: InitialMarket[]) {
    return markets.map((m: any) => new SportMarket(m));
  }

  getInitialMarkets(configuration: InitialBasicOffer) {
    const { markets } = configuration || {};

    return (markets || [])
      .map(({ marketId, outcomeIds }) => {
        const market = (this.marketsList || []).find((m) => m.id === marketId);

        if (market) {
          return Object.assign(
            Object.create(Object.getPrototypeOf(market)),
            market,
            {
              outcomes: (outcomeIds || []).map((outcomeId) => {
                return (market.outcomes || []).find(
                  (outcome) => outcome.id === outcomeId
                );
              }),
            }
          );
        }

        return null;
      })
      .filter((m: any) => !!m);
  }

  getSportGroups(): SportMarketGroup[] {
    return this.marketGroups.filter((group: any) => {
      return group.marketsList.some((m: any) => m.sportId === this.sportId);
    });
  }

  getSportMarkets(): SportMarket[] {
    return this.groups.reduce(
      (markets: SportMarket[], group: SportMarketGroup) => {
        return [
          ...markets,
          ...group.marketsList.map((m: Market) => new SportMarket(m)),
        ];
      },
      []
    );
  }
}

export { MarketService };
