// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/odds_stream/odds_stream.proto" (package "odds_stream", syntax proto3)
// tslint:disable
import { Empty } from "../../google/protobuf/empty_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
/**
 * Events stream
 *
 * @generated from protobuf message odds_stream.EventTime
 */
export interface EventTime {
    /**
     * @generated from protobuf field: string M = 202 [json_name = "M"];
     */
    m: string;
    /**
     * @generated from protobuf field: string S = 203 [json_name = "S"];
     */
    s: string;
}
/**
 * @generated from protobuf message odds_stream.EventCurrentPhase
 */
export interface EventCurrentPhase {
    /**
     * @generated from protobuf field: string N = 200 [json_name = "N"];
     */
    n: string;
    /**
     * @generated from protobuf field: string S = 201 [json_name = "S"];
     */
    s: string;
    /**
     * @generated from protobuf field: odds_stream.EventTime T = 204 [json_name = "T"];
     */
    t?: EventTime;
}
/**
 * @generated from protobuf message odds_stream.EventCurrentResult
 */
export interface EventCurrentResult {
    /**
     * @generated from protobuf field: string G = 205 [json_name = "G"];
     */
    g: string;
    /**
     * @generated from protobuf field: string C = 206 [json_name = "C"];
     */
    c: string;
    /**
     * @generated from protobuf field: string RC = 207 [json_name = "RC"];
     */
    rC: string;
    /**
     * @generated from protobuf field: string YC = 208 [json_name = "YC"];
     */
    yC: string;
    /**
     * @generated from protobuf field: string P = 209 [json_name = "P"];
     */
    p: string;
    /**
     * @generated from protobuf field: string S = 210 [json_name = "S"];
     */
    s: string;
    /**
     * @generated from protobuf field: string L = 267 [json_name = "L"];
     */
    l: string;
    /**
     * @generated from protobuf field: string S180 = 268 [json_name = "S180"];
     */
    s180: string;
    /**
     * @generated from protobuf field: string FG = 269 [json_name = "FG"];
     */
    fG: string;
    /**
     * @generated from protobuf field: string PN = 270 [json_name = "PN"];
     */
    pN: string;
    /**
     * @generated from protobuf field: string TD = 271 [json_name = "TD"];
     */
    tD: string;
}
/**
 * @generated from protobuf message odds_stream.EventLiveResultHistoryElement
 */
export interface EventLiveResultHistoryElement {
    /**
     * @generated from protobuf field: odds_stream.EventCurrentPhase P = 211 [json_name = "P"];
     */
    p?: EventCurrentPhase;
    /**
     * @generated from protobuf field: odds_stream.EventCurrentResult R = 212 [json_name = "R"];
     */
    r?: EventCurrentResult;
}
/**
 * @generated from protobuf message odds_stream.Error
 */
export interface Error {
    /**
     * @generated from protobuf field: int32 grpc_code = 5;
     */
    grpcCode: number;
    /**
     * @generated from protobuf field: int32 http_code = 6;
     */
    httpCode: number;
    /**
     * @generated from protobuf field: repeated string message = 7;
     */
    message: string[];
}
/**
 * @generated from protobuf message odds_stream.EventResult
 */
export interface EventResult {
    /**
     * @generated from protobuf field: odds_stream.EventCurrentPhase current_phase = 213;
     */
    currentPhase?: EventCurrentPhase;
    /**
     * @generated from protobuf field: odds_stream.EventCurrentResult current_result = 214;
     */
    currentResult?: EventCurrentResult;
    /**
     * @generated from protobuf field: repeated odds_stream.EventLiveResultHistoryElement live_result_history = 215;
     */
    liveResultHistory: EventLiveResultHistoryElement[];
    /**
     * @generated from protobuf field: string live_result_stats = 216;
     */
    liveResultStats: string;
    /**
     * @generated from protobuf field: string prematch_result = 217;
     */
    prematchResult: string;
    /**
     * @generated from protobuf field: string live_result = 218;
     */
    liveResult: string;
    /**
     * @generated from protobuf field: bool confirmed = 219;
     */
    confirmed: boolean;
    /**
     * @generated from protobuf field: odds_stream.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds_stream.EventStream
 */
export interface EventStream {
    /**
     * @generated from protobuf field: odds_stream.WebStreamEvents live_events = 1;
     */
    liveEvents?: WebStreamEvents;
    /**
     * @generated from protobuf field: odds_stream.WebStreamEvents upcoming_events = 2;
     */
    upcomingEvents?: WebStreamEvents;
    /**
     * @generated from protobuf field: odds_stream.WebStreamEvents outright_events = 3;
     */
    outrightEvents?: WebStreamEvents;
    /**
     * @generated from protobuf field: odds_stream.WebStreamEvents player_events = 4;
     */
    playerEvents?: WebStreamEvents;
    /**
     * @generated from protobuf field: odds_stream.WebStreamEvents group_events = 5;
     */
    groupEvents?: WebStreamEvents;
}
/**
 * @generated from protobuf message odds_stream.WebStreamEvents
 */
export interface WebStreamEvents {
    /**
     * @generated from protobuf field: repeated odds_stream.WebStreamEvent events = 17;
     */
    events: WebStreamEvent[];
}
/**
 * @generated from protobuf message odds_stream.WebStreamEvent
 */
export interface WebStreamEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 competition_id = 3;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: int32 location_id = 4;
     */
    locationId: number;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated odds_stream.WebStreamEventOdd odds = 6;
     */
    odds: WebStreamEventOdd[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 7;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: bool is_disabled = 8;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: odds_stream.EventResult result = 9;
     */
    result?: EventResult;
    /**
     * @generated from protobuf field: string landbase_code = 10;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: string type = 11;
     */
    type: string;
    /**
     * @generated from protobuf field: string outright_type = 12;
     */
    outrightType: string;
    /**
     * @generated from protobuf field: int32 mon = 13;
     */
    mon: number;
    /**
     * @generated from protobuf field: int32 number_of_odds = 14;
     */
    numberOfOdds: number;
    /**
     * @generated from protobuf field: int32 exefeed_id = 15;
     */
    exefeedId: number;
    /**
     * @generated from protobuf field: string profile = 16;
     */
    profile: string;
    /**
     * @generated from protobuf field: string betradar_id = 17;
     */
    betradarId: string;
    /**
     * @generated from protobuf field: string betradar_stream_id = 18;
     */
    betradarStreamId: string;
}
/**
 * @generated from protobuf message odds_stream.WebStreamEventOdd
 */
export interface WebStreamEventOdd {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: double value = 2;
     */
    value: number;
    /**
     * @generated from protobuf field: int32 outcome_id = 3;
     */
    outcomeId: number;
    /**
     * @generated from protobuf field: double limit = 4;
     */
    limit: number;
    /**
     * @generated from protobuf field: int32 frame_number = 5;
     */
    frameNumber: number;
    /**
     * @generated from protobuf field: string current_result = 6;
     */
    currentResult: string;
    /**
     * @generated from protobuf field: bool is_disabled = 7;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: int32 status = 8;
     */
    status: number;
    /**
     * @generated from protobuf field: string live_player = 9;
     */
    livePlayer: string;
}
/**
 * @generated from protobuf message odds_stream.EventStreamInt
 */
export interface EventStreamInt {
    /**
     * @generated from protobuf field: odds_stream.WebStreamEventsInt live_events = 1;
     */
    liveEvents?: WebStreamEventsInt;
    /**
     * @generated from protobuf field: odds_stream.WebStreamEventsInt upcoming_events = 2;
     */
    upcomingEvents?: WebStreamEventsInt;
    /**
     * @generated from protobuf field: odds_stream.WebStreamEventsInt outright_events = 3;
     */
    outrightEvents?: WebStreamEventsInt;
    /**
     * @generated from protobuf field: odds_stream.WebStreamEventsInt player_events = 4;
     */
    playerEvents?: WebStreamEventsInt;
    /**
     * @generated from protobuf field: odds_stream.WebStreamEventsInt group_events = 5;
     */
    groupEvents?: WebStreamEventsInt;
}
/**
 * @generated from protobuf message odds_stream.WebStreamEventsInt
 */
export interface WebStreamEventsInt {
    /**
     * @generated from protobuf field: repeated odds_stream.WebStreamEventInt events = 17;
     */
    events: WebStreamEventInt[];
}
/**
 * @generated from protobuf message odds_stream.WebStreamEventInt
 */
export interface WebStreamEventInt {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 competition_id = 3;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: int32 location_id = 4;
     */
    locationId: number;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated odds_stream.WebStreamEventOddInt odds = 6;
     */
    odds: WebStreamEventOddInt[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 7;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: bool is_disabled = 8;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: odds_stream.EventResult result = 9;
     */
    result?: EventResult;
    /**
     * @generated from protobuf field: string landbase_code = 10;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: string type = 11;
     */
    type: string;
    /**
     * @generated from protobuf field: string outright_type = 12;
     */
    outrightType: string;
    /**
     * @generated from protobuf field: int32 mon = 13;
     */
    mon: number;
    /**
     * @generated from protobuf field: int32 number_of_odds = 14;
     */
    numberOfOdds: number;
    /**
     * @generated from protobuf field: int32 exefeed_id = 15;
     */
    exefeedId: number;
    /**
     * @generated from protobuf field: string profile = 16;
     */
    profile: string;
    /**
     * @generated from protobuf field: string betradar_id = 17;
     */
    betradarId: string;
    /**
     * @generated from protobuf field: string betradar_stream_id = 18;
     */
    betradarStreamId: string;
}
/**
 * @generated from protobuf message odds_stream.WebStreamEventOddInt
 */
export interface WebStreamEventOddInt {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int32 value = 2;
     */
    value: number;
    /**
     * @generated from protobuf field: int32 outcome_id = 3;
     */
    outcomeId: number;
    /**
     * @generated from protobuf field: int32 limit = 4;
     */
    limit: number;
    /**
     * @generated from protobuf field: int32 frame_number = 5;
     */
    frameNumber: number;
    /**
     * @generated from protobuf field: string current_result = 6;
     */
    currentResult: string;
    /**
     * @generated from protobuf field: bool is_disabled = 7;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: int32 status = 8;
     */
    status: number;
    /**
     * @generated from protobuf field: string live_player = 9;
     */
    livePlayer: string;
}
/**
 * @generated from protobuf message odds_stream.StreamEvent
 */
export interface StreamEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string note = 3;
     */
    note: string;
    /**
     * @generated from protobuf field: int32 competition_id = 4;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: int32 location_id = 5;
     */
    locationId: number;
    /**
     * @generated from protobuf field: int32 sport_id = 6;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated odds_stream.StreamEventOdd odds = 7;
     */
    odds: StreamEventOdd[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 8;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: bool is_disabled = 9;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: bool is_suspicious = 10;
     */
    isSuspicious: boolean;
    /**
     * @generated from protobuf field: bool is_single_approval = 11;
     */
    isSingleApproval: boolean;
    /**
     * @generated from protobuf field: string profile = 12;
     */
    profile: string;
    /**
     * @generated from protobuf field: odds_stream.EventCurrentPhase current_phase = 13;
     */
    currentPhase?: EventCurrentPhase;
    /**
     * @generated from protobuf field: string landbase_code = 14;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: string type = 15;
     */
    type: string;
    /**
     * @generated from protobuf field: string outright_type = 16;
     */
    outrightType: string;
    /**
     * @generated from protobuf field: int32 mon = 17;
     */
    mon: number;
    /**
     * @generated from protobuf field: odds_stream.EventResult result = 18;
     */
    result?: EventResult;
}
/**
 * @generated from protobuf message odds_stream.StreamEventOdd
 */
export interface StreamEventOdd {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: double value = 8;
     */
    value: number;
    /**
     * @generated from protobuf field: int32 outcome_id = 11;
     */
    outcomeId: number;
    /**
     * @generated from protobuf field: double limit = 122;
     */
    limit: number;
    /**
     * @generated from protobuf field: int32 frame_number = 238;
     */
    frameNumber: number;
    /**
     * @generated from protobuf field: string current_result = 239;
     */
    currentResult: string;
    /**
     * @generated from protobuf field: bool is_disabled = 136;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: string live_player = 9;
     */
    livePlayer: string;
}
/**
 * @generated from protobuf message odds_stream.LiveEvents
 */
export interface LiveEvents {
    /**
     * @generated from protobuf field: repeated odds_stream.StreamEvent events = 17;
     */
    events: StreamEvent[];
    /**
     * @generated from protobuf field: bool disable_all = 193;
     */
    disableAll: boolean;
    /**
     * @generated from protobuf field: repeated int32 disabled_sports = 194;
     */
    disabledSports: number[];
}
/**
 * @generated from protobuf message odds_stream.UpcomingEvents
 */
export interface UpcomingEvents {
    /**
     * @generated from protobuf field: repeated odds_stream.StreamEvent events = 17;
     */
    events: StreamEvent[];
    /**
     * @generated from protobuf field: odds_stream.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message odds_stream.GetEventsResponse
 */
export interface GetEventsResponse {
    /**
     * @generated from protobuf field: odds_stream.LiveEvents live_events = 36;
     */
    liveEvents?: LiveEvents;
    /**
     * @generated from protobuf field: odds_stream.UpcomingEvents upcoming_events = 37;
     */
    upcomingEvents?: UpcomingEvents;
    /**
     * @generated from protobuf field: odds_stream.UpcomingEvents outright_events = 247;
     */
    outrightEvents?: UpcomingEvents;
    /**
     * @generated from protobuf field: odds_stream.UpcomingEvents player_events = 248;
     */
    playerEvents?: UpcomingEvents;
    /**
     * @generated from protobuf field: odds_stream.UpcomingEvents group_events = 249;
     */
    groupEvents?: UpcomingEvents;
}
// @generated message type with reflection information, may provide speed optimized methods
class EventTime$Type extends MessageType<EventTime> {
    constructor() {
        super("odds_stream.EventTime", [
            { no: 202, name: "M", kind: "scalar", jsonName: "M", T: 9 /*ScalarType.STRING*/ },
            { no: 203, name: "S", kind: "scalar", jsonName: "S", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EventTime>): EventTime {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.m = "";
        message.s = "";
        if (value !== undefined)
            reflectionMergePartial<EventTime>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventTime): EventTime {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string M = 202 [json_name = "M"];*/ 202:
                    message.m = reader.string();
                    break;
                case /* string S = 203 [json_name = "S"];*/ 203:
                    message.s = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventTime, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string M = 202 [json_name = "M"]; */
        if (message.m !== "")
            writer.tag(202, WireType.LengthDelimited).string(message.m);
        /* string S = 203 [json_name = "S"]; */
        if (message.s !== "")
            writer.tag(203, WireType.LengthDelimited).string(message.s);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.EventTime
 */
export const EventTime = new EventTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventCurrentPhase$Type extends MessageType<EventCurrentPhase> {
    constructor() {
        super("odds_stream.EventCurrentPhase", [
            { no: 200, name: "N", kind: "scalar", jsonName: "N", T: 9 /*ScalarType.STRING*/ },
            { no: 201, name: "S", kind: "scalar", jsonName: "S", T: 9 /*ScalarType.STRING*/ },
            { no: 204, name: "T", kind: "message", jsonName: "T", T: () => EventTime }
        ]);
    }
    create(value?: PartialMessage<EventCurrentPhase>): EventCurrentPhase {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.n = "";
        message.s = "";
        if (value !== undefined)
            reflectionMergePartial<EventCurrentPhase>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventCurrentPhase): EventCurrentPhase {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string N = 200 [json_name = "N"];*/ 200:
                    message.n = reader.string();
                    break;
                case /* string S = 201 [json_name = "S"];*/ 201:
                    message.s = reader.string();
                    break;
                case /* odds_stream.EventTime T = 204 [json_name = "T"];*/ 204:
                    message.t = EventTime.internalBinaryRead(reader, reader.uint32(), options, message.t);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventCurrentPhase, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string N = 200 [json_name = "N"]; */
        if (message.n !== "")
            writer.tag(200, WireType.LengthDelimited).string(message.n);
        /* string S = 201 [json_name = "S"]; */
        if (message.s !== "")
            writer.tag(201, WireType.LengthDelimited).string(message.s);
        /* odds_stream.EventTime T = 204 [json_name = "T"]; */
        if (message.t)
            EventTime.internalBinaryWrite(message.t, writer.tag(204, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.EventCurrentPhase
 */
export const EventCurrentPhase = new EventCurrentPhase$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventCurrentResult$Type extends MessageType<EventCurrentResult> {
    constructor() {
        super("odds_stream.EventCurrentResult", [
            { no: 205, name: "G", kind: "scalar", jsonName: "G", T: 9 /*ScalarType.STRING*/ },
            { no: 206, name: "C", kind: "scalar", jsonName: "C", T: 9 /*ScalarType.STRING*/ },
            { no: 207, name: "RC", kind: "scalar", jsonName: "RC", T: 9 /*ScalarType.STRING*/ },
            { no: 208, name: "YC", kind: "scalar", jsonName: "YC", T: 9 /*ScalarType.STRING*/ },
            { no: 209, name: "P", kind: "scalar", jsonName: "P", T: 9 /*ScalarType.STRING*/ },
            { no: 210, name: "S", kind: "scalar", jsonName: "S", T: 9 /*ScalarType.STRING*/ },
            { no: 267, name: "L", kind: "scalar", jsonName: "L", T: 9 /*ScalarType.STRING*/ },
            { no: 268, name: "S180", kind: "scalar", jsonName: "S180", T: 9 /*ScalarType.STRING*/ },
            { no: 269, name: "FG", kind: "scalar", jsonName: "FG", T: 9 /*ScalarType.STRING*/ },
            { no: 270, name: "PN", kind: "scalar", jsonName: "PN", T: 9 /*ScalarType.STRING*/ },
            { no: 271, name: "TD", kind: "scalar", jsonName: "TD", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EventCurrentResult>): EventCurrentResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.g = "";
        message.c = "";
        message.rC = "";
        message.yC = "";
        message.p = "";
        message.s = "";
        message.l = "";
        message.s180 = "";
        message.fG = "";
        message.pN = "";
        message.tD = "";
        if (value !== undefined)
            reflectionMergePartial<EventCurrentResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventCurrentResult): EventCurrentResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string G = 205 [json_name = "G"];*/ 205:
                    message.g = reader.string();
                    break;
                case /* string C = 206 [json_name = "C"];*/ 206:
                    message.c = reader.string();
                    break;
                case /* string RC = 207 [json_name = "RC"];*/ 207:
                    message.rC = reader.string();
                    break;
                case /* string YC = 208 [json_name = "YC"];*/ 208:
                    message.yC = reader.string();
                    break;
                case /* string P = 209 [json_name = "P"];*/ 209:
                    message.p = reader.string();
                    break;
                case /* string S = 210 [json_name = "S"];*/ 210:
                    message.s = reader.string();
                    break;
                case /* string L = 267 [json_name = "L"];*/ 267:
                    message.l = reader.string();
                    break;
                case /* string S180 = 268 [json_name = "S180"];*/ 268:
                    message.s180 = reader.string();
                    break;
                case /* string FG = 269 [json_name = "FG"];*/ 269:
                    message.fG = reader.string();
                    break;
                case /* string PN = 270 [json_name = "PN"];*/ 270:
                    message.pN = reader.string();
                    break;
                case /* string TD = 271 [json_name = "TD"];*/ 271:
                    message.tD = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventCurrentResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string G = 205 [json_name = "G"]; */
        if (message.g !== "")
            writer.tag(205, WireType.LengthDelimited).string(message.g);
        /* string C = 206 [json_name = "C"]; */
        if (message.c !== "")
            writer.tag(206, WireType.LengthDelimited).string(message.c);
        /* string RC = 207 [json_name = "RC"]; */
        if (message.rC !== "")
            writer.tag(207, WireType.LengthDelimited).string(message.rC);
        /* string YC = 208 [json_name = "YC"]; */
        if (message.yC !== "")
            writer.tag(208, WireType.LengthDelimited).string(message.yC);
        /* string P = 209 [json_name = "P"]; */
        if (message.p !== "")
            writer.tag(209, WireType.LengthDelimited).string(message.p);
        /* string S = 210 [json_name = "S"]; */
        if (message.s !== "")
            writer.tag(210, WireType.LengthDelimited).string(message.s);
        /* string L = 267 [json_name = "L"]; */
        if (message.l !== "")
            writer.tag(267, WireType.LengthDelimited).string(message.l);
        /* string S180 = 268 [json_name = "S180"]; */
        if (message.s180 !== "")
            writer.tag(268, WireType.LengthDelimited).string(message.s180);
        /* string FG = 269 [json_name = "FG"]; */
        if (message.fG !== "")
            writer.tag(269, WireType.LengthDelimited).string(message.fG);
        /* string PN = 270 [json_name = "PN"]; */
        if (message.pN !== "")
            writer.tag(270, WireType.LengthDelimited).string(message.pN);
        /* string TD = 271 [json_name = "TD"]; */
        if (message.tD !== "")
            writer.tag(271, WireType.LengthDelimited).string(message.tD);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.EventCurrentResult
 */
export const EventCurrentResult = new EventCurrentResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventLiveResultHistoryElement$Type extends MessageType<EventLiveResultHistoryElement> {
    constructor() {
        super("odds_stream.EventLiveResultHistoryElement", [
            { no: 211, name: "P", kind: "message", jsonName: "P", T: () => EventCurrentPhase },
            { no: 212, name: "R", kind: "message", jsonName: "R", T: () => EventCurrentResult }
        ]);
    }
    create(value?: PartialMessage<EventLiveResultHistoryElement>): EventLiveResultHistoryElement {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EventLiveResultHistoryElement>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventLiveResultHistoryElement): EventLiveResultHistoryElement {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* odds_stream.EventCurrentPhase P = 211 [json_name = "P"];*/ 211:
                    message.p = EventCurrentPhase.internalBinaryRead(reader, reader.uint32(), options, message.p);
                    break;
                case /* odds_stream.EventCurrentResult R = 212 [json_name = "R"];*/ 212:
                    message.r = EventCurrentResult.internalBinaryRead(reader, reader.uint32(), options, message.r);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventLiveResultHistoryElement, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* odds_stream.EventCurrentPhase P = 211 [json_name = "P"]; */
        if (message.p)
            EventCurrentPhase.internalBinaryWrite(message.p, writer.tag(211, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.EventCurrentResult R = 212 [json_name = "R"]; */
        if (message.r)
            EventCurrentResult.internalBinaryWrite(message.r, writer.tag(212, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.EventLiveResultHistoryElement
 */
export const EventLiveResultHistoryElement = new EventLiveResultHistoryElement$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Error$Type extends MessageType<Error> {
    constructor() {
        super("odds_stream.Error", [
            { no: 5, name: "grpc_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "http_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "message", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Error>): Error {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.grpcCode = 0;
        message.httpCode = 0;
        message.message = [];
        if (value !== undefined)
            reflectionMergePartial<Error>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Error): Error {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 grpc_code */ 5:
                    message.grpcCode = reader.int32();
                    break;
                case /* int32 http_code */ 6:
                    message.httpCode = reader.int32();
                    break;
                case /* repeated string message */ 7:
                    message.message.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Error, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 grpc_code = 5; */
        if (message.grpcCode !== 0)
            writer.tag(5, WireType.Varint).int32(message.grpcCode);
        /* int32 http_code = 6; */
        if (message.httpCode !== 0)
            writer.tag(6, WireType.Varint).int32(message.httpCode);
        /* repeated string message = 7; */
        for (let i = 0; i < message.message.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.message[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.Error
 */
export const Error = new Error$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventResult$Type extends MessageType<EventResult> {
    constructor() {
        super("odds_stream.EventResult", [
            { no: 213, name: "current_phase", kind: "message", T: () => EventCurrentPhase },
            { no: 214, name: "current_result", kind: "message", T: () => EventCurrentResult },
            { no: 215, name: "live_result_history", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventLiveResultHistoryElement },
            { no: 216, name: "live_result_stats", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 217, name: "prematch_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 218, name: "live_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 219, name: "confirmed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<EventResult>): EventResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.liveResultHistory = [];
        message.liveResultStats = "";
        message.prematchResult = "";
        message.liveResult = "";
        message.confirmed = false;
        if (value !== undefined)
            reflectionMergePartial<EventResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventResult): EventResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* odds_stream.EventCurrentPhase current_phase */ 213:
                    message.currentPhase = EventCurrentPhase.internalBinaryRead(reader, reader.uint32(), options, message.currentPhase);
                    break;
                case /* odds_stream.EventCurrentResult current_result */ 214:
                    message.currentResult = EventCurrentResult.internalBinaryRead(reader, reader.uint32(), options, message.currentResult);
                    break;
                case /* repeated odds_stream.EventLiveResultHistoryElement live_result_history */ 215:
                    message.liveResultHistory.push(EventLiveResultHistoryElement.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string live_result_stats */ 216:
                    message.liveResultStats = reader.string();
                    break;
                case /* string prematch_result */ 217:
                    message.prematchResult = reader.string();
                    break;
                case /* string live_result */ 218:
                    message.liveResult = reader.string();
                    break;
                case /* bool confirmed */ 219:
                    message.confirmed = reader.bool();
                    break;
                case /* odds_stream.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* odds_stream.EventCurrentPhase current_phase = 213; */
        if (message.currentPhase)
            EventCurrentPhase.internalBinaryWrite(message.currentPhase, writer.tag(213, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.EventCurrentResult current_result = 214; */
        if (message.currentResult)
            EventCurrentResult.internalBinaryWrite(message.currentResult, writer.tag(214, WireType.LengthDelimited).fork(), options).join();
        /* repeated odds_stream.EventLiveResultHistoryElement live_result_history = 215; */
        for (let i = 0; i < message.liveResultHistory.length; i++)
            EventLiveResultHistoryElement.internalBinaryWrite(message.liveResultHistory[i], writer.tag(215, WireType.LengthDelimited).fork(), options).join();
        /* string live_result_stats = 216; */
        if (message.liveResultStats !== "")
            writer.tag(216, WireType.LengthDelimited).string(message.liveResultStats);
        /* string prematch_result = 217; */
        if (message.prematchResult !== "")
            writer.tag(217, WireType.LengthDelimited).string(message.prematchResult);
        /* string live_result = 218; */
        if (message.liveResult !== "")
            writer.tag(218, WireType.LengthDelimited).string(message.liveResult);
        /* bool confirmed = 219; */
        if (message.confirmed !== false)
            writer.tag(219, WireType.Varint).bool(message.confirmed);
        /* odds_stream.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.EventResult
 */
export const EventResult = new EventResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventStream$Type extends MessageType<EventStream> {
    constructor() {
        super("odds_stream.EventStream", [
            { no: 1, name: "live_events", kind: "message", T: () => WebStreamEvents },
            { no: 2, name: "upcoming_events", kind: "message", T: () => WebStreamEvents },
            { no: 3, name: "outright_events", kind: "message", T: () => WebStreamEvents },
            { no: 4, name: "player_events", kind: "message", T: () => WebStreamEvents },
            { no: 5, name: "group_events", kind: "message", T: () => WebStreamEvents }
        ]);
    }
    create(value?: PartialMessage<EventStream>): EventStream {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EventStream>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventStream): EventStream {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* odds_stream.WebStreamEvents live_events */ 1:
                    message.liveEvents = WebStreamEvents.internalBinaryRead(reader, reader.uint32(), options, message.liveEvents);
                    break;
                case /* odds_stream.WebStreamEvents upcoming_events */ 2:
                    message.upcomingEvents = WebStreamEvents.internalBinaryRead(reader, reader.uint32(), options, message.upcomingEvents);
                    break;
                case /* odds_stream.WebStreamEvents outright_events */ 3:
                    message.outrightEvents = WebStreamEvents.internalBinaryRead(reader, reader.uint32(), options, message.outrightEvents);
                    break;
                case /* odds_stream.WebStreamEvents player_events */ 4:
                    message.playerEvents = WebStreamEvents.internalBinaryRead(reader, reader.uint32(), options, message.playerEvents);
                    break;
                case /* odds_stream.WebStreamEvents group_events */ 5:
                    message.groupEvents = WebStreamEvents.internalBinaryRead(reader, reader.uint32(), options, message.groupEvents);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventStream, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* odds_stream.WebStreamEvents live_events = 1; */
        if (message.liveEvents)
            WebStreamEvents.internalBinaryWrite(message.liveEvents, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.WebStreamEvents upcoming_events = 2; */
        if (message.upcomingEvents)
            WebStreamEvents.internalBinaryWrite(message.upcomingEvents, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.WebStreamEvents outright_events = 3; */
        if (message.outrightEvents)
            WebStreamEvents.internalBinaryWrite(message.outrightEvents, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.WebStreamEvents player_events = 4; */
        if (message.playerEvents)
            WebStreamEvents.internalBinaryWrite(message.playerEvents, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.WebStreamEvents group_events = 5; */
        if (message.groupEvents)
            WebStreamEvents.internalBinaryWrite(message.groupEvents, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.EventStream
 */
export const EventStream = new EventStream$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebStreamEvents$Type extends MessageType<WebStreamEvents> {
    constructor() {
        super("odds_stream.WebStreamEvents", [
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WebStreamEvent }
        ]);
    }
    create(value?: PartialMessage<WebStreamEvents>): WebStreamEvents {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<WebStreamEvents>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebStreamEvents): WebStreamEvents {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds_stream.WebStreamEvent events */ 17:
                    message.events.push(WebStreamEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebStreamEvents, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds_stream.WebStreamEvent events = 17; */
        for (let i = 0; i < message.events.length; i++)
            WebStreamEvent.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.WebStreamEvents
 */
export const WebStreamEvents = new WebStreamEvents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebStreamEvent$Type extends MessageType<WebStreamEvent> {
    constructor() {
        super("odds_stream.WebStreamEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WebStreamEventOdd },
            { no: 7, name: "start", kind: "message", T: () => Timestamp },
            { no: 8, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "result", kind: "message", T: () => EventResult },
            { no: 10, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "outright_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "mon", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "number_of_odds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "exefeed_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "profile", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "betradar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "betradar_stream_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WebStreamEvent>): WebStreamEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.competitionId = 0;
        message.locationId = 0;
        message.sportId = 0;
        message.odds = [];
        message.isDisabled = false;
        message.landbaseCode = "";
        message.type = "";
        message.outrightType = "";
        message.mon = 0;
        message.numberOfOdds = 0;
        message.exefeedId = 0;
        message.profile = "";
        message.betradarId = "";
        message.betradarStreamId = "";
        if (value !== undefined)
            reflectionMergePartial<WebStreamEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebStreamEvent): WebStreamEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 competition_id */ 3:
                    message.competitionId = reader.int32();
                    break;
                case /* int32 location_id */ 4:
                    message.locationId = reader.int32();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* repeated odds_stream.WebStreamEventOdd odds */ 6:
                    message.odds.push(WebStreamEventOdd.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp start */ 7:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* bool is_disabled */ 8:
                    message.isDisabled = reader.bool();
                    break;
                case /* odds_stream.EventResult result */ 9:
                    message.result = EventResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                case /* string landbase_code */ 10:
                    message.landbaseCode = reader.string();
                    break;
                case /* string type */ 11:
                    message.type = reader.string();
                    break;
                case /* string outright_type */ 12:
                    message.outrightType = reader.string();
                    break;
                case /* int32 mon */ 13:
                    message.mon = reader.int32();
                    break;
                case /* int32 number_of_odds */ 14:
                    message.numberOfOdds = reader.int32();
                    break;
                case /* int32 exefeed_id */ 15:
                    message.exefeedId = reader.int32();
                    break;
                case /* string profile */ 16:
                    message.profile = reader.string();
                    break;
                case /* string betradar_id */ 17:
                    message.betradarId = reader.string();
                    break;
                case /* string betradar_stream_id */ 18:
                    message.betradarStreamId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebStreamEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 competition_id = 3; */
        if (message.competitionId !== 0)
            writer.tag(3, WireType.Varint).int32(message.competitionId);
        /* int32 location_id = 4; */
        if (message.locationId !== 0)
            writer.tag(4, WireType.Varint).int32(message.locationId);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* repeated odds_stream.WebStreamEventOdd odds = 6; */
        for (let i = 0; i < message.odds.length; i++)
            WebStreamEventOdd.internalBinaryWrite(message.odds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp start = 7; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bool is_disabled = 8; */
        if (message.isDisabled !== false)
            writer.tag(8, WireType.Varint).bool(message.isDisabled);
        /* odds_stream.EventResult result = 9; */
        if (message.result)
            EventResult.internalBinaryWrite(message.result, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string landbase_code = 10; */
        if (message.landbaseCode !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.landbaseCode);
        /* string type = 11; */
        if (message.type !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.type);
        /* string outright_type = 12; */
        if (message.outrightType !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.outrightType);
        /* int32 mon = 13; */
        if (message.mon !== 0)
            writer.tag(13, WireType.Varint).int32(message.mon);
        /* int32 number_of_odds = 14; */
        if (message.numberOfOdds !== 0)
            writer.tag(14, WireType.Varint).int32(message.numberOfOdds);
        /* int32 exefeed_id = 15; */
        if (message.exefeedId !== 0)
            writer.tag(15, WireType.Varint).int32(message.exefeedId);
        /* string profile = 16; */
        if (message.profile !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.profile);
        /* string betradar_id = 17; */
        if (message.betradarId !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.betradarId);
        /* string betradar_stream_id = 18; */
        if (message.betradarStreamId !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.betradarStreamId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.WebStreamEvent
 */
export const WebStreamEvent = new WebStreamEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebStreamEventOdd$Type extends MessageType<WebStreamEventOdd> {
    constructor() {
        super("odds_stream.WebStreamEventOdd", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "frame_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "current_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "live_player", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WebStreamEventOdd>): WebStreamEventOdd {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.value = 0;
        message.outcomeId = 0;
        message.limit = 0;
        message.frameNumber = 0;
        message.currentResult = "";
        message.isDisabled = false;
        message.status = 0;
        message.livePlayer = "";
        if (value !== undefined)
            reflectionMergePartial<WebStreamEventOdd>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebStreamEventOdd): WebStreamEventOdd {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double value */ 2:
                    message.value = reader.double();
                    break;
                case /* int32 outcome_id */ 3:
                    message.outcomeId = reader.int32();
                    break;
                case /* double limit */ 4:
                    message.limit = reader.double();
                    break;
                case /* int32 frame_number */ 5:
                    message.frameNumber = reader.int32();
                    break;
                case /* string current_result */ 6:
                    message.currentResult = reader.string();
                    break;
                case /* bool is_disabled */ 7:
                    message.isDisabled = reader.bool();
                    break;
                case /* int32 status */ 8:
                    message.status = reader.int32();
                    break;
                case /* string live_player */ 9:
                    message.livePlayer = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebStreamEventOdd, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double value = 2; */
        if (message.value !== 0)
            writer.tag(2, WireType.Bit64).double(message.value);
        /* int32 outcome_id = 3; */
        if (message.outcomeId !== 0)
            writer.tag(3, WireType.Varint).int32(message.outcomeId);
        /* double limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Bit64).double(message.limit);
        /* int32 frame_number = 5; */
        if (message.frameNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.frameNumber);
        /* string current_result = 6; */
        if (message.currentResult !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.currentResult);
        /* bool is_disabled = 7; */
        if (message.isDisabled !== false)
            writer.tag(7, WireType.Varint).bool(message.isDisabled);
        /* int32 status = 8; */
        if (message.status !== 0)
            writer.tag(8, WireType.Varint).int32(message.status);
        /* string live_player = 9; */
        if (message.livePlayer !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.livePlayer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.WebStreamEventOdd
 */
export const WebStreamEventOdd = new WebStreamEventOdd$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventStreamInt$Type extends MessageType<EventStreamInt> {
    constructor() {
        super("odds_stream.EventStreamInt", [
            { no: 1, name: "live_events", kind: "message", T: () => WebStreamEventsInt },
            { no: 2, name: "upcoming_events", kind: "message", T: () => WebStreamEventsInt },
            { no: 3, name: "outright_events", kind: "message", T: () => WebStreamEventsInt },
            { no: 4, name: "player_events", kind: "message", T: () => WebStreamEventsInt },
            { no: 5, name: "group_events", kind: "message", T: () => WebStreamEventsInt }
        ]);
    }
    create(value?: PartialMessage<EventStreamInt>): EventStreamInt {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EventStreamInt>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventStreamInt): EventStreamInt {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* odds_stream.WebStreamEventsInt live_events */ 1:
                    message.liveEvents = WebStreamEventsInt.internalBinaryRead(reader, reader.uint32(), options, message.liveEvents);
                    break;
                case /* odds_stream.WebStreamEventsInt upcoming_events */ 2:
                    message.upcomingEvents = WebStreamEventsInt.internalBinaryRead(reader, reader.uint32(), options, message.upcomingEvents);
                    break;
                case /* odds_stream.WebStreamEventsInt outright_events */ 3:
                    message.outrightEvents = WebStreamEventsInt.internalBinaryRead(reader, reader.uint32(), options, message.outrightEvents);
                    break;
                case /* odds_stream.WebStreamEventsInt player_events */ 4:
                    message.playerEvents = WebStreamEventsInt.internalBinaryRead(reader, reader.uint32(), options, message.playerEvents);
                    break;
                case /* odds_stream.WebStreamEventsInt group_events */ 5:
                    message.groupEvents = WebStreamEventsInt.internalBinaryRead(reader, reader.uint32(), options, message.groupEvents);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventStreamInt, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* odds_stream.WebStreamEventsInt live_events = 1; */
        if (message.liveEvents)
            WebStreamEventsInt.internalBinaryWrite(message.liveEvents, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.WebStreamEventsInt upcoming_events = 2; */
        if (message.upcomingEvents)
            WebStreamEventsInt.internalBinaryWrite(message.upcomingEvents, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.WebStreamEventsInt outright_events = 3; */
        if (message.outrightEvents)
            WebStreamEventsInt.internalBinaryWrite(message.outrightEvents, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.WebStreamEventsInt player_events = 4; */
        if (message.playerEvents)
            WebStreamEventsInt.internalBinaryWrite(message.playerEvents, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.WebStreamEventsInt group_events = 5; */
        if (message.groupEvents)
            WebStreamEventsInt.internalBinaryWrite(message.groupEvents, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.EventStreamInt
 */
export const EventStreamInt = new EventStreamInt$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebStreamEventsInt$Type extends MessageType<WebStreamEventsInt> {
    constructor() {
        super("odds_stream.WebStreamEventsInt", [
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WebStreamEventInt }
        ]);
    }
    create(value?: PartialMessage<WebStreamEventsInt>): WebStreamEventsInt {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<WebStreamEventsInt>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebStreamEventsInt): WebStreamEventsInt {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds_stream.WebStreamEventInt events */ 17:
                    message.events.push(WebStreamEventInt.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebStreamEventsInt, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds_stream.WebStreamEventInt events = 17; */
        for (let i = 0; i < message.events.length; i++)
            WebStreamEventInt.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.WebStreamEventsInt
 */
export const WebStreamEventsInt = new WebStreamEventsInt$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebStreamEventInt$Type extends MessageType<WebStreamEventInt> {
    constructor() {
        super("odds_stream.WebStreamEventInt", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WebStreamEventOddInt },
            { no: 7, name: "start", kind: "message", T: () => Timestamp },
            { no: 8, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "result", kind: "message", T: () => EventResult },
            { no: 10, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "outright_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "mon", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "number_of_odds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "exefeed_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "profile", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "betradar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "betradar_stream_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WebStreamEventInt>): WebStreamEventInt {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.competitionId = 0;
        message.locationId = 0;
        message.sportId = 0;
        message.odds = [];
        message.isDisabled = false;
        message.landbaseCode = "";
        message.type = "";
        message.outrightType = "";
        message.mon = 0;
        message.numberOfOdds = 0;
        message.exefeedId = 0;
        message.profile = "";
        message.betradarId = "";
        message.betradarStreamId = "";
        if (value !== undefined)
            reflectionMergePartial<WebStreamEventInt>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebStreamEventInt): WebStreamEventInt {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 competition_id */ 3:
                    message.competitionId = reader.int32();
                    break;
                case /* int32 location_id */ 4:
                    message.locationId = reader.int32();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* repeated odds_stream.WebStreamEventOddInt odds */ 6:
                    message.odds.push(WebStreamEventOddInt.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp start */ 7:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* bool is_disabled */ 8:
                    message.isDisabled = reader.bool();
                    break;
                case /* odds_stream.EventResult result */ 9:
                    message.result = EventResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                case /* string landbase_code */ 10:
                    message.landbaseCode = reader.string();
                    break;
                case /* string type */ 11:
                    message.type = reader.string();
                    break;
                case /* string outright_type */ 12:
                    message.outrightType = reader.string();
                    break;
                case /* int32 mon */ 13:
                    message.mon = reader.int32();
                    break;
                case /* int32 number_of_odds */ 14:
                    message.numberOfOdds = reader.int32();
                    break;
                case /* int32 exefeed_id */ 15:
                    message.exefeedId = reader.int32();
                    break;
                case /* string profile */ 16:
                    message.profile = reader.string();
                    break;
                case /* string betradar_id */ 17:
                    message.betradarId = reader.string();
                    break;
                case /* string betradar_stream_id */ 18:
                    message.betradarStreamId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebStreamEventInt, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 competition_id = 3; */
        if (message.competitionId !== 0)
            writer.tag(3, WireType.Varint).int32(message.competitionId);
        /* int32 location_id = 4; */
        if (message.locationId !== 0)
            writer.tag(4, WireType.Varint).int32(message.locationId);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* repeated odds_stream.WebStreamEventOddInt odds = 6; */
        for (let i = 0; i < message.odds.length; i++)
            WebStreamEventOddInt.internalBinaryWrite(message.odds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp start = 7; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bool is_disabled = 8; */
        if (message.isDisabled !== false)
            writer.tag(8, WireType.Varint).bool(message.isDisabled);
        /* odds_stream.EventResult result = 9; */
        if (message.result)
            EventResult.internalBinaryWrite(message.result, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string landbase_code = 10; */
        if (message.landbaseCode !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.landbaseCode);
        /* string type = 11; */
        if (message.type !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.type);
        /* string outright_type = 12; */
        if (message.outrightType !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.outrightType);
        /* int32 mon = 13; */
        if (message.mon !== 0)
            writer.tag(13, WireType.Varint).int32(message.mon);
        /* int32 number_of_odds = 14; */
        if (message.numberOfOdds !== 0)
            writer.tag(14, WireType.Varint).int32(message.numberOfOdds);
        /* int32 exefeed_id = 15; */
        if (message.exefeedId !== 0)
            writer.tag(15, WireType.Varint).int32(message.exefeedId);
        /* string profile = 16; */
        if (message.profile !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.profile);
        /* string betradar_id = 17; */
        if (message.betradarId !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.betradarId);
        /* string betradar_stream_id = 18; */
        if (message.betradarStreamId !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.betradarStreamId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.WebStreamEventInt
 */
export const WebStreamEventInt = new WebStreamEventInt$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebStreamEventOddInt$Type extends MessageType<WebStreamEventOddInt> {
    constructor() {
        super("odds_stream.WebStreamEventOddInt", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "value", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "frame_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "current_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "live_player", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WebStreamEventOddInt>): WebStreamEventOddInt {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.value = 0;
        message.outcomeId = 0;
        message.limit = 0;
        message.frameNumber = 0;
        message.currentResult = "";
        message.isDisabled = false;
        message.status = 0;
        message.livePlayer = "";
        if (value !== undefined)
            reflectionMergePartial<WebStreamEventOddInt>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebStreamEventOddInt): WebStreamEventOddInt {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 value */ 2:
                    message.value = reader.int32();
                    break;
                case /* int32 outcome_id */ 3:
                    message.outcomeId = reader.int32();
                    break;
                case /* int32 limit */ 4:
                    message.limit = reader.int32();
                    break;
                case /* int32 frame_number */ 5:
                    message.frameNumber = reader.int32();
                    break;
                case /* string current_result */ 6:
                    message.currentResult = reader.string();
                    break;
                case /* bool is_disabled */ 7:
                    message.isDisabled = reader.bool();
                    break;
                case /* int32 status */ 8:
                    message.status = reader.int32();
                    break;
                case /* string live_player */ 9:
                    message.livePlayer = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebStreamEventOddInt, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 value = 2; */
        if (message.value !== 0)
            writer.tag(2, WireType.Varint).int32(message.value);
        /* int32 outcome_id = 3; */
        if (message.outcomeId !== 0)
            writer.tag(3, WireType.Varint).int32(message.outcomeId);
        /* int32 limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Varint).int32(message.limit);
        /* int32 frame_number = 5; */
        if (message.frameNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.frameNumber);
        /* string current_result = 6; */
        if (message.currentResult !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.currentResult);
        /* bool is_disabled = 7; */
        if (message.isDisabled !== false)
            writer.tag(7, WireType.Varint).bool(message.isDisabled);
        /* int32 status = 8; */
        if (message.status !== 0)
            writer.tag(8, WireType.Varint).int32(message.status);
        /* string live_player = 9; */
        if (message.livePlayer !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.livePlayer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.WebStreamEventOddInt
 */
export const WebStreamEventOddInt = new WebStreamEventOddInt$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamEvent$Type extends MessageType<StreamEvent> {
    constructor() {
        super("odds_stream.StreamEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "note", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StreamEventOdd },
            { no: 8, name: "start", kind: "message", T: () => Timestamp },
            { no: 9, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "is_suspicious", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "is_single_approval", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "profile", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "current_phase", kind: "message", T: () => EventCurrentPhase },
            { no: 14, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "outright_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "mon", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "result", kind: "message", T: () => EventResult }
        ]);
    }
    create(value?: PartialMessage<StreamEvent>): StreamEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.note = "";
        message.competitionId = 0;
        message.locationId = 0;
        message.sportId = 0;
        message.odds = [];
        message.isDisabled = false;
        message.isSuspicious = false;
        message.isSingleApproval = false;
        message.profile = "";
        message.landbaseCode = "";
        message.type = "";
        message.outrightType = "";
        message.mon = 0;
        if (value !== undefined)
            reflectionMergePartial<StreamEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StreamEvent): StreamEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string note */ 3:
                    message.note = reader.string();
                    break;
                case /* int32 competition_id */ 4:
                    message.competitionId = reader.int32();
                    break;
                case /* int32 location_id */ 5:
                    message.locationId = reader.int32();
                    break;
                case /* int32 sport_id */ 6:
                    message.sportId = reader.int32();
                    break;
                case /* repeated odds_stream.StreamEventOdd odds */ 7:
                    message.odds.push(StreamEventOdd.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp start */ 8:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* bool is_disabled */ 9:
                    message.isDisabled = reader.bool();
                    break;
                case /* bool is_suspicious */ 10:
                    message.isSuspicious = reader.bool();
                    break;
                case /* bool is_single_approval */ 11:
                    message.isSingleApproval = reader.bool();
                    break;
                case /* string profile */ 12:
                    message.profile = reader.string();
                    break;
                case /* odds_stream.EventCurrentPhase current_phase */ 13:
                    message.currentPhase = EventCurrentPhase.internalBinaryRead(reader, reader.uint32(), options, message.currentPhase);
                    break;
                case /* string landbase_code */ 14:
                    message.landbaseCode = reader.string();
                    break;
                case /* string type */ 15:
                    message.type = reader.string();
                    break;
                case /* string outright_type */ 16:
                    message.outrightType = reader.string();
                    break;
                case /* int32 mon */ 17:
                    message.mon = reader.int32();
                    break;
                case /* odds_stream.EventResult result */ 18:
                    message.result = EventResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StreamEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string note = 3; */
        if (message.note !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.note);
        /* int32 competition_id = 4; */
        if (message.competitionId !== 0)
            writer.tag(4, WireType.Varint).int32(message.competitionId);
        /* int32 location_id = 5; */
        if (message.locationId !== 0)
            writer.tag(5, WireType.Varint).int32(message.locationId);
        /* int32 sport_id = 6; */
        if (message.sportId !== 0)
            writer.tag(6, WireType.Varint).int32(message.sportId);
        /* repeated odds_stream.StreamEventOdd odds = 7; */
        for (let i = 0; i < message.odds.length; i++)
            StreamEventOdd.internalBinaryWrite(message.odds[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp start = 8; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* bool is_disabled = 9; */
        if (message.isDisabled !== false)
            writer.tag(9, WireType.Varint).bool(message.isDisabled);
        /* bool is_suspicious = 10; */
        if (message.isSuspicious !== false)
            writer.tag(10, WireType.Varint).bool(message.isSuspicious);
        /* bool is_single_approval = 11; */
        if (message.isSingleApproval !== false)
            writer.tag(11, WireType.Varint).bool(message.isSingleApproval);
        /* string profile = 12; */
        if (message.profile !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.profile);
        /* odds_stream.EventCurrentPhase current_phase = 13; */
        if (message.currentPhase)
            EventCurrentPhase.internalBinaryWrite(message.currentPhase, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* string landbase_code = 14; */
        if (message.landbaseCode !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.landbaseCode);
        /* string type = 15; */
        if (message.type !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.type);
        /* string outright_type = 16; */
        if (message.outrightType !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.outrightType);
        /* int32 mon = 17; */
        if (message.mon !== 0)
            writer.tag(17, WireType.Varint).int32(message.mon);
        /* odds_stream.EventResult result = 18; */
        if (message.result)
            EventResult.internalBinaryWrite(message.result, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.StreamEvent
 */
export const StreamEvent = new StreamEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamEventOdd$Type extends MessageType<StreamEventOdd> {
    constructor() {
        super("odds_stream.StreamEventOdd", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 11, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 122, name: "limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 238, name: "frame_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 239, name: "current_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 136, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "live_player", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StreamEventOdd>): StreamEventOdd {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.value = 0;
        message.outcomeId = 0;
        message.limit = 0;
        message.frameNumber = 0;
        message.currentResult = "";
        message.isDisabled = false;
        message.livePlayer = "";
        if (value !== undefined)
            reflectionMergePartial<StreamEventOdd>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StreamEventOdd): StreamEventOdd {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double value */ 8:
                    message.value = reader.double();
                    break;
                case /* int32 outcome_id */ 11:
                    message.outcomeId = reader.int32();
                    break;
                case /* double limit */ 122:
                    message.limit = reader.double();
                    break;
                case /* int32 frame_number */ 238:
                    message.frameNumber = reader.int32();
                    break;
                case /* string current_result */ 239:
                    message.currentResult = reader.string();
                    break;
                case /* bool is_disabled */ 136:
                    message.isDisabled = reader.bool();
                    break;
                case /* string live_player */ 9:
                    message.livePlayer = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StreamEventOdd, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double value = 8; */
        if (message.value !== 0)
            writer.tag(8, WireType.Bit64).double(message.value);
        /* int32 outcome_id = 11; */
        if (message.outcomeId !== 0)
            writer.tag(11, WireType.Varint).int32(message.outcomeId);
        /* double limit = 122; */
        if (message.limit !== 0)
            writer.tag(122, WireType.Bit64).double(message.limit);
        /* int32 frame_number = 238; */
        if (message.frameNumber !== 0)
            writer.tag(238, WireType.Varint).int32(message.frameNumber);
        /* string current_result = 239; */
        if (message.currentResult !== "")
            writer.tag(239, WireType.LengthDelimited).string(message.currentResult);
        /* bool is_disabled = 136; */
        if (message.isDisabled !== false)
            writer.tag(136, WireType.Varint).bool(message.isDisabled);
        /* string live_player = 9; */
        if (message.livePlayer !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.livePlayer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.StreamEventOdd
 */
export const StreamEventOdd = new StreamEventOdd$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LiveEvents$Type extends MessageType<LiveEvents> {
    constructor() {
        super("odds_stream.LiveEvents", [
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StreamEvent },
            { no: 193, name: "disable_all", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 194, name: "disabled_sports", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<LiveEvents>): LiveEvents {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        message.disableAll = false;
        message.disabledSports = [];
        if (value !== undefined)
            reflectionMergePartial<LiveEvents>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LiveEvents): LiveEvents {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds_stream.StreamEvent events */ 17:
                    message.events.push(StreamEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool disable_all */ 193:
                    message.disableAll = reader.bool();
                    break;
                case /* repeated int32 disabled_sports */ 194:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.disabledSports.push(reader.int32());
                    else
                        message.disabledSports.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LiveEvents, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds_stream.StreamEvent events = 17; */
        for (let i = 0; i < message.events.length; i++)
            StreamEvent.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* bool disable_all = 193; */
        if (message.disableAll !== false)
            writer.tag(193, WireType.Varint).bool(message.disableAll);
        /* repeated int32 disabled_sports = 194; */
        if (message.disabledSports.length) {
            writer.tag(194, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.disabledSports.length; i++)
                writer.int32(message.disabledSports[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.LiveEvents
 */
export const LiveEvents = new LiveEvents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpcomingEvents$Type extends MessageType<UpcomingEvents> {
    constructor() {
        super("odds_stream.UpcomingEvents", [
            { no: 17, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StreamEvent },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<UpcomingEvents>): UpcomingEvents {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<UpcomingEvents>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpcomingEvents): UpcomingEvents {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated odds_stream.StreamEvent events */ 17:
                    message.events.push(StreamEvent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* odds_stream.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpcomingEvents, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated odds_stream.StreamEvent events = 17; */
        for (let i = 0; i < message.events.length; i++)
            StreamEvent.internalBinaryWrite(message.events[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.UpcomingEvents
 */
export const UpcomingEvents = new UpcomingEvents$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsResponse$Type extends MessageType<GetEventsResponse> {
    constructor() {
        super("odds_stream.GetEventsResponse", [
            { no: 36, name: "live_events", kind: "message", T: () => LiveEvents },
            { no: 37, name: "upcoming_events", kind: "message", T: () => UpcomingEvents },
            { no: 247, name: "outright_events", kind: "message", T: () => UpcomingEvents },
            { no: 248, name: "player_events", kind: "message", T: () => UpcomingEvents },
            { no: 249, name: "group_events", kind: "message", T: () => UpcomingEvents }
        ]);
    }
    create(value?: PartialMessage<GetEventsResponse>): GetEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsResponse): GetEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* odds_stream.LiveEvents live_events */ 36:
                    message.liveEvents = LiveEvents.internalBinaryRead(reader, reader.uint32(), options, message.liveEvents);
                    break;
                case /* odds_stream.UpcomingEvents upcoming_events */ 37:
                    message.upcomingEvents = UpcomingEvents.internalBinaryRead(reader, reader.uint32(), options, message.upcomingEvents);
                    break;
                case /* odds_stream.UpcomingEvents outright_events */ 247:
                    message.outrightEvents = UpcomingEvents.internalBinaryRead(reader, reader.uint32(), options, message.outrightEvents);
                    break;
                case /* odds_stream.UpcomingEvents player_events */ 248:
                    message.playerEvents = UpcomingEvents.internalBinaryRead(reader, reader.uint32(), options, message.playerEvents);
                    break;
                case /* odds_stream.UpcomingEvents group_events */ 249:
                    message.groupEvents = UpcomingEvents.internalBinaryRead(reader, reader.uint32(), options, message.groupEvents);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* odds_stream.LiveEvents live_events = 36; */
        if (message.liveEvents)
            LiveEvents.internalBinaryWrite(message.liveEvents, writer.tag(36, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.UpcomingEvents upcoming_events = 37; */
        if (message.upcomingEvents)
            UpcomingEvents.internalBinaryWrite(message.upcomingEvents, writer.tag(37, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.UpcomingEvents outright_events = 247; */
        if (message.outrightEvents)
            UpcomingEvents.internalBinaryWrite(message.outrightEvents, writer.tag(247, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.UpcomingEvents player_events = 248; */
        if (message.playerEvents)
            UpcomingEvents.internalBinaryWrite(message.playerEvents, writer.tag(248, WireType.LengthDelimited).fork(), options).join();
        /* odds_stream.UpcomingEvents group_events = 249; */
        if (message.groupEvents)
            UpcomingEvents.internalBinaryWrite(message.groupEvents, writer.tag(249, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message odds_stream.GetEventsResponse
 */
export const GetEventsResponse = new GetEventsResponse$Type();
/**
 * @generated ServiceType for protobuf service odds_stream.OddsStreamService
 */
export const OddsStreamService = new ServiceType("odds_stream.OddsStreamService", [
    { name: "WebEventsStream", serverStreaming: true, options: {}, I: Empty, O: EventStream },
    { name: "WebEventsStreamOrdered", serverStreaming: true, options: {}, I: Empty, O: EventStreamInt },
    { name: "WebEventsStreamTop", serverStreaming: true, options: {}, I: Empty, O: EventStreamInt },
    { name: "WebEventsStreamRest", serverStreaming: true, options: {}, I: Empty, O: EventStreamInt },
    { name: "AppEventsStreamTop", serverStreaming: true, options: {}, I: Empty, O: EventStreamInt },
    { name: "AppEventsStreamRest", serverStreaming: true, options: {}, I: Empty, O: EventStreamInt },
    { name: "UnaryStreamGetEvents", serverStreaming: true, options: {}, I: Empty, O: GetEventsResponse },
    { name: "LiveEventsStream", serverStreaming: true, options: {}, I: Empty, O: GetEventsResponse },
    { name: "WebEventsLiveStream", serverStreaming: true, options: {}, I: Empty, O: EventStream },
    { name: "WebEventsPrematchStream", serverStreaming: true, options: {}, I: Empty, O: EventStream }
]);
