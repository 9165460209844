// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/cache_service/cache.proto" (package "web_odds", syntax proto3)
// tslint:disable
import { Empty } from "../../google/protobuf/empty_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../google/protobuf/timestamp_pb";
/**
 * @generated from protobuf message web_odds.CounterRequest
 */
export interface CounterRequest {
    /**
     * @generated from protobuf field: string offset = 1;
     */
    offset: string;
}
/**
 * @generated from protobuf message web_odds.CounterMap
 */
export interface CounterMap {
    /**
     * @generated from protobuf field: map<string, web_odds.CounterSportMap> sports = 1;
     */
    sports: {
        [key: string]: CounterSportMap;
    };
}
/**
 * @generated from protobuf message web_odds.CounterSportMap
 */
export interface CounterSportMap {
    /**
     * @generated from protobuf field: map<string, web_odds.CounterLocationsMap> locations = 1;
     */
    locations: {
        [key: string]: CounterLocationsMap;
    };
    /**
     * @generated from protobuf field: map<string, web_odds.CounterTypeMap> date = 2;
     */
    date: {
        [key: string]: CounterTypeMap;
    };
}
/**
 * @generated from protobuf message web_odds.CounterLocationsMap
 */
export interface CounterLocationsMap {
    /**
     * @generated from protobuf field: map<string, web_odds.CounterTypeMap> competitions = 1;
     */
    competitions: {
        [key: string]: CounterTypeMap;
    };
}
/**
 * @generated from protobuf message web_odds.CounterTypeMap
 */
export interface CounterTypeMap {
    /**
     * @generated from protobuf field: web_odds.CounterTimeMap live = 1;
     */
    live?: CounterTimeMap;
    /**
     * @generated from protobuf field: web_odds.CounterTimeMap prematch = 2;
     */
    prematch?: CounterTimeMap;
    /**
     * @generated from protobuf field: web_odds.CounterTimeMap O = 3 [json_name = "O"];
     */
    o?: CounterTimeMap;
    /**
     * @generated from protobuf field: web_odds.CounterTimeMap P = 4 [json_name = "P"];
     */
    p?: CounterTimeMap;
    /**
     * @generated from protobuf field: web_odds.CounterTimeMap G = 5 [json_name = "G"];
     */
    g?: CounterTimeMap;
}
/**
 * @generated from protobuf message web_odds.CounterTimeMap
 */
export interface CounterTimeMap {
    /**
     * @generated from protobuf field: int32 all = 1;
     */
    all: number;
    /**
     * @generated from protobuf field: int32 h1 = 2;
     */
    h1: number;
    /**
     * @generated from protobuf field: int32 h3 = 3;
     */
    h3: number;
    /**
     * @generated from protobuf field: int32 d1 = 4;
     */
    d1: number;
    /**
     * @generated from protobuf field: int32 d3 = 5;
     */
    d3: number;
}
/**
 * Initial data
 *
 * @generated from protobuf message web_odds.InitialDataRequest
 */
export interface InitialDataRequest {
    /**
     * @generated from protobuf field: bool only_markets = 1;
     */
    onlyMarkets: boolean;
}
/**
 * @generated from protobuf message web_odds.InitialDataResponse
 */
export interface InitialDataResponse {
    /**
     * @generated from protobuf field: repeated web_odds.InitialMarketGroup market_groups = 1;
     */
    marketGroups: InitialMarketGroup[];
    /**
     * @generated from protobuf field: repeated web_odds.InitialSport sports = 2;
     */
    sports: InitialSport[];
}
/**
 * @generated from protobuf message web_odds.InitialSport
 */
export interface InitialSport {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated web_odds.InitialLocation locations = 3;
     */
    locations: InitialLocation[];
    /**
     * @generated from protobuf field: web_odds.InitialBasicOffer basic_offer_live = 4;
     */
    basicOfferLive?: InitialBasicOffer;
    /**
     * @generated from protobuf field: web_odds.InitialBasicOffer basic_offer_prematch = 5;
     */
    basicOfferPrematch?: InitialBasicOffer;
}
/**
 * @generated from protobuf message web_odds.InitialLocation
 */
export interface InitialLocation {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated web_odds.InitialCompetition competitions = 3;
     */
    competitions: InitialCompetition[];
    /**
     * @generated from protobuf field: string flag_code = 444;
     */
    flagCode: string;
}
/**
 * @generated from protobuf message web_odds.InitialCompetition
 */
export interface InitialCompetition {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string short_name = 3;
     */
    shortName: string;
    /**
     * @generated from protobuf field: int32 competition_order = 333;
     */
    competitionOrder: number;
    /**
     * @generated from protobuf field: int32 location_order = 4;
     */
    locationOrder: number;
    /**
     * @generated from protobuf field: bool is_favorite = 334;
     */
    isFavorite: boolean;
}
/**
 * @generated from protobuf message web_odds.InitialDataSportsMap
 */
export interface InitialDataSportsMap {
    /**
     * @generated from protobuf field: map<string, web_odds.InitialSportMap> sports = 1;
     */
    sports: {
        [key: string]: InitialSportMap;
    };
}
/**
 * @generated from protobuf message web_odds.InitialSportMap
 */
export interface InitialSportMap {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 order = 6;
     */
    order: number;
    /**
     * @generated from protobuf field: map<string, web_odds.InitialLocationMap> locations = 3;
     */
    locations: {
        [key: string]: InitialLocationMap;
    };
    /**
     * @generated from protobuf field: web_odds.InitialBasicOffer basic_offer_live = 4;
     */
    basicOfferLive?: InitialBasicOffer;
    /**
     * @generated from protobuf field: web_odds.InitialBasicOffer basic_offer_prematch = 5;
     */
    basicOfferPrematch?: InitialBasicOffer;
}
/**
 * @generated from protobuf message web_odds.InitialLocationMap
 */
export interface InitialLocationMap {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: map<string, web_odds.InitialCompetition> competitions = 3;
     */
    competitions: {
        [key: string]: InitialCompetition;
    };
    /**
     * @generated from protobuf field: string flag_code = 444;
     */
    flagCode: string;
}
/**
 * @generated from protobuf message web_odds.InitialBasicOffer
 */
export interface InitialBasicOffer {
    /**
     * @generated from protobuf field: repeated web_odds.InitialBasicOfferMarket markets = 1;
     */
    markets: InitialBasicOfferMarket[];
}
/**
 * @generated from protobuf message web_odds.InitialBasicOfferMarket
 */
export interface InitialBasicOfferMarket {
    /**
     * @generated from protobuf field: int32 market_id = 1;
     */
    marketId: number;
    /**
     * @generated from protobuf field: repeated int32 outcome_ids = 2;
     */
    outcomeIds: number[];
}
/**
 * @generated from protobuf message web_odds.InitialMarketGroup
 */
export interface InitialMarketGroup {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string english_name = 555;
     */
    englishName: string;
    /**
     * @generated from protobuf field: string albanian_name = 556;
     */
    albanianName: string;
    /**
     * @generated from protobuf field: string turkish_name = 557;
     */
    turkishName: string;
    /**
     * @generated from protobuf field: string russian_name = 558;
     */
    russianName: string;
    /**
     * @generated from protobuf field: string ukrainian_name = 559;
     */
    ukrainianName: string;
    /**
     * @generated from protobuf field: string italian_name = 560;
     */
    italianName: string;
    /**
     * @generated from protobuf field: string german_name = 561;
     */
    germanName: string;
    /**
     * @generated from protobuf field: repeated web_odds.InitialMarket markets = 3;
     */
    markets: InitialMarket[];
}
/**
 * @generated from protobuf message web_odds.InitialMarket
 */
export interface InitialMarket {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int32 sport_id = 3;
     */
    sportId: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string english_name = 555;
     */
    englishName: string;
    /**
     * @generated from protobuf field: string albanian_name = 556;
     */
    albanianName: string;
    /**
     * @generated from protobuf field: string turkish_name = 557;
     */
    turkishName: string;
    /**
     * @generated from protobuf field: string russian_name = 558;
     */
    russianName: string;
    /**
     * @generated from protobuf field: string ukrainian_name = 559;
     */
    ukrainianName: string;
    /**
     * @generated from protobuf field: string italian_name = 560;
     */
    italianName: string;
    /**
     * @generated from protobuf field: string german_name = 561;
     */
    germanName: string;
    /**
     * @generated from protobuf field: repeated web_odds.InitialOutcome outcomes = 4;
     */
    outcomes: InitialOutcome[];
}
/**
 * @generated from protobuf message web_odds.InitialOutcome
 */
export interface InitialOutcome {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string outcome_group = 3;
     */
    outcomeGroup: string;
    /**
     * @generated from protobuf field: string outcome_tic = 4;
     */
    outcomeTic: string;
    /**
     * @generated from protobuf field: string exe_limit = 5;
     */
    exeLimit: string;
    /**
     * @generated from protobuf field: string short_description = 6;
     */
    shortDescription: string;
    /**
     * @generated from protobuf field: bool has_limit = 7;
     */
    hasLimit: boolean;
    /**
     * @generated from protobuf field: int32 order = 8;
     */
    order: number;
}
// MarketsMap

/**
 * @generated from protobuf message web_odds.InitialDataMarketsMap
 */
export interface InitialDataMarketsMap {
    /**
     * @generated from protobuf field: map<string, web_odds.InitialMarketGroupMap> market_groups = 1;
     */
    marketGroups: {
        [key: string]: InitialMarketGroupMap;
    };
}
/**
 * @generated from protobuf message web_odds.InitialMarketGroupMap
 */
export interface InitialMarketGroupMap {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string english_name = 4;
     */
    englishName: string;
    /**
     * @generated from protobuf field: string albanian_name = 556;
     */
    albanianName: string;
    /**
     * @generated from protobuf field: string turkish_name = 557;
     */
    turkishName: string;
    /**
     * @generated from protobuf field: string russian_name = 558;
     */
    russianName: string;
    /**
     * @generated from protobuf field: string ukrainian_name = 559;
     */
    ukrainianName: string;
    /**
     * @generated from protobuf field: string italian_name = 560;
     */
    italianName: string;
    /**
     * @generated from protobuf field: string german_name = 561;
     */
    germanName: string;
    /**
     * @generated from protobuf field: int32 order = 5;
     */
    order: number;
    /**
     * @generated from protobuf field: map<string, web_odds.InitialMarketMap> markets = 3;
     */
    markets: {
        [key: string]: InitialMarketMap;
    };
}
/**
 * @generated from protobuf message web_odds.InitialMarketMap
 */
export interface InitialMarketMap {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int32 sport_id = 3;
     */
    sportId: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string english_name = 5;
     */
    englishName: string;
    /**
     * @generated from protobuf field: string albanian_name = 556;
     */
    albanianName: string;
    /**
     * @generated from protobuf field: string turkish_name = 557;
     */
    turkishName: string;
    /**
     * @generated from protobuf field: string russian_name = 558;
     */
    russianName: string;
    /**
     * @generated from protobuf field: string ukrainian_name = 559;
     */
    ukrainianName: string;
    /**
     * @generated from protobuf field: string italian_name = 560;
     */
    italianName: string;
    /**
     * @generated from protobuf field: string german_name = 561;
     */
    germanName: string;
    /**
     * @generated from protobuf field: int32 order = 6;
     */
    order: number;
    /**
     * @generated from protobuf field: map<string, web_odds.InitialOutcome> outcomes = 4;
     */
    outcomes: {
        [key: string]: InitialOutcome;
    };
}
/**
 * Event Proto
 *
 * @generated from protobuf message web_odds.EventTime
 */
export interface EventTime {
    /**
     * @generated from protobuf field: string M = 202 [json_name = "M"];
     */
    m: string;
    /**
     * @generated from protobuf field: string S = 203 [json_name = "S"];
     */
    s: string;
}
/**
 * @generated from protobuf message web_odds.EventCurrentPhase
 */
export interface EventCurrentPhase {
    /**
     * @generated from protobuf field: string N = 200 [json_name = "N"];
     */
    n: string;
    /**
     * @generated from protobuf field: string S = 201 [json_name = "S"];
     */
    s: string;
    /**
     * @generated from protobuf field: web_odds.EventTime T = 204 [json_name = "T"];
     */
    t?: EventTime;
}
/**
 * @generated from protobuf message web_odds.EventCurrentResult
 */
export interface EventCurrentResult {
    /**
     * @generated from protobuf field: string G = 205 [json_name = "G"];
     */
    g: string;
    /**
     * @generated from protobuf field: string C = 206 [json_name = "C"];
     */
    c: string;
    /**
     * @generated from protobuf field: string RC = 207 [json_name = "RC"];
     */
    rC: string;
    /**
     * @generated from protobuf field: string YC = 208 [json_name = "YC"];
     */
    yC: string;
    /**
     * @generated from protobuf field: string P = 209 [json_name = "P"];
     */
    p: string;
    /**
     * @generated from protobuf field: string S = 210 [json_name = "S"];
     */
    s: string;
    /**
     * @generated from protobuf field: string L = 267 [json_name = "L"];
     */
    l: string;
    /**
     * @generated from protobuf field: string S180 = 268 [json_name = "S180"];
     */
    s180: string;
    /**
     * @generated from protobuf field: string FG = 269 [json_name = "FG"];
     */
    fG: string;
    /**
     * @generated from protobuf field: string PN = 270 [json_name = "PN"];
     */
    pN: string;
    /**
     * @generated from protobuf field: string TD = 271 [json_name = "TD"];
     */
    tD: string;
}
/**
 * @generated from protobuf message web_odds.EventLiveResultHistoryElement
 */
export interface EventLiveResultHistoryElement {
    /**
     * @generated from protobuf field: web_odds.EventCurrentPhase P = 211 [json_name = "P"];
     */
    p?: EventCurrentPhase;
    /**
     * @generated from protobuf field: web_odds.EventCurrentResult R = 212 [json_name = "R"];
     */
    r?: EventCurrentResult;
}
/**
 * @generated from protobuf message web_odds.Error
 */
export interface Error {
    /**
     * @generated from protobuf field: int32 grpc_code = 5;
     */
    grpcCode: number;
    /**
     * @generated from protobuf field: int32 http_code = 6;
     */
    httpCode: number;
    /**
     * @generated from protobuf field: repeated string message = 7;
     */
    message: string[];
}
/**
 * @generated from protobuf message web_odds.EventResult
 */
export interface EventResult {
    /**
     * @generated from protobuf field: web_odds.EventCurrentPhase current_phase = 213;
     */
    currentPhase?: EventCurrentPhase;
    /**
     * @generated from protobuf field: web_odds.EventCurrentResult current_result = 214;
     */
    currentResult?: EventCurrentResult;
    /**
     * @generated from protobuf field: repeated web_odds.EventLiveResultHistoryElement live_result_history = 215;
     */
    liveResultHistory: EventLiveResultHistoryElement[];
    /**
     * @generated from protobuf field: string live_result_stats = 216;
     */
    liveResultStats: string;
    /**
     * @generated from protobuf field: string prematch_result = 217;
     */
    prematchResult: string;
    /**
     * @generated from protobuf field: string live_result = 218;
     */
    liveResult: string;
    /**
     * @generated from protobuf field: bool confirmed = 219;
     */
    confirmed: boolean;
    /**
     * @generated from protobuf field: web_odds.Error error = 78;
     */
    error?: Error;
}
/**
 * @generated from protobuf message web_odds.WebStreamEvent
 */
export interface WebStreamEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 competition_id = 3;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: int32 location_id = 4;
     */
    locationId: number;
    /**
     * @generated from protobuf field: int32 sport_id = 5;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated web_odds.WebStreamEventOdd odds = 6;
     */
    odds: WebStreamEventOdd[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 7;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: bool is_disabled = 8;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: web_odds.EventResult result = 9;
     */
    result?: EventResult;
    /**
     * @generated from protobuf field: string landbase_code = 10;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: string type = 11;
     */
    type: string;
    /**
     * @generated from protobuf field: string outright_type = 12;
     */
    outrightType: string;
    /**
     * @generated from protobuf field: int32 mon = 13;
     */
    mon: number;
    /**
     * @generated from protobuf field: int32 number_of_odds = 14;
     */
    numberOfOdds: number;
    /**
     * @generated from protobuf field: int32 exefeed_id = 15;
     */
    exefeedId: number;
    /**
     * @generated from protobuf field: string profile = 16;
     */
    profile: string;
    /**
     * @generated from protobuf field: string betradar_id = 17;
     */
    betradarId: string;
    /**
     * @generated from protobuf field: string betradar_stream_id = 18;
     */
    betradarStreamId: string;
}
/**
 * @generated from protobuf message web_odds.WebStreamEventOdd
 */
export interface WebStreamEventOdd {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: double value = 2;
     */
    value: number;
    /**
     * @generated from protobuf field: int32 outcome_id = 3;
     */
    outcomeId: number;
    /**
     * @generated from protobuf field: double limit = 4;
     */
    limit: number;
    /**
     * @generated from protobuf field: int32 frame_number = 5;
     */
    frameNumber: number;
    /**
     * @generated from protobuf field: string current_result = 6;
     */
    currentResult: string;
    /**
     * @generated from protobuf field: bool is_disabled = 7;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: int32 status = 8;
     */
    status: number;
    /**
     * @generated from protobuf field: string live_player = 9;
     */
    livePlayer: string;
}
/**
 * @generated from protobuf message web_odds.EventRequest
 */
export interface EventRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * Stream Event
 *
 * @generated from protobuf message web_odds.StreamEventOdd
 */
export interface StreamEventOdd {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: double value = 8;
     */
    value: number;
    /**
     * @generated from protobuf field: int32 outcome_id = 11;
     */
    outcomeId: number;
    /**
     * @generated from protobuf field: double limit = 122;
     */
    limit: number;
    /**
     * @generated from protobuf field: int32 frame_number = 238;
     */
    frameNumber: number;
    /**
     * @generated from protobuf field: string current_result = 239;
     */
    currentResult: string;
    /**
     * @generated from protobuf field: bool is_disabled = 136;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: string live_player = 9;
     */
    livePlayer: string;
}
/**
 * @generated from protobuf message web_odds.StreamEvent
 */
export interface StreamEvent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string note = 3;
     */
    note: string;
    /**
     * @generated from protobuf field: int32 competition_id = 4;
     */
    competitionId: number;
    /**
     * @generated from protobuf field: int32 location_id = 5;
     */
    locationId: number;
    /**
     * @generated from protobuf field: int32 sport_id = 6;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated web_odds.StreamEventOdd odds = 7;
     */
    odds: StreamEventOdd[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp start = 8;
     */
    start?: Timestamp;
    /**
     * @generated from protobuf field: bool is_disabled = 9;
     */
    isDisabled: boolean;
    /**
     * @generated from protobuf field: bool is_suspicious = 10;
     */
    isSuspicious: boolean;
    /**
     * @generated from protobuf field: bool is_single_approval = 11;
     */
    isSingleApproval: boolean;
    /**
     * @generated from protobuf field: string profile = 12;
     */
    profile: string;
    /**
     * @generated from protobuf field: web_odds.EventCurrentPhase current_phase = 13;
     */
    currentPhase?: EventCurrentPhase;
    /**
     * @generated from protobuf field: string landbase_code = 14;
     */
    landbaseCode: string;
    /**
     * @generated from protobuf field: string type = 15;
     */
    type: string;
    /**
     * @generated from protobuf field: string outright_type = 16;
     */
    outrightType: string;
    /**
     * @generated from protobuf field: int32 mon = 17;
     */
    mon: number;
    /**
     * @generated from protobuf field: web_odds.EventResult result = 18;
     */
    result?: EventResult;
}
/**
 * LiveDisabled
 *
 * @generated from protobuf message web_odds.LiveDisabled
 */
export interface LiveDisabled {
    /**
     * @generated from protobuf field: bool all = 1;
     */
    all: boolean;
    /**
     * @generated from protobuf field: repeated int32 sport_ids = 2;
     */
    sportIds: number[];
}
/**
 * PlayerOutright
 *
 * @generated from protobuf message web_odds.PlayerOutrightMarkets
 */
export interface PlayerOutrightMarkets {
    /**
     * @generated from protobuf field: int32 sport_id = 1;
     */
    sportId: number;
    /**
     * @generated from protobuf field: repeated web_odds.InitialMarket outright_markets = 2;
     */
    outrightMarkets: InitialMarket[];
    /**
     * @generated from protobuf field: repeated web_odds.InitialMarket player_markets = 3;
     */
    playerMarkets: InitialMarket[];
}
// @generated message type with reflection information, may provide speed optimized methods
class CounterRequest$Type extends MessageType<CounterRequest> {
    constructor() {
        super("web_odds.CounterRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CounterRequest>): CounterRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.offset = "";
        if (value !== undefined)
            reflectionMergePartial<CounterRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CounterRequest): CounterRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string offset */ 1:
                    message.offset = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CounterRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string offset = 1; */
        if (message.offset !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.offset);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.CounterRequest
 */
export const CounterRequest = new CounterRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CounterMap$Type extends MessageType<CounterMap> {
    constructor() {
        super("web_odds.CounterMap", [
            { no: 1, name: "sports", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => CounterSportMap } }
        ]);
    }
    create(value?: PartialMessage<CounterMap>): CounterMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sports = {};
        if (value !== undefined)
            reflectionMergePartial<CounterMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CounterMap): CounterMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, web_odds.CounterSportMap> sports */ 1:
                    this.binaryReadMap1(message.sports, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: CounterMap["sports"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CounterMap["sports"] | undefined, val: CounterMap["sports"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = CounterSportMap.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field web_odds.CounterMap.sports");
            }
        }
        map[key ?? ""] = val ?? CounterSportMap.create();
    }
    internalBinaryWrite(message: CounterMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, web_odds.CounterSportMap> sports = 1; */
        for (let k of globalThis.Object.keys(message.sports)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            CounterSportMap.internalBinaryWrite(message.sports[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.CounterMap
 */
export const CounterMap = new CounterMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CounterSportMap$Type extends MessageType<CounterSportMap> {
    constructor() {
        super("web_odds.CounterSportMap", [
            { no: 1, name: "locations", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => CounterLocationsMap } },
            { no: 2, name: "date", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => CounterTypeMap } }
        ]);
    }
    create(value?: PartialMessage<CounterSportMap>): CounterSportMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.locations = {};
        message.date = {};
        if (value !== undefined)
            reflectionMergePartial<CounterSportMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CounterSportMap): CounterSportMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, web_odds.CounterLocationsMap> locations */ 1:
                    this.binaryReadMap1(message.locations, reader, options);
                    break;
                case /* map<string, web_odds.CounterTypeMap> date */ 2:
                    this.binaryReadMap2(message.date, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: CounterSportMap["locations"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CounterSportMap["locations"] | undefined, val: CounterSportMap["locations"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = CounterLocationsMap.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field web_odds.CounterSportMap.locations");
            }
        }
        map[key ?? ""] = val ?? CounterLocationsMap.create();
    }
    private binaryReadMap2(map: CounterSportMap["date"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CounterSportMap["date"] | undefined, val: CounterSportMap["date"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = CounterTypeMap.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field web_odds.CounterSportMap.date");
            }
        }
        map[key ?? ""] = val ?? CounterTypeMap.create();
    }
    internalBinaryWrite(message: CounterSportMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, web_odds.CounterLocationsMap> locations = 1; */
        for (let k of globalThis.Object.keys(message.locations)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            CounterLocationsMap.internalBinaryWrite(message.locations[k], writer, options);
            writer.join().join();
        }
        /* map<string, web_odds.CounterTypeMap> date = 2; */
        for (let k of globalThis.Object.keys(message.date)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            CounterTypeMap.internalBinaryWrite(message.date[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.CounterSportMap
 */
export const CounterSportMap = new CounterSportMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CounterLocationsMap$Type extends MessageType<CounterLocationsMap> {
    constructor() {
        super("web_odds.CounterLocationsMap", [
            { no: 1, name: "competitions", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => CounterTypeMap } }
        ]);
    }
    create(value?: PartialMessage<CounterLocationsMap>): CounterLocationsMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.competitions = {};
        if (value !== undefined)
            reflectionMergePartial<CounterLocationsMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CounterLocationsMap): CounterLocationsMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, web_odds.CounterTypeMap> competitions */ 1:
                    this.binaryReadMap1(message.competitions, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: CounterLocationsMap["competitions"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CounterLocationsMap["competitions"] | undefined, val: CounterLocationsMap["competitions"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = CounterTypeMap.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field web_odds.CounterLocationsMap.competitions");
            }
        }
        map[key ?? ""] = val ?? CounterTypeMap.create();
    }
    internalBinaryWrite(message: CounterLocationsMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, web_odds.CounterTypeMap> competitions = 1; */
        for (let k of globalThis.Object.keys(message.competitions)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            CounterTypeMap.internalBinaryWrite(message.competitions[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.CounterLocationsMap
 */
export const CounterLocationsMap = new CounterLocationsMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CounterTypeMap$Type extends MessageType<CounterTypeMap> {
    constructor() {
        super("web_odds.CounterTypeMap", [
            { no: 1, name: "live", kind: "message", T: () => CounterTimeMap },
            { no: 2, name: "prematch", kind: "message", T: () => CounterTimeMap },
            { no: 3, name: "O", kind: "message", jsonName: "O", T: () => CounterTimeMap },
            { no: 4, name: "P", kind: "message", jsonName: "P", T: () => CounterTimeMap },
            { no: 5, name: "G", kind: "message", jsonName: "G", T: () => CounterTimeMap }
        ]);
    }
    create(value?: PartialMessage<CounterTypeMap>): CounterTypeMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CounterTypeMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CounterTypeMap): CounterTypeMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* web_odds.CounterTimeMap live */ 1:
                    message.live = CounterTimeMap.internalBinaryRead(reader, reader.uint32(), options, message.live);
                    break;
                case /* web_odds.CounterTimeMap prematch */ 2:
                    message.prematch = CounterTimeMap.internalBinaryRead(reader, reader.uint32(), options, message.prematch);
                    break;
                case /* web_odds.CounterTimeMap O = 3 [json_name = "O"];*/ 3:
                    message.o = CounterTimeMap.internalBinaryRead(reader, reader.uint32(), options, message.o);
                    break;
                case /* web_odds.CounterTimeMap P = 4 [json_name = "P"];*/ 4:
                    message.p = CounterTimeMap.internalBinaryRead(reader, reader.uint32(), options, message.p);
                    break;
                case /* web_odds.CounterTimeMap G = 5 [json_name = "G"];*/ 5:
                    message.g = CounterTimeMap.internalBinaryRead(reader, reader.uint32(), options, message.g);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CounterTypeMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* web_odds.CounterTimeMap live = 1; */
        if (message.live)
            CounterTimeMap.internalBinaryWrite(message.live, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.CounterTimeMap prematch = 2; */
        if (message.prematch)
            CounterTimeMap.internalBinaryWrite(message.prematch, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.CounterTimeMap O = 3 [json_name = "O"]; */
        if (message.o)
            CounterTimeMap.internalBinaryWrite(message.o, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.CounterTimeMap P = 4 [json_name = "P"]; */
        if (message.p)
            CounterTimeMap.internalBinaryWrite(message.p, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.CounterTimeMap G = 5 [json_name = "G"]; */
        if (message.g)
            CounterTimeMap.internalBinaryWrite(message.g, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.CounterTypeMap
 */
export const CounterTypeMap = new CounterTypeMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CounterTimeMap$Type extends MessageType<CounterTimeMap> {
    constructor() {
        super("web_odds.CounterTimeMap", [
            { no: 1, name: "all", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "h1", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "h3", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "d1", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "d3", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CounterTimeMap>): CounterTimeMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.all = 0;
        message.h1 = 0;
        message.h3 = 0;
        message.d1 = 0;
        message.d3 = 0;
        if (value !== undefined)
            reflectionMergePartial<CounterTimeMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CounterTimeMap): CounterTimeMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 all */ 1:
                    message.all = reader.int32();
                    break;
                case /* int32 h1 */ 2:
                    message.h1 = reader.int32();
                    break;
                case /* int32 h3 */ 3:
                    message.h3 = reader.int32();
                    break;
                case /* int32 d1 */ 4:
                    message.d1 = reader.int32();
                    break;
                case /* int32 d3 */ 5:
                    message.d3 = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CounterTimeMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 all = 1; */
        if (message.all !== 0)
            writer.tag(1, WireType.Varint).int32(message.all);
        /* int32 h1 = 2; */
        if (message.h1 !== 0)
            writer.tag(2, WireType.Varint).int32(message.h1);
        /* int32 h3 = 3; */
        if (message.h3 !== 0)
            writer.tag(3, WireType.Varint).int32(message.h3);
        /* int32 d1 = 4; */
        if (message.d1 !== 0)
            writer.tag(4, WireType.Varint).int32(message.d1);
        /* int32 d3 = 5; */
        if (message.d3 !== 0)
            writer.tag(5, WireType.Varint).int32(message.d3);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.CounterTimeMap
 */
export const CounterTimeMap = new CounterTimeMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialDataRequest$Type extends MessageType<InitialDataRequest> {
    constructor() {
        super("web_odds.InitialDataRequest", [
            { no: 1, name: "only_markets", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<InitialDataRequest>): InitialDataRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.onlyMarkets = false;
        if (value !== undefined)
            reflectionMergePartial<InitialDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialDataRequest): InitialDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool only_markets */ 1:
                    message.onlyMarkets = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool only_markets = 1; */
        if (message.onlyMarkets !== false)
            writer.tag(1, WireType.Varint).bool(message.onlyMarkets);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialDataRequest
 */
export const InitialDataRequest = new InitialDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialDataResponse$Type extends MessageType<InitialDataResponse> {
    constructor() {
        super("web_odds.InitialDataResponse", [
            { no: 1, name: "market_groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialMarketGroup },
            { no: 2, name: "sports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialSport }
        ]);
    }
    create(value?: PartialMessage<InitialDataResponse>): InitialDataResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.marketGroups = [];
        message.sports = [];
        if (value !== undefined)
            reflectionMergePartial<InitialDataResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialDataResponse): InitialDataResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated web_odds.InitialMarketGroup market_groups */ 1:
                    message.marketGroups.push(InitialMarketGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated web_odds.InitialSport sports */ 2:
                    message.sports.push(InitialSport.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialDataResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated web_odds.InitialMarketGroup market_groups = 1; */
        for (let i = 0; i < message.marketGroups.length; i++)
            InitialMarketGroup.internalBinaryWrite(message.marketGroups[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated web_odds.InitialSport sports = 2; */
        for (let i = 0; i < message.sports.length; i++)
            InitialSport.internalBinaryWrite(message.sports[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialDataResponse
 */
export const InitialDataResponse = new InitialDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialSport$Type extends MessageType<InitialSport> {
    constructor() {
        super("web_odds.InitialSport", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "locations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialLocation },
            { no: 4, name: "basic_offer_live", kind: "message", T: () => InitialBasicOffer },
            { no: 5, name: "basic_offer_prematch", kind: "message", T: () => InitialBasicOffer }
        ]);
    }
    create(value?: PartialMessage<InitialSport>): InitialSport {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.locations = [];
        if (value !== undefined)
            reflectionMergePartial<InitialSport>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialSport): InitialSport {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated web_odds.InitialLocation locations */ 3:
                    message.locations.push(InitialLocation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* web_odds.InitialBasicOffer basic_offer_live */ 4:
                    message.basicOfferLive = InitialBasicOffer.internalBinaryRead(reader, reader.uint32(), options, message.basicOfferLive);
                    break;
                case /* web_odds.InitialBasicOffer basic_offer_prematch */ 5:
                    message.basicOfferPrematch = InitialBasicOffer.internalBinaryRead(reader, reader.uint32(), options, message.basicOfferPrematch);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialSport, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated web_odds.InitialLocation locations = 3; */
        for (let i = 0; i < message.locations.length; i++)
            InitialLocation.internalBinaryWrite(message.locations[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.InitialBasicOffer basic_offer_live = 4; */
        if (message.basicOfferLive)
            InitialBasicOffer.internalBinaryWrite(message.basicOfferLive, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.InitialBasicOffer basic_offer_prematch = 5; */
        if (message.basicOfferPrematch)
            InitialBasicOffer.internalBinaryWrite(message.basicOfferPrematch, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialSport
 */
export const InitialSport = new InitialSport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialLocation$Type extends MessageType<InitialLocation> {
    constructor() {
        super("web_odds.InitialLocation", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "competitions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialCompetition },
            { no: 444, name: "flag_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InitialLocation>): InitialLocation {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.competitions = [];
        message.flagCode = "";
        if (value !== undefined)
            reflectionMergePartial<InitialLocation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialLocation): InitialLocation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated web_odds.InitialCompetition competitions */ 3:
                    message.competitions.push(InitialCompetition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string flag_code */ 444:
                    message.flagCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialLocation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated web_odds.InitialCompetition competitions = 3; */
        for (let i = 0; i < message.competitions.length; i++)
            InitialCompetition.internalBinaryWrite(message.competitions[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string flag_code = 444; */
        if (message.flagCode !== "")
            writer.tag(444, WireType.LengthDelimited).string(message.flagCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialLocation
 */
export const InitialLocation = new InitialLocation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialCompetition$Type extends MessageType<InitialCompetition> {
    constructor() {
        super("web_odds.InitialCompetition", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 333, name: "competition_order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "location_order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 334, name: "is_favorite", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<InitialCompetition>): InitialCompetition {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.shortName = "";
        message.competitionOrder = 0;
        message.locationOrder = 0;
        message.isFavorite = false;
        if (value !== undefined)
            reflectionMergePartial<InitialCompetition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialCompetition): InitialCompetition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string short_name */ 3:
                    message.shortName = reader.string();
                    break;
                case /* int32 competition_order */ 333:
                    message.competitionOrder = reader.int32();
                    break;
                case /* int32 location_order */ 4:
                    message.locationOrder = reader.int32();
                    break;
                case /* bool is_favorite */ 334:
                    message.isFavorite = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialCompetition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string short_name = 3; */
        if (message.shortName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.shortName);
        /* int32 competition_order = 333; */
        if (message.competitionOrder !== 0)
            writer.tag(333, WireType.Varint).int32(message.competitionOrder);
        /* int32 location_order = 4; */
        if (message.locationOrder !== 0)
            writer.tag(4, WireType.Varint).int32(message.locationOrder);
        /* bool is_favorite = 334; */
        if (message.isFavorite !== false)
            writer.tag(334, WireType.Varint).bool(message.isFavorite);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialCompetition
 */
export const InitialCompetition = new InitialCompetition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialDataSportsMap$Type extends MessageType<InitialDataSportsMap> {
    constructor() {
        super("web_odds.InitialDataSportsMap", [
            { no: 1, name: "sports", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => InitialSportMap } }
        ]);
    }
    create(value?: PartialMessage<InitialDataSportsMap>): InitialDataSportsMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sports = {};
        if (value !== undefined)
            reflectionMergePartial<InitialDataSportsMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialDataSportsMap): InitialDataSportsMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, web_odds.InitialSportMap> sports */ 1:
                    this.binaryReadMap1(message.sports, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: InitialDataSportsMap["sports"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof InitialDataSportsMap["sports"] | undefined, val: InitialDataSportsMap["sports"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = InitialSportMap.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field web_odds.InitialDataSportsMap.sports");
            }
        }
        map[key ?? ""] = val ?? InitialSportMap.create();
    }
    internalBinaryWrite(message: InitialDataSportsMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, web_odds.InitialSportMap> sports = 1; */
        for (let k of globalThis.Object.keys(message.sports)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            InitialSportMap.internalBinaryWrite(message.sports[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialDataSportsMap
 */
export const InitialDataSportsMap = new InitialDataSportsMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialSportMap$Type extends MessageType<InitialSportMap> {
    constructor() {
        super("web_odds.InitialSportMap", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "locations", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => InitialLocationMap } },
            { no: 4, name: "basic_offer_live", kind: "message", T: () => InitialBasicOffer },
            { no: 5, name: "basic_offer_prematch", kind: "message", T: () => InitialBasicOffer }
        ]);
    }
    create(value?: PartialMessage<InitialSportMap>): InitialSportMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.order = 0;
        message.locations = {};
        if (value !== undefined)
            reflectionMergePartial<InitialSportMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialSportMap): InitialSportMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 order */ 6:
                    message.order = reader.int32();
                    break;
                case /* map<string, web_odds.InitialLocationMap> locations */ 3:
                    this.binaryReadMap3(message.locations, reader, options);
                    break;
                case /* web_odds.InitialBasicOffer basic_offer_live */ 4:
                    message.basicOfferLive = InitialBasicOffer.internalBinaryRead(reader, reader.uint32(), options, message.basicOfferLive);
                    break;
                case /* web_odds.InitialBasicOffer basic_offer_prematch */ 5:
                    message.basicOfferPrematch = InitialBasicOffer.internalBinaryRead(reader, reader.uint32(), options, message.basicOfferPrematch);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: InitialSportMap["locations"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof InitialSportMap["locations"] | undefined, val: InitialSportMap["locations"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = InitialLocationMap.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field web_odds.InitialSportMap.locations");
            }
        }
        map[key ?? ""] = val ?? InitialLocationMap.create();
    }
    internalBinaryWrite(message: InitialSportMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 order = 6; */
        if (message.order !== 0)
            writer.tag(6, WireType.Varint).int32(message.order);
        /* map<string, web_odds.InitialLocationMap> locations = 3; */
        for (let k of globalThis.Object.keys(message.locations)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            InitialLocationMap.internalBinaryWrite(message.locations[k], writer, options);
            writer.join().join();
        }
        /* web_odds.InitialBasicOffer basic_offer_live = 4; */
        if (message.basicOfferLive)
            InitialBasicOffer.internalBinaryWrite(message.basicOfferLive, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.InitialBasicOffer basic_offer_prematch = 5; */
        if (message.basicOfferPrematch)
            InitialBasicOffer.internalBinaryWrite(message.basicOfferPrematch, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialSportMap
 */
export const InitialSportMap = new InitialSportMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialLocationMap$Type extends MessageType<InitialLocationMap> {
    constructor() {
        super("web_odds.InitialLocationMap", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "competitions", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => InitialCompetition } },
            { no: 444, name: "flag_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InitialLocationMap>): InitialLocationMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.competitions = {};
        message.flagCode = "";
        if (value !== undefined)
            reflectionMergePartial<InitialLocationMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialLocationMap): InitialLocationMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* map<string, web_odds.InitialCompetition> competitions */ 3:
                    this.binaryReadMap3(message.competitions, reader, options);
                    break;
                case /* string flag_code */ 444:
                    message.flagCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: InitialLocationMap["competitions"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof InitialLocationMap["competitions"] | undefined, val: InitialLocationMap["competitions"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = InitialCompetition.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field web_odds.InitialLocationMap.competitions");
            }
        }
        map[key ?? ""] = val ?? InitialCompetition.create();
    }
    internalBinaryWrite(message: InitialLocationMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* map<string, web_odds.InitialCompetition> competitions = 3; */
        for (let k of globalThis.Object.keys(message.competitions)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            InitialCompetition.internalBinaryWrite(message.competitions[k], writer, options);
            writer.join().join();
        }
        /* string flag_code = 444; */
        if (message.flagCode !== "")
            writer.tag(444, WireType.LengthDelimited).string(message.flagCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialLocationMap
 */
export const InitialLocationMap = new InitialLocationMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialBasicOffer$Type extends MessageType<InitialBasicOffer> {
    constructor() {
        super("web_odds.InitialBasicOffer", [
            { no: 1, name: "markets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialBasicOfferMarket }
        ]);
    }
    create(value?: PartialMessage<InitialBasicOffer>): InitialBasicOffer {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.markets = [];
        if (value !== undefined)
            reflectionMergePartial<InitialBasicOffer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialBasicOffer): InitialBasicOffer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated web_odds.InitialBasicOfferMarket markets */ 1:
                    message.markets.push(InitialBasicOfferMarket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialBasicOffer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated web_odds.InitialBasicOfferMarket markets = 1; */
        for (let i = 0; i < message.markets.length; i++)
            InitialBasicOfferMarket.internalBinaryWrite(message.markets[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialBasicOffer
 */
export const InitialBasicOffer = new InitialBasicOffer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialBasicOfferMarket$Type extends MessageType<InitialBasicOfferMarket> {
    constructor() {
        super("web_odds.InitialBasicOfferMarket", [
            { no: 1, name: "market_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "outcome_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<InitialBasicOfferMarket>): InitialBasicOfferMarket {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.marketId = 0;
        message.outcomeIds = [];
        if (value !== undefined)
            reflectionMergePartial<InitialBasicOfferMarket>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialBasicOfferMarket): InitialBasicOfferMarket {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 market_id */ 1:
                    message.marketId = reader.int32();
                    break;
                case /* repeated int32 outcome_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.outcomeIds.push(reader.int32());
                    else
                        message.outcomeIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialBasicOfferMarket, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 market_id = 1; */
        if (message.marketId !== 0)
            writer.tag(1, WireType.Varint).int32(message.marketId);
        /* repeated int32 outcome_ids = 2; */
        if (message.outcomeIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.outcomeIds.length; i++)
                writer.int32(message.outcomeIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialBasicOfferMarket
 */
export const InitialBasicOfferMarket = new InitialBasicOfferMarket$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialMarketGroup$Type extends MessageType<InitialMarketGroup> {
    constructor() {
        super("web_odds.InitialMarketGroup", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 555, name: "english_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 556, name: "albanian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 557, name: "turkish_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 558, name: "russian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 559, name: "ukrainian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 560, name: "italian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 561, name: "german_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "markets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialMarket }
        ]);
    }
    create(value?: PartialMessage<InitialMarketGroup>): InitialMarketGroup {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.englishName = "";
        message.albanianName = "";
        message.turkishName = "";
        message.russianName = "";
        message.ukrainianName = "";
        message.italianName = "";
        message.germanName = "";
        message.markets = [];
        if (value !== undefined)
            reflectionMergePartial<InitialMarketGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialMarketGroup): InitialMarketGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string english_name */ 555:
                    message.englishName = reader.string();
                    break;
                case /* string albanian_name */ 556:
                    message.albanianName = reader.string();
                    break;
                case /* string turkish_name */ 557:
                    message.turkishName = reader.string();
                    break;
                case /* string russian_name */ 558:
                    message.russianName = reader.string();
                    break;
                case /* string ukrainian_name */ 559:
                    message.ukrainianName = reader.string();
                    break;
                case /* string italian_name */ 560:
                    message.italianName = reader.string();
                    break;
                case /* string german_name */ 561:
                    message.germanName = reader.string();
                    break;
                case /* repeated web_odds.InitialMarket markets */ 3:
                    message.markets.push(InitialMarket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialMarketGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string english_name = 555; */
        if (message.englishName !== "")
            writer.tag(555, WireType.LengthDelimited).string(message.englishName);
        /* string albanian_name = 556; */
        if (message.albanianName !== "")
            writer.tag(556, WireType.LengthDelimited).string(message.albanianName);
        /* string turkish_name = 557; */
        if (message.turkishName !== "")
            writer.tag(557, WireType.LengthDelimited).string(message.turkishName);
        /* string russian_name = 558; */
        if (message.russianName !== "")
            writer.tag(558, WireType.LengthDelimited).string(message.russianName);
        /* string ukrainian_name = 559; */
        if (message.ukrainianName !== "")
            writer.tag(559, WireType.LengthDelimited).string(message.ukrainianName);
        /* string italian_name = 560; */
        if (message.italianName !== "")
            writer.tag(560, WireType.LengthDelimited).string(message.italianName);
        /* string german_name = 561; */
        if (message.germanName !== "")
            writer.tag(561, WireType.LengthDelimited).string(message.germanName);
        /* repeated web_odds.InitialMarket markets = 3; */
        for (let i = 0; i < message.markets.length; i++)
            InitialMarket.internalBinaryWrite(message.markets[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialMarketGroup
 */
export const InitialMarketGroup = new InitialMarketGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialMarket$Type extends MessageType<InitialMarket> {
    constructor() {
        super("web_odds.InitialMarket", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 555, name: "english_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 556, name: "albanian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 557, name: "turkish_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 558, name: "russian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 559, name: "ukrainian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 560, name: "italian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 561, name: "german_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "outcomes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialOutcome }
        ]);
    }
    create(value?: PartialMessage<InitialMarket>): InitialMarket {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.sportId = 0;
        message.name = "";
        message.englishName = "";
        message.albanianName = "";
        message.turkishName = "";
        message.russianName = "";
        message.ukrainianName = "";
        message.italianName = "";
        message.germanName = "";
        message.outcomes = [];
        if (value !== undefined)
            reflectionMergePartial<InitialMarket>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialMarket): InitialMarket {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 sport_id */ 3:
                    message.sportId = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string english_name */ 555:
                    message.englishName = reader.string();
                    break;
                case /* string albanian_name */ 556:
                    message.albanianName = reader.string();
                    break;
                case /* string turkish_name */ 557:
                    message.turkishName = reader.string();
                    break;
                case /* string russian_name */ 558:
                    message.russianName = reader.string();
                    break;
                case /* string ukrainian_name */ 559:
                    message.ukrainianName = reader.string();
                    break;
                case /* string italian_name */ 560:
                    message.italianName = reader.string();
                    break;
                case /* string german_name */ 561:
                    message.germanName = reader.string();
                    break;
                case /* repeated web_odds.InitialOutcome outcomes */ 4:
                    message.outcomes.push(InitialOutcome.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialMarket, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 sport_id = 3; */
        if (message.sportId !== 0)
            writer.tag(3, WireType.Varint).int32(message.sportId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string english_name = 555; */
        if (message.englishName !== "")
            writer.tag(555, WireType.LengthDelimited).string(message.englishName);
        /* string albanian_name = 556; */
        if (message.albanianName !== "")
            writer.tag(556, WireType.LengthDelimited).string(message.albanianName);
        /* string turkish_name = 557; */
        if (message.turkishName !== "")
            writer.tag(557, WireType.LengthDelimited).string(message.turkishName);
        /* string russian_name = 558; */
        if (message.russianName !== "")
            writer.tag(558, WireType.LengthDelimited).string(message.russianName);
        /* string ukrainian_name = 559; */
        if (message.ukrainianName !== "")
            writer.tag(559, WireType.LengthDelimited).string(message.ukrainianName);
        /* string italian_name = 560; */
        if (message.italianName !== "")
            writer.tag(560, WireType.LengthDelimited).string(message.italianName);
        /* string german_name = 561; */
        if (message.germanName !== "")
            writer.tag(561, WireType.LengthDelimited).string(message.germanName);
        /* repeated web_odds.InitialOutcome outcomes = 4; */
        for (let i = 0; i < message.outcomes.length; i++)
            InitialOutcome.internalBinaryWrite(message.outcomes[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialMarket
 */
export const InitialMarket = new InitialMarket$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialOutcome$Type extends MessageType<InitialOutcome> {
    constructor() {
        super("web_odds.InitialOutcome", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "outcome_group", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "outcome_tic", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "exe_limit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "short_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "has_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "order", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<InitialOutcome>): InitialOutcome {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.outcomeGroup = "";
        message.outcomeTic = "";
        message.exeLimit = "";
        message.shortDescription = "";
        message.hasLimit = false;
        message.order = 0;
        if (value !== undefined)
            reflectionMergePartial<InitialOutcome>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialOutcome): InitialOutcome {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string outcome_group */ 3:
                    message.outcomeGroup = reader.string();
                    break;
                case /* string outcome_tic */ 4:
                    message.outcomeTic = reader.string();
                    break;
                case /* string exe_limit */ 5:
                    message.exeLimit = reader.string();
                    break;
                case /* string short_description */ 6:
                    message.shortDescription = reader.string();
                    break;
                case /* bool has_limit */ 7:
                    message.hasLimit = reader.bool();
                    break;
                case /* int32 order */ 8:
                    message.order = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialOutcome, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string outcome_group = 3; */
        if (message.outcomeGroup !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.outcomeGroup);
        /* string outcome_tic = 4; */
        if (message.outcomeTic !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.outcomeTic);
        /* string exe_limit = 5; */
        if (message.exeLimit !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.exeLimit);
        /* string short_description = 6; */
        if (message.shortDescription !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.shortDescription);
        /* bool has_limit = 7; */
        if (message.hasLimit !== false)
            writer.tag(7, WireType.Varint).bool(message.hasLimit);
        /* int32 order = 8; */
        if (message.order !== 0)
            writer.tag(8, WireType.Varint).int32(message.order);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialOutcome
 */
export const InitialOutcome = new InitialOutcome$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialDataMarketsMap$Type extends MessageType<InitialDataMarketsMap> {
    constructor() {
        super("web_odds.InitialDataMarketsMap", [
            { no: 1, name: "market_groups", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => InitialMarketGroupMap } }
        ]);
    }
    create(value?: PartialMessage<InitialDataMarketsMap>): InitialDataMarketsMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.marketGroups = {};
        if (value !== undefined)
            reflectionMergePartial<InitialDataMarketsMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialDataMarketsMap): InitialDataMarketsMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, web_odds.InitialMarketGroupMap> market_groups */ 1:
                    this.binaryReadMap1(message.marketGroups, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: InitialDataMarketsMap["marketGroups"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof InitialDataMarketsMap["marketGroups"] | undefined, val: InitialDataMarketsMap["marketGroups"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = InitialMarketGroupMap.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field web_odds.InitialDataMarketsMap.market_groups");
            }
        }
        map[key ?? ""] = val ?? InitialMarketGroupMap.create();
    }
    internalBinaryWrite(message: InitialDataMarketsMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, web_odds.InitialMarketGroupMap> market_groups = 1; */
        for (let k of globalThis.Object.keys(message.marketGroups)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            InitialMarketGroupMap.internalBinaryWrite(message.marketGroups[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialDataMarketsMap
 */
export const InitialDataMarketsMap = new InitialDataMarketsMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialMarketGroupMap$Type extends MessageType<InitialMarketGroupMap> {
    constructor() {
        super("web_odds.InitialMarketGroupMap", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "english_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 556, name: "albanian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 557, name: "turkish_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 558, name: "russian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 559, name: "ukrainian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 560, name: "italian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 561, name: "german_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "markets", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => InitialMarketMap } }
        ]);
    }
    create(value?: PartialMessage<InitialMarketGroupMap>): InitialMarketGroupMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.englishName = "";
        message.albanianName = "";
        message.turkishName = "";
        message.russianName = "";
        message.ukrainianName = "";
        message.italianName = "";
        message.germanName = "";
        message.order = 0;
        message.markets = {};
        if (value !== undefined)
            reflectionMergePartial<InitialMarketGroupMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialMarketGroupMap): InitialMarketGroupMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string english_name */ 4:
                    message.englishName = reader.string();
                    break;
                case /* string albanian_name */ 556:
                    message.albanianName = reader.string();
                    break;
                case /* string turkish_name */ 557:
                    message.turkishName = reader.string();
                    break;
                case /* string russian_name */ 558:
                    message.russianName = reader.string();
                    break;
                case /* string ukrainian_name */ 559:
                    message.ukrainianName = reader.string();
                    break;
                case /* string italian_name */ 560:
                    message.italianName = reader.string();
                    break;
                case /* string german_name */ 561:
                    message.germanName = reader.string();
                    break;
                case /* int32 order */ 5:
                    message.order = reader.int32();
                    break;
                case /* map<string, web_odds.InitialMarketMap> markets */ 3:
                    this.binaryReadMap3(message.markets, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: InitialMarketGroupMap["markets"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof InitialMarketGroupMap["markets"] | undefined, val: InitialMarketGroupMap["markets"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = InitialMarketMap.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field web_odds.InitialMarketGroupMap.markets");
            }
        }
        map[key ?? ""] = val ?? InitialMarketMap.create();
    }
    internalBinaryWrite(message: InitialMarketGroupMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string english_name = 4; */
        if (message.englishName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.englishName);
        /* string albanian_name = 556; */
        if (message.albanianName !== "")
            writer.tag(556, WireType.LengthDelimited).string(message.albanianName);
        /* string turkish_name = 557; */
        if (message.turkishName !== "")
            writer.tag(557, WireType.LengthDelimited).string(message.turkishName);
        /* string russian_name = 558; */
        if (message.russianName !== "")
            writer.tag(558, WireType.LengthDelimited).string(message.russianName);
        /* string ukrainian_name = 559; */
        if (message.ukrainianName !== "")
            writer.tag(559, WireType.LengthDelimited).string(message.ukrainianName);
        /* string italian_name = 560; */
        if (message.italianName !== "")
            writer.tag(560, WireType.LengthDelimited).string(message.italianName);
        /* string german_name = 561; */
        if (message.germanName !== "")
            writer.tag(561, WireType.LengthDelimited).string(message.germanName);
        /* int32 order = 5; */
        if (message.order !== 0)
            writer.tag(5, WireType.Varint).int32(message.order);
        /* map<string, web_odds.InitialMarketMap> markets = 3; */
        for (let k of globalThis.Object.keys(message.markets)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            InitialMarketMap.internalBinaryWrite(message.markets[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialMarketGroupMap
 */
export const InitialMarketGroupMap = new InitialMarketGroupMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialMarketMap$Type extends MessageType<InitialMarketMap> {
    constructor() {
        super("web_odds.InitialMarketMap", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "english_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 556, name: "albanian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 557, name: "turkish_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 558, name: "russian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 559, name: "ukrainian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 560, name: "italian_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 561, name: "german_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "order", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "outcomes", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => InitialOutcome } }
        ]);
    }
    create(value?: PartialMessage<InitialMarketMap>): InitialMarketMap {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.sportId = 0;
        message.name = "";
        message.englishName = "";
        message.albanianName = "";
        message.turkishName = "";
        message.russianName = "";
        message.ukrainianName = "";
        message.italianName = "";
        message.germanName = "";
        message.order = 0;
        message.outcomes = {};
        if (value !== undefined)
            reflectionMergePartial<InitialMarketMap>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialMarketMap): InitialMarketMap {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 sport_id */ 3:
                    message.sportId = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string english_name */ 5:
                    message.englishName = reader.string();
                    break;
                case /* string albanian_name */ 556:
                    message.albanianName = reader.string();
                    break;
                case /* string turkish_name */ 557:
                    message.turkishName = reader.string();
                    break;
                case /* string russian_name */ 558:
                    message.russianName = reader.string();
                    break;
                case /* string ukrainian_name */ 559:
                    message.ukrainianName = reader.string();
                    break;
                case /* string italian_name */ 560:
                    message.italianName = reader.string();
                    break;
                case /* string german_name */ 561:
                    message.germanName = reader.string();
                    break;
                case /* int32 order */ 6:
                    message.order = reader.int32();
                    break;
                case /* map<string, web_odds.InitialOutcome> outcomes */ 4:
                    this.binaryReadMap4(message.outcomes, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: InitialMarketMap["outcomes"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof InitialMarketMap["outcomes"] | undefined, val: InitialMarketMap["outcomes"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = InitialOutcome.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field web_odds.InitialMarketMap.outcomes");
            }
        }
        map[key ?? ""] = val ?? InitialOutcome.create();
    }
    internalBinaryWrite(message: InitialMarketMap, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 sport_id = 3; */
        if (message.sportId !== 0)
            writer.tag(3, WireType.Varint).int32(message.sportId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string english_name = 5; */
        if (message.englishName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.englishName);
        /* string albanian_name = 556; */
        if (message.albanianName !== "")
            writer.tag(556, WireType.LengthDelimited).string(message.albanianName);
        /* string turkish_name = 557; */
        if (message.turkishName !== "")
            writer.tag(557, WireType.LengthDelimited).string(message.turkishName);
        /* string russian_name = 558; */
        if (message.russianName !== "")
            writer.tag(558, WireType.LengthDelimited).string(message.russianName);
        /* string ukrainian_name = 559; */
        if (message.ukrainianName !== "")
            writer.tag(559, WireType.LengthDelimited).string(message.ukrainianName);
        /* string italian_name = 560; */
        if (message.italianName !== "")
            writer.tag(560, WireType.LengthDelimited).string(message.italianName);
        /* string german_name = 561; */
        if (message.germanName !== "")
            writer.tag(561, WireType.LengthDelimited).string(message.germanName);
        /* int32 order = 6; */
        if (message.order !== 0)
            writer.tag(6, WireType.Varint).int32(message.order);
        /* map<string, web_odds.InitialOutcome> outcomes = 4; */
        for (let k of globalThis.Object.keys(message.outcomes)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            InitialOutcome.internalBinaryWrite(message.outcomes[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.InitialMarketMap
 */
export const InitialMarketMap = new InitialMarketMap$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventTime$Type extends MessageType<EventTime> {
    constructor() {
        super("web_odds.EventTime", [
            { no: 202, name: "M", kind: "scalar", jsonName: "M", T: 9 /*ScalarType.STRING*/ },
            { no: 203, name: "S", kind: "scalar", jsonName: "S", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EventTime>): EventTime {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.m = "";
        message.s = "";
        if (value !== undefined)
            reflectionMergePartial<EventTime>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventTime): EventTime {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string M = 202 [json_name = "M"];*/ 202:
                    message.m = reader.string();
                    break;
                case /* string S = 203 [json_name = "S"];*/ 203:
                    message.s = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventTime, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string M = 202 [json_name = "M"]; */
        if (message.m !== "")
            writer.tag(202, WireType.LengthDelimited).string(message.m);
        /* string S = 203 [json_name = "S"]; */
        if (message.s !== "")
            writer.tag(203, WireType.LengthDelimited).string(message.s);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.EventTime
 */
export const EventTime = new EventTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventCurrentPhase$Type extends MessageType<EventCurrentPhase> {
    constructor() {
        super("web_odds.EventCurrentPhase", [
            { no: 200, name: "N", kind: "scalar", jsonName: "N", T: 9 /*ScalarType.STRING*/ },
            { no: 201, name: "S", kind: "scalar", jsonName: "S", T: 9 /*ScalarType.STRING*/ },
            { no: 204, name: "T", kind: "message", jsonName: "T", T: () => EventTime }
        ]);
    }
    create(value?: PartialMessage<EventCurrentPhase>): EventCurrentPhase {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.n = "";
        message.s = "";
        if (value !== undefined)
            reflectionMergePartial<EventCurrentPhase>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventCurrentPhase): EventCurrentPhase {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string N = 200 [json_name = "N"];*/ 200:
                    message.n = reader.string();
                    break;
                case /* string S = 201 [json_name = "S"];*/ 201:
                    message.s = reader.string();
                    break;
                case /* web_odds.EventTime T = 204 [json_name = "T"];*/ 204:
                    message.t = EventTime.internalBinaryRead(reader, reader.uint32(), options, message.t);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventCurrentPhase, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string N = 200 [json_name = "N"]; */
        if (message.n !== "")
            writer.tag(200, WireType.LengthDelimited).string(message.n);
        /* string S = 201 [json_name = "S"]; */
        if (message.s !== "")
            writer.tag(201, WireType.LengthDelimited).string(message.s);
        /* web_odds.EventTime T = 204 [json_name = "T"]; */
        if (message.t)
            EventTime.internalBinaryWrite(message.t, writer.tag(204, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.EventCurrentPhase
 */
export const EventCurrentPhase = new EventCurrentPhase$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventCurrentResult$Type extends MessageType<EventCurrentResult> {
    constructor() {
        super("web_odds.EventCurrentResult", [
            { no: 205, name: "G", kind: "scalar", jsonName: "G", T: 9 /*ScalarType.STRING*/ },
            { no: 206, name: "C", kind: "scalar", jsonName: "C", T: 9 /*ScalarType.STRING*/ },
            { no: 207, name: "RC", kind: "scalar", jsonName: "RC", T: 9 /*ScalarType.STRING*/ },
            { no: 208, name: "YC", kind: "scalar", jsonName: "YC", T: 9 /*ScalarType.STRING*/ },
            { no: 209, name: "P", kind: "scalar", jsonName: "P", T: 9 /*ScalarType.STRING*/ },
            { no: 210, name: "S", kind: "scalar", jsonName: "S", T: 9 /*ScalarType.STRING*/ },
            { no: 267, name: "L", kind: "scalar", jsonName: "L", T: 9 /*ScalarType.STRING*/ },
            { no: 268, name: "S180", kind: "scalar", jsonName: "S180", T: 9 /*ScalarType.STRING*/ },
            { no: 269, name: "FG", kind: "scalar", jsonName: "FG", T: 9 /*ScalarType.STRING*/ },
            { no: 270, name: "PN", kind: "scalar", jsonName: "PN", T: 9 /*ScalarType.STRING*/ },
            { no: 271, name: "TD", kind: "scalar", jsonName: "TD", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EventCurrentResult>): EventCurrentResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.g = "";
        message.c = "";
        message.rC = "";
        message.yC = "";
        message.p = "";
        message.s = "";
        message.l = "";
        message.s180 = "";
        message.fG = "";
        message.pN = "";
        message.tD = "";
        if (value !== undefined)
            reflectionMergePartial<EventCurrentResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventCurrentResult): EventCurrentResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string G = 205 [json_name = "G"];*/ 205:
                    message.g = reader.string();
                    break;
                case /* string C = 206 [json_name = "C"];*/ 206:
                    message.c = reader.string();
                    break;
                case /* string RC = 207 [json_name = "RC"];*/ 207:
                    message.rC = reader.string();
                    break;
                case /* string YC = 208 [json_name = "YC"];*/ 208:
                    message.yC = reader.string();
                    break;
                case /* string P = 209 [json_name = "P"];*/ 209:
                    message.p = reader.string();
                    break;
                case /* string S = 210 [json_name = "S"];*/ 210:
                    message.s = reader.string();
                    break;
                case /* string L = 267 [json_name = "L"];*/ 267:
                    message.l = reader.string();
                    break;
                case /* string S180 = 268 [json_name = "S180"];*/ 268:
                    message.s180 = reader.string();
                    break;
                case /* string FG = 269 [json_name = "FG"];*/ 269:
                    message.fG = reader.string();
                    break;
                case /* string PN = 270 [json_name = "PN"];*/ 270:
                    message.pN = reader.string();
                    break;
                case /* string TD = 271 [json_name = "TD"];*/ 271:
                    message.tD = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventCurrentResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string G = 205 [json_name = "G"]; */
        if (message.g !== "")
            writer.tag(205, WireType.LengthDelimited).string(message.g);
        /* string C = 206 [json_name = "C"]; */
        if (message.c !== "")
            writer.tag(206, WireType.LengthDelimited).string(message.c);
        /* string RC = 207 [json_name = "RC"]; */
        if (message.rC !== "")
            writer.tag(207, WireType.LengthDelimited).string(message.rC);
        /* string YC = 208 [json_name = "YC"]; */
        if (message.yC !== "")
            writer.tag(208, WireType.LengthDelimited).string(message.yC);
        /* string P = 209 [json_name = "P"]; */
        if (message.p !== "")
            writer.tag(209, WireType.LengthDelimited).string(message.p);
        /* string S = 210 [json_name = "S"]; */
        if (message.s !== "")
            writer.tag(210, WireType.LengthDelimited).string(message.s);
        /* string L = 267 [json_name = "L"]; */
        if (message.l !== "")
            writer.tag(267, WireType.LengthDelimited).string(message.l);
        /* string S180 = 268 [json_name = "S180"]; */
        if (message.s180 !== "")
            writer.tag(268, WireType.LengthDelimited).string(message.s180);
        /* string FG = 269 [json_name = "FG"]; */
        if (message.fG !== "")
            writer.tag(269, WireType.LengthDelimited).string(message.fG);
        /* string PN = 270 [json_name = "PN"]; */
        if (message.pN !== "")
            writer.tag(270, WireType.LengthDelimited).string(message.pN);
        /* string TD = 271 [json_name = "TD"]; */
        if (message.tD !== "")
            writer.tag(271, WireType.LengthDelimited).string(message.tD);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.EventCurrentResult
 */
export const EventCurrentResult = new EventCurrentResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventLiveResultHistoryElement$Type extends MessageType<EventLiveResultHistoryElement> {
    constructor() {
        super("web_odds.EventLiveResultHistoryElement", [
            { no: 211, name: "P", kind: "message", jsonName: "P", T: () => EventCurrentPhase },
            { no: 212, name: "R", kind: "message", jsonName: "R", T: () => EventCurrentResult }
        ]);
    }
    create(value?: PartialMessage<EventLiveResultHistoryElement>): EventLiveResultHistoryElement {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EventLiveResultHistoryElement>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventLiveResultHistoryElement): EventLiveResultHistoryElement {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* web_odds.EventCurrentPhase P = 211 [json_name = "P"];*/ 211:
                    message.p = EventCurrentPhase.internalBinaryRead(reader, reader.uint32(), options, message.p);
                    break;
                case /* web_odds.EventCurrentResult R = 212 [json_name = "R"];*/ 212:
                    message.r = EventCurrentResult.internalBinaryRead(reader, reader.uint32(), options, message.r);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventLiveResultHistoryElement, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* web_odds.EventCurrentPhase P = 211 [json_name = "P"]; */
        if (message.p)
            EventCurrentPhase.internalBinaryWrite(message.p, writer.tag(211, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.EventCurrentResult R = 212 [json_name = "R"]; */
        if (message.r)
            EventCurrentResult.internalBinaryWrite(message.r, writer.tag(212, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.EventLiveResultHistoryElement
 */
export const EventLiveResultHistoryElement = new EventLiveResultHistoryElement$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Error$Type extends MessageType<Error> {
    constructor() {
        super("web_odds.Error", [
            { no: 5, name: "grpc_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "http_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "message", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Error>): Error {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.grpcCode = 0;
        message.httpCode = 0;
        message.message = [];
        if (value !== undefined)
            reflectionMergePartial<Error>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Error): Error {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 grpc_code */ 5:
                    message.grpcCode = reader.int32();
                    break;
                case /* int32 http_code */ 6:
                    message.httpCode = reader.int32();
                    break;
                case /* repeated string message */ 7:
                    message.message.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Error, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 grpc_code = 5; */
        if (message.grpcCode !== 0)
            writer.tag(5, WireType.Varint).int32(message.grpcCode);
        /* int32 http_code = 6; */
        if (message.httpCode !== 0)
            writer.tag(6, WireType.Varint).int32(message.httpCode);
        /* repeated string message = 7; */
        for (let i = 0; i < message.message.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.message[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.Error
 */
export const Error = new Error$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventResult$Type extends MessageType<EventResult> {
    constructor() {
        super("web_odds.EventResult", [
            { no: 213, name: "current_phase", kind: "message", T: () => EventCurrentPhase },
            { no: 214, name: "current_result", kind: "message", T: () => EventCurrentResult },
            { no: 215, name: "live_result_history", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventLiveResultHistoryElement },
            { no: 216, name: "live_result_stats", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 217, name: "prematch_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 218, name: "live_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 219, name: "confirmed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 78, name: "error", kind: "message", T: () => Error }
        ]);
    }
    create(value?: PartialMessage<EventResult>): EventResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.liveResultHistory = [];
        message.liveResultStats = "";
        message.prematchResult = "";
        message.liveResult = "";
        message.confirmed = false;
        if (value !== undefined)
            reflectionMergePartial<EventResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventResult): EventResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* web_odds.EventCurrentPhase current_phase */ 213:
                    message.currentPhase = EventCurrentPhase.internalBinaryRead(reader, reader.uint32(), options, message.currentPhase);
                    break;
                case /* web_odds.EventCurrentResult current_result */ 214:
                    message.currentResult = EventCurrentResult.internalBinaryRead(reader, reader.uint32(), options, message.currentResult);
                    break;
                case /* repeated web_odds.EventLiveResultHistoryElement live_result_history */ 215:
                    message.liveResultHistory.push(EventLiveResultHistoryElement.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string live_result_stats */ 216:
                    message.liveResultStats = reader.string();
                    break;
                case /* string prematch_result */ 217:
                    message.prematchResult = reader.string();
                    break;
                case /* string live_result */ 218:
                    message.liveResult = reader.string();
                    break;
                case /* bool confirmed */ 219:
                    message.confirmed = reader.bool();
                    break;
                case /* web_odds.Error error */ 78:
                    message.error = Error.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* web_odds.EventCurrentPhase current_phase = 213; */
        if (message.currentPhase)
            EventCurrentPhase.internalBinaryWrite(message.currentPhase, writer.tag(213, WireType.LengthDelimited).fork(), options).join();
        /* web_odds.EventCurrentResult current_result = 214; */
        if (message.currentResult)
            EventCurrentResult.internalBinaryWrite(message.currentResult, writer.tag(214, WireType.LengthDelimited).fork(), options).join();
        /* repeated web_odds.EventLiveResultHistoryElement live_result_history = 215; */
        for (let i = 0; i < message.liveResultHistory.length; i++)
            EventLiveResultHistoryElement.internalBinaryWrite(message.liveResultHistory[i], writer.tag(215, WireType.LengthDelimited).fork(), options).join();
        /* string live_result_stats = 216; */
        if (message.liveResultStats !== "")
            writer.tag(216, WireType.LengthDelimited).string(message.liveResultStats);
        /* string prematch_result = 217; */
        if (message.prematchResult !== "")
            writer.tag(217, WireType.LengthDelimited).string(message.prematchResult);
        /* string live_result = 218; */
        if (message.liveResult !== "")
            writer.tag(218, WireType.LengthDelimited).string(message.liveResult);
        /* bool confirmed = 219; */
        if (message.confirmed !== false)
            writer.tag(219, WireType.Varint).bool(message.confirmed);
        /* web_odds.Error error = 78; */
        if (message.error)
            Error.internalBinaryWrite(message.error, writer.tag(78, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.EventResult
 */
export const EventResult = new EventResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebStreamEvent$Type extends MessageType<WebStreamEvent> {
    constructor() {
        super("web_odds.WebStreamEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WebStreamEventOdd },
            { no: 7, name: "start", kind: "message", T: () => Timestamp },
            { no: 8, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "result", kind: "message", T: () => EventResult },
            { no: 10, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "outright_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "mon", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "number_of_odds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "exefeed_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "profile", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "betradar_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "betradar_stream_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WebStreamEvent>): WebStreamEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.competitionId = 0;
        message.locationId = 0;
        message.sportId = 0;
        message.odds = [];
        message.isDisabled = false;
        message.landbaseCode = "";
        message.type = "";
        message.outrightType = "";
        message.mon = 0;
        message.numberOfOdds = 0;
        message.exefeedId = 0;
        message.profile = "";
        message.betradarId = "";
        message.betradarStreamId = "";
        if (value !== undefined)
            reflectionMergePartial<WebStreamEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebStreamEvent): WebStreamEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 competition_id */ 3:
                    message.competitionId = reader.int32();
                    break;
                case /* int32 location_id */ 4:
                    message.locationId = reader.int32();
                    break;
                case /* int32 sport_id */ 5:
                    message.sportId = reader.int32();
                    break;
                case /* repeated web_odds.WebStreamEventOdd odds */ 6:
                    message.odds.push(WebStreamEventOdd.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp start */ 7:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* bool is_disabled */ 8:
                    message.isDisabled = reader.bool();
                    break;
                case /* web_odds.EventResult result */ 9:
                    message.result = EventResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                case /* string landbase_code */ 10:
                    message.landbaseCode = reader.string();
                    break;
                case /* string type */ 11:
                    message.type = reader.string();
                    break;
                case /* string outright_type */ 12:
                    message.outrightType = reader.string();
                    break;
                case /* int32 mon */ 13:
                    message.mon = reader.int32();
                    break;
                case /* int32 number_of_odds */ 14:
                    message.numberOfOdds = reader.int32();
                    break;
                case /* int32 exefeed_id */ 15:
                    message.exefeedId = reader.int32();
                    break;
                case /* string profile */ 16:
                    message.profile = reader.string();
                    break;
                case /* string betradar_id */ 17:
                    message.betradarId = reader.string();
                    break;
                case /* string betradar_stream_id */ 18:
                    message.betradarStreamId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebStreamEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 competition_id = 3; */
        if (message.competitionId !== 0)
            writer.tag(3, WireType.Varint).int32(message.competitionId);
        /* int32 location_id = 4; */
        if (message.locationId !== 0)
            writer.tag(4, WireType.Varint).int32(message.locationId);
        /* int32 sport_id = 5; */
        if (message.sportId !== 0)
            writer.tag(5, WireType.Varint).int32(message.sportId);
        /* repeated web_odds.WebStreamEventOdd odds = 6; */
        for (let i = 0; i < message.odds.length; i++)
            WebStreamEventOdd.internalBinaryWrite(message.odds[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp start = 7; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bool is_disabled = 8; */
        if (message.isDisabled !== false)
            writer.tag(8, WireType.Varint).bool(message.isDisabled);
        /* web_odds.EventResult result = 9; */
        if (message.result)
            EventResult.internalBinaryWrite(message.result, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string landbase_code = 10; */
        if (message.landbaseCode !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.landbaseCode);
        /* string type = 11; */
        if (message.type !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.type);
        /* string outright_type = 12; */
        if (message.outrightType !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.outrightType);
        /* int32 mon = 13; */
        if (message.mon !== 0)
            writer.tag(13, WireType.Varint).int32(message.mon);
        /* int32 number_of_odds = 14; */
        if (message.numberOfOdds !== 0)
            writer.tag(14, WireType.Varint).int32(message.numberOfOdds);
        /* int32 exefeed_id = 15; */
        if (message.exefeedId !== 0)
            writer.tag(15, WireType.Varint).int32(message.exefeedId);
        /* string profile = 16; */
        if (message.profile !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.profile);
        /* string betradar_id = 17; */
        if (message.betradarId !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.betradarId);
        /* string betradar_stream_id = 18; */
        if (message.betradarStreamId !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.betradarStreamId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.WebStreamEvent
 */
export const WebStreamEvent = new WebStreamEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WebStreamEventOdd$Type extends MessageType<WebStreamEventOdd> {
    constructor() {
        super("web_odds.WebStreamEventOdd", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "frame_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "current_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "live_player", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WebStreamEventOdd>): WebStreamEventOdd {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.value = 0;
        message.outcomeId = 0;
        message.limit = 0;
        message.frameNumber = 0;
        message.currentResult = "";
        message.isDisabled = false;
        message.status = 0;
        message.livePlayer = "";
        if (value !== undefined)
            reflectionMergePartial<WebStreamEventOdd>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WebStreamEventOdd): WebStreamEventOdd {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double value */ 2:
                    message.value = reader.double();
                    break;
                case /* int32 outcome_id */ 3:
                    message.outcomeId = reader.int32();
                    break;
                case /* double limit */ 4:
                    message.limit = reader.double();
                    break;
                case /* int32 frame_number */ 5:
                    message.frameNumber = reader.int32();
                    break;
                case /* string current_result */ 6:
                    message.currentResult = reader.string();
                    break;
                case /* bool is_disabled */ 7:
                    message.isDisabled = reader.bool();
                    break;
                case /* int32 status */ 8:
                    message.status = reader.int32();
                    break;
                case /* string live_player */ 9:
                    message.livePlayer = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WebStreamEventOdd, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double value = 2; */
        if (message.value !== 0)
            writer.tag(2, WireType.Bit64).double(message.value);
        /* int32 outcome_id = 3; */
        if (message.outcomeId !== 0)
            writer.tag(3, WireType.Varint).int32(message.outcomeId);
        /* double limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Bit64).double(message.limit);
        /* int32 frame_number = 5; */
        if (message.frameNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.frameNumber);
        /* string current_result = 6; */
        if (message.currentResult !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.currentResult);
        /* bool is_disabled = 7; */
        if (message.isDisabled !== false)
            writer.tag(7, WireType.Varint).bool(message.isDisabled);
        /* int32 status = 8; */
        if (message.status !== 0)
            writer.tag(8, WireType.Varint).int32(message.status);
        /* string live_player = 9; */
        if (message.livePlayer !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.livePlayer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.WebStreamEventOdd
 */
export const WebStreamEventOdd = new WebStreamEventOdd$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventRequest$Type extends MessageType<EventRequest> {
    constructor() {
        super("web_odds.EventRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EventRequest>): EventRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<EventRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventRequest): EventRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.EventRequest
 */
export const EventRequest = new EventRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamEventOdd$Type extends MessageType<StreamEventOdd> {
    constructor() {
        super("web_odds.StreamEventOdd", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 11, name: "outcome_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 122, name: "limit", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 238, name: "frame_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 239, name: "current_result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 136, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "live_player", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StreamEventOdd>): StreamEventOdd {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.value = 0;
        message.outcomeId = 0;
        message.limit = 0;
        message.frameNumber = 0;
        message.currentResult = "";
        message.isDisabled = false;
        message.livePlayer = "";
        if (value !== undefined)
            reflectionMergePartial<StreamEventOdd>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StreamEventOdd): StreamEventOdd {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double value */ 8:
                    message.value = reader.double();
                    break;
                case /* int32 outcome_id */ 11:
                    message.outcomeId = reader.int32();
                    break;
                case /* double limit */ 122:
                    message.limit = reader.double();
                    break;
                case /* int32 frame_number */ 238:
                    message.frameNumber = reader.int32();
                    break;
                case /* string current_result */ 239:
                    message.currentResult = reader.string();
                    break;
                case /* bool is_disabled */ 136:
                    message.isDisabled = reader.bool();
                    break;
                case /* string live_player */ 9:
                    message.livePlayer = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StreamEventOdd, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double value = 8; */
        if (message.value !== 0)
            writer.tag(8, WireType.Bit64).double(message.value);
        /* int32 outcome_id = 11; */
        if (message.outcomeId !== 0)
            writer.tag(11, WireType.Varint).int32(message.outcomeId);
        /* double limit = 122; */
        if (message.limit !== 0)
            writer.tag(122, WireType.Bit64).double(message.limit);
        /* int32 frame_number = 238; */
        if (message.frameNumber !== 0)
            writer.tag(238, WireType.Varint).int32(message.frameNumber);
        /* string current_result = 239; */
        if (message.currentResult !== "")
            writer.tag(239, WireType.LengthDelimited).string(message.currentResult);
        /* bool is_disabled = 136; */
        if (message.isDisabled !== false)
            writer.tag(136, WireType.Varint).bool(message.isDisabled);
        /* string live_player = 9; */
        if (message.livePlayer !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.livePlayer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.StreamEventOdd
 */
export const StreamEventOdd = new StreamEventOdd$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamEvent$Type extends MessageType<StreamEvent> {
    constructor() {
        super("web_odds.StreamEvent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "note", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "competition_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "location_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "odds", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StreamEventOdd },
            { no: 8, name: "start", kind: "message", T: () => Timestamp },
            { no: 9, name: "is_disabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "is_suspicious", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "is_single_approval", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "profile", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "current_phase", kind: "message", T: () => EventCurrentPhase },
            { no: 14, name: "landbase_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "outright_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "mon", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "result", kind: "message", T: () => EventResult }
        ]);
    }
    create(value?: PartialMessage<StreamEvent>): StreamEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.note = "";
        message.competitionId = 0;
        message.locationId = 0;
        message.sportId = 0;
        message.odds = [];
        message.isDisabled = false;
        message.isSuspicious = false;
        message.isSingleApproval = false;
        message.profile = "";
        message.landbaseCode = "";
        message.type = "";
        message.outrightType = "";
        message.mon = 0;
        if (value !== undefined)
            reflectionMergePartial<StreamEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StreamEvent): StreamEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string note */ 3:
                    message.note = reader.string();
                    break;
                case /* int32 competition_id */ 4:
                    message.competitionId = reader.int32();
                    break;
                case /* int32 location_id */ 5:
                    message.locationId = reader.int32();
                    break;
                case /* int32 sport_id */ 6:
                    message.sportId = reader.int32();
                    break;
                case /* repeated web_odds.StreamEventOdd odds */ 7:
                    message.odds.push(StreamEventOdd.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp start */ 8:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* bool is_disabled */ 9:
                    message.isDisabled = reader.bool();
                    break;
                case /* bool is_suspicious */ 10:
                    message.isSuspicious = reader.bool();
                    break;
                case /* bool is_single_approval */ 11:
                    message.isSingleApproval = reader.bool();
                    break;
                case /* string profile */ 12:
                    message.profile = reader.string();
                    break;
                case /* web_odds.EventCurrentPhase current_phase */ 13:
                    message.currentPhase = EventCurrentPhase.internalBinaryRead(reader, reader.uint32(), options, message.currentPhase);
                    break;
                case /* string landbase_code */ 14:
                    message.landbaseCode = reader.string();
                    break;
                case /* string type */ 15:
                    message.type = reader.string();
                    break;
                case /* string outright_type */ 16:
                    message.outrightType = reader.string();
                    break;
                case /* int32 mon */ 17:
                    message.mon = reader.int32();
                    break;
                case /* web_odds.EventResult result */ 18:
                    message.result = EventResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StreamEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string note = 3; */
        if (message.note !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.note);
        /* int32 competition_id = 4; */
        if (message.competitionId !== 0)
            writer.tag(4, WireType.Varint).int32(message.competitionId);
        /* int32 location_id = 5; */
        if (message.locationId !== 0)
            writer.tag(5, WireType.Varint).int32(message.locationId);
        /* int32 sport_id = 6; */
        if (message.sportId !== 0)
            writer.tag(6, WireType.Varint).int32(message.sportId);
        /* repeated web_odds.StreamEventOdd odds = 7; */
        for (let i = 0; i < message.odds.length; i++)
            StreamEventOdd.internalBinaryWrite(message.odds[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp start = 8; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* bool is_disabled = 9; */
        if (message.isDisabled !== false)
            writer.tag(9, WireType.Varint).bool(message.isDisabled);
        /* bool is_suspicious = 10; */
        if (message.isSuspicious !== false)
            writer.tag(10, WireType.Varint).bool(message.isSuspicious);
        /* bool is_single_approval = 11; */
        if (message.isSingleApproval !== false)
            writer.tag(11, WireType.Varint).bool(message.isSingleApproval);
        /* string profile = 12; */
        if (message.profile !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.profile);
        /* web_odds.EventCurrentPhase current_phase = 13; */
        if (message.currentPhase)
            EventCurrentPhase.internalBinaryWrite(message.currentPhase, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* string landbase_code = 14; */
        if (message.landbaseCode !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.landbaseCode);
        /* string type = 15; */
        if (message.type !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.type);
        /* string outright_type = 16; */
        if (message.outrightType !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.outrightType);
        /* int32 mon = 17; */
        if (message.mon !== 0)
            writer.tag(17, WireType.Varint).int32(message.mon);
        /* web_odds.EventResult result = 18; */
        if (message.result)
            EventResult.internalBinaryWrite(message.result, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.StreamEvent
 */
export const StreamEvent = new StreamEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LiveDisabled$Type extends MessageType<LiveDisabled> {
    constructor() {
        super("web_odds.LiveDisabled", [
            { no: 1, name: "all", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "sport_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<LiveDisabled>): LiveDisabled {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.all = false;
        message.sportIds = [];
        if (value !== undefined)
            reflectionMergePartial<LiveDisabled>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LiveDisabled): LiveDisabled {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool all */ 1:
                    message.all = reader.bool();
                    break;
                case /* repeated int32 sport_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.sportIds.push(reader.int32());
                    else
                        message.sportIds.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LiveDisabled, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool all = 1; */
        if (message.all !== false)
            writer.tag(1, WireType.Varint).bool(message.all);
        /* repeated int32 sport_ids = 2; */
        if (message.sportIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.sportIds.length; i++)
                writer.int32(message.sportIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.LiveDisabled
 */
export const LiveDisabled = new LiveDisabled$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlayerOutrightMarkets$Type extends MessageType<PlayerOutrightMarkets> {
    constructor() {
        super("web_odds.PlayerOutrightMarkets", [
            { no: 1, name: "sport_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "outright_markets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialMarket },
            { no: 3, name: "player_markets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InitialMarket }
        ]);
    }
    create(value?: PartialMessage<PlayerOutrightMarkets>): PlayerOutrightMarkets {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sportId = 0;
        message.outrightMarkets = [];
        message.playerMarkets = [];
        if (value !== undefined)
            reflectionMergePartial<PlayerOutrightMarkets>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlayerOutrightMarkets): PlayerOutrightMarkets {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sport_id */ 1:
                    message.sportId = reader.int32();
                    break;
                case /* repeated web_odds.InitialMarket outright_markets */ 2:
                    message.outrightMarkets.push(InitialMarket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated web_odds.InitialMarket player_markets */ 3:
                    message.playerMarkets.push(InitialMarket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlayerOutrightMarkets, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 sport_id = 1; */
        if (message.sportId !== 0)
            writer.tag(1, WireType.Varint).int32(message.sportId);
        /* repeated web_odds.InitialMarket outright_markets = 2; */
        for (let i = 0; i < message.outrightMarkets.length; i++)
            InitialMarket.internalBinaryWrite(message.outrightMarkets[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated web_odds.InitialMarket player_markets = 3; */
        for (let i = 0; i < message.playerMarkets.length; i++)
            InitialMarket.internalBinaryWrite(message.playerMarkets[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message web_odds.PlayerOutrightMarkets
 */
export const PlayerOutrightMarkets = new PlayerOutrightMarkets$Type();
/**
 * @generated ServiceType for protobuf service web_odds.OddsCacheService
 */
export const OddsCacheService = new ServiceType("web_odds.OddsCacheService", [
    { name: "GetInitialData", options: {}, I: InitialDataRequest, O: InitialDataResponse },
    { name: "GetInitialDataMarkets", options: {}, I: InitialDataRequest, O: InitialDataMarketsMap },
    { name: "GetInitialDataSportsMap", options: {}, I: InitialDataRequest, O: InitialDataSportsMap },
    { name: "GetInitialDataDeltaMap", options: {}, I: InitialDataRequest, O: InitialDataSportsMap },
    { name: "GetSingleEvent", options: {}, I: WebStreamEvent, O: WebStreamEvent },
    { name: "GetLiveDisabled", options: {}, I: Empty, O: LiveDisabled },
    { name: "GetPlayerOutrightMarkets", options: {}, I: Empty, O: PlayerOutrightMarkets },
    { name: "GetMissingLiveEvent", options: {}, I: EventRequest, O: StreamEvent },
    { name: "GetMissingPrematchEvent", options: {}, I: EventRequest, O: StreamEvent },
    { name: "GetOfferCounter", options: {}, I: CounterRequest, O: CounterMap }
]);
