/* React modules */

/* Our modules */
import { ApprovalStatuses } from 'modules/ticket/ticket.types';
import { NotificationsAPI } from 'modules/notifications/notifications.api';
import { hasProperty } from 'libs/common-helpers';

/* 3rd Party modules */

type ApprovalStatusesKeys = `${ApprovalStatuses}` | 'cashdrop';

type NotificationHandlers = {
  [key in ApprovalStatusesKeys]?: (data: any) => Promise<void>;
};

class NotificationsService {
  public handlers: NotificationHandlers;

  private api: NotificationsAPI;

  constructor(handlers: NotificationHandlers, token: string) {
    this.handlers = handlers;

    this.api = new NotificationsAPI();
    this.api.connect(this.onMessage.bind(this), token);
  }

  onMessage(message: any) {
    const { type } = message;

    if (this.handlers && type && hasProperty(this.handlers, type)) {
      const notificationHandler = this.handlers[type as ApprovalStatusesKeys];

      if (notificationHandler) {
        notificationHandler(message);
      }
    }
  }

  disconnect() {
    this.api.disconnect();
  }
}

export { NotificationsService };
