// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/cache_service/cache.proto" (package "web_odds", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { OddsCacheService } from "./cache_pb";
import type { CounterMap } from "./cache_pb";
import type { CounterRequest } from "./cache_pb";
import type { StreamEvent } from "./cache_pb";
import type { EventRequest } from "./cache_pb";
import type { PlayerOutrightMarkets } from "./cache_pb";
import type { LiveDisabled } from "./cache_pb";
import type { Empty } from "../../google/protobuf/empty_pb";
import type { WebStreamEvent } from "./cache_pb";
import type { InitialDataSportsMap } from "./cache_pb";
import type { InitialDataMarketsMap } from "./cache_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { InitialDataResponse } from "./cache_pb";
import type { InitialDataRequest } from "./cache_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service web_odds.OddsCacheService
 */
export interface IOddsCacheServiceClient {
    /**
     * @generated from protobuf rpc: GetInitialData(web_odds.InitialDataRequest) returns (web_odds.InitialDataResponse);
     */
    getInitialData(input: InitialDataRequest, options?: RpcOptions): UnaryCall<InitialDataRequest, InitialDataResponse>;
    /**
     * @generated from protobuf rpc: GetInitialDataMarkets(web_odds.InitialDataRequest) returns (web_odds.InitialDataMarketsMap);
     */
    getInitialDataMarkets(input: InitialDataRequest, options?: RpcOptions): UnaryCall<InitialDataRequest, InitialDataMarketsMap>;
    /**
     * @generated from protobuf rpc: GetInitialDataSportsMap(web_odds.InitialDataRequest) returns (web_odds.InitialDataSportsMap);
     */
    getInitialDataSportsMap(input: InitialDataRequest, options?: RpcOptions): UnaryCall<InitialDataRequest, InitialDataSportsMap>;
    /**
     * @generated from protobuf rpc: GetInitialDataDeltaMap(web_odds.InitialDataRequest) returns (web_odds.InitialDataSportsMap);
     */
    getInitialDataDeltaMap(input: InitialDataRequest, options?: RpcOptions): UnaryCall<InitialDataRequest, InitialDataSportsMap>;
    /**
     * @generated from protobuf rpc: GetSingleEvent(web_odds.WebStreamEvent) returns (web_odds.WebStreamEvent);
     */
    getSingleEvent(input: WebStreamEvent, options?: RpcOptions): UnaryCall<WebStreamEvent, WebStreamEvent>;
    /**
     * @generated from protobuf rpc: GetLiveDisabled(google.protobuf.Empty) returns (web_odds.LiveDisabled);
     */
    getLiveDisabled(input: Empty, options?: RpcOptions): UnaryCall<Empty, LiveDisabled>;
    /**
     * @generated from protobuf rpc: GetPlayerOutrightMarkets(google.protobuf.Empty) returns (web_odds.PlayerOutrightMarkets);
     */
    getPlayerOutrightMarkets(input: Empty, options?: RpcOptions): UnaryCall<Empty, PlayerOutrightMarkets>;
    /**
     * @generated from protobuf rpc: GetMissingLiveEvent(web_odds.EventRequest) returns (web_odds.StreamEvent);
     */
    getMissingLiveEvent(input: EventRequest, options?: RpcOptions): UnaryCall<EventRequest, StreamEvent>;
    /**
     * @generated from protobuf rpc: GetMissingPrematchEvent(web_odds.EventRequest) returns (web_odds.StreamEvent);
     */
    getMissingPrematchEvent(input: EventRequest, options?: RpcOptions): UnaryCall<EventRequest, StreamEvent>;
    /**
     * @generated from protobuf rpc: GetOfferCounter(web_odds.CounterRequest) returns (web_odds.CounterMap);
     */
    getOfferCounter(input: CounterRequest, options?: RpcOptions): UnaryCall<CounterRequest, CounterMap>;
}
/**
 * @generated from protobuf service web_odds.OddsCacheService
 */
export class OddsCacheServiceClient implements IOddsCacheServiceClient, ServiceInfo {
    typeName = OddsCacheService.typeName;
    methods = OddsCacheService.methods;
    options = OddsCacheService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetInitialData(web_odds.InitialDataRequest) returns (web_odds.InitialDataResponse);
     */
    getInitialData(input: InitialDataRequest, options?: RpcOptions): UnaryCall<InitialDataRequest, InitialDataResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<InitialDataRequest, InitialDataResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInitialDataMarkets(web_odds.InitialDataRequest) returns (web_odds.InitialDataMarketsMap);
     */
    getInitialDataMarkets(input: InitialDataRequest, options?: RpcOptions): UnaryCall<InitialDataRequest, InitialDataMarketsMap> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<InitialDataRequest, InitialDataMarketsMap>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInitialDataSportsMap(web_odds.InitialDataRequest) returns (web_odds.InitialDataSportsMap);
     */
    getInitialDataSportsMap(input: InitialDataRequest, options?: RpcOptions): UnaryCall<InitialDataRequest, InitialDataSportsMap> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<InitialDataRequest, InitialDataSportsMap>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInitialDataDeltaMap(web_odds.InitialDataRequest) returns (web_odds.InitialDataSportsMap);
     */
    getInitialDataDeltaMap(input: InitialDataRequest, options?: RpcOptions): UnaryCall<InitialDataRequest, InitialDataSportsMap> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<InitialDataRequest, InitialDataSportsMap>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSingleEvent(web_odds.WebStreamEvent) returns (web_odds.WebStreamEvent);
     */
    getSingleEvent(input: WebStreamEvent, options?: RpcOptions): UnaryCall<WebStreamEvent, WebStreamEvent> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<WebStreamEvent, WebStreamEvent>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLiveDisabled(google.protobuf.Empty) returns (web_odds.LiveDisabled);
     */
    getLiveDisabled(input: Empty, options?: RpcOptions): UnaryCall<Empty, LiveDisabled> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, LiveDisabled>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPlayerOutrightMarkets(google.protobuf.Empty) returns (web_odds.PlayerOutrightMarkets);
     */
    getPlayerOutrightMarkets(input: Empty, options?: RpcOptions): UnaryCall<Empty, PlayerOutrightMarkets> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, PlayerOutrightMarkets>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMissingLiveEvent(web_odds.EventRequest) returns (web_odds.StreamEvent);
     */
    getMissingLiveEvent(input: EventRequest, options?: RpcOptions): UnaryCall<EventRequest, StreamEvent> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<EventRequest, StreamEvent>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMissingPrematchEvent(web_odds.EventRequest) returns (web_odds.StreamEvent);
     */
    getMissingPrematchEvent(input: EventRequest, options?: RpcOptions): UnaryCall<EventRequest, StreamEvent> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<EventRequest, StreamEvent>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOfferCounter(web_odds.CounterRequest) returns (web_odds.CounterMap);
     */
    getOfferCounter(input: CounterRequest, options?: RpcOptions): UnaryCall<CounterRequest, CounterMap> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<CounterRequest, CounterMap>("unary", this._transport, method, opt, input);
    }
}
