// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/slip/web_slip.proto" (package "web_slip", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { WebSlipService } from "./web_slip_pb";
import type { GetTaxThresholdConfigurationResponseWeb } from "./web_slip_pb";
import type { FastSlips } from "./web_slip_pb";
import type { FastSlip } from "./web_slip_pb";
import type { FastSlipRequest } from "../common/common_pb";
import type { BonusConfig } from "./web_slip_pb";
import type { CalculatedValues } from "./web_slip_pb";
import type { PaginatedSlips } from "./web_slip_pb";
import type { GetUserSlipsRequest } from "./web_slip_pb";
import type { Rules } from "./web_slip_pb";
import type { Empty } from "../../google/protobuf/empty_pb";
import type { EmptyError } from "../common/common_pb";
import type { SlipId } from "./web_slip_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Slip } from "../common/common_pb";
import type { SlipRequest } from "../common/common_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Service definitions
 *
 * @generated from protobuf service web_slip.WebSlipService
 */
export interface IWebSlipServiceClient {
    /**
     * Place bet
     *
     * @generated from protobuf rpc: PlaceBetWeb(common.SlipRequest) returns (common.Slip);
     */
    placeBetWeb(input: SlipRequest, options?: RpcOptions): UnaryCall<SlipRequest, Slip>;
    /**
     * @generated from protobuf rpc: PlaceBetLiveWeb(common.SlipRequest) returns (common.Slip);
     */
    placeBetLiveWeb(input: SlipRequest, options?: RpcOptions): UnaryCall<SlipRequest, Slip>;
    /**
     * Approval
     *
     * @generated from protobuf rpc: ManualChangeUserAcceptWeb(web_slip.SlipId) returns (common.EmptyError);
     */
    manualChangeUserAcceptWeb(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, EmptyError>;
    /**
     * @generated from protobuf rpc: ManualChangeUserDenyWeb(web_slip.SlipId) returns (common.EmptyError);
     */
    manualChangeUserDenyWeb(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, EmptyError>;
    /**
     * Cashout
     *
     * @generated from protobuf rpc: SlipSendForCashOutWeb(web_slip.SlipId) returns (common.EmptyError);
     */
    slipSendForCashOutWeb(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, EmptyError>;
    /**
     * @generated from protobuf rpc: SlipCashOutUserAcceptWeb(web_slip.SlipId) returns (common.EmptyError);
     */
    slipCashOutUserAcceptWeb(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, EmptyError>;
    /**
     * @generated from protobuf rpc: SlipCashOutUserDenyWeb(web_slip.SlipId) returns (common.EmptyError);
     */
    slipCashOutUserDenyWeb(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, EmptyError>;
    /**
     * @generated from protobuf rpc: SlipCashOutUserCancel(web_slip.SlipId) returns (common.EmptyError);
     */
    slipCashOutUserCancel(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, EmptyError>;
    /**
     * Get data
     *
     * @generated from protobuf rpc: GetValidationRulesWeb(google.protobuf.Empty) returns (web_slip.Rules);
     */
    getValidationRulesWeb(input: Empty, options?: RpcOptions): UnaryCall<Empty, Rules>;
    /**
     * @generated from protobuf rpc: GetUserSlipsWeb(web_slip.GetUserSlipsRequest) returns (web_slip.PaginatedSlips);
     */
    getUserSlipsWeb(input: GetUserSlipsRequest, options?: RpcOptions): UnaryCall<GetUserSlipsRequest, PaginatedSlips>;
    /**
     * @generated from protobuf rpc: GetSingleSlipWeb(web_slip.SlipId) returns (common.Slip);
     */
    getSingleSlipWeb(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, Slip>;
    /**
     * @generated from protobuf rpc: GetSharedSlip(web_slip.SlipId) returns (common.Slip);
     */
    getSharedSlip(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, Slip>;
    /**
     * @generated from protobuf rpc: GetTopWinningSlips(google.protobuf.Empty) returns (web_slip.PaginatedSlips);
     */
    getTopWinningSlips(input: Empty, options?: RpcOptions): UnaryCall<Empty, PaginatedSlips>;
    /**
     * @generated from protobuf rpc: CalculateSlipValuesWeb(common.SlipRequest) returns (web_slip.CalculatedValues);
     */
    calculateSlipValuesWeb(input: SlipRequest, options?: RpcOptions): UnaryCall<SlipRequest, CalculatedValues>;
    /**
     * @generated from protobuf rpc: ScanSlip(web_slip.SlipId) returns (common.Slip);
     */
    scanSlip(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, Slip>;
    /**
     * @generated from protobuf rpc: GetBonusConfig(google.protobuf.Empty) returns (web_slip.BonusConfig);
     */
    getBonusConfig(input: Empty, options?: RpcOptions): UnaryCall<Empty, BonusConfig>;
    /**
     * FastSlip
     *
     * @generated from protobuf rpc: CreateFastSlip(common.FastSlipRequest) returns (web_slip.FastSlip);
     */
    createFastSlip(input: FastSlipRequest, options?: RpcOptions): UnaryCall<FastSlipRequest, FastSlip>;
    /**
     * @generated from protobuf rpc: CreateFastSlipUser(common.FastSlipRequest) returns (web_slip.FastSlip);
     */
    createFastSlipUser(input: FastSlipRequest, options?: RpcOptions): UnaryCall<FastSlipRequest, FastSlip>;
    /**
     * @generated from protobuf rpc: GetFastSlipsForUser(google.protobuf.Empty) returns (web_slip.FastSlips);
     */
    getFastSlipsForUser(input: Empty, options?: RpcOptions): UnaryCall<Empty, FastSlips>;
    /**
     * @generated from protobuf rpc: GetFastSlipWeb(web_slip.FastSlip) returns (common.Slip);
     */
    getFastSlipWeb(input: FastSlip, options?: RpcOptions): UnaryCall<FastSlip, Slip>;
    /**
     * Tax threshold
     *
     * @generated from protobuf rpc: GetTaxThresholdConfigurationWeb(google.protobuf.Empty) returns (web_slip.GetTaxThresholdConfigurationResponseWeb);
     */
    getTaxThresholdConfigurationWeb(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetTaxThresholdConfigurationResponseWeb>;
}
/**
 * Service definitions
 *
 * @generated from protobuf service web_slip.WebSlipService
 */
export class WebSlipServiceClient implements IWebSlipServiceClient, ServiceInfo {
    typeName = WebSlipService.typeName;
    methods = WebSlipService.methods;
    options = WebSlipService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Place bet
     *
     * @generated from protobuf rpc: PlaceBetWeb(common.SlipRequest) returns (common.Slip);
     */
    placeBetWeb(input: SlipRequest, options?: RpcOptions): UnaryCall<SlipRequest, Slip> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<SlipRequest, Slip>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PlaceBetLiveWeb(common.SlipRequest) returns (common.Slip);
     */
    placeBetLiveWeb(input: SlipRequest, options?: RpcOptions): UnaryCall<SlipRequest, Slip> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<SlipRequest, Slip>("unary", this._transport, method, opt, input);
    }
    /**
     * Approval
     *
     * @generated from protobuf rpc: ManualChangeUserAcceptWeb(web_slip.SlipId) returns (common.EmptyError);
     */
    manualChangeUserAcceptWeb(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, EmptyError> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<SlipId, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ManualChangeUserDenyWeb(web_slip.SlipId) returns (common.EmptyError);
     */
    manualChangeUserDenyWeb(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, EmptyError> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<SlipId, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * Cashout
     *
     * @generated from protobuf rpc: SlipSendForCashOutWeb(web_slip.SlipId) returns (common.EmptyError);
     */
    slipSendForCashOutWeb(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, EmptyError> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<SlipId, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SlipCashOutUserAcceptWeb(web_slip.SlipId) returns (common.EmptyError);
     */
    slipCashOutUserAcceptWeb(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, EmptyError> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<SlipId, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SlipCashOutUserDenyWeb(web_slip.SlipId) returns (common.EmptyError);
     */
    slipCashOutUserDenyWeb(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, EmptyError> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<SlipId, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SlipCashOutUserCancel(web_slip.SlipId) returns (common.EmptyError);
     */
    slipCashOutUserCancel(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, EmptyError> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<SlipId, EmptyError>("unary", this._transport, method, opt, input);
    }
    /**
     * Get data
     *
     * @generated from protobuf rpc: GetValidationRulesWeb(google.protobuf.Empty) returns (web_slip.Rules);
     */
    getValidationRulesWeb(input: Empty, options?: RpcOptions): UnaryCall<Empty, Rules> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, Rules>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserSlipsWeb(web_slip.GetUserSlipsRequest) returns (web_slip.PaginatedSlips);
     */
    getUserSlipsWeb(input: GetUserSlipsRequest, options?: RpcOptions): UnaryCall<GetUserSlipsRequest, PaginatedSlips> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserSlipsRequest, PaginatedSlips>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSingleSlipWeb(web_slip.SlipId) returns (common.Slip);
     */
    getSingleSlipWeb(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, Slip> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<SlipId, Slip>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSharedSlip(web_slip.SlipId) returns (common.Slip);
     */
    getSharedSlip(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, Slip> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<SlipId, Slip>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTopWinningSlips(google.protobuf.Empty) returns (web_slip.PaginatedSlips);
     */
    getTopWinningSlips(input: Empty, options?: RpcOptions): UnaryCall<Empty, PaginatedSlips> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, PaginatedSlips>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CalculateSlipValuesWeb(common.SlipRequest) returns (web_slip.CalculatedValues);
     */
    calculateSlipValuesWeb(input: SlipRequest, options?: RpcOptions): UnaryCall<SlipRequest, CalculatedValues> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<SlipRequest, CalculatedValues>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ScanSlip(web_slip.SlipId) returns (common.Slip);
     */
    scanSlip(input: SlipId, options?: RpcOptions): UnaryCall<SlipId, Slip> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<SlipId, Slip>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBonusConfig(google.protobuf.Empty) returns (web_slip.BonusConfig);
     */
    getBonusConfig(input: Empty, options?: RpcOptions): UnaryCall<Empty, BonusConfig> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, BonusConfig>("unary", this._transport, method, opt, input);
    }
    /**
     * FastSlip
     *
     * @generated from protobuf rpc: CreateFastSlip(common.FastSlipRequest) returns (web_slip.FastSlip);
     */
    createFastSlip(input: FastSlipRequest, options?: RpcOptions): UnaryCall<FastSlipRequest, FastSlip> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<FastSlipRequest, FastSlip>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateFastSlipUser(common.FastSlipRequest) returns (web_slip.FastSlip);
     */
    createFastSlipUser(input: FastSlipRequest, options?: RpcOptions): UnaryCall<FastSlipRequest, FastSlip> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<FastSlipRequest, FastSlip>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetFastSlipsForUser(google.protobuf.Empty) returns (web_slip.FastSlips);
     */
    getFastSlipsForUser(input: Empty, options?: RpcOptions): UnaryCall<Empty, FastSlips> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, FastSlips>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetFastSlipWeb(web_slip.FastSlip) returns (common.Slip);
     */
    getFastSlipWeb(input: FastSlip, options?: RpcOptions): UnaryCall<FastSlip, Slip> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<FastSlip, Slip>("unary", this._transport, method, opt, input);
    }
    /**
     * Tax threshold
     *
     * @generated from protobuf rpc: GetTaxThresholdConfigurationWeb(google.protobuf.Empty) returns (web_slip.GetTaxThresholdConfigurationResponseWeb);
     */
    getTaxThresholdConfigurationWeb(input: Empty, options?: RpcOptions): UnaryCall<Empty, GetTaxThresholdConfigurationResponseWeb> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, GetTaxThresholdConfigurationResponseWeb>("unary", this._transport, method, opt, input);
    }
}
