// @generated by protobuf-ts 2.9.4 with parameter output_legacy_commonjs,add_pb_suffix,long_type_number
// @generated from protobuf file "proto/loyalty/cashdrop.proto" (package "cashdrop", syntax proto3)
// tslint:disable
import { CashDropWebService } from "./cashdrop_pb";
import type { WebDrops } from "./cashdrop_pb";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { CashDropBOService } from "./cashdrop_pb";
import type { CashDropDisabled } from "./cashdrop_pb";
import type { Empty } from "../../google/protobuf/empty_pb";
import type { Drop } from "./cashdrop_pb";
import type { Drops } from "./cashdrop_pb";
import type { DropGroup } from "./cashdrop_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { DropGroups } from "./cashdrop_pb";
import type { GetDropGroups } from "./cashdrop_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service cashdrop.CashDropBOService
 */
export interface ICashDropBOServiceClient {
    /**
     * @generated from protobuf rpc: GetCashDropGroups(cashdrop.GetDropGroups) returns (cashdrop.DropGroups);
     */
    getCashDropGroups(input: GetDropGroups, options?: RpcOptions): UnaryCall<GetDropGroups, DropGroups>;
    /**
     * @generated from protobuf rpc: CreateCashDropGroup(cashdrop.DropGroup) returns (cashdrop.DropGroup);
     */
    createCashDropGroup(input: DropGroup, options?: RpcOptions): UnaryCall<DropGroup, DropGroup>;
    /**
     * @generated from protobuf rpc: EditCashDropStart(cashdrop.Drops) returns (cashdrop.Drops);
     */
    editCashDropStart(input: Drops, options?: RpcOptions): UnaryCall<Drops, Drops>;
    /**
     * @generated from protobuf rpc: DisableCashDrop(cashdrop.Drop) returns (cashdrop.Drop);
     */
    disableCashDrop(input: Drop, options?: RpcOptions): UnaryCall<Drop, Drop>;
    /**
     * @generated from protobuf rpc: DisableCashDropGroup(cashdrop.DropGroup) returns (cashdrop.DropGroup);
     */
    disableCashDropGroup(input: DropGroup, options?: RpcOptions): UnaryCall<DropGroup, DropGroup>;
    /**
     * @generated from protobuf rpc: GetCashDropDisabledBO(google.protobuf.Empty) returns (cashdrop.CashDropDisabled);
     */
    getCashDropDisabledBO(input: Empty, options?: RpcOptions): UnaryCall<Empty, CashDropDisabled>;
    /**
     * @generated from protobuf rpc: SetCashDropDisabled(cashdrop.CashDropDisabled) returns (cashdrop.CashDropDisabled);
     */
    setCashDropDisabled(input: CashDropDisabled, options?: RpcOptions): UnaryCall<CashDropDisabled, CashDropDisabled>;
}
/**
 * @generated from protobuf service cashdrop.CashDropBOService
 */
export class CashDropBOServiceClient implements ICashDropBOServiceClient, ServiceInfo {
    typeName = CashDropBOService.typeName;
    methods = CashDropBOService.methods;
    options = CashDropBOService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetCashDropGroups(cashdrop.GetDropGroups) returns (cashdrop.DropGroups);
     */
    getCashDropGroups(input: GetDropGroups, options?: RpcOptions): UnaryCall<GetDropGroups, DropGroups> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetDropGroups, DropGroups>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateCashDropGroup(cashdrop.DropGroup) returns (cashdrop.DropGroup);
     */
    createCashDropGroup(input: DropGroup, options?: RpcOptions): UnaryCall<DropGroup, DropGroup> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<DropGroup, DropGroup>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: EditCashDropStart(cashdrop.Drops) returns (cashdrop.Drops);
     */
    editCashDropStart(input: Drops, options?: RpcOptions): UnaryCall<Drops, Drops> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<Drops, Drops>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DisableCashDrop(cashdrop.Drop) returns (cashdrop.Drop);
     */
    disableCashDrop(input: Drop, options?: RpcOptions): UnaryCall<Drop, Drop> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<Drop, Drop>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DisableCashDropGroup(cashdrop.DropGroup) returns (cashdrop.DropGroup);
     */
    disableCashDropGroup(input: DropGroup, options?: RpcOptions): UnaryCall<DropGroup, DropGroup> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DropGroup, DropGroup>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCashDropDisabledBO(google.protobuf.Empty) returns (cashdrop.CashDropDisabled);
     */
    getCashDropDisabledBO(input: Empty, options?: RpcOptions): UnaryCall<Empty, CashDropDisabled> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, CashDropDisabled>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SetCashDropDisabled(cashdrop.CashDropDisabled) returns (cashdrop.CashDropDisabled);
     */
    setCashDropDisabled(input: CashDropDisabled, options?: RpcOptions): UnaryCall<CashDropDisabled, CashDropDisabled> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<CashDropDisabled, CashDropDisabled>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service cashdrop.CashDropWebService
 */
export interface ICashDropWebServiceClient {
    /**
     * @generated from protobuf rpc: GetDailyDrops(google.protobuf.Empty) returns (cashdrop.WebDrops);
     */
    getDailyDrops(input: Empty, options?: RpcOptions): UnaryCall<Empty, WebDrops>;
    /**
     * @generated from protobuf rpc: Collect(cashdrop.Drop) returns (cashdrop.Drop);
     */
    collect(input: Drop, options?: RpcOptions): UnaryCall<Drop, Drop>;
    /**
     * @generated from protobuf rpc: GetCashDropGlobalDisabled(google.protobuf.Empty) returns (cashdrop.CashDropDisabled);
     */
    getCashDropGlobalDisabled(input: Empty, options?: RpcOptions): UnaryCall<Empty, CashDropDisabled>;
}
/**
 * @generated from protobuf service cashdrop.CashDropWebService
 */
export class CashDropWebServiceClient implements ICashDropWebServiceClient, ServiceInfo {
    typeName = CashDropWebService.typeName;
    methods = CashDropWebService.methods;
    options = CashDropWebService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetDailyDrops(google.protobuf.Empty) returns (cashdrop.WebDrops);
     */
    getDailyDrops(input: Empty, options?: RpcOptions): UnaryCall<Empty, WebDrops> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, WebDrops>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Collect(cashdrop.Drop) returns (cashdrop.Drop);
     */
    collect(input: Drop, options?: RpcOptions): UnaryCall<Drop, Drop> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<Drop, Drop>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCashDropGlobalDisabled(google.protobuf.Empty) returns (cashdrop.CashDropDisabled);
     */
    getCashDropGlobalDisabled(input: Empty, options?: RpcOptions): UnaryCall<Empty, CashDropDisabled> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<Empty, CashDropDisabled>("unary", this._transport, method, opt, input);
    }
}
