enum OrderStatus {
  CANCELED = 'C',
  PENDING = 'P',
  APPROVED = 'A',
  DELIVERED = 'D',
  SUCCESS = 'S',
}

/*
type OrderStatuses =
  | OrderStatus.CANCELED
  | OrderStatus.PENDING
  | OrderStatus.APPROVED
  | OrderStatus.DELIVERED
  | OrderStatus.SUCCESS;
  */

enum OrderError {
  ORDER_ALREADY_EXISTS = 'ANOTHER_ORDER_ALREADY_EXISTS',
  NOT_ENOUGH_POINTS = 'NOT_ENOUGH_POINTS',
  ITEM_NOT_FOUND = 'ITEM_NOT_FOUND',
  ITEM_NOT_AVAILABLE = 'ITEM_NOT_AVAILABLE',
  CANNOT_CANCEL_ORDER = 'CANNOT_CANCEL_ORDER',
  CANNOT_APPROVE_ORDER = 'CANNOT_APPROVE_ORDER',
  CANNOT_DELIVER_ORDER = 'CANNOT_DELIVER_ORDER',
  ORDER_DOES_NOT_EXIST = 'ORDER_DOES_NOT_EXIST',
  CANNOT_ORDER_MIXED_ITEMS = 'CANNOT_ORDER_MIXED_ITEMS',
  NO_ITEMS_IN_ORDER = 'NO_ITEMS_IN_ORDER',
  ZERO_ITEM_QUANTITY = 'ZERO_ITEM_QUANTITY',
  NO_BETTING_PLACE_ID = 'NO_BETTING_PLACE_ID',
  INVALID_CODE = 'INVALID_CODE',
  MISSING_PARAMETAR = 'MISSING_PARAMETAR',
  CANNOT_ORDER_MORE_THAN_ONE_DIGITAL_ITEM_TYPE = 'CANNOT_ORDER_MORE_THAN_ONE_DIGITAL_ITEM_TYPE',
  UNKNOWN_DIGITAL_ITEM_TYPE = 'UNKNOWN_DIGITAL_ITEM_TYPE',
  TRANSACTION_UNSUCESSFUL_TRY_AGAIN = 'TRANSACTION_UNSUCESSFUL_TRY_AGAIN',
}

/*
type OrderErrors =
  | OrderError.ORDER_ALREADY_EXISTS
  | OrderError.NOT_ENOUGH_POINTS
  | OrderError.ITEM_NOT_AVAILABLE
  | OrderError.ITEM_NOT_FOUND
  | OrderError.CANNOT_APPROVE_ORDER
  | OrderError.CANNOT_DELIVER_ORDER
  | OrderError.ORDER_DOES_NOT_EXIST
  | OrderError.CANNOT_ORDER_MIXED_ITEMS
  | OrderError.NO_ITEMS_IN_ORDER
  | OrderError.ZERO_ITEM_QUANTITY
  | OrderError.NO_BETTING_PLACE_ID
  | OrderError.INVALID_CODE
  | OrderError.MISSING_PARAMETAR
  | OrderError.CANNOT_ORDER_MORE_THAN_ONE_DIGITAL_ITEM_TYPE
  | OrderError.UNKNOWN_DIGITAL_ITEM_TYPE
  | OrderError.TRANSACTION_UNSUCESSFUL_TRY_AGAIN
  | OrderError.CANNOT_CANCEL_ORDER;
*/

enum LoyaltyLevel {
  NO_LEVEL = '0',
  EXPERT = '1',
  PROFESSOR = '2',
  DOCTOR = '3',
}

/*
type LoyaltyLevels =
  | LoyaltyLevel.NO_LEVEL
  | LoyaltyLevel.EXPERT
  | LoyaltyLevel.PROFESSOR
  | LoyaltyLevel.DOCTOR;
  */

/*
interface Error {
  // Reference: proto/common/common_pb.d.ts
  grpcCode: number;
  httpCode: number;
  messageList: string[];
}

interface StoreItem {
  // Reference: proto/loyalty/loyalty_pb.d.ts
  id: number;
  name: string;
  description: string;
  price: number;
  imageUrl: string;
  quantity: number;
  reserved: number;
  digital: boolean;
  digitalType: string;
  digitalAmount: number;
  isActive: boolean;
  order: number;
  error?: Error;
}

interface GetStoreItemsResponse {
  // Reference: proto/loyalty/loyalty_pb.d.ts
  itemsList: StoreItem[];
}
*/

interface CartItem {
  id: number;
  title: string;
  desc: string;
  points_amount: number;
}

export { OrderStatus, OrderError, LoyaltyLevel };

export type {
  // OrderStatuses,
  // OrderErrors,
  // LoyaltyLevels,
  // StoreItem,
  // GetStoreItemsResponse,
  CartItem,
};
