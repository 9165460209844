/* React modules */
import { useEffect } from 'react';

/* Our modules */
import useStores from 'common/hooks/useStores';
import { LoaderWrapper } from 'components/Loader';
import './GamblingCasinoModal.scss';

/* 3rd Party modules */
import { observer } from 'mobx-react-lite';

const GamblingCasinoModal = observer(() => {
  const { overlayStore, gamblingStore } = useStores();
  const { gameName } = overlayStore.modalProps;
  const { gameUrl } = gamblingStore;

  // If Effect subscribes to something, the cleanup function should unsubscribe (https://react.dev/learn/synchronizing-with-effects#subscribing-to-events)
  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (
        event.data.command === 'com.egt-bg.reload' ||
        event.data.command === 'com.egt-bg.exit'
      ) {
        overlayStore.closeModal();
      }
    }

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <div className="gambling-casino-modal">
      <div className="gambling-casino-modal__title">{gameName}</div>
      <LoaderWrapper name="play-game">
        <iframe src={gameUrl} title={gameName} />
      </LoaderWrapper>
    </div>
  );
});

export default GamblingCasinoModal;
