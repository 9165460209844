/* React modules */

/* Our modules */
import {
  PlaceBetPayload,
  SlipPayloadGroup,
  SlipPayloadGroupEvent,
  SlipPayloadOdd,
  TicketStatuses,
} from 'modules/ticket/ticket.types';
import { SLIP_FEED_SOCKET_ENDPOINT } from 'modules/ticket/ticket.constants';
import {
  SlipRequest,
  FastSlipRequest,
  SlipGroup,
  FastSlipGroup,
  SlipSubGroup,
  FastSlipSubGroup,
  FastOdd,
} from 'generated-proto/proto/common/common_pb';
import { WebSlipServiceClient } from 'generated-proto/proto/slip/web_slip_pb.client';
import {
  GetUserSlipsRequest,
  SlipId,
  FastSlip,
} from 'generated-proto/proto/slip/web_slip_pb';
import { getRpcTransport, getApiUrl, getSocketUrl } from 'libs/urlBuilder';
import { hasProperty, parseJSONString } from 'libs/common-helpers';

/* 3rd Party modules */
import { Timestamp } from 'generated-proto/google/protobuf/timestamp_pb';
import { Empty } from 'generated-proto/google/protobuf/empty_pb';

class TicketAPI {
  private client: WebSlipServiceClient;
  socket: WebSocket | null = null;

  constructor() {
    this.client = new WebSlipServiceClient(getRpcTransport(getApiUrl()));
  }

  private getTimestamp(date: Date) {
    date.setHours(0, 0, 0, 0);

    const timestamp: Timestamp = {
      seconds: 0,
      nanos: 0,
    };

    timestamp.seconds =
      (date.getTime() + date.getTimezoneOffset() * 60 * 1000) / 1000;

    return timestamp;
  }

  getTickets(
    pagination: { pageSize: number; page: number },
    ticketStatuses: TicketStatuses | TicketStatuses[],
    accessToken: string,
    dateFilter?: [Date, Date],
    id?: string
  ) {
    const [start, end] = dateFilter || [];

    let request: GetUserSlipsRequest = {
      page: 0,
      statuses: [],
      shortUuid: '',
      perPage: 0,
    };

    request = {
      ...request,
      statuses: ticketStatuses as string[],
      page: pagination.page,
      perPage: pagination.pageSize,
    };

    if (id) {
      request = { ...request, shortUuid: id };
    }

    if (start && end) {
      const date = new Date(end);
      const endDate = new Date(date.getTime() + 86400000); // + 1 day in ms

      request = {
        ...request,
        start: this.getTimestamp(start),
        end: this.getTimestamp(endDate),
      };
    }

    return this.client.getUserSlipsWeb(request, { meta: { accessToken } });
  }

  getTicket(id: string, accessToken: string) {
    const request: SlipId = {
      slipId: '',
      shortUuid: id,
      checkCashout: false,
    };

    return this.client.getSingleSlipWeb(request, { meta: { accessToken } });
  }

  getSharedTicket(id: string) {
    const request: SlipId = {
      slipId: id,
      shortUuid: '',
      checkCashout: false,
    };

    return this.client.getSharedSlip(request);
  }

  getQuickTicket(code: string, accessToken: string) {
    const request: FastSlip = {
      userId: 0,
      code,
    };

    return this.client.getFastSlipWeb(request, { meta: { accessToken } });
  }

  sendSlipForCashout(id: string, accessToken: string) {
    const request: SlipId = {
      slipId: id,
      shortUuid: '',
      checkCashout: false,
    };

    return this.client.slipSendForCashOutWeb(request, {
      meta: { accessToken },
    });
  }

  acceptCashout(id: string, accessToken: string) {
    const request: SlipId = {
      slipId: id,
      shortUuid: '',
      checkCashout: false,
    };

    return this.client.slipCashOutUserAcceptWeb(request, {
      meta: { accessToken },
    });
  }

  denyCashout(id: string, accessToken: string) {
    const request: SlipId = {
      slipId: id,
      shortUuid: '',
      checkCashout: false,
    };

    return this.client.slipCashOutUserDenyWeb(request, {
      meta: { accessToken },
    });
  }

  cancelCashout(id: string, accessToken: string) {
    const request: SlipId = {
      slipId: id,
      shortUuid: '',
      checkCashout: false,
    };

    return this.client.slipCashOutUserCancel(request, {
      meta: { accessToken },
    });
  }

  placeBet(
    ticket: PlaceBetPayload,
    accessToken: string,
    isLive: boolean = false
  ) {
    const request: SlipRequest = {
      amount: 0,
      credit: false,
      creditee: '',
      crediteeUuid: '',
      creditNote: '',
      slipGroups: [],
      approvalReason: '',
      walletPrefix: '',
      noLiveChanges: false,
      ssn: '',
      passportNumber: '',
      identificationDocumentCountry: '',
      identificationDocumentType: '',
    };

    const { slip_groups, wallet, wallet_prefix } = ticket;

    const slipGroupsList: SlipGroup[] = [];

    (slip_groups || []).forEach((group: SlipPayloadGroup) => {
      const slipGroup: SlipGroup = {
        type: '',
        subGroups: [],
      };

      const subGroups: SlipSubGroup[] = [];

      (group.events || []).forEach((group: SlipPayloadGroupEvent) => {
        const sub: SlipSubGroup = {
          type: '',
          eventId: '',
          odds: [],
          oddValues: [],
        };

        const odds = (group.odds || []).map((odd: SlipPayloadOdd) => odd.id);

        sub.type = `1/${odds.length}`;
        sub.odds = odds;
        sub.eventId = group.event_id;

        subGroups.push(sub);
      });

      slipGroup.subGroups = subGroups;
      slipGroup.type = group.system;

      slipGroupsList.push(slipGroup);
    });

    request.slipGroups = slipGroupsList;
    request.amount = ticket.amount * 1; // parse to Int and set amount
    request.walletPrefix = wallet || wallet_prefix || '';

    if (hasProperty(ticket, 'shouldAcceptChanges')) {
      request.noLiveChanges = !ticket.shouldAcceptChanges;
    }

    return this.client[isLive ? 'placeBetLiveWeb' : 'placeBetWeb'](request, {
      meta: { accessToken },
    });
  }

  calculateSlipValues(ticket: any, accessToken: string) {
    const request: SlipRequest = {
      amount: 0,
      credit: false,
      creditee: '',
      crediteeUuid: '',
      creditNote: '',
      slipGroups: [],
      approvalReason: '',
      walletPrefix: '',
      noLiveChanges: false,
      ssn: '',
      passportNumber: '',
      identificationDocumentCountry: '',
      identificationDocumentType: '',
    };

    const { slip_groups } = ticket;

    const slipGroupsList: SlipGroup[] = [];

    (slip_groups || []).forEach((group: SlipPayloadGroup) => {
      const slipGroup: SlipGroup = {
        type: '',
        subGroups: [],
      };

      const subGroups: SlipSubGroup[] = [];

      (group.events || []).forEach((group: SlipPayloadGroupEvent) => {
        const sub: SlipSubGroup = {
          type: '',
          eventId: '',
          odds: [],
          oddValues: [],
        };

        const odds = (group.odds || []).map((odd: SlipPayloadOdd) => +odd.id);

        sub.type = `1/${odds.length}`;
        sub.eventId = group.event_id;
        sub.oddValues = odds;

        subGroups.push(sub);
      });

      slipGroup.subGroups = subGroups;
      slipGroup.type = group.system;

      slipGroupsList.push(slipGroup);
    });

    request.slipGroups = slipGroupsList;
    request.amount = ticket.amount * 1;

    return this.client.calculateSlipValuesWeb(request, {
      meta: { accessToken },
    });
  }

  getQuickCode(ticket: any, accessToken: string) {
    const request: FastSlipRequest = {
      amount: 0,
      slipGroups: [],
    };

    const { slip_groups } = ticket;

    const slipGroupsList: FastSlipGroup[] = [];

    (slip_groups || []).forEach((group: SlipPayloadGroup) => {
      const slipGroup: FastSlipGroup = {
        type: '',
        subGroups: [],
      };

      const subGroups: FastSlipSubGroup[] = [];

      (group.events || []).forEach((group: SlipPayloadGroupEvent) => {
        const sub: FastSlipSubGroup = {
          type: '',
          odds: [],
        };

        const slipOdds: FastOdd[] = [];

        (group.odds || []).forEach((odd) => {
          const slipOdd: FastOdd = {
            oddId: 0,
            eventId: '',
          };

          slipOdd.oddId = odd.id;
          slipOdd.eventId = group.event_id;

          slipOdds.push(slipOdd);
        });

        sub.type = `1/${slipOdds.length}`;
        sub.odds = slipOdds;

        subGroups.push(sub);
      });

      slipGroup.subGroups = subGroups;
      slipGroup.type = group.system;

      slipGroupsList.push(slipGroup);
    });

    request.slipGroups = slipGroupsList;
    request.amount = ticket.amount * 1; // parse to Int and set amount

    return this.client[accessToken ? 'createFastSlipUser' : 'createFastSlip'](
      request,
      accessToken
        ? {
            meta: { accessToken },
          }
        : {}
    );
  }

  getUserQuickCodes(accessToken: string) {
    const request: Empty = {};

    return this.client.getFastSlipsForUser(request, {
      meta: { accessToken },
    });
  }

  getValidationRules() {
    const request: Empty = {};

    return this.client.getValidationRulesWeb(request);
  }

  getTopWinningTickets() {
    const request: Empty = {};

    return this.client.getTopWinningSlips(request);
  }

  getBonusConfig() {
    const request: Empty = {};

    return this.client.getBonusConfig(request);
  }

  acceptTicketChanges(slipId: string, accessToken: string) {
    const request: SlipId = {
      slipId,
      shortUuid: '',
      checkCashout: false,
    };

    return this.client.manualChangeUserAcceptWeb(request, {
      meta: { accessToken },
    });
  }

  denyTicketChanges(slipId: string, accessToken: string) {
    const request: SlipId = {
      slipId,
      shortUuid: '',
      checkCashout: false,
    };

    return this.client.manualChangeUserDenyWeb(request, {
      meta: { accessToken },
    });
  }

  listenForApprovalChanges(
    token: string | null,
    onApprovalStatusChange: (status: string, slipId: string) => void
  ) {
    if (!token) {
      console.error('Cannot open socket because token is not provided');
    }

    const url = getSocketUrl(`${SLIP_FEED_SOCKET_ENDPOINT}/${token}/`);
    this.socket = new WebSocket(url);

    this.socket.addEventListener('message', ({ data }: any) => {
      const parsedData = parseJSONString(data);

      if (parsedData) {
        const { type, message } = parsedData;

        if (type && message && message.id) {
          onApprovalStatusChange(type, message.id);
        }
      }
    });
  }

  closeSocket() {
    if (this.socket) {
      this.socket.close();
    }
  }

  getTaxThresholdConfiguration(accessToken: string) {
    const request: Empty = {};

    return this.client.getTaxThresholdConfigurationWeb(request, {
      meta: { accessToken },
    });
  }
}

export { TicketAPI };
