/* React modules */
import { useEffect } from 'react';

/* Our modules */
import { EVENT_TYPE } from 'modules/sports/sports.types';
import useStores from 'common/hooks/useStores';
import useHighlight from 'modules/offer/ui/Offer/OddCell/use-highlight';
import { Icon } from 'components';
import './OddCell.scss';

/* 3rd Party modules */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import Tooltip from 'rc-tooltip';

const OddCell = observer(({ event, odd }: any) => {
  const {
    ticketBuilder,
    ticketValidator,
    offerStore: { liveDisabled, disabledSports },
  } = useStores();

  const { isUp, isDown } = useHighlight(odd);
  const { disabled, type, sportId } = event;

  const isSuspended = () => {
    if (type === EVENT_TYPE.LIVE) {
      return (
        odd.isDisabled ||
        disabled ||
        liveDisabled ||
        disabledSports.includes(sportId)
      );
    }

    return odd.isDisabled || disabled;
  };

  /** Start listening for odd updates in live feed */
  useEffect(() => {
    odd.connectToFeed();

    return () => {
      if (!odd.isSelected) {
        odd.disconnectFromFeed();
      }
    };
  }, [odd]);

  const handleSelect = () => {
    if (
      ticketValidator?.displayError?.key ===
        'validations.max-number-of-events' &&
      !odd.isSelected
    )
      return;
    if (!isSuspended() || odd.isSelected) {
      ticketBuilder.onOddSelected({ ...event, odd });
    }
  };

  const classes = {
    down: !isSuspended() && isDown(),
    up: !isSuspended() && isUp(),
    active: odd.isSelected,
    suspended: isSuspended(),
    suspended__active: isSuspended() && odd.isSelected,
    'odd-cell__antepost':
      event.type === EVENT_TYPE.ANTEPOST || event.type === EVENT_TYPE.SPECIAL,
  };

  return (
    <Tooltip
      placement="right"
      overlay={
        (event.type === EVENT_TYPE.ANTEPOST ||
          event.type === EVENT_TYPE.SPECIAL) &&
        odd
          ? odd.displayValue
          : (odd.outcome || {}).shortDescription || ''
      }
      overlayClassName="tooltip"
      mouseEnterDelay={0.5}
    >
      <div
        onClick={handleSelect}
        className={classNames('cell', 'odd-cell', 'noselect', classes)}
      >
        <span className="text-ellipsis">{odd.displayValue}</span>

        {isSuspended() && (
          <Icon name="lockSmall" size="small" className="ml-1" />
        )}
      </div>
    </Tooltip>
  );
});

export default OddCell;
